import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Button, Card, Space, Tag } from 'antd'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { Actions } from '../../../modules/sbrm/components/Actions'
import { initialQuery } from '../../../utils/helpers/crud/models'
import { extractSortDataFromSorterResult } from '../../../utils/table/sorter'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { ReportItem as ReportItemModel } from '../../../models/Report'
import SBTable from '../../../components/SBTable'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import {
  getReportItems,
  selectReportItems,
  setReportItemQuery,
} from '../../../reducers/ReportItemReducer'
import * as AntdIcons from '@ant-design/icons'
import { Reporting } from '../../../components/reporting/Reporting'
import { Link } from 'react-router-dom'
import HasAccess from '../../../components/HasAccess'
import { AlelaPermission } from '../../../utils/permissions'

const ItemsPage = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const items = useAppSelector(selectReportItems()).sort(
    (a, b) => a.type.id - b.type.id
  )
  const { query, isLoading } = useAppSelector((state) => state.reportItem)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const allIcons: { [key: string]: any } = AntdIcons

  const columns: ColumnsType<ReportItemModel> = [
    {
      key: 'type',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.Settings.Venues.Type,
      }),
      render: (record: ReportItemModel) => (
        <Reporting.ItemType type={record.type} />
      ),
    },
    {
      key: 'name',
      title: intl.formatMessage({ id: LocalizationKeys.Misc.Table.Name }),
      render: (record: ReportItemModel) => record.name,
    },
    // {
    //   key: 'icon',
    //   title: intl.formatMessage({ id: LocalizationKeys.Misc.Table.Icon }),
    //   render: (record: ReportItemModel) => {
    //     const isIcon = allIcons.hasOwnProperty(record.icon)
    //     if (!isIcon) return '--'
    //     const Component = allIcons[record.icon]
    //     return <Component style={{ fontSize: '20px' }} />
    //   },
    // },
    {
      key: 'actions',
      align: 'right',
      render: (record: ReportItemModel) => (
        <Actions
          actions={['update', 'delete']}
          entity={SBRMType.reportItem}
          entityId={record.id}
        />
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<ReportItemModel> | SorterResult<ReportItemModel>[]
  ) => {
    const newQuery = {
      pagination,
      filters,
      ...extractSortDataFromSorterResult(sorter),
    }
    dispatch(setReportItemQuery(newQuery))
    dispatch(getReportItems(newQuery))
  }

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      return
    }
    setIsFirstRender(false)

    // Trigger on init and when SBRM is closed
    dispatch(setReportItemQuery(initialQuery))
    dispatch(getReportItems(initialQuery))
  }, [dispatch, SBRMIsOpen])

  return (
    <HasAccess permissions={[AlelaPermission.listReportItem]}>
      <Card
        bordered={false}
        styles={{ body: { padding: 0 } }}
        title={
          <PageTitle
            title={intl.formatMessage({
              id: LocalizationKeys.Page.Settings.Reporting.Items.Title,
            })}
            level={5}
            toolbar={
              <Space>
                <Link to={'reorder'}>
                  <Button>
                    {intl.formatMessage({
                      id: LocalizationKeys.Page.Settings.Reporting.Items
                        .Reorder,
                    })}
                  </Button>
                </Link>
                <Actions actions={['create']} entity={SBRMType.reportItem} />
              </Space>
            }
          />
        }
      >
        <SBTable
          entity={SBRMType.reportItem}
          scroll={{ x: 600 }}
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={items}
          pagination={query.pagination}
          loading={isLoading}
          onChange={handleTableChange}
        />
      </Card>
    </HasAccess>
  )
}

export default ItemsPage

import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Tenant } from '../../models/Tenant'
import { useAuth } from '../../utils/auth/Auth'
import { ItemType } from 'antd/es/menu/hooks/useItems'
import { Avatar, Dropdown, MenuProps, Space, Typography } from 'antd'
import SearchDrawer from '../../modules/search/SearchDrawer'
import { Notification } from '../../components/notification/Notification'
import { Help } from '../../components/help/Help'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { isAvailableLocale, useLang } from '../../i18n/useLang'
import { useState } from 'react'
import { CheckOutlined } from '@ant-design/icons'
import { handleLocaleSubmit } from '../../components/preference/Locale'

const { Text } = Typography

const RightHeader = () => {
  const intl = useIntl()
  const { currentUser, currentTenant, tenants, saveTenant } = useAuth()

  const { locales, selectedLocale, setLocale } = useLang()
  const [localeIsLoading, setLocaleIsLoading] = useState<boolean>(false)

  const onChangeTenant = (tenant: Tenant) => {
    saveTenant(tenant)
    window.location.reload()
  }

  const selectedTenantStyle: React.CSSProperties = {
    border: 'solid 1px rgba(93, 93, 246,0.5)',
    backgroundColor: 'rgba(93, 93, 246,0.2)',
  }

  const items: MenuProps['items'] = [
    ...(tenants ?? []).map((tenant: Tenant) => {
      const item: ItemType = {
        key: tenant.id,
        disabled: currentTenant?.id === tenant.id,
        onClick: () => {
          onChangeTenant(tenant)
        },
        style:
          (tenants ?? []).length > 1 && currentTenant?.id === tenant.id
            ? selectedTenantStyle
            : undefined,
        label: (
          <Space>
            <Avatar shape="square" src={tenant?.logo} />
            <Text>{tenant?.name}</Text>
          </Space>
        ),
      }
      return item
    }),
    {
      type: 'divider',
    },
    {
      key: 'profile',
      label: (
        <Link to={'/account/profile'}>
          {intl.formatMessage({
            id: LocalizationKeys.Layout.RightHeader.Profile,
          })}
        </Link>
      ),
    },
    {
      key: 'lang',
      label: intl.formatMessage({
        id: LocalizationKeys.Layout.RightHeader.Lang,
      }),
      disabled: localeIsLoading,
      children: locales.map((locale) => ({
        key: locale.id,
        label: (
          <Space
            className="w-full"
            style={{ justifyContent: 'space-between', minWidth: 150 }}
          >
            {locale.label} {selectedLocale == locale.id && <CheckOutlined />}
          </Space>
        ),
        onClick: () =>
          handleLocaleSubmit(locale.id, setLocaleIsLoading, setLocale),
      })),
    },
    {
      key: 'settings',
      label: (
        <Link to={'/settings'}>
          {intl.formatMessage({
            id: LocalizationKeys.Layout.RightHeader.Settings,
          })}
        </Link>
      ),
    },
  ]

  return (
    <Space size={12}>
      <Notification.Wrapper />
      <Help.Toogle />
      <SearchDrawer />
      <Dropdown
        arrow
        menu={{ items }}
        placement="bottomRight"
        overlayStyle={{ width: 230, right: '10px' }}
      >
        <Avatar
          src={currentUser?.profilePicture}
          style={{
            marginBottom: 4,
            color: '#fff',
            backgroundColor: currentUser?.profilePicture
              ? undefined
              : '#5d5df6',
            cursor: 'pointer',
          }}
        >
          {currentUser && currentUser.firstName && currentUser.lastName
            ? currentUser!.firstName.slice(0, 1) +
              currentUser!.lastName.slice(0, 1)
            : '--'}
        </Avatar>
      </Dropdown>
    </Space>
  )
}

export default RightHeader

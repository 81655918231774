import { AxiosStatic } from 'axios'
import { AuthModel } from '../models/AuthModel'
import {
  getAuth,
  getAuthTenant,
  setAuth,
} from './helpers/AuthLocalStorageHelper'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from './saml-sso/authConfig'
import { IPublicClientApplication } from '@azure/msal-browser'
import { ArgsProps } from 'antd/es/notification/interface'
import { useLang } from '../i18n/useLang'

/**
 * Get an access token for Alela API
 */
async function getAccessToken(instance: IPublicClientApplication) {
  // Check if there is an email connection token stored
  const auth = getAuth()
  if (auth && auth.access_token) {
    return auth.access_token
  }

  // If not, check if there is an active Azure SSO account
  const account = instance.getActiveAccount()
  if (!account) {
    return undefined
  }

  // If so, get a token for the active Azure SSO account
  const response = await instance.acquireTokenSilent({
    ...loginRequest,
    account: account,
  })
  return response.idToken
}

/**
 * Declare a global pointer to the refresh token function
 * Thanks to this, when 2 expired requests started at the same time will get the same function pointer
 */
let refreshAccessTokenPointer: Promise<string | null> | undefined = undefined

export function setupAxios(
  axios: AxiosStatic,
  displayNotification: (notification: ArgsProps) => void
) {
  const { instance } = useMsal()
  const { selectedLocale } = useLang()
  // Requests default headers
  axios.defaults.headers.common['Accept'] = 'application/json'
  axios.defaults.headers.common['Accept-Language'] = selectedLocale

  // Request interceptor for API calls
  axios.interceptors.request.use(
    async (config) => {
      const accessToken = await getAccessToken(instance)
      if (accessToken) {
        config.headers['Alela-Bearer'] = `Bearer ${accessToken}`
        config.headers['Authorization'] = `Bearer ${accessToken}`
        config.headers.Accept = 'application/json'
      }

      const tenant = getAuthTenant()
      if (tenant) {
        config.headers['X-Alela-Organization'] = tenant.id
      }

      return config
    },
    (error) => {
      Promise.reject(error)
    }
  )

  // Response interceptor for API calls
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    async function (error) {
      const originalRequest = error.config
      if (error.response.status === 401 && !originalRequest._retry) {
        /**
         * The API returned a 401 - Unauthorized status code
         */
        try {
          // First of all, mark the request as retried
          originalRequest._retry = true

          // Grab a new token
          if (refreshAccessTokenPointer === undefined) {
            refreshAccessTokenPointer = refreshAccessToken(axios, instance)
          }
          const access_token = await refreshAccessTokenPointer
          if (access_token === null) {
            return Promise.reject(error)
          }

          axios.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${access_token}`
          axios.defaults.headers.common[
            'Alela-Bearer'
          ] = `Bearer ${access_token}`

          return axios(originalRequest)
        } catch (error) {
          return Promise.reject(error)
        } finally {
          refreshAccessTokenPointer = undefined
        }
      }
      if (error.response.status === 403) {
        /**
         * The API returned a 403 - Forbidden
         *
         * Check if it's a tenant suspended error
         */

        const messages: Record<string, string> = {
          ['tenants.suspended']:
            'Your tenant has been suspended. Contact your account administrator.',
        }

        const axiosResponse = error.response.data
        if (
          axiosResponse &&
          'data' in axiosResponse &&
          'message' in axiosResponse.data &&
          messages[axiosResponse.data.message]
        ) {
          displayNotification({
            type: 'error',
            duration: 0,
            message: 'Organization issue',
            description: messages[axiosResponse.data.message],
          })
        }
        // No fallback `else` statement as we don't want to display notification for each 403 error

        // No return so we fallback on the Promise.reject below
      }

      /**
       * It is not a special error, just return the error
       */
      return Promise.reject(error)
    }
  )
}

/**
 * Refresh the access token
 */
async function refreshAccessToken(
  axios: AxiosStatic,
  msalInstance: IPublicClientApplication
) {
  const auth = getAuth()
  if (!auth || !auth.refresh_token) {
    await refreshAzureSSOAccessToken(msalInstance)
    return null
  }

  return refreshEmailAccessToken(axios)
}

async function refreshEmailAccessToken(axios: AxiosStatic) {
  const auth = getAuth()
  const OAUTH_CLIENT_SECRET = process.env.REACT_APP_OAUTH_CLIENT_SECRET
  const OAUTH_CLIENT_ID = process.env.REACT_APP_OAUTH_CLIENT_ID
  const API_OAUTH_URL = process.env.REACT_APP_API_OAUTH_URL
  const REFRESH_TOKEN_URL = `${API_OAUTH_URL}/token`

  const { data: response } = await axios.post<AuthModel>(REFRESH_TOKEN_URL, {
    grant_type: 'refresh_token',
    client_id: OAUTH_CLIENT_ID,
    client_secret: OAUTH_CLIENT_SECRET,
    refresh_token: auth?.refresh_token,
    scope: '',
  })
  setAuth(response)

  return response.access_token
}

async function refreshAzureSSOAccessToken(
  msalInstance: IPublicClientApplication
) {
  const account = msalInstance.getActiveAccount()
  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    )
  }
  msalInstance.acquireTokenRedirect({
    ...loginRequest,
    account: account,
  })
}

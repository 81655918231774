import { Stage } from '../../models/Stage'
import { ColumnsType } from 'antd/es/table'
import { useAppSelector } from '../../reducers/hooks'
import { Actions } from '../../modules/sbrm/components/Actions'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { selectStages, selectStagesByIds } from '../../reducers/StageReducer'
import SBTable from '../SBTable'
import { MetaData } from '../../models/MetaData'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'

interface Props {
  ids?: number[]
  emptyActionsMetadata?: MetaData[]
}

export const Table = ({ ids, emptyActionsMetadata }: Props) => {
  const intl = useIntl()
  const stages = ids
    ? useAppSelector(selectStagesByIds(ids))
    : useAppSelector(selectStages())

  const { isLoading } = useAppSelector((state) => state.stage)

  const columns: ColumnsType<Stage> = [
    {
      key: 'name',
      title: intl.formatMessage({ id: LocalizationKeys.Misc.Table.Name }),
      dataIndex: 'name',
    },
    {
      key: 'actions',
      align: 'right',
      render: (record: Stage) => (
        <Actions
          actions={['update', 'delete']}
          entity={SBRMType.stage}
          entityId={record.id}
        />
      ),
    },
  ]

  return (
    <SBTable
      entity={SBRMType.stage}
      emptyActionsMetadata={emptyActionsMetadata}
      scroll={{ x: 800 }}
      loading={isLoading && !stages.length}
      columns={columns}
      rowKey={(record) => record.id}
      dataSource={stages}
      // pagination={query.pagination}
      // onChange={handleTableChange}
    />
  )
}

export type TableType = {
  Table: typeof Table
}

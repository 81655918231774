import { FC } from 'react'
import { Tag, TagType } from './Tag'
import { PerformanceTypeForm, PerformanceTypeFormType } from './form/Form'

export const PerformanceType: FC &
  PerformanceTypeFormType &
  TagType = (): JSX.Element => <></>

PerformanceType.Form = PerformanceTypeForm
PerformanceType.Tag = Tag

import axios from 'axios'
import { useAPIPing, useAPIPingType } from './hooks'
import { PING_URL } from '../../utils/urls'
import { useAuth } from '../../utils/auth/Auth'
import { AlelaPingData, initialAlelaPingData } from './models'
import { usePageVisibility } from '../../utils/usePageVisibility'
import { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { APIPingContext } from './context'

interface APIPingProps {}

const APIPing: React.FC<PropsWithChildren<APIPingProps>> & useAPIPingType = ({
  children,
}: PropsWithChildren<APIPingProps>) => {
  const isPageVisible = usePageVisibility()

  const { currentTenant } = useAuth()

  const timeIdRef = useRef<NodeJS.Timer | null>(null)

  const [isPollingEnabled, setIsPollingEnabled] = useState<boolean>(true)
  const [pingData, setPingData] = useState<AlelaPingData>(initialAlelaPingData)

  useEffect(() => {
    const pollingCallback = async () => {
      // Ensure having all mandatory data
      if (!currentTenant) {
        return
      }

      // Polling logic
      await axios
        .post(PING_URL)
        .then((data) => {
          setPingData(data.data.data)
        })
        .catch((e) => {
          setIsPollingEnabled(false)
        })
    }

    const startPolling = () => {
      pollingCallback() // Fetch data on initialization
      timeIdRef.current = setInterval(pollingCallback, 60000) // Polling every 60 seconds
    }

    const stopPolling = () => {
      if (!timeIdRef.current) {
        return
      }
      clearInterval(timeIdRef.current as NodeJS.Timeout)
    }

    if (isPageVisible && isPollingEnabled) {
      startPolling()
    } else {
      stopPolling()
    }

    return () => {
      stopPolling()
    }
  }, [isPageVisible, isPollingEnabled, currentTenant])

  return (
    <APIPingContext.Provider
      value={{
        pingData,
      }}
    >
      {children}
    </APIPingContext.Provider>
  )
}

APIPing.useAPIPing = useAPIPing

export default APIPing

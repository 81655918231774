import { forwardRef, Ref, useImperativeHandle } from 'react'
import { SBRMFormInterface, SBRMFormProps } from '../SBRMModel'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import { Alert, Form } from 'antd'
import Create from './Create'
import Update from './Update'
import Delete from './Delete'
import { useSBRMData } from '../hooks'
import {
  createBookingType,
  deleteBookingType,
  updateBookingType,
} from '../../../reducers/BookingTypeReducer'

const BookingTypeForm = forwardRef(
  ({ isNested }: SBRMFormProps, ref: Ref<SBRMFormInterface>) => {
    useImperativeHandle(ref, () => ({
      handleCreate,
      handleUpdate,
      handleDelete,
      handleReset,
    }))

    const dispatch = useAppDispatch()
    const { action } = useSBRMData(isNested)
    const { error } = useAppSelector((state) => state.bookingType)

    const [createForm] = Form.useForm()
    const [updateForm] = Form.useForm()
    const [deleteForm] = Form.useForm()

    const handleCreate = async () =>
      await createForm.validateFields().then(async (result) =>
        dispatch(createBookingType(createForm.getFieldsValue())).then(() => {
          createForm.resetFields()
          return true
        })
      )

    const handleUpdate = async () =>
      await updateForm.validateFields().then(async (result) =>
        dispatch(updateBookingType(updateForm.getFieldsValue())).then(() => {
          updateForm.resetFields()
          return true
        })
      )

    const handleDelete = async () =>
      await deleteForm.validateFields().then(async (result) =>
        dispatch(deleteBookingType(deleteForm.getFieldsValue())).then(() => {
          deleteForm.resetFields()
          return true
        })
      )

    const handleReset = () => {
      createForm.resetFields()
      updateForm.resetFields()
      deleteForm.resetFields()
    }

    return (
      <>
        {action === 'create' && <Create form={createForm} />}
        {action === 'update' && <Update form={updateForm} />}
        {action === 'delete' && <Delete form={deleteForm} />}
        {error && <Alert message={String(error)} type="error" />}
      </>
    )
  }
)

BookingTypeForm.displayName = 'BookingTypeForm'

export default BookingTypeForm

import { useAppSelector } from '../../reducers/hooks'
import { Travel as TravelModel } from '../../models/Travel'
import { selectTravelById } from '../../reducers/TravelReducer'
import { Travel as UIKitTravel } from '@supplement-bacon/alela-uikit'

interface Props {
  id?: number
  model?: TravelModel
}

export const Type = ({ id, model }: Props) => {
  const travel = model ? model : useAppSelector(selectTravelById(id!))

  if (travel === undefined) return <></>
  return <UIKitTravel.Type travel={travel} />
}

export type TypeType = { Type: typeof Type }

import { Button, Card, InputRef, Tag } from 'antd'
import { useIntl } from 'react-intl'
import { useEffect, useRef, useState } from 'react'
import { Venue as VenueModel } from '../../../models/Venue'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { initialQuery } from '../../../utils/helpers/crud/models'
import {
  getVenues,
  selectVenues,
  setVenueQuery,
} from '../../../reducers/VenueReducer'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import getColumnSearchProps from '../../../utils/table/getColumnSearchProps'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { Actions } from '../../../modules/sbrm/components/Actions'
import { Stage } from '../../../components/stage/Stage'
import { Venue } from '../../../components/venue/Venue'
import SBTable from '../../../components/SBTable'
import { extractSortDataFromSorterResult } from '../../../utils/table/sorter'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { HelpArticle } from '../../../utils/hooks/useAlelaHelp'
import { useNavigate } from 'react-router-dom'
import { TableParams } from '../../../models/TableParams'
import { Help } from '../../../components/help/Help'

const VenuesPage = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const searchInput = useRef<InputRef>(null)
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const venues = useAppSelector(selectVenues())
  const { query, isLoading } = useAppSelector((state) => state.venue)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const handleSearch = (
    selectedKeys: string[],
    dataIndex: keyof VenueModel
  ) => {
    const newQuery = {
      ...query,
      ...{ search: { [dataIndex]: selectedKeys[0] } },
    }
    dispatch(setVenueQuery(newQuery))
    dispatch(getVenues(newQuery))
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    const newQuery = {
      ...query,
      ...{ search: { dataIndex: null } },
    }
    dispatch(setVenueQuery(newQuery))
    dispatch(getVenues(newQuery))
  }

  const columns: ColumnsType<VenueModel> = [
    {
      key: 'name',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.Settings.Venues.Name,
      }),
      sorter: true,
      ...getColumnSearchProps<VenueModel>(
        'name',
        searchInput,
        handleSearch,
        handleReset,
        intl,
        isLoading
      ),
      render: (venue: VenueModel) => (
        <Venue.Avatar model={venue} size="large" />
      ),
    },
    {
      key: 'type',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.Settings.Venues.Type,
      }),
      render: (venue: VenueModel) => <Venue.TypeTag id={Number(venue.type)} />,
    },
    {
      key: 'stages',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.Settings.Venues.Stages,
      }),
      render: (venue: VenueModel) => <Stage.List ids={venue.stages ?? []} />,
    },
    {
      key: 'defaultOpeningRange',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.Settings.Venues.OpeningRange,
      }),
      render: (venue: VenueModel) => (
        <>
          {venue.defaultOpeningRange && venue.defaultOpeningRange.start && (
            <Tag>
              {venue.defaultOpeningRange.start}
              {' -> '}
              {venue.defaultOpeningRange.end}
            </Tag>
          )}
        </>
      ),
    },
    {
      key: 'actions',
      align: 'right',
      render: (venue: VenueModel) => (
        <Actions
          actions={['update', 'delete']}
          entity={SBRMType.venue}
          entityId={venue.id}
        />
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<VenueModel> | SorterResult<VenueModel>[]
  ) => {
    const newQuery = {
      pagination,
      filters,
      ...extractSortDataFromSorterResult(sorter),
    }
    dispatch(setVenueQuery(newQuery))
    dispatch(getVenues(newQuery))
  }

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      return
    }
    setIsFirstRender(false)

    // Trigger when SBRM is closed and on init
    const baseQuery: TableParams = {
      ...initialQuery,
      pagination: { current: 1, pageSize: 100 },
    }
    dispatch(setVenueQuery(baseQuery))
    dispatch(getVenues(baseQuery))
  }, [dispatch, SBRMIsOpen])

  return (
    <Card
      bordered={false}
      styles={{ body: { padding: 0 } }}
      title={
        <PageTitle
          title={intl.formatMessage({
            id: LocalizationKeys.Page.Settings.Venues.Title,
          })}
          level={5}
          toolbar={
            <>
              <Actions actions={['create']} entity={SBRMType.venue} />
              <Button onClick={() => navigate('/settings/venues/trashed')}>
                {intl.formatMessage({
                  id: LocalizationKeys.Page.Settings.Venues.SeeArchived,
                })}
              </Button>
              <Help.Button article={HelpArticle.CreateAVenue} />
            </>
          }
        />
      }
    >
      <SBTable
        entity={SBRMType.venue}
        scroll={{ x: 600 }}
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={venues}
        pagination={query.pagination}
        loading={isLoading && venues.length == 0}
        onChange={handleTableChange}
      />
    </Card>
  )
}

export default VenuesPage

import { Form, FormInstance, Input, Result } from 'antd'
import { useAppSelector } from '../../../reducers/hooks'
import { requiredRule } from '../../../utils/rules'
import { useIntl } from 'react-intl'
import { useEffect } from 'react'
import { WarningOutlined } from '@ant-design/icons'
import { selectSelectedInvoice } from '../../../reducers/InvoiceReducer'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

interface Props {
  form: FormInstance
}

const Delete = ({ form }: Props) => {
  const intl = useIntl()
  const invoice = useAppSelector(selectSelectedInvoice())

  useEffect(() => {
    form.setFieldsValue(invoice)
  }, [invoice])

  return invoice ? (
    <Form form={form} layout="vertical">
      <Result
        title={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.AreYouSureToDelete,
        })}
        icon={<WarningOutlined style={{ color: '#dc4446' }} />}
      />
      <Form.Item name="id" rules={[requiredRule(intl)]} className="d-none">
        <Input type="hidden" />
      </Form.Item>
    </Form>
  ) : (
    <></>
  )
}

export default Delete

import { Event } from '../components/event/Event'
import { AlelaPermission } from '../utils/permissions'

import HasAccess from '../components/HasAccess'

const CalendarPage = () => {
  return (
    <HasAccess permissions={[AlelaPermission.listEvent]}>
      <Event.Calendar />
    </HasAccess>
  )
}

export default CalendarPage

import axios from 'axios'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useLang } from '../../i18n/useLang'
import { useAuth } from '../../utils/auth/Auth'
import { requiredRule } from '../../utils/rules'
import { CURRENCIES_URL, TENANT_SETTINGS_URL } from '../../utils/urls'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import {
  App,
  Button,
  Card,
  Col,
  Form,
  Row,
  Select,
  SelectProps,
  Space,
  Spin,
} from 'antd'
import { debounce } from 'lodash'
import { initialQuery } from '../../utils/helpers/crud/models'
import qs from 'qs'
import { TableParams } from '../../models/TableParams'
import { useTenantSettings } from '../../utils/hooks/useTenantSettings'

const OrganizationSettingsPage = () => {
  const intl = useIntl()
  const [form] = Form.useForm()
  const { locales } = useLang()
  const { message } = App.useApp()
  const { currentTenant } = useAuth()

  const data = {
    measurementUnit: [
      {
        value: 'metric',
        label: intl.formatMessage({
          id: LocalizationKeys.Page.Settings.OrganizationPreferences
            .MetricLabel,
        }),
      },
      {
        value: 'imperial',
        label: intl.formatMessage({
          id: LocalizationKeys.Page.Settings.OrganizationPreferences
            .ImperialLabel,
        }),
      },
    ],
    days: [
      {
        value: 0,
        label: intl.formatMessage({ id: LocalizationKeys.Misc.Days.Sunday }),
      },
      {
        value: 1,
        label: intl.formatMessage({ id: LocalizationKeys.Misc.Days.Monday }),
      },
      {
        value: 2,
        label: intl.formatMessage({ id: LocalizationKeys.Misc.Days.Tuesday }),
      },
      {
        value: 3,
        label: intl.formatMessage({ id: LocalizationKeys.Misc.Days.Wednesday }),
      },
      {
        value: 4,
        label: intl.formatMessage({ id: LocalizationKeys.Misc.Days.Thursday }),
      },
      {
        value: 5,
        label: intl.formatMessage({ id: LocalizationKeys.Misc.Days.Friday }),
      },
      {
        value: 6,
        label: intl.formatMessage({ id: LocalizationKeys.Misc.Days.Saturday }),
      },
    ],
  }

  const onFormSubmit = () => {
    axios
      .put(TENANT_SETTINGS_URL, form.getFieldsValue())
      .then(() => {
        message.open({ type: 'success', content: 'Alelaaaaa!' })
      })
      .catch(() => {
        message.open({ type: 'error', content: 'Hum...' })
      })
  }

  const fetchCurrencies = (search: any) => {
    const baseQuery: TableParams = {
      ...initialQuery,
      pagination: { current: 1, pageSize: 10000 },
      search: { name: search },
    }
    return axios.get(CURRENCIES_URL + '?' + qs.stringify(baseQuery))
  }

  useEffect(() => {
    form.setFieldsValue(currentTenant?.settings)
    form.setFieldValue('currency', currentTenant?.settings.currency.id)
  }, [currentTenant])

  if (currentTenant === undefined) return <Spin />

  return (
    <>
      <PageTitle
        title={intl.formatMessage({
          id: LocalizationKeys.Page.Settings.OrganizationPreferences.Title,
        })}
      />
      <Card bordered={false}>
        <Form form={form} layout="vertical" onFinish={onFormSubmit}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="currency"
                label={intl.formatMessage({
                  id: LocalizationKeys.Misc.Form.Currency,
                })}
                rules={[requiredRule(intl)]}
              >
                <DebounceSelect
                  placeholder="Search currency"
                  fetchOptions={fetchCurrencies}
                  style={{
                    width: '100%',
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="measurementUnit"
                label={intl.formatMessage({
                  id: LocalizationKeys.Misc.Form.UnitOfMeasurement,
                })}
                rules={[requiredRule(intl)]}
                help={intl.formatMessage({
                  id: LocalizationKeys.Page.Settings.OrganizationPreferences
                    .UnitsOfMeasurementDescription,
                })}
              >
                <Select options={data.measurementUnit} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="locale"
                label={intl.formatMessage({
                  id: LocalizationKeys.Misc.Form.Locale,
                })}
                rules={[requiredRule(intl)]}
                help={intl.formatMessage({
                  id: LocalizationKeys.Page.Settings.OrganizationPreferences
                    .LocaleDescription,
                })}
              >
                <Select options={locales} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="firstDayCalendar"
                label={intl.formatMessage({
                  id: LocalizationKeys.Misc.Form.FirstCalendarDay,
                })}
                rules={[requiredRule(intl)]}
                help={intl.formatMessage({
                  id: LocalizationKeys.Page.Settings.OrganizationPreferences
                    .FirstCalendarDayDescription,
                })}
              >
                <Select options={data.days} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="timezone"
                label={intl.formatMessage({
                  id: LocalizationKeys.Misc.Form.Timezone,
                })}
                rules={[requiredRule(intl)]}
              >
                <Select
                  showSearch
                  options={Intl.supportedValuesOf('timeZone').map(
                    (timezone: string) => ({ value: timezone, label: timezone })
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Space className="w-full" style={{ justifyContent: 'end' }}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Space>
        </Form>
      </Card>
    </>
  )
}

function DebounceSelect({
  fetchOptions,
  debounceTimeout = 800,
  ...props
}: any) {
  const { currency } = useTenantSettings()
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState<SelectProps['options']>([
    { value: currency?.id, label: currency?.name + ' - ' + currency?.symbol },
  ])
  const fetchRef = useRef(0)

  useEffect(() => {
    debounceFetcher('')
  }, [])

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: any) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)
      fetchOptions(value).then((res: any) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }
        const newOptions = res.data.data
        setOptions(
          newOptions.map((option: any) => ({
            value: option.id,
            label: option.name + ' - ' + option.symbol,
          }))
        )
        setFetching(false)
      })
    }
    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  return (
    <Select
      filterOption={false}
      showSearch
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  )
}

export default OrganizationSettingsPage

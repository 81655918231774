import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIUpdate,
} from '../utils/helpers/SBAPIHelper'
import { BookingType } from '../models/Booking'
import { BOOKING_TYPES_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { bookingTypeSchema } from '../models/schema'

const initialState: SBState<BookingType> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: {
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },
}

const slice = createSlice({
  name: 'bookingType',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getBookingTypesSuccess,
  setQuery: setBookingTypeQuery,
  reset: resetBookingTypeState,
  resetQueryAndIds: resetBookingTypeQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: BookingType }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectBookingTypes = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedBookingType = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectBookingTypeById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectBookingTypesByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedBookingType =
  (bookingType: BookingType | number) => async (dispatch: AppDispatch) =>
    dispatch(
      slice.actions.setSelectedId(getIdOrModelId<BookingType>(bookingType))
    )

export const getBookingTypes = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<BookingType>(
    BOOKING_TYPES_URL,
    params,
    [bookingTypeSchema],
    slice.actions
  )

export const getBookingTypeWithId = (id: number) =>
  SBAPIFetchDispatch<BookingType>(
    `${BOOKING_TYPES_URL}/${id}`,
    bookingTypeSchema,
    slice.actions
  )

export const createBookingType = (bookingType: BookingType) =>
  SBAPICreate<BookingType>(
    bookingType,
    BOOKING_TYPES_URL,
    bookingTypeSchema,
    slice.actions
  )

export const updateBookingType = (bookingType: BookingType) =>
  SBAPIUpdate<BookingType>(
    bookingType,
    `${BOOKING_TYPES_URL}/${bookingType.id}`,
    slice.actions
  )

export const deleteBookingType = (bookingType: BookingType) =>
  SBAPIDelete<BookingType>(
    bookingType,
    `${BOOKING_TYPES_URL}/${bookingType.id}`,
    slice.actions
  )

import { createSlice } from '@reduxjs/toolkit'
import { Artist } from '../models/Artist'
import {
  artistSchema,
  eventSchema,
  supplierSchema,
  venueSchema,
} from '../models/schema'
import { TableParams } from '../models/TableParams'
import { Venue } from '../models/Venue'
import { SBAPIFetchPaginatedDispatch } from '../utils/helpers/SBAPIHelper'
import {
  ARTISTS_URL,
  EVENTS_URL,
  SUPPLIERS_URL,
  VENUES_URL,
} from '../utils/urls'
import { Supplier } from '../models/Supplier'

type SearchItem = {
  ids: number[]
  isLoading: boolean
}

type State = {
  query: TableParams
  events: SearchItem
  venues: SearchItem
  artists: SearchItem
  suppliers: SearchItem
}

const initialSearch = {
  pagination: {
    current: 1,
    pageSize: 10,
  },
}

const initialSearchItem: SearchItem = {
  ids: [],
  isLoading: false,
}

const initialState: State = {
  query: initialSearch,
  events: initialSearchItem,
  venues: initialSearchItem,
  artists: initialSearchItem,
  suppliers: initialSearchItem,
}

const slice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setEventsLoading(state, action) {
      state.events.isLoading = true
    },
    setVenuesLoading(state, action) {
      state.venues.isLoading = true
    },
    setArtistsLoading(state, action) {
      state.artists.isLoading = true
    },
    setSuppliersLoading(state, action) {
      state.suppliers.isLoading = true
    },
    setEventIds(state, action) {
      state.events.isLoading = false
      state.events.ids = action.payload
    },
    setVenueIds(state, action) {
      state.venues.isLoading = false
      state.venues.ids = action.payload
    },
    setArtistIds(state, action) {
      state.artists.isLoading = false
      state.artists.ids = action.payload
    },
    setSupplierIds(state, action) {
      state.suppliers.isLoading = false
      state.suppliers.ids = action.payload
    },
    reset(state) {
      state = {
        query: initialSearch,
        events: initialSearchItem,
        venues: initialSearchItem,
        artists: initialSearchItem,
        suppliers: initialSearchItem,
      }
    },
  },
})

// Reducer
export default slice.reducer

export const {
  setEventIds,
  setArtistIds,
  setVenueIds,
  setSupplierIds,
  reset: resetSearch,
} = slice.actions

/**
 * Actions
 */

export const getSearchArtists = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<Artist>(
    ARTISTS_URL,
    params,
    [artistSchema],
    slice.actions,
    slice.actions.setArtistsLoading,
    slice.actions.setArtistIds
  )

export const getSearchEvents = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<Event>(
    EVENTS_URL,
    params,
    [eventSchema],
    slice.actions,
    slice.actions.setEventsLoading,
    slice.actions.setEventIds
  )

export const getSearchVenues = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<Venue>(
    VENUES_URL,
    params,
    [venueSchema],
    slice.actions,
    slice.actions.setVenuesLoading,
    slice.actions.setVenueIds
  )

export const getSearchSuppliers = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<Supplier>(
    SUPPLIERS_URL,
    params,
    [supplierSchema],
    slice.actions,
    slice.actions.setSuppliersLoading,
    slice.actions.setSupplierIds
  )

import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIUpdate,
} from '../utils/helpers/SBAPIHelper'
import { MARKETING_TASK_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { marketingTaskSchema } from '../models/schema'
import { MarketingTask } from '../models/MarketingTask'

const initialState: SBState<MarketingTask> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: {
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },
}

const slice = createSlice({
  name: 'marketingTask',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getMarketingTasksSuccess,
  setQuery: setMarketingTaskQuery,
  reset: resetMarketingTaskState,
  resetQueryAndIds: resetMarketingTaskQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: MarketingTask }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectMarketingTasks = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectAllMarketingTasks = () =>
  createSelector([selectRawItems], (items) =>
    Object.entries(items).map((i) => i[1])
  )
export const selectSelectedMarketingTask = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectMarketingTaskById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectMarketingTasksByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )
export const selectMarketingTasksByStatusId = (id: number) =>
  createSelector([selectRawItems], (items) =>
    Object.entries(items)
      .map((i) => i[1])
      .filter((t) => t.status === id)
  )

/**
 * Actions
 */

export const setSelectedMarketingTask =
  (marketingTask: MarketingTask | number) => async (dispatch: AppDispatch) =>
    dispatch(
      slice.actions.setSelectedId(getIdOrModelId<MarketingTask>(marketingTask))
    )

export const getMarketingTasks = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<MarketingTask>(
    MARKETING_TASK_URL,
    params,
    [marketingTaskSchema],
    slice.actions
  )

export const getMarketingTaskWithId = (id: number) =>
  SBAPIFetchDispatch<MarketingTask>(
    `${MARKETING_TASK_URL}/${id}`,
    marketingTaskSchema,
    slice.actions
  )

export const createMarketingTask = (marketingTask: MarketingTask) =>
  SBAPICreate<MarketingTask>(
    marketingTask,
    MARKETING_TASK_URL,
    marketingTaskSchema,
    slice.actions
  )

export const updateMarketingTask = (marketingTask: MarketingTask) =>
  SBAPIUpdate<MarketingTask>(
    marketingTask,
    `${MARKETING_TASK_URL}/${marketingTask.id}`,
    slice.actions
  )

export const restoreMarketingTask = (marketingTask: MarketingTask) =>
  SBAPIUpdate<MarketingTask>(
    marketingTask,
    `${MARKETING_TASK_URL}/${marketingTask.id}/restore`,
    slice.actions
  )

export const deleteMarketingTask = (marketingTask: MarketingTask) =>
  SBAPIDelete<MarketingTask>(
    marketingTask,
    `${MARKETING_TASK_URL}/${marketingTask.id}`,
    slice.actions
  )

import { Tag, Tooltip } from 'antd'
import { useAppSelector } from '../../reducers/hooks'
import { selectEventById } from '../../reducers/EventReducer'
import dayjs from 'dayjs'
import { selectVenuesByIds } from '../../reducers/VenueReducer'
import { selectEventStatusById } from '../../reducers/EventStatusReducer'
import { useHasAccess } from '../HasAccess'
import { AlelaPermission } from '../../utils/permissions'
import { ConditionalLinkWrapper } from '@supplement-bacon/alela-uikit'

interface Props {
  id: number
  clickable?: boolean
  colorFrom?: 'venue' | 'status'
}
export const EventCalendarCell = ({
  id,
  clickable = false,
  colorFrom = 'venue',
}: Props) => {
  const { hasAccess } = useHasAccess()

  const event = useAppSelector(selectEventById(id))
  const venue = useAppSelector(selectVenuesByIds(event?.venues ?? []))
  const status = useAppSelector(selectEventStatusById(event?.status ?? 0))

  const color =
    colorFrom === 'venue'
      ? (venue[0] && venue[0].color) ?? 'black'
      : status?.color ?? 'black'

  const isPast = dayjs(event?.end_date).isBefore(dayjs())
  const canViewEvent = hasAccess([AlelaPermission.viewEvent])

  if (!event) {
    return <></>
  }

  return (
    <ConditionalLinkWrapper
      condition={clickable && canViewEvent}
      to={`/events/${event.id}/line-up`}
    >
      <Tooltip
        title={
          (status?.isDraft == true ? `${status?.name} : ` : '') + event.name
        }
      >
        <Tag
          className="w-full"
          style={{
            overflow: 'hidden',
            display: 'block',
            filter: isPast ? 'grayscale(50%)' : 'none',
          }}
          color={color}
        >
          {(status?.isDraft == true && `${status?.name} :`) ?? ''} {event.name}
        </Tag>
      </Tooltip>
    </ConditionalLinkWrapper>
  )
}

export type EventCalendarCellType = { CalendarCell: typeof EventCalendarCell }

import { useAppSelector } from '../../reducers/hooks'
import { Space, Tag, Typography } from 'antd'
import { selectPerformanceById } from '../../reducers/PerformanceReducer'
import { selectBookingById } from '../../reducers/BookingReducer'
import { Artist } from '../artist/Artist'
import { Performance } from '../../models/Performance'
import dayjs from 'dayjs'
import { selectArtistById } from '../../reducers/ArtistReducer'
import { selectPerformanceTypeById } from '../../reducers/PerformanceTypeReducer'

const { Text } = Typography

interface Props {
  id?: number
  model?: Performance
}

export const TimetableCell = ({ id, model }: Props) => {
  const performance = model
    ? model
    : useAppSelector(selectPerformanceById(id ?? 0))
  const booking = useAppSelector(selectBookingById(performance?.booking ?? 0))
  const artist = useAppSelector(selectArtistById(booking?.artist ?? 0))
  const type = useAppSelector(selectPerformanceTypeById(performance?.type ?? 0))

  return performance && booking ? (
    <Space direction="horizontal" style={{ marginTop: 10, marginLeft: 10 }}>
      <Artist.Avatar
        id={booking.artist}
        linkToDetails={false}
        displayName={false}
      />
      <Text>
        {artist?.name} / {dayjs(performance.start).format('HH:mm')} -{' '}
        {dayjs(performance.end).format('HH:mm')}
      </Text>
      {type !== undefined && <Tag color={type.color}>{type.name}</Tag>}
    </Space>
  ) : (
    <></>
  )
}

export type TimetableCellType = { TimetableCell: typeof TimetableCell }

import { Tag } from 'antd'
import { useAppSelector } from '../../reducers/hooks'
import { selectBookingTypeById } from '../../reducers/BookingTypeReducer'

interface Props {
  id: number
}

const BookingTypeTag = ({ id }: Props) => {
  const type = useAppSelector(selectBookingTypeById(id))

  if (type === undefined) return <></>

  return (
    <Tag key={type.id} color={type.color}>
      {type.name}
    </Tag>
  )
}

export default BookingTypeTag

export type BookingTypeTagType = { TypeTag: typeof BookingTypeTag }

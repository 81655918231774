import { useIntl } from 'react-intl'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { Organization } from '../../components/organization/Organization'

const OrganizationPage = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle
        title={intl.formatMessage({
          id: LocalizationKeys.Page.Organization.Usage,
        })}
      />
      <Organization.Usage />

      <Organization.Plan />

      <PageTitle
        title={intl.formatMessage({
          id: LocalizationKeys.Page.Financials.Invoices.Title,
        })}
      />
      <Organization.Invoices />
    </>
  )
}

export default OrganizationPage

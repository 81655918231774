import { Card, Col, Row, Space, Statistic } from 'antd'
import { Weather as WeatherModel } from '../../models/Weather'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { useTenantSettings } from '../../utils/hooks/useTenantSettings'

interface Props {
  model: WeatherModel | undefined
  isLoading?: boolean
  condensed?: boolean
}

export const WeatherCard = ({
  model,
  isLoading = false,
  condensed = false,
}: Props) => {
  const intl = useIntl()
  const { tempSymbol, windSymbol } = useTenantSettings()

  const onlyNightIcon = (str: string) => {
    const re = /d/gi
    return str.replace(re, 'n')
  }

  const getValue = (key: keyof WeatherModel): string =>
    model && model[key] !== undefined && model[key] !== null
      ? model[key]!.toString()
      : '--'

  return (
    <Card
      loading={isLoading}
      styles={{
        body: !condensed
          ? { padding: 20, paddingTop: 10, paddingBottom: 10 }
          : {},
      }}
    >
      <Space
        className="w-full"
        direction={condensed ? 'horizontal' : 'vertical'}
        size={condensed ? 20 : undefined}
      >
        <Space direction="horizontal">
          {model && model.conditionIconId && (
            <img
              width={condensed ? 50 : 70}
              src={`https://openweathermap.org/img/wn/${onlyNightIcon(
                model.conditionIconId
              )}@4x.png`}
            />
          )}
          {!condensed && (
            <Space direction="vertical">
              <Statistic
                title={intl.formatMessage({
                  id: LocalizationKeys.Components.Weather.Title,
                })}
                value={getValue('conditionName')}
              />
            </Space>
          )}
        </Space>
        <Row gutter={40}>
          <Col>
            <Statistic
              title={intl.formatMessage({
                id: LocalizationKeys.Components.Weather.Temp,
              })}
              value={getValue('temp')}
              suffix={tempSymbol}
            />
          </Col>
          <Col>
            <Statistic
              title={intl.formatMessage({
                id: LocalizationKeys.Components.Weather.Humm,
              })}
              value={getValue('humidity')}
              suffix={'%'}
            />
          </Col>
          <Col>
            <Statistic
              title={intl.formatMessage({
                id: LocalizationKeys.Components.Weather.Wind,
              })}
              value={getValue('windSpeed')}
              suffix={windSymbol}
            />
          </Col>
          <Col>
            <Statistic
              title={intl.formatMessage({
                id: LocalizationKeys.Components.Weather.Rain,
              })}
              value={getValue('rain')}
              suffix={'mm/h'}
            />
          </Col>
        </Row>
      </Space>
    </Card>
  )
}

export type WeatherCardType = { Card: typeof WeatherCard }

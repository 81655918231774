import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchPaginatedDispatch,
} from '../utils/helpers/SBAPIHelper'
import { Comment } from '../models/Comment'
import { COMMENTS_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { commentSchema } from '../models/schema'
import { TableParams } from '../models/TableParams'
import { SBRMType } from '../modules/sbrm/SBRMModel'

const initialState: SBState<Comment> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: {
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },
}

const slice = createSlice({
  name: 'comment',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getCommentsSuccess,
  setQuery: setCommentQuery,
  reset: resetCommentState,
  resetQueryAndIds: resetCommentQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: Comment }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectComments = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedComment = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectCommentById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectCommentsByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedComment =
  (Comment: Comment | number) => async (dispatch: AppDispatch) =>
    dispatch(slice.actions.setSelectedId(getIdOrModelId<Comment>(Comment)))

export const getComments = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<Comment>(
    COMMENTS_URL,
    params,
    [commentSchema],
    slice.actions
  )

export const createTextComment = (
  entity: SBRMType,
  entityId: number,
  body: string,
  usersMentioned: number[]
) =>
  createComment({
    type: 'text',
    entity: entity,
    entityId: entityId,
    body: body,
    usersMentioned: usersMentioned,
  })

export const createGIFComment = (
  entity: SBRMType,
  entityId: number,
  body: string
) =>
  createComment({
    type: 'GIF',
    entity: entity,
    entityId: entityId,
    body: body,
    usersMentioned: [],
  })

const createComment = (params: {
  type: 'text' | 'GIF'
  entity: SBRMType
  entityId: number
  body: string
  usersMentioned: number[]
}) => SBAPICreate<any>(params, COMMENTS_URL, commentSchema, slice.actions)

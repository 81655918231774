import FullCalendar, {
  DateSelectArg,
  EventClickArg,
  EventDropArg,
} from '@fullcalendar/react'
import { Card } from 'antd'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import interactionPlugin, {
  EventResizeDoneArg,
} from '@fullcalendar/interaction'
import dayjs from 'dayjs'
import allLocales from '@fullcalendar/core/locales-all'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { MetaDataKey } from '../../models/MetaData'
import { useEffect, useRef, useState } from 'react'
import { selectEventById } from '../../reducers/EventReducer'
import {
  getStagesForEventWithId,
  selectStages,
} from '../../reducers/StageReducer'
import { TableParams } from '../../models/TableParams'
import { initialQuery } from '../../utils/helpers/crud/models'
import {
  getPerformances,
  selectPerformances,
  setPerformanceQuery,
  updatePerformance,
} from '../../reducers/PerformanceReducer'
import { Performance } from '../../components/performance/Performance'
import { useHasAccess } from '../../components/HasAccess'
import { AlelaPermission } from '../../utils/permissions'
import { Event } from '../../models/Event'
import { addUrlParams } from '../../modules/sbrm/UrlHelper'
import { useSearchParams } from 'react-router-dom'
import { useLang } from '../../i18n/useLang'

interface Props {
  eventId?: number
  eventModel?: Event
}

export const Timetable = ({ eventId, eventModel }: Props) => {
  const dispatch = useAppDispatch()
  const { hasAccess } = useHasAccess()
  const { selectedLocale } = useLang()
  const [searchParams, setSearchParams] = useSearchParams()
  const calendarRef = useRef<any | null>(null)

  const event = eventModel
    ? eventModel
    : useAppSelector(selectEventById(eventId ?? 0))

  const stages = useAppSelector(selectStages()).map((stage) => ({
    id: String(stage.id),
    title: stage.name,
  }))
  const performances = useAppSelector(selectPerformances()).map(
    (performance) => ({
      id: performance.id.toString(),
      title: 'hassid',
      start: dayjs(performance.start).format('YYYY-MM-DD HH:mm'),
      end: dayjs(performance.end).format('YYYY-MM-DD HH:mm'),
      resourceId: performance.stage.toString(),
      extendedProps: { performance: performance.id },
      color: '#5d5df633',
      borderColor: '#5d5df6',
    })
  )

  const { isLoading } = useAppSelector((state) => state.performance)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const slotMaxTime = () => {
    if (!event) {
      return '23:59'
    }
    /**
     * Starting from event start
     * We need to compute the max time that will be displayed
     * To display a time the day after the event start date we must return a time superior to 24h
     * Exemple: Event 22:00 -> 06:00 the next day => slotMinTime: 22:00 slotMaxTime 30:00
     */
    const startHour = dayjs(event.start_date).hour()
    const startMinute = dayjs(event.start_date).minute()
    const diffToEndInMinutes = dayjs(event.end_date).diff(
      event?.start_date,
      'minute'
    )
    const hoursToAdd = Math.floor(diffToEndInMinutes / 60)
    const minutesToAdd = diffToEndInMinutes % 60

    const sumHours = startHour + hoursToAdd
    const sumMinutes = startMinute + minutesToAdd
    if (sumMinutes < 60) {
      return `${sumHours.toString().padEnd(2, '0')}:${sumMinutes
        .toString()
        .padEnd(2, '0')}`
    }

    const finalMinutes = sumMinutes % 60
    const finalHours = sumHours + Math.floor(sumMinutes / 60)

    return `${finalHours.toString().padEnd(2, '0')}:${finalMinutes
      .toString()
      .padEnd(2, '0')}`
  }

  const handleDateSelect = (arg: DateSelectArg) => {
    addUrlParams(
      {
        action: 'create',
        entity: SBRMType.performance,
      },
      [
        { key: MetaDataKey.eventId, value: event?.id },
        { key: MetaDataKey.venuesId, value: event?.venues ?? [] },
        {
          key: MetaDataKey.selectedFromDateTime,
          value: dayjs(arg.start).toISOString(),
        },
        {
          key: MetaDataKey.selectedToDateTime,
          value: dayjs(arg.end).toISOString(),
        },
        {
          key: MetaDataKey.stageId,
          value: arg.resource ? arg.resource.id : undefined,
        },
      ],
      setSearchParams
    )
  }

  const handlePerformanceClick = (arg: EventClickArg) => {
    addUrlParams(
      {
        action: 'update',
        entity: SBRMType.performance,
        entityId: arg.event.extendedProps.performance.toString(),
      },
      [{ key: MetaDataKey.venuesId, value: event?.venues ?? [] }],
      setSearchParams
    )
  }

  const updatePerformanceFromArg = (arg: EventDropArg | EventResizeDoneArg) =>
    dispatch(
      updatePerformance({
        id: arg.event.extendedProps.performance,
        start: dayjs(arg.event.start).toISOString(),
        end: dayjs(arg.event.end).toISOString(),
        stage: Number(arg.event.getResources()[0].id),
      })
    )

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      return
    }
    setIsFirstRender(false)

    if (event === undefined) return

    const stageBaseQuery: TableParams = {
      ...initialQuery,
      pagination: { current: 1, pageSize: 10000 },
    }
    dispatch(getStagesForEventWithId(event.id, stageBaseQuery))

    const baseQuery: TableParams = {
      ...initialQuery,
      filters: { events: [event.id] },
      pagination: { current: 1, pageSize: 10000 },
    }
    dispatch(setPerformanceQuery(baseQuery))
    dispatch(getPerformances(baseQuery))
  }, [SBRMIsOpen])

  return (
    <Card
      bordered={false}
      styles={{ body: { padding: '5px 15px' } }}
      loading={isLoading && !performances.length}
    >
      <FullCalendar
        ref={calendarRef}
        locales={allLocales}
        locale={selectedLocale}
        //height={800}
        resources={stages}
        events={performances}
        editable={hasAccess([AlelaPermission.editPerformance])}
        selectable={hasAccess([AlelaPermission.createPerformance])}
        allDaySlot={false}
        datesAboveResources={true}
        eventDurationEditable={true}
        select={handleDateSelect}
        slotDuration={{ minutes: 15 }}
        slotLabelInterval={'00:30:00'}
        initialDate={dayjs(event?.start_date).toDate()}
        slotMinTime={dayjs(event?.start_date).format('HH:mm')}
        slotMaxTime={slotMaxTime()}
        initialView="resourceTimeGridDay"
        plugins={[resourceTimeGridPlugin, interactionPlugin]}
        schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
        headerToolbar={{
          left: '',
          center: '',
          right: '',
        }}
        eventContent={(arg) => (
          <Performance.TimetableCell id={arg.event.extendedProps.performance} />
        )}
        eventClick={
          hasAccess([AlelaPermission.editPerformance])
            ? handlePerformanceClick
            : () => {}
        }
        eventDrop={
          hasAccess([AlelaPermission.editPerformance])
            ? updatePerformanceFromArg
            : () => {}
        }
        eventResize={
          hasAccess([AlelaPermission.editPerformance])
            ? updatePerformanceFromArg
            : () => {}
        }
      />
    </Card>
  )
}

export type TimetableType = { Timetable: typeof Timetable }

import { SBModel } from '../utils/helpers/crud/models'

export interface Role extends SBModel {
  name: string
}

const roleNameNotEditable = [
  'admin',
  'general-manager',
  'financial-manager',
  'production-manager',
  'marketing',
  'visitor',
  'support',
]
const isRoleEditable: (role: Role) => boolean = (role) =>
  !roleNameNotEditable.includes(role.name)

export { roleNameNotEditable, isRoleEditable }

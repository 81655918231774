const HELP_CONFIG_KEY = 'alela-help'

interface HelpSettings {
  readArticles: string[]
}

const initialSettings: HelpSettings = {
  readArticles: [],
}

const loadSettings = (): HelpSettings => {
  const ls = localStorage.getItem(HELP_CONFIG_KEY)
  if (!ls) return initialSettings

  try {
    return JSON.parse(ls) as HelpSettings
  } catch (e) {
    return initialSettings
  }
}

const updateSettings = (settings: HelpSettings): void => {
  localStorage.setItem(HELP_CONFIG_KEY, JSON.stringify(settings))
}

const markArticleAsRead = (articleId: string): void => {
  const settings = loadSettings()
  updateSettings({
    ...settings,
    readArticles: [...settings.readArticles, articleId],
  })
}

enum HelpArticle {
  AddAndManageHotelRooms = 'Help-Article-AddAndManageHotelRooms',
  ManageEventStatusAndPermissions = 'Help-Article-ManageEventStatusAndPermissions',
  AddAndManageGroundTransfers = 'Help-Article-AddAndManageGroundTransfers',
  AddAndManageTravels = 'Help-Article-AddAndManageTravels',
  CreateAVenue = 'Help-Article-CreateAVenue',
  AddAndManagePartyCrew = 'Help-Article-AddAndManagePartyCrew',
  CreateAndManageYourEvent = 'Help-Article-CreateAndManageYourEvent',
  AddAndManageTimetable = 'Help-Article-AddAndManageTimetable',
  ShareAFile = 'Help-Article-ShareAFile',
  AddAndManageTechnicalSheets = 'Help-Article-AddAndManageTechnicalSheets',
  ManageTeamAndPermissions = 'Help-Article-ManageTeamAndPermissions',
  AddAndManageContact = 'Help-Article-AddAndManageContact',
  AddContractOnEvent = 'Help-Article-AddContractOnEvent',
  AddAndManageHotels = 'Help-Article-AddAndManageHotels',
  AddAndManageLineUp = 'Help-Article-AddAndManageLineUp',
  HowToAddAnInvoice = 'Help-Article-HowToAddAnInvoice',
  HowToMarkAnInvoiceAsPaid = 'Help-Article-HowToMarkAnInvoiceAsPaid',
  HowToGenerateRoadsheet = 'Help-Article-HowToGenerateRoadsheet',
}
const articles: Record<HelpArticle, string> = {
  [HelpArticle.AddAndManageHotelRooms]: '659e7ce13afe0a1c1e4d18cc',
  [HelpArticle.ManageEventStatusAndPermissions]: '658316a8f84a306f0c62b580',
  [HelpArticle.AddAndManageGroundTransfers]: '658314ab6193703fb330577b',
  [HelpArticle.AddAndManageTravels]: '6583147598851b27c5e42b19',
  [HelpArticle.CreateAVenue]: '658313b6f84a306f0c62b552',
  [HelpArticle.AddAndManagePartyCrew]: '658314e76193703fb3305781',
  [HelpArticle.CreateAndManageYourEvent]: '65831370763e843d84bc7143',
  [HelpArticle.AddAndManageTimetable]: '65a11c61ca9db276442714af',
  [HelpArticle.ShareAFile]: '6583161698851b27c5e42b2a',
  [HelpArticle.AddAndManageTechnicalSheets]: '658314d6ea64e97a8cf9a643',
  [HelpArticle.ManageTeamAndPermissions]: '658316866193703fb3305791',
  [HelpArticle.AddAndManageContact]: '658316366193703fb330578c',
  [HelpArticle.AddContractOnEvent]: '6580a6a36a3ddb6ea4b4b403',
  [HelpArticle.AddAndManageHotels]: '6583149598851b27c5e42b1c',
  [HelpArticle.AddAndManageLineUp]: '65a112bc3afe0a1c1e4d20e8',
  [HelpArticle.HowToAddAnInvoice]: '6580a671f447f475faf768bd',
  [HelpArticle.HowToMarkAnInvoiceAsPaid]: '6580a68a6a3ddb6ea4b4b401',
  [HelpArticle.HowToGenerateRoadsheet]: '6580b3ee6a3ddb6ea4b4b447',
}

const useAlelaHelp = (): {
  settings: HelpSettings
  articles: Record<string, string>
  markArticleAsRead: (articleId: string) => void
} => ({
  settings: loadSettings(),
  articles: articles,
  markArticleAsRead: markArticleAsRead,
})

export default useAlelaHelp
export { HelpArticle }

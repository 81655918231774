import dayjs from 'dayjs'
import { Booking } from '../../models/Booking'
import { Column } from '@ant-design/plots'
import { selectEventsByIds } from '../../reducers/EventReducer'
import { useAppSelector } from '../../reducers/hooks'
import { ColumnConfig } from '@ant-design/plots/es/components/column/index'
import { Datum } from '@ant-design/charts'
import { useTenantSettings } from '../../utils/hooks/useTenantSettings'
import { currencyFormat, getTotalFee } from '@supplement-bacon/alela-uikit'
import { Alert, Space } from 'antd'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'

interface Props {
  bookings: Booking[]
}

export const BookingBarChart = ({ bookings }: Props) => {
  const intl = useIntl()
  const events = useAppSelector(
    selectEventsByIds(bookings.map((b) => b.event ?? 0).filter((e) => e > 0))
  )
  const { currency } = useTenantSettings()

  const data = bookings
    .map((booking) => ({
      price: getTotalFee(booking),
      event: dayjs(
        events.find((e) => e.id === booking.event)?.start_date
      ).format('YYYY-MM-DD'),
    }))
    .sort((a, b) => (dayjs(a.event).isAfter(dayjs(b.event)) ? 1 : -1))

  const config: ColumnConfig = {
    tooltip: {
      title: 'Infos',
      formatter: (datum: Datum) => {
        return {
          name: datum.event,
          value: currencyFormat(datum.price, currency),
        }
      },
    },
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: false,
      },
    },
    color: '#5d5df6',
  }
  return (
    <Space direction="vertical" className="w-full">
      <Column
        data={data}
        xField="event"
        yField="price"
        label={{
          text: (d: any) => currencyFormat(d.price, currency),
          fill: 'white',
          fillOpacity: 1,
          fontWeight: 'bolder',
          offset: 20,
        }}
        style={{
          fill: '#5d5df6',
          opacity: 1,
          fontWeight: 'bold',
        }}
        tooltip={(datum: Datum) => ({
          color: '#5d5df6',
          name: datum.event,
          value: currencyFormat(datum.price, currency),
        })}
        axis={{
          x: {
            labelFill: 'rgba(200,200,200)',

            line: true,
            lineLineWidth: 2,
            lineStroke: 'white',
            lineLineDash: [0, 0],

            tick: true,
            tickLineWidth: 2,
            tickStroke: 'white',
          },
          y: {
            labelFill: 'rgba(200,200,200)',
            grid: true,
            gridLineWidth: 2,
            gridStrokeOpacity: 1,
            gridLineDash: null,
            gridStroke: 'white',
            gridStrokeWidth: 1,
          },
        }}
      />
      <Alert
        description={intl.formatMessage({
          id: LocalizationKeys.Components.Booking.BarChart.CurencyDisclaimer,
        })}
        type="warning"
        style={{ padding: '2px 10px' }}
      />
    </Space>
  )
}

export type BookingBarChartType = { BarChart: typeof BookingBarChart }

import { FC } from 'react'
import { Roles, RolesType } from './Roles'
import { Plan, PlanType } from './Plan'
import {
  StripePortalButton,
  StripePortalButtonType,
} from './StripePortalButton'
import { Invoices, InvoicesType } from './Invoices'
import { Usage, UsageType } from './Usage'

export const Organization: FC &
  PlanType &
  UsageType &
  RolesType &
  InvoicesType &
  StripePortalButtonType = (): JSX.Element => <></>

Organization.Plan = Plan
Organization.Usage = Usage
Organization.Roles = Roles
Organization.Invoices = Invoices
Organization.StripePortalButton = StripePortalButton

import { Card, Space, Typography } from 'antd'
import { useAppSelector } from '../../reducers/hooks'
import { Payment } from '../../models/Payment'
import { selectPaymentById } from '../../reducers/PaymentReducer'
import dayjs from 'dayjs'
import { Actions } from '../../modules/sbrm/components/Actions'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { File } from '../file/File'
import { CurrencyFormat } from '@supplement-bacon/alela-uikit'
import { useTenantSettings } from '../../utils/hooks/useTenantSettings'
import { selectCurrencyById } from '../../reducers/CurrencyReducer'

const { Text } = Typography
interface Props {
  id?: number
  model?: Payment
}

export const PaymentCard = ({ id, model }: Props) => {
  const item = model ? model : useAppSelector(selectPaymentById(id!))
  const currency = useAppSelector(selectCurrencyById(item?.currency ?? 0))

  if (item === undefined || currency === undefined) {
    return <></>
  }

  return (
    <Card
      className="w-full"
      style={{ border: '1px solid #274916', background: '#162312' }}
      styles={{ body: { padding: 15 } }}
    >
      <Space className="w-full" style={{ justifyContent: 'space-between' }}>
        <Space direction="vertical" size={0}>
          <Text type="success" strong>
            +{' '}
            <CurrencyFormat
              amount={item.amount}
              currencySymbol={currency}
              type="success"
              strong
            />
          </Text>
          <Text type="success">
            {dayjs(item.date).format('YYYY-MM-DD')} by {item.type}
          </Text>
        </Space>
        <Space>
          {item.pop && <File.DownloadButton id={item.pop} />}
          <Actions
            actions={['delete']}
            entity={SBRMType.payment}
            entityId={item.id}
          />
        </Space>
      </Space>
    </Card>
  )
}

export type PaymentCardType = { Card: typeof PaymentCard }

import {
  Alert,
  App,
  Button,
  Card,
  Col,
  Form,
  FormInstance,
  InputNumber,
  Row,
  Space,
  Steps,
  Watermark,
} from 'antd'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import TextArea from 'antd/es/input/TextArea'
import { ReactNode, useEffect, useState } from 'react'
import { Reporting } from './Reporting'
import { Event } from '../../models/Event'
import ReportWizardStep1 from './wizard/step-1'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import {
  CreateReport,
  CreateReportItem,
  UpdateReport,
} from '../../models/Report'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { createReport, updateReport } from '../../reducers/ReportReducer'
import { fieldFormatter } from '../../utils/formatters'
import { numberRule, requiredRule } from '../../utils/rules'
import { Weather } from '../weather/Weather'
import { Weather as WeatherModel } from '../../models/Weather'
import { WEATHER_URL } from '../../utils/urls'
import axios from 'axios'
import dayjs from 'dayjs'
import { selectVenueById } from '../../reducers/VenueReducer'

export enum ReportingWizardForms {
  RevenuesAndCosts = 'revenues-and-costs',
  Misc = 'misc',
  Submit = 'submit',
}

interface Props {
  event: Event
  initialValue?: UpdateReport
  onReportCreated: () => void
}

export const Wizard = ({ event, initialValue, onReportCreated }: Props) => {
  const intl = useIntl()
  const { message } = App.useApp()
  const dispatch = useAppDispatch()
  const [formStep1] = Form.useForm()
  const [formStep2] = Form.useForm()

  const [current, setCurrent] = useState<number>(0)
  const [isLoading, setIsloading] = useState<boolean>(false)
  const [reportData, setReportData] = useState<CreateReport>(
    initialValue
      ? initialValue
      : {
          event: event.id,
          attendees: 0,
          items: [],
          weather: undefined,
        }
  )

  const [weatherIsLoading, setWeatherIsLoading] = useState<boolean>(false)

  const venue = useAppSelector(
    selectVenueById((event.venues && event.venues[0]) ?? 0)
  )

  const filterNonFilledFields = (
    items: CreateReportItem[]
  ): CreateReportItem[] => items.filter((i) => i.amount && i.amount > 0)

  const steps: { title: ReactNode; content: ReactNode; form?: FormInstance }[] =
    [
      {
        title: intl.formatMessage({
          id: LocalizationKeys.Components.Reporting.Wizard.CostsAndRevenues,
        }),
        form: formStep1,
        content: <ReportWizardStep1 form={formStep1} />,
      },
      {
        title: intl.formatMessage({
          id: LocalizationKeys.Components.Reporting.Wizard.Misc,
        }),
        form: formStep2,
        content: (
          <Space
            direction="vertical"
            className="w-full"
            style={{ paddingBottom: 40 }}
          >
            <PageTitle
              title={intl.formatMessage({
                id: LocalizationKeys.Components.Reporting.Wizard.Misc,
              })}
              level={5}
            />
            <Row gutter={16}>
              <Col span={12}>
                <Form
                  form={formStep2}
                  name={ReportingWizardForms.Misc}
                  layout="vertical"
                >
                  <Form.Item name="attendees" rules={[numberRule(intl)]}>
                    <InputNumber
                      min={0}
                      className="w-full"
                      formatter={fieldFormatter}
                      placeholder={intl.formatMessage({
                        id: LocalizationKeys.Components.Reporting.Overview
                          .Attendees,
                      })}
                      addonAfter={'Pax'}
                    />
                  </Form.Item>
                  <Form.Item name="notes">
                    <TextArea
                      placeholder={intl.formatMessage({
                        id: LocalizationKeys.Misc.Form.Notes,
                      })}
                      rows={4}
                    />
                  </Form.Item>
                </Form>
              </Col>
              <Col span={12}>
                <Weather.Card
                  model={reportData.weather}
                  isLoading={weatherIsLoading}
                />
              </Col>
            </Row>
          </Space>
        ),
      },
      {
        title: intl.formatMessage({
          id: LocalizationKeys.Components.Reporting.Wizard.Preview,
        }),
        content: (
          <>
            {filterNonFilledFields(reportData.items).length === 0 && (
              <Row style={{ paddingTop: 40, paddingBottom: 40 }}>
                <Col span={24}>
                  <Alert
                    showIcon
                    type="warning"
                    description={intl.formatMessage({
                      id: LocalizationKeys.Components.Reporting.Wizard
                        .AtLeast1Filled,
                    })}
                  />
                </Col>
              </Row>
            )}
            {filterNonFilledFields(reportData.items).length > 0 && (
              <Watermark
                gap={[50, 50]}
                content="Preview"
                font={{
                  color: '#6b6b6b33',
                }}
              >
                <div style={{ opacity: 0.5, pointerEvents: 'none' }}>
                  <Reporting.Preview reportData={reportData} />
                </div>
              </Watermark>
            )}
          </>
        ),
      },
    ]

  const items = steps.map((item) => ({ key: item.title, title: item.title }))

  const next = () => {
    steps[current].form
      ?.validateFields()
      .then(() => {
        steps[current].form?.submit()
        setCurrent(current + 1)
      })
      .catch(() => {})
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const onFormFinish = (
    name: string,
    { values, forms }: { values: Record<string, any>; forms: any }
  ) => {
    if (name === ReportingWizardForms.RevenuesAndCosts) {
      setReportData({
        ...reportData,
        items: Object.entries(values).map(
          ([itemId, amount]) =>
            ({
              id: Number(itemId),
              amount: amount,
            } as CreateReportItem)
        ),
      })
    } else if (name === ReportingWizardForms.Misc) {
      setReportData({
        ...reportData,
        notes: values.notes,
        attendees: values.attendees,
      })
    } else if (name === ReportingWizardForms.Submit) {
      setIsloading(true)

      const reportSanitized: CreateReport | UpdateReport = {
        ...reportData,
        items: filterNonFilledFields(reportData.items),
        //Weather here
      }
      dispatch(
        initialValue
          ? updateReport(reportSanitized as UpdateReport)
          : createReport(reportSanitized)
      )
        .then((response) => {
          onReportCreated()
        })
        .catch((error) => {
          message.error(
            intl.formatMessage({
              id: LocalizationKeys.Components.Reporting.Wizard.Error,
            })
          )
        })
        .finally(() => setIsloading(false))
    }
  }

  useEffect(() => {
    if (venue != undefined) {
      setWeatherIsLoading(true)
      axios
        .get(WEATHER_URL, {
          params: {
            latitude: venue.address.latitude,
            longitude: venue.address.longitude,
            timestamp: dayjs(event.start_date).unix(),
          },
        })
        .then((response) => {
          const weather = response.data.data as WeatherModel
          setReportData({
            ...reportData,
            weather: weather,
          })
          setWeatherIsLoading(false)
        })
        .catch((error) => setWeatherIsLoading(false))
    }

    initialValue?.items.forEach((item) => {
      formStep1.setFieldValue(item.id, item.amount)
    })

    formStep2.setFieldsValue(initialValue)
  }, [initialValue])

  return (
    <Card>
      <Steps current={current} items={items} />
      <Form.Provider onFormFinish={onFormFinish}>
        {steps[current].content}

        <Space
          style={{
            marginTop: 0,
            width: '100%',
            justifyContent: 'flex-end',
          }}
        >
          {current > 0 && (
            <Button onClick={prev} disabled={isLoading}>
              {intl.formatMessage({ id: LocalizationKeys.Misc.Form.Previous })}
            </Button>
          )}
          <div>
            {current < steps.length - 1 && (
              <Button type="primary" onClick={next}>
                {intl.formatMessage({ id: LocalizationKeys.Misc.Form.Next })}
              </Button>
            )}
            {current === steps.length - 1 && (
              <Form name={ReportingWizardForms.Submit}>
                <Button
                  className="w-full"
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  disabled={
                    filterNonFilledFields(reportData.items).length === 0
                  }
                >
                  {intl.formatMessage({
                    id: initialValue
                      ? LocalizationKeys.Misc.Form.Update
                      : LocalizationKeys.Misc.Form.Add,
                  })}
                </Button>
              </Form>
            )}
          </div>
        </Space>
      </Form.Provider>
    </Card>
  )
}

export type WizardType = { Wizard: typeof Wizard }

import { App, Button, Tooltip } from 'antd'
import { useState } from 'react'
import { RetweetOutlined } from '@ant-design/icons'
import { ExternalAdvancingLink } from '../../../models/ExternalAdvancing'
import { resendAdvancingLink } from '../../../reducers/ExternalAdvancingLinkReducer'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

interface Props {
  link: ExternalAdvancingLink
}
const ALMResend = ({ link }: Props) => {
  const intl = useIntl()
  const { message } = App.useApp()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  return (
    <Tooltip
      title={intl.formatMessage({
        id: LocalizationKeys.ExternalAdvancingManager.Cell.Resendinvitation,
      })}
    >
      <Button
        size="small"
        type="text"
        loading={isLoading}
        icon={<RetweetOutlined />}
        onClick={() => {
          setIsLoading(true)
          resendAdvancingLink(link)
            .then(() => {
              message.open({
                type: 'success',
                content: intl.formatMessage({
                  id: LocalizationKeys.ExternalAdvancingManager.ResendSuccess,
                }),
              })
            })
            .finally(() => setIsLoading(false))
        }}
      />
    </Tooltip>
  )
}

export default ALMResend

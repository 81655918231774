import { Card, Empty } from 'antd'
import { useIntl } from 'react-intl'
import { File } from '../../models/File'
import { useEffect, useState } from 'react'
import { SBRMType } from '../sbrm/SBRMModel'
import { getFile } from './helpers/FileHelper'
import { SBRMTypeInfos } from '../sbrm/SBRMTypeInfos'
import { useAppSelector } from '../../reducers/hooks'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { FileViewer as UIKitFileViewer } from '@supplement-bacon/alela-uikit'

interface Props {
  entity: SBRMType
  style?: React.CSSProperties
}

const FileViewer = ({ entity, style }: Props) => {
  const intl = useIntl()
  const [resourceFile, setResourceFile] = useState<File | undefined>(undefined)

  // Get selected resource based on the entity
  const resourceId = useAppSelector(
    (state) => state[SBRMTypeInfos[entity].reducerName].selectedId
  )
  const resource = useAppSelector(
    (state) => state[SBRMTypeInfos[entity].reducerName].items[resourceId!]
  )
  const { items: files } = useAppSelector((state) => state.file)
  const { isOpen: SBRMisOpen } = useAppSelector((state) => state.SBRM)

  useEffect(() => {
    if (!SBRMisOpen || !resource) return // When closing SBRM do not set the resource
    setResourceFile(getFile(entity, resource, files))
  }, [SBRMisOpen, resource])

  useEffect(() => {
    /**
     * Reset the selected resource when the Drawer is closed
     */
    if (!SBRMisOpen) {
      setResourceFile(undefined)
    }
  }, [SBRMisOpen])

  return (
    <>
      {resourceFile !== undefined && (
        <UIKitFileViewer.ViewerDispatcher
          file={resourceFile}
          isOpen={SBRMisOpen}
          texts={{
            download: intl.formatMessage({
              id: LocalizationKeys.FileViewer.Download,
            }),
            pdf: {
              error: intl.formatMessage({
                id: LocalizationKeys.FileViewer.PDFError,
              }),
            },
          }}
        />
      )}
      {resourceFile === undefined && (
        <Card
          bordered={false}
          style={{
            width: '300px',
            top: 'calc(50vh - 57px)',
            left: '50%',
            transform: 'translate(-50%,-50%)',
          }}
        >
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={intl.formatMessage(
              { id: LocalizationKeys.Components.SBTable.Empty },
              { entity: intl.formatMessage({ id: `Entity-${entity}` }) }
            )}
          />
        </Card>
      )}
    </>
  )
}

export default FileViewer

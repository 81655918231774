import axios from 'axios'
import { NOTIFICATIONS_URL } from './urls'
import { isMobile } from 'react-device-detect'
import { FirebaseOptions, initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

const stagingConfig: FirebaseOptions = {
  apiKey: 'AIzaSyB_sE1peO6fuAdJ6cX8g5-UCahxv_7ZL-s',
  authDomain: 'alela-staging.firebaseapp.com',
  projectId: 'alela-staging',
  storageBucket: 'alela-staging.appspot.com',
  messagingSenderId: '220892024418',
  appId: '1:220892024418:web:79d7cf2e50688c0aa30b3b',
  measurementId: 'G-SLV0RQ1V3B',
}

const productionConfig: FirebaseOptions = {
  apiKey: 'AIzaSyB_1wgqinvZg3DKjDjtMdHjMLLMWX2jDyI',
  authDomain: 'alela-production.firebaseapp.com',
  projectId: 'alela-production',
  storageBucket: 'alela-production.appspot.com',
  messagingSenderId: '500253031421',
  appId: '1:500253031421:web:e95bbc693c1074f85b7794',
  measurementId: 'G-CV22MZQ5PS',
}

initializeApp(
  window.location.origin.includes('alela.app')
    ? productionConfig
    : stagingConfig
)

const messaging = getMessaging()

export const requestForToken = () =>
  getToken(messaging, {
    vapidKey: process.env.REACT_APP_FCM_VAPID_KEY,
  }).catch((err) => {
    console.error('An error occurred while retrieving token. ', err)
  })

/**
 * Expose listener
 *
 */
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload)
    })
  })

export const registerDevice = (token: string) =>
  axios.post(`${NOTIFICATIONS_URL}/register`, { fcmToken: token })

export const forgetCurrentDevice = async () => {
  if (isMobile || Notification.permission !== 'granted') {
    return
  }

  const token = await requestForToken()

  if (!token) {
    return
  }

  await axios.post(`${NOTIFICATIONS_URL}/forget`, { fcmToken: token })

  return
}

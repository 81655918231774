import { Alert, Space, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { Artist as ArtistModel } from '../../models/Artist'
import { SBAPIFetch } from '../../utils/helpers/SBAPIHelper'
import { ARTISTS_URL } from '../../utils/urls'
import { TableParams } from '../../models/TableParams'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { CurrencyFormat } from '@supplement-bacon/alela-uikit'
import { useTenantSettings } from '../../utils/hooks/useTenantSettings'

interface FeesInfosProps {
  item: ArtistModel
}

export const FeesInfos = ({ item }: FeesInfosProps) => {
  const intl = useIntl()
  const { currency } = useTenantSettings()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [artist, setArtist] = useState<ArtistModel>(item)

  useEffect(() => {
    setIsLoading(true)
    setArtist(item)

    if (item.hasOwnProperty('last_fee') && item.last_fee !== 0) {
      setIsLoading(false)
      return
    }

    const baseQuery: TableParams = {
      with: ['feesResume'],
    }
    SBAPIFetch<ArtistModel>(`${ARTISTS_URL}/${item.id}`, baseQuery).then(
      (data) => {
        setArtist(data.data)
        setIsLoading(false)
      }
    )
  }, [item])

  return (
    <Alert
      type="success"
      style={{ marginBottom: 20 }}
      description={
        !isLoading ? (
          <Space direction="vertical">
            <li>
              {intl.formatMessage({ id: LocalizationKeys.Page.Artist.LastFee })}
              :{' '}
              <CurrencyFormat
                amount={artist.last_fee}
                currencySymbol={currency}
              />
            </li>
            <li>
              {intl.formatMessage({
                id: LocalizationKeys.Page.Artist.AverageFee,
              })}
              :{' '}
              <CurrencyFormat
                amount={artist.average_fee}
                currencySymbol={currency}
              />
            </li>
            <li>
              {intl.formatMessage({
                id: LocalizationKeys.Page.Artist.MinimumFee,
              })}
              :{' '}
              <CurrencyFormat
                amount={artist.min_fee}
                currencySymbol={currency}
              />
            </li>
            <li>
              {intl.formatMessage({
                id: LocalizationKeys.Page.Artist.MaximumFee,
              })}
              :{' '}
              <CurrencyFormat
                amount={artist.max_fee}
                currencySymbol={currency}
              />
            </li>
          </Space>
        ) : (
          <Space className="w-full text-center">
            <Spin style={{ margin: 'auto' }} />
          </Space>
        )
      }
    />
  )
}

export type FeesInfosType = { FeesInfos: typeof FeesInfos }

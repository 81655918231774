import './App.css'
import { App as ANTDApp, ConfigProvider, theme } from 'antd'
import { Outlet } from 'react-router-dom'
import 'dayjs/locale/fr'
import dayjs from 'dayjs'
import { useLang } from './i18n/useLang'
import frFR from 'antd/locale/fr_FR'

const { defaultAlgorithm, darkAlgorithm } = theme

function App() {
  const { selectedLocale } = useLang()

  /**
   * Setup dayjs locale globally
   */
  dayjs.locale(selectedLocale)

  return (
    <ConfigProvider
      locale={selectedLocale === 'fr' ? frFR : undefined}
      theme={{
        algorithm: darkAlgorithm,
        token: {
          colorPrimary: '#5d5df6',
          colorBgBase: '#000000',
          colorBgLayout: '#1b1a1a',
          colorBgContainer: '#1E1E1E',
        },
      }}
    >
      <ANTDApp>
        <Outlet />
      </ANTDApp>
    </ConfigProvider>
  )
}

export default App

import { commonStyle } from './style'
import { Text, View, StyleSheet } from '@react-pdf/renderer'

interface TableColumn {
  title: string
  key: string
  render?: (record: any) => JSX.Element
}

interface Props {
  columns: TableColumn[]
  data: any[]
}

const Table = ({ columns, data }: Props) => {
  const styles = StyleSheet.create({
    table: {
      width: '100%',
      borderBottom: '1px solid #EEE',
      borderLeft: '1px solid #EEE',
      borderRight: '1px solid #EEE',
    },
    tableHeader: {
      backgroundColor: '#EEE',
    },
    tableRow: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #EEE',
      paddingTop: 8,
      paddingBottom: 8,
    },
    tableCol: {
      width: String(100 / columns.length) + '%',
    },
    tableCell: {
      margin: 'auto',
      fontSize: 10,
      textAlign: 'left',
      width: '100%',
      paddingLeft: 5,
      paddingRight: 5,
      alignItems: 'stretch',
    },
  })

  return (
    <View style={styles.table} wrap={true}>
      <View style={[styles.tableRow, styles.tableHeader]}>
        {columns.map((column) => (
          <View key={column.key} style={styles.tableCol}>
            <Text style={styles.tableCell}>{column.title}</Text>
          </View>
        ))}
      </View>
      {data.map((item) => (
        <View key={item} style={styles.tableRow}>
          {columns.map((column) => (
            <View key={item} style={styles.tableCol}>
              <View style={styles.tableCell}>
                {column.render == undefined && (
                  <Text style={commonStyle.p}>{item[column.key]}</Text>
                )}
                {column.render != undefined && column.render(item)}
              </View>
            </View>
          ))}
        </View>
      ))}
    </View>
  )
}

export default Table

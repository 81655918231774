import { Travel } from '../../models/Travel'
import { useAppSelector } from '../../reducers/hooks'
import { selectTravelById } from '../../reducers/TravelReducer'
import { selectStationById } from '../../reducers/StationReducer'
import { Travel as UIKitTravel } from '@supplement-bacon/alela-uikit'

interface Props {
  id?: number
  model?: Travel
}

export const Infos = ({ id, model }: Props) => {
  const travel = model ? model : useAppSelector(selectTravelById(id!))
  const departure =
    typeof travel?.departure === 'number'
      ? useAppSelector(selectStationById(travel?.departure ?? 0))
      : travel?.departure
  const arrival =
    typeof travel?.arrival === 'number'
      ? useAppSelector(selectStationById(travel?.arrival ?? 0))
      : travel?.arrival

  if (travel === undefined || departure === undefined || arrival === undefined)
    return <></>

  return (
    <UIKitTravel.Infos
      travel={travel}
      departure={departure}
      arrival={arrival}
    />
  )
}

export type InfosType = { Infos: typeof Infos }

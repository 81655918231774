import {
  Button,
  Col,
  Row,
  Space,
  Spin,
  TablePaginationConfig,
  Tooltip,
} from 'antd'
import {
  getInvitations,
  selectInvitations,
  setInvitationQuery,
} from '../../../reducers/InvitationReducer'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SBTable from '../../../components/SBTable'
import relativeTime from 'dayjs/plugin/relativeTime'
import HasAccess from '../../../components/HasAccess'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import { AlelaPermission } from '../../../utils/permissions'
import { initialQuery } from '../../../utils/helpers/crud/models'
import { Actions } from '../../../modules/sbrm/components/Actions'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import { Invitation as InvitationModel } from '../../../models/Invitation'
import { extractSortDataFromSorterResult } from '../../../utils/table/sorter'
import { ColumnsType, FilterValue, SorterResult } from 'antd/es/table/interface'
import { Invitation } from '../../../components/invitation/Invitation'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

const InvitesPage = () => {
  dayjs.extend(relativeTime)

  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const invitations = useAppSelector(selectInvitations())
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)
  const { query, isLoading } = useAppSelector((state) => state.invitation)

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const columns: ColumnsType<InvitationModel> = [
    {
      key: 'email',
      title: intl.formatMessage({ id: LocalizationKeys.Misc.Form.Email }),
      render: (record: InvitationModel) => record.email,
    },
    {
      key: 'date',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.CRM.Invitations.InvitedAt,
      }),
      render: (record: InvitationModel) => (
        <Tooltip title={record.createdAt}>
          {dayjs(record.createdAt).fromNow()}
        </Tooltip>
      ),
    },
    {
      key: 'actions',
      title: intl.formatMessage({ id: LocalizationKeys.Misc.Table.Actions }),
      render: (record: InvitationModel) => (
        <Space direction="horizontal" size="large">
          <Invitation.ResendEmail id={record.id} />
          <Actions
            entity={SBRMType.invitation}
            entityId={record.id}
            actions={['delete']}
          />
        </Space>
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<InvitationModel> | SorterResult<InvitationModel>[]
  ) => {
    const newQuery = {
      ...query,
      pagination,
      filters,
      ...extractSortDataFromSorterResult<InvitationModel>(sorter),
    }

    dispatch(setInvitationQuery(newQuery))
    dispatch(getInvitations(newQuery))
  }

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      // Fetch invitation on init and when SBRM is closed
      return
    }
    setIsFirstRender(false)

    const newQuery = {
      ...initialQuery,
    }
    dispatch(setInvitationQuery(newQuery))
    dispatch(getInvitations(newQuery))
  }, [SBRMIsOpen])

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Button
          type="text"
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate('/crm/organization-team')}
        >
          {intl.formatMessage({
            id: LocalizationKeys.Page.CRM.Invitations.Back,
          })}
        </Button>
      </Col>

      <HasAccess permissions={[AlelaPermission.listInvitation]}>
        <Col span={24}>
          <PageTitle
            title={intl.formatMessage({
              id: LocalizationKeys.Page.CRM.Invitations.Title,
            })}
            toolbar={
              <Space dir="horizontal">
                {isLoading && <Spin />}
                <Actions actions={['create']} entity={SBRMType.invitation} />
              </Space>
            }
          />
          <SBTable
            entity={SBRMType.invitation}
            loading={isLoading && !invitations.length}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={invitations}
            pagination={query.pagination}
            onChange={handleTableChange}
          />
        </Col>
      </HasAccess>
    </Row>
  )
}

export default InvitesPage

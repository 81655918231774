import {
  createInvitation,
  deleteInvitation,
} from '../../../reducers/InvitationReducer'
import Create from './Create'
import Delete from './Delete'
import { Alert, Form } from 'antd'
import { useSBRMData } from '../hooks'
import { forwardRef, Ref, useImperativeHandle } from 'react'
import { SBRMFormInterface, SBRMFormProps } from '../SBRMModel'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'

const InvitationForm = forwardRef(
  ({ isNested }: SBRMFormProps, ref: Ref<SBRMFormInterface>) => {
    useImperativeHandle(ref, () => ({
      handleCreate,
      handleUpdate,
      handleDelete,
      handleReset,
    }))

    const dispatch = useAppDispatch()
    const { action } = useSBRMData(isNested)
    const { error } = useAppSelector((state) => state.invitation)

    const [createForm] = Form.useForm()
    const [updateForm] = Form.useForm()
    const [deleteForm] = Form.useForm()

    const handleCreate = async () =>
      await createForm.validateFields().then(async (result) =>
        dispatch(createInvitation(createForm.getFieldsValue()))
          .then(() => {
            createForm.resetFields()
            return true
          })
          .catch((e: any) => {
            throw e
          })
      )

    const handleUpdate = async () => true

    const handleDelete = async () =>
      await deleteForm.validateFields().then(async (result) =>
        dispatch(deleteInvitation(deleteForm.getFieldsValue()))
          .then(() => {
            deleteForm.resetFields()
            return true
          })
          .catch((e: any) => {
            throw e
          })
      )

    const handleReset = () => {
      createForm.resetFields()
      updateForm.resetFields()
      deleteForm.resetFields()
    }

    return (
      <>
        {action === 'create' && <Create form={createForm} />}
        {action === 'update' && <></>}
        {action === 'delete' && <Delete form={deleteForm} />}
        {error && <Alert message={String(error)} type="error" />}
      </>
    )
  }
)

InvitationForm.displayName = 'InvitationForm'

export default InvitationForm

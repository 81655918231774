import { Tag } from 'antd'
import { useAppSelector } from '../../reducers/hooks'
import { selectMarketingTaskStatusById } from '../../reducers/MarketingTaskStatusReducer'
import { MarketingTaskStatus } from '../../models/MarketingTask'

interface Props {
  id?: number
  model?: MarketingTaskStatus
}

export const MarketingTaskStatusTag = ({ id, model }: Props) => {
  const status = model
    ? model
    : useAppSelector(selectMarketingTaskStatusById(id ?? 0))

  return status ? <Tag color={status.color}>{status.name}</Tag> : <></>
}

export type MarketingTaskStatusTagType = { Tag: typeof MarketingTaskStatusTag }

import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { ContactType } from '../../models/Contact'
import { Card, Tag } from 'antd'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import {
  getContactTypes,
  selectContactTypes,
  setContactTypeQuery,
} from '../../reducers/ContactTypeReducer'
import { Actions } from '../../modules/sbrm/components/Actions'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import SBTable from '../../components/SBTable'
import { extractSortDataFromSorterResult } from '../../utils/table/sorter'
import LocalizationKeys from '../../i18n/LocalizationKeys'

const ContactTypePage = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const items = useAppSelector(selectContactTypes())
  const { query, isLoading } = useAppSelector((state) => state.contactType)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const columns: ColumnsType<ContactType> = [
    {
      key: 'name',
      title: intl.formatMessage({ id: LocalizationKeys.Misc.Table.Name }),
      render: (record: ContactType) => record.name,
    },
    {
      key: 'color',
      title: intl.formatMessage({ id: LocalizationKeys.Misc.Table.Color }),
      render: (record: ContactType) => <Tag>{record.color}</Tag>,
    },
    {
      key: 'icon',
      title: intl.formatMessage({ id: LocalizationKeys.Misc.Table.Icon }),
      render: (record: ContactType) => <Tag>{record.icon}</Tag>,
    },
    {
      key: 'actions',
      align: 'right',
      render: (record: ContactType) => (
        <Actions
          actions={['update', 'delete']}
          entity={SBRMType.contactType}
          entityId={record.id}
        />
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<ContactType> | SorterResult<ContactType>[]
  ) => {
    const newQuery = {
      pagination,
      filters,
      ...extractSortDataFromSorterResult(sorter),
    }
    dispatch(setContactTypeQuery(newQuery))
    dispatch(getContactTypes(newQuery))
  }

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      return
    }
    setIsFirstRender(false)
    // Trigger on init and when SBRM is closed
    dispatch(getContactTypes(query))
  }, [dispatch, SBRMIsOpen])

  return (
    <Card
      bordered={false}
      styles={{ body: { padding: 0 } }}
      title={
        <PageTitle
          title={intl.formatMessage({
            id: LocalizationKeys.Page.Settings.ContactTypes,
          })}
          level={5}
          toolbar={
            <Actions actions={['create']} entity={SBRMType.contactType} />
          }
        />
      }
    >
      <SBTable
        entity={SBRMType.contactType}
        scroll={{ x: 600 }}
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={items}
        pagination={query.pagination}
        loading={isLoading}
        onChange={handleTableChange}
      />
    </Card>
  )
}

export default ContactTypePage

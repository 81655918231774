import { Avatar, Space, Typography } from 'antd'
import { useAppSelector } from '../../reducers/hooks'
import { selectSupplierById } from '../../reducers/SupplierReducer'
import { Supplier } from '../../models/Supplier'
import { ConditionalLinkWrapper } from '@supplement-bacon/alela-uikit'

const { Text } = Typography

interface Props {
  id?: number
  model?: Supplier
  linkToDetails?: boolean
}

export const SupplierAvatar = ({ id, model, linkToDetails = false }: Props) => {
  const item = model ? model : useAppSelector(selectSupplierById(id!))

  if (!item) {
    return <></>
  }

  return (
    <ConditionalLinkWrapper
      condition={linkToDetails}
      to={`/crm/suppliers/${item.id}`}
    >
      <Space direction={'horizontal'}>
        <Avatar shape="square" src={item.logo}>
          {item.name.slice(0, 1)}
        </Avatar>
        <Space.Compact direction="vertical">
          <Text>{item.name}</Text>
          <Text type="secondary">{item.registrationNumber}</Text>
        </Space.Compact>
      </Space>
    </ConditionalLinkWrapper>
  )
}

export type SupplierAvatarType = { Avatar: typeof SupplierAvatar }

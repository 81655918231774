import { useIntl } from 'react-intl'
import { Form, FormInstance, Input, DatePicker } from 'antd'
import { requiredRule } from '../../../utils/rules'
import { useAppSelector } from '../../../reducers/hooks'
import { SBAsyncSelect } from '../../../components/custom-inputs/sb-async-select/SBAsyncSelect'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import { MetaDataKey } from '../../../models/MetaData'
import { useEffect } from 'react'
import dayjs from 'dayjs'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

const { RangePicker } = DatePicker

interface Props {
  form: FormInstance
  venuesId: number
  eventId: number
  bookingId?: number
  stageId?: number
  selectedFromDateTime?: Date
  selectedToDateTime?: Date
}

const Create = ({
  form,
  venuesId,
  eventId,
  bookingId,
  stageId,
  selectedFromDateTime,
  selectedToDateTime,
}: Props) => {
  const intl = useIntl()

  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  useEffect(() => {
    if (!SBRMIsOpen) {
      // We want to trigger only the reset
      // when the SBRM opens
      return
    }

    form.setFieldValue('booking', bookingId)
    form.setFieldValue('stage', stageId)
    form.setFieldValue('dates', [
      dayjs(selectedFromDateTime),
      dayjs(selectedToDateTime),
    ])
  }, [SBRMIsOpen])

  return (
    <Form form={form} layout="vertical">
      {!bookingId && (
        <SBAsyncSelect
          type={SBRMType.booking}
          name={'booking'}
          label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Booking })}
          rules={[requiredRule(intl)]}
          metadata={[
            {
              key: MetaDataKey.eventId,
              value: eventId,
            },
          ]}
        />
      )}
      {bookingId && (
        <Form.Item
          name="booking"
          rules={[requiredRule(intl)]}
          className="d-none"
        >
          <Input type="hidden" />
        </Form.Item>
      )}
      {!stageId && (
        <SBAsyncSelect
          type={SBRMType.stage}
          name="stage"
          label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Stage })}
          rules={[requiredRule(intl)]}
          metadata={[{ key: MetaDataKey.venuesId, value: venuesId }]}
        />
      )}
      {stageId && (
        <Form.Item name="stage" rules={[requiredRule(intl)]} className="d-none">
          <Input type="hidden" />
        </Form.Item>
      )}

      <Form.Item
        name="dates"
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Dates })}
        rules={[requiredRule(intl)]}
      >
        <RangePicker
          className="w-full"
          showTime={{ format: 'HH:mm', minuteStep: 15 }}
          format="DD/MM/YYYY HH:mm"
        />
      </Form.Item>
      <SBAsyncSelect
        type={SBRMType.performanceType}
        name="type"
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Type })}
        rules={[]}
        metadata={[]}
      />
    </Form>
  )
}

export default Create

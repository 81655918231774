import { forwardRef, Ref, useImperativeHandle } from 'react'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import { Alert, Form } from 'antd'
import Create from './Create'
import Delete from './Delete'
import {
  createContactAssociation,
  deleteContactAssociation,
} from '../../../reducers/ContactAssociationReducer'
import { useSBRMData } from '../../../modules/sbrm/hooks'
import {
  SBRMFormInterface,
  SBRMFormProps,
} from '../../../modules/sbrm/SBRMModel'

export const ContactAssocationForm = forwardRef(
  ({ isNested }: SBRMFormProps, ref: Ref<SBRMFormInterface>) => {
    useImperativeHandle(ref, () => ({
      handleCreate,
      handleUpdate,
      handleDelete,
      handleReset,
    }))

    const dispatch = useAppDispatch()
    const { action } = useSBRMData(isNested)
    const { error } = useAppSelector((state) => state.contactAssociation)

    const [createForm] = Form.useForm()
    const [updateForm] = Form.useForm()
    const [deleteForm] = Form.useForm()

    const handleCreate = async () =>
      await createForm.validateFields().then(async (result) =>
        dispatch(createContactAssociation(createForm.getFieldsValue())).then(
          () => {
            createForm.resetFields()
            return true
          }
        )
      )

    const handleUpdate = async () =>
      await updateForm.validateFields().then(async (result) =>
        dispatch(createContactAssociation(updateForm.getFieldsValue())).then(
          () => {
            updateForm.resetFields()
            return true
          }
        )
      )

    const handleDelete = async () =>
      await deleteForm.validateFields().then(async (result) =>
        dispatch(deleteContactAssociation(deleteForm.getFieldsValue())).then(
          () => {
            deleteForm.resetFields()
            return true
          }
        )
      )

    const handleReset = () => {
      createForm.resetFields()
      updateForm.resetFields()
      deleteForm.resetFields()
    }

    return (
      <>
        {action === 'create' && <Create form={createForm} />}
        {action === 'delete' && <Delete form={deleteForm} />}
        {error && <Alert message={String(error)} type="error" />}
      </>
    )
  }
)

ContactAssocationForm.displayName = 'ContactAssocationForm'

export type ContactAssocationFormType = { Form: typeof ContactAssocationForm }

import { compare, valid } from 'semver'
import { useEffect, useState } from 'react'
import APIPing from '../modules/api-ping/APIPing'
import { Button, Space, notification } from 'antd'
import { ArgsProps } from 'antd/es/notification/interface'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../i18n/LocalizationKeys'

const NewVersionChecker = () => {
  const intl = useIntl()
  const alelaData = APIPing.useAPIPing()

  const [api, contextHolder] = notification.useNotification()

  const [notificationDisplayed, setNotificationDisplayed] =
    useState<boolean>(false)

  const newVersionNotification: ArgsProps = {
    duration: 0,
    placement: 'bottomRight',
    message: intl.formatMessage({
      id: LocalizationKeys.Components.NewVersionChecker.Title,
    }),
    description: (
      <Space direction="horizontal">
        {intl.formatMessage({
          id: LocalizationKeys.Components.NewVersionChecker.Description,
        })}
        <Button type="primary" onClick={() => window.location.reload()}>
          {intl.formatMessage({
            id: LocalizationKeys.Components.NewVersionChecker.Refresh,
          })}
        </Button>
      </Space>
    ),
  }

  useEffect(() => {
    if (!alelaData || !alelaData.app.version) {
      return
    }

    const apiVersion = valid(alelaData.app.version)
    const localVersion = valid(process.env.REACT_APP_VERSION)

    if (apiVersion && localVersion && compare(apiVersion, localVersion) === 1) {
      // A new version is available, display the notification
      if (!notificationDisplayed) {
        api.info(newVersionNotification)
        setNotificationDisplayed(true)
      }
    }
  }, [alelaData])

  return <>{contextHolder}</>
}

export default NewVersionChecker

import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIUpdate,
} from '../utils/helpers/SBAPIHelper'
import { VenueType } from '../models/VenueType'
import { VENUE_TYPES_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { venueTypeSchema } from '../models/schema'

const initialState: SBState<VenueType> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: {
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },
}

const slice = createSlice({
  name: 'venueType',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getVenueTypesSuccess,
  setQuery: setVenueTypeQuery,
  reset: resetVenueTypeState,
  resetQueryAndIds: resetVenueTypeQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: VenueType }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectVenueTypes = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedVenueType = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectVenueTypeById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectVenueTypesByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedVenueType =
  (venueType: VenueType | number) => async (dispatch: AppDispatch) =>
    dispatch(slice.actions.setSelectedId(getIdOrModelId<VenueType>(venueType)))

export const getVenueTypes = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<VenueType>(
    VENUE_TYPES_URL,
    params,
    [venueTypeSchema],
    slice.actions
  )

export const createVenueType = (venueType: VenueType) =>
  SBAPICreate<VenueType>(
    venueType,
    VENUE_TYPES_URL,
    venueTypeSchema,
    slice.actions
  )

export const updateVenueType = (venueType: VenueType) =>
  SBAPIUpdate<VenueType>(
    venueType,
    `${VENUE_TYPES_URL}/${venueType.id}`,
    slice.actions
  )

export const deleteVenueType = (venueType: VenueType) =>
  SBAPIDelete<VenueType>(
    venueType,
    `${VENUE_TYPES_URL}/${venueType.id}`,
    slice.actions
  )

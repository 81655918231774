import {
  getStages,
  resetStageQueryAndIds,
  selectStages,
  setStageQuery,
} from '../../../reducers/StageReducer'
import {
  getVenueWithId,
  selectSelectedVenue,
  setSelectedVenue,
} from '../../../reducers/VenueReducer'
import {
  SBRMContactAssociationType,
  SBRMType,
} from '../../../modules/sbrm/SBRMModel'
import {
  getFiles,
  resetFileQueryAndIds,
  selectFiles,
  setFileQuery,
} from '../../../reducers/FileReducer'
import { useIntl } from 'react-intl'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { VENUES_URL } from '../../../utils/urls'
import { FileRelation } from '../../../models/File'
import { File } from '../../../components/file/File'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { MetaDataKey } from '../../../models/MetaData'
import { Stage } from '../../../components/stage/Stage'
import { TableParams } from '../../../models/TableParams'
import { addressFormatter } from '../../../utils/formatters'
import { Contact } from '../../../components/contact/Contact'
import { initialQuery } from '../../../utils/helpers/crud/models'
import {
  getContacts,
  resetContactQueryAndIds,
  selectContacts,
  setContactQuery,
} from '../../../reducers/ContactReducer'
import { Actions } from '../../../modules/sbrm/components/Actions'
import { Typography, Card, Space, Row, Col, Avatar, Modal } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

const { Title, Text } = Typography

const VenuePage = () => {
  const intl = useIntl()
  const { venueId } = useParams()
  const dispatch = useAppDispatch()

  const [showPictureModal, setShowPictureModal] = useState(false)
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const openPictureModal = () => {
    setShowPictureModal(true)
  }

  const handleClose = () => {
    setShowPictureModal(false)
  }

  const venue = useAppSelector(selectSelectedVenue())
  const files = useAppSelector(selectFiles())
  const stages = useAppSelector(selectStages())
  const contacts = useAppSelector(selectContacts())
  const { isLoading } = useAppSelector((state) => state.venue)
  const { isOpen: SBRMIsOpen, entity: SBRMEntity } = useAppSelector(
    (state) => state.SBRM
  )

  const baseQuery: TableParams = {
    ...initialQuery,
    pagination: { current: 1, pageSize: 100 },
    filters: { venues: [Number(venueId)] },
  }

  const fetchContacts = () => {
    const query: TableParams = { ...baseQuery, with: ['associations'] }
    dispatch(setContactQuery(query))
    dispatch(getContacts(query))
  }

  const fetchStages = () => {
    dispatch(setStageQuery(baseQuery))
    dispatch(getStages(baseQuery))
  }

  const fetchFiles = () => {
    dispatch(setFileQuery(baseQuery))
    dispatch(getFiles(baseQuery))
  }

  useEffect(() => {
    if (Number.isNaN(venueId)) {
      //TODO: Redirect to venues list
      return
    }
    dispatch(setSelectedVenue(Number(venueId)))
    dispatch(getVenueWithId(Number(venueId)))

    // Reset current values on page arrival
    dispatch(resetContactQueryAndIds(baseQuery))
    dispatch(resetStageQueryAndIds(baseQuery))
    dispatch(resetFileQueryAndIds(baseQuery))

    // Fetch fresh data
    fetchContacts()
    fetchStages()
    fetchFiles()
  }, [venueId])

  useEffect(() => {
    if (!isFirstRender && (!venue || SBRMIsOpen)) {
      return
    }
    setIsFirstRender(false)

    // Trigger when SBRM is closed and on init
    if (SBRMEntity === SBRMType.venue) {
      dispatch(getVenueWithId(Number(venueId)))
    } else if (SBRMEntity === SBRMType.contactAssociation) {
      fetchContacts()
    } else if (SBRMEntity === SBRMType.stage) {
      fetchStages()
    } else if (SBRMEntity === SBRMType.file) {
      fetchFiles()
    }
  }, [SBRMIsOpen])

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card bordered={false} loading={isLoading || !venue}>
            <Space
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Space direction="horizontal">
                <Avatar
                  style={{ cursor: 'pointer' }}
                  shape="square"
                  src={venue && venue.logo}
                  size={'large'}
                  onClick={() => openPictureModal()}
                />
                <Space direction="vertical" size={0}>
                  <Title className="m-0" level={4}>
                    {venue && venue.name}
                  </Title>
                  {venue && venue.address && (
                    <Text type="secondary">
                      {addressFormatter(venue.address, 'full')}
                    </Text>
                  )}
                </Space>
              </Space>

              <Actions
                actions={['update', 'delete']}
                entity={SBRMType.venue}
                entityId={venue?.id}
              />
            </Space>
          </Card>
        </Col>
        <Modal
          title="Update picture"
          open={showPictureModal}
          onOk={handleClose}
          onCancel={handleClose}
        >
          <File.Upload
            action={`${VENUES_URL}/${venue?.id}/logo`}
            accept="image/*"
            onSuccessUpload={() => {
              dispatch(getVenueWithId(Number(venueId))), handleClose()
            }}
          />
        </Modal>
      </Row>
      <Row style={{ marginTop: '10px' }}>
        <Col span={24}>
          <PageTitle
            title={intl.formatMessage({
              id: LocalizationKeys.Page.Settings.Venue.Staff,
            })}
            level={5}
            badge={contacts.length}
            toolbar={
              <Actions
                actions={['create']}
                entity={SBRMType.contactAssociation}
                metadata={[
                  {
                    key: MetaDataKey.contactAssociationType,
                    value: SBRMContactAssociationType.venue,
                  },
                  { key: MetaDataKey.venueId, value: venue?.id },
                ]}
              />
            }
          />
          <Contact.TableWithAssociation
            emptyActionsMetadata={[
              {
                key: MetaDataKey.contactAssociationType,
                value: SBRMContactAssociationType.venue,
              },
              { key: MetaDataKey.venueId, value: venue?.id },
            ]}
          />
        </Col>
        <Col span={24}>
          <PageTitle
            title={intl.formatMessage({
              id: LocalizationKeys.Page.Settings.Venue.Stages,
            })}
            level={5}
            badge={stages.length}
            toolbar={
              <Actions
                actions={['create']}
                entity={SBRMType.stage}
                metadata={[{ key: MetaDataKey.venueId, value: venueId }]}
              />
            }
          />
          <Stage.Table
            emptyActionsMetadata={[
              { key: MetaDataKey.venueId, value: venueId },
            ]}
          />
        </Col>
        <Col span={24}>
          <PageTitle
            title={intl.formatMessage({
              id: LocalizationKeys.Page.Settings.Venue.Files,
            })}
            level={5}
            badge={files.length}
            toolbar={
              <Actions
                actions={['create']}
                entity={SBRMType.file}
                metadata={[
                  {
                    key: MetaDataKey.fileParentType,
                    value: SBRMType.venue,
                  },
                  { key: MetaDataKey.fileParentId, value: venueId },
                  {
                    key: MetaDataKey.fileRelation,
                    value: FileRelation.file,
                  },
                ]}
              />
            }
          />
          <Card bordered={false}>
            <File.Grid
              ids={files.map((f) => f.id) ?? []}
              withDeleteButton={true}
            />
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default VenuePage

import { useIntl } from 'react-intl'
import { Col, Form, FormInstance, Input, InputNumber, Row } from 'antd'
import { numberRule, requiredRule, stringRule } from '../../../utils/rules'
import { useAppSelector } from '../../../reducers/hooks'
import { useEffect } from 'react'
import { selectSelectedHotel } from '../../../reducers/HotelReducer'
import { Address } from '../../../components/address/Address'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

interface Props {
  form: FormInstance
}

const Update = ({ form }: Props) => {
  const intl = useIntl()

  const hotel = useAppSelector(selectSelectedHotel())

  useEffect(() => {
    form.setFieldsValue(hotel)
  }, [hotel])

  if (hotel === undefined) {
    return <></>
  }

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="id"
        rules={[requiredRule(intl)]}
        style={{ display: 'none' }}
      >
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        name="name"
        rules={[requiredRule(intl), stringRule(intl)]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Name })}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="stars"
        rules={[requiredRule(intl), numberRule(intl)]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Stars })}
      >
        <InputNumber min={0} max={5} />
      </Form.Item>
      <Address.Form initialValue={hotel?.address} />
    </Form>
  )
}

export default Update

import {
  getInvitationWithId,
  selectInvitationById,
} from '../../reducers/InvitationReducer'
import {
  Alert,
  Avatar,
  Button,
  Card,
  Form,
  Input,
  Result,
  Skeleton,
  Space,
  Typography,
} from 'antd'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import {
  capsRule,
  containsNumberRule,
  containsSymbolRule,
  emailRule,
  lowercaseRule,
  minLengthRule,
  requiredRule,
} from '../../utils/rules'
import { useIntl } from 'react-intl'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { register } from '../../utils/auth/requests'
import { AxiosError } from 'axios'
import { APIResponse, MessageResponse } from '../../models/APIResponse'
import LocalizationKeys from '../../i18n/LocalizationKeys'

const { Text, Title } = Typography

const RegisterPage = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [invitationId, setInvitationId] = useState<string>('')
  const [feedbackError, setFeedbackError] = useState<string>('')
  const [invitationIsLoading, setInvitationIsLoading] = useState<boolean>(true)
  const [registrationIsLoading, setRegistrationIsLoading] =
    useState<boolean>(false)

  const invitation = useAppSelector(selectInvitationById(invitationId))

  const handleFormSubmit = (values: any) => {
    setRegistrationIsLoading(true)
    setFeedbackError('')
    register(values)
      .then((response) => {
        setRegistrationIsLoading(false)
        setIsSuccess(true)
      })
      .catch((error: AxiosError<APIResponse<MessageResponse>>) => {
        const errorMessage = error.response?.data?.data?.message ?? 'fallback'

        const messages: Record<string, string> = {
          ['auth.registration.invitation_not_found']: intl.formatMessage({
            id: LocalizationKeys.Auth.Invitation.ErrorWhileLoading,
          }),
          ['auth.registration.email_already_exists']: intl.formatMessage({
            id: LocalizationKeys.Auth.Register.ErrorEmailAlreadyExists,
          }),
          ['fallback']: intl.formatMessage({
            id: LocalizationKeys.Auth.Register.Error,
          }),
        }

        setFeedbackError(messages[errorMessage])

        setRegistrationIsLoading(false)
      })
  }

  useEffect(() => {
    setFeedbackError('')
    const queryParameters = new URLSearchParams(window.location.search)
    const nextInvitationId = queryParameters.get('invitation')
    setInvitationId(nextInvitationId ?? '')

    if (!nextInvitationId) {
      setFeedbackError(
        intl.formatMessage({
          id: LocalizationKeys.Auth.Invitation.ErrorWhileAccepting,
        })
      )
    }

    setInvitationIsLoading(true)
    dispatch(getInvitationWithId(nextInvitationId!))
      .then((response) => setInvitationIsLoading(false))
      .catch((e) => {
        setInvitationIsLoading(false)
      })
  }, [])

  return (
    <>
      {invitationIsLoading && <Skeleton />}

      {!invitationIsLoading && !isSuccess && (
        <Space
          direction="vertical"
          style={{ textAlign: 'center', width: '100%' }}
        >
          <Title level={4}>
            {intl.formatMessage({
              id: LocalizationKeys.Auth.Register.Title,
            })}
          </Title>
          <Card bordered>
            <Space direction="horizontal">
              <Avatar
                shape="square"
                size="large"
                src={invitation?.tenant?.logo}
              />
              <Text>
                <Text strong>{invitation?.tenant.name}</Text>{' '}
                {intl.formatMessage({
                  id: LocalizationKeys.Auth.Register.InvitedYou,
                })}
              </Text>
            </Space>
          </Card>

          <Form
            layout="vertical"
            initialValues={{
              email: invitation?.email,
              invitation: invitation?.id,
            }}
            onFinish={handleFormSubmit}
            onValuesChange={(_, allFields) => {}}
          >
            <Form.Item name="firstName" rules={[requiredRule(intl)]}>
              <Input placeholder="John" autoComplete="given-name" />
            </Form.Item>
            <Form.Item name="lastName" rules={[requiredRule(intl)]}>
              <Input placeholder="Doe" autoComplete="family-name" />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[requiredRule(intl), emailRule(intl)]}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: LocalizationKeys.Misc.Form.Email,
                })}
                disabled
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                requiredRule(intl),
                minLengthRule(12, intl),
                lowercaseRule(intl),
                capsRule(intl),
                containsNumberRule(intl),
                containsSymbolRule(intl),
              ]}
            >
              <Input.Password
                placeholder={intl.formatMessage({
                  id: LocalizationKeys.Misc.Form.Password,
                })}
                autoComplete="new-password"
              />
            </Form.Item>
            <Form.Item
              name="password-confirmation"
              rules={[
                requiredRule(intl),
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      new Error('The passwords do not match!')
                    )
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder={intl.formatMessage({
                  id: LocalizationKeys.Misc.Form.PasswordConfirmation,
                })}
                autoComplete="new-password"
              />
            </Form.Item>
            <Form.Item
              name="invitation"
              rules={[requiredRule(intl)]}
              className="d-none"
            >
              <Input type="hidden" />
            </Form.Item>

            <Form.Item>
              <Button
                className="w-full"
                type="primary"
                htmlType="submit"
                loading={registrationIsLoading}
              >
                {intl.formatMessage({
                  id: LocalizationKeys.Auth.Register.Register,
                })}
              </Button>
            </Form.Item>
          </Form>
          {feedbackError && <Alert type="error" message={feedbackError} />}
          <Button type="text">
            <Link
              to={`/auth/login?returnTo=/auth/invitation/${invitation?.id}`}
            >
              {intl.formatMessage({
                id: LocalizationKeys.Auth.Register.AlreadyHaveAnAccount,
              })}
            </Link>
          </Button>
        </Space>
      )}
      {isSuccess && (
        <Result
          status="success"
          title={intl.formatMessage({
            id: LocalizationKeys.Auth.Register.Success,
          })}
          extra={
            <Button
              key={'back-login'}
              type="primary"
              onClick={() => navigate('/auth/login')}
            >
              {intl.formatMessage({
                id: LocalizationKeys.Auth.Register.GoToLogin,
              })}
            </Button>
          }
        />
      )}
    </>
  )
}

export default RegisterPage

import { Tag } from 'antd'
import { useAppSelector } from '../../reducers/hooks'
import { selectEventStatusById } from '../../reducers/EventStatusReducer'

interface Props {
  id: number
}

export const EventStatusTag = ({ id }: Props) => {
  const type = useAppSelector(selectEventStatusById(id))

  return type ? (
    <Tag key={type.id} color={type.color}>
      {type.name}
    </Tag>
  ) : (
    <></>
  )
}

export type EventStatusTagType = { StatusTag: typeof EventStatusTag }

import { Tag, Tooltip } from 'antd'
import { Room } from '../../models/Room'
import { selectRoomById } from '../../reducers/RoomReducer'
import { useAppSelector } from '../../reducers/hooks'
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'
import LocalizationKeys from '../../i18n/LocalizationKeys'

interface Props {
  id?: number
  model?: Room
}

const Nights = ({ id, model }: Props) => {
  const intl = useIntl()
  const room = model ? model : useAppSelector(selectRoomById(id ?? 0))

  if (room === undefined || !room?.from || !room?.to) return <></>

  return (
    <Tooltip
      title={`${dayjs(room.from).format('DD/MM (HH:mm)')} - ${dayjs(
        room.to
      ).format('DD/MM (HH:mm)')}`}
    >
      <Tag>
        {Math.round(dayjs(room.to).diff(room.from, 'day', true))}{' '}
        {intl.formatMessage({
          id: LocalizationKeys.Page.EventDetails.Hotels.Nights,
        })}
      </Tag>
    </Tooltip>
  )
}

type NightsType = { Nights: typeof Nights }

export { Nights, type NightsType }

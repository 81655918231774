import { File, isFile } from '../../../models/File'
import { SBRMType } from '../../sbrm/SBRMModel'
import { SBRMTypeInfos } from '../../sbrm/SBRMTypeInfos'

const extractFileFromArg = (
  arg: any,
  files: Record<string, File>
): File | undefined => {
  if (isFile(arg)) {
    // The file property conforms the File interface
    // Bingo! We have a file, let's return it
    return arg
  } else if (Array.isArray(arg) && arg[0]) {
    // We have an array
    // Let's consider only the first item
    return extractFileFromArg(arg[0], files)
  } else if (typeof arg === 'number') {
    // We have a number
    // Let's check if we have a file with a matching ID in store
    return files[arg]
  }
  return undefined
}

// Get the file attached to the resource
const getFile = (
  entity: SBRMType,
  resource: any,
  files: Record<string, File>
): File | undefined => {
  if (resource === undefined) return undefined

  if (SBRMTypeInfos[entity].filePropertyName === null) {
    return resource as unknown as File
  }

  if (typeof SBRMTypeInfos[entity].filePropertyName === 'string') {
    // Grab the resource property in a variable
    const fileProperty = (resource as Record<string, any>)[
      `${SBRMTypeInfos[entity].filePropertyName}`
    ]

    return extractFileFromArg(fileProperty, files)
  }

  return undefined
}

export { getFile }

import { useIntl } from 'react-intl'
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Input,
  Popover,
  Row,
  Space,
  Typography,
} from 'antd'
import { requiredRule, stringRule } from '../../../utils/rules'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import { useEffect, useState } from 'react'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { SBAsyncSelect } from '../../../components/custom-inputs/sb-async-select/SBAsyncSelect'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import TextArea from 'antd/es/input/TextArea'
import dayjs from 'dayjs'
import {
  getMarketingTaskWithId,
  selectSelectedMarketingTask,
} from '../../../reducers/MarketingTaskReducer'
import { File } from '../../file/File'
import { FileOutlined, PaperClipOutlined } from '@ant-design/icons'
import { MARKETING_TASK_URL } from '../../../utils/urls'
import { Comment } from '../../comment/Comment'
import { close } from '../../../reducers/SBRMReducer'
import { CommentContextProvider } from '../../../utils/context/CommentContext'
import { PageTitle } from '@supplement-bacon/alela-uikit'

interface Props {
  form: FormInstance
}
const { Text, Title } = Typography

const Update = ({ form }: Props) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const item = useAppSelector(selectSelectedMarketingTask())
  const { isOpen } = useAppSelector((state) => state.SBRM)
  const [titleTmp, setTitleTmp] = useState<string | undefined>('')

  useEffect(() => {
    if (!item) return
    form.setFieldsValue(item)
    item.dueDate && form.setFieldValue('dueDate', dayjs(item?.dueDate))
    item.title && setTitleTmp(item.title)
  }, [item])

  useEffect(() => {
    if (!isOpen || !item) {
      // we want to fetch details when modal opens
      return
    }
    dispatch(getMarketingTaskWithId(item.id))
  }, [isOpen])

  if (item === undefined) {
    // Close SBRM
    // This allows to automatically close the update modal when the card is deleted
    dispatch(close())
    return <></>
  }

  const handleTitleChange = (v: string) => {
    setTitleTmp(v)
    form.setFieldValue('title', v)
  }
  return (
    <>
      <Form form={form} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              name="id"
              rules={[requiredRule(intl)]}
              style={{ display: 'none' }}
            >
              <Input type="hidden" />
            </Form.Item>
            <Form.Item
              name="order"
              rules={[requiredRule(intl)]}
              style={{ display: 'none' }}
            >
              <Input type="hidden" />
            </Form.Item>
            {/*
             Because of the <Popover/> the form item are not present until opened 
             So we add hidden fields here to make sure the values a present by default
             The value can be later on changed with the <Popover/>
             */}
            <Form.Item
              name="status"
              rules={[requiredRule(intl)]}
              style={{ display: 'none' }}
            >
              <Input type="hidden" />
            </Form.Item>
            <Form.Item name="user" style={{ display: 'none' }}>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item name="title" style={{ display: 'none' }}>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item name="dueDate" style={{ display: 'none' }}>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item name="event" style={{ display: 'none' }}>
              <Input type="hidden" />
            </Form.Item>
            <Title
              style={{ marginTop: 0 }}
              level={3}
              editable={{
                tooltip: 'Click to edit text',
                onChange: (v) => handleTitleChange(v),
                triggerType: ['text'],
              }}
            >
              {titleTmp && titleTmp}
            </Title>
            <Row gutter={[16, 16]}>
              <Col span={8} style={{ marginBottom: 24 }}>
                <Space direction="vertical" className="w-full">
                  <Text>
                    {intl.formatMessage({
                      id: LocalizationKeys.Misc.Form.Assignee,
                    })}
                  </Text>
                  <SBAsyncSelect
                    size="middle"
                    style={{ marginBottom: 0 }}
                    type={SBRMType.user}
                    name={'user'}
                    rules={[]}
                  />
                </Space>{' '}
              </Col>

              <Col span={8} style={{ marginBottom: 24 }}>
                <Space direction="vertical" className="w-full">
                  <Text>
                    {intl.formatMessage({
                      id: LocalizationKeys.Misc.Form.DueDate,
                    })}
                  </Text>
                  <Form.Item
                    name="dueDate"
                    rules={[]}
                    style={{ marginBottom: 0 }}
                  >
                    <DatePicker format="YYYY-MM-DD" className="w-full" />
                  </Form.Item>
                </Space>
              </Col>

              <Col span={8} style={{ marginBottom: 24 }}>
                <Space direction="vertical" className="w-full">
                  <Text>
                    {intl.formatMessage({
                      id: LocalizationKeys.Misc.Form.Status,
                    })}
                  </Text>
                  <SBAsyncSelect
                    size="middle"
                    style={{ marginBottom: 0 }}
                    type={SBRMType.marketingTaskStatus}
                    name={'status'}
                    rules={[requiredRule(intl)]}
                  />
                </Space>
              </Col>
            </Row>
            <Form.Item
              name="description"
              label={intl.formatMessage({
                id: LocalizationKeys.Misc.Form.Description,
              })}
              rules={[stringRule(intl)]}
            >
              <TextArea rows={5} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Divider />

      <>
        <PageTitle
          style={{ marginBottom: 0 }}
          title={intl.formatMessage({
            id: LocalizationKeys.Misc.Form.Attachements,
          })}
          level={5}
          badge={item.files && item.files.length}
          toolbar={
            <Popover
              style={{ width: 500 }}
              placement="bottom"
              content={
                <File.Upload
                  multiple
                  icon={<FileOutlined />}
                  title={intl.formatMessage({
                    id: LocalizationKeys.Components.Event.Cover,
                  })}
                  action={`${MARKETING_TASK_URL}/${item.id}/files`}
                  accept={'*/*'}
                  onSuccessUpload={() => {
                    dispatch(getMarketingTaskWithId(item.id))
                  }}
                />
              }
              title="Add files"
              trigger="click"
            >
              <Button
                type="primary"
                className="w-full"
                icon={<PaperClipOutlined />}
              >
                {intl.formatMessage({
                  id: LocalizationKeys.Misc.Form.Add,
                })}
              </Button>
            </Popover>
          }
        />
        {item.files && item.files.length > 0 && (
          <File.Grid
            colSize={4}
            ids={item.files ?? []}
            withDeleteButton={true}
          />
        )}
      </>

      <Divider />
      <PageTitle
        level={5}
        style={{ marginBottom: 0 }}
        title={intl.formatMessage({
          id: LocalizationKeys.SBRM.Comment,
        })}
      />
      <Space className="w-full" direction="vertical" style={{ marginTop: 10 }}>
        <CommentContextProvider entity={SBRMType.marketingTask}>
          <Comment.Editor entity={SBRMType.marketingTask} />
          <Comment.Wrapper display={'inner'} entity={SBRMType.marketingTask} />
        </CommentContextProvider>
      </Space>
    </>
  )
}

export default Update

import { Space, Tag, Tooltip } from 'antd'
import { Room } from '../../models/Room'
import { selectRoomById } from '../../reducers/RoomReducer'
import { useAppSelector } from '../../reducers/hooks'
import { useIntl } from 'react-intl'
import { currencyFormat } from '@supplement-bacon/alela-uikit'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { selectCurrencyById } from '../../reducers/CurrencyReducer'

interface Props {
  id?: number
  model?: Room
}

const Price = ({ id, model }: Props) => {
  const intl = useIntl()
  const room = model ? model : useAppSelector(selectRoomById(id ?? 0))
  const currency = useAppSelector(selectCurrencyById(room?.currency ?? 0))

  if (room === undefined || !room.price) return <></>

  return (
    <Space size={0}>
      <Tooltip
        title={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Room.Price,
        })}
      >
        <Tag>{currencyFormat(Number(room.price), currency)}</Tag>
      </Tooltip>
      <Tooltip
        title={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Room.RoomCredits,
        })}
      >
        <Tag>{currencyFormat(Number(room.roomCredits), currency)}</Tag>
      </Tooltip>
    </Space>
  )
}

type PriceType = { Price: typeof Price }

export { Price, type PriceType }

import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Form, FormInstance, Input } from 'antd'
import { requiredRule } from '../../../utils/rules'
import { useAppSelector } from '../../../reducers/hooks'
import ColorSelect from '../../../components/custom-inputs/select/ColorSelect'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { selectSelectedReportItem } from '../../../reducers/ReportItemReducer'
import { SBRMFormStepProps } from '../SBRMModel'
import IconSelect from '../../../components/custom-inputs/select/IconSelect'

interface Props extends SBRMFormStepProps {}

const Update = ({ form }: Props) => {
  const intl = useIntl()

  const item = useAppSelector(selectSelectedReportItem())

  useEffect(() => {
    form.setFieldsValue(item)
  }, [item])

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        style={{ display: 'none' }}
        name="id"
        rules={[requiredRule(intl)]}
      >
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        name="name"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Name,
        })}
        rules={[requiredRule(intl)]}
      >
        <Input />
      </Form.Item>
      {/* <IconSelect
        name="icon"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Icon,
        })}
        rules={[]}
      /> */}
    </Form>
  )
}

export default Update

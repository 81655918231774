import {
  displayImportFromArtist,
  importContactsFromArtist,
  selectBookingById,
} from '../../reducers/BookingReducer'
import { Space, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { SBBanner } from '../banner/SBBanner'
import { DownloadOutlined } from '@ant-design/icons'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { MetaData } from '../../models/MetaData'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { Booking } from './Booking'

const { Text } = Typography

interface Props {
  bookingId: number
  onImportFinished: () => void
}

const ContactsImportFromArtist = ({ bookingId, onImportFinished }: Props) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const [displayImporter, setDisplayImporter] = useState<boolean>(false)
  const [importInProgress, setImportInProgress] = useState<boolean>(false)

  const booking = useAppSelector(selectBookingById(bookingId))

  useEffect(() => {
    displayImportFromArtist(bookingId).then((result) => {
      setDisplayImporter(
        result.data.message === 'bookings.display_contact_importer'
      )
    })
  }, [booking])

  if (displayImporter === false) {
    return <></>
  }

  return (
    <SBBanner.WithAction1
      content={
        <Space>
          <Booking.Avatar id={bookingId} />
          <Text> - </Text>
          <Text>
            {intl.formatMessage({
              id: LocalizationKeys.Components.Booking.ContactsTable
                .ContactsFound,
            })}
          </Text>
        </Space>
      }
      buttonIcon={<DownloadOutlined />}
      buttonTitle={intl.formatMessage({
        id: LocalizationKeys.Misc.Form.Import,
      })}
      buttonLoading={importInProgress}
      onButtonClick={() => {
        setImportInProgress(true)
        dispatch(importContactsFromArtist(booking!)).then(() => {
          onImportFinished()
          setImportInProgress(false)
        })
      }}
    />
  )
}

type ContactsImportFromArtistType = {
  ContactsImportFromArtist: typeof ContactsImportFromArtist
}

export { ContactsImportFromArtist, type ContactsImportFromArtistType }

import {
  importContactsFromVenue,
  selectEventById,
} from '../../reducers/EventReducer'
import { useState } from 'react'
import { Alert, Button, Space } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { useAppSelector } from '../../reducers/hooks'
import { selectVenueById } from '../../reducers/VenueReducer'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'

interface Props {
  venueId: number
  eventId: number
  onImportFinished: () => void
}

export const AutoImportVenueContacts = ({
  venueId,
  eventId,
  onImportFinished,
}: Props) => {
  const intl = useIntl()
  const [importInProgress, setImportInProgress] = useState<boolean>(false)

  const event = useAppSelector(selectEventById(eventId))
  const venue = useAppSelector(selectVenueById(venueId))

  return venue && venue.contacts && venue.contacts.length ? (
    <Alert
      message={
        <Space style={{ width: '100%', padding: 10 }}>
          {intl.formatMessage(
            { id: LocalizationKeys.Components.Event.AutoImportVenueContacts },
            { venue: venue.name }
          )}
        </Space>
      }
      type="success"
      action={
        <Button
          type="dashed"
          icon={<DownloadOutlined />}
          loading={importInProgress}
          onClick={() => {
            setImportInProgress(true)
            importContactsFromVenue(event!, venue.id).then(() => {
              onImportFinished()
              setImportInProgress(false)
            })
          }}
        >
          {intl.formatMessage({
            id: LocalizationKeys.Misc.Form.Import,
          })}
        </Button>
      }
    />
  ) : (
    <></>
  )
}

export type AutoImportVenueContactsType = {
  AutoImportVenueContacts: typeof AutoImportVenueContacts
}

function getFormDataFrom(object: object) {
  const formData = new FormData()
  Object.keys(object).forEach((key) => {
    const objectKey = (object as any)[key]
    if (Array.isArray(objectKey)) {
      objectKey.forEach((e) => formData.append(`${key}[]`, e.toString()))
    } else if (typeof objectKey === 'object')
      formData.append(key, JSON.stringify(objectKey))
    else formData.append(key, objectKey)
  })
  return formData
}
export { getFormDataFrom }

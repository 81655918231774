import { selectUsers } from '../../reducers/UserReducer'
import { BellOutlined, CloseOutlined, SendOutlined } from '@ant-design/icons'
import { Button, Dropdown, Input, Mentions, MenuProps, Space, Tag } from 'antd'
import { useEffect, useState } from 'react'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { SBRMTypeInfos } from '../../modules/sbrm/SBRMTypeInfos'
import {
  createGIFComment,
  createTextComment,
} from '../../reducers/CommentReducer'
import { useComments } from '../../utils/context/CommentContext'
import { MentionsOptionProps } from 'antd/es/mentions'
import { userToMentionValue } from '../../utils/helpers/MentionHelper'
import { useIntl } from 'react-intl'
import { GiphyFetch } from '@giphy/js-fetch-api'
import { Gif, Grid } from '@giphy/react-components'
import { IGif } from '@giphy/js-types'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { Comment } from './Comment'
import {
  createGIFExternalComment,
  createTextExternalComment,
} from '../../reducers/ExternalCommentReducer'

interface Props {
  entity: SBRMType
}

export const CommentEditor = ({ entity }: Props) => {
  const DEFAULT_SEARCH_GIF_TERM = 'hello'
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const { fetchComments } = useComments()
  const gf = new GiphyFetch(process.env.REACT_APP_GIPHY_API_KEY ?? '')

  const users = useAppSelector(selectUsers())
  const { isLoading: usersIsLoading } = useAppSelector((state) => state.user)
  const resourceId = useAppSelector(
    (state) => state[SBRMTypeInfos[entity].reducerName].selectedId
  )

  const [textValue, setTextValue] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [editorType, setEditorType] = useState<'text' | 'GIF'>('text')
  const [selectedGif, setSelectedGif] = useState<IGif | undefined>(undefined)
  const [gifSearchBarValue, setGifSearchBarValue] = useState<string>(
    DEFAULT_SEARCH_GIF_TERM
  )

  const usersToMentions: MentionsOptionProps[] = users.map((user) => ({
    value: userToMentionValue(user),
    label: `${user.firstName} ${user.lastName}`,
  }))

  const handleSend = () => {
    setIsLoading(true)

    let submitMethod

    switch (editorType) {
      case 'text':
        submitMethod = handleSubmitComment
        break
      case 'GIF':
        submitMethod = handleSubmitGIF
        break
    }

    submitMethod!().then(() => {
      fetchComments()
      setIsLoading(false)
    })
  }

  const handleSubmitComment = async (): Promise<void> => {
    if (!textValue) return
    // Extract users from comment body
    const usersMentioned: number[] = textValue
      .split(' ')
      .map((word) =>
        word[0] === '@'
          ? users.find((u) => userToMentionValue(u) === word.substring(1))?.id
          : undefined
      )
      .filter((i) => i !== undefined) as number[]

    // Create comment request
    return dispatch(
      entity === SBRMType.externalAdvancingLink
        ? createTextExternalComment(
            (resourceId ?? '').toString(),
            textValue,
            usersMentioned
          )
        : createTextComment(entity, resourceId ?? 0, textValue, usersMentioned)
    ).then(() => {
      setTextValue('')
    })
  }

  const handleSubmitGIF = async (): Promise<void> =>
    dispatch(
      entity === SBRMType.externalAdvancingLink
        ? createGIFExternalComment(
            (resourceId ?? '').toString(),
            selectedGif?.id.toString() ?? ''
          )
        : createGIFComment(
            entity,
            resourceId ?? 0,
            selectedGif?.id.toString() ?? ''
          )
    ).then(() => {
      closeAndClearGIFSelection()
    })

  // fetch 10 gifs at a time as the user scrolls (offset is handled by the grid)
  // if this function changes, change the Grid key to recreate the grid and start over
  // see the codesandbox for a runnable example
  const fetchGifs = (offset: number) =>
    gf.search(gifSearchBarValue, {
      offset,
      limit: 10,
    })

  const closeAndClearGIFSelection = () => {
    setEditorType('text')
    setSelectedGif(undefined)
    setGifSearchBarValue(DEFAULT_SEARCH_GIF_TERM)
  }

  const items =
    entity !== SBRMType.externalAdvancingLink
      ? [
          {
            key: '1',
            label: <Comment.SubscribeToConversation entity={entity} />,
          },
        ]
      : []

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingRight: 10,
          gap: 10,
          justifyContent: 'space-between',
        }}
      >
        {/* Text input */}
        {editorType == 'text' && (
          <Mentions
            value={textValue}
            onChange={setTextValue}
            loading={usersIsLoading}
            options={usersToMentions}
            autoSize={{ minRows: 1, maxRows: 8 }}
            placeholder={intl.formatMessage({
              id: LocalizationKeys.Components.Comment.Editor.MentionPlaceholder,
            })}
            style={{
              borderRadius: 0,
              border: 'none',
              padding: 15,
            }}
          />
        )}

        {/* Selected GIF preview */}
        {editorType == 'GIF' && selectedGif && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              height: 200,
              width: 200,
              overflow: 'hidden',
            }}
          >
            <Button
              size="small"
              shape="circle"
              onClick={closeAndClearGIFSelection}
              icon={<CloseOutlined style={{ fontSize: 11 }} />}
              style={{ zIndex: 999, marginTop: 5, marginRight: 5 }}
            />
            <Gif
              width={200}
              height={200}
              noLink={true}
              gif={selectedGif}
              hideAttribution={true}
              style={{ marginTop: -30, overflow: 'hidden', background: 'red' }}
            />
          </div>
        )}

        {/* Send Button & GIF tag */}
        {(editorType == 'text' || (editorType == 'GIF' && selectedGif)) && (
          <Space direction="horizontal">
            {editorType == 'text' && (
              <Tag
                style={{ cursor: 'pointer' }}
                onClick={() => setEditorType('GIF')}
              >
                GIF
              </Tag>
            )}
            <Dropdown.Button
              type="primary"
              onClick={handleSend}
              loading={isLoading}
              menu={{ items }}
            >
              {!isLoading && <SendOutlined />}
              {intl.formatMessage({
                id: LocalizationKeys.Components.Comment.Editor.Send,
              })}
            </Dropdown.Button>
          </Space>
        )}
      </div>

      {/* GIF Search */}
      {editorType == 'GIF' && !selectedGif && (
        <Space direction="vertical" className="w-full">
          <Space.Compact className="w-full">
            <Input
              placeholder={intl.formatMessage({
                id: LocalizationKeys.Components.Comment.Editor.GIFPlaceholder,
              })}
              onChange={(e) => setGifSearchBarValue(e.target.value)}
            />
            <Button
              icon={<CloseOutlined />}
              onClick={closeAndClearGIFSelection}
            />
          </Space.Compact>
          <div
            style={{
              height: 300,
              overflow: 'scroll',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <Grid
              width={580}
              columns={3}
              gutter={6}
              fetchGifs={fetchGifs}
              key={gifSearchBarValue}
              onGifClick={(g) => setSelectedGif(g)}
              noLink={true}
              hideAttribution={true}
            />
          </div>
        </Space>
      )}
    </div>
  )
}

export type CommentEditorType = { Editor: typeof CommentEditor }

import { FC } from 'react'
import { Option, OptionType } from './Option'
import { Type, TypeType } from './Type'
import { StationForm, StationFormType } from './form/Form'

export const Station: FC &
  OptionType &
  TypeType &
  StationFormType = (): JSX.Element => <></>

Station.Type = Type
Station.Option = Option
Station.Form = StationForm

import { Button, Alert } from 'antd'
import { useEffect, useState } from 'react'
import useAlelaHelp, { HelpArticle } from '../../utils/hooks/useAlelaHelp'
import LocalizationKeys, { LocalizationKey } from '../../i18n/LocalizationKeys'
import { useIntl } from 'react-intl'

interface SupportAlertProps {
  style?: React.CSSProperties
}

export const SupportAlert = ({ style }: SupportAlertProps) => {
  const intl = useIntl()
  const help = useAlelaHelp()

  const [isRead, setIsRead] = useState(true)
  const articleId = 'need-help-support'

  const handleClose = () => {
    help.markArticleAsRead(articleId)
  }

  useEffect(() => {
    if (!articleId) return
    setIsRead(help.settings.readArticles.includes(articleId))
  }, [articleId])

  if (isRead) return <></>

  return (
    <Alert
      style={{
        ...style,
        alignItems: 'center',
        backgroundColor: '#1a1223',
        border: '1px solid #5762bb',
      }}
      closable
      onClose={handleClose}
      message={intl.formatMessage({
        id: LocalizationKeys.Help.SupportAlert.Message,
      })}
      description={intl.formatMessage({
        id: LocalizationKeys.Help.SupportAlert.Description,
      })}
      action={
        <Button
          type="primary"
          onClick={() => {
            window.Beacon('open')
            window.Beacon('navigate', '/ask/message/')
          }}
        >
          {intl.formatMessage({
            id: LocalizationKeys.Help.SupportAlert.Button,
          })}
        </Button>
      }
    />
  )
}

export type SupportAlertType = { SupportAlert: typeof SupportAlert }

import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Card } from 'antd'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { Actions } from '../../modules/sbrm/components/Actions'
import { initialQuery } from '../../utils/helpers/crud/models'
import { extractSortDataFromSorterResult } from '../../utils/table/sorter'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { Station as StationModel } from '../../models/Station'
import {
  getStations,
  selectStations,
  setStationQuery,
} from '../../reducers/StationReducer'
import { Station } from '../../components/station/Station'
import SBTable from '../../components/SBTable'
import LocalizationKeys from '../../i18n/LocalizationKeys'

const StationsPage = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const items = useAppSelector(selectStations())
  const { query, isLoading } = useAppSelector((state) => state.station)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const columns: ColumnsType<StationModel> = [
    {
      key: 'type',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.Settings.Venues.Type,
      }),
      render: (record: StationModel) => <Station.Type id={record.type} />,
    },
    {
      key: 'name',
      title: intl.formatMessage({ id: LocalizationKeys.Misc.Table.Name }),
      render: (record: StationModel) => record.name,
    },

    {
      key: 'actions',
      align: 'right',
      render: (record: StationModel) => (
        <Actions
          actions={['delete']}
          entity={SBRMType.station}
          entityId={record.id}
        />
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<StationModel> | SorterResult<StationModel>[]
  ) => {
    const newQuery = {
      pagination,
      filters,
      ...extractSortDataFromSorterResult(sorter),
    }
    dispatch(setStationQuery(newQuery))
    dispatch(getStations(newQuery))
  }

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      return
    }
    setIsFirstRender(false)

    // Trigger on init and when SBRM is closed
    dispatch(setStationQuery(initialQuery))
    dispatch(getStations(initialQuery))
  }, [dispatch, SBRMIsOpen])

  return (
    <Card
      bordered={false}
      styles={{ body: { padding: 0 } }}
      title={
        <PageTitle
          title={intl.formatMessage({
            id: LocalizationKeys.Page.Settings.Stations,
          })}
          level={5}
          toolbar={<Actions actions={['create']} entity={SBRMType.station} />}
        />
      }
    >
      <SBTable
        entity={SBRMType.station}
        scroll={{ x: 600 }}
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={items}
        pagination={query.pagination}
        loading={isLoading}
        onChange={handleTableChange}
      />
    </Card>
  )
}

export default StationsPage

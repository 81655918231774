import { selectBookings } from '../../../reducers/BookingReducer'
import {
  SBRMContactAssociationType,
  SBRMType,
} from '../../../modules/sbrm/SBRMModel'
import { useIntl } from 'react-intl'
import { Col, Row, Space, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import HasAccess from '../../../components/HasAccess'
import { MetaDataKey } from '../../../models/MetaData'
import { TableParams } from '../../../models/TableParams'
import { AlelaPermission } from '../../../utils/permissions'
import { Booking } from '../../../components/booking/Booking'
import { initialQuery } from '../../../utils/helpers/crud/models'
import { selectSelectedEvent } from '../../../reducers/EventReducer'
import { Actions } from '../../../modules/sbrm/components/Actions'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { HelpArticle } from '../../../utils/hooks/useAlelaHelp'
import { Help } from '../../../components/help/Help'
import { Event } from '../../../components/event/Event'
import { FilterValue } from 'antd/es/table/interface'
import { getContacts, setContactQuery } from '../../../reducers/ContactReducer'
import { Contact } from '../../../components/contact/Contact'

const PartyCrewPage = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const [activeTabBookingId, setActiveTabBookingId] = useState<
    string | undefined
  >(undefined)

  const event = useAppSelector(selectSelectedEvent())
  const bookings = useAppSelector(selectBookings())
  const { query, isLoading } = useAppSelector((state) => state.contact)

  const fetchData = () => {
    const baseQuery: TableParams = {
      ...initialQuery,
      pagination: { current: 1, pageSize: 100 },
      filters: { bookings: event?.bookings ?? [] },
      with: ['associations'],
    }
    dispatch(setContactQuery(baseQuery))
    dispatch(getContacts(baseQuery))
  }

  const handleTabChange = (nextBookingsFilter: FilterValue | null) => {
    const newQuery: TableParams = {
      ...query,
      filters: { ...query.filters, bookings: nextBookingsFilter },
    }
    dispatch(setContactQuery(newQuery))
    dispatch(getContacts(newQuery))
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (!bookings.length || activeTabBookingId !== undefined) {
      return
    }
    // Set intial selected booking ID only if activeTabBookingId is not already defined
    setActiveTabBookingId(bookings[0].id.toString())
  }, [bookings.length])

  if (event === undefined) return <Spin />

  return (
    <HasAccess permissions={[AlelaPermission.listContactAssociation]}>
      <Row className="container-row">
        <Col span={24}>
          <PageTitle
            title={intl.formatMessage({
              id: LocalizationKeys.Page.EventDetails.PartyCrew,
            })}
            level={5}
            toolbar={
              <Space>
                {isLoading && <Spin />}
                <Actions
                  actions={['create']}
                  entity={SBRMType.contactAssociation}
                  metadata={[
                    {
                      key: MetaDataKey.contactAssociationType,
                      value: SBRMContactAssociationType.booking,
                    },
                    { key: MetaDataKey.eventId, value: event.id },
                    {
                      key: MetaDataKey.bookingId,
                      value: Number(activeTabBookingId),
                    },
                  ]}
                />
                <Help.Button article={HelpArticle.AddAndManagePartyCrew} />
              </Space>
            }
          />
          <Event.PartyCrewExternalRequests
            bookings={bookings}
            shouldRefreshList={(association) => {
              fetchData()
            }}
          />
          <Space direction="vertical" style={{ width: '100%' }}>
            {(event.bookings ?? []).map((b) => (
              <Booking.MissingContactsInPartyCrew key={b} id={b} />
            ))}
            {(event.bookings ?? []).map((b) => (
              <Booking.ContactsImportFromArtist
                key={b}
                bookingId={b}
                onImportFinished={() => {
                  fetchData()
                }}
              />
            ))}
          </Space>
          <Event.BookingFilter
            defaultAllIds
            id={event.id}
            onChange={handleTabChange}
          />
          <Contact.TableWithAssociation
            emptyActionsMetadata={[
              {
                key: MetaDataKey.contactAssociationType,
                value: SBRMContactAssociationType.booking,
              },
              { key: MetaDataKey.eventId, value: event?.id },
              { key: MetaDataKey.bookingId, value: activeTabBookingId },
            ]}
          />
        </Col>
      </Row>
    </HasAccess>
  )
}

export default PartyCrewPage

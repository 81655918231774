import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIUpdate,
} from '../utils/helpers/SBAPIHelper'

import { HOTELS_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { hotelSchema } from '../models/schema'
import { Hotel } from '../models/Hotel'

const initialState: SBState<Hotel> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: {
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },
}

const slice = createSlice({
  name: 'hotel',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getHotelsSuccess,
  setQuery: setHotelQuery,
  reset: resetHotelState,
  resetQueryAndIds: resetHotelQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: Hotel }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectHotels = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedHotel = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectHotelById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectHotelsByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedHotel =
  (hotel: Hotel | number) => async (dispatch: AppDispatch) =>
    dispatch(slice.actions.setSelectedId(getIdOrModelId<Hotel>(hotel)))

export const getHotels = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<Hotel>(
    HOTELS_URL,
    params,
    [hotelSchema],
    slice.actions
  )

export const getHotelWithId = (id: number) =>
  SBAPIFetchDispatch<Hotel>(`${HOTELS_URL}/${id}`, hotelSchema, slice.actions)

export const createHotel = (hotel: Hotel) =>
  SBAPICreate<Hotel>(hotel, HOTELS_URL, hotelSchema, slice.actions)

export const updateHotel = (hotel: Hotel) =>
  SBAPIUpdate<Hotel>(hotel, `${HOTELS_URL}/${hotel.id}`, slice.actions)

export const deleteHotel = (hotel: Hotel) =>
  SBAPIDelete<Hotel>(hotel, `${HOTELS_URL}/${hotel.id}`, slice.actions)

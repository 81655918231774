import Create from './Create'
import Delete from './Delete'
import { Alert, Form } from 'antd'
import { deleteUser, inviteUser } from '../../../reducers/UserReducer'
import { forwardRef, Ref, useImperativeHandle } from 'react'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import { useSBRMData } from '../../../modules/sbrm/hooks'
import {
  SBRMFormInterface,
  SBRMFormProps,
} from '../../../modules/sbrm/SBRMModel'

export const UserForm = forwardRef(
  ({ isNested }: SBRMFormProps, ref: Ref<SBRMFormInterface>) => {
    useImperativeHandle(ref, () => ({
      handleCreate,
      handleUpdate,
      handleDelete,
      handleReset,
    }))

    const dispatch = useAppDispatch()
    const { action } = useSBRMData(isNested)
    const { error } = useAppSelector((state) => state.user)

    const [createForm] = Form.useForm()
    const [updateForm] = Form.useForm()
    const [deleteForm] = Form.useForm()

    const handleCreate = async () =>
      await createForm.validateFields().then(async (result) =>
        dispatch(inviteUser(createForm.getFieldsValue())).then(() => {
          createForm.resetFields()
          return true
        })
      )

    const handleUpdate = async () =>
      await updateForm.validateFields().then(async (result) =>
        dispatch(inviteUser(updateForm.getFieldsValue())).then(() => {
          updateForm.resetFields()
          return true
        })
      )

    const handleDelete = async () =>
      await deleteForm.validateFields().then(async (result) =>
        dispatch(deleteUser(deleteForm.getFieldsValue())).then(() => {
          deleteForm.resetFields()
          return true
        })
      )

    const handleReset = () => {
      createForm.resetFields()
      updateForm.resetFields()
      deleteForm.resetFields()
    }

    return (
      <>
        {action === 'create' && <Create form={createForm} />}
        {action === 'delete' && (
          <Delete form={deleteForm} deleteWithConfirmation />
        )}
        {error && <Alert message={String(error)} type="error" />}
      </>
    )
  }
)

UserForm.displayName = 'UserForm'

export type UserFormType = { Form: typeof UserForm }

import { FC } from 'react'
import { ListForEvent, ListForEventType } from './ListForEvent'
import { TaskForm, TaskFormType } from './form/Form'

export const Task: FC & ListForEventType & TaskFormType = (): JSX.Element => (
  <></>
)

Task.ListForEvent = ListForEvent
Task.Form = TaskForm

import { Key, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { Button, Card, Col, Row, Skeleton } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import { initialQuery } from '../../../utils/helpers/crud/models'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import {
  REPORT_ITEM_TYPE_COST_ID,
  REPORT_ITEM_TYPE_REVENUE_ID,
  ReorderReportItem,
} from '../../../models/Report'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import {
  getReportItems,
  reorderReportItems,
  selectReportItems,
  setReportItemQuery,
} from '../../../reducers/ReportItemReducer'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Reporting } from '../../../components/reporting/Reporting'
import { useNavigate } from 'react-router-dom'
import HasAccess from '../../../components/HasAccess'
import { AlelaPermission } from '../../../utils/permissions'
import { ReorderItemsInterface } from '../../../components/reporting/ReorderItems'

const ReorderItemsPage = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const costRef = useRef<ReorderItemsInterface>(null)
  const revenueRef = useRef<ReorderItemsInterface>(null)

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const items = useAppSelector(selectReportItems())
  const costItemsIds = items
    .filter((i) => i.type.id === REPORT_ITEM_TYPE_COST_ID)
    .map((i) => i.id)
  const revenuesItemsIds = items
    .filter((i) => i.type.id === REPORT_ITEM_TYPE_REVENUE_ID)
    .map((i) => i.id)
  const { isLoading } = useAppSelector((state) => state.reportItem)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const onSave = () => {
    if (!costRef.current || !revenueRef.current) return

    const mapChildToReorderReportItem = (
      { key }: { key: Key },
      index: number
    ): ReorderReportItem => ({
      id: Number(key),
      order: index,
    })

    const newOrder: ReorderReportItem[] = [
      ...costRef.current
        .getData()[0]
        .children!.map(mapChildToReorderReportItem),
      ...revenueRef.current
        .getData()[0]
        .children!.map(mapChildToReorderReportItem),
    ]

    dispatch(reorderReportItems(newOrder)).then(() => {
      fetchItems()
    })
  }

  const fetchItems = () => {
    const baseQuery = {
      ...initialQuery,
      pagination: { ...initialQuery.pagination, pageSize: 1000 },
    }
    dispatch(getReportItems(baseQuery))
  }

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      return
    }
    setIsFirstRender(false)

    fetchItems()
  }, [dispatch, SBRMIsOpen])

  if (isLoading) {
    return <Skeleton />
  }

  return (
    <HasAccess permissions={[AlelaPermission.listReportItem]}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Button
            type="text"
            icon={<ArrowLeftOutlined />}
            onClick={() => navigate('/settings/reporting/items')}
          >
            {intl.formatMessage({
              id: LocalizationKeys.Page.CRM.Supplier.Back,
            })}
          </Button>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card
            bordered={false}
            styles={{ body: { padding: 10 } }}
            title={
              <PageTitle
                title={intl.formatMessage({
                  id: LocalizationKeys.Page.Settings.Reporting.ReorderItems
                    .Title,
                })}
                level={5}
              />
            }
          >
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Reporting.ReorderItems
                  ref={costRef}
                  rootTitle={intl.formatMessage({
                    id: LocalizationKeys.Page.Settings.Reporting.ReorderItems
                      .Costs,
                  })}
                  ids={costItemsIds}
                />
              </Col>
              <Col span={12}>
                <Reporting.ReorderItems
                  ref={revenueRef}
                  rootTitle={intl.formatMessage({
                    id: LocalizationKeys.Page.Settings.Reporting.ReorderItems
                      .Revenues,
                  })}
                  ids={revenuesItemsIds}
                />
              </Col>
            </Row>

            <Button
              type="primary"
              onClick={onSave}
              loading={isLoading}
              style={{ marginTop: 20 }}
            >
              {intl.formatMessage({
                id: LocalizationKeys.SBRM.Save,
              })}
            </Button>
          </Card>
        </Col>
      </Row>
    </HasAccess>
  )
}

export default ReorderItemsPage

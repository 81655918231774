import { FC } from 'react'
import { PaymentCard, PaymentCardType } from './Card'
import { PaymentList, PaymentListType } from './List'
import { PaymentForm, PaymentFormType } from './form/Form'

export const Payment: FC &
  PaymentCardType &
  PaymentListType &
  PaymentFormType = (): JSX.Element => <></>

Payment.Card = PaymentCard
Payment.List = PaymentList
Payment.Form = PaymentForm

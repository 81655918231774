import { forwardRef, Ref, useImperativeHandle } from 'react'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import { Alert, Form } from 'antd'
import Create from './Create'
import Update from './Update'
import Delete from './Delete'
import {
  createFile,
  deleteFile,
  updateFile,
} from '../../../reducers/FileReducer'
import { MetaDataKey } from '../../../models/MetaData'
import { useSBRMData } from '../../../modules/sbrm/hooks'
import {
  getMetadata,
  SBRMFormInterface,
  SBRMFormProps,
} from '../../../modules/sbrm/SBRMModel'

export const FileForm = forwardRef(
  ({ isNested }: SBRMFormProps, ref: Ref<SBRMFormInterface>) => {
    useImperativeHandle(ref, () => ({
      handleCreate,
      handleUpdate,
      handleDelete,
      handleReset,
    }))

    const dispatch = useAppDispatch()
    const { action, metadata } = useSBRMData(isNested)
    const { error } = useAppSelector((state) => state.file)

    const [createForm] = Form.useForm()
    const [updateForm] = Form.useForm()
    const [deleteForm] = Form.useForm()

    const handleCreate = async () =>
      await createForm.validateFields().then(async (result) => {
        const data = new FormData()
        data.append('parent', getMetadata(metadata, MetaDataKey.fileParentType))
        data.append(
          'parentId',
          `${getMetadata(metadata, MetaDataKey.fileParentId)}`
        )
        data.append(
          'relation',
          `${getMetadata(metadata, MetaDataKey.fileRelation)}`
        )
        data.append(
          'file',
          createForm.getFieldValue('file').fileList[0].originFileObj
        )

        return dispatch(createFile(data)).then(() => {
          createForm.resetFields()
          return true
        })
      })

    const handleUpdate = async () =>
      await updateForm.validateFields().then(async (result) =>
        dispatch(updateFile(updateForm.getFieldsValue())).then(() => {
          updateForm.resetFields()
          return true
        })
      )

    const handleDelete = async () =>
      await deleteForm.validateFields().then(async (result) =>
        dispatch(deleteFile(deleteForm.getFieldsValue())).then(() => {
          deleteForm.resetFields()
          return true
        })
      )

    const handleReset = () => {
      createForm.resetFields()
      updateForm.resetFields()
      deleteForm.resetFields()
    }

    return (
      <>
        {action === 'create' && <Create form={createForm} />}
        {action === 'update' && <Update form={updateForm} />}
        {action === 'delete' && <Delete form={deleteForm} />}
        {error && <Alert message={String(error)} type="error" />}
      </>
    )
  }
)

FileForm.displayName = 'FileForm'

export type FileFormType = { Form: typeof FileForm }

import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Lane } from '../../models/Kanban'
import { LaneMap } from './types'
import { composeColumnId } from './utils'
import { once } from 'lodash'
import { initialQuery } from '../../utils/helpers/crud/models'
import { TableParams } from '../../models/TableParams'
import { AppDispatch } from '../../reducers/store'

type KanbanContextProps<T> = {
  columns: LaneMap<T>
  ordered: string[]
  showQuickadd: boolean
  setColumns: Dispatch<SetStateAction<LaneMap<T>>>
  setOrdered: Dispatch<SetStateAction<string[]>>
  setKanbanData: (data: Lane<T>[]) => void
  renderItem: (item: T) => React.ReactNode
  columnToolbar: (columnId: number) => React.ReactNode
  itemMoved: (
    draggableId: string,
    newColumnId: number,
    newIndex: number
  ) => void
  quickAddSubmited: (content: string, columnId: number) => Promise<any>
  fetchCardsForLane: (
    params: TableParams
  ) => (dispatch: AppDispatch) => Promise<any>
  cardsSelectorForLane: (lane: Lane<T>) => any
  getInitialQueryForLane: (lane: Lane<T>) => TableParams
  orderAttribute: keyof T
}

const initKanbanContextPropsState = <T,>(): KanbanContextProps<T> => ({
  columns: {},
  ordered: [],
  showQuickadd: true,
  setColumns: () => {},
  setOrdered: () => {},
  setKanbanData: () => {},
  renderItem: (item: T) => <></>,
  columnToolbar: (columnId) => <></>,
  itemMoved: (draggableId, newColumnId, newIndex) => {},
  quickAddSubmited: (content, columnId) => Promise.resolve(),
  fetchCardsForLane: (lane) => (dispatch) => Promise.resolve(),
  cardsSelectorForLane: (lane) => [],
  getInitialQueryForLane: (lane) => initialQuery,
  orderAttribute: undefined as any,
})

const createKanbanContext = once(<T,>() =>
  createContext<KanbanContextProps<T>>(initKanbanContextPropsState<T>())
)
const useKanban = <T,>() => useContext(createKanbanContext<T>())

interface KanbanContextProviderProps<T> {
  initialValue: Lane<T>[]
  showQuickadd?: KanbanContextProps<T>['showQuickadd']
  renderItem: KanbanContextProps<T>['renderItem']
  itemMoved: KanbanContextProps<T>['itemMoved']
  quickAddSubmited: KanbanContextProps<T>['quickAddSubmited']
  columnToolbar: KanbanContextProps<T>['columnToolbar']
  fetchCardsForLane: KanbanContextProps<T>['fetchCardsForLane']
  cardsSelectorForLane: KanbanContextProps<T>['cardsSelectorForLane']
  getInitialQueryForLane: KanbanContextProps<T>['getInitialQueryForLane']
  orderAttribute: KanbanContextProps<T>['orderAttribute']
}

const KanbanContextProvider = <T,>({
  children,
  initialValue,
  showQuickadd = false,
  renderItem,
  itemMoved,
  quickAddSubmited,
  columnToolbar,
  fetchCardsForLane,
  cardsSelectorForLane,
  getInitialQueryForLane,
  orderAttribute,
}: PropsWithChildren<KanbanContextProviderProps<T>>) => {
  const KanbanContext = createKanbanContext<T>()
  const [columns, setColumns] = useState<LaneMap<T>>({})
  const [ordered, setOrdered] = useState<string[]>([])

  const setKanbanData = (data: Lane<T>[]) => {
    setColumns(
      data.reduce((a, v) => ({ ...a, [composeColumnId(v.id)]: v }), {})
    )
    setOrdered(data.map((e) => composeColumnId(e.id)))
  }

  useEffect(() => {
    setKanbanData(initialValue)
  }, [])

  return (
    <KanbanContext.Provider
      value={{
        columns,
        ordered,
        showQuickadd,
        setColumns,
        setOrdered,
        setKanbanData,
        renderItem,
        itemMoved,
        quickAddSubmited,
        columnToolbar,
        fetchCardsForLane,
        cardsSelectorForLane,
        getInitialQueryForLane,
        orderAttribute,
      }}
    >
      {children}
    </KanbanContext.Provider>
  )
}

type KanbanContextProviderType = typeof KanbanContextProvider

export {
  KanbanContextProvider,
  type KanbanContextProviderType,
  type KanbanContextProviderProps,
  useKanban,
}

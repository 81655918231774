import { Form, FormInstance, Input, Result, Typography } from 'antd'
import { useAppSelector } from '../../../reducers/hooks'
import { matchValueRule, requiredRule } from '../../../utils/rules'
import { useIntl } from 'react-intl'
import { useEffect } from 'react'
import { selectSelectedBooking } from '../../../reducers/BookingReducer'
import { WarningOutlined } from '@ant-design/icons'
import { selectArtistById } from '../../../reducers/ArtistReducer'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

const { Text } = Typography

interface Props {
  form: FormInstance
  deleteWithConfirmation: boolean
}

const Delete = ({ form, deleteWithConfirmation }: Props) => {
  const intl = useIntl()
  const booking = useAppSelector(selectSelectedBooking())
  const artist = useAppSelector(selectArtistById(booking?.artist ?? 0))

  useEffect(() => {
    form.setFieldsValue(booking)
  }, [booking])

  return booking && artist ? (
    <Form form={form} layout="vertical">
      <Result
        title={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.AreYouSureToDelete,
        })}
        icon={<WarningOutlined style={{ color: '#dc4446' }} />}
      >
        <Typography.Text>
          {intl.formatMessage({
            id: LocalizationKeys.Misc.Form.ActionCannotBeUndone,
          })}
          <br />
          {intl.formatMessage({
            id: LocalizationKeys.Misc.Form.BookingResourcesDeleteDescription,
          })}
        </Typography.Text>
      </Result>
      {deleteWithConfirmation && (
        <Form.Item
          name={artist?.name}
          rules={[matchValueRule(intl, artist?.name ?? '')]}
          label={
            <Text>
              {intl.formatMessage(
                { id: LocalizationKeys.Misc.Form.InputValueToConfirm },
                { value: <Text strong>{artist?.name}</Text> }
              )}
            </Text>
          }
        >
          <Input placeholder={`${artist?.name}`} />
        </Form.Item>
      )}
      <Form.Item
        name="id"
        required
        rules={[requiredRule(intl)]}
        className="d-none"
      >
        <Input type="hidden" />
      </Form.Item>
    </Form>
  ) : (
    <></>
  )
}

export default Delete

import { FC } from 'react'
import { RiderForm, RiderFormType } from './form/RiderForm'
import { ExternalRequests, ExternalRequestsType } from './ExternalRequests'

export const Rider: FC &
  RiderFormType &
  ExternalRequestsType = (): JSX.Element => <></>

Rider.Form = RiderForm
Rider.ExternalRequests = ExternalRequests

import { App, Button, Card, Col, Form, Input, Row, Space } from 'antd'
import { useIntl } from 'react-intl'
import { useAuth } from '../../utils/auth/Auth'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { useEffect, useState } from 'react'
import {
  emailRule,
  requiredRule,
  stringRule,
  validPhoneNumberRule,
} from '../../utils/rules'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import LocalizationKeys from '../../i18n/LocalizationKeys'

const ProfilePage = () => {
  const intl = useIntl()
  const [form] = Form.useForm()
  const { message } = App.useApp()
  const { currentUser, updateUser } = useAuth()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    form.setFieldsValue(currentUser)
  }, [currentUser])

  return (
    <>
      <PageTitle
        title={intl.formatMessage({ id: LocalizationKeys.Page.Profile.Title })}
      />
      <Card bordered={false}>
        <Form
          form={form}
          layout="vertical"
          onFinish={() => {
            setIsLoading(true)
            updateUser(form.getFieldsValue())
              .then((_) => {
                setIsLoading(false)
                message.open({
                  type: 'success',
                  content: intl.formatMessage({
                    id: LocalizationKeys.Page.Profile.UpdateSuccess,
                  }),
                })
              })
              .catch((error) => {
                setIsLoading(false)
                const errorMessage = error.response.data.data.message
                message.open({
                  type: 'error',
                  content: intl.formatMessage({
                    id: errorMessage
                      ? errorMessage
                      : LocalizationKeys.Page.Profile.UpdateFailure,
                  }),
                })
              })
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="firstName"
                label={intl.formatMessage({
                  id: LocalizationKeys.Misc.Form.FirstName,
                })}
                rules={[requiredRule(intl), stringRule(intl)]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="lastName"
                label={intl.formatMessage({
                  id: LocalizationKeys.Misc.Form.LastName,
                })}
                rules={[requiredRule(intl), stringRule(intl)]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="phone"
                label={intl.formatMessage({
                  id: LocalizationKeys.Misc.Form.Phone,
                })}
                rules={[requiredRule(intl), validPhoneNumberRule(intl)]}
              >
                <PhoneInput defaultCountry="FR" onChange={() => {}} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="email"
                label={intl.formatMessage({
                  id: LocalizationKeys.Misc.Form.Email,
                })}
                rules={[requiredRule(intl), emailRule(intl)]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Space
            direction="horizontal"
            style={{ flexDirection: 'row-reverse', width: '100%' }}
          >
            <Button type="primary" htmlType="submit" loading={isLoading}>
              {intl.formatMessage({
                id: LocalizationKeys.Misc.Button.Update,
              })}
            </Button>
          </Space>
        </Form>
      </Card>
    </>
  )
}

export default ProfilePage

import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Checkbox, Form, FormInstance, Input } from 'antd'
import { requiredRule } from '../../../utils/rules'
import { useAppSelector } from '../../../reducers/hooks'
import { selectSelectedEventStatus } from '../../../reducers/EventStatusReducer'
import ColorSelect from '../../../components/custom-inputs/select/ColorSelect'
import { SBAsyncSelect } from '../../../components/custom-inputs/sb-async-select/SBAsyncSelect'
import { SBRMType } from '../SBRMModel'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

interface Props {
  form: FormInstance
}

const Update = ({ form }: Props) => {
  const intl = useIntl()
  const status = useAppSelector(selectSelectedEventStatus())

  useEffect(() => {
    form.setFieldsValue(status)
  }, [status])

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        style={{ display: 'none' }}
        name="id"
        required
        rules={[requiredRule(intl)]}
      >
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        name="name"
        rules={[requiredRule(intl)]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Name })}
      >
        <Input />
      </Form.Item>
      <ColorSelect
        name="color"
        rules={[]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Color })}
      />
      <Form.Item
        name="icon"
        rules={[]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Icon })}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="isDraft"
        valuePropName="checked"
        rules={[]}
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.IsDefaultStatus,
        })}
      >
        <Checkbox />
      </Form.Item>
      <SBAsyncSelect
        type={SBRMType.role}
        multiple={true}
        name="visibleByRoles"
        rules={[requiredRule(intl)]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.VisibleBy })}
      />
    </Form>
  )
}

export default Update

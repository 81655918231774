import { createContext } from 'react'
import { AlelaPingData, initialAlelaPingData } from './models'

type APIPingContextProps = {
  pingData: AlelaPingData
}

const initialAPIPingContextState: APIPingContextProps = {
  pingData: initialAlelaPingData,
}

const APIPingContext = createContext<APIPingContextProps>(
  initialAPIPingContextState
)

export { APIPingContext }

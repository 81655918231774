import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIUpdate,
} from '../utils/helpers/SBAPIHelper'
import { Task } from '../models/Task'
import { TASKS_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { taskSchema } from '../models/schema'
import { initialQuery } from '../utils/helpers/crud/models'

const initialState: SBState<Task> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: initialQuery,
}

const slice = createSlice({
  name: 'task',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getTasksSuccess,
  setQuery: setTaskQuery,
  reset: resetTaskState,
  resetQueryAndIds: resetTaskQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: Task }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectTasks = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedTask = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectTaskById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectTasksByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedTask =
  (task: Task | number | undefined) => async (dispatch: AppDispatch) =>
    dispatch(
      slice.actions.setSelectedId(task ? getIdOrModelId<Task>(task) : undefined)
    )

export const getTasks = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<Task>(
    TASKS_URL,
    params,
    [taskSchema],
    slice.actions
  )

export const getTaskWithId = (id: number) =>
  SBAPIFetchDispatch<Task>(`${TASKS_URL}/${id}`, taskSchema, slice.actions)

export const createTask = (task: Task) =>
  SBAPICreate<Task>(task, TASKS_URL, taskSchema, slice.actions)

export const updateTask = (task: Task) =>
  SBAPIUpdate<Task>(task, `${TASKS_URL}/${task.id}`, slice.actions)

export const deleteTask = (task: Task) =>
  SBAPIDelete<Task>(task, `${TASKS_URL}/${task.id}`, slice.actions)

import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Layout, Space, Typography } from 'antd'
import LocalizationKeys from '../i18n/LocalizationKeys'

const { Footer } = Layout
const { Text } = Typography

export const LayoutFooter: React.FC = () => {
  const intl = useIntl()
  const year = new Date().getFullYear()

  return (
    <Footer className="hide-mobile" style={{ background: '#1E1E1E' }}>
      <Space style={{ justifyContent: 'space-between' }} className="w-full">
        <Space size={30}>
          <a
            type="text"
            className="ant-btn-text"
            style={{
              textAlign: 'center',
              color: '#fff',
              fontWeight: 300,
            }}
            href="https://www.alela.live"
          >
            {intl.formatMessage({
              id: LocalizationKeys.Layout.Auth.Discover,
            })}
          </a>
          <a
            type="text"
            className="ant-btn-text"
            style={{
              textAlign: 'center',
              color: '#fff',
              fontWeight: 300,
            }}
            href="mailto:contact@alela.app"
          >
            {intl.formatMessage({
              id: LocalizationKeys.Layout.Auth.ContactUs,
            })}
          </a>
          <a
            type="text"
            className="ant-btn-text"
            style={{
              textAlign: 'center',
              color: '#fff',
              fontWeight: 300,
            }}
            href="https://www.alela.live/template/style-guide"
          >
            {intl.formatMessage({
              id: LocalizationKeys.Layout.Auth.Legal,
            })}
          </a>

          <Link to={'/release-notes'}>
            <Text style={{ fontWeight: 300 }}>
              {intl.formatMessage({
                id: LocalizationKeys.Layout.Footer.ReleaseNotes,
              })}
            </Text>
          </Link>
        </Space>
        <Text style={{ fontWeight: 300 }}>© {year} Supplément Bacon</Text>
      </Space>
    </Footer>
  )
}

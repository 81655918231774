import { Avatar, Badge, Space, Spin, Typography } from 'antd'
import { Notification } from '../../models/Notification'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import {
  readNotification,
  selectNotificationById,
} from '../../reducers/NotificationReducer'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { PictureOutlined } from '@ant-design/icons'
import { useState } from 'react'

const { Text } = Typography

interface Props {
  id?: number
  model?: Notification
}
export const Cell = ({ id, model }: Props) => {
  dayjs.extend(relativeTime)
  const dispatch = useAppDispatch()

  const notification = model
    ? model
    : useAppSelector(selectNotificationById(id ?? 0))

  const [isLoading, setIsLoading] = useState<boolean>(false)

  if (!notification) {
    return <></>
  }

  const handleClick = () => {
    setIsLoading(true)
    dispatch(readNotification(notification)).then(() => {
      setIsLoading(false)
      if (notification.url) {
        window.location.href = notification.url
      }
    })
  }

  return (
    <Spin spinning={isLoading}>
      <Space
        direction="horizontal"
        onClick={handleClick}
        className="w-full"
        style={{
          justifyContent: 'space-between',
          opacity: notification.read ? '0.5' : '1',
          cursor: notification.url ? 'pointer' : 'default',
        }}
        size={10}
      >
        <Badge dot={!notification.read}>
          {notification.logo ? (
            <Avatar shape="square" size={48} src={notification.logo} />
          ) : (
            <div
              style={{
                borderRadius: 8,
                width: '48px',
                height: '48px',
                backgroundColor: '#252525',
                alignItems: 'center',
                alignContent: 'center',
                display: 'flex',
              }}
            >
              <PictureOutlined
                style={{
                  color: '#333333',
                  margin: 'auto',
                  fontSize: 25,
                }}
              />
            </div>
          )}
        </Badge>

        <div style={{ flex: 1, lineHeight: '1.0' }}>
          <Space.Compact
            className="w-full"
            style={{
              justifyContent: 'space-between',
            }}
          >
            <Text style={{ lineHeight: '1.3' }} strong>
              {notification.title}
            </Text>

            <Text style={{ opacity: '0.5', fontSize: 12 }} type="secondary">
              {dayjs(notification.date).fromNow()}
            </Text>
          </Space.Compact>

          <Text style={{ lineHeight: '1.3' }} type="secondary">
            {notification.body}
          </Text>
        </div>
      </Space>
    </Spin>
  )
}

export type CellType = { Cell: typeof Cell }

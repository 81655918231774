import { useAuth } from '../../utils/auth/Auth'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { SBCauserActivityLog } from '../../components/SBActivityLog'
import { Card, Typography } from 'antd'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'

const ActivityPage = () => {
  const intl = useIntl()
  const { currentUser } = useAuth()

  return (
    <>
      <PageTitle
        title={intl.formatMessage({ id: LocalizationKeys.Page.Activity.Title })}
        toolbar={
          <Typography.Text italic>
            {intl.formatMessage({ id: LocalizationKeys.Page.Activity.Toolbar })}
          </Typography.Text>
        }
      />
      <Card bordered={false} styles={{ body: { padding: 0 } }}>
        <SBCauserActivityLog activities={currentUser?.activities ?? []} />
      </Card>
    </>
  )
}

export default ActivityPage

import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Form, FormInstance, Input } from 'antd'
import { requiredRule } from '../../../utils/rules'
import { useAppSelector } from '../../../reducers/hooks'
import { selectSelectedVenueType } from '../../../reducers/VenueTypeReducer'
import ColorSelect from '../../../components/custom-inputs/select/ColorSelect'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

interface Props {
  form: FormInstance
}

const Update = ({ form }: Props) => {
  const intl = useIntl()

  const type = useAppSelector(selectSelectedVenueType())

  useEffect(() => {
    form.setFieldsValue(type)
  }, [type])

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        style={{ display: 'none' }}
        name="id"
        required
        rules={[requiredRule(intl)]}
      >
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        name="name"
        required
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Name,
        })}
        rules={[requiredRule(intl)]}
      >
        <Input />
      </Form.Item>
      <ColorSelect
        name="color"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Color,
        })}
        rules={[requiredRule(intl)]}
      />
      <Form.Item
        name="icon"
        required
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Icon,
        })}
        rules={[requiredRule(intl)]}
      >
        <Input />
      </Form.Item>
    </Form>
  )
}

export default Update

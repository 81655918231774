import { Navigate, Route, Routes } from 'react-router-dom'
import PartyCrewPage from '../../../pages/event-detail/production/PartyCrewPage'
import GroundPage from '../../../pages/event-detail/production/GroundPage'
import HotelsPage from '../../../pages/event-detail/production/HotelsPage'
import TechnicalPage from '../../../pages/event-detail/production/TechnicalPage'
import TravelsPage from '../../../pages/event-detail/production/TravelsPage'

const EventProductionRoutes = () => {
  return (
    <Routes>
      <Route index path="travels" element={<TravelsPage />} />
      <Route path="hotels" element={<HotelsPage />} />
      <Route path="ground" element={<GroundPage />} />
      <Route path="technical" element={<TechnicalPage />} />
      <Route path="party-crew" element={<PartyCrewPage />} />
      <Route path="*" element={<Navigate to={'travels'} />} />
    </Routes>
  )
}

export default EventProductionRoutes

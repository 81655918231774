import Table from './Table'
import ContactAvatar from './ContactAvatar'
import { Link, Text } from '@react-pdf/renderer'
import { ContactWithRelation } from '../../../models/Contact'

interface Props {
  data: ContactWithRelation[]
}

const ContactTable = ({ data }: Props) => {
  const ContactCols = [
    {
      title: 'Name',
      key: 'firstName',
      render: (record: ContactWithRelation) => (
        <ContactAvatar contact={record} />
      ),
    },
    {
      title: 'Phone',
      key: 'phone',
      render: (record: ContactWithRelation) => (
        <Text>
          <Link src={`tel:${record.phone}`}>{record.phone}</Link>
        </Text>
      ),
    },
    {
      title: 'Type',
      key: 'type',
      render: (record: ContactWithRelation) => (
        <>
          {record.associations.map((association) => (
            <Text key={association.id}>{association.type?.name}</Text>
          ))}
        </>
      ),
    },
  ]

  return <Table columns={ContactCols} data={data} />
}

export default ContactTable

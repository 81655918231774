import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
} from 'antd'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useSBRMData } from '../../../modules/sbrm/hooks'
import TextArea from 'antd/es/input/TextArea'
import { SBRMFormStepProps, SBRMType } from '../../../modules/sbrm/SBRMModel'
import { File } from '../../../components/file/File'
import { useAppSelector } from '../../../reducers/hooks'
import { selectInvoiceById } from '../../../reducers/InvoiceReducer'
import { fieldFormatter, roundToTwo } from '../../../utils/formatters'
import { numberRule, requiredRule, stringRule } from '../../../utils/rules'
import dayjs from 'dayjs'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { SBAsyncSelect } from '../../custom-inputs/sb-async-select/SBAsyncSelect'

interface Props extends SBRMFormStepProps {
  invoiceId?: number
}

const Create = ({ isNested, form, invoiceId }: Props) => {
  const intl = useIntl()

  const { isOpen: SBRMIsOpen } = useSBRMData(isNested)

  const invoice = useAppSelector(selectInvoiceById(invoiceId!))

  useEffect(() => {
    if (!SBRMIsOpen) {
      // We want to trigger only the reset
      // when the SBRM opens
      return
    }
    form.setFieldValue('invoice', invoiceId)
    form.setFieldValue('method', 'bank-transfert')
    form.setFieldValue('date', dayjs(Date()))
    form.setFieldValue('amount', invoice?.amountIncludingTaxes ?? 0)
    form.setFieldValue('currency', invoice?.currency)
  }, [SBRMIsOpen])

  return (
    <Form form={form} layout="vertical">
      <Form.Item name="invoice" rules={[requiredRule(intl)]} className="d-none">
        <Input type="hidden" />
      </Form.Item>

      <SBAsyncSelect
        size="middle"
        type={SBRMType.currency}
        name={'currency'}
        rules={[requiredRule(intl)]}
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Currency,
        })}
      />
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Form.Item
            style={{ marginBottom: 10 }}
            name="amount"
            rules={[requiredRule(intl), numberRule(intl)]}
            label={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Invoice.Amount,
            })}
          >
            <InputNumber
              min={0}
              className="w-full"
              formatter={fieldFormatter}
            />
          </Form.Item>
        </Col>
        <Col span={18}>
          <Form.Item
            label={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Payment.CalculatePaidAmount,
            })}
            name="amount_helper"
            initialValue={'1'}
          >
            <Radio.Group
              onChange={(e) =>
                form.setFieldValue(
                  'amount',
                  roundToTwo(
                    (invoice?.amountIncludingTaxes ?? 0) *
                      Number(e.target.value)
                  )
                )
              }
            >
              <Radio.Button value="0.1">10%</Radio.Button>
              <Radio.Button value="0.25">25%</Radio.Button>
              <Radio.Button value="0.33">33%</Radio.Button>
              <Radio.Button value="0.50">50%</Radio.Button>
              <Radio.Button value="1">100%</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            name="type"
            initialValue={'bank-transfer'}
            label={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Payment.OperationType,
            })}
            rules={[requiredRule(intl)]}
          >
            <Select
              defaultValue="bank-transfer"
              options={[
                {
                  value: 'bank-transfer',
                  label: intl.formatMessage({
                    id: LocalizationKeys.Misc.Form.Payment.BankTransfer,
                  }),
                },
                {
                  value: 'card',
                  label: intl.formatMessage({
                    id: LocalizationKeys.Misc.Form.Payment.Card,
                  }),
                },
                {
                  value: 'check',
                  label: intl.formatMessage({
                    id: LocalizationKeys.Misc.Form.Payment.Check,
                  }),
                },
                {
                  value: 'cash',
                  label: intl.formatMessage({
                    id: LocalizationKeys.Misc.Form.Payment.Cash,
                  }),
                },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Payment.OperationDate,
            })}
            name="date"
            rules={[requiredRule(intl)]}
          >
            <DatePicker className="w-full" />
          </Form.Item>
        </Col>
      </Row>

      <File.Dropzone
        name="pop"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Payment.POP,
        })}
      />
      <div style={{ height: 24 }}></div>
      <Form.Item
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Notes })}
        name="notes"
        rules={[stringRule(intl)]}
      >
        <TextArea rows={5} />
      </Form.Item>
    </Form>
  )
}

export default Create

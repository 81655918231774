import { SearchOutlined } from '@ant-design/icons'
import { Input, Button, Space, InputRef } from 'antd'
import { ColumnType } from 'antd/es/table'
import { RefObject } from 'react'
import { IntlShape } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'

const getColumnSearchProps = <T,>(
  dataIndex: keyof T,
  searchInput: RefObject<InputRef>,
  handleSearch: (selectedKeys: string[], dataIndex: keyof T) => void,
  handleReset: (clearFilters: () => void) => void,
  intl: IntlShape,
  isLoading: boolean
): ColumnType<T> => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters, close }) => (
    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
      <Input
        ref={searchInput}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => handleSearch(selectedKeys as string[], dataIndex)}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys as string[], dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 110 }}
          loading={isLoading}
        >
          {intl.formatMessage({
            id: LocalizationKeys.Misc.Table.Search.Search,
          })}
        </Button>
        <Button
          onClick={() => clearFilters && handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          {intl.formatMessage({ id: LocalizationKeys.Misc.Table.Search.Reset })}
        </Button>

        <Button
          type="link"
          size="small"
          onClick={() => {
            close()
          }}
        >
          {intl.formatMessage({ id: LocalizationKeys.Misc.Table.Search.Close })}
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered: boolean) => (
    <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  ),
  onFilterDropdownOpenChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInput.current?.select(), 100)
    }
  },
})

export default getColumnSearchProps

import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { requiredRule } from '../../utils/rules'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { Button, Col, Form, Row, Typography } from 'antd'
import { SBAsyncSelect } from '../custom-inputs/sb-async-select/SBAsyncSelect'
import { useAppDispatch } from '../../reducers/hooks'
import { associateContact } from '../../reducers/UserReducer'
import { User } from '../../models/User'
import LocalizationKeys from '../../i18n/LocalizationKeys'

const { Text } = Typography

interface Props {
  user: User
  contactAssociated: (contactId: number) => void
}

export const AssociateContact = ({ user, contactAssociated }: Props) => {
  const intl = useIntl()
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()

  const handleFormSubmit = () => {
    const contactId = form.getFieldValue('contact')
    dispatch(associateContact(user, contactId)).then((data) =>
      contactAssociated(contactId)
    )
  }

  return (
    <>
      <Text type="secondary">
        {intl.formatMessage({
          id: LocalizationKeys.Components.User.AssociateContact.Description,
        })}
        <Link to="/account/profile">
          <Text underline>
            {intl.formatMessage({
              id: LocalizationKeys.Components.User.AssociateContact
                .ProfileLabel,
            })}
          </Text>
        </Link>
        <br />
        {intl.formatMessage({
          id: LocalizationKeys.Components.User.AssociateContact.Description2,
        })}
      </Text>
      <Form
        form={form}
        layout="horizontal"
        style={{ marginTop: '20px' }}
        onFinish={handleFormSubmit}
      >
        <Row gutter={[8, 8]}>
          <Col span={16}>
            <SBAsyncSelect
              allowClear
              outsideSBRM
              name="contact"
              type={SBRMType.contact}
              rules={[requiredRule(intl)]}
              placeholder={intl.formatMessage({
                id: LocalizationKeys.Components.User.AssociateContact
                  .SearchPlaceholder,
              })}
            />
          </Col>
          <Col span={8}>
            <Button type="primary" htmlType="submit">
              {intl.formatMessage({
                id: LocalizationKeys.Components.User.AssociateContact.Associate,
              })}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export type AssociateContactType = { AssociateContact: typeof AssociateContact }

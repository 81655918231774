import { Select, Form, Tag } from 'antd'
import { Rule } from 'antd/es/form'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { useIntl } from 'react-intl'

interface Props {
  name: string
  label?: string
  rules?: Rule[]
}

const { Option } = Select

const ColorSelect = ({ name, label, rules }: Props) => {
  const intl = useIntl()
  const items = [
    {
      id: 'pink',
      label: intl.formatMessage({ id: LocalizationKeys.Misc.Color.Pink }),
    },
    {
      id: 'red',
      label: intl.formatMessage({ id: LocalizationKeys.Misc.Color.Red }),
    },
    {
      id: 'yellow',
      label: intl.formatMessage({ id: LocalizationKeys.Misc.Color.Yellow }),
    },
    {
      id: 'orange',
      label: intl.formatMessage({ id: LocalizationKeys.Misc.Color.Orange }),
    },
    {
      id: 'cyan',
      label: intl.formatMessage({ id: LocalizationKeys.Misc.Color.Cyan }),
    },
    {
      id: 'green',
      label: intl.formatMessage({ id: LocalizationKeys.Misc.Color.Green }),
    },
    {
      id: 'blue',
      label: intl.formatMessage({ id: LocalizationKeys.Misc.Color.Blue }),
    },
    {
      id: 'purple',
      label: intl.formatMessage({ id: LocalizationKeys.Misc.Color.Purple }),
    },
    {
      id: 'geekblue',
      label: intl.formatMessage({ id: LocalizationKeys.Misc.Color.Geekblue }),
    },
    {
      id: 'magenta',
      label: intl.formatMessage({ id: LocalizationKeys.Misc.Color.Magenta }),
    },
    {
      id: 'volcano',
      label: intl.formatMessage({ id: LocalizationKeys.Misc.Color.Volcano }),
    },
    {
      id: 'gold',
      label: intl.formatMessage({ id: LocalizationKeys.Misc.Color.Gold }),
    },
    {
      id: 'lime',
      label: intl.formatMessage({ id: LocalizationKeys.Misc.Color.Lime }),
    },
  ]

  return (
    <Form.Item name={name} label={label} rules={rules}>
      <Select>
        {items.map((item) => (
          <Option key={item.id} value={item.id}>
            <Tag
              style={{ display: 'inline', padding: '2px 8px' }}
              color={item.id}
            >
              {item.label}
            </Tag>
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default ColorSelect

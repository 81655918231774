import { forwardRef, Ref, useImperativeHandle } from 'react'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import { Alert, Form } from 'antd'
import Create from './Create'
import Update from './Update'
import Delete from './Delete'
import {
  createGround,
  deleteGround,
  updateGround,
} from '../../../reducers/GroundReducer'
import dayjs from 'dayjs'
import { MetaDataKey } from '../../../models/MetaData'
import { useSBRMData } from '../../../modules/sbrm/hooks'
import {
  getMetadata,
  SBRMFormInterface,
  SBRMFormProps,
} from '../../../modules/sbrm/SBRMModel'
import { groundTypeToAPI } from '../../../utils/helpers/GroundHelper'

export const GroundForm = forwardRef(
  ({ isNested }: SBRMFormProps, ref: Ref<SBRMFormInterface>) => {
    useImperativeHandle(ref, () => ({
      handleCreate,
      handleUpdate,
      handleDelete,
      handleReset,
    }))

    const dispatch = useAppDispatch()
    const { action, metadata } = useSBRMData(isNested)
    const { error } = useAppSelector((state) => state.ground)

    const [createForm] = Form.useForm()
    const [updateForm] = Form.useForm()
    const [deleteForm] = Form.useForm()

    const handleCreate = async () =>
      await createForm.validateFields().then(async (result) => {
        const fromField = createForm.getFieldValue('from')
        const toField = createForm.getFieldValue('to')

        // Ground are always stored with pickup time
        var pickupTime = dayjs(createForm.getFieldValue('pickupTime'))
        const timeMode = createForm.getFieldValue('timeMode')
        if (timeMode === 'arriveBy') {
          const duration = createForm.getFieldValue('duration')
          pickupTime = pickupTime.subtract(duration, 'minute')
        }

        return dispatch(
          createGround({
            ...createForm.getFieldsValue(),
            from: {
              type: groundTypeToAPI(fromField[0]),
              id:
                fromField.length === 2 && fromField[1]
                  ? Number(fromField[1])
                  : undefined,
            },
            to: {
              type: groundTypeToAPI(toField[0]),
              id:
                toField.length === 2 && toField[1]
                  ? Number(toField[1])
                  : undefined,
            },
            pickupTime: pickupTime.toISOString(),
          })
        ).then(() => {
          createForm.resetFields()
          return true
        })
      })

    const handleUpdate = async () =>
      await updateForm.validateFields().then(async (result) => {
        const fromType = updateForm.getFieldValue('from')
        const toType = updateForm.getFieldValue('to')

        // Ground are always stored with pickup time
        var pickupTime = dayjs(updateForm.getFieldValue('pickupTime'))
        const timeMode = updateForm.getFieldValue('timeMode')
        if (timeMode === 'arriveBy') {
          const duration = updateForm.getFieldValue('duration')
          pickupTime = pickupTime.subtract(duration, 'minute')
        }

        return dispatch(
          updateGround({
            ...updateForm.getFieldsValue(),
            from: {
              type: groundTypeToAPI(fromType[0]),
              id:
                fromType.length === 2 && fromType[1]
                  ? Number(fromType[1])
                  : undefined,
            },
            to: {
              type: groundTypeToAPI(toType[0]),
              id:
                toType.length === 2 && toType[1]
                  ? Number(toType[1])
                  : undefined,
            },
            pickupTime: pickupTime.toISOString(),
          })
        ).then(() => {
          updateForm.resetFields()
          return true
        })
      })

    const handleDelete = async () =>
      await deleteForm.validateFields().then(async (result) =>
        dispatch(deleteGround(deleteForm.getFieldsValue())).then(() => {
          deleteForm.resetFields()
          return true
        })
      )

    const handleReset = () => {
      createForm.resetFields()
      updateForm.resetFields()
      deleteForm.resetFields()
    }

    return (
      <>
        {(action === 'create' || action === 'duplicate') && (
          <Create
            form={createForm}
            duplicate={action === 'duplicate'}
            eventId={getMetadata(metadata, MetaDataKey.eventId)}
            bookingId={getMetadata(metadata, MetaDataKey.bookingId)}
          />
        )}
        {action === 'update' && (
          <Update
            form={updateForm}
            eventId={getMetadata(metadata, MetaDataKey.eventId)}
          />
        )}
        {action === 'delete' && <Delete form={deleteForm} />}
        {error && <Alert message={String(error)} type="error" />}
      </>
    )
  }
)

GroundForm.displayName = 'GroundForm'

export type GroundFormType = { Form: typeof GroundForm }

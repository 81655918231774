import {
  acceptInvitation,
  getInvitationWithId,
  selectInvitationById,
} from '../../reducers/InvitationReducer'
import { useIntl } from 'react-intl'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAuth } from '../../utils/auth/Auth'
import { Alert, Avatar, Button, Skeleton, Space, Typography } from 'antd'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import LocalizationKeys from '../../i18n/LocalizationKeys'

const { Text, Title } = Typography

const InvitationPage = () => {
  const intl = useIntl()
  const { isLogged } = useAuth()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const { invitationId } = useParams<{ invitationId: string }>()

  const invitation = useAppSelector(selectInvitationById(invitationId ?? ''))

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [invitationIsAccepting, setInvitationIsAccepting] =
    useState<boolean>(false)
  const [feedbackError, setFeedbackError] = useState<string>('')

  const handleAcceptInvitation = () => {
    if (!invitation) {
      return
    }
    setFeedbackError('')
    setInvitationIsAccepting(true)
    dispatch(acceptInvitation(invitation))
      .then((response) => {
        setInvitationIsAccepting(false)
        navigate('/')
      })
      .catch((e) => {
        setInvitationIsAccepting(false)
        setFeedbackError(
          intl.formatMessage({
            id: LocalizationKeys.Auth.Invitation.ErrorWhileAccepting,
          })
        )
      })
  }

  useEffect(() => {
    if (!invitationId) {
      return
    }
    setIsLoading(true)
    setFeedbackError('')
    dispatch(getInvitationWithId(invitationId))
      .then((response) => setIsLoading(false))
      .catch((e) => {
        setFeedbackError(
          intl.formatMessage({
            id: LocalizationKeys.Auth.Invitation.ErrorWhileLoading,
          })
        )
        setIsLoading(false)
      })
  }, [invitationId])

  return (
    <>
      {isLoading && <Skeleton />}

      {!isLoading && !feedbackError && (
        <Space
          direction="vertical"
          style={{ textAlign: 'center', width: '100%' }}
        >
          <Title level={4}>
            {intl.formatMessage({
              id: LocalizationKeys.Auth.Invitation.Title,
            })}
          </Title>
          <Avatar shape="square" size="large" src={invitation?.tenant?.logo}>
            {invitation?.tenant.name.toLocaleUpperCase().slice(0, 1)}
          </Avatar>
          <Text>
            <Text strong>{invitation?.tenant.name}</Text>{' '}
            {intl.formatMessage({
              id: LocalizationKeys.Auth.Invitation.NeedsYou,
            })}
          </Text>
          {isLogged && (
            <Button
              type="primary"
              loading={invitationIsAccepting}
              onClick={handleAcceptInvitation}
            >
              {intl.formatMessage({
                id: LocalizationKeys.Auth.Invitation.Accept,
              })}
            </Button>
          )}
          {!isLogged && (
            <>
              <Button type="primary">
                <Link to={`/auth/register?invitation=${invitation?.id}`}>
                  {intl.formatMessage({
                    id: LocalizationKeys.Auth.Invitation.Register,
                  })}
                </Link>
              </Button>
              <Button type="text">
                <Link to={`/auth/login?returnTo=${pathname}`}>
                  {intl.formatMessage({
                    id: LocalizationKeys.Auth.Invitation.AlreadyHaveAnAccount,
                  })}
                </Link>
              </Button>
            </>
          )}
        </Space>
      )}
      {feedbackError && <Alert type="error" message={feedbackError} />}
    </>
  )
}

export default InvitationPage

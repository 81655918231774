import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from './LocalStorageHelper'
import { Tenant } from '../../models/Tenant'
import { AuthModel, SSODetails } from '../../models/AuthModel'

const AUTH_LOCAL_STORAGE_TOKEN_KEY = 'alela-auth-v1'
const AUTH_LOCAL_STORAGE_TENANT_KEY = 'alela-tenant-v1'

const SSO_LOCAL_STORAGE_TOKEN_KEY = 'alela-sso-auth-v1'
const SSO_LOCAL_STORAGE_DETAILS_KEY = 'alela-sso-details-v1'

const getAuth = () =>
  getLocalStorageItem<AuthModel>(AUTH_LOCAL_STORAGE_TOKEN_KEY)

const setAuth = (auth: AuthModel) =>
  setLocalStorageItem<AuthModel>(AUTH_LOCAL_STORAGE_TOKEN_KEY, auth)

const removeAuth = () => removeLocalStorageItem(AUTH_LOCAL_STORAGE_TOKEN_KEY)

const getAuthTenant = () =>
  getLocalStorageItem<Tenant>(AUTH_LOCAL_STORAGE_TENANT_KEY)

const setAuthTenant = (tenant: Tenant) =>
  setLocalStorageItem<Tenant>(AUTH_LOCAL_STORAGE_TENANT_KEY, tenant)

const removeAuthTenant = () =>
  removeLocalStorageItem(AUTH_LOCAL_STORAGE_TENANT_KEY)

const getSSOAuth = () =>
  getLocalStorageItem<AuthModel>(SSO_LOCAL_STORAGE_TOKEN_KEY)

const setSSOAuth = (auth: AuthModel) =>
  setLocalStorageItem<AuthModel>(SSO_LOCAL_STORAGE_TOKEN_KEY, auth)

const removeSSOAuth = () => removeLocalStorageItem(SSO_LOCAL_STORAGE_TOKEN_KEY)

const getSSODetails = () =>
  getLocalStorageItem<SSODetails>(SSO_LOCAL_STORAGE_DETAILS_KEY)

const setSSODetails = (details: SSODetails) =>
  setLocalStorageItem<SSODetails>(SSO_LOCAL_STORAGE_DETAILS_KEY, details)

const removeSSODetails = () =>
  removeLocalStorageItem(SSO_LOCAL_STORAGE_DETAILS_KEY)

export {
  getAuth,
  setAuth,
  removeAuth,
  getAuthTenant,
  setAuthTenant,
  removeAuthTenant,
  getSSOAuth,
  setSSOAuth,
  removeSSOAuth,
  getSSODetails,
  setSSODetails,
  removeSSODetails,
}

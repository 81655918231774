import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIUpdate,
} from '../utils/helpers/SBAPIHelper'
import { ContactType } from '../models/Contact'
import { CONTACT_TYPES_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { contactTypeSchema } from '../models/schema'

const initialState: SBState<ContactType> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: {
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },
}

const slice = createSlice({
  name: 'contactType',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getContactTypesSuccess,
  setQuery: setContactTypeQuery,
  reset: resetContactTypeState,
  resetQueryAndIds: resetContactTypeQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: ContactType }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectContactTypes = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedContactType = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectContactTypeById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectContactTypesByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedContactType =
  (contactType: ContactType | number) => async (dispatch: AppDispatch) =>
    dispatch(
      slice.actions.setSelectedId(getIdOrModelId<ContactType>(contactType))
    )

export const getContactTypes = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<ContactType>(
    CONTACT_TYPES_URL,
    params,
    [contactTypeSchema],
    slice.actions
  )

export const createContactType = (contactType: ContactType) =>
  SBAPICreate<ContactType>(
    contactType,
    CONTACT_TYPES_URL,
    contactTypeSchema,
    slice.actions
  )

export const updateContactType = (contactType: ContactType) =>
  SBAPIUpdate<ContactType>(
    contactType,
    `${CONTACT_TYPES_URL}/${contactType.id}`,
    slice.actions
  )

export const deleteContactType = (contactType: ContactType) =>
  SBAPIDelete<ContactType>(
    contactType,
    `${CONTACT_TYPES_URL}/${contactType.id}`,
    slice.actions
  )

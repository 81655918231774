import React from 'react'
import { Tag as ANTDTag, Typography } from 'antd'
import { useAppSelector } from '../../reducers/hooks'
import { PerformanceType } from '../../models/Performance'
import { selectPerformanceTypeById } from '../../reducers/PerformanceTypeReducer'

const { Text } = Typography
interface Props {
  id?: number
  model?: PerformanceType
}

export const Tag = ({ id, model }: Props) => {
  const type = model
    ? model
    : useAppSelector(selectPerformanceTypeById(id ?? 0))
  return type ? <ANTDTag>{type.name}</ANTDTag> : <></>
}

export type TagType = { Tag: typeof Tag }

import { useIntl } from 'react-intl'
import { Alert, Form, FormInstance, Input, Space, Typography } from 'antd'
import { emailRule, requiredRule } from '../../../utils/rules'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { SBAsyncSelect } from '../../../components/custom-inputs/sb-async-select/SBAsyncSelect'
import { SBRMType } from '../SBRMModel'

interface Props {
  form: FormInstance
}

const Create = ({ form }: Props) => {
  const intl = useIntl()

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="email"
        rules={[requiredRule(intl), emailRule(intl)]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Email })}
      >
        <Input />
      </Form.Item>
      <SBAsyncSelect
        type={SBRMType.role}
        name={'role'}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Role })}
        rules={[requiredRule(intl)]}
      />
      <Alert
        style={{ marginTop: 10 }}
        banner
        type="warning"
        message={
          <Space direction="vertical">
            <Typography.Text>
              {intl.formatMessage(
                {
                  id: LocalizationKeys.Page.CRM.Team
                    .SubscriptionWillAdjustDisclaimer,
                },
                { br: <br /> }
              )}
            </Typography.Text>
          </Space>
        }
      />
    </Form>
  )
}

export default Create

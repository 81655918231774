import { useIntl } from 'react-intl'
import { Form, FormInstance, Input } from 'antd'
import { requiredRule } from '../../../utils/rules'
import { SBAsyncSelect } from '../../../components/custom-inputs/sb-async-select/SBAsyncSelect'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

interface Props {
  form: FormInstance
}

const Create = ({ form }: Props) => {
  const intl = useIntl()

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="name"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Name,
        })}
        rules={[requiredRule(intl)]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="registrationNumber"
        rules={[]}
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.RegistrationNumber,
        })}
      >
        <Input />
      </Form.Item>

      <SBAsyncSelect
        type={SBRMType.supplierType}
        name={'type'}
        size={'middle'}
        rules={[]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Type })}
      />
    </Form>
  )
}

export default Create

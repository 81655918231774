import axios from 'axios'
import { useState, useEffect } from 'react'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Button, Skeleton, Space } from 'antd'
import { TENANT_BILLING_PORTAL_URL } from '../../utils/urls'
import { ArrowRightOutlined } from '@ant-design/icons'

export const StripePortalButton = () => {
  const intl = useIntl()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [portalUrl, setPortalUrl] = useState<string>('')

  useEffect(() => {
    setIsLoading(true)
    axios.get(TENANT_BILLING_PORTAL_URL).then((response) => {
      setPortalUrl(response.data.data.url)
      setIsLoading(false)
    })
  }, [])

  if (isLoading) {
    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        <Skeleton.Button size="large" />
      </Space>
    )
  }

  return (
    <Link to={portalUrl} target="_blank">
      <Button type={'primary'} icon={<ArrowRightOutlined />}>
        {intl.formatMessage({
          id: LocalizationKeys.Components.Organization.BillingsInfos.Manage,
        })}
      </Button>
    </Link>
  )
}

export type StripePortalButtonType = {
  StripePortalButton: typeof StripePortalButton
}

import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Alert, Form, Input, InputNumber, Radio } from 'antd'
import { numberRule, requiredRule, stringRule } from '../../../utils/rules'
import TextArea from 'antd/es/input/TextArea'
import { File } from '../../../components/file/File'
import { SBRMFormStepProps, SBRMType } from '../../../modules/sbrm/SBRMModel'
import { SBAsyncSelect } from '../../../components/custom-inputs/sb-async-select/SBAsyncSelect'
import { MetaDataKey } from '../../../models/MetaData'
import { fieldFormatter } from '../../../utils/formatters'
import { useSBRMData } from '../../../modules/sbrm/hooks'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { useTenantSettings } from '../../../utils/hooks/useTenantSettings'

interface Props extends SBRMFormStepProps {
  eventId?: number
}

const Create = ({ form, isNested, eventId }: Props) => {
  const intl = useIntl()
  const { currency } = useTenantSettings()

  const [viaPDFUpload, setViaPDFUpload] = useState<boolean>(true)
  const [selectedEventId, setSelectedEventId] = useState<number | undefined>(
    undefined
  )
  const [fileSizeWarning, setFileSizeWarning] = useState<boolean>(false)

  const { isOpen: SBRMIsOpen } = useSBRMData(isNested)

  // 10 Mb
  const maxFileSize = 10 * 1024 * 1024

  useEffect(() => {
    if (!SBRMIsOpen) {
      // We want to trigger only the reset
      // when the SBRM opens
      return
    }
    form.setFieldValue('event', eventId)
    form.setFieldValue('method', 0)
    setViaPDFUpload(true)
    setSelectedEventId(eventId)
  }, [SBRMIsOpen])

  const valuesChanged = () => {
    setSelectedEventId(form.getFieldValue('event'))
    const selectedFile = form.getFieldValue('file')

    if (selectedFile?.file.size >= maxFileSize) {
      setFileSizeWarning(true)
      return false
    }
    setFileSizeWarning(false)
    return true
  }

  return (
    <Form form={form} layout="vertical" onValuesChange={() => valuesChanged()}>
      {!eventId && (
        <SBAsyncSelect
          type={SBRMType.event}
          name={'event'}
          rules={[requiredRule(intl)]}
          label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Event })}
          metadata={[
            {
              key: MetaDataKey.eventId,
              value: eventId,
            },
          ]}
        />
      )}
      {eventId && (
        <Form.Item name="event" rules={[requiredRule(intl)]} className="d-none">
          <Input type="hidden" />
        </Form.Item>
      )}
      <Form.Item
        name="method"
        initialValue={0}
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Invoice.HowToCreate,
        })}
        rules={[requiredRule(intl)]}
      >
        <Radio.Group
          onChange={(e: any) => setViaPDFUpload(e.target.value === 0)}
        >
          <Radio.Button value={0}>
            {intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Invoice.UploadPDF,
            })}
          </Radio.Button>
          <Radio.Button value={1}>
            {intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Invoice.Manual,
            })}
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
      {viaPDFUpload && (
        <File.Dropzone
          multiple={false}
          name="file"
          rules={[requiredRule(intl)]}
        />
      )}
      {fileSizeWarning && (
        <Alert
          type="warning"
          message={intl.formatMessage({
            id: LocalizationKeys.Misc.Form.Invoice.FileSizeWarning,
          })}
          showIcon
          style={{ marginTop: 10 }}
        />
      )}
      {!viaPDFUpload && (
        <>
          <Form.Item
            name="reference"
            label={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Invoice.Reference,
            })}
            rules={[requiredRule(intl), stringRule(intl)]}
          >
            <Input />
          </Form.Item>
          <SBAsyncSelect
            size="middle"
            type={SBRMType.currency}
            name={'currency'}
            rules={[requiredRule(intl)]}
            label={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Currency,
            })}
          />
          <Form.Item
            name="amountIncludingTaxes"
            label={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Invoice.AmountInclTaxes,
            })}
            rules={[requiredRule(intl), numberRule(intl)]}
          >
            <InputNumber
              min={0}
              className="w-full"
              addonBefore={currency?.symbol}
              formatter={fieldFormatter}
            />
          </Form.Item>
          <Form.Item
            name="amountExcludingTaxes"
            label={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Invoice.AmountExclTaxes,
            })}
            rules={[requiredRule(intl), numberRule(intl)]}
          >
            <InputNumber
              min={0}
              className="w-full"
              addonBefore={currency?.symbol}
              formatter={fieldFormatter}
            />
          </Form.Item>
          <Form.Item
            name="notes"
            rules={[stringRule(intl)]}
            label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Notes })}
          >
            <TextArea rows={5} />
          </Form.Item>
        </>
      )}
    </Form>
  )
}

export default Create

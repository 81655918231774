import { forwardRef, Ref, useImperativeHandle } from 'react'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import { Alert, Form } from 'antd'
import Create from './Create'
import Update from './Update'
import Delete from './Delete'
import {
  createRoom,
  deleteRoom,
  updateRoom,
} from '../../../reducers/RoomReducer'
import { MetaDataKey } from '../../../models/MetaData'
import { useSBRMData } from '../../../modules/sbrm/hooks'
import {
  getMetadata,
  SBRMFormInterface,
  SBRMFormProps,
} from '../../../modules/sbrm/SBRMModel'

const RoomForm = forwardRef(
  ({ isNested }: SBRMFormProps, ref: Ref<SBRMFormInterface>) => {
    useImperativeHandle(ref, () => ({
      handleCreate,
      handleUpdate,
      handleDelete,
      handleReset,
    }))

    const dispatch = useAppDispatch()
    const { action, metadata } = useSBRMData(isNested)
    const { error } = useAppSelector((state) => state.room)

    const [createForm] = Form.useForm()
    const [updateForm] = Form.useForm()
    const [deleteForm] = Form.useForm()

    const handleCreate = async () =>
      await createForm.validateFields().then(async (result) => {
        let datesField = createForm.getFieldValue('dates')
        if (
          Array.isArray(datesField) &&
          datesField.length === 2 &&
          datesField[0] !== '' &&
          datesField[1] !== ''
        ) {
          datesField = [
            datesField[0].toISOString(),
            datesField[1].toISOString(),
          ]
        } else {
          datesField = undefined
        }

        return dispatch(
          createRoom({
            ...createForm.getFieldsValue(),
            dates: undefined, // Erase `dates` value to avoid non-serializable dayjs() in store
            from: datesField !== undefined ? datesField[0] : null,
            to: datesField !== undefined ? datesField[1] : null,
          })
        ).then(() => {
          createForm.resetFields()
          return true
        })
      })

    const handleUpdate = async () =>
      await updateForm.validateFields().then(async (result) => {
        let datesField = updateForm.getFieldValue('dates')
        if (
          Array.isArray(datesField) &&
          datesField.length === 2 &&
          datesField[0] !== '' &&
          datesField[1] !== ''
        ) {
          datesField = [
            datesField[0].toISOString(),
            datesField[1].toISOString(),
          ]
        } else {
          datesField = undefined
        }

        return dispatch(
          updateRoom({
            ...updateForm.getFieldsValue(),
            dates: undefined, // Erase `dates` value to avoid non-serializable dayjs() in store
            from: datesField !== undefined ? datesField[0] : null,
            to: datesField !== undefined ? datesField[1] : null,
          })
        ).then(() => {
          updateForm.resetFields()
          return true
        })
      })

    const handleDelete = async () =>
      await deleteForm.validateFields().then(async (result) =>
        dispatch(deleteRoom(deleteForm.getFieldsValue())).then(() => {
          deleteForm.resetFields()
          return true
        })
      )

    const handleReset = () => {
      createForm.resetFields()
      updateForm.resetFields()
      deleteForm.resetFields()
    }

    return (
      <>
        {(action === 'create' || action === 'duplicate') && (
          <Create
            form={createForm}
            duplicate={action === 'duplicate'}
            eventId={getMetadata(metadata, MetaDataKey.eventId)}
            bookingId={getMetadata(metadata, MetaDataKey.bookingId)}
          />
        )}
        {action === 'update' && <Update form={updateForm} />}
        {action === 'delete' && <Delete form={deleteForm} />}
        {error && <Alert message={String(error)} type="error" />}
      </>
    )
  }
)

RoomForm.displayName = 'RoomForm'

type RoomFormType = { Form: typeof RoomForm }

export { RoomForm, type RoomFormType }

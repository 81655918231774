import { Empty, Form, Radio, Select, Spin } from 'antd'
import { FormItemProps } from 'antd/es/form'
import { debounce } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { MetaData, MetaDataKey } from '../../../models/MetaData'
import { TableParams } from '../../../models/TableParams'
import { SBRMType, getMetadata } from '../../../modules/sbrm/SBRMModel'
import { initialQuery } from '../../../utils/helpers/crud/models'
import { SBModel } from '../../../utils/helpers/crud/models'
import { SBAPIFetchPaginated } from '../../../utils/helpers/SBAPIHelper'
import { useAppSelector } from '../../../reducers/hooks'
import { Actions } from '../../../modules/sbrm/components/Actions'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import getConfigInstance from './config/SBAsyncSelectConfig'
import { mergeObjectArraysWithoutDuplicates } from '../../../utils/helpers/ReducerHelper'

const { Option } = Select

interface SBAsyncSelectProps {
  type: SBRMType
  multiple?: boolean
  metadata?: MetaData[]
  allowClear?: boolean | undefined
  style?: React.CSSProperties
  size?: SizeType
  placeholder?: string
  outsideSBRM?: boolean
}

export const SBAsyncSelect = <T extends SBModel>({
  type,
  name,
  label,
  rules,
  extra,
  multiple = false,
  metadata,
  hidden,
  allowClear,
  style,
  size = 'large',
  placeholder = '',
  outsideSBRM = false,
}: SBAsyncSelectProps & FormItemProps) => {
  const intl = useIntl()
  const form = Form.useFormInstance()
  const formItemValue = Form.useWatch(name, form)
  const [items, setItems] = useState<T[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [query, setQuery] = useState<TableParams>(initialQuery)
  const [itemSelected, setItemSelected] = useState<T | undefined>(undefined)

  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)
  const { isOpen: nestedIsOpen } = useAppSelector((state) => state.SBRM.nested)

  const config = getConfigInstance().getConfigForType(type)

  const handleSearch = (newValue: string) => {
    let newQuery: TableParams = {
      ...composeQueryFiltered(),
      search: { name: newValue },
    }

    if (!newValue) {
      delete newQuery.search
    }

    setQuery(newQuery)
    fetchDataForQuery(newQuery)
  }
  const debouncedSearch = useMemo(() => debounce(handleSearch, 500), [metadata])

  const handleOnChange = (e: any) => {
    setItemSelected(items.find((i) => i.id === e))
  }

  const composeQueryFiltered = () => {
    // Instantiate a base query without any filter
    var query: TableParams = { ...initialQuery }

    // If needed, add filters
    if (!metadata) {
      return query
    }

    const keysToWrapInArray = [
      {
        metadataKey: MetaDataKey.eventId,
        filterKey: 'events',
        wrapInArray: true,
      },
      {
        metadataKey: MetaDataKey.venueId,
        filterKey: 'venues',
        wrapInArray: true,
      },
      {
        metadataKey: MetaDataKey.venuesId,
        filterKey: 'venues',
        wrapInArray: false,
      },
      {
        metadataKey: MetaDataKey.stationType,
        filterKey: 'type',
        wrapInArray: false,
      },
    ]
    for (const item of keysToWrapInArray) {
      const data = getMetadata(metadata, item.metadataKey)

      if (data === undefined) {
        continue
      }
      query = {
        ...query,
        filters: {
          [item.filterKey]: item.wrapInArray ? [data] : data,
        },
      }
    }

    return query
  }

  const fetchDataForQuery = (
    newQuery: TableParams,
    mode: 'replace' | 'merge' = 'replace'
  ): Promise<any> => {
    if (config === undefined) return Promise.resolve(true)

    setIsLoading(true)
    return SBAPIFetchPaginated<SBModel>(config.endpoint, newQuery)
      .then((data: any) => {
        setItems(
          mode === 'replace'
            ? data.data
            : mergeObjectArraysWithoutDuplicates(items, data.data)
        )
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    if (!SBRMIsOpen) {
      // We want to trigger only the reset
      // when the SBRM opens
      return
    }
    setItemSelected(undefined)

    const baseQuery = composeQueryFiltered()
    setQuery(baseQuery)
    fetchDataForQuery(baseQuery)
  }, [SBRMIsOpen, setQuery, metadata])

  useEffect(() => {
    if (!outsideSBRM) {
      return
    }
    // If the component is placed outside the SBRM
    // we need to trigger the initial request manually
    setItemSelected(undefined)

    const baseQuery = composeQueryFiltered()
    setQuery(baseQuery)
    fetchDataForQuery(baseQuery)
  }, [outsideSBRM])

  /**
   * When the field value changes make sure we always have the proper data to display
   * If the data is missing, only the ID will be displayed in the Select
   * Which is confusing for the user
   */
  useEffect(() => {
    if (!formItemValue) {
      // If there is no value in the field there is no missing data
      return
    }
    // If it's not an array, wrap it in array
    const value = !Array.isArray(formItemValue)
      ? [formItemValue]
      : formItemValue
    const existingIds = items.map((item) => item.id) // Map existing items to an array of IDs
    const missingIds = value.filter((id) => !existingIds.includes(id)) // Compute missing IDs

    if (missingIds.length === 0) return

    // Fetch data for missing IDs
    const baseQuery = composeQueryFiltered()
    setQuery(baseQuery)

    fetchDataForQuery({ ...baseQuery, ids: missingIds }).then(() =>
      fetchDataForQuery(baseQuery, 'merge')
    )
  }, [formItemValue])

  useEffect(() => {
    return () => {
      debouncedSearch.cancel()
    }
  })

  if (config === undefined) {
    throw Error('No config defined for type: ' + type)
  }

  return (
    <>
      <Form.Item
        name={name}
        label={label}
        rules={rules}
        hidden={hidden}
        style={style}
        extra={extra}
      >
        {isLoading && config.canbeRadio ? (
          <Spin />
        ) : config.canbeRadio && items.length < 4 ? (
          <Radio.Group>
            {items.map((item) => (
              <Radio.Button key={item.id} value={item.id}>
                {config.option(item)}
              </Radio.Button>
            ))}
          </Radio.Group>
        ) : (
          <Select
            size={size}
            loading={isLoading}
            filterOption={false}
            showSearch={true}
            onSearch={debouncedSearch}
            onChange={handleOnChange}
            onClick={() => debouncedSearch}
            allowClear={allowClear}
            mode={multiple ? 'multiple' : undefined}
            placeholder={placeholder}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={intl.formatMessage(
                  { id: LocalizationKeys.SBAsyncSelect.NotFound },
                  {
                    type: intl.formatMessage({
                      id: LocalizationKeys.Entity[type],
                    }),
                  }
                )}
              >
                {!nestedIsOpen && config.displayEmpty && (
                  <Actions actions={['create']} entity={type} />
                )}
              </Empty>
            }
            dropdownRender={
              config.footer !== undefined
                ? (menu) => (
                    <>
                      {menu}
                      {config.footer!(() => handleSearch(''))}
                    </>
                  )
                : undefined
            }
          >
            {items.map((item) => (
              <Option key={item.id} value={item.id}>
                {config.option(item)}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
      {itemSelected &&
        config.extra !== undefined &&
        config.extra!(itemSelected)}
    </>
  )
}

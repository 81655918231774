import { Card, Col, Row, Space } from 'antd'
import { selectSelectedEvent } from '../../reducers/EventReducer'
import { useAppSelector } from '../../reducers/hooks'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { useState } from 'react'
import { Actions } from '../../modules/sbrm/components/Actions'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { MetaDataKey } from '../../models/MetaData'
import HasAccess from '../../components/HasAccess'
import { AlelaPermission } from '../../utils/permissions'
import { useIntl } from 'react-intl'
import { selectTasks } from '../../reducers/TaskReducer'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { Task } from '../../components/task/Task'

const TaskPage = () => {
  const intl = useIntl()

  const [selectedView, setSelectedView] = useState<'table' | 'kanban'>('table')

  const event = useAppSelector(selectSelectedEvent())

  return (
    <Row className="container-row">
      <Col span={24}>
        <HasAccess permissions={[AlelaPermission.listTask]}>
          <PageTitle
            title={intl.formatMessage({
              id: LocalizationKeys.Layout.EventDetailsSider.Tasks,
            })}
            level={5}
            toolbar={
              <Space>
                {/* <Segmented
              onChange={(tab) => setSelectedView(tab as any)}
              options={[
                {
                  value: 'table',
                  icon: <TableOutlined />,
                },
                {
                  value: 'kanban',
                  icon: <BorderHorizontalOutlined />,
                },
              ]}
            /> */}
                <Actions
                  actions={['create']}
                  entity={SBRMType.task}
                  metadata={[
                    {
                      key: MetaDataKey.taskParentType,
                      value: SBRMType.event,
                    },
                    { key: MetaDataKey.taskParentId, value: event?.id },
                  ]}
                />
              </Space>
            }
          />
          <Row gutter={16} style={{ marginTop: '10px' }}>
            <Col md={24} sm={24} xs={24}>
              {selectedView === 'table' && (
                <Task.ListForEvent eventId={event?.id} />
              )}
              {selectedView === 'kanban' && <Card bordered={false}></Card>}
            </Col>
          </Row>
        </HasAccess>
      </Col>
    </Row>
  )
}

export default TaskPage

import Create from './Create'
import Delete from './Delete'
import { Alert, Form } from 'antd'
import { forwardRef, Ref, useImperativeHandle } from 'react'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import { MetaDataKey } from '../../../models/MetaData'
import { createPayment, deletePayment } from '../../../reducers/PaymentReducer'
import { useSBRMData } from '../../../modules/sbrm/hooks'
import {
  getMetadata,
  SBRMFormInterface,
  SBRMFormProps,
} from '../../../modules/sbrm/SBRMModel'

export const PaymentForm = forwardRef(
  ({ isNested }: SBRMFormProps, ref: Ref<SBRMFormInterface>) => {
    useImperativeHandle(ref, () => ({
      handleCreate,
      handleUpdate,
      handleDelete,
      handleReset,
    }))

    const dispatch = useAppDispatch()
    const { action, metadata } = useSBRMData(isNested)
    const { error } = useAppSelector((state) => state.payment)

    const [createForm] = Form.useForm()
    const [deleteForm] = Form.useForm()

    const handleCreate = async () =>
      await createForm.validateFields().then(async (result) => {
        const data = new FormData()
        data.append('invoice', createForm.getFieldValue('invoice'))
        data.append('amount', createForm.getFieldValue('amount'))
        data.append('type', createForm.getFieldValue('type'))
        data.append('date', createForm.getFieldValue('date'))
        data.append('currency', createForm.getFieldValue('currency'))

        if (createForm.getFieldValue('pop')) {
          data.append(
            'pop',
            createForm.getFieldValue('pop').fileList[0].originFileObj
          )
        }

        return dispatch(createPayment(data)).then(() => {
          createForm.resetFields()
          return true
        })
      })

    const handleUpdate = async () => await true

    const handleDelete = async () =>
      await deleteForm.validateFields().then(async (result) =>
        dispatch(deletePayment(deleteForm.getFieldsValue())).then(() => {
          deleteForm.resetFields()
          return true
        })
      )

    const handleReset = () => {
      createForm.resetFields()
      deleteForm.resetFields()
    }

    return (
      <>
        {action === 'create' && (
          <Create
            isNested={isNested}
            form={createForm}
            invoiceId={getMetadata(metadata, MetaDataKey.invoiceId)}
          />
        )}
        {action === 'delete' && <Delete form={deleteForm} />}
        {error && <Alert message={String(error)} type="error" />}
      </>
    )
  }
)

PaymentForm.displayName = 'PaymentForm'

export type PaymentFormType = { Form: typeof PaymentForm }

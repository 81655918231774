import { useState } from 'react'
import Roadsheet from './Roadsheet'
import { Button, Tooltip } from 'antd'
import { ROADSHEETS_URL } from '../../utils/urls'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { RoadsheetData } from '../../models/Roadsheet'
import { SBAPIFetch } from '../../utils/helpers/SBAPIHelper'
import { DownloadOutlined, FileTextOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'

interface Props {
  eventId: number
  bookingId?: number
}

const RoadsheetGenerator = ({ eventId, bookingId }: Props) => {
  const intl = useIntl()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<RoadsheetData | undefined>(undefined)

  const fetchRoadsheetDataForBooking = () => {
    setIsLoading(true)
    SBAPIFetch<RoadsheetData>(
      `${ROADSHEETS_URL}/data?event=${eventId}&booking=${bookingId}`
    ).then((response) => {
      setData(response.data)
      setIsLoading(false)
    })
  }

  return (
    <>
      {data === undefined && (
        <Tooltip
          title={intl.formatMessage({
            id: LocalizationKeys.Roadsheet.GenerateRoadsheet,
          })}
        >
          <Button
            type="primary"
            loading={isLoading}
            onClick={fetchRoadsheetDataForBooking}
            icon={<FileTextOutlined />}
          />
        </Tooltip>
      )}
      {data !== undefined && (
        <PDFDownloadLink
          document={<Roadsheet data={data} />}
          fileName={`${data.bookings[0].artist.name}-${data.event.name}.pdf`}
        >
          {({ blob, url, loading, error }) => (
            <Tooltip title={'Download'}>
              <Button type="primary" icon={<DownloadOutlined />} />
            </Tooltip>
          )}
        </PDFDownloadLink>
      )}
    </>
  )
}

export default RoadsheetGenerator

import { Space, Tag } from 'antd'
import { Booking } from '../booking/Booking'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { useEffect } from 'react'
import { initialQuery } from '../../utils/helpers/crud/models'
import { TableParams } from '../../models/TableParams'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import BaseExternalRequests from '../BaseExternalRequests'
import { Room } from './Room'
import {
  deleteRoom,
  getNonApprovedRooms,
  selectAllNotApprovedRooms,
  updateRoom,
} from '../../reducers/RoomReducer'
import { Hotel } from '../hotel/Hotel'
import { Contact } from '../contact/Contact'

interface ExternalRequestsProps {
  eventId: number
  shouldRefreshList: () => void
}

const ExternalRequests = ({
  eventId,
  shouldRefreshList,
}: ExternalRequestsProps) => {
  const dispatch = useAppDispatch()
  const rooms = useAppSelector(selectAllNotApprovedRooms())

  useEffect(() => {
    const baseQuery: TableParams = {
      ...initialQuery,
      filters: { events: [eventId] },
      columnKey: 'time',
      order: 'ascend',
    }
    dispatch(getNonApprovedRooms(baseQuery))
  }, [])

  if (rooms.length === 0) return <></>

  return (
    <BaseExternalRequests
      entity={SBRMType.room}
      items={rooms}
      renderItem={(item) => (
        <Space size={10}>
          <a href="#" onClick={(e) => e.stopPropagation()}>
            <Booking.Avatar id={item.booking ?? 0} />
          </a>
          <Space size={5} direction="vertical">
            <Hotel.Avatar id={item.hotel!} linkToDetails={false} />
            {(item.reference != null || item.category != null) && (
              <Space size={0}>
                {item.reference && <Tag>{item.reference}</Tag>}
                {item.category && <Tag>{item.category}</Tag>}
              </Space>
            )}
          </Space>
          <Room.Price id={item.id} />
          <Room.Nights id={item.id} />
          <Contact.AvatarGroup ids={item.contacts} />
        </Space>
      )}
      updateMethod={updateRoom}
      deleteMethod={deleteRoom}
      shouldRefreshList={shouldRefreshList}
    />
  )
}

type ExternalRequestsType = { ExternalRequests: typeof ExternalRequests }

export { ExternalRequests, type ExternalRequestsType }

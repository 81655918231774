import { Alert, Button, Form, Input, Result } from 'antd'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { forgotPassword } from '../../utils/auth/requests'
import { emailRule, requiredRule } from '../../utils/rules'
import LocalizationKeys from '../../i18n/LocalizationKeys'

const ForgotPasswordPage = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  const [data, setData] = useState({ email: '' })
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [hasError, setHasError] = useState(false)

  const onFinish = async () => {
    setIsLoading(true)
    setHasError(false)
    try {
      await forgotPassword(data.email)
      setIsSuccess(true)
    } catch (error) {
      setHasError(true)
      setIsLoading(false)
    }
  }

  return isSuccess ? (
    <Result
      status="success"
      title="Check your inbox"
      subTitle={intl.formatMessage({
        id: LocalizationKeys.Auth.ForgotPassword.Success,
      })}
      extra={[
        <Button
          key={'back-login'}
          type="primary"
          onClick={() => navigate('/auth/login')}
        >
          {intl.formatMessage({
            id: LocalizationKeys.Auth.ForgotPassword.BackToLogin,
          })}
        </Button>,
      ]}
    />
  ) : (
    <>
      <Form
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onValuesChange={(_, allFields) => setData(allFields)}
      >
        <Form.Item name="email" rules={[requiredRule(intl), emailRule(intl)]}>
          <Input
            placeholder={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Email,
            })}
          />
        </Form.Item>

        <Form.Item>
          <Button
            className="w-full"
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            {intl.formatMessage({
              id: LocalizationKeys.Auth.ForgotPassword.Button,
            })}
          </Button>
        </Form.Item>
      </Form>
      {hasError && (
        <Alert
          style={{ marginBottom: 25 }}
          message={intl.formatMessage({
            id: LocalizationKeys.Auth.ForgotPassword.Failure,
          })}
          type="error"
        />
      )}
      <Button
        className="w-full"
        type="text"
        onClick={() => navigate('/auth/login')}
      >
        {intl.formatMessage({
          id: LocalizationKeys.Auth.ForgotPassword.BackToLogin,
        })}
      </Button>
    </>
  )
}

export default ForgotPasswordPage

import { FC } from 'react'
import { Donut, DonutType } from './Donut'
import { Balance, BalanceType } from './Balance'
import { Wizard, WizardType } from './Wizard'
import { ItemType, ItemTypeType } from './ItemType'
import { Overview, OverviewType } from './Overview'
import { EventAlert, EventAlertType } from './EventAlert'
import { ReorderItems, ReorderItemsType } from './ReorderItems'
import { Preview, PreviewType } from './Preview'
import { Filters, FiltersType } from './Filters'
import { Charts, ChartsType } from './Charts'

export const Reporting: FC &
  DonutType &
  BalanceType &
  WizardType &
  ItemTypeType &
  OverviewType &
  EventAlertType &
  ReorderItemsType &
  PreviewType &
  FiltersType &
  ChartsType = (): JSX.Element => <></>

Reporting.Donut = Donut
Reporting.Balance = Balance
Reporting.Wizard = Wizard
Reporting.ItemType = ItemType
Reporting.Overview = Overview
Reporting.EventAlert = EventAlert
Reporting.ReorderItems = ReorderItems
Reporting.Preview = Preview
Reporting.Filters = Filters
Reporting.Charts = Charts

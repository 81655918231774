import { Ref, forwardRef, useImperativeHandle, useState } from 'react'
import { selectReportItemsByIds } from '../../reducers/ReportItemReducer'
import { useAppSelector } from '../../reducers/hooks'
import { Tree, TreeDataNode, TreeProps } from 'antd'

interface Props {
  ids: number[]
  rootTitle: string
}

interface ReorderItemsInterface {
  getData: () => TreeDataNode[]
}

const ReorderItems = forwardRef(
  ({ ids, rootTitle }: Props, ref: Ref<ReorderItemsInterface>) => {
    useImperativeHandle(ref, () => ({ getData }))
    const getData = () => treeData

    const items = useAppSelector(selectReportItemsByIds(ids)).sort(
      (a, b) => a.order - b.order
    )

    const [treeData, setTreeData] = useState<TreeDataNode[]>([
      {
        title: rootTitle,
        disabled: true,
        key: '0',
        children: items.map((item) => ({
          isLeaf: true,
          title: item.name,
          key: item.id,
        })),
      },
    ])

    const onDrop: TreeProps['onDrop'] = (info) => {
      const dropKey = info.node.key
      const dragKey = info.dragNode.key
      const dropPos = info.node.pos.split('-')
      const dropPosition =
        info.dropPosition - Number(dropPos[dropPos.length - 1])

      /**
       * Do not handle item dropped on item
       * But handle when item is dropped on the top
       */
      if (!info.dropToGap && dropKey !== '0') return

      const loop = (
        data: TreeDataNode[],
        key: React.Key,
        callback: (node: TreeDataNode, i: number, data: TreeDataNode[]) => void
      ) => {
        for (let i = 0; i < data.length; i++) {
          if (data[i].key === key) {
            return callback(data[i], i, data)
          }
          if (data[i].children) {
            loop(data[i].children!, key, callback)
          }
        }
      }
      const data = [...treeData]

      // Find dragObject
      let dragObj: TreeDataNode
      loop(data, dragKey, (item, index, arr) => {
        arr.splice(index, 1)
        dragObj = item
      })

      if (!info.dropToGap) {
        // Drop on the content
        loop(data, dropKey, (item, index, arr) => {
          item.children = item.children || []
          // where to insert. New item was inserted to the start of the array in this example, but can be anywhere
          item.children.unshift(dragObj)
        })
      } else {
        let ar: TreeDataNode[] = []
        let i: number
        loop(data, dropKey, (_item, index, arr) => {
          ar = arr
          i = index
        })
        if (dropPosition === -1) {
          // Drop on the top of the drop node
          ar.splice(i!, 0, dragObj!)
        } else {
          // Drop on the bottom of the drop node
          ar.splice(i! + 1, 0, dragObj!)
        }
      }
      setTreeData(data)
    }

    return (
      <Tree
        className="draggable-tree"
        draggable
        blockNode
        onDrop={onDrop}
        defaultExpandAll
        autoExpandParent
        treeData={treeData}
      />
    )
  }
)

ReorderItems.displayName = 'ReorderItems'

type ReorderItemsType = { ReorderItems: typeof ReorderItems }

export { ReorderItems, type ReorderItemsType, type ReorderItemsInterface }

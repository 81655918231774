import {
  Form,
  FormInstance,
  Input,
  Row,
  Col,
  Checkbox,
  Card,
  Space,
  Switch,
} from 'antd'
import dayjs from 'dayjs'
import { useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import { useAppSelector } from '../../../reducers/hooks'
import { Ground } from '../../../components/ground/Ground'
import { selectSelectedGround } from '../../../reducers/GroundReducer'
import { requiredRule, stringRule } from '../../../utils/rules'
import { SBAsyncSelect } from '../../../components/custom-inputs/sb-async-select/SBAsyncSelect'
import { GroundCascaderInterface } from '../../../components/ground/Cascader'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import {
  GroundContextProvider,
  useGroundContext,
} from '../../../utils/context/GroundContext'
import { LinkOutlined } from '@ant-design/icons'
import ExternalAdvancingStatus from '../../ExternalAdvancingStatus'

interface Props {
  form: FormInstance
  eventId: number
}

const { TextArea } = Input

const Update = ({ form, eventId }: Props) => {
  const intl = useIntl()

  const cascaderRef = useRef<GroundCascaderInterface>(null)
  const { fillCascader } = useGroundContext()

  const ground = useAppSelector(selectSelectedGround())

  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  useEffect(() => {
    if (!SBRMIsOpen) {
      // We want to trigger only the reset
      // when the SBRM opens
      return
    }

    // Set one by one to avoid setting`from` and `to`
    // Which are set in the Ground.Cascader component
    form.setFieldValue('id', ground?.id)
    form.setFieldValue('pickupTime', dayjs(ground?.pickupTime))
    form.setFieldValue('timeMode', 'departAt')
    form.setFieldValue('duration', ground?.duration)
    form.setFieldValue('driver', ground?.driver)
    form.setFieldValue('vehicleDetails', ground?.vehicleDetails)
    form.setFieldValue('passengers', ground?.passengers)
    form.setFieldValue('notes', ground?.notes)
    form.setFieldValue('isApproved', ground?.isApproved)
    fillCascader()
  }, [SBRMIsOpen])

  if (ground === undefined) {
    return <></>
  }

  return (
    <Form form={form} layout="vertical">
      <GroundContextProvider bookingId={ground?.booking} initialValue={ground}>
        <Form.Item name="id" rules={[requiredRule(intl)]} className="d-none">
          <Input type="hidden" />
        </Form.Item>

        <Ground.Cascader
          ref={cascaderRef}
          eventId={eventId}
          initialValue={ground}
          bookingId={ground?.booking}
        />
        <Row gutter={[16, 16]}>
          <Col span={16}>
            <Ground.PickupTimeFormItem eventId={eventId} />
          </Col>
          <Col span={8}>
            <Ground.DurationFormItem />
          </Col>
        </Row>

        <SBAsyncSelect
          type={SBRMType.contact}
          name={'driver'}
          rules={[]}
          label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Driver })}
        />
        <Form.Item
          name="vehicleDetails"
          rules={[stringRule(intl)]}
          label={intl.formatMessage({
            id: LocalizationKeys.Misc.Form.VehicleDetails,
          })}
        >
          <Input className="w-full" />
        </Form.Item>
        <SBAsyncSelect
          type={SBRMType.contact}
          name={'passengers'}
          multiple={true}
          rules={[]}
          label={intl.formatMessage({
            id: LocalizationKeys.Misc.Form.Passengers,
          })}
        />
        <Form.Item
          name="notes"
          rules={[stringRule(intl)]}
          label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Notes })}
        >
          <TextArea rows={5} />
        </Form.Item>
        <ExternalAdvancingStatus />
      </GroundContextProvider>
    </Form>
  )
}

export default Update

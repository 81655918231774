const getLocalStorageItem = <T>(key: string): T | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(key)
  if (!lsValue) {
    return
  }

  try {
    const item: T = JSON.parse(lsValue) as T
    if (item) {
      return item
    }
  } catch (error) {
    console.error('LOCAL STORAGE ITEM PARSING ERROR', error)
  }
}

const setLocalStorageItem = <T>(key: string, item: T): void => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(item)
    localStorage.setItem(key, lsValue)
  } catch (error) {
    console.error(`LOCAL STORAGE SAVE ITEM ${key} ERROR`, error)
  }
}

const removeLocalStorageItem = (key: string): void => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(key)
  } catch (error) {
    console.error('LOCAL STORAGE REMOVE ITEM ERROR', error)
  }
}

export { getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem }

import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAPIPing } from '../modules/api-ping/hooks'
import { Button, Col, Layout, Row, Space, Typography } from 'antd'
import LocalizationKeys from '../i18n/LocalizationKeys'
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '../utils/helpers/LocalStorageHelper'

const { Text, Title } = Typography
const { Content } = Layout

interface Props {
  children: JSX.Element
}

export const MobileDisclaimer = ({ children }: Props) => {
  const intl = useIntl()

  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  )

  const [displayDisclaimer, setDisplayDisclaimer] = useState<boolean>(false)

  const dontCare = () => {
    setLocalStorageItem('MobileDisclaimer', 'dontShowAgain')
    window.location.reload()
  }

  useEffect(() => {
    setDisplayDisclaimer(
      getLocalStorageItem('MobileDisclaimer') != 'dontShowAgain' && vw < 800
    )
  }, [])

  if (displayDisclaimer) {
    return (
      <Layout>
        <Content>
          <Row
            align="middle"
            style={{
              minHeight: '100vh',
              margin: 0,
              textAlign: 'center',
              padding: 30,
            }}
          >
            <Col span={24} style={{ textAlign: 'center' }}>
              <Space direction="vertical" size={25}>
                <Space direction="vertical">
                  <Title level={3}>
                    {intl.formatMessage({
                      id: LocalizationKeys.Components.MobileDisclaimer.Title,
                    })}
                  </Title>
                  <Text style={{ textAlign: 'center' }}>
                    {intl.formatMessage({
                      id: LocalizationKeys.Components.MobileDisclaimer
                        .Description,
                    })}
                  </Text>
                </Space>
                <svg
                  data-icon="BrowserResize"
                  aria-hidden="true"
                  focusable="false"
                  width="100"
                  height="80"
                  viewBox="0 0 52 40"
                >
                  <path
                    d="M26 32v-2h-4.586l6.293-6.293L32 19.414V24h2v-8h-8v2h4.586l-4.293 4.293L20 28.586V24h-2v8zM2 8h48V2H2zm0 30h48V10H2zM52 0v40H0V0zM11 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2M8 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2M4 5a1 1 0 1 1 2 0 1 1 0 0 1-2 0"
                    fill="#dc4446"
                  ></path>
                </svg>

                <Button type="default" onClick={() => dontCare()}>
                  {intl.formatMessage({
                    id: LocalizationKeys.Components.MobileDisclaimer.Continue,
                  })}
                </Button>
              </Space>
            </Col>
          </Row>
        </Content>
      </Layout>
    )
  }

  return children
}

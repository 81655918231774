import { List, Skeleton, Space } from 'antd'
import { useAppSelector } from '../../reducers/hooks'
import { selectFilesByIds } from '../../reducers/FileReducer'
import { File } from './File'

interface Props {
  ids: number[]
  withDeleteButton?: boolean
  colSize?: number
}

export const FileGrid = ({
  ids,
  withDeleteButton = true,
  colSize = 6,
}: Props) => {
  const { isLoading } = useAppSelector((state) => state.file)
  const files = useAppSelector(selectFilesByIds(ids))

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {isLoading && files.length == 0 ? (
        <Skeleton />
      ) : (
        <List
          grid={{
            gutter: 16,
            column: colSize,
          }}
          dataSource={files}
          renderItem={(item) => (
            <List.Item className="m-0">
              <File.Card id={item.id} withDeleteButton={withDeleteButton} />
            </List.Item>
          )}
        />
      )}
    </Space>
  )
}

export type FileGridType = { Grid: typeof FileGrid }

import {
  CreateReport,
  CreateReportItem,
  Report,
  ReportItem,
  UpdateReport,
} from '../../../models/Report'

const reportToUpdateReport = (
  report: Report,
  eventId: number
): UpdateReport => ({
  ...report,
  event: eventId,
  items: report.items.map((item: ReportItem) => ({
    id: item.id,
    amount: item.amount ?? 0,
  })),
})

const createReportToReport = (
  reportData: CreateReport,
  items: ReportItem[]
): Report => ({
  ...reportData,
  id: 0,
  weather: reportData.weather,
  event: undefined,
  items: reportData.items.map(
    (item: CreateReportItem) =>
      ({
        ...items.find((i) => i.id === item.id),
        amount: item.amount,
      } as ReportItem)
  ),
})

export { reportToUpdateReport, createReportToReport }

import React from 'react'
import { useIntl } from 'react-intl'
import { Form, Cascader as CascaderAntd, Space } from 'antd'
import {
  requiredRule,
  nestedCascaderExceptAddressRule,
} from '../../utils/rules'
import { extractTravelResumeFromCascaderOptionLabel } from '../../utils/extractFromJsx'
import { SBModel } from '../../utils/helpers/crud/models'
import { Address } from '../address/Address'
import { Hotel } from '../../models/Hotel'
import { Venue } from '../../models/Venue'
import {
  Address as AddressModel,
  CustomAddressWithRelation,
} from '../../models/Address'
import { TravelWithRelation } from '../../models/Travel'
import { DefaultOptionType } from 'antd/es/cascader'
import { AddressFormInterface } from '../address/Form'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { Ground } from '../../models/Ground'
import { PlusOutlined } from '@ant-design/icons'
import { Travel } from '../travel/Travel'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import {
  GroundContextCustomAddressField,
  useGroundContext,
} from '../../utils/context/GroundContext'

interface Props {
  direction: string
  customAddressField: GroundContextCustomAddressField

  cascaderIsLoading: boolean
  bookingId: number | undefined

  addressRef: React.RefObject<AddressFormInterface>
  addressToFill: AddressModel | undefined
  setAddressToFill: React.Dispatch<
    React.SetStateAction<AddressModel | undefined>
  >
  initialValue: Ground | undefined
  onChange?: (value: (string | number)[], selectOptions: any[]) => void
}

export const CascaderItem = ({
  direction,
  customAddressField,
  cascaderIsLoading,
  bookingId,
  addressRef,
  addressToFill,
  setAddressToFill,
  initialValue,
  onChange,
}: Props) => {
  const intl = useIntl()
  const { hotels, venues, travels, customAddress } = useGroundContext()

  const label = intl.formatMessage({
    id: LocalizationKeys.Components.Ground.CascaderItem[
      direction === 'from' ? 'From' : 'To'
    ],
  })
  const name = direction === 'from' ? 'from' : 'to'

  const cascaderOptions = [
    {
      value: 'hotel',
      label: intl.formatMessage({
        id: LocalizationKeys.Components.Ground.CascaderItem.Hotels,
      }),
      children: hotels.data.map((hotel) => ({
        value: `${hotel.id}`,
        label: hotel.name,
      })),
    },
    {
      value: 'venue',
      label: intl.formatMessage({
        id: LocalizationKeys.Components.Ground.CascaderItem.Venues,
      }),
      children: venues.data.map((venue) => ({
        value: `${venue.id}`,
        label: venue.name,
      })),
    },
    {
      value: 'inbound',
      label: intl.formatMessage({
        id: LocalizationKeys.Components.Ground.CascaderItem.Inbound,
      }),
      children: travels.data
        .filter((t) => t.inbound)
        .map((travel) => ({
          value: `${travel.id}`,
          label: <Travel.CascaderOptionLabel travel={travel} />,
        })),
    },
    {
      value: 'outbound',
      label: intl.formatMessage({
        id: LocalizationKeys.Components.Ground.CascaderItem.Outbound,
      }),
      children: travels.data
        .filter((t) => !t.inbound)
        .map((travel) => ({
          value: `${travel.id}`,
          label: <Travel.CascaderOptionLabel travel={travel} />,
        })),
    },
    {
      value: 'customaddress',
      label: intl.formatMessage({
        id: LocalizationKeys.Components.Ground.CascaderItem.CustomAddress,
      }),
      children: [
        ...customAddress.data.map((customAddess) => ({
          value: `${customAddess.id}`,
          label: customAddess.address.label,
        })),
        {
          value: 'new_customaddress',
          label: (
            <Space direction="horizontal">
              <PlusOutlined />{' '}
              {intl.formatMessage({ id: LocalizationKeys.Misc.Form.Add })}
            </Space>
          ),
        },
      ],
    },
  ]

  /**
   * Method to filter cascader elements
   * Only local search matching the option label
   */
  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some((option) =>
      typeof option.label === 'string'
        ? option.label.toLowerCase().includes(inputValue.toLowerCase())
        : false
    )

  /**
   * Rewrite the Cascader displayed value
   * @param label The label provided by the cascader
   * @param selectedOptions The selectedOptions provided by the cascader
   *
   * On ground update the Cascader is displaying the resource id instead of the label i.e. `Hotels / 1` instead of `Hotels / Greathotel`
   * This method ensure the resource name is returned when the second arguement is a number
   */
  const cascaderDisplayLabel = (
    label: string[],
    selectedOptions: DefaultOptionType[] | undefined
  ) => {
    if (
      label.length !== 2 ||
      !selectedOptions ||
      selectedOptions.length !== 2 ||
      !selectedOptions[1]
    ) {
      return label.join('/')
    }

    const labelNumber = Number(label[1])
    if (
      selectedOptions[0].value === 'customaddress' &&
      typeof selectedOptions[1].label !== 'string'
    ) {
      // Exception for Custom address Add button
      return intl.formatMessage({
        id: LocalizationKeys.Components.Ground.CascaderItem.NewCustomAddress,
      })
    } else if (
      (selectedOptions[0].value === 'inbound' ||
        selectedOptions[0].value === 'outbound') &&
      React.isValidElement(selectedOptions[1].label)
    ) {
      const extractedText = extractTravelResumeFromCascaderOptionLabel(
        selectedOptions[1].label
      )
      return `${label[0]} / ${extractedText}`
    } else if (Number.isNaN(labelNumber)) {
      return label.join('/')
    }

    const resources: Record<
      string,
      { data: SBModel[]; display: (i: any) => string }
    > = {
      hotel: { data: hotels.data, display: (h: Hotel) => h.name },
      venues: { data: venues.data, display: (v: Venue) => v.name },
      inbound: {
        data: travels.data,
        display: (t: TravelWithRelation) => t.arrival.name,
      },
      outbound: {
        data: travels.data,
        display: (t: TravelWithRelation) => t.departure.name,
      },
      new_customaddress: {
        data: customAddress.data,
        display: (a: CustomAddressWithRelation) => a.address.label,
      },
    }

    const item = resources[selectedOptions[0].value!].data.find(
      (h) => h.id === labelNumber
    )
    return `${label[0]} / ${resources[selectedOptions[0].value!].display(item)}`
  }

  return (
    <>
      <Form.Item
        label={label}
        name={name}
        style={{
          marginBottom: 0,
          display: customAddressField.isVisible ? 'none ' : 'unset',
        }}
        rules={[requiredRule(intl), nestedCascaderExceptAddressRule(intl)]}
      >
        <CascaderAntd
          showSearch={{ filter }}
          options={cascaderOptions}
          placeholder="From"
          loading={cascaderIsLoading}
          disabled={cascaderIsLoading || !bookingId}
          displayRender={cascaderDisplayLabel}
          style={customAddressField.isVisible ? { display: 'none' } : {}}
          onChange={(value, selectedOptions) => {
            /**
             * Display custom address form
             * and fill it if necessary
             */
            const visible =
              value !== undefined &&
              value.length > 1 &&
              value[0] === 'customaddress'
            customAddressField.setIsVisible(visible)
            if (visible && value[1] === 'new_customaddress') {
              addressRef.current?.fillFormWithAddress(undefined, false)
            } else if (visible && value[1] !== undefined) {
              const addressToFill = customAddress.data.find(
                (c) => c.id == value[1]
              )
              /**
               * We don't use the fromAddressRef because if the custom address form juste became visible
               * The fromAddressRef.current will still be `null` as it won't be fully initialized yet
               * Same thing applies for the `to` field below
               */
              setAddressToFill(addressToFill?.address)
            }

            /**
             * Forward change
             */
            if (onChange !== undefined) onChange(value, selectedOptions)
          }}
        />
      </Form.Item>
      <div
        style={{
          display: customAddressField.isVisible ? 'block' : 'none',
        }}
      >
        <Address.Form
          label={label}
          ref={addressRef}
          embededInCascaderItem
          name={`${name}_address`}
          bypassRequiredRules={!customAddressField.isVisible}
          initialValueId={
            initialValue?.[name]?.type === SBRMType.customAddress
              ? initialValue?.[name]?.customaddress?.address
              : undefined
          }
          initialValue={addressToFill}
          handleClear={() => customAddressField.setIsVisible(false)}
        />
      </div>
    </>
  )
}

export default CascaderItem

export type CascaderItemType = { CascaderItem: typeof CascaderItem }

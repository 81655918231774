import {
  Button,
  Card,
  Col,
  Drawer,
  Input,
  InputRef,
  List,
  Row,
  Tooltip,
  Typography,
} from 'antd'
import { useCallback, useEffect, useRef, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { selectArtistsByIds } from '../../reducers/ArtistReducer'
import { selectEventsByIds } from '../../reducers/EventReducer'
import { selectVenuesByIds } from '../../reducers/VenueReducer'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { debounce } from 'lodash'
import { Artist } from '../../components/artist/Artist'
import { Link } from 'react-router-dom'
import {
  getSearchArtists,
  getSearchEvents,
  getSearchSuppliers,
  getSearchVenues,
} from '../../reducers/SearchReducer'
import { Event } from '../../components/event/Event'
import { Venue } from '../../components/venue/Venue'
import { selectSuppliersByIds } from '../../reducers/SupplierReducer'
import { Supplier } from '../../components/supplier/Supplier'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'

const { Title } = Typography

const SearchDrawer = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const {
    query,
    events: searchEvents,
    artists: searchArtists,
    venues: searchVenues,
    suppliers: searchSuppliers,
  } = useAppSelector((state) => state.search)

  const venues = useAppSelector(selectVenuesByIds(searchVenues.ids))
  const events = useAppSelector(selectEventsByIds(searchEvents.ids))
  const artists = useAppSelector(selectArtistsByIds(searchArtists.ids))
  const suppliers = useAppSelector(selectSuppliersByIds(searchSuppliers.ids))

  const inputRef = useRef<InputRef>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    inputRef.current &&
      inputRef.current!.focus({
        cursor: 'all',
      })
  }, [])

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const search = (value: string) => {
    if (value) {
      const newQuery = {
        ...query,
        ...{ search: { name: value } },
      }
      dispatch(getSearchEvents(newQuery))
      dispatch(getSearchVenues(newQuery))
      dispatch(getSearchArtists(newQuery))
      dispatch(getSearchSuppliers(newQuery))
    }
  }

  const debouncedSearch = useCallback(
    debounce((nextValue) => search(nextValue), 300),
    [] // will be created only once initially
  )

  const handleSearch = (event: any) => {
    event.persist()
    const { value: nextValue } = event.target
    // Even though handleSearch is created on each render and executed
    // it references the same debouncedSearch that was created initially
    debouncedSearch(nextValue)
  }

  window.addEventListener('keydown', function (e) {
    if (e.ctrlKey && e.key === 'f') {
      e.stopPropagation
      e.preventDefault
      openModal()
    }
  })

  return (
    <>
      <Tooltip
        title={intl.formatMessage({ id: LocalizationKeys.SearchDrawer.Search })}
      >
        <Button
          type="text"
          icon={<SearchOutlined />}
          onClick={openModal}
          style={{ outline: 'none' }}
        />
      </Tooltip>
      <Drawer
        placement={'top'}
        closable={true}
        onClose={closeModal}
        afterOpenChange={() =>
          inputRef.current!.focus({
            cursor: 'start',
          })
        }
        open={isModalOpen}
        height="100%"
        title={
          <Input
            ref={inputRef}
            style={{ background: '#343434' }}
            bordered={false}
            size="large"
            placeholder={intl.formatMessage({
              id: LocalizationKeys.SearchDrawer.Placeholder,
            })}
            onChange={handleSearch}
          />
        }
      >
        <Row className="container-row">
          {events.length != 0 && (
            <Col md={24}>
              <Title level={3}>
                {intl.formatMessage({
                  id: LocalizationKeys.SearchDrawer.Events,
                })}
              </Title>
              <List
                grid={{ gutter: 16, column: 3 }}
                dataSource={events}
                loading={searchEvents.isLoading}
                renderItem={(item) => (
                  <List.Item onClick={closeModal}>
                    <Card>
                      <Event.Cell id={item.id} />
                    </Card>
                  </List.Item>
                )}
              />
            </Col>
          )}
          {venues.length != 0 && (
            <Col md={24}>
              <Title level={3}>
                {intl.formatMessage({
                  id: LocalizationKeys.SearchDrawer.Venues,
                })}
              </Title>
              <List
                grid={{ gutter: 16, column: 4 }}
                dataSource={venues}
                loading={searchVenues.isLoading}
                renderItem={(item) => (
                  <List.Item onClick={closeModal}>
                    <Card>
                      <Link to={`/venues/${item.id}`}>
                        <Venue.Avatar id={item.id} />
                      </Link>
                    </Card>
                  </List.Item>
                )}
              />
            </Col>
          )}
          {artists.length != 0 && (
            <Col md={24}>
              <Title level={3}>
                {intl.formatMessage({
                  id: LocalizationKeys.SearchDrawer.Artists,
                })}
              </Title>
              <List
                grid={{ gutter: 16, column: 4 }}
                dataSource={artists}
                loading={searchArtists.isLoading}
                renderItem={(item) => (
                  <List.Item onClick={closeModal}>
                    <Card>
                      <Artist.Avatar id={item.id} />
                    </Card>
                  </List.Item>
                )}
              />
            </Col>
          )}
          {suppliers.length != 0 && (
            <Col md={24}>
              <Title level={3}>
                {intl.formatMessage({
                  id: LocalizationKeys.SearchDrawer.Suppliers,
                })}
              </Title>
              <List
                grid={{ gutter: 16, column: 4 }}
                dataSource={suppliers}
                loading={searchSuppliers.isLoading}
                renderItem={(item) => (
                  <List.Item onClick={closeModal}>
                    <Card>
                      <Supplier.Avatar id={item.id} linkToDetails />
                    </Card>
                  </List.Item>
                )}
              />
            </Col>
          )}
        </Row>
      </Drawer>
    </>
  )
}

export default SearchDrawer

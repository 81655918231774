import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIUpdate,
} from '../utils/helpers/SBAPIHelper'
import { EventStatus } from '../models/Event'
import { EVENT_STATUSES_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { eventStatusSchema } from '../models/schema'

const initialState: SBState<EventStatus> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: {
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },
}

const slice = createSlice({
  name: 'eventStatus',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getEventStatusesSuccess,
  setQuery: setEventStatusQuery,
  reset: resetEventStatusState,
  resetQueryAndIds: resetEventStatusQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: EventStatus }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectEventStatuses = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedEventStatus = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectEventStatusById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectEventStatusesByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedEventStatus =
  (eventStatus: EventStatus | number) => async (dispatch: AppDispatch) =>
    dispatch(
      slice.actions.setSelectedId(getIdOrModelId<EventStatus>(eventStatus))
    )

export const getEventStatuses = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<EventStatus>(
    EVENT_STATUSES_URL,
    params,
    [eventStatusSchema],
    slice.actions
  )

export const getEventStatusWithId = (id: number) =>
  SBAPIFetchDispatch<EventStatus>(
    `${EVENT_STATUSES_URL}/${id}`,
    eventStatusSchema,
    slice.actions
  )

export const createEventStatus = (eventStatus: EventStatus) =>
  SBAPICreate<EventStatus>(
    eventStatus,
    EVENT_STATUSES_URL,
    eventStatusSchema,
    slice.actions
  )

export const updateEventStatus = (eventStatus: EventStatus) =>
  SBAPIUpdate<EventStatus>(
    eventStatus,
    `${EVENT_STATUSES_URL}/${eventStatus.id}`,
    slice.actions
  )

export const restoreEventStatus = (eventStatus: EventStatus) =>
  SBAPIUpdate<EventStatus>(
    eventStatus,
    `${EVENT_STATUSES_URL}/${eventStatus.id}/restore`,
    slice.actions
  )

export const deleteEventStatus = (eventStatus: EventStatus) =>
  SBAPIDelete<EventStatus>(
    eventStatus,
    `${EVENT_STATUSES_URL}/${eventStatus.id}`,
    slice.actions
  )

import {
  getEventWithId,
  selectSelectedEvent,
  setSelectedEvent,
} from '../reducers/EventReducer'
import { useEffect } from 'react'
import { Col, Layout, Row, Spin } from 'antd'
import MainHeader from './components/MainHeader'
import { initialQuery } from '../utils/helpers/crud/models'
import { resetRoomQueryAndIds } from '../reducers/RoomReducer'
import { resetFileQueryAndIds } from '../reducers/FileReducer'
import { resetRiderQueryAndIds } from '../reducers/RiderReducer'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { SBRMType } from '../modules/sbrm/SBRMModel'
import { EventDetailsSider } from './components/EventDetailsSider'
import { resetTravelQueryAndIds } from '../reducers/TravelReducer'
import { useAppDispatch, useAppSelector } from '../reducers/hooks'
import { resetBookingQueryAndIds } from '../reducers/BookingReducer'
import { resetContractQueryAndIds } from '../reducers/ContractReducer'
import { resetInvoiceQueryAndIds } from '../reducers/InvoiceReducer'
import { resetPerformanceQueryAndIds } from '../reducers/PerformanceReducer'
import { resetContactQueryAndIds } from '../reducers/ContactReducer'
import { resetGroundQueryAndIds } from '../reducers/GroundReducer'
import MainHeaderMobile from './components/MainHeaderMobile'
import { resetTaskQueryAndIds } from '../reducers/TaskReducer'
import { resetReportQueryAndIds } from '../reducers/ReportReducer'

export const LayoutEventDetail: React.FC = () => {
  const { eventId } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const event = useAppSelector(selectSelectedEvent())
  const { isLoading } = useAppSelector((state) => state.event)
  const { isOpen: SBRMIsOpen, entity: SBRMEntity } = useAppSelector(
    (state) => state.SBRM
  )

  useEffect(() => {
    if (Number.isNaN(eventId)) {
      navigate('/events')
      return
    }

    dispatch(setSelectedEvent(Number(eventId)))
    dispatch(getEventWithId(Number(eventId))).catch((err) => {
      // Error while fetching event, fallback to events page
      navigate('/events')
    })

    dispatch(resetBookingQueryAndIds(initialQuery))
    dispatch(resetRoomQueryAndIds(initialQuery))
    dispatch(resetGroundQueryAndIds(initialQuery))
    dispatch(resetFileQueryAndIds(initialQuery))
    dispatch(resetRiderQueryAndIds(initialQuery))
    dispatch(resetTravelQueryAndIds(initialQuery))
    dispatch(resetInvoiceQueryAndIds(initialQuery))
    dispatch(resetContractQueryAndIds(initialQuery))
    dispatch(resetPerformanceQueryAndIds(initialQuery))
    dispatch(resetContactQueryAndIds(initialQuery))
    dispatch(resetTaskQueryAndIds(initialQuery))
    dispatch(resetReportQueryAndIds(initialQuery))
  }, [eventId])

  useEffect(() => {
    if (SBRMIsOpen || !event || SBRMEntity !== SBRMType.event) {
      return
    }
    // Trigger only after an event update
    dispatch(getEventWithId(Number(eventId)))
  }, [SBRMIsOpen])

  return isLoading || event === undefined || Number(eventId) !== event.id ? (
    <Layout className="min-h-100vh">
      <MainHeader />
      <Spin
        style={{
          top: '50%',
          left: '50%',
          position: 'absolute',
          transform: 'translate(-50%,-50%)',
        }}
      />
    </Layout>
  ) : (
    <Layout>
      <MainHeader />
      <Layout style={{ minHeight: '100vh', marginTop: 64 }}>
        <EventDetailsSider />
        <Layout>
          <Outlet />
        </Layout>
      </Layout>
      <MainHeaderMobile />
    </Layout>
  )
}

import { Space, Typography } from 'antd'
import { useAppSelector } from '../../reducers/hooks'
import { ConditionalLinkWrapper } from '@supplement-bacon/alela-uikit'
import { addressFormatter } from '../../utils/formatters'
import { selectHotelById } from '../../reducers/HotelReducer'
import { Hotel } from '../../models/Hotel'

const { Text } = Typography
interface Props {
  id?: number
  model?: Hotel
  linkToDetails?: boolean
}

export const HotelAvatar = ({ id, model, linkToDetails = true }: Props) => {
  const item = model ? model : useAppSelector(selectHotelById(id!))

  return item ? (
    <ConditionalLinkWrapper condition={linkToDetails} to={`/hotels/${item.id}`}>
      <Space direction="vertical" size={0}>
        <Text>{item.name}</Text>
        <Text type="secondary">
          {item.address && addressFormatter(item.address)}
        </Text>
      </Space>
    </ConditionalLinkWrapper>
  ) : (
    <></>
  )
}

export type HotelAvatarType = { Avatar: typeof HotelAvatar }

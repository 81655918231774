import {
  Dropdown as ANTDDropdown,
  Alert,
  Badge,
  Button,
  Card,
  Divider,
  List,
  Space,
  Spin,
  Typography,
} from 'antd'
import {
  getNotifications,
  getNotificationsCount,
  selectNotifications,
  setNotificationQuery,
} from '../../reducers/NotificationReducer'
import React from 'react'
import { useEffect } from 'react'
import { Notification } from './Notification'
import { BellOutlined } from '@ant-design/icons'
import { TableParams } from '../../models/TableParams'
import InfiniteScroll from 'react-infinite-scroll-component'
import { initialQuery } from '../../utils/helpers/crud/models'
import { usePageVisibility } from '../../utils/usePageVisibility'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { BellIcon } from '@supplement-bacon/alela-uikit'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'

const { Title, Text } = Typography

interface Props {
  permission: NotificationPermission
  requestPermission: () => void
}

export const Dropdown = ({ permission, requestPermission }: Props) => {
  const PAGE_SIZE = 20

  const intl = useIntl()
  const dispatch = useAppDispatch()
  const isPageVisible = usePageVisibility()

  const notifications = useAppSelector(selectNotifications())
  const { query, read, unread, isLoading } = useAppSelector(
    (state) => state.notification
  )

  const firstReadId = notifications.find((n) => n.read === true)?.id

  const hasMore =
    !query.pagination || !query.pagination.total
      ? true
      : query.pagination.total! > notifications.length

  const loadMoreData = () => {
    const nextPageSize = query.pagination!.pageSize! + PAGE_SIZE
    const nextQuery: TableParams = {
      ...query,
      pagination: { current: 1, pageSize: nextPageSize },
    }
    dispatch(setNotificationQuery(nextQuery))
    dispatch(getNotifications(nextQuery))
  }

  const fetchNotifications = () => {
    // Fetch notifications
    const baseQuery: TableParams = {
      ...initialQuery,
      pagination: { current: 1, pageSize: PAGE_SIZE },
    }
    dispatch(setNotificationQuery(baseQuery))
    dispatch(getNotifications(baseQuery))

    // Fetch notifications counts
    dispatch(getNotificationsCount())
  }

  useEffect(() => {
    if (!isPageVisible) {
      return
    }
    fetchNotifications()
  }, [isPageVisible])

  return (
    <ANTDDropdown
      placement="bottom"
      trigger={['click']}
      overlayStyle={{ position: 'fixed' }}
      dropdownRender={(_) => (
        <>
          <Card
            id="scrollableDiv"
            styles={{ body: { padding: 10, paddingTop: 0 } }}
            size="small"
            style={{
              width: 350,
              maxHeight: '70vh',
              overflow: 'scroll',
              marginRight: 10,
              marginTop: 22,
              paddingBottom: unread == 0 ? 0 : 40,
            }}
          >
            <Space size={4} className="w-full" direction="vertical">
              {permission != 'granted' && (
                <>
                  {permission == 'default' && (
                    <Space
                      className="w-full"
                      style={{
                        textAlign: 'center',
                        paddingTop: 20,
                        paddingBottom: 20,
                      }}
                      direction="vertical"
                    >
                      <BellIcon />
                      <Title
                        level={5}
                        style={{ marginBottom: 0, marginTop: 0 }}
                      >
                        {intl.formatMessage({
                          id: LocalizationKeys.Components.Notification.Dropdown
                            .Title,
                        })}
                      </Title>
                      <Space.Compact direction="vertical" className="w-full">
                        <Text>
                          {intl.formatMessage({
                            id: LocalizationKeys.Components.Notification
                              .Dropdown.Description1,
                          })}
                        </Text>
                        <Text>
                          {intl.formatMessage({
                            id: LocalizationKeys.Components.Notification
                              .Dropdown.Description2,
                          })}
                        </Text>
                      </Space.Compact>
                      <Button
                        style={{ marginTop: 10 }}
                        type="primary"
                        onClick={requestPermission}
                      >
                        {intl.formatMessage({
                          id: LocalizationKeys.Components.Notification.Dropdown
                            .Accept,
                        })}
                      </Button>
                    </Space>
                  )}
                  {permission == 'denied' && (
                    <Alert
                      type="error"
                      message={intl.formatMessage({
                        id: LocalizationKeys.Components.Notification.Dropdown
                          .Error,
                      })}
                    />
                  )}
                </>
              )}
              {permission == 'granted' && (
                <InfiniteScroll
                  dataLength={notifications.length}
                  next={loadMoreData}
                  hasMore={hasMore}
                  scrollableTarget="scrollableDiv"
                  loader={
                    <>
                      {isLoading && (
                        <Space
                          className="w-full"
                          style={{ justifyContent: 'center', padding: 2 }}
                        >
                          <Spin />
                        </Space>
                      )}
                    </>
                  }
                >
                  <List
                    dataSource={notifications}
                    renderItem={(item, index) => (
                      <React.Fragment key={item.id}>
                        {firstReadId && item.id === firstReadId && (
                          <Divider style={{ margin: 0 }} orientationMargin={0}>
                            <Text type="secondary" style={{ fontSize: 12 }}>
                              {intl.formatMessage({
                                id: LocalizationKeys.Components.Notification
                                  .Dropdown.Read,
                              })}{' '}
                              ({read})
                            </Text>
                          </Divider>
                        )}
                        <List.Item
                          style={{
                            border: 'none',
                            paddingBottom: item.read ? 10 : 0,
                            paddingTop: item.read ? 0 : 10,
                          }}
                        >
                          <Notification.Cell model={item} />
                        </List.Item>
                      </React.Fragment>
                    )}
                  />
                </InfiniteScroll>
              )}
            </Space>
          </Card>
          {unread != 0 && (
            <div
              style={{
                width: '100%',
                position: 'absolute',
                bottom: 0,
                paddingBottom: 20,
                paddingTop: 40,
                left: 0,
                right: 0,
                textAlign: 'center',
                background:
                  'linear-gradient(0deg, rgba(15,15,15,1) 0%,  rgba(15,15,15,0.3) 80%, rgba(0,0,0,0) 100%)',
                borderRadius: 8,
              }}
            >
              <Notification.MarkAllAsRead
                onSuccess={() => fetchNotifications()}
              />
            </div>
          )}
        </>
      )}
    >
      <Badge
        size="small"
        count={permission != 'granted' ? '!' : unread}
        style={{ paddingLeft: 5, paddingRight: 5 }}
      >
        <Button type="text" icon={<BellOutlined />} />
      </Badge>
    </ANTDDropdown>
  )
}

export type DropdownType = { Dropdown: typeof Dropdown }

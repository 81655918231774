import { Row, Col, Card, Typography, Divider, Form, Button, App } from 'antd'
import HasAccess from '../../../components/HasAccess'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { AlelaPermission } from '../../../utils/permissions'
import { SBAsyncSelect } from '../../../components/custom-inputs/sb-async-select/SBAsyncSelect'
import { requiredRule } from '../../../utils/rules'
import { useIntl } from 'react-intl'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import axios, { AxiosError } from 'axios'
import { CONTACTS_URL } from '../../../utils/urls'
import { useState } from 'react'
import { APIResponse, MessageResponse } from '../../../models/APIResponse'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

const { Text } = Typography

interface FormValues {
  contactToKeep: number
  contactToDelete: number
}

const ContactMerger = () => {
  const intl = useIntl()
  const [form] = Form.useForm()
  const { message } = App.useApp()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  interface MergeContactValues {
    contactToKeep: number
    contactToDelete: number
  }

  const mergeContacts = async (values: MergeContactValues) =>
    await axios
      .post(CONTACTS_URL + '/merge-contacts', values)
      .then((response) => {
        message.open({
          type: 'success',
          content: intl.formatMessage({
            id: LocalizationKeys.Page.CRM.ContactMerger.Success,
          }),
        })
        const data = response.data
        return data
      })
      .catch((error: AxiosError) => {
        message.open({
          type: 'error',
          content: intl.formatMessage({
            id: LocalizationKeys.Page.CRM.ContactMerger.Failure,
          }),
        })
        const data = error.response?.data as APIResponse<MessageResponse>
        const errorMessage = data.data?.message ?? ''
        throw { message: errorMessage, error: error }
      })

  const handleFormSubmit = async (values: FormValues) => {
    setIsLoading(true)
    mergeContacts(values)
      .then((result) => {
        setIsLoading(false)
        form.resetFields()
      })
      .catch((result) => {
        setIsLoading(false)
      })
  }

  return (
    <HasAccess permissions={[AlelaPermission.listContact]}>
      <PageTitle
        title={intl.formatMessage({
          id: LocalizationKeys.Page.CRM.ContactMerger.Title,
        })}
      />
      <Row gutter={[16, 16]}>
        <Col md={24} sm={24} xs={24}>
          <Card>
            <Text>
              {intl.formatMessage({
                id: LocalizationKeys.Page.CRM.ContactMerger.Description,
              })}
              <Divider />
            </Text>
            <Form
              form={form}
              layout="vertical"
              style={{ marginTop: '20px' }}
              onFinish={handleFormSubmit}
            >
              <Row gutter={[8, 8]}>
                <Col xs={24} sm={12} md={8}>
                  <SBAsyncSelect
                    allowClear
                    outsideSBRM
                    name="contactToKeep"
                    label={intl.formatMessage({
                      id: LocalizationKeys.Page.CRM.ContactMerger.ContactToKeep,
                    })}
                    type={SBRMType.contact}
                    rules={[requiredRule(intl)]}
                    placeholder={intl.formatMessage({
                      id: LocalizationKeys.Page.CRM.ContactMerger.SelectContact,
                    })}
                  />
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <SBAsyncSelect
                    allowClear
                    outsideSBRM
                    name="contactToDelete"
                    label={intl.formatMessage({
                      id: LocalizationKeys.Page.CRM.ContactMerger
                        .ContactToDelete,
                    })}
                    type={SBRMType.contact}
                    rules={[requiredRule(intl)]}
                    placeholder={intl.formatMessage({
                      id: LocalizationKeys.Page.CRM.ContactMerger.SelectContact,
                    })}
                  />
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col>
                  <Button type="primary" htmlType="submit" loading={isLoading}>
                    {intl.formatMessage({
                      id: LocalizationKeys.Page.CRM.ContactMerger.MergeButton,
                    })}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </HasAccess>
  )
}

export default ContactMerger

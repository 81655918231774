import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Form, FormInstance, Input } from 'antd'
import { requiredRule, stringRule } from '../../../utils/rules'
import { useAppSelector } from '../../../reducers/hooks'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { selectSelectedInvoiceCategory } from '../../../reducers/InvoiceCategoryReducer'

interface Props {
  form: FormInstance
}

const Update = ({ form }: Props) => {
  const intl = useIntl()

  const item = useAppSelector(selectSelectedInvoiceCategory())

  useEffect(() => {
    form.setFieldsValue(item)
  }, [item])

  if (item === undefined) {
    return <></>
  }

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        style={{ display: 'none' }}
        name="id"
        required
        rules={[requiredRule(intl)]}
      >
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        name="name"
        rules={[requiredRule(intl), stringRule(intl)]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Name })}
      >
        <Input />
      </Form.Item>
    </Form>
  )
}

export default Update

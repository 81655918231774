import { PropsWithChildren } from 'react'
import { BoardType, Board } from './Board'
import { KanbanQuickForm, KanbanQuickFormType } from './QuickForm'
import {
  KanbanContextProvider,
  KanbanContextProviderProps,
  KanbanContextProviderType,
} from './context'

export const Kanban: KanbanContextProviderType &
  BoardType &
  KanbanQuickFormType = <T,>({
  children,
  ...props
}: PropsWithChildren<KanbanContextProviderProps<T>>) => (
  <KanbanContextProvider {...props}>{children}</KanbanContextProvider>
)

Kanban.Board = Board
Kanban.QuickForm = KanbanQuickForm

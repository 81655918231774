import { Spin } from 'antd'
import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { registerDevice, requestForToken } from '../../utils/firebase'
import { Notification as NotificationComponent } from './Notification'

export const Wrapper = () => {
  /**
   * On mobile we skip the browser permission
   */
  const [isLoading, setIsLoading] = useState<boolean>(isMobile ? false : true)
  const [permission, setPermission] = useState<NotificationPermission>(
    isMobile ? 'granted' : 'default'
  )

  const requestPermission = () => {
    setIsLoading(true)
    Notification.requestPermission().then((permission) => {
      setPermission(permission)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    if (isMobile) {
      return
    }

    setIsLoading(true)
    setPermission(Notification.permission)
    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (isMobile || permission != 'granted') {
      return
    }

    requestForToken().then((token) => {
      if (!token) {
        return
      }
      registerDevice(token)
    })
  }, [permission])

  if (isLoading) {
    return <Spin size="small" />
  }

  return (
    <>
      <NotificationComponent.Dropdown
        permission={permission}
        requestPermission={requestPermission}
      />
      {!isMobile && permission === 'granted' && (
        <NotificationComponent.Listener />
      )}
    </>
  )
}

export type WrapperType = { Wrapper: typeof Wrapper }

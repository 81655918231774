import { useState } from 'react'
import { User } from '../../models/User'
import { Alert, App, Button, Select, Space, Typography } from 'antd'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { updateUserRole } from '../../reducers/UserReducer'
import { selectRoles } from '../../reducers/RoleReducer'
import HasAccess from '../HasAccess'
import { AlelaPermission } from '../../utils/permissions'
import { ArrowRightOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

const { Option } = Select

interface Props {
  user: User
}

export const SelectRole = ({ user }: Props) => {
  const { message } = App.useApp()
  const dispatch = useAppDispatch()
  const roles = useAppSelector(selectRoles())

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showSubscriptionUpdated, setShowSubscriptionUpdated] =
    useState<boolean>(false)

  const selectedValue = () => {
    if (!user.roles.length) {
      return undefined
    }
    const matching = roles.find((r) => r.name === user.roles[0].name)
    return matching ? matching.id : undefined
  }

  if (!roles.length || !user.roles || user.roles.length === 0) {
    return <></>
  }

  return (
    <HasAccess
      permissions={[AlelaPermission.editUser]}
      renderAuthFailed={user.roles[0] && user.roles[0].name}
    >
      <Select
        value={selectedValue()}
        style={{ width: 200 }}
        loading={isLoading}
        disabled={isLoading}
        onChange={(value) => {
          setShowSubscriptionUpdated(false)
          setIsLoading(true)
          dispatch(updateUserRole(user, roles.find((r) => r.id === value)!))
            .then((data) => {
              setIsLoading(false)
              message.open({
                type: 'success',
                content: String('Alelaaa'),
              })

              if ('subscriptionUpdated' in data.data.data) {
                setShowSubscriptionUpdated(data.data.data.subscriptionUpdated)
              }
            })
            .catch(() => {
              message.open({
                type: 'error',
                content: String('Erreur'),
              })
            })
        }}
      >
        {roles.map((role) => (
          <Option key={role.id} value={role.id}>
            {role.name}
          </Option>
        ))}
      </Select>
      {showSubscriptionUpdated && (
        <Alert
          style={{ marginTop: 10 }}
          banner
          type="warning"
          message={
            <Space direction="vertical">
              <Typography.Text>
                This change will be visible on your next invoice
              </Typography.Text>
            </Space>
          }
        />
      )}
    </HasAccess>
  )
}

export type SelectRoleType = { SelectRole: typeof SelectRole }

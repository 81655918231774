import { Tag } from 'antd'
import { useIntl } from 'react-intl'
import { LocalizationKey } from '../i18n/LocalizationKeys'

interface Props {
  color: string
}

const ColorTag = ({ color }: Props) => {
  const intl = useIntl()
  const colorToLocalizationKey: Record<string, LocalizationKey> = {
    pink: LocalizationKey.MiscColorPink,
    red: LocalizationKey.MiscColorRed,
    yellow: LocalizationKey.MiscColorYellow,
    orange: LocalizationKey.MiscColorOrange,
    cyan: LocalizationKey.MiscColorCyan,
    green: LocalizationKey.MiscColorGreen,
    blue: LocalizationKey.MiscColorBlue,
    purple: LocalizationKey.MiscColorPurple,
    geekblue: LocalizationKey.MiscColorGeekblue,
    magenta: LocalizationKey.MiscColorMagenta,
    volcano: LocalizationKey.MiscColorVolcano,
    gold: LocalizationKey.MiscColorGold,
    lime: LocalizationKey.MiscColorLime,
  }

  return (
    <Tag color={color}>
      {intl.formatMessage({ id: colorToLocalizationKey[color] })}
    </Tag>
  )
}

export { ColorTag }

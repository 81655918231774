import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIUpdate,
} from '../utils/helpers/SBAPIHelper'
import { PublicLink } from '../models/PublicLink'
import { PUBLIC_LINKS_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { fileSchema, publicLinkSchema } from '../models/schema'
import { initialQuery } from '../utils/helpers/crud/models'
import { PublicLinkCreate } from '../models/PublicLink'

export const initialPublicLinkQuery: TableParams = {
  ...initialQuery,
}

const initialState: SBState<PublicLink> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: initialPublicLinkQuery,
}

const slice = createSlice({
  name: 'publicLink',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getPublicLinksSuccess,
  setQuery: setPublicLinkQuery,
  reset: resetPublicLinkState,
  resetQueryAndIds: resetPublicLinkQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: PublicLink }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectPublicLinks = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedPublicLink = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectPublicLinkById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectPublicLinksByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedPublicLink =
  (publicLink: PublicLink | number | undefined) =>
  async (dispatch: AppDispatch) =>
    dispatch(
      slice.actions.setSelectedId(
        publicLink ? getIdOrModelId<PublicLink>(publicLink) : undefined
      )
    )

export const getPublicLinks = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<PublicLink>(
    PUBLIC_LINKS_URL,
    params,
    [publicLinkSchema],
    slice.actions
  )

export const getPublicLinkWithId = (id: number) =>
  SBAPIFetchDispatch<PublicLink>(
    `${PUBLIC_LINKS_URL}/${id}`,
    publicLinkSchema,
    slice.actions
  )

export const createPublicLink = (data: PublicLinkCreate) =>
  SBAPICreate<PublicLinkCreate>(
    data,
    PUBLIC_LINKS_URL,
    fileSchema,
    slice.actions
  )

export const updatePublicLink = (publicLink: PublicLink) =>
  SBAPIUpdate<PublicLink>(
    publicLink,
    `${PUBLIC_LINKS_URL}/${publicLink.id}`,
    slice.actions
  )

export const deletePublicLink = (publicLink: PublicLink) =>
  SBAPIDelete<PublicLink>(
    publicLink,
    `${PUBLIC_LINKS_URL}/${publicLink.id}`,
    slice.actions
  )

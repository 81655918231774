import { useState } from 'react'
import { useIntl } from 'react-intl'
import { requiredRule } from '../../../../../utils/rules'
import { useAppDispatch } from '../../../../../reducers/hooks'
import { Form, Divider, Input, Button, Row, Col } from 'antd'
import { createInvoiceCategory } from '../../../../../reducers/InvoiceCategoryReducer'
import LocalizationKeys from '../../../../../i18n/LocalizationKeys'

interface Props {
  onSuccessCreate: () => void
}

const InvoiceCategoryFooter = ({ onSuccessCreate }: Props) => {
  const intl = useIntl()
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleCreate = (values: any) => {
    setIsLoading(true)
    dispatch(createInvoiceCategory(values))
      .then(() => {
        form.resetFields()
        onSuccessCreate()
        setIsLoading(false)
        return true
      })
      .catch((e: any) => {
        setIsLoading(false)
        throw e
      })
  }

  return (
    <>
      <Divider style={{ margin: '8px 0' }} />
      <Form
        form={form}
        layout="horizontal"
        onFinish={handleCreate}
        style={{ padding: '0 8px 4px' }}
      >
        <Row gutter={[8, 8]}>
          <Col span={20}>
            <Form.Item
              name="name"
              rules={[requiredRule(intl)]}
              style={{ marginBottom: '0' }}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: LocalizationKeys.Misc.Form.Name,
                })}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Button
              className="w-full"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              {intl.formatMessage({ id: LocalizationKeys.Misc.Form.Add })}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default InvoiceCategoryFooter

import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { Event } from '../../models/Event'
import { Performance as PerformanceModel } from '../../models/Performance'
import { selectEventById } from '../../reducers/EventReducer'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { Space } from 'antd'
import { Actions } from '../../modules/sbrm/components/Actions'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { MetaDataKey } from '../../models/MetaData'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import {
  getPerformances,
  selectPerformances,
  setPerformanceQuery,
} from '../../reducers/PerformanceReducer'
import { useEffect, useState } from 'react'
import { TableParams } from '../../models/TableParams'
import { initialQuery } from '../../utils/helpers/crud/models'
import { Performance } from './Performance'
import { Stage } from '../stage/Stage'
import { Booking } from '../booking/Booking'
import { selectVenuesByIds } from '../../reducers/VenueReducer'
import { selectStagesByIds } from '../../reducers/StageReducer'
import SBTable from '../SBTable'
import { extractSortDataFromSorterResult } from '../../utils/table/sorter'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { PerformanceType } from '../performance-type/PerformanceType'
import { selectPerformanceTypesByIds } from '../../reducers/PerformanceTypeReducer'

interface Props {
  eventId?: number
  eventModel?: Event
}

export const Table = ({ eventId, eventModel }: Props) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const event = eventModel
    ? eventModel
    : useAppSelector(selectEventById(eventId ?? 0))
  const venues = useAppSelector(selectVenuesByIds(event?.venues ?? []))
  const stages = useAppSelector(
    selectStagesByIds(venues.map((i) => i.stages).flat())
  )
  const { query, isLoading } = useAppSelector((state) => state.performance)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const performances = useAppSelector(selectPerformances())
  const performanceTypes = useAppSelector(
    selectPerformanceTypesByIds(performances.map((i) => i.type ?? 0).flat())
  )

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const columns: ColumnsType<PerformanceModel> = [
    {
      key: 'booking',
      title: intl.formatMessage({
        id: LocalizationKeys.Components.Performance.Table.Artist,
      }),
      render: (record: PerformanceModel) => (
        <Booking.Avatar id={record.booking ?? 0} />
      ),
    },
    {
      key: 'start',
      defaultSortOrder: 'ascend',
      title: intl.formatMessage({
        id: LocalizationKeys.Components.Performance.Table.SetTime,
      }),
      sorter: true,
      render: (record: PerformanceModel) =>
        record ? <Performance.Cell model={record} /> : <></>,
    },
    {
      key: 'stages',
      title: intl.formatMessage({
        id: LocalizationKeys.Components.Performance.Table.Stage,
      }),
      filters: stages.map((i) => ({
        text: i.name,
        value: i.id,
      })),
      render: (record: PerformanceModel) =>
        record ? <Stage.Tag id={record.stage} /> : <></>,
    },
    {
      key: 'type',
      title: intl.formatMessage({
        id: LocalizationKeys.Components.Performance.Table.Type,
      }),
      filters: performanceTypes.map((i) => ({
        text: i.name,
        value: i.id,
      })),
      render: (record: PerformanceModel) =>
        record ? <PerformanceType.Tag id={record.type} /> : <></>,
    },
    {
      key: 'actions',
      align: 'right',
      render: (record: PerformanceModel) => (
        <Space direction="horizontal">
          <Actions
            actions={['update', 'delete']}
            entity={SBRMType.performance}
            entityId={record.id}
            metadata={[
              { key: MetaDataKey.venuesId, value: event?.venues ?? [] },
            ]}
          />
        </Space>
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<PerformanceModel> | SorterResult<PerformanceModel>[]
  ) => {
    const newQuery = {
      ...query,
      pagination,
      filters: { ...query.filters, ...filters },
      ...extractSortDataFromSorterResult<PerformanceModel>(sorter),
    }
    dispatch(setPerformanceQuery(newQuery))
    dispatch(getPerformances(newQuery))
  }

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      return
    }
    setIsFirstRender(false)

    const baseQuery: TableParams = {
      ...initialQuery,
      filters: { events: [event?.id!] },
      columnKey: 'start',
      order: 'ascend',
    }

    dispatch(setPerformanceQuery(baseQuery))
    dispatch(getPerformances(baseQuery))
  }, [SBRMIsOpen])

  return (
    <SBTable
      entity={SBRMType.performance}
      emptyActionsMetadata={[
        { key: MetaDataKey.venuesId, value: event?.venues ?? [] },
      ]}
      scroll={{ x: 1000 }}
      columns={columns}
      rowKey={(record) => record.id}
      dataSource={performances}
      loading={isLoading && !performances.length}
      pagination={query.pagination}
      onChange={handleTableChange}
    />
  )
}

export type TableType = { Table: typeof Table }

import { FC } from 'react'
import { SupplierAvatar, SupplierAvatarType } from './Avatar'
import { SupplierTypeTag, SupplierTypeTagType } from './SupplierTypeTag'
import { SupplierForm, SupplierFormType } from './form/Form'

export const Supplier: FC &
  SupplierAvatarType &
  SupplierTypeTagType &
  SupplierFormType = (): JSX.Element => <></>

Supplier.Avatar = SupplierAvatar
Supplier.TypeTag = SupplierTypeTag
Supplier.Form = SupplierForm

import { Space } from 'antd'
import { Artist as ArtistModel } from '../../../../models/Artist'
import { BookingType } from '../../../../models/Booking'
import {
  Contact as ContactModel,
  ContactType,
} from '../../../../models/Contact'
import { Event as EventModel, EventStatus } from '../../../../models/Event'
import { Hotel as HotelModel } from '../../../../models/Hotel'
import { TravelType } from '../../../../models/Travel'
import { User as UserModel } from '../../../../models/User'
import { Venue as VenueModel } from '../../../../models/Venue'
import { VenueType } from '../../../../models/VenueType'
import { SBRMType } from '../../../../modules/sbrm/SBRMModel'
import { SBModel } from '../../../../utils/helpers/crud/models'
import {
  ARTISTS_URL,
  BOOKINGS_URL,
  BOOKING_TYPES_URL,
  CONTACTS_URL,
  CONTACT_TYPES_URL,
  EVENTS_URL,
  EVENT_STATUSES_URL,
  GROUND_URL,
  HOTELS_URL,
  INVOICES_URL,
  INVOICE_CATEGORIES_URL,
  PUBLIC_LINKS_URL,
  ROLES_URL,
  STAGES_URL,
  STATIONS_URL,
  STATION_TYPES_URL,
  SUPPLIERS_URL,
  SUPPLIER_TYPES_URL,
  TRAVELS_URL,
  TRAVEL_TYPE_URL,
  ACCOUNT_USERS_URL,
  VENUES_URL,
  VENUE_TYPES_URL,
  TENANT_INVITATIONS,
  NOTIFICATIONS_URL,
  CONTRACT_STATUSES_URL,
  RIDER_STATUSES_URL,
  REPORTS_URL,
  REPORT_ITEMS_URL,
  TASKS_URL,
  TASKS_STATUSES_URL,
  CURRENCIES_URL,
  PERFORMANCE_TYPES_URL,
  MARKETING_TASK_URL,
  MARKETING_TASK_STATUS_URL,
  RIDER_TYPES_URL,
} from '../../../../utils/urls'
import { Artist } from '../../../artist/Artist'
import { Contact } from '../../../contact/Contact'
import { User } from '../../../user/User'
import { Stage } from '../../../../models/Stage'
import ContactFooter from '../components/footers/ContactFooter'
import ArtistFooter from '../components/footers/ArtistFooter'
import SupplierFooter from '../components/footers/SupplierFooter'
import { Station as StationComponent } from '../../../station/Station'
import { StationType, StationWithRelation } from '../../../../models/Station'
import { PublicLink } from '../../../../models/PublicLink'
import HasAccess from '../../../HasAccess'
import { AlelaPermission } from '../../../../utils/permissions'
import { Role } from '../../../role/Role'
import { Role as RoleModel } from '../../../../models/Role'
import { Hotel } from '../../../hotel/Hotel'
import { Venue } from '../../../venue/Venue'
import { Event } from '../../../event/Event'
import { Supplier, SupplierType } from '../../../../models/Supplier'
import { InvoiceCategory } from '../../../../models/InvoiceCategory'
import InvoiceCategoryFooter from '../components/footers/InvoiceCategoryFooter'
import SupplierTypeFooter from '../components/footers/SupplierTypeFooter'
import ContactTypeFooter from '../components/footers/ContactTypeFooter'
import { ContractStatus } from '../../../../models/ContractStatus'
import { RiderStatus, RiderType } from '../../../../models/Rider'
import { ReportItem } from '../../../../models/Report'
import { TaskStatus as TaskStatusModel } from '../../../../models/TaskStatus'
import { TaskStatus } from '../../../task-status/TaskStatus'
import { SBAsyncSelectConfig } from '../types'
import { Currency } from '../../../../models/Tenant'
import { PerformanceType } from '../../../performance-type/PerformanceType'
import { PerformanceType as PerformanceTypeModel } from '../../../../models/Performance'
import { MarketingTaskStatus } from '../../../marketing-task-status/MarketingTaskStatus'

const SBAsyncSelectDefaultConfig: SBAsyncSelectConfig<SBModel> = {
  [SBRMType.artist]: {
    endpoint: ARTISTS_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => (
      <Artist.Avatar
        model={item as unknown as ArtistModel}
        linkToDetails={false}
        size="default"
      />
    ),
    footer: (shouldTriggerIndex: () => void) => (
      <HasAccess permissions={[AlelaPermission.createArtist]}>
        <ArtistFooter onSuccessCreate={shouldTriggerIndex} />
      </HasAccess>
    ),
    extra: (item: SBModel) => (
      <HasAccess permissions={[AlelaPermission.viewPriceBooking]}>
        <Artist.FeesInfos item={item as unknown as ArtistModel} />
      </HasAccess>
    ),
  },
  [SBRMType.venue]: {
    endpoint: VENUES_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => (
      <Venue.Avatar
        model={item as unknown as VenueModel}
        linkToDetails={false}
        displayAddress={false}
        size="small"
      />
    ),
  },
  [SBRMType.venueType]: {
    endpoint: VENUE_TYPES_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => <>{(item as unknown as VenueType).name}</>,
  },
  [SBRMType.stage]: {
    endpoint: STAGES_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => <>{(item as unknown as Stage).name}</>,
  },
  [SBRMType.event]: {
    endpoint: EVENTS_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => (
      <Event.Option model={item as unknown as EventModel} />
    ),
  },
  [SBRMType.eventStatus]: {
    endpoint: EVENT_STATUSES_URL,
    canbeRadio: true,
    displayEmpty: true,
    option: (item: SBModel) => <>{(item as unknown as EventStatus).name}</>,
  },
  [SBRMType.booking]: {
    endpoint: BOOKINGS_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => (
      <Artist.Avatar
        model={(item as unknown as any).artist}
        linkToDetails={false}
        size="default"
      />
    ),
  },
  [SBRMType.bookingType]: {
    endpoint: BOOKING_TYPES_URL,
    canbeRadio: true,
    displayEmpty: true,
    option: (item: SBModel) => <>{(item as unknown as BookingType).name}</>,
  },
  [SBRMType.contract]: {
    endpoint: '',
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => <></>,
  },
  [SBRMType.contractStatus]: {
    endpoint: CONTRACT_STATUSES_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => <>{(item as unknown as ContractStatus).name}</>,
  },
  [SBRMType.invoice]: {
    endpoint: '',
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => <></>,
  },
  [SBRMType.invoiceCategory]: {
    endpoint: INVOICE_CATEGORIES_URL,
    canbeRadio: false,
    displayEmpty: false,
    option: (item: SBModel) => <>{(item as unknown as InvoiceCategory).name}</>,
    footer: (shouldTriggerIndex: () => void) => (
      <HasAccess permissions={[AlelaPermission.createInvoiceCategory]}>
        <InvoiceCategoryFooter onSuccessCreate={shouldTriggerIndex} />
      </HasAccess>
    ),
  },
  [SBRMType.payment]: {
    endpoint: INVOICES_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => <></>,
  },
  [SBRMType.supplier]: {
    endpoint: SUPPLIERS_URL,
    canbeRadio: false,
    displayEmpty: false,
    option: (item: SBModel) => <>{(item as unknown as Supplier).name}</>,
    footer: (shouldTriggerIndex: () => void) => (
      <HasAccess permissions={[AlelaPermission.createSupplier]}>
        <SupplierFooter onSuccessCreate={shouldTriggerIndex} />
      </HasAccess>
    ),
  },
  [SBRMType.supplierType]: {
    endpoint: SUPPLIER_TYPES_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => <>{(item as unknown as SupplierType).name}</>,
    footer: (shouldTriggerIndex: () => void) => (
      <HasAccess permissions={[AlelaPermission.createSupplierType]}>
        <SupplierTypeFooter onSuccessCreate={shouldTriggerIndex} />
      </HasAccess>
    ),
  },
  [SBRMType.hotel]: {
    endpoint: HOTELS_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => (
      <Hotel.Option model={item as unknown as HotelModel} />
    ),
  },
  [SBRMType.room]: {
    endpoint: '',
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => <></>,
  },
  [SBRMType.rider]: {
    endpoint: '',
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => <></>,
  },
  [SBRMType.riderStatus]: {
    endpoint: RIDER_STATUSES_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => <>{(item as unknown as RiderStatus).name}</>,
  },
  [SBRMType.riderType]: {
    endpoint: RIDER_TYPES_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => <>{(item as unknown as RiderType).name}</>,
  },
  [SBRMType.file]: {
    endpoint: '',
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => <></>,
  },
  [SBRMType.user]: {
    endpoint: ACCOUNT_USERS_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => (
      <User.Avatar
        size="small"
        type="full"
        model={item as unknown as UserModel}
      />
    ),
  },
  [SBRMType.contact]: {
    endpoint: CONTACTS_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => (
      <Contact.Avatar
        size={'small'}
        model={item as unknown as ContactModel}
        displayCompany={false}
        editable={false}
      />
    ),
    footer: (shouldTriggerIndex: () => void) => (
      <HasAccess permissions={[AlelaPermission.createContact]}>
        <ContactFooter onSuccessCreate={shouldTriggerIndex} />
      </HasAccess>
    ),
  },
  [SBRMType.contactType]: {
    endpoint: CONTACT_TYPES_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => <>{(item as unknown as ContactType).name}</>,
    footer: (shouldTriggerIndex: () => void) => (
      <HasAccess permissions={[AlelaPermission.createContactType]}>
        <ContactTypeFooter onSuccessCreate={shouldTriggerIndex} />
      </HasAccess>
    ),
  },
  [SBRMType.contactAssociation]: {
    endpoint: '',
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => <></>,
  },
  [SBRMType.travel]: {
    endpoint: TRAVELS_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => <></>,
  },
  [SBRMType.travelType]: {
    endpoint: TRAVEL_TYPE_URL,
    canbeRadio: true,
    displayEmpty: true,
    option: (item: SBModel) => <>{(item as unknown as TravelType).name}</>,
  },
  [SBRMType.ground]: {
    endpoint: GROUND_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => <></>,
  },
  [SBRMType.performance]: {
    endpoint: '',
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => <></>,
  },
  [SBRMType.performanceType]: {
    endpoint: PERFORMANCE_TYPES_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => (
      <PerformanceType.Tag model={item as unknown as PerformanceTypeModel} />
    ),
  },
  [SBRMType.station]: {
    endpoint: STATIONS_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => (
      <StationComponent.Option item={item as unknown as StationWithRelation} />
    ),
  },
  [SBRMType.stationType]: {
    endpoint: STATION_TYPES_URL,
    canbeRadio: true,
    displayEmpty: true,
    option: (item: SBModel) => <>{(item as unknown as StationType).name}</>,
  },
  [SBRMType.publicLink]: {
    endpoint: PUBLIC_LINKS_URL,
    canbeRadio: true,
    displayEmpty: true,
    option: (item: SBModel) => <>{(item as unknown as PublicLink).name}</>,
  },
  [SBRMType.role]: {
    endpoint: ROLES_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => (
      <Role.Option model={item as unknown as RoleModel} />
    ),
  },
  [SBRMType.customAddress]: {
    endpoint: ROLES_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => (
      <Role.Option model={item as unknown as RoleModel} />
    ),
  },
  [SBRMType.invitation]: {
    endpoint: TENANT_INVITATIONS,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => <></>,
  },
  [SBRMType.notification]: {
    endpoint: NOTIFICATIONS_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => <></>,
  },
  [SBRMType.report]: {
    endpoint: REPORTS_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => <></>,
  },
  [SBRMType.reportItem]: {
    endpoint: REPORT_ITEMS_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => <>{(item as unknown as ReportItem).name}</>,
  },
  [SBRMType.task]: {
    endpoint: TASKS_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => <></>,
  },
  [SBRMType.taskStatus]: {
    endpoint: TASKS_STATUSES_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => (
      <Space>
        <TaskStatus.Tag model={item as unknown as TaskStatusModel} />
      </Space>
    ),
  },
  [SBRMType.marketingTaskStatus]: {
    endpoint: MARKETING_TASK_STATUS_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => (
      <Space>
        <MarketingTaskStatus.Tag model={item as unknown as any} />
      </Space>
    ),
  },
  [SBRMType.externalAdvancingLink]: {
    endpoint: '',
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => <></>,
  },
  [SBRMType.currency]: {
    endpoint: CURRENCIES_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => (
      <>
        {(item as unknown as Currency).symbol} -{' '}
        {(item as unknown as Currency).name}
      </>
    ),
  },
}

export default SBAsyncSelectDefaultConfig

import { useIntl } from 'react-intl'
import { Checkbox, Form, FormInstance, Input } from 'antd'
import { requiredRule } from '../../../utils/rules'
import ColorSelect from '../../custom-inputs/select/ColorSelect'
import { useAppSelector } from '../../../reducers/hooks'
import { selectSelectedContractStatus } from '../../../reducers/ContractStatusReducer'
import { useEffect } from 'react'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

interface Props {
  form: FormInstance
}

const Update = ({ form }: Props) => {
  const intl = useIntl()

  const status = useAppSelector(selectSelectedContractStatus())

  useEffect(() => {
    form.setFieldsValue(status)
  }, [status])

  if (status === undefined) {
    return <></>
  }

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="id"
        style={{ display: 'none' }}
        rules={[requiredRule(intl)]}
      >
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        name="name"
        rules={[requiredRule(intl)]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Name })}
      >
        <Input />
      </Form.Item>
      <ColorSelect
        name="color"
        rules={[requiredRule(intl)]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Color })}
      />
      <Form.Item
        name="icon"
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Icon })}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="isDefault"
        valuePropName="checked"
        rules={[]}
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.IsDefaultStatusForExternalAdvancing,
        })}
      >
        <Checkbox />
      </Form.Item>
    </Form>
  )
}

export default Update

import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIUpdate,
} from '../utils/helpers/SBAPIHelper'
import { TaskStatus } from '../models/TaskStatus'
import { TASKS_STATUSES_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { taskStatusSchema } from '../models/schema'
import { initialQuery } from '../utils/helpers/crud/models'

const initialState: SBState<TaskStatus> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: initialQuery,
}

const slice = createSlice({
  name: 'taskStatus',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getTaskStatusesSuccess,
  setQuery: setTaskStatusQuery,
  reset: resetTaskStatusState,
  resetQueryAndIds: resetTaskStatusQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: TaskStatus }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectTaskStatuss = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedTaskStatus = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectTaskStatusById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectTaskStatussByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedTaskStatus =
  (taskStatus: TaskStatus | number | undefined) =>
  async (dispatch: AppDispatch) =>
    dispatch(
      slice.actions.setSelectedId(
        taskStatus ? getIdOrModelId<TaskStatus>(taskStatus) : undefined
      )
    )

export const getTaskStatuses = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<TaskStatus>(
    TASKS_STATUSES_URL,
    params,
    [taskStatusSchema],
    slice.actions
  )

export const getTaskStatusWithId = (id: number) =>
  SBAPIFetchDispatch<TaskStatus>(
    `${TASKS_STATUSES_URL}/${id}`,
    taskStatusSchema,
    slice.actions
  )

export const createTaskStatus = (taskStatus: TaskStatus) =>
  SBAPICreate<TaskStatus>(
    taskStatus,
    TASKS_STATUSES_URL,
    taskStatusSchema,
    slice.actions
  )

export const updateTaskStatus = (taskStatus: TaskStatus) =>
  SBAPIUpdate<TaskStatus>(
    taskStatus,
    `${TASKS_STATUSES_URL}/${taskStatus.id}`,
    slice.actions
  )

export const deleteTaskStatus = (taskStatus: TaskStatus) =>
  SBAPIDelete<TaskStatus>(
    taskStatus,
    `${TASKS_STATUSES_URL}/${taskStatus.id}`,
    slice.actions
  )

import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Space,
  Typography,
} from 'antd'
import { numberRule, requiredRule, stringRule } from '../../../utils/rules'
import { fieldFormatter } from '../../../utils/formatters'
import { SBAsyncSelect } from '../../../components/custom-inputs/sb-async-select/SBAsyncSelect'
import { useAppSelector } from '../../../reducers/hooks'
import { MetaDataKey } from '../../../models/MetaData'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { useTenantSettings } from '../../../utils/hooks/useTenantSettings'
import { SBRMType, getMetadata } from '../../../modules/sbrm/SBRMModel'
import { ApartmentOutlined, InboxOutlined } from '@ant-design/icons'
import Dragger from 'antd/es/upload/Dragger'

const { TextArea } = Input
const { Text } = Typography

interface Props {
  form: FormInstance
}

const Create = ({ form }: Props) => {
  const intl = useIntl()
  const { currency } = useTenantSettings()

  const { isOpen: SBRMIsOpen, metadata } = useAppSelector((state) => state.SBRM)

  const [feeIsSplitted, setFeeIsSplitted] = useState<boolean>(false)

  useEffect(() => {
    if (!SBRMIsOpen) {
      // We want to trigger only the reset
      // when the SBRM opens
      return
    }
    setFeeIsSplitted(false)
    form.setFieldValue('event', getMetadata(metadata, MetaDataKey.eventId))
    form.setFieldValue('artist', getMetadata(metadata, MetaDataKey.artistId))
    form.setFieldValue('currency', currency?.id)
  }, [SBRMIsOpen])

  return (
    <Form form={form} layout="vertical">
      {getMetadata(metadata, MetaDataKey.eventId) && (
        <Form.Item name="event" rules={[requiredRule(intl)]} className="d-none">
          <Input type="hidden" />
        </Form.Item>
      )}
      {!getMetadata(metadata, MetaDataKey.eventId) && (
        <SBAsyncSelect
          name="event"
          label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Event })}
          type={SBRMType.event}
          rules={[requiredRule(intl)]}
        />
      )}

      <SBAsyncSelect
        type={SBRMType.artist}
        name="artist"
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Artist })}
        rules={[requiredRule(intl)]}
      />
      <SBAsyncSelect
        type={SBRMType.bookingType}
        name="type"
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Type })}
        rules={[requiredRule(intl)]}
      />
      <Row gutter={[16, 16]}>
        {!feeIsSplitted ? (
          <Col span={18}>
            <Form.Item
              name="price"
              label={intl.formatMessage({
                id: LocalizationKeys.Misc.Form.Price,
              })}
              rules={[requiredRule(intl), numberRule(intl)]}
            >
              <InputNumber
                min={0}
                className="w-full"
                formatter={fieldFormatter}
                disabled={feeIsSplitted}
              />
            </Form.Item>
          </Col>
        ) : (
          <>
            <Col span={6}>
              <Form.Item
                name="artistFee"
                label={'Artist fee'}
                rules={[requiredRule(intl), numberRule(intl)]}
              >
                <InputNumber
                  min={0}
                  className="w-full"
                  formatter={fieldFormatter}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="productionFee"
                label={'Production fee'}
                rules={[requiredRule(intl), numberRule(intl)]}
              >
                <InputNumber
                  min={0}
                  className="w-full"
                  formatter={fieldFormatter}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="bookingFee"
                label={'Booking fee'}
                rules={[requiredRule(intl), numberRule(intl)]}
              >
                <InputNumber
                  min={0}
                  className="w-full"
                  formatter={fieldFormatter}
                />
              </Form.Item>
            </Col>
          </>
        )}
        <Col span={6}>
          <Button
            style={{ marginTop: 30 }}
            className="w-full"
            type="default"
            icon={<ApartmentOutlined />}
            onClick={() => setFeeIsSplitted(!feeIsSplitted)}
          >
            {feeIsSplitted ? 'Unsplit' : 'Split'}
          </Button>
        </Col>
      </Row>
      <SBAsyncSelect
        size="middle"
        type={SBRMType.currency}
        name={'currency'}
        rules={[requiredRule(intl)]}
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Currency,
        })}
      />
      <Form.Item
        name="internal_note"
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.MemoDeal })}
        rules={[stringRule(intl)]}
      >
        <TextArea rows={8} />
      </Form.Item>
      <Form.Item
        name="file"
        rules={[]}
        label={intl.formatMessage({
          id: LocalizationKeys.Components.Booking.Table.OfferSheet,
        })}
      >
        <Dragger
          accept="*"
          maxCount={1}
          multiple={false}
          beforeUpload={(file) => {
            return false
          }}
        >
          <Space direction="vertical" size={0}>
            <p className="ant-upload-drag-icon" style={{ marginTop: 0 }}>
              <InboxOutlined />
            </p>
            <Text>
              {intl.formatMessage({
                id: LocalizationKeys.Components.CustomInput.Dropzone,
              })}
            </Text>
            <Text type="secondary" italic style={{ fontSize: 12 }}>
              {intl.formatMessage({
                id: LocalizationKeys.Components.File.MaxSize,
              })}
            </Text>
          </Space>
        </Dragger>
      </Form.Item>
    </Form>
  )
}

export default Create

import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Form, FormInstance, Input } from 'antd'
import {
  emailRule,
  requiredRule,
  validPhoneNumberRule,
} from '../../../utils/rules'
import { useAppSelector } from '../../../reducers/hooks'
import TextArea from 'antd/es/input/TextArea'
import { selectSelectedContact } from '../../../reducers/ContactReducer'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

interface Props {
  form: FormInstance
}

const Update = ({ form }: Props) => {
  const intl = useIntl()

  const item = useAppSelector(selectSelectedContact())

  useEffect(() => {
    form.setFieldsValue(item)
  }, [item])

  if (item === undefined) {
    return <></>
  }

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="id"
        style={{ display: 'none' }}
        rules={[requiredRule(intl)]}
      >
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        name="firstName"
        rules={[requiredRule(intl)]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.FirstName })}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="lastName"
        rules={[]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.LastName })}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[emailRule(intl)]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Email })}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="phone"
        rules={[validPhoneNumberRule(intl)]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Phone })}
      >
        <PhoneInput defaultCountry="FR" onChange={() => {}} />
      </Form.Item>
      <Form.Item
        name="comment"
        rules={[]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Comment })}
      >
        <TextArea rows={8} />
      </Form.Item>
    </Form>
  )
}

export default Update

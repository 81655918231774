import { Space } from 'antd'
import { Booking } from '../booking/Booking'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { useEffect } from 'react'
import { initialQuery } from '../../utils/helpers/crud/models'
import { TableParams } from '../../models/TableParams'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import BaseExternalRequests from '../BaseExternalRequests'
import {
  approveContract,
  deleteContract,
  getNonApprovedContracts,
  selectAllNotApprovedContracts,
  updateContract,
} from '../../reducers/ContractReducer'
import { ContractStatus } from '../contract-status/ContractStatus'

interface ExternalRequestsProps {
  eventId: number
  shouldRefreshList: () => void
}

const ExternalRequests = ({
  eventId,
  shouldRefreshList,
}: ExternalRequestsProps) => {
  const dispatch = useAppDispatch()
  const contracts = useAppSelector(selectAllNotApprovedContracts())

  useEffect(() => {
    const baseQuery: TableParams = {
      ...initialQuery,
      filters: { events: [eventId] },
      columnKey: 'time',
      order: 'ascend',
      with: ['sumPaid', 'supplier', 'category', 'booking'],
    }
    dispatch(getNonApprovedContracts(baseQuery))
  }, [])

  if (contracts.length === 0) return <></>

  return (
    <BaseExternalRequests
      entity={SBRMType.contract}
      items={contracts}
      renderItem={(item) => (
        <Space size={10}>
          <a href="#" onClick={(e) => e.stopPropagation()}>
            <Booking.Avatar id={item.booking ?? 0} />
          </a>
          <ContractStatus.Tag id={item.status} />
        </Space>
      )}
      updateMethod={approveContract}
      deleteMethod={deleteContract}
      shouldRefreshList={shouldRefreshList}
    />
  )
}

type ExternalRequestsType = { ExternalRequests: typeof ExternalRequests }

export { ExternalRequests, type ExternalRequestsType }

import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIUpdate,
} from '../utils/helpers/SBAPIHelper'
import { StationType } from '../models/Station'
import { STATION_TYPES_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { stationTypeSchema } from '../models/schema'
import { initialQuery } from '../utils/helpers/crud/models'

const initialState: SBState<StationType> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: initialQuery,
}

const slice = createSlice({
  name: 'stationType',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getStationTypesSuccess,
  setQuery: setStationTypeQuery,
  reset: resetStationTypeState,
  resetQueryAndIds: resetStationTypeQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: StationType }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectStationTypes = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedStationType = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectStationTypeById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectStationTypesByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedStationType =
  (stationType: StationType | number | undefined) =>
  async (dispatch: AppDispatch) =>
    dispatch(
      slice.actions.setSelectedId(
        stationType ? getIdOrModelId<StationType>(stationType) : undefined
      )
    )

export const getStationTypes = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<StationType>(
    STATION_TYPES_URL,
    params,
    [stationTypeSchema],
    slice.actions
  )

export const getStationTypeWithId = (id: number) =>
  SBAPIFetchDispatch<StationType>(
    `${STATION_TYPES_URL}/${id}`,
    stationTypeSchema,
    slice.actions
  )

export const createStationType = (stationType: StationType) =>
  SBAPICreate<StationType>(
    stationType,
    STATION_TYPES_URL,
    stationTypeSchema,
    slice.actions
  )

export const updateStationType = (stationType: StationType) =>
  SBAPIUpdate<StationType>(
    stationType,
    `${STATION_TYPES_URL}/${stationType.id}`,
    slice.actions
  )

export const deleteStationType = (stationType: StationType) =>
  SBAPIDelete<StationType>(
    stationType,
    `${STATION_TYPES_URL}/${stationType.id}`,
    slice.actions
  )

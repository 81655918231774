import { FC } from 'react'
import { Tag, TagType } from './Tag'
import { List, ListType } from './List'
import { Table, TableType } from './Table'
import { StageForm, StageFormType } from './form/Form'

export const Stage: FC &
  TableType &
  TagType &
  ListType &
  StageFormType = (): JSX.Element => <></>

Stage.Table = Table
Stage.Tag = Tag
Stage.List = List
Stage.Form = StageForm

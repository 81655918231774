import { Col, Row, Segmented, Space } from 'antd'
import { useAppSelector } from '../../reducers/hooks'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { MetaDataKey } from '../../models/MetaData'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { selectSelectedEvent } from '../../reducers/EventReducer'
import { Actions } from '../../modules/sbrm/components/Actions'
import { Performance } from '../../components/performance/Performance'
import HasAccess from '../../components/HasAccess'
import { AlelaPermission } from '../../utils/permissions'
import { ClockCircleOutlined, TableOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { SegmentedValue } from 'antd/es/segmented'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { HelpArticle } from '../../utils/hooks/useAlelaHelp'
import { Help } from '../../components/help/Help'

const TimeTablePage = () => {
  const intl = useIntl()
  const event = useAppSelector(selectSelectedEvent())
  const [selectedView, setSelectedView] = useState<SegmentedValue>('timeline')

  return (
    <Row className="container-row">
      <Col span={24}>
        <HasAccess permissions={[AlelaPermission.listPerformance]}>
          <PageTitle
            title={intl.formatMessage({
              id: LocalizationKeys.Page.EventDetails.TimeTable,
            })}
            level={5}
            toolbar={
              <Space>
                <Segmented
                  onChange={(tab) => setSelectedView(tab)}
                  options={[
                    {
                      value: 'timeline',
                      icon: <ClockCircleOutlined />,
                    },
                    {
                      value: 'table',
                      icon: <TableOutlined />,
                    },
                  ]}
                />
                <Actions
                  actions={['create']}
                  entity={SBRMType.performance}
                  metadata={[
                    { key: MetaDataKey.eventId, value: event?.id },
                    { key: MetaDataKey.venuesId, value: event?.venues ?? [] },
                  ]}
                />
                <Help.Button article={HelpArticle.AddAndManageTimetable} />
              </Space>
            }
          />
          {selectedView == 'timeline' && (
            <Performance.Timetable eventModel={event} />
          )}
          {selectedView == 'table' && <Performance.Table eventModel={event} />}
        </HasAccess>
      </Col>
    </Row>
  )
}

export default TimeTablePage

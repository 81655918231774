import Sider from 'antd/es/layout/Sider'
import { Outlet } from 'react-router-dom'
import { Affix, Alert, Button, Col, Layout, Row } from 'antd'
import MainHeader from './components/MainHeader'
import SettingsSider from './components/SettingsSider'
import { useAuth } from '../utils/auth/Auth'
import { Help } from '../components/help/Help'

const { Content } = Layout

export const LayoutSettings: React.FC = () => {
  const { currentUser } = useAuth()

  return (
    <Layout>
      <MainHeader />
      <Content style={{ minHeight: '100vh', marginTop: 64 }}>
        <Row className="container-row">
          <Col xs={24} className="px-4">
            <Layout>
              <Sider
                width={230}
                style={{ background: 'none', overflow: 'scroll' }}
              >
                <SettingsSider />
              </Sider>

              <Content style={{ padding: '0 24px', minHeight: 280 }}>
                <Help.SupportAlert style={{ marginBottom: 10 }} />
                <Outlet />
              </Content>
            </Layout>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

import { Button, Modal, Typography } from 'antd'
import { useState } from 'react'
import { EyeOutlined } from '@ant-design/icons'

const { Text, Paragraph } = Typography
interface Props {
  title: string
  content: string
}
const DescriptionModal = ({ title, content }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Button onClick={showModal} icon={<EyeOutlined />} />
      <Modal
        title={title}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={<></>}
      >
        {content &&
          content.split('\n').map((i, key) => {
            return <Paragraph key={key}>{i}</Paragraph>
          })}
      </Modal>
    </>
  )
}

export default DescriptionModal

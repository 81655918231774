import { useIntl } from 'react-intl'
import { Col, Form, FormInstance, Input, InputNumber, Row } from 'antd'
import { numberRule, requiredRule, stringRule } from '../../../utils/rules'
import { Address } from '../../../components/address/Address'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

interface Props {
  form: FormInstance
}

const Create = ({ form }: Props) => {
  const intl = useIntl()

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="name"
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Name })}
        rules={[requiredRule(intl), stringRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="stars"
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Stars })}
        rules={[requiredRule(intl), numberRule(intl)]}
      >
        <InputNumber min={0} max={5} />
      </Form.Item>
      <Address.Form />
    </Form>
  )
}

export default Create

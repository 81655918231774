import { Alert, Form } from 'antd'
import { forwardRef, Ref, useImperativeHandle } from 'react'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'

import Create from './Create'
import Update from './Update'
import Delete from './Delete'
import {
  createBooking,
  deleteBooking,
  updateBooking,
} from '../../../reducers/BookingReducer'
import { MetaDataKey } from '../../../models/MetaData'
import { useSBRMData } from '../../../modules/sbrm/hooks'
import {
  SBRMFormInterface,
  SBRMFormProps,
  getMetadata,
} from '../../../modules/sbrm/SBRMModel'

export const BookingForm = forwardRef(
  ({ isNested }: SBRMFormProps, ref: Ref<SBRMFormInterface>) => {
    useImperativeHandle(ref, () => ({
      handleCreate,
      handleUpdate,
      handleDelete,
      handleReset,
    }))

    const dispatch = useAppDispatch()
    const { action, metadata } = useSBRMData(isNested)
    const { error } = useAppSelector((state) => state.booking)

    const [createForm] = Form.useForm()
    const [updateForm] = Form.useForm()
    const [deleteForm] = Form.useForm()

    const handleCreate = async () =>
      await createForm.validateFields().then(async (result) => {
        const data = new FormData()
        const fields = [
          'event',
          'artist',
          'type',
          'currency',
          'internal_note',
          'public_note',
          'price',
          'bookingFee',
          'productionFee',
          'artistFee',
        ]
        fields.map((field) => {
          data.append(field, createForm.getFieldValue(field) ?? '')
        })

        if (createForm.getFieldValue('file')) {
          data.append(
            'file',
            createForm.getFieldValue('file').fileList[0].originFileObj
          )
        }

        return dispatch(createBooking(data)).then(() => {
          createForm.resetFields()
          return true
        })
      })

    const handleUpdate = async () =>
      await updateForm.validateFields().then(async (result) => {
        const data = new FormData()
        const fields = [
          'id',
          'type',
          'currency',
          'internal_note',
          'public_note',
          'owner',
          'price',
          'bookingFee',
          'productionFee',
          'artistFee',
        ]

        fields.map((field) => {
          data.append(field, updateForm.getFieldValue(field) ?? '')
        })

        if (updateForm.getFieldValue('file')) {
          data.append(
            'file',
            updateForm.getFieldValue('file').fileList[0].originFileObj
          )
        }

        return dispatch(updateBooking(data)).then(() => {
          updateForm.resetFields()
          return true
        })
      })

    const handleDelete = async () =>
      await deleteForm.validateFields().then(async (result) =>
        dispatch(deleteBooking(deleteForm.getFieldsValue())).then(() => {
          deleteForm.resetFields()
          return true
        })
      )

    const handleReset = () => {
      createForm.resetFields()
      updateForm.resetFields()
      deleteForm.resetFields()
    }

    return (
      <>
        {action === 'create' && <Create form={createForm} />}
        {action === 'update' && <Update form={updateForm} />}
        {action === 'delete' && (
          <Delete
            form={deleteForm}
            deleteWithConfirmation={getMetadata(
              metadata,
              MetaDataKey.deleteWithConfirmation
            )}
          />
        )}
        {error && <Alert message={String(error)} type="error" />}
      </>
    )
  }
)

BookingForm.displayName = 'BookingForm'

export type BookingFormType = { Form: typeof BookingForm }

import {
  selectBookings,
  selectBookingsByIds,
} from '../../reducers/BookingReducer'
import { ColumnsType } from 'antd/es/table'
import { useAppSelector } from '../../reducers/hooks'
import { Booking } from '../../models/Booking'
import { Event } from '../event/Event'
import { Space } from 'antd'
import { selectEventsByIds } from '../../reducers/EventReducer'
import DescriptionModal from '../DescriptionModal'
import { useHasAccess } from '../HasAccess'
import { AlelaPermission } from '../../utils/permissions'
import { Venue } from '../venue/Venue'
import SBTable from '../SBTable'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { MetaData } from '../../models/MetaData'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { useTenantSettings } from '../../utils/hooks/useTenantSettings'
import { Booking as BookingComponent } from './Booking'
import { Actions } from '../../modules/sbrm/components/Actions'

interface Props {
  ids?: number[]
  emptyActionsMetadata?: MetaData[]
}

export const Table = ({ ids, emptyActionsMetadata }: Props) => {
  const intl = useIntl()
  const { currency } = useTenantSettings()
  const { hasAccess } = useHasAccess()

  const bookings = ids
    ? useAppSelector(selectBookingsByIds(ids))
    : useAppSelector(selectBookings())

  const events = useAppSelector(
    selectEventsByIds(bookings.map((b) => b.event ?? 0))
  )

  const { isLoading } = useAppSelector((state) => state.booking)

  const columnsToDisplay: Record<string, boolean> = {
    event: true,
    venue: true,
    price: hasAccess([AlelaPermission.viewPriceBooking]),
    memo: hasAccess([AlelaPermission.viewMemoDealBooking]),
    offerSheet: hasAccess([AlelaPermission.viewPriceBooking]),
  }

  const columns: ColumnsType<Booking> = [
    {
      key: 'event',
      title: intl.formatMessage({
        id: LocalizationKeys.Components.Booking.Table.Event,
      }),
      render: (record: Booking) => (
        <>{record.event && <Event.Cell id={record.event} />}</>
      ),
    },
    {
      key: 'venue',
      title: intl.formatMessage({
        id: LocalizationKeys.Components.Booking.Table.Venue,
      }),
      render: (record: Booking) => (
        <Space direction="horizontal">
          {record.event &&
            events
              .find((e) => e.id === record.event)
              ?.venues?.map((venue) => <Venue.Avatar key={venue} id={venue} />)}
        </Space>
      ),
    },
    {
      key: 'price',
      title: intl.formatMessage({
        id: LocalizationKeys.Components.Booking.Table.Price,
      }),
      render: (record: Booking) => (
        <BookingComponent.AmountsTag id={record.id} />
      ),
    },
    {
      key: 'offerSheet',
      title: intl.formatMessage({
        id: LocalizationKeys.Components.Booking.Table.OfferSheet,
      }),
      align: 'center',
      render: (record: Booking) =>
        record.file && (
          <Actions
            actions={['view']}
            entity={SBRMType.file}
            entityId={record.file}
          />
        ),
    },
    {
      key: 'memo',
      title: intl.formatMessage({
        id: LocalizationKeys.Components.Booking.Table.MemoDeal,
      }),
      align: 'center',
      render: (record: Booking) =>
        record.internal_note && (
          <DescriptionModal
            title={intl.formatMessage({
              id: LocalizationKeys.Components.Booking.Table.MemoDeal,
            })}
            content={record.internal_note}
          />
        ),
    },
  ]

  return (
    <SBTable
      entity={SBRMType.booking}
      emptyActionsMetadata={emptyActionsMetadata}
      scroll={{ x: 800 }}
      loading={isLoading && !bookings.length}
      columns={columns.filter(
        (column) => columnsToDisplay[column.key! as string]
      )}
      rowKey={(record) => record.id}
      dataSource={bookings}
      // pagination={query.pagination}
      // loading={isLoading}
      // onChange={handleTableChange}
    />
  )
}

export type TableType = {
  Table: typeof Table
}

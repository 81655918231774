import {
  computeSumForDonutData,
  extractCostsData,
  extractRevenuesData,
} from '../../utils/helpers/ReportHelper'
import {
  Report,
  REPORT_ITEM_TYPE_COST_COLOR,
  REPORT_ITEM_TYPE_REVENUE_COLOR,
} from '../../models/Report'
import dayjs from 'dayjs'
import { Card, Space, Typography } from 'antd'
import { useIntl } from 'react-intl'
import { Datum, DualAxes } from '@ant-design/plots'
import { useAppSelector } from '../../reducers/hooks'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { selectReports } from '../../reducers/ReportReducer'
import { useTenantSettings } from '../../utils/hooks/useTenantSettings'
import { currencyFormat } from '@supplement-bacon/alela-uikit'

interface Props {
  reports?: Report[]
  displayMode?: 'embedded' | 'standalone'
}

const Charts = ({ reports: models, displayMode = 'standalone' }: Props) => {
  const intl = useIntl()
  const { currency } = useTenantSettings()
  const reports = models ? models : useAppSelector(selectReports())

  const TIME_LABEL_FORMAT = 'YYYY-MM-DD'

  const findReportWithId = (reportId: string): Report | undefined =>
    reports.find((report) => report.id === Number(reportId))

  const reportsFinancialData = reports
    .map((report) => {
      const startDate = dayjs(report.event?.start_date).format(
        TIME_LABEL_FORMAT
      )

      const items = report.items
      const costsData = extractCostsData(items)
      const costsSum = computeSumForDonutData(costsData)

      const revenueData = extractRevenuesData(items)
      const revenueSum = computeSumForDonutData(revenueData)

      return [
        {
          reportId: report.id,
          timeLabel: startDate,
          color: REPORT_ITEM_TYPE_COST_COLOR,
          uniqueTimeReport: `${startDate}-${report.id}`,
          value: costsSum,
          name: intl.formatMessage({
            id: LocalizationKeys.Components.Reporting.Overview.Costs,
          }),
        },
        {
          reportId: report.id,
          timeLabel: startDate,
          color: REPORT_ITEM_TYPE_REVENUE_COLOR,
          uniqueTimeReport: `${startDate}-${report.id}`,
          value: revenueSum,
          name: intl.formatMessage({
            id: LocalizationKeys.Components.Reporting.Overview.Revenues,
          }),
        },
        {
          reportId: report.id,
          timeLabel: startDate,
          color: 'orange',
          uniqueTimeReport: `${startDate}-${report.id}`,
          value: revenueSum - costsSum,
          name: intl.formatMessage({
            id: LocalizationKeys.Components.Reporting.Overview.Margin,
          }),
        },
      ]
    })
    .flat()

  const reportsWeatherData = reports
    .map((report, index) => {
      const startDate = dayjs(report.event?.start_date).format(
        TIME_LABEL_FORMAT
      )
      return [
        {
          reportId: report.id,
          timeLabel: startDate,
          uniqueTimeReport: `${startDate}-${report.id}`,
          name: intl.formatMessage({
            id: LocalizationKeys.Components.Weather.Temp,
          }),
          color: '#BB8FCE',
          value: report.weather?.temp ?? 0,
        },
        {
          reportId: report.id,
          timeLabel: startDate,
          uniqueTimeReport: `${startDate}-${report.id}`,
          name: intl.formatMessage({
            id: LocalizationKeys.Components.Weather.Rain,
          }),
          color: '#3498DB',
          value: report.weather?.rain ?? 0,
        },
      ]
    })
    .flat()

  const chartDataConfig = {
    children: [
      {
        data: reportsFinancialData,
        type: 'interval',
        yField: 'value',
        colorField: 'name',
        group: true,
        style: {
          maxWidth: 40,
          fill: (d: any) => d.color ?? 'white',
        },
        axis: {
          y: {
            labelFormatter: (text: string) =>
              currencyFormat(Number(text), currency),
            grid: true,
            gridLineWidth: 2,
            gridStrokeOpacity: 1,
            gridLineDash: null,
            gridStroke: 'white',
            gridStrokeWidth: 1,
          },
        },
      },
      {
        data: reportsWeatherData,
        type: 'line',
        yField: 'value',
        color: 'red',
        axis: {
          y: {
            position: 'right',
          },
        },
        colorField: 'name',
        shapeField: 'smooth',
        scale: { series: { independent: true } },
        style: {
          lineWidth: 3,
          lineDash: [5, 5],
        },
      },
    ],
  }

  const getItemMarkerColor = (datum: Datum) =>
    reportsFinancialData.find((d) => d.name === datum.label)?.color ?? undefined

  return (
    <Card
      title={
        displayMode == 'standalone' && (
          <Space
            direction="horizontal"
            className="w-full"
            style={{ justifyContent: 'space-between' }}
          >
            {intl.formatMessage({
              id: LocalizationKeys.Components.Reporting.Chart.Evolution,
            })}
          </Space>
        )
      }
      bordered={displayMode == 'standalone'}
      styles={{ body: { padding: displayMode == 'embedded' ? '0' : '' } }}
    >
      <DualAxes
        xField={'uniqueTimeReport'}
        {...chartDataConfig}
        legend={{
          color: {
            itemLabelFill: 'white',
            itemMarkerFill: getItemMarkerColor,
            itemMarkerStroke: getItemMarkerColor,
          },
        }}
        tooltip={{
          title: (d: any) => {
            const report = findReportWithId(d.reportId)
            if (!report || !report.event) return d.timeLabel
            return `${dayjs(report.event.start_date).format('DD/MM/YYYY')} - ${
              report.event.name
            }`
          },
          items: [
            {
              channel: 'y',
            },
          ],
        }}
        axis={{
          x: {
            labelFormatter: (text: string, item: any, index: number) => {
              const matches = text.match(/.*\-(\d*)/)
              if (!matches || matches.length < 2) return text
              const report = findReportWithId(matches[1])
              return report !== undefined && report.event !== undefined
                ? dayjs(report.event.start_date).format('DD/MM/YYYY')
                : text
            },
            labelFill: 'rgba(200,200,200)',

            line: true,
            lineLineWidth: 2,
            lineStroke: 'white',
            lineLineDash: [0, 0],

            tick: true,
            tickLineWidth: 2,
            tickStroke: 'white',
          },
          y: {
            labelFill: 'rgba(200,200,200)',
          },
        }}
      />
      <Typography.Text type="secondary" italic>
        Click on the legend to hide/display values
      </Typography.Text>
    </Card>
  )
}

type ChartsType = { Charts: typeof Charts }

export { Charts, type ChartsType }

import { Space, Typography } from 'antd'
import { Booking } from '../booking/Booking'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { useEffect } from 'react'
import { initialQuery } from '../../utils/helpers/crud/models'
import { TableParams } from '../../models/TableParams'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import BaseExternalRequests from '../BaseExternalRequests'
import {
  approveInvoice,
  deleteInvoice,
  getNonApprovedInvoices,
  selectAllNotApprovedInvoices,
} from '../../reducers/InvoiceReducer'
import { Invoice } from './Invoice'
import dayjs from 'dayjs'

interface ExternalRequestsProps {
  eventId: number
  shouldRefreshList: () => void
}

const ExternalRequests = ({
  eventId,
  shouldRefreshList,
}: ExternalRequestsProps) => {
  const dispatch = useAppDispatch()
  const invoices = useAppSelector(selectAllNotApprovedInvoices())

  useEffect(() => {
    const baseQuery: TableParams = {
      ...initialQuery,
      filters: { events: [eventId] },
      columnKey: 'time',
      order: 'ascend',
    }
    dispatch(getNonApprovedInvoices(baseQuery))
  }, [])

  if (invoices.length === 0) return <></>

  return (
    <BaseExternalRequests
      entity={SBRMType.invoice}
      items={invoices}
      renderItem={(item) => (
        <Space size={10}>
          <Booking.Avatar id={item.booking ?? 0} linkToDetails={false} />
          <Invoice.ReferenceTag model={item} />
          <Invoice.AmountsTag model={item} />
          <Typography.Text>
            {dayjs(item.dueDate).format('DD-MM-YYYY')}
          </Typography.Text>
        </Space>
      )}
      updateMethod={approveInvoice}
      deleteMethod={deleteInvoice}
      shouldRefreshList={shouldRefreshList}
    />
  )
}

type ExternalRequestsType = { ExternalRequests: typeof ExternalRequests }

export { ExternalRequests, type ExternalRequestsType }

import { Form, FormInstance, Input, Result } from 'antd'
import { useAppSelector } from '../../../reducers/hooks'
import { requiredRule } from '../../../utils/rules'
import { useIntl } from 'react-intl'
import { useEffect } from 'react'
import { selectSelectedRoom } from '../../../reducers/RoomReducer'
import { WarningOutlined } from '@ant-design/icons'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

interface Props {
  form: FormInstance
}

const Delete = ({ form }: Props) => {
  const intl = useIntl()
  const item = useAppSelector(selectSelectedRoom())

  useEffect(() => {
    form.setFieldsValue(item)
  }, [item])

  return item ? (
    <Form form={form} layout="vertical">
      <Result
        title={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.AreYouSureToDelete,
        })}
        icon={<WarningOutlined style={{ color: '#dc4446' }} />}
      />
      <Form.Item
        name="id"
        required
        rules={[requiredRule(intl)]}
        className="d-none"
      >
        <Input type="hidden" />
      </Form.Item>
    </Form>
  ) : (
    <></>
  )
}

export default Delete

import { useIntl } from 'react-intl'
import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Space,
  Typography,
} from 'antd'
import { numberRule, requiredRule, stringRule } from '../../../utils/rules'
import { useAppSelector } from '../../../reducers/hooks'
import { useEffect, useState } from 'react'
import { selectSelectedBooking } from '../../../reducers/BookingReducer'
import { fieldFormatter } from '../../../utils/formatters'
import { SBAsyncSelect } from '../../../components/custom-inputs/sb-async-select/SBAsyncSelect'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import { ApartmentOutlined, InboxOutlined } from '@ant-design/icons'
import Dragger from 'antd/es/upload/Dragger'

const { TextArea } = Input
const { Text } = Typography

interface Props {
  form: FormInstance
}

const Update = ({ form }: Props) => {
  const intl = useIntl()
  const booking = useAppSelector(selectSelectedBooking())

  const [feeIsSplitted, setFeeIsSplitted] = useState<boolean>(
    booking?.artistFee != undefined ||
      booking?.bookingFee != undefined ||
      booking?.productionFee != undefined
  )

  useEffect(() => {
    form.setFieldsValue(booking)
  }, [booking])

  if (booking === undefined) {
    return <></>
  }

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="id"
        rules={[requiredRule(intl)]}
        style={{ display: 'none' }}
      >
        <Input type="hidden" />
      </Form.Item>
      <SBAsyncSelect
        type={SBRMType.contact}
        name="owner"
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Owner })}
        rules={[requiredRule(intl)]}
      />
      <SBAsyncSelect
        type={SBRMType.bookingType}
        name="type"
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Type })}
        rules={[requiredRule(intl)]}
      />
      <Row gutter={[16, 16]}>
        {!feeIsSplitted ? (
          <Col span={18}>
            <Form.Item
              name="price"
              label={intl.formatMessage({
                id: LocalizationKeys.Misc.Form.Price,
              })}
              rules={[requiredRule(intl), numberRule(intl)]}
            >
              <InputNumber
                min={0}
                className="w-full"
                formatter={fieldFormatter}
                disabled={feeIsSplitted}
              />
            </Form.Item>
          </Col>
        ) : (
          <>
            <Col span={6}>
              <Form.Item
                name="artistFee"
                label={'Artist fee'}
                rules={[requiredRule(intl), numberRule(intl)]}
              >
                <InputNumber
                  min={0}
                  className="w-full"
                  formatter={fieldFormatter}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="productionFee"
                label={'Production fee'}
                rules={[requiredRule(intl), numberRule(intl)]}
              >
                <InputNumber
                  min={0}
                  className="w-full"
                  formatter={fieldFormatter}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="bookingFee"
                label={'Booking fee'}
                rules={[requiredRule(intl), numberRule(intl)]}
              >
                <InputNumber
                  min={0}
                  className="w-full"
                  formatter={fieldFormatter}
                />
              </Form.Item>
            </Col>
          </>
        )}
        <Col span={6}>
          <Button
            style={{ marginTop: 30 }}
            className="w-full"
            type="default"
            icon={<ApartmentOutlined />}
            onClick={() => setFeeIsSplitted(!feeIsSplitted)}
          >
            {feeIsSplitted ? 'Unsplit' : 'Split'}
          </Button>
        </Col>
      </Row>
      <SBAsyncSelect
        size="middle"
        type={SBRMType.currency}
        name={'currency'}
        rules={[requiredRule(intl)]}
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Currency,
        })}
      />
      <Form.Item
        name="internal_note"
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.MemoDeal })}
        rules={[stringRule(intl)]}
      >
        <TextArea rows={8} />
      </Form.Item>
      <Form.Item
        name="file"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.ReplaceTheFile,
        })}
      >
        <Dragger
          style={{
            borderStyle: 'dashed',
            borderColor: '#dc4446',
            background: '#281f20',
          }}
          accept="*"
          maxCount={1}
          multiple={false}
          beforeUpload={(file) => {
            return false
          }}
        >
          <Space direction="vertical" size={0}>
            <p className="ant-upload-drag-icon" style={{ marginTop: 0 }}>
              <InboxOutlined style={{ color: '#dc4446' }} />
            </p>
            <Text style={{ color: '#dc4446' }}>
              {intl.formatMessage({
                id: LocalizationKeys.Components.CustomInput.Dropzone,
              })}
            </Text>
            <Text
              type="secondary"
              italic
              style={{ fontSize: 12, color: '#dc4446' }}
            >
              {intl.formatMessage({
                id: LocalizationKeys.Components.File.MaxSize,
              })}
            </Text>
          </Space>
        </Dragger>
      </Form.Item>
    </Form>
  )
}

export default Update

import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIUpdate,
  SBAPIFetch,
  SBAPIPost,
} from '../utils/helpers/SBAPIHelper'
import { Event } from '../models/Event'
import { EVENTS_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams, TableParamsWithDates } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { eventSchema } from '../models/schema'

const initialState: SBState<Event> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: {
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },
}

const slice = createSlice({
  name: 'event',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getEventsSuccess,
  setQuery: setEventQuery,
  reset: resetEventState,
  resetQueryAndIds: resetEventQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: Event }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectEvents = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )

export const selectAllEvents = () =>
  createSelector([selectRawItems], (items) =>
    Object.entries(items).map((i) => i[1])
  )
export const selectSelectedEvent = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectEventById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectEventsByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedEvent =
  (Event: Event | number) => async (dispatch: AppDispatch) =>
    dispatch(slice.actions.setSelectedId(getIdOrModelId<Event>(Event)))

export const getEvents = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<Event>(
    EVENTS_URL,
    params,
    [eventSchema],
    slice.actions
  )

export const getEventsByDates = (params: TableParamsWithDates) =>
  SBAPIFetchPaginatedDispatch<Event>(
    `${EVENTS_URL}/by-dates`,
    params,
    [eventSchema],
    slice.actions
  )

export const getEventWithId = (id: number) =>
  SBAPIFetchDispatch<Event>(`${EVENTS_URL}/${id}`, eventSchema, slice.actions)

export const getEventFinancialWithId = (id: number) =>
  SBAPIFetch<Event>(`${EVENTS_URL}/${id}/financial`)

export const createEvent = (Event: Event | any) =>
  SBAPICreate<Event>(Event, EVENTS_URL, eventSchema, slice.actions)

export const updateEvent = (Event: Event) =>
  SBAPIUpdate<Event>(Event, `${EVENTS_URL}/${Event.id}`, slice.actions)

export const deleteEvent = (Event: Event) =>
  SBAPIDelete<Event>(Event, `${EVENTS_URL}/${Event.id}`, slice.actions)

export const importContactsFromVenue = (event: Event, venueId: number) =>
  SBAPIPost(`${EVENTS_URL}/${event.id}/contacts/import/venue/${venueId}`, {})

export const exportEventResource = (eventId: number, resource: string) =>
  SBAPIPost(`${EVENTS_URL}/${eventId}/exports`, { resource: resource })

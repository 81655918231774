import dayjs from 'dayjs'
import {
  Alert,
  AlertProps,
  Space,
  Tag,
  Timeline,
  Tooltip,
  Typography,
} from 'antd'
import { ActivityLog, CauserActivityLog } from '../models/ActivityLog'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Link } from 'react-router-dom'
import { Event } from './event/Event'
import { Contact } from './contact/Contact'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../i18n/LocalizationKeys'

const { Text } = Typography

interface Props {
  activities: ActivityLog[]
}

export const SBActivityLog = ({ activities }: Props) => {
  dayjs.extend(relativeTime)

  return (
    <Timeline
      mode="left"
      items={activities.map((activity) => ({
        children: (
          <>
            <p>
              {activity.description} by {activity.causer.firstName}{' '}
              {activity.causer.lastName} <b>{dayjs(activity.date).fromNow()}</b>
            </p>
          </>
        ),
      }))}
    />
  )
}

interface CauserProps {
  activities: CauserActivityLog[]
  maxHeight?: number
}

export const SBCauserActivityLog = ({
  activities,
  maxHeight = 800,
}: CauserProps) => {
  dayjs.extend(relativeTime)
  const intl = useIntl()

  const descriptionToNiceDescription: Record<string, string> = {
    ['created']: intl.formatMessage({
      id: LocalizationKeys.Components.SBActivityLog.Created,
    }),
    ['updated']: intl.formatMessage({
      id: LocalizationKeys.Components.SBActivityLog.Updated,
    }),
    ['deleted']: intl.formatMessage({
      id: LocalizationKeys.Components.SBActivityLog.Deleted,
    }),
  }

  const descriptionToAlertStyle: Record<string, string> = {
    ['created']: 'success',
    ['updated']: 'warning',
    ['deleted']: 'danger',
  }

  const subjectDisplayer = (activity: CauserActivityLog) => {
    const defaultDisplay = (
      <Text>
        {activity.subjectType} #{activity.subjectId}
      </Text>
    )

    if (activity.description === 'deleted' || !activity.subject) {
      return (
        <Text disabled>
          {activity.subjectType} #{activity.subjectId} -{' '}
          {intl.formatMessage({
            id: LocalizationKeys.Components.SBActivityLog.Deleted,
          })}
        </Text>
      )
    } else if (activity.subjectType === 'Event') {
      return <Event.Cell model={activity.subject} />
    } else if (activity.subjectType === 'Contact') {
      return (
        <Contact.Avatar
          model={activity.subject}
          editable={false}
          linkToDetails
        />
      )
    } else {
      return defaultDisplay
    }
  }

  return (
    <Timeline
      style={{ maxHeight: maxHeight, overflow: 'scroll', padding: 24 }}
      mode="left"
      items={activities.map((activity) => ({
        children: (
          <Space direction="vertical" className="w-full">
            <Text>
              {descriptionToNiceDescription[activity.description] ??
                activity.description}
            </Text>
            <Alert
              type={
                descriptionToAlertStyle[
                  activity.description
                ] as AlertProps['type']
              }
              message={
                <Space
                  direction="horizontal"
                  className="w-full"
                  style={{ justifyContent: 'space-between' }}
                >
                  <Space direction="horizontal">
                    {subjectDisplayer(activity)}
                  </Space>
                  <Text type="secondary">{dayjs(activity.date).fromNow()}</Text>
                </Space>
              }
            ></Alert>
          </Space>
        ),
      }))}
    />
  )
}

import { PaperClipOutlined } from '@ant-design/icons'
import { Badge, Button, Dropdown, Tag, Typography } from 'antd'
import { useAppSelector } from '../../reducers/hooks'
import { selectFilesByIds } from '../../reducers/FileReducer'
import { File } from './File'

const { Text } = Typography

interface Props {
  ids: number[]
}

const AttachmentDropdown = ({ ids }: Props) => {
  const files = useAppSelector(selectFilesByIds(ids))
  return (
    <Dropdown
      menu={{
        items: files.map((f) => ({
          key: f.id,
          label: <Text style={{ marginLeft: 5 }}>{f.name}</Text>,
          icon: <File.DownloadButton id={f.id} />,
        })),
      }}
    >
      <Button onClick={undefined} icon={<PaperClipOutlined />} />
    </Dropdown>
  )
}

type AttachmentDropdownType = { AttachmentDropdown: typeof AttachmentDropdown }

export { AttachmentDropdown, type AttachmentDropdownType }

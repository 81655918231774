import { FC } from 'react'
import { ExternalRequests, ExternalRequestsType } from './ExternalRequests'
import { Nights, NightsType } from './Nights'
import { Price, PriceType } from './Price'
import { RoomForm, RoomFormType } from './form/Form'

export const Room: FC &
  NightsType &
  PriceType &
  RoomFormType &
  ExternalRequestsType = (): JSX.Element => <></>

Room.Nights = Nights
Room.Price = Price
Room.Form = RoomForm
Room.ExternalRequests = ExternalRequests

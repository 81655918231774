import { Column } from '@ant-design/plots'
import { currencyFormat } from '@supplement-bacon/alela-uikit'
import { useTenantSettings } from '../../utils/hooks/useTenantSettings'

interface Props {
  datas: Item[]
}
interface Item {
  type: string
  value: number
  color: string
}

export const Balance = ({ datas }: Props) => {
  const { currency } = useTenantSettings()
  const data = datas.sort((a: any, b: any) => b.value - a.value)

  return (
    <Column
      data={data}
      xField={'type'}
      yField={'value'}
      style={{
        fill: ({ type }: { type: string }) =>
          data.find((d) => d.type === type)?.color ?? '#5d5df6',
      }}
      label={{
        text: (originData: Item) =>
          currencyFormat(Number(originData.value), currency),
        fill: 'white',
        fillOpacity: 1,
        fontWeight: 'bolder',
        offset: 20,
      }}
      tooltip={(d: Item) => ({
        name: d.type,
        color: d.color,
        value: `${currencyFormat(d.value, currency)}`,
      })}
      axis={{
        x: {
          labelFill: 'rgba(200,200,200)',

          line: true,
          lineLineWidth: 2,
          lineStroke: 'white',
          lineLineDash: [0, 0],

          tick: true,
          tickLineWidth: 2,
          tickStroke: 'white',
        },
        y: {
          labelFill: 'rgba(200,200,200)',

          grid: true,
          gridLineWidth: 2,
          gridStrokeOpacity: 1,
          gridLineDash: null,
          gridStroke: 'white',
          gridStrokeWidth: 1,
        },
      }}
    />
  )
}

export type BalanceType = { Balance: typeof Balance }

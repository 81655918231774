// @flow
import React from 'react'
import type { DraggableProvided } from 'react-beautiful-dnd'
import styled from 'styled-components'
import { Card } from '../../models/Kanban'
import { MarketingTask } from '../marketing-task/MarketingTask'
import { useKanban } from './context'

type ItemProps<T> = {
  card: Card<T>
  isDragging: boolean
  provided: DraggableProvided
  isClone?: boolean
  isGroupedOver?: boolean
  style?: Object
  index?: number
}

const Container = styled.a`
  user-select: none;
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
`

function Item<T>(props: ItemProps<T>) {
  const { card, isDragging, provided, index } = props
  const { renderItem } = useKanban()

  return (
    <Container
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      data-is-dragging={isDragging}
      data-testid={card.id}
      data-index={index}
      aria-label={`card ${card.id}`}
    >
      {renderItem(card.rawItem)}
    </Container>
  )
}

type ItemType = typeof Item

export default React.memo<ItemType>(Item)

import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIUpdate,
} from '../utils/helpers/SBAPIHelper'
import { File } from '../models/File'
import { FILES_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { fileSchema } from '../models/schema'
import { initialQuery } from '../utils/helpers/crud/models'

export const initialFileQuery: TableParams = {
  ...initialQuery,
}

const initialState: SBState<File> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: initialFileQuery,
}

const slice = createSlice({
  name: 'file',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getFilesSuccess,
  setQuery: setFileQuery,
  reset: resetFileState,
  resetQueryAndIds: resetFileQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: File }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectFiles = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedFile = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectFileById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectFilesByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedFile =
  (file: File | number | undefined) => async (dispatch: AppDispatch) =>
    dispatch(
      slice.actions.setSelectedId(file ? getIdOrModelId<File>(file) : undefined)
    )

export const getFiles = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<File>(
    FILES_URL,
    params,
    [fileSchema],
    slice.actions
  )

export const getFileWithId = (id: number) =>
  SBAPIFetchDispatch<File>(`${FILES_URL}/${id}`, fileSchema, slice.actions)

export const createFile = (data: FormData) =>
  SBAPICreate<FormData>(data, FILES_URL, fileSchema, slice.actions)

export const updateFile = (file: File) =>
  SBAPIUpdate<File>(file, `${FILES_URL}/${file.id}`, slice.actions)

export const deleteFile = (file: File) =>
  SBAPIDelete<File>(file, `${FILES_URL}/${file.id}`, slice.actions)

import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import Dragger from 'antd/es/upload/Dragger'
import TextArea from 'antd/es/input/TextArea'
import { useAppSelector } from '../../../reducers/hooks'
import { Form, FormInstance, Input, Space, Typography } from 'antd'
import { requiredRule, stringRule } from '../../../utils/rules'
import { selectSelectedContract } from '../../../reducers/ContractReducer'
import { SBAsyncSelect } from '../../../components/custom-inputs/sb-async-select/SBAsyncSelect'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import ExternalAdvancingStatus from '../../ExternalAdvancingStatus'
import { InboxOutlined } from '@ant-design/icons'

const { Text } = Typography

interface Props {
  form: FormInstance
}

const Update = ({ form }: Props) => {
  const intl = useIntl()
  const contract = useAppSelector(selectSelectedContract())

  useEffect(() => {
    if (contract === undefined) return

    form.setFieldsValue({
      id: contract.id,
      status: contract.status,
      notes: contract.notes ?? '',
      isApproved: contract.isApproved,
    })
  }, [contract])

  if (contract === undefined) {
    return <></>
  }

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="id"
        style={{ display: 'none' }}
        rules={[requiredRule(intl)]}
      >
        <Input type="hidden" />
      </Form.Item>
      <SBAsyncSelect
        type={SBRMType.contractStatus}
        name={'status'}
        rules={[requiredRule(intl)]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Status })}
      />
      <Form.Item
        name="notes"
        rules={[stringRule(intl)]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Notes })}
      >
        <TextArea rows={5} />
      </Form.Item>

      <Form.Item
        name="file"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.ReplaceTheFile,
        })}
      >
        <Dragger
          style={{
            borderStyle: 'dashed',
            borderColor: '#dc4446',
            background: '#281f20',
          }}
          accept="*"
          maxCount={1}
          multiple={false}
          beforeUpload={(file) => {
            return false
          }}
        >
          <Space direction="vertical" size={0}>
            <p className="ant-upload-drag-icon" style={{ marginTop: 0 }}>
              <InboxOutlined style={{ color: '#dc4446' }} />
            </p>
            <Text style={{ color: '#dc4446' }}>
              {intl.formatMessage({
                id: LocalizationKeys.Components.CustomInput.Dropzone,
              })}
            </Text>
            <Text
              type="secondary"
              italic
              style={{ fontSize: 12, color: '#dc4446' }}
            >
              {intl.formatMessage({
                id: LocalizationKeys.Components.File.MaxSize,
              })}
            </Text>
          </Space>
        </Dragger>
      </Form.Item>
      <ExternalAdvancingStatus />
    </Form>
  )
}

export default Update

import { LocalizationKey } from '../LocalizationKeys'

const FR: Record<LocalizationKey, string> = {
  [LocalizationKey.AuthForgotPasswordButton]: 'Réinitialiser',
  [LocalizationKey.AuthForgotPasswordBackToLogin]: 'Retour à la connexion',
  [LocalizationKey.AuthForgotPasswordSuccess]:
    'Vous allez recevoir un lien de réinitialisation de mot de passe si un compte est associé à cet email. Vérifiez aussi vos spams',
  [LocalizationKey.AuthForgotPasswordFailure]: 'Une erreur est survenue',

  [LocalizationKey.AuthInvitationErrorWhileAccepting]:
    "Erreur lors de l'acceptation de l'invitation. Réessayez plus tard...",
  [LocalizationKey.AuthInvitationErrorWhileLoading]:
    "Erreur lors du chargement de l'invitation. Vérifiez que le code d'invitation soit correct",
  [LocalizationKey.AuthInvitationTitle]: 'Vous êtes invité !',
  [LocalizationKey.AuthInvitationNeedsYou]: "a besoin de vous dans l'équipe",
  [LocalizationKey.AuthInvitationAccept]: "Accepter l'invitation",
  [LocalizationKey.AuthInvitationRegister]: "S'inscrire",
  [LocalizationKey.AuthInvitationAlreadyHaveAnAccount]:
    'Vous avez déjà un compte ? Se connecter',

  [LocalizationKey.AuthLoginError]:
    "Assurez vous que votre email/mot de passe soit correct. Si le problème persiste contactez l'administrateur de votre organisation",
  [LocalizationKey.AuthLoginSSOError]:
    "SAML SSO n'est pas configuré pour cette Organisation",
  [LocalizationKey.AuthLoginSSO]: 'SAML SSO',
  [LocalizationKey.AuthLogin]: 'Connexion',
  [LocalizationKey.AuthLoginForgotPassword]: 'Mot de passe oublié',
  [LocalizationKey.AuthLoginContinueWithSSO]: 'Continuer avec SAML SSO',
  [LocalizationKey.AuthLoginLoginWithMicrosoft]: 'Connexion avec Microsoft',
  [LocalizationKey.AuthLoginEmailConnexion]: 'Connexion par email',
  [LocalizationKey.AuthLoginRedirect]:
    'Nous avons besoin de quelques instants pour vous connecter ...',

  [LocalizationKey.AuthRegisterError]:
    "Erreur lors de l'inscription, réessayez plus tard",
  [LocalizationKey.AuthRegisterErrorEmailAlreadyExists]:
    'Un compte avec cet email existe déjà. Connectez-vous avec le lien ci-dessous',
  [LocalizationKey.AuthRegisterTitle]: 'Rejoignez le futur',
  [LocalizationKey.AuthRegisterInvitedYouToJoinTheTeam]:
    "vous a invité à rejoindre l'équipe",
  [LocalizationKey.AuthRegister]: "S'inscrire",
  [LocalizationKey.AuthRegisterAlreadyHaveAnAccount]:
    'Vous avez déja un compte ? Se connecter',
  [LocalizationKey.AuthRegisterSuccess]:
    'Vous pouvez maintenant vous connecter',
  [LocalizationKey.AuthRegisterGoToLogin]: 'Allez à la connexion',

  [LocalizationKey.PageArtistsTitle]: 'Artistes',
  [LocalizationKey.PageArtistsName]: 'Nom',
  [LocalizationKey.PageArtistsBookingsCount]: 'Nombre de bookings',
  [LocalizationKey.PageArtistLastFee]: 'Dernier fee',
  [LocalizationKey.PageArtistAverageFee]: 'Fee moyen',
  [LocalizationKey.PageArtistMinimumFee]: 'Fee minimum',
  [LocalizationKey.PageArtistMaximumFee]: 'Fee maximum',
  [LocalizationKey.PageArtistBookings]: 'Bookings',
  [LocalizationKey.PageArtistListen]: 'Écouter',
  [LocalizationKey.PageArtistCrew]: 'Équipe',
  [LocalizationKey.PageArtistFiles]: 'Fichiers',

  [LocalizationKey.PagePreferenceTitle]: 'Préférences',
  [LocalizationKey.PagePreferenceLocale]: 'Langue',

  [LocalizationKey.PageProfileTitle]: 'Profil',
  [LocalizationKey.PageProfileUpdateSuccess]: 'Profil mis à jour !',
  [LocalizationKey.PageProfileUpdateFailure]:
    "Erreur lors de l'enregistrement du profil... Réessayer plus tard.",

  [LocalizationKey.PageSettingsOrganizationTitle]: 'Organisation',
  [LocalizationKey.PageSettingsOrganizationBillingInfos]:
    'Informations de facturation',
  [LocalizationKey.PageSettingsOrganizationPlan]: 'Plan',
  [LocalizationKey.PageSettingsOrganizationRoles]: 'Rôles & accès',
  [LocalizationKey.PageSettingsOrganizationUsage]: 'Quotas',

  [LocalizationKey.PageSettingsOrganizationPreferencesTitle]:
    "Préférences de l'organisation",
  [LocalizationKey.PageSettingsOrganizationPreferencesUnitsOfMeasurementDescription]:
    "Utilisé pour la météo du rapport d'évènement",
  [LocalizationKey.PageSettingsOrganizationPreferencesLocaleDescription]:
    'Définit la langue par défaut des nouveaux utilisateurs et des notifications',
  [LocalizationKey.PageSettingsOrganizationPreferencesFirstCalendarDayDescription]:
    'Définit le premier jour des calendriers',
  [LocalizationKey.PageSettingsOrganizationPreferencesMetricLabel]:
    'Métrique - °C / mètre/seconde',
  [LocalizationKey.PageSettingsOrganizationPreferencesImperialLabel]:
    'Impérial - °F / miles/heure',

  [LocalizationKey.PageSettingsMarketingTaskStatusTitle]:
    'Status de tâche marketing',

  [LocalizationKey.PageAccountActivityTitle]: 'Activités',
  [LocalizationKey.PageAccountActivityToolbar]: '20 dernières actions',

  [LocalizationKey.PageCRMContactsTitle]: 'Contacts',
  [LocalizationKey.PageCRMContactsName]: 'Nom',
  [LocalizationKey.PageCRMContactsSearch]: 'Rechercher un contact',
  [LocalizationKey.PageCRMContactsMerge]: 'Merger des contacts',
  [LocalizationKey.PageCRMContactLoading]: 'Chargement du contact ...',
  [LocalizationKey.PageCRMContactBack]: 'Retour',
  [LocalizationKey.PageCRMContactWorkFor]: 'Travail pour',
  [LocalizationKey.PageCRMContactMergerSuccess]: 'Contacts mergés avec succès',
  [LocalizationKey.PageCRMContactMergerFailure]:
    'Erreur lors du merge des contacts',
  [LocalizationKey.PageCRMContactMergerTitle]: 'Merger de contacts',
  [LocalizationKey.PageCRMContactMergerDescription]: `Le merger de contacts permet de supprimer les doublons de contacts.
Les contacts associés à des utilisateurs ne peuvent pas être supprimés.`,
  [LocalizationKey.PageCRMContactMergerSelectContact]:
    'Sélectionner un contact',
  [LocalizationKey.PageCRMContactMergerContactToKeep]: 'Contact à garder',
  [LocalizationKey.PageCRMContactMergerContactToDelete]: 'Contact à supprimer',
  [LocalizationKey.PageCRMContactMergerMergeButton]: 'Merger les contacts',

  [LocalizationKey.PageCRMSuppliersTitle]: 'Fournisseurs',
  [LocalizationKey.PageCRMSuppliersName]: 'Nom',
  [LocalizationKey.PageCRMSuppliersType]: 'Type',
  [LocalizationKey.PageCRMSuppliersSupplierBalance]: 'Balance fournisseur',
  [LocalizationKey.PageCRMSuppliersContacts]: 'Contacts',
  [LocalizationKey.PageCRMSuppliersSearch]: 'Rechercher un fournisseur',
  [LocalizationKey.PageCRMSupplierLoading]: 'Chargement du fournisseur ...',
  [LocalizationKey.PageCRMSupplierBack]: 'Retour',
  [LocalizationKey.PageCRMSupplierUpdatePicture]: 'Modifier la photo',
  [LocalizationKey.PageCRMSupplierTeam]: 'Équipe',
  [LocalizationKey.PageCRMSupplierInvoices]: 'Factures',

  [LocalizationKey.PageCRMUserLoading]:
    "Chargement des infos de l'utilisateur ...",
  [LocalizationKey.PageCRMUserBack]: 'Retour',
  [LocalizationKey.PageCRMUserAssociatedContact]: 'Contact associé',
  [LocalizationKey.PageCRMUserEvents]: 'Évènements',
  [LocalizationKey.PageCRMUserActivities]: 'Activités',

  [LocalizationKey.PageCRMTeamName]: 'Nom',
  [LocalizationKey.PageCRMTeamRole]: 'Rôle',
  [LocalizationKey.PageCRMTeamTitle]: 'Équipe',
  [LocalizationKey.PageCRMTeamInvitations]: 'Invitations',
  [LocalizationKey.PageCRMTeamSubscriptionWillAdjustDisclaimer]:
    "Votre abonnement s'ajuste automatiquement lorsque vous modifez les rôles des utilisateurs.{br}Les modifications de votre abonnement seront visibles sur la prochaine facture.",

  [LocalizationKey.PageCRMInvitationsTitle]: 'Invitations en attente',
  [LocalizationKey.PageCRMInvitationsBack]: 'Retour',
  [LocalizationKey.PageCRMInvitationsInvitedAt]: 'Invité à',

  [LocalizationKey.PageEventDetailsContacts]: 'Contacts',
  [LocalizationKey.PageEventDetailsFiles]: 'Fichiers',
  [LocalizationKey.PageEventDetailsTimetable]: 'Timetable',
  [LocalizationKey.PageEventDetailsPartyCrew]: 'Party Crew',
  [LocalizationKey.PageEventDetailsLineUpArtist]: 'Artiste',
  [LocalizationKey.PageEventDetailsLineUpPrice]: 'Prix',
  [LocalizationKey.PageEventDetailsLineUpType]: 'Type',
  [LocalizationKey.PageEventDetailsLineUpMemoDeal]: 'Memo deal',
  [LocalizationKey.PageEventDetailsLineUpOwner]: 'Responsable',
  [LocalizationKey.PageEventDetailsLineUp]: 'Line-up',
  [LocalizationKey.PageEventDetailsContractsTitle]: 'Contrats',
  [LocalizationKey.PageEventDetailsContractsBooking]: 'Booking',
  [LocalizationKey.PageEventDetailsContractsStatus]: 'Statut',
  [LocalizationKey.PageEventDetailsContractsNotes]: 'Notes',
  [LocalizationKey.PageEventDetailsInvoicesTitle]: 'Factures',
  [LocalizationKey.PageEventDetailsInvoicesAmountPaid]: 'Montant payé',
  [LocalizationKey.PageEventDetailsInvoicesAmountToPay]: 'Montant à payer',
  [LocalizationKey.PageEventDetailsInvoicesInvoicesPaid]: 'Factures payées',
  [LocalizationKey.PageEventDetailsInvoicesInvoicesToPay]: 'Factures à payer',
  [LocalizationKey.PageEventDetailsReportingTitle]: 'Rapport',
  [LocalizationKey.PageEventDetailsGroundsBookings]: 'Booking',
  [LocalizationKey.PageEventDetailsGroundsInfos]: 'Infos',
  [LocalizationKey.PageEventDetailsGroundsDriver]: 'Chauffeur',
  [LocalizationKey.PageEventDetailsGroundsPassengers]: 'Passagers',
  [LocalizationKey.PageEventDetailsGroundsNotes]: 'Notes',
  [LocalizationKey.PageEventDetailsGroundsGroundNotes]: 'Notes du ground',
  [LocalizationKey.PageEventDetailsGroundsGround]: 'Ground',
  [LocalizationKey.PageEventDetailsHotelsBooking]: 'Booking',
  [LocalizationKey.PageEventDetailsHotelsHotel]: 'Hôtel',
  [LocalizationKey.PageEventDetailsHotelsPrice]: 'Prix',
  [LocalizationKey.PageEventDetailsHotelsDates]: 'Dates',
  [LocalizationKey.PageEventDetailsHotelsNights]: 'nuits',
  [LocalizationKey.PageEventDetailsHotelsTravelers]: 'Voyageurs',
  [LocalizationKey.PageEventDetailsHotelsNotes]: 'Notes',
  [LocalizationKey.PageEventDetailsHotelsTitle]: "Chambres d'hôtel",
  [LocalizationKey.PageEventDetailsTechnicalBooking]: 'Booking',
  [LocalizationKey.PageEventDetailsTechnicalStatus]: 'Statut',
  [LocalizationKey.PageEventDetailsTechnicalType]: 'Type',
  [LocalizationKey.PageEventDetailsTechnicalNotes]: 'Notes',
  [LocalizationKey.PageEventDetailsTechnicalTitle]: 'Riders',
  [LocalizationKey.PageEventDetailsTravelsBooking]: 'Boooking',
  [LocalizationKey.PageEventDetailsTravelsType]: 'Type',
  [LocalizationKey.PageEventDetailsTravelsTravel]: 'Voyage',
  [LocalizationKey.PageEventDetailsTravelsTravelers]: 'Voyageurs',
  [LocalizationKey.PageEventDetailsTravelsTitle]: 'Travels',

  [LocalizationKey.PageFinancialInvoiceTitle]: 'Factures',
  [LocalizationKey.PageFinancialReportsTitle]: 'Rapports',

  [LocalizationKey.PageReleaseNotesTitle]: 'Notes de version',

  [LocalizationKey.PageCalendarToday]: "Aujourd'hui",
  [LocalizationKey.PageCalendarAll]: 'Tout',
  [LocalizationKey.PageCalendarMore]: 'plus',

  [LocalizationKey.PageSettingsVenuesName]: 'Nom',
  [LocalizationKey.PageSettingsVenuesType]: 'Type',
  [LocalizationKey.PageSettingsVenuesStages]: 'Scènes',
  [LocalizationKey.PageSettingsVenuesOpeningRange]: "Horaires d'ouverture",
  [LocalizationKey.PageSettingsVenuesTitle]: 'Lieux',
  [LocalizationKey.PageSettingsVenueStaff]: 'Personnel',
  [LocalizationKey.PageSettingsVenueStages]: 'Scènes',
  [LocalizationKey.PageSettingsVenueFiles]: 'Fichiers',
  [LocalizationKey.PageSettingsVenuesSeeArchived]: 'Lieux archivés',
  [LocalizationKey.PageSettingsArchivedVenuesTitle]: 'Lieux archivés',
  [LocalizationKey.PageSettingsArchivedVenuesRestore]: 'Restaurer',

  [LocalizationKey.PageSettingsContactTypesTitle]: 'Types de contact',
  [LocalizationKey.PageSettingsInvoiceCategoriesTitle]: 'Catégories de facture',
  [LocalizationKey.PageSettingsContractStatusTitle]: 'Statuts de contrat',

  [LocalizationKey.PageSettingsEventStatusDefaultStatus]: 'Statut par défaut',
  [LocalizationKey.PageSettingsEventStatusVisibleBy]: 'Visible par',
  [LocalizationKey.PageSettingsEventStatusTitle]: "Statuts d'évènement",
  [LocalizationKey.PageSettingsEventStatusSeeArchived]: 'Statuts archivés',

  [LocalizationKey.PageSettingsHotelsAddress]: 'Adresse',
  [LocalizationKey.PageSettingsHotelsStars]: 'Ètoiles',
  [LocalizationKey.PageSettingsHotelsTitle]: 'Hôtels',

  [LocalizationKey.PageSettingsRiderStatusTitle]: 'Statuts de rider',
  [LocalizationKey.PageSettingsRiderTypeTitle]: 'Types de rider',
  [LocalizationKey.PageSettingsStationsTitle]: 'Stations',
  [LocalizationKey.PageSettingsVenueTypesTitle]: 'Types de lieux',
  [LocalizationKey.PageSettingsBookingTypesTitle]: 'Types de booking',
  [LocalizationKey.PageSettingsPerformanceTypesTitle]: 'Types de performance',

  [LocalizationKey.PageSettingsReportingItemsTitle]: 'Éléments de rapport',
  [LocalizationKey.PageSettingsReportingItemsReorder]: 'Réordonner',
  [LocalizationKey.PageSettingsReportingReorderItemsPageTitle]:
    'Ordonner les éléments de rapport',
  [LocalizationKey.PageSettingsReportingReorderItemsPageCosts]: 'Coûts',
  [LocalizationKey.PageSettingsReportingReorderItemsPageRevenues]: 'Revenus',

  /**
   * EXTERNAL ADVANCING
   */

  [LocalizationKey.ExternalAdvancingDeal]: 'Deal',
  [LocalizationKey.ExternalAdvancingTimetable]: 'Timetable',
  [LocalizationKey.ExternalAdvancingContract]: 'Contrats',
  [LocalizationKey.ExternalAdvancingInvoices]: 'Factures',
  [LocalizationKey.ExternalAdvancingTravels]: 'Travels',
  [LocalizationKey.ExternalAdvancingHotels]: 'Hôtels',
  [LocalizationKey.ExternalAdvancingGround]: 'Grounds',
  [LocalizationKey.ExternalAdvancingTechnical]: 'Riders techniques',
  [LocalizationKey.ExternalAdvancingPartyCrew]: 'Party crew',

  [LocalizationKey.ExternalAdvancingManagerTooltip]: 'Advancing externe',
  [LocalizationKey.ExternalAdvancingManagerTitle]:
    'Advancing externe pour {artist}',
  [LocalizationKey.ExternalAdvancingManagerNewLink]: 'Générer un nouveau lien',
  [LocalizationKey.ExternalAdvancingManagerResendSuccess]:
    'Lien envoyé avec succès',
  [LocalizationKey.ExternalAdvancingManagerCellPending]: 'En attente',
  [LocalizationKey.ExternalAdvancingManagerCellResendinvitation]:
    "Renvoyer l'invitation",
  [LocalizationKey.ExternalAdvancingManagerCellDisplayPermissions]:
    'Afficher les permissions',
  [LocalizationKey.ExternalAdvancingManagerCellCopy]:
    'Copier dans le presse-papier',
  [LocalizationKey.ExternalAdvancingManagerCellExpires]: 'Expire le : {date}',
  [LocalizationKey.ExternalAdvancingManagerCellCan]:
    'Peut {permission} {entity}{quantity}',
  [LocalizationKey.ExternalAdvancingManagerListNoLink]:
    "Pas de lien d'advancing externe créé.",
  [LocalizationKey.ExternalAdvancingManagerListUseTheForm]:
    'Utiliser le formulaire ci-dessous pour en créer un',
  [LocalizationKey.ExternalAdvancingManagerSectionEdit]: 'Modifier',
  [LocalizationKey.ExternalAdvancingManagerSectionView]: 'Voir',
  [LocalizationKey.ExternalAdvancingManagerSectionAdd]: 'Ajouter',
  [LocalizationKey.ExternalAdvancingManagerSectionItemsMax]: 'éléments max',
  [LocalizationKey.ExternalAdvancingManagerCreateRestrictionsAndCapabilities]:
    "Niveaux d'accès",
  [LocalizationKey.ExternalAdvancingManagerCreateMoreOptions]: "Plus d'options",
  [LocalizationKey.ExternalAdvancingManagerCreateDeadlineLabel]: 'Deadline',
  [LocalizationKey.ExternalAdvancingManagerCreateDeadlineDescription]:
    "Une fois la deadline atteinte les ressources ne peuvent plus être éditées; mais restent accessibles en lecture-seule. Si aucune deadline n'est spécifié les ressources resteront éditables jusqu'à la date de début de l'évènement.",

  /**
   * LAYOUT
   */

  [LocalizationKey.LayoutEventDetailsSiderLineup]: 'Lineup',
  [LocalizationKey.LayoutEventDetailsSiderTimetable]: 'Timetable',
  [LocalizationKey.LayoutEventDetailsSiderAdmin]: 'Admin',
  [LocalizationKey.LayoutEventDetailsSiderContracts]: 'Contrats',
  [LocalizationKey.LayoutEventDetailsSiderInvoices]: 'Factures',
  [LocalizationKey.LayoutEventDetailsSiderReporting]: 'Rapport',
  [LocalizationKey.LayoutEventDetailsSiderProduction]: 'Production',
  [LocalizationKey.LayoutEventDetailsSiderTravels]: 'Travels',
  [LocalizationKey.LayoutEventDetailsSiderHotels]: 'Hôtels',
  [LocalizationKey.LayoutEventDetailsSiderGround]: 'Ground',
  [LocalizationKey.LayoutEventDetailsSiderTechnical]: 'Technique',
  [LocalizationKey.LayoutEventDetailsSiderPartyCrew]: 'Party Crew',
  [LocalizationKey.LayoutEventDetailsSiderFiles]: 'Fichiers',
  [LocalizationKey.LayoutEventDetailsSiderContacts]: 'Contacts',
  [LocalizationKey.LayoutEventDetailsSiderSettings]: 'Réglages',
  [LocalizationKey.LayoutEventDetailsSiderTasks]: 'Checklist',

  [LocalizationKey.LayoutMainHeaderEvents]: 'Évènements',
  [LocalizationKey.LayoutMainHeaderArtists]: 'Artistes',
  [LocalizationKey.LayoutMainHeaderMarketing]: 'Marketing',
  [LocalizationKey.LayoutMainHeaderFinancials]: 'Finances',

  [LocalizationKey.LayoutMainHeaderCRM]: 'CRM',

  [LocalizationKey.LayoutProfileSiderInformations]: 'Informations',
  [LocalizationKey.LayoutProfileSiderActivity]: 'Activité',
  [LocalizationKey.LayoutProfileSiderPreferences]: 'Préférences',
  [LocalizationKey.LayoutProfileSiderLogout]: 'Déconnexion',
  [LocalizationKey.LayoutProfileSiderLogoutConfirmation]:
    'Êtes-vous sûr de vouloir vous déconnecter ?',
  [LocalizationKey.LayoutProfileSiderImage]: 'Téléverser une photo de profil',

  [LocalizationKey.LayoutRightHeaderProfile]: 'Profil',
  [LocalizationKey.LayoutRightHeaderSettings]: 'Réglages',
  [LocalizationKey.LayoutRightHeaderLang]: 'Langue',

  [LocalizationKey.LayoutSettingsSiderUploadOrganizationImage]:
    "Téléverser une image d'organisation",
  [LocalizationKey.LayoutSettingsSiderVenues]: 'Lieux',
  [LocalizationKey.LayoutSettingsSiderHotels]: 'Hôtels',
  [LocalizationKey.LayoutSettingsSiderStations]: 'Stations',
  [LocalizationKey.LayoutSettingsSiderReportingItems]: 'Rapports',
  [LocalizationKey.LayoutSettingsSiderVenueTypes]: 'Types de lieu',
  [LocalizationKey.LayoutSettingsSiderBookingTypes]: 'Types de booking',
  [LocalizationKey.LayoutSettingsSiderContactTypes]: 'Types de contact',
  [LocalizationKey.LayoutSettingsSiderPerformanceTypes]: 'Types de performance',
  [LocalizationKey.LayoutSettingsSiderInvoiceCategories]:
    'Catégories de facture',
  [LocalizationKey.LayoutSettingsSiderEventStatuses]: "Statuts d'évènement",
  [LocalizationKey.LayoutSettingsSiderContractStatuses]: 'Statuts de contrat',
  [LocalizationKey.LayoutSettingsSiderRiderStatuses]: 'Statuts de rider',
  [LocalizationKey.LayoutSettingsSiderMarketingTaskStatuses]:
    'Status de tâches marketing',
  [LocalizationKey.LayoutSettingsSiderOrganization]: 'Organisation',
  [LocalizationKey.LayoutSettingsSiderPreferences]: 'Préférences',

  [LocalizationKey.LayoutAuthContactUs]: 'Nous contacter',
  [LocalizationKey.LayoutAuthDiscover]: 'Découvrir Alela',
  [LocalizationKey.LayoutAuthLegal]: 'Mentions légales',

  [LocalizationKey.LayoutFinancialInvoices]: 'Factures',
  [LocalizationKey.LayoutFinancialReports]: 'Raports',

  [LocalizationKey.LayoutCRMContacts]: 'Contacts',
  [LocalizationKey.LayoutCRMSuppliers]: 'Fournisseurs',
  [LocalizationKey.LayoutCRMOrganization]: 'Organisation',

  [LocalizationKey.LayoutFooterReleaseNotes]: 'Notes de version',

  /**
   * ROADSHEET
   */
  [LocalizationKey.RoadsheetGenerateRoadsheet]: 'Générer la feuille de route',

  /**
   * FILE SHARING
   */

  [LocalizationKey.FileSharingExpires]: 'Expire',
  [LocalizationKey.FileSharingDownload]: 'Télécharger',

  /**
   * FILE VIEWER
   */

  [LocalizationKey.FileViewerDownload]: 'Download',
  [LocalizationKey.FileViewerPDFViewerError]:
    "Erreur lors de l'ouverture du fichier ... réessayez plus tard",

  /**
   * SEARCH DRAWER
   */

  [LocalizationKey.SearchDrawerSearch]: 'Recherche',
  [LocalizationKey.SearchDrawerPlaceholder]: 'Taper ici pour rechercher',
  [LocalizationKey.SearchDrawerEvents]: 'Évènements',
  [LocalizationKey.SearchDrawerVenues]: 'Lieux',
  [LocalizationKey.SearchDrawerArtists]: 'Artistes',
  [LocalizationKey.SearchDrawerSuppliers]: 'Fournisseurs',

  /**
   * SBRM
   */

  [LocalizationKey.SBRMEntityNotDefined]:
    'Choisissez une action et revenez plus tard',
  [LocalizationKey.SBRMClose]: 'Fermer',
  [LocalizationKey.SBRMDelete]: 'Supprimer',
  [LocalizationKey.SBRMSave]: 'Sauvegarder',
  [LocalizationKey.SBRMUpdate]: 'Modifier',
  [LocalizationKey.SBRMContactAssociatedWithUserNotEditable]:
    'Les contacts associés à des utilisateurs ne sont pas modifiables',
  [LocalizationKey.SBRMAddNew]: 'Ajouter',
  [LocalizationKey.SBRMDuplicate]: 'Dupliquer',
  [LocalizationKey.SBRMView]: 'Voir',
  [LocalizationKey.SBRMComment]: 'Commenter',
  [LocalizationKey.SBRMTitleAddNew]: 'Ajouter un nouveau {type}',
  [LocalizationKey.SBRMTitleCreateNew]: 'Créer un nouveau {type}',
  [LocalizationKey.SBRMTitleEdit]: 'Modifier {type}',
  [LocalizationKey.SBRMTitleDelete]: 'Supprimer {type}',
  [LocalizationKey.SBRMTitleComment]: 'Commenter {type}',
  [LocalizationKey.SBRMTitleView]: 'Voir {type}',
  [LocalizationKey.SBRMTitleUpload]: 'Téléverser {type}',
  [LocalizationKey.SBRMTitleDuplicate]: 'Dupliquer {type}',
  [LocalizationKey.SBRMTitleAssign]: 'Assigner {type}',
  [LocalizationKey.SBRMTitleInvite]: 'Inviter {type}',
  [LocalizationKey.SBRMTitleCancel]: 'Annuler {type}',

  /**
   * SBAsyncSelect
   */
  [LocalizationKey.SBAsyncSelectNotFound]: 'Aucun(e) {type} trouvé(e)',

  /**
   * COMPONENTS
   */

  [LocalizationKey.ComponentsBookingContactsTableContactsFound]:
    "Contacts trouvés ! Cliquez ici pour assigner les contacts de l'artiste à cet évènement",
  [LocalizationKey.ComponentsBookingMissingContactsInPartyCrewTitle]:
    'Contacts du party crew non attachés',
  [LocalizationKey.ComponentsBookingTableEvent]: 'Évènement',
  [LocalizationKey.ComponentsBookingTableVenue]: 'Lieu',
  [LocalizationKey.ComponentsBookingTablePrice]: 'Fee',
  [LocalizationKey.ComponentsBookingTableMemoDeal]: 'Memo deal',
  [LocalizationKey.ComponentsBookingTableOfferSheet]: 'Offer sheet',
  [LocalizationKey.ComponentsBookingBarChartCurencyDisclaimer]: `Seuls les bookings dans la devise de votre organisation s'affichent ici.`,
  [LocalizationKey.ComponentsCommentEditorMentionPlaceholder]:
    'Entrez votre réponse ici',
  [LocalizationKey.ComponentsCommentEditorGIFPlaceholder]: 'Rechercher un GIF',
  [LocalizationKey.ComponentsCommentEditorSend]: 'Envoyer',
  [LocalizationKey.ComponentsCommentListSendFirstMessage]:
    'Envoyer votre premier message dans cette conversation',
  [LocalizationKey.ComponentsCommentListUseTag]:
    'Utilisez {tag} pour mentionner et notifier un utilisateur',
  [LocalizationKey.ComponentsCommentSubscribeToConversationSubscribe]:
    "S'abonner à la conversation",
  [LocalizationKey.ComponentsCommentSubscribeToConversationUnsubscribe]:
    'Se désabonner de la conversation',
  [LocalizationKey.ComponentsCommentPeopleOutsideOrganization]:
    'Attention, cette discussion inclut des personnes en dehors de votre organisation.',
  [LocalizationKey.ComponentsContactLinkedToUser]:
    'Ce contact est associé à un utilisateur',
  [LocalizationKey.ComponentsContactTableWithAssociationContact]: 'Contact',
  [LocalizationKey.ComponentsContactTableWithAssociationType]: 'Type',
  [LocalizationKey.ComponentsContactTypeListCheckboxesAll]: 'Tous',
  [LocalizationKey.ComponentsContactTypeListCheckboxesCreateType]:
    'Créer un type',
  [LocalizationKey.ComponentsCustomInputsSelectFooterArtistSpotify]: 'Spotify',
  [LocalizationKey.ComponentsCustomInputsSelectFooterArtistManual]: 'Manuel',
  [LocalizationKey.ComponentsCustomInputsSelectFooterArtistSpotifyUrl]:
    'URL Spotify',
  [LocalizationKey.ComponentsCustomInputsSelectFooterArtistArtistName]:
    "Nom d'artiste",
  [LocalizationKey.ComponentsCustomInputsDropzone]: 'Téléverser un fichier',
  [LocalizationKey.ComponentsEventAutoImportVenueContacts]:
    'Contacts trouvés sur le lieu {venue} ! Cliquez ici pour assigner les contacts du lieu à cet évènement',
  [LocalizationKey.ComponentsEventCover]: 'Modifier la photo de couverture',
  [LocalizationKey.ComponentsFileCell]: 'Télécharger',
  [LocalizationKey.ComponentsFileShareShare]: 'Partager',
  [LocalizationKey.ComponentsFileSharePublicLinkGenerated]:
    'Lien publique généré et copié dans le presse-papier',
  [LocalizationKey.ComponentsFileSharePublicLinkError]:
    'Erreur lors de la génération du lien publique',
  [LocalizationKey.ComponentsFileSharePublicLinkCopiedToClipboard]:
    'Lien copié dans votre presse-papier',
  [LocalizationKey.ComponentsFileShareCopyPublicLink]:
    'Copier le lien dans le presse-papier',
  [LocalizationKey.ComponentsFileUpload]: 'Téléverser plusieurs fichiers',
  [LocalizationKey.ComponentsFileMaxSize]: 'Taille max. : 15Mo',
  [LocalizationKey.ComponentsInvitationResendEmail]:
    "Renvoyer le mail d'invitation",
  [LocalizationKey.ComponentsPerformanceTableArtist]: 'Artiste',
  [LocalizationKey.ComponentsPerformanceTableSetTime]: 'Heure du set',
  [LocalizationKey.ComponentsPerformanceTableStage]: 'Scène',
  [LocalizationKey.ComponentsPerformanceTableType]: 'Type',
  [LocalizationKey.ComponentsSBTableEmpty]: 'Pas de {entity} trouvé',
  [LocalizationKey.ComponentsSBActivityLogCreated]: 'Créé',
  [LocalizationKey.ComponentsSBActivityLogUpdated]: 'Modifié',
  [LocalizationKey.ComponentsSBActivityLogDeleted]: 'Supprimé',
  [LocalizationKey.ComponentsNewVersionCheckerTitle]: 'New version available',
  [LocalizationKey.ComponentsNewVersionCheckerDescription]:
    'De belles nouveautés sont en route, rafraîchir la page pour y avoir accès',
  [LocalizationKey.ComponentsNewVersionCheckerRefresh]: 'Rafraîchir',
  [LocalizationKey.ComponentsMaintenanceModeTitle]: 'Maintenance en cours',
  [LocalizationKey.ComponentsMaintenanceModeDescription]:
    "Nous sommes entrain d'améliorer votre expérience. Revenez plus tard !",
  [LocalizationKey.ComponentsMaintenanceModeReload]: 'Rafraîchir',
  [LocalizationKey.ComponentsMobileDisclaimerTitle]:
    'Votre écrant est trop petit',
  [LocalizationKey.ComponentsMobileDisclaimerDescription]:
    "Nous vous conseillons d'utiliser un ordinateur pour découvir Alela.",
  [LocalizationKey.ComponentsMobileDisclaimerContinue]: 'Continuer',
  [LocalizationKey.ComponentsGroundCascaderSelectBooking]:
    'Selectionner un booking pour afficher ses ressources',
  [LocalizationKey.ComponentsGroundCascaderItemFrom]: 'Depuis',
  [LocalizationKey.ComponentsGroundCascaderItemTo]: 'Vers',
  [LocalizationKey.ComponentsGroundCascaderItemHotels]: 'Hôtels',
  [LocalizationKey.ComponentsGroundCascaderItemVenues]: 'Lieux',
  [LocalizationKey.ComponentsGroundCascaderItemInbound]: 'Inbound',
  [LocalizationKey.ComponentsGroundCascaderItemOutbound]: 'Outbound',
  [LocalizationKey.ComponentsGroundCascaderItemCustomAddress]:
    'Adresse manuelle',
  [LocalizationKey.ComponentsGroundCascaderItemNewCustomAddress]:
    'Nouvelle adresse manuelle',
  [LocalizationKey.ComponentsInvoiceFiltersLast7Days]: '7 derniers jours',
  [LocalizationKey.ComponentsInvoiceFiltersLast14Days]: '14 derniers jours',
  [LocalizationKey.ComponentsInvoiceFiltersLast30Days]: '30 derniers jours',
  [LocalizationKey.ComponentsInvoiceFiltersLast90Days]: '90 derniers jours',
  [LocalizationKey.ComponentsInvoiceFiltersLast1Year]: '365 derniers jours',
  [LocalizationKey.ComponentsInvoiceFiltersPaid]: 'Payé',
  [LocalizationKey.ComponentsInvoiceFiltersPartiallyPaid]: 'Ouverte',
  [LocalizationKey.ComponentsInvoiceFiltersUnpaid]: 'Non payé',
  [LocalizationKey.ComponentsInvoiceFiltersAll]: 'Tout',
  [LocalizationKey.ComponentsInvoiceFiltersVenue]: 'Lieu',
  [LocalizationKey.ComponentsInvoiceFiltersDueDate]: 'Date dûe',
  [LocalizationKey.ComponentsInvoiceFiltersEventDate]: "Date d'évènement",
  [LocalizationKey.ComponentsInvoiceFiltersSearch]: 'Recherche',
  [LocalizationKey.ComponentsInvoiceFiltersSearchPlaceholder]:
    'Fournisseur, référence, évènement, ...',
  [LocalizationKey.ComponentsInvoiceTableReference]: 'Référence',
  [LocalizationKey.ComponentsInvoiceTableSupplier]: 'Fournisseur',
  [LocalizationKey.ComponentsInvoiceTableAmount]: 'Montant',
  [LocalizationKey.ComponentsInvoiceTableDueDate]: 'Date dûe',
  [LocalizationKey.ComponentsInvoiceTableEvent]: 'Évènement',
  [LocalizationKey.ComponentsInvoiceTableLinkedTo]: 'Associée à',
  [LocalizationKey.ComponentsInvoiceTableAddPayment]: 'Ajouter un paiement',
  [LocalizationKey.ComponentsNotificationDropdownTitle]:
    'Activer les notifications en temps réel',
  [LocalizationKey.ComponentsNotificationDropdownDescription1]:
    'Rester dans la boucle et ne ratez jamais rien !',
  [LocalizationKey.ComponentsNotificationDropdownDescription2]:
    'Activez les notifications pour recevoir les dernières mise à jour et infos de votre équipe',
  [LocalizationKey.ComponentsNotificationDropdownAccept]:
    'Accepter les notifications',
  [LocalizationKey.ComponentsNotificationDropdownError]:
    'Il semble que vous ayez refusé les notifications 🥺 Vous ne recevrez pas les mise à jour de votre équipe en temps réel ... Mais on reste quand même amis, pas vrai ?',
  [LocalizationKey.ComponentsNotificationDropdownEndMesssage]:
    "C'est tout, rien de plus 🤐",
  [LocalizationKey.ComponentsNotificationDropdownRead]: 'Lues',
  [LocalizationKey.ComponentsNotificationMarkAllAsRead]:
    'Tout marquer comme lu',
  [LocalizationKey.ComponentsOrganizationBillingInfosManage]:
    'Modifier les informations de facturation',
  [LocalizationKey.ComponentsOrganizationPlanYourOrganization]:
    'Votre organisation',
  [LocalizationKey.ComponentsOrganizationPlanUsers]: 'Utilisateurs payants',
  [LocalizationKey.ComponentsOrganizationPlanPricePerUser]:
    'Prix par utilisateur HT / mois',
  [LocalizationKey.ComponentsOrganizationPlanTotalPrice]:
    'Prix total HT / mois',
  [LocalizationKey.ComponentsOrganizationPlanToEditGoToPortal]:
    'Pour éditer vos informations de facturation cliquer sur le bouton ci-dessous pour accéder au portal dédié de notre partenaire',
  [LocalizationKey.ComponentsOrganizationPlanMoveTheCursor]:
    'Déplacer le curseur ci-dessus pour estimer vos coûts au fur et à mesure que votre équipe grandira',
  [LocalizationKey.ComponentsOrganizationPlanTrialsEndsOn]: "Période d'essai",
  [LocalizationKey.ComponentsOrganizationPlanTrialsEndsOnDescription]:
    "La période d'essai se termine le",
  [LocalizationKey.ComponentsOrganizationPlanNoDefaultPaymentMethod]:
    'Pas de méthode de paiement par défaut',
  [LocalizationKey.ComponentsOrganizationPlanNoDefaultPaymentMethodDescription]:
    'Sans méthode de paiment par défaut votre compte sera suspendu lors du prochain renouvellement de votre abonnement',
  [LocalizationKey.ComponentsOrganizationPlanReset]: 'Réinitialiser',
  [LocalizationKey.ComponentsOrganizationUsageOCRThisMonth]:
    'Reconnaissance de facture',
  [LocalizationKey.ComponentsOrganizationUsageStorage]: 'Stockage',
  [LocalizationKey.ComponentsOrganizationUsageDescription]:
    '{used} utilisés sur {quota} inclus dans votre abonnement',
  [LocalizationKey.ComponentsOrganizationRolesPermissions]: 'Permissions',
  [LocalizationKey.ComponentsOrganizationRolesMarketing]: 'Marketing',
  [LocalizationKey.ComponentsOrganizationRolesVisitor]: 'Visiteur',
  [LocalizationKey.ComponentsOrganizationRolesProductionManager]:
    'Chargé de production',
  [LocalizationKey.ComponentsOrganizationRolesfinancialManager]:
    'Responsable financier',
  [LocalizationKey.ComponentsOrganizationRolesGeneralManager]:
    'General Manager',
  [LocalizationKey.ComponentsOrganizationRolesAdmin]: 'Admin',
  [LocalizationKey.ComponentsPaymentListAddPayment]: 'Ajouter un paiment',
  [LocalizationKey.ComponentsPaymentListAddFirstPayment]:
    'Ajouter votre premier paiment',
  [LocalizationKey.ComponentsTravelSearcherNoTravelFound]:
    'Pas de voyage trouvé',
  [LocalizationKey.ComponentsTravelSearcherError]:
    'Error lors du chargement des voyages',
  [LocalizationKey.ComponentsTravelSearcherFillBeforeSearching]:
    'Remplir date et référence avant de rechercher',
  [LocalizationKey.ComponentsTravelSearcherCreateManually]:
    'Créer manuellement',
  [LocalizationKey.ComponentsTravelSearcherSearch]: 'Rechercher',
  [LocalizationKey.ComponentsTravelSearcherSelect]: 'Sélectionner',
  [LocalizationKey.ComponentsTravelSearcherChange]: 'Changer les arrêts',
  [LocalizationKey.ComponentsTravelSearcherValidate]: 'Valider les arrêts',
  [LocalizationKey.ComponentsUserAssociateContactDescription]:
    'En association un contact à un utilisateur, il recevra des notifications basées sur les associations de contact. Les préférences de notifications sont disponibles pour chaque utilisateur sur leur',
  [LocalizationKey.ComponentsUserAssociateContactPofileLabel]: 'page de profil',
  [LocalizationKey.ComponentsUserAssociateContactDescription2]:
    "Associer un contact à un utilisateur va remplacer les informations du contact - name, email & téléphone - par les détails de l'utilisateur",
  [LocalizationKey.ComponentsUserAssociateContactSearchPlaceholder]:
    'Rechercher un contact à associer',
  [LocalizationKey.ComponentsUserAssociateContactAssociate]: 'Associer',
  [LocalizationKey.ComponentsUserAvatarDeletedUser]: 'Utilisateur supprimé',
  [LocalizationKey.ComponentsReportingWizardStep1NoItemsDescription]:
    "Avant de créer un rapport d'évènement vous devez configurer vos élèments de rapport dans les réglages",
  [LocalizationKey.ComponentsReportingWizardStep1NoItemsTitle]:
    "Pas encore d'élèments",
  [LocalizationKey.ComponentsReportingWizardStep1NoItemsGoToSettings]:
    'Aller dans les réglages',
  [LocalizationKey.ComponentsReportingWizardStep1Costs]: 'Coûts',
  [LocalizationKey.ComponentsReportingWizardStep1Revenues]: 'Revenus',
  [LocalizationKey.ComponentsReportingWizardError]:
    "Erreur lors de l'enregistrement du rapport",
  [LocalizationKey.ComponentsReportingWizardAtLeast1Filled]:
    'Au moins un élèment de Coûts & revenus doit être rempli',
  [LocalizationKey.ComponentsReportingWizardPreview]: 'Aperçu',
  [LocalizationKey.ComponentsReportingWizardMisc]: 'Divers',
  [LocalizationKey.ComponentsReportingWizardCostsAndRevenues]:
    'Coûts & revenus',
  [LocalizationKey.ComponentsReportingEventAlertDescription]:
    "Garder trace des finances de votre évènement avec le rapport d'évènement",
  [LocalizationKey.ComponentsReportingEventAlertTitle]: "Rapport d'évènement",
  [LocalizationKey.ComponentsReportingEventAlertButton]: 'Ouvrir le rapport',
  [LocalizationKey.ComponentsReportingOverviewMargin]: 'Marge bénéficiaire',
  [LocalizationKey.ComponentsReportingOverviewAttendees]: 'Participants',
  [LocalizationKey.ComponentsReportingOverviewBalance]: 'Balance',
  [LocalizationKey.ComponentsReportingOverviewCosts]: 'Coûts',
  [LocalizationKey.ComponentsReportingOverviewRevenues]: 'Revenus',
  [LocalizationKey.ComponentsReportingChartEvolution]: 'Évolution',
  [LocalizationKey.ComponentsWeatherCardTitle]: 'Rapport météo',
  [LocalizationKey.ComponentsWeatherCardTemp]: 'Temp',
  [LocalizationKey.ComponentsWeatherCardTemperature]: 'Temperature',
  [LocalizationKey.ComponentsWeatherCardHumm]: 'Humm',
  [LocalizationKey.ComponentsWeatherCardWind]: 'Vent',
  [LocalizationKey.ComponentsWeatherCardRain]: 'Pluie',
  [LocalizationKey.ComponentsExternalAdvancingRequestTitle]:
    'Advancing externe demande de {entity}',
  [LocalizationKey.ComponentsExternalAdvancingRequestAccept]: 'Accepter',
  [LocalizationKey.ComponentsExternalAdvancingRequestUpdate]: 'Modifier',
  [LocalizationKey.ComponentsExternalAdvancingRequestDecline]: 'Refuser',

  /**
   * MISC
   */

  [LocalizationKey.MiscButtonUpdate]: 'Enregistrer',

  [LocalizationKey.MiscTabDisplayAll]: 'Tout afficher',

  [LocalizationKey.MiscTableActions]: 'Actions',
  [LocalizationKey.MiscTableName]: 'Nom',
  [LocalizationKey.MiscTableColor]: 'Couleur',
  [LocalizationKey.MiscTableIcon]: 'Icône',
  [LocalizationKey.MiscTableSearchSearch]: 'Search',
  [LocalizationKey.MiscTableSearchReset]: 'Reset',
  [LocalizationKey.MiscTableSearchClose]: 'Close',

  [LocalizationKey.MiscFormApproved]: 'Approuvé',
  [LocalizationKey.MiscFormPending]: 'À valider',
  [LocalizationKey.MiscFormExternalAdvancingStatus]: 'Statut advancing externe',
  [LocalizationKey.MiscFormRole]: 'Rôle',
  [LocalizationKey.MiscFormTitle]: 'Titre',
  [LocalizationKey.MiscFormDescription]: 'Description',
  [LocalizationKey.MiscFormName]: 'Nom',
  [LocalizationKey.MiscFormFirstName]: 'Prénom',
  [LocalizationKey.MiscFormLastName]: 'Nom',
  [LocalizationKey.MiscFormEmail]: 'Email',
  [LocalizationKey.MiscFormPhone]: 'Téléphone',
  [LocalizationKey.MiscFormPassword]: 'Mot de passe',
  [LocalizationKey.MiscFormPasswordConfirmation]:
    'Confirmation du mot de passe',
  [LocalizationKey.MiscFormRememberMe]: 'Se souvenir de moi',
  [LocalizationKey.MiscFormAttachements]: 'Pièces jointes',
  [LocalizationKey.MiscFormAdd]: 'Ajouter',
  [LocalizationKey.MiscFormImport]: 'Importer',
  [LocalizationKey.MiscFormUpdate]: 'Enregistrer',
  [LocalizationKey.MiscFormCancelModifications]: 'Annuler les modifications',
  [LocalizationKey.MiscFormAddress]: 'Adresse',
  [LocalizationKey.MiscFormAddressSearch]: 'Rechercher une adresse ...',
  [LocalizationKey.MiscFormAddressCloseManual]: 'Fermer édition manuelle',
  [LocalizationKey.MiscFormAddressOpenManual]: 'Créer adresse manuellement',
  [LocalizationKey.MiscFormAddressLabel]: 'Label',
  [LocalizationKey.MiscFormAddressStreetNumber]: 'Numéro de voie',
  [LocalizationKey.MiscFormAddressStreet]: 'Rue',
  [LocalizationKey.MiscFormAddressCity]: 'Ville',
  [LocalizationKey.MiscFormAddressPostCode]: 'Code postal',
  [LocalizationKey.MiscFormAddressState]: 'Département',
  [LocalizationKey.MiscFormAddressCountry]: 'Pays',
  [LocalizationKey.MiscFormAddressLatitude]: 'Latitude',
  [LocalizationKey.MiscFormAddressLongitude]: 'Longitude',
  [LocalizationKey.MiscFormCompanyName]: 'Raison sociale',
  [LocalizationKey.MiscFormTaxNumber]: 'Numéro de TVA',
  [LocalizationKey.MiscFormHowToCreate]: 'Comment le créer ?',
  [LocalizationKey.MiscFormAreYouSureToDelete]:
    'Êtes-vous sûr de vouloir supprimer cette resource ?',
  [LocalizationKey.MiscFormBooking]: 'Booking',
  [LocalizationKey.MiscFormEvent]: 'Évènement',
  [LocalizationKey.MiscFormCurrency]: 'Devise',
  [LocalizationKey.MiscFormArtist]: 'Artiste',
  [LocalizationKey.MiscFormType]: 'Type',
  [LocalizationKey.MiscFormPrice]: 'Prix total',
  [LocalizationKey.MiscFormMemoDeal]: 'Memo deal',
  [LocalizationKey.MiscFormActionCannotBeUndone]:
    'Cette action est irréversible !',
  [LocalizationKey.MiscFormBookingResourcesDeleteDescription]:
    "Toutes les ressources associées à ce booking vont aussi être supprimées, incluant les performances, contrats, riders, grounds, chambre d'hôtel, ...",
  [LocalizationKey.MiscFormInputValueToConfirm]: 'Input {value} to confirm',
  [LocalizationKey.MiscFormOwner]: 'Owner',
  [LocalizationKey.MiscFormComment]: 'Commentaire',
  [LocalizationKey.MiscFormCompany]: 'Société',
  [LocalizationKey.MiscFormContact]: 'Contact',
  [LocalizationKey.MiscFormContactAssociationEventTitle]:
    "Également associer le contact aux lieux de l'évènement ?",
  [LocalizationKey.MiscFormContactAssociationEventDescription]:
    "Au cas où plusieurs lieux sont associés à l'évènement, le contact sera attaché à tous les lieux",
  [LocalizationKey.MiscFormContactAssociationBookinTitle]:
    "Également associer le contact à l'artiste ?",
  [LocalizationKey.MiscFormColor]: 'Couleur',
  [LocalizationKey.MiscFormIcon]: 'Icône',
  [LocalizationKey.MiscFormStatus]: 'Statut',
  [LocalizationKey.MiscFormAssignee]: 'Assigné',
  [LocalizationKey.MiscFormNotes]: 'Notes',
  [LocalizationKey.MiscFormReplaceTheFile]: 'Remplacer le fichier',
  [LocalizationKey.MiscFormUploadFileToReplaceCurrent]:
    'Téléverser un fichier pour remplacer le fichier actuel',
  [LocalizationKey.MiscFormVenues]: 'Lieu(x)',
  [LocalizationKey.MiscFormDate]: 'Date',
  [LocalizationKey.MiscFormDates]: 'Dates',
  [LocalizationKey.MiscFormEventUpdateDateChanging]:
    'En changeant la date de début toutes les performances seront décalées à ce nouveau jour',
  [LocalizationKey.MiscFormEventUpdateDateChangingWarning]:
    'Attention: toutes les performances hors de la nouvelle fourchette horaire seront supprimées !',
  [LocalizationKey.MiscFormVisibleBy]: 'Visible par',
  [LocalizationKey.MiscFormIsDefaultStatus]:
    "C'est le statut par défault lors de la création d'évènement ?",
  [LocalizationKey.MiscFormIsDefaultStatusForExternalAdvancing]:
    "C'est le statut par défault lors de l'ajout via l'External Advancing ?",
  [LocalizationKey.MiscFormSelectABooking]: "Sélectionnez d'abord un booking",
  [LocalizationKey.MiscFormPickupTime]: 'Heure de pickup',
  [LocalizationKey.MiscFormDueDate]: 'Date limite',
  [LocalizationKey.MiscFormDuration]: 'Durée',
  [LocalizationKey.MiscFormPassengers]: 'Passagers',
  [LocalizationKey.MiscFormDriver]: 'Conducteur',
  [LocalizationKey.MiscFormVehicleDetails]: 'Détails du véhicule',
  [LocalizationKey.MiscFormStars]: 'Étoiles',
  [LocalizationKey.MiscFormInvoiceHowToCreate]: 'How to create ?',
  [LocalizationKey.MiscFormInvoiceUploadPDF]: 'Upload PDF',
  [LocalizationKey.MiscFormInvoiceManual]: 'Manual',
  [LocalizationKey.MiscFormInvoiceFileSizeWarning]:
    "The file size is larger than 10 MB. Our AI will not be able to extract the invoice's informations automatically.",
  [LocalizationKey.MiscFormInvoiceReference]: 'Reference',
  [LocalizationKey.MiscFormInvoiceAmount]: 'Montant',
  [LocalizationKey.MiscFormInvoiceAmountInclTaxes]: 'Amount including taxes',
  [LocalizationKey.MiscFormInvoiceAmountExclTaxes]: 'Amount excluding taxes',
  [LocalizationKey.MiscFormInvoiceDueDate]: 'Date dûe',
  [LocalizationKey.MiscFormInvoiceSupplier]: 'Fournisseur',
  [LocalizationKey.MiscFormInvoicePayments]: 'Paiments',
  [LocalizationKey.MiscFormInvoiceOptions]: 'Options',
  [LocalizationKey.MiscFormInvoiceCategory]: 'Catégorie',
  [LocalizationKey.MiscFormPaymentCalculatePaidAmount]:
    'Calculer le montant payé',
  [LocalizationKey.MiscFormPaymentOperationType]: "Type d'opération",
  [LocalizationKey.MiscFormPaymentBankTransfer]: 'Virement bancaire',
  [LocalizationKey.MiscFormPaymentCard]: 'Carte',
  [LocalizationKey.MiscFormPaymentCheck]: 'Chèque',
  [LocalizationKey.MiscFormPaymentCash]: 'Espèces',
  [LocalizationKey.MiscFormPaymentOperationDate]: "Date d'opération",
  [LocalizationKey.MiscFormPaymentPOP]: 'PDP - Preuve de paiment',
  [LocalizationKey.MiscFormReportItemCost]: 'Coût',
  [LocalizationKey.MiscFormReportItemRevenue]: 'Revenu',
  [LocalizationKey.MiscFormStage]: 'Scène',
  [LocalizationKey.MiscFormRoomHotel]: 'Hôtel',
  [LocalizationKey.MiscFormRoomReference]: 'Référence',
  [LocalizationKey.MiscFormRoomRoomType]: 'Type de chambre',
  [LocalizationKey.MiscFormRoomTravelers]: 'Voyageurs',
  [LocalizationKey.MiscFormRoomPrice]: 'Prix',
  [LocalizationKey.MiscFormRoomRoomCredits]: 'Room credits',
  [LocalizationKey.MiscFormStationNoStationFound]: 'Pas de station trouvée',
  [LocalizationKey.MiscFormStationError]:
    'Erreur lors de la recherche de station',
  [LocalizationKey.MiscFormStationMinLength]:
    'Entrez au moins 3 caractères pour rechercher',
  [LocalizationKey.MiscFormStationTransportType]: 'Type de station',
  [LocalizationKey.MiscFormStationAirport]: 'Aéroport',
  [LocalizationKey.MiscFormStationTrainStation]: 'Gare',
  [LocalizationKey.MiscFormStationSearch]: 'Rechercher',
  [LocalizationKey.MiscFormStationAdded]: 'Ajouté',
  [LocalizationKey.MiscFormRegistrationNumber]: "Numéro d'identification",
  [LocalizationKey.MiscFormTravelType]: 'Travel type',
  [LocalizationKey.MiscFormTravelInbound]: 'Inbound',
  [LocalizationKey.MiscFormTravelOutbound]: 'Outbound',
  [LocalizationKey.MiscFormTravelTransportType]: 'Type',
  [LocalizationKey.MiscFormTravelPlane]: 'Plane',
  [LocalizationKey.MiscFormTravelTrain]: 'Train',
  [LocalizationKey.MiscFormTravelFlightNumber]: 'Flight number',
  [LocalizationKey.MiscFormTravelTrainNumber]: 'Train number',
  [LocalizationKey.MiscFormTravelDeparture]: 'Departure',
  [LocalizationKey.MiscFormTravelDepartureTime]: 'Departure time',
  [LocalizationKey.MiscFormTravelArrival]: 'Arrival',
  [LocalizationKey.MiscFormTravelArrivalTime]: 'Arrival time',
  [LocalizationKey.MiscFormUserResourcesDeleteDescription]:
    "L'utilisateur va être immédiatement supprimé et n'aura plus accès à cette organisation. Tous les résumés d'activité de l'utilisateur vont être supprimé. Toutes les resources associées à son contact ne seront pas supprimées. Son contact ne sera pas supprimé",
  [LocalizationKey.MiscFormEventColorOnCalendar]:
    "Couleur de l'évènement sur le calendrier",
  [LocalizationKey.MiscFormOpening]: 'Ouverture',
  [LocalizationKey.MiscFormClosing]: 'Fermeture',
  [LocalizationKey.MiscFormClosingIsOnNextDay]: 'La fermeture est le lendemain',
  [LocalizationKey.MiscFormPrevious]: 'Précédent',
  [LocalizationKey.MiscFormNext]: 'Suivant',
  [LocalizationKey.MiscFormUnitOfMeasurement]: 'Unité de mesure',
  [LocalizationKey.MiscFormLocale]: 'Langue',
  [LocalizationKey.MiscFormTimezone]: 'Fuseau horaire',
  [LocalizationKey.MiscFormFirstCalendarDay]: 'Premier jour du calendrier',

  [LocalizationKey.MiscErrorRequire]: 'Le champs est requis',
  [LocalizationKey.MiscErrorTypeString]:
    "L'entrée n'est pas une chaîne de caractères valide",
  [LocalizationKey.MiscErrorTypeNumber]: "L'entrée n'est pas un nombre valide",
  [LocalizationKey.MiscErrorTypeLink]: "L'entrée n'est pas un lien valide",
  [LocalizationKey.MiscErrorTypeEmail]: "L'entrée n'est pas un email valide",
  [LocalizationKey.MiscErrorNotValidPhone]:
    "L'entrée n'est pas un numéro de téléphone valide",
  [LocalizationKey.MiscErrorMinLength]:
    'La valeur doit avoir au moins {length} caractères',
  [LocalizationKey.MiscErrorCaps]:
    'La valeur doit contenir au moins une lettre capitale',
  [LocalizationKey.MiscErrorLowercase]:
    'La valeur doit contenir au moins une lettre minuscule',
  [LocalizationKey.MiscErrorContainsNumber]:
    'La valeur doit contenir au moins un chiffre',
  [LocalizationKey.MiscErrorContainsSymbol]:
    'La valeur doit contenir au moins un symbol',
  [LocalizationKey.MiscErrorMatchValue]:
    'La valeur ne correspond pas à la valeur attendue',

  [LocalizationKey.MiscColorPink]: 'Rose',
  [LocalizationKey.MiscColorRed]: 'Rouge',
  [LocalizationKey.MiscColorYellow]: 'Jaune',
  [LocalizationKey.MiscColorOrange]: 'Orange',
  [LocalizationKey.MiscColorCyan]: 'Cyan',
  [LocalizationKey.MiscColorGreen]: 'Vert',
  [LocalizationKey.MiscColorBlue]: 'Bleu',
  [LocalizationKey.MiscColorPurple]: 'Pourpre',
  [LocalizationKey.MiscColorGeekblue]: 'Bleu geek',
  [LocalizationKey.MiscColorMagenta]: 'Magenta',
  [LocalizationKey.MiscColorVolcano]: 'Volcan',
  [LocalizationKey.MiscColorGold]: 'Or',
  [LocalizationKey.MiscColorLime]: 'Citron',

  [LocalizationKey.EntityArtist]: 'artiste',
  [LocalizationKey.EntityEvent]: 'évènement',
  [LocalizationKey.EntityEventStatus]: "statut d'évènement",
  [LocalizationKey.EntityBooking]: 'booking',
  [LocalizationKey.EntityBookingType]: 'type de booking',
  [LocalizationKey.EntityContract]: 'contrat',
  [LocalizationKey.EntityContractStatus]: 'statut de contrat',
  [LocalizationKey.EntityInvoice]: 'facture',
  [LocalizationKey.EntityPayment]: 'paiement',
  [LocalizationKey.EntityHotel]: 'hôtel',
  [LocalizationKey.EntityRoom]: 'chambre',
  [LocalizationKey.EntityRider]: 'rider',
  [LocalizationKey.EntityRiderStatus]: 'statut de rider',
  [LocalizationKey.EntityRiderType]: 'type de rider',
  [LocalizationKey.EntityFile]: 'fichier',
  [LocalizationKey.EntityUser]: 'utilisateur',
  [LocalizationKey.EntityVenue]: 'lieu',
  [LocalizationKey.EntityVenueType]: 'type de lieux',
  [LocalizationKey.EntityStage]: 'scène',
  [LocalizationKey.EntityContact]: 'contact',
  [LocalizationKey.EntityContactType]: 'type de contact',
  [LocalizationKey.EntityContactAssociation]: 'association de contact',
  [LocalizationKey.EntityTravel]: 'travel',
  [LocalizationKey.EntityTravelType]: 'type de travel',
  [LocalizationKey.EntityGround]: 'ground',
  [LocalizationKey.EntityPerformance]: 'performance',
  [LocalizationKey.EntityPerformanceType]: 'type de performance',
  [LocalizationKey.EntityStation]: 'station',
  [LocalizationKey.EntityStationType]: 'type de station',
  [LocalizationKey.EntityPublicLink]: 'lien publique',
  [LocalizationKey.EntityRole]: 'rôle',
  [LocalizationKey.EntityCustomAddress]: 'customaddress',
  [LocalizationKey.EntitySupplier]: 'fournisseur',
  [LocalizationKey.EntitySupplierType]: 'type de fournisseur',
  [LocalizationKey.EntityInvoiceCategory]: 'catégorie de facture',
  [LocalizationKey.EntityInvitation]: 'invitation',
  [LocalizationKey.EntityNotification]: 'notification',
  [LocalizationKey.EntityReport]: 'rapport',
  [LocalizationKey.EntityReportItem]: 'élément de rapport',
  [LocalizationKey.EntityTask]: 'tâche',
  [LocalizationKey.EntityTaskStatus]: 'statut de tâche',
  [LocalizationKey.EntityExternalAdvancingLink]: "lien d'advance externe",
  [LocalizationKey.EntityCurrency]: 'devise',
  [LocalizationKey.EntityMarketingTask]: 'tâche marketing',
  [LocalizationKey.EntityMarketingTaskStatus]: 'status de tâche marketing',

  /**
   * HELP
   */
  [LocalizationKey.HelpArticleAddAndManageHotelRooms]:
    "Ajouter et gérer les chambres d'hôtel",
  [LocalizationKey.HelpArticleManageEventStatusAndPermissions]:
    "Gérer les statut d'évènement et leur permission",
  [LocalizationKey.HelpArticleAddAndManageGroundTransfers]:
    'Ajouter et gérer les transferts au sol - ground',
  [LocalizationKey.HelpArticleAddAndManageTravels]:
    'Ajouter et gérer les travels',
  [LocalizationKey.HelpArticleCreateAVenue]: 'Ajouter un lieu',
  [LocalizationKey.HelpArticleAddAndManagePartyCrew]:
    'Ajouter et gérer le party crew',
  [LocalizationKey.HelpArticleCreateAndManageYourEvent]:
    'Créer et gérer votre évènement',
  [LocalizationKey.HelpArticleAddAndManageTimetable]:
    'Ajouter et gérer la timetable',
  [LocalizationKey.HelpArticleShareAFile]: 'Partager un fichier',
  [LocalizationKey.HelpArticleAddAndManageTechnicalSheets]:
    'Ajouter et gérer les fiches techniques',
  [LocalizationKey.HelpArticleManageTeamAndPermissions]:
    'Gérer les équipes et les permissions',
  [LocalizationKey.HelpArticleAddAndManageContact]:
    'Ajouter et gérer les contacts',
  [LocalizationKey.HelpArticleAddContractOnEvent]:
    'Ajouter un contrat à un évènement',
  [LocalizationKey.HelpArticleAddAndManageHotels]: 'Ajouter et gérer un hôtel',
  [LocalizationKey.HelpArticleAddAndManageLineUp]:
    'Ajouter et gérer le line-up',
  [LocalizationKey.HelpArticleHowToAddAnInvoice]: 'Comment ajouter une facture',
  [LocalizationKey.HelpArticleHowToMarkAnInvoiceAsPaid]:
    'Comment marquer une facture comme payée',
  [LocalizationKey.HelpArticleHowToGenerateRoadsheet]:
    'Comment générer une feuille de route',

  [LocalizationKey.HelpAlertTitle]: "Besoin d'aide ?",
  [LocalizationKey.HelpAlertDescription]:
    "Lis nos docs pour tout connaître d'Alela !",
  [LocalizationKey.HelpAlertButton]: 'Open doc',
  [LocalizationKey.HelpSupportAlertMessage]: "Besoin d'aide ?",
  [LocalizationKey.HelpSupportAlertDescription]:
    'Notre équipe du support est là pour vous aider',
  [LocalizationKey.HelpSupportAlertButton]: 'Contacter le support',

  [LocalizationKey.MiscDaysMonday]: 'Lundi',
  [LocalizationKey.MiscDaysTuesday]: 'Mardi',
  [LocalizationKey.MiscDaysWednesday]: 'Mercredi',
  [LocalizationKey.MiscDaysThursday]: 'Jeudi',
  [LocalizationKey.MiscDaysFriday]: 'Vendredi',
  [LocalizationKey.MiscDaysSaturday]: 'Samedi',
  [LocalizationKey.MiscDaysSunday]: 'Dimanche',
}

export default FR

import { useIntl } from 'react-intl'
import { useEffect, useRef, useState } from 'react'
import { Card, InputRef, Tag } from 'antd'
import SBTable from '../../components/SBTable'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { Actions } from '../../modules/sbrm/components/Actions'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import getColumnSearchProps from '../../utils/table/getColumnSearchProps'
import { extractSortDataFromSorterResult } from '../../utils/table/sorter'
import { RiderType as RiderTypeModel } from '../../models/Rider'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import {
  getRiderTypes,
  selectRiderTypes,
  setRiderTypeQuery,
} from '../../reducers/RiderTypeReducer'

const RiderTypePage = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const searchInput = useRef<InputRef>(null)
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const types = useAppSelector(selectRiderTypes())
  const { query, isLoading } = useAppSelector((state) => state.riderType)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const handleSearch = (
    selectedKeys: string[],
    dataIndex: keyof RiderTypeModel
  ) => {
    const newQuery = {
      ...query,
      ...{ search: { [dataIndex]: selectedKeys[0] } },
    }
    dispatch(setRiderTypeQuery(newQuery))
    dispatch(getRiderTypes(newQuery))
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    const newQuery = {
      ...query,
      ...{ search: { dataIndex: null } },
    }
    dispatch(setRiderTypeQuery(newQuery))
    dispatch(getRiderTypes(newQuery))
  }

  const columns: ColumnsType<RiderTypeModel> = [
    {
      key: 'name',
      title: intl.formatMessage({ id: LocalizationKeys.Misc.Table.Name }),
      ...getColumnSearchProps<RiderTypeModel>(
        'name',
        searchInput,
        handleSearch,
        handleReset,
        intl,
        isLoading
      ),
      render: (type: RiderTypeModel) => <>{type.name}</>,
    },
    {
      key: 'color',
      title: intl.formatMessage({ id: LocalizationKeys.Misc.Table.Color }),
      render: (type: RiderTypeModel) => (
        <Tag color={type.color}>{type.color}</Tag>
      ),
    },
    {
      key: 'icon',
      title: intl.formatMessage({ id: LocalizationKeys.Misc.Table.Icon }),
      render: (type: RiderTypeModel) => <Tag>{type.icon}</Tag>,
    },
    {
      key: 'actions',
      align: 'right',
      render: (type: RiderTypeModel) => (
        <Actions
          actions={['update', 'delete']}
          entity={SBRMType.riderType}
          entityId={type.id}
        />
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<RiderTypeModel> | SorterResult<RiderTypeModel>[]
  ) => {
    const newQuery = {
      ...query,
      pagination,
      filters,
      ...extractSortDataFromSorterResult(sorter),
    }
    dispatch(setRiderTypeQuery(newQuery))
    dispatch(getRiderTypes(newQuery))
  }

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      return
    }
    setIsFirstRender(false)

    // Trigger when SBRM is closed and on init
    dispatch(getRiderTypes(query))
  }, [dispatch, SBRMIsOpen])

  return (
    <Card
      bordered={false}
      styles={{ body: { padding: 0 } }}
      title={
        <PageTitle
          title={intl.formatMessage({
            id: LocalizationKeys.Page.Settings.RiderType,
          })}
          level={5}
          toolbar={<Actions actions={['create']} entity={SBRMType.riderType} />}
        />
      }
    >
      <SBTable
        entity={SBRMType.riderType}
        scroll={{ x: 600 }}
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={types}
        pagination={query.pagination}
        loading={isLoading}
        onChange={handleTableChange}
      />
    </Card>
  )
}

export default RiderTypePage

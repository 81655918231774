import dayjs from 'dayjs'
import { useIntl } from 'react-intl'
import { Form, FormInstance, Input, DatePicker } from 'antd'
import { requiredRule } from '../../../utils/rules'
import { useAppSelector } from '../../../reducers/hooks'
import { useEffect } from 'react'
import { SBAsyncSelect } from '../../../components/custom-inputs/sb-async-select/SBAsyncSelect'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import { selectSelectedPerformance } from '../../../reducers/PerformanceReducer'
import { MetaDataKey } from '../../../models/MetaData'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { Actions } from '../../../modules/sbrm/components/Actions'

const { RangePicker } = DatePicker

interface Props {
  form: FormInstance
  venuesId: number
}

const Update = ({ form, venuesId }: Props) => {
  const intl = useIntl()

  const performance = useAppSelector(selectSelectedPerformance())

  useEffect(() => {
    form.setFieldsValue(performance)
    form.setFieldValue('dates', [
      performance?.start ? dayjs(performance?.start) : '',
      performance?.end ? dayjs(performance?.end) : '',
    ])
  }, [performance])

  if (performance === undefined) {
    return <></>
  }

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="id"
        required
        rules={[requiredRule(intl)]}
        style={{ display: 'none' }}
      >
        <Input type="hidden" />
      </Form.Item>
      <SBAsyncSelect
        type={SBRMType.stage}
        name="stage"
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Stage })}
        rules={[requiredRule(intl)]}
        metadata={[{ key: MetaDataKey.venuesId, value: venuesId }]}
      />
      <Form.Item
        name="dates"
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Dates })}
        rules={[requiredRule(intl)]}
      >
        <RangePicker
          className="w-full"
          showTime={{ format: 'HH:mm', minuteStep: 15 }}
          format="DD/MM/YYYY HH:mm"
        />
      </Form.Item>
      <SBAsyncSelect
        type={SBRMType.performanceType}
        name="type"
        allowClear
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Type })}
        rules={[]}
        metadata={[]}
      />
      <Actions
        actions={['delete']}
        entity={SBRMType.performance}
        entityId={performance?.id}
      />
    </Form>
  )
}

export default Update

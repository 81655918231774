import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIUpdate,
} from '../utils/helpers/SBAPIHelper'
import { SUPPLIERS_URL } from '../utils/urls'
import { Supplier } from '../models/Supplier'
import { supplierSchema } from '../models/schema'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'

import { initialQuery } from '../utils/helpers/crud/models'

const initialState: SBState<Supplier> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: initialQuery,
}

const slice = createSlice({
  name: 'supplier',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getSuppliersSuccess,
  setQuery: setSupplierQuery,
  reset: resetSupplierState,
  resetQueryAndIds: resetSupplierQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: Supplier }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectSuppliers = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )

export const selectSelectedSupplier = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectSupplierById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectSuppliersByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedSupplier =
  (supplier: Supplier | number) => async (dispatch: AppDispatch) =>
    dispatch(slice.actions.setSelectedId(getIdOrModelId<Supplier>(supplier)))

export const getSuppliers = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<Supplier>(
    SUPPLIERS_URL,
    params,
    [supplierSchema],
    slice.actions
  )

export const getSupplierWithId = (id: number) =>
  SBAPIFetchDispatch<Supplier>(
    `${SUPPLIERS_URL}/${id}`,
    supplierSchema,
    slice.actions
  )

export const createSupplier = (supplier: Supplier) =>
  SBAPICreate<Supplier>(supplier, SUPPLIERS_URL, supplierSchema, slice.actions)

export const updateSupplier = (supplier: Supplier) =>
  SBAPIUpdate<Supplier>(
    supplier,
    `${SUPPLIERS_URL}/${supplier.id}`,
    slice.actions
  )

export const deleteSupplier = (supplier: Supplier) =>
  SBAPIDelete<Supplier>(
    supplier,
    `${SUPPLIERS_URL}/${supplier.id}`,
    slice.actions
  )

import { FC } from 'react'

import { ContactTypeForm, ContactTypeFormType } from './form/Form'
import {
  ContactListCheckboxes,
  ContactListCheckboxesType,
} from './ListCheckboxes'

export const ContactType: FC &
  ContactTypeFormType &
  ContactListCheckboxesType = (): JSX.Element => <></>

ContactType.Form = ContactTypeForm
ContactType.ListCheckboxes = ContactListCheckboxes

import { FC, useLayoutEffect } from 'react'
import App from '../App'
import { AuthLayout } from '../layouts/AuthLayout'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { LayoutGeneral } from '../layouts/LayoutGeneral'
import LoginPage from '../pages/auth/LoginPage'
import ArtistsPage from '../pages/artist/ArtistsPage'
import ResetPasswordPage from '../pages/auth/ResetPasswordPage'
import ForgotPasswordPage from '../pages/auth/ForgotPasswordPage'
import EventDetailsRoutes from './app/events/EventDetailsRoutes'
import CalendarPage from '../pages/CalendarPage'
import SettingsRoutes from './app/settings/SettingsRoutes'
import ArtistPage from '../pages/artist/ArtistPage'
import ReleaseNotesPage from '../pages/misc/ReleaseNotesPage'
import ProfileRoutes from './app/ProfileRoutes'
import LayoutLogged from '../layouts/LayoutLogged'
import LoginRedirect from '../pages/auth/LoginRedirect'
import CRMRoutes from './app/CRM/CRMRoutes'
import InvitationPage from '../pages/auth/InvitationPage'
import RegisterPage from '../pages/auth/RegisterPage'
import FinancialRoutes from './app/Financial/FinancialRoutes'
import LayoutAlelaMainAppInit from '../layouts/auth/LayoutAlelaMainAppInit'
import MarketingPage from '../pages/MarketingPage'

const AppRoutes: FC = () => {
  const location = useLocation()

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <Routes>
      <Route element={<App />}>
        {/* MAIN APP */}
        <Route element={<LayoutAlelaMainAppInit />}>
          <Route element={<LayoutLogged />}>
            <Route element={<LayoutGeneral />}>
              <Route path="/events" element={<CalendarPage />} />
              <Route path="/marketing" element={<MarketingPage />} />

              <Route path="/artists" element={<ArtistsPage />} />
              <Route path="/artists/:artistId" element={<ArtistPage />} />
              <Route path="/release-notes" element={<ReleaseNotesPage />} />
            </Route>
            <Route path="/events/:eventId/*" element={<EventDetailsRoutes />} />
            <Route path="/financials/*" element={<FinancialRoutes />} />
            <Route path="/crm/*" element={<CRMRoutes />} />
            <Route path="/settings/*" element={<SettingsRoutes />} />
            <Route path="/account/*" element={<ProfileRoutes />} />
          </Route>

          <Route path="*" element={<Navigate to="/" />} />
          <Route index element={<Navigate to="/events" />} />

          <Route path="/auth" element={<AuthLayout />}>
            <Route path="login" element={<LoginPage />} />
            <Route path="login-redirect" element={<LoginRedirect />} />
            <Route path="forgot-password" element={<ForgotPasswordPage />} />
            <Route path="reset-password" element={<ResetPasswordPage />} />

            <Route path="register" element={<RegisterPage />} />

            <Route
              path="invitation/:invitationId"
              element={<InvitationPage />}
            />

            <Route index path="*" element={<Navigate to="/auth/login" />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  )
}

export { AppRoutes }

import Create from './Create'
import Update from './Update'
import Delete from './Delete'
import { Alert, Form } from 'antd'
import { forwardRef, Ref, useImperativeHandle } from 'react'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import {
  createInvoice,
  deleteInvoice,
  updateInvoiceFormData,
} from '../../../reducers/InvoiceReducer'
import { MetaDataKey } from '../../../models/MetaData'
import { useSBRMData } from '../../../modules/sbrm/hooks'
import {
  getMetadata,
  SBRMFormInterface,
  SBRMFormProps,
} from '../../../modules/sbrm/SBRMModel'

export const InvoiceForm = forwardRef(
  ({ isNested }: SBRMFormProps, ref: Ref<SBRMFormInterface>) => {
    useImperativeHandle(ref, () => ({
      handleCreate,
      handleUpdate,
      handleDelete,
      handleReset,
    }))

    const dispatch = useAppDispatch()
    const { action, metadata } = useSBRMData(isNested)
    const { error } = useAppSelector((state) => state.invoice)

    const [createForm] = Form.useForm()
    const [updateForm] = Form.useForm()
    const [deleteForm] = Form.useForm()

    const handleCreate = async () =>
      await createForm.validateFields().then(async (result) => {
        const data = new FormData()

        data.append('event', createForm.getFieldValue('event'))

        if (createForm.getFieldValue('method') === 0) {
          // Upload PDF
          data.append(
            'file',
            createForm.getFieldValue('file').fileList[0].originFileObj
          )
        } else {
          // Manuel creation
          data.append('reference', createForm.getFieldValue('reference'))
          data.append('currency', createForm.getFieldValue('currency'))
          data.append(
            'amountIncludingTaxes',
            createForm.getFieldValue('amountIncludingTaxes')
          )
          data.append(
            'amountExcludingTaxes',
            createForm.getFieldValue('amountExcludingTaxes')
          )
          if (createForm.getFieldValue('description')) {
            data.append('description', createForm.getFieldValue('description'))
          }
        }

        return dispatch(createInvoice(data)).then(() => {
          createForm.resetFields()
          return true
        })
      })

    const handleUpdate = async () =>
      await updateForm.validateFields().then(async (result) => {
        const data = new FormData()
        data.append('_method', 'PUT')
        const fields = [
          'id',
          'reference',
          'amountIncludingTaxes',
          'amountExcludingTaxes',
          'currency',
          'description',
          'supplier',
          'category',
          'notes',
          'isPaid',
          'isApproved',
        ].map((field) =>
          data.append(field, updateForm.getFieldValue(field) ?? '')
        )

        data.append(
          'dueDate',
          updateForm.getFieldValue('dueDate')
            ? updateForm.getFieldValue('dueDate').format('YYYY-MM-DD')
            : ''
        )

        if (updateForm.getFieldValue('booking')) {
          data.append('booking', updateForm.getFieldValue('booking'))
        }

        // Files to upload
        if (updateForm.getFieldValue('files').fileList) {
          updateForm
            .getFieldValue('files')
            .fileList.map((file: any) =>
              data.append('uploads[]', file.originFileObj)
            )
        }

        // Add file ids to delete
        if (updateForm.getFieldValue('filesToDelete')) {
          Object.values(updateForm.getFieldValue('filesToDelete')).map(
            (id: any) => data.append('filesToDelete[]', id)
          )
        }

        return dispatch(updateInvoiceFormData(data)).then(() => {
          updateForm.resetFields()
          return true
        })
      })

    const handleDelete = async () =>
      await deleteForm.validateFields().then(async (result) =>
        dispatch(deleteInvoice(deleteForm.getFieldsValue())).then(() => {
          deleteForm.resetFields()
          return true
        })
      )

    const handleReset = () => {
      createForm.resetFields()
      updateForm.resetFields()
      deleteForm.resetFields()
    }

    return (
      <>
        {action === 'create' && (
          <Create
            form={createForm}
            isNested={isNested}
            eventId={getMetadata(metadata, MetaDataKey.eventId)}
          />
        )}
        {action === 'update' && (
          <Update form={updateForm} isNested={isNested} />
        )}
        {action === 'delete' && <Delete form={deleteForm} />}
        {error && <Alert message={String(error)} type="error" />}
      </>
    )
  }
)

InvoiceForm.displayName = 'InvoiceForm'

export type InvoiceFormType = { Form: typeof InvoiceForm }

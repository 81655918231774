import { Space } from 'antd'
import { useEffect, useState } from 'react'
import { BellOutlined } from '@ant-design/icons'
import { useAppSelector } from '../../reducers/hooks'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { SBRMTypeInfos } from '../../modules/sbrm/SBRMTypeInfos'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import axios from 'axios'
import qs from 'qs'
import { COMMENTS_SUBSCRIPTION_URL } from '../../utils/urls'

interface Props {
  entity: SBRMType
}

export const CommentSubscribeToConversation = ({ entity }: Props) => {
  const intl = useIntl()
  const resourceId = useAppSelector(
    (state) => state[SBRMTypeInfos[entity].reducerName].selectedId
  )

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false)

  const handleSubscribeToConversation = () => {
    setIsLoading(true)
    axios
      .post(COMMENTS_SUBSCRIPTION_URL, { entity: entity, entityId: resourceId })
      .then((response) => {
        setIsSubscribed(response.data.data.isSubscribed)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    setIsLoading(true)
    axios
      .get(
        COMMENTS_SUBSCRIPTION_URL +
          '?' +
          qs.stringify({ entity: entity, entityId: resourceId })
      )
      .then((response) => {
        setIsSubscribed(response.data.data.isSubscribed)
        setIsLoading(false)
      })
  }, [entity])

  return (
    <Space onClick={handleSubscribeToConversation}>
      <BellOutlined />
      {intl.formatMessage({
        id: isSubscribed
          ? LocalizationKeys.Components.Comment.SubscribeToConversation
              .Unsubscribe
          : LocalizationKeys.Components.Comment.SubscribeToConversation
              .Subscribe,
      })}
    </Space>
  )
}

export type CommentSubscribeToConversationType = {
  SubscribeToConversation: typeof CommentSubscribeToConversation
}

import {
  App,
  Avatar,
  Button,
  Card,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd'
import {
  ControlOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
} from '@ant-design/icons'
import dayjs from 'dayjs'
import { useState } from 'react'
import { externalAdvancingSections } from '../types'
import { ExternalAdvancingLink } from '../../../models/ExternalAdvancing'
import ALMResend from './ALMResend'
import { Actions } from '../../sbrm/components/Actions'
import { SBRMType } from '../../sbrm/SBRMModel'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

const { Text } = Typography

interface Props {
  shouldUpdateId: (linkId: string) => void
  shouldDeleteId: (linkId: string) => void
  link: ExternalAdvancingLink
}

const ALMLCell = ({ shouldUpdateId, shouldDeleteId, link }: Props) => {
  const intl = useIntl()
  const { message } = App.useApp()

  const [displayPermission, setDisplayPermission] = useState(false)

  const copyLinkToClipboard = async (link: string) => {
    await navigator.clipboard.writeText(link)
    message.open({
      type: 'success',
      content: 'Copied!',
    })
  }

  return (
    <Card key={link.id} size="small">
      <Space direction="vertical" className="w-full">
        <Space
          direction="horizontal"
          className="w-full"
          style={{ justifyContent: 'space-between' }}
        >
          <Space>
            <Avatar>
              <div style={{ textTransform: 'uppercase' }}>
                {(link.user.name ?? link.user.email).slice(0, 1)}
              </div>
            </Avatar>
            <Space.Compact direction="vertical">
              <Text>
                {link.user.name ?? (
                  <Tag color="orange">
                    {intl.formatMessage({
                      id: LocalizationKeys.ExternalAdvancingManager.Cell
                        .Pending,
                    })}
                  </Tag>
                )}
              </Text>
              <Text type="secondary">{link.user.email}</Text>
            </Space.Compact>
            <Actions
              actions={['comment']}
              entity={SBRMType.externalAdvancingLink}
              entityId={link.id}
            />
          </Space>
          <Space.Compact direction="vertical" style={{ alignItems: 'end' }}>
            <Space size={1}>
              <ALMResend link={link} />
              <Tooltip
                title={intl.formatMessage({
                  id: LocalizationKeys.ExternalAdvancingManager.Cell
                    .DisplayPermissions,
                })}
              >
                <Button
                  size="small"
                  type="text"
                  icon={<ControlOutlined />}
                  onClick={async () => setDisplayPermission(!displayPermission)}
                />
              </Tooltip>
              <Tooltip
                title={intl.formatMessage({
                  id: LocalizationKeys.ExternalAdvancingManager.Cell.Copy,
                })}
              >
                <Button
                  size="small"
                  type="text"
                  icon={<CopyOutlined />}
                  onClick={async () => copyLinkToClipboard(link.url)}
                />
              </Tooltip>
              <Tooltip
                title={intl.formatMessage({
                  id: LocalizationKeys.SBRM.Update,
                })}
              >
                <Button
                  size="small"
                  type="text"
                  icon={<EditOutlined />}
                  onClick={() => shouldUpdateId(link.id)}
                />
              </Tooltip>
              <Tooltip
                title={intl.formatMessage({
                  id: LocalizationKeys.SBRM.Delete,
                })}
              >
                <Button
                  size="small"
                  type="text"
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => shouldDeleteId(link.id)}
                />
              </Tooltip>
            </Space>
            <Text type="secondary" style={{ marginRight: 6 }}>
              {intl.formatMessage(
                {
                  id: LocalizationKeys.ExternalAdvancingManager.Cell.Expires,
                },
                { date: dayjs(link.deadline).format('YYYY-MM-DD') }
              )}
            </Text>
          </Space.Compact>
        </Space>
        {displayPermission && (
          <Space wrap>
            {externalAdvancingSections.map(
              (section) =>
                link[section.propertyName]?.toString() !== 'hidden' && (
                  <Tooltip
                    key={section.key}
                    title={intl.formatMessage(
                      {
                        id: LocalizationKeys.ExternalAdvancingManager.Cell.Can,
                      },
                      {
                        permission: link[section.propertyName]
                          ?.toString()
                          .toLowerCase(),
                        entity: intl
                          .formatMessage({
                            id: LocalizationKeys.ExternalAdvancing[section.key],
                          })
                          ?.toString()
                          .toLowerCase(),
                        quantity:
                          section.maxValuePropertyName !== undefined &&
                          link[section.propertyName]?.toString() === 'edit'
                            ? link[section.maxValuePropertyName]
                              ? `(max ${link[section.maxValuePropertyName]})`
                              : '∞'
                            : '',
                      }
                    )}
                  >
                    <Tag
                      icon={
                        link[section.propertyName]?.toString() === 'view' ? (
                          <EyeOutlined />
                        ) : (
                          <EditOutlined />
                        )
                      }
                    >
                      {intl.formatMessage({
                        id: LocalizationKeys.ExternalAdvancing[section.key],
                      })}
                    </Tag>
                  </Tooltip>
                )
            )}
          </Space>
        )}
      </Space>
    </Card>
  )
}

export default ALMLCell

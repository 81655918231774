import { SBRMAction, SBRMType } from '../modules/sbrm/SBRMModel'

export enum AlelaPermission {
  // Artist
  listArtist = 'list artist',
  createArtist = 'create artist',
  viewArtist = 'view artist',
  editArtist = 'edit artist',
  deleteArtist = 'delete artist',

  // Venue
  listVenue = 'list venue',
  createVenue = 'create venue',
  viewVenue = 'view venue',
  editVenue = 'edit venue',
  deleteVenue = 'delete venue',

  // Venue Type
  listVenueType = 'list venue type',
  createVenueType = 'create venue type',
  viewVenueType = 'view venue type',
  editVenueType = 'edit venue type',
  deleteVenueType = 'delete venue type',

  // Stage
  listStage = 'list stage',
  createStage = 'create stage',
  viewStage = 'view stage',
  editStage = 'edit stage',
  deleteStage = 'delete stage',

  // Event
  listEvent = 'list event',
  createEvent = 'create event',
  viewEvent = 'view event',
  editEvent = 'edit event',
  deleteEvent = 'delete event',

  // Event status
  listEventStatus = 'list event status',
  createEventStatus = 'create event status',
  viewEventStatus = 'view event status',
  editEventStatus = 'edit event status',
  deleteEventStatus = 'delete event status',

  // Booking
  listBooking = 'list booking',
  createBooking = 'create booking',
  viewBooking = 'view booking',
  editBooking = 'edit booking',
  deleteBooking = 'delete booking',
  commentBooking = 'comment booking',
  viewPriceBooking = 'view price booking',
  viewMemoDealBooking = 'view memo deal booking',

  // Booking Type
  listBookingType = 'list booking type',
  createBookingType = 'create booking type',
  viewBookingType = 'view booking type',
  editBookingType = 'edit booking type',
  deleteBookingType = 'delete booking type',

  // Contract
  listContract = 'list contract',
  createContract = 'create contract',
  viewContract = 'view contract',
  editContract = 'edit contract',
  deleteContract = 'delete contract',
  commentContract = 'comment contract',

  // Contract Status
  listContractStatus = 'list contract status',
  createContractStatus = 'create contract status',
  viewContractStatus = 'view contract status',
  editContractStatus = 'edit contract status',
  deleteContractStatus = 'delete contract status',

  // Hotel
  listHotel = 'list hotel',
  createHotel = 'create hotel',
  viewHotel = 'view hotel',
  editHotel = 'edit hotel',
  deleteHotel = 'delete hotel',

  // Room
  listRoom = 'list room',
  createRoom = 'create room',
  viewRoom = 'view room',
  editRoom = 'edit room',
  deleteRoom = 'delete room',

  // Run
  listRun = 'list run',
  createRun = 'create run',
  viewRun = 'view run',
  editRun = 'edit run',
  deleteRun = 'delete run',

  // Rider
  listRider = 'list rider',
  createRider = 'create rider',
  viewRider = 'view rider',
  editRider = 'edit rider',
  deleteRider = 'delete rider',
  commentRider = 'comment rider',

  // Rider Status
  listRiderStatus = 'list rider status',
  createRiderStatus = 'create rider status',
  viewRiderStatus = 'view rider status',
  editRiderStatus = 'edit rider status',
  deleteRiderStatus = 'delete rider status',

  // Rider Type
  listRiderType = 'list rider type',
  createRiderType = 'create rider type',
  viewRiderType = 'view rider type',
  editRiderType = 'edit rider type',
  deleteRiderType = 'delete rider type',

  // File
  listFile = 'list file',
  createFile = 'create file',
  viewFile = 'view file',
  editFile = 'edit file',
  deleteFile = 'delete file',
  attachFileToArtist = 'attach file to artist',
  attachFileToEvent = 'attach file to event',
  attachFileToVenue = 'attach file to venue',

  // User
  listUser = 'list user',
  createUser = 'create user',
  viewUser = 'view user',
  editUser = 'edit user',
  deleteUser = 'delete user',

  // Contact
  listContact = 'list contact',
  createContact = 'create contact',
  viewContact = 'view contact',
  editContact = 'edit contact',
  deleteContact = 'delete contact',
  associateContactToBooking = 'associate contact to booking',
  associateContactToEvent = 'associate contact to event',
  associateContactToVenue = 'associate contact to venue',
  associateContactToArtist = 'associate contact to artist',
  associateContactToSupplier = 'associate contact to supplier',
  dissociateContactToBooking = 'dissociate contact to booking',
  dissociateContactToEvent = 'dissociate contact to event',
  dissociateContactToVenue = 'dissociate contact to venue',
  dissociateContactToArtist = 'dissociate contact to artist',
  dissociateContactToSupplier = 'dissociate contact to supplier',

  // Contact type
  listContactType = 'list contact type',
  createContactType = 'create contact type',
  viewContactType = 'view contact type',
  editContactType = 'edit contact type',
  deleteContactType = 'delete contact type',

  // Contact association
  listContactAssociation = 'list contact association',
  createContactAssociation = 'create contact association',
  viewContactAssociation = 'view contact association',
  editContactAssociation = 'edit contact association',
  deleteContactAssociation = 'delete contact association',

  // Travel
  listTravel = 'list travel',
  createTravel = 'create travel',
  viewTravel = 'view travel',
  editTravel = 'edit travel',
  deleteTravel = 'delete travel',

  // Travel Type
  listTravelType = 'list travel type',
  createTravelType = 'create travel type',
  viewTravelType = 'view travel type',
  editTravelType = 'edit travel type',
  deleteTravelType = 'delete travel type',

  // Ground
  listGround = 'list ground',
  createGround = 'create ground',
  viewGround = 'view ground',
  editGround = 'edit ground',
  deleteGround = 'delete ground',

  // Performance
  listPerformance = 'list performance',
  createPerformance = 'create performance',
  viewPerformance = 'view performance',
  editPerformance = 'edit performance',
  deletePerformance = 'delete performance',

  // Performance type
  listPerformanceType = 'list performance type',
  createPerformanceType = 'create performance type',
  viewPerformanceType = 'view performance type',
  editPerformanceType = 'edit performance type',
  deletePerformanceType = 'delete performance type',

  // Station
  listStation = 'list station',
  createStation = 'create station',
  viewStation = 'view station',
  editStation = 'edit station',
  deleteStation = 'delete station',

  // Station Type
  listStationType = 'list station type',
  createStationType = 'create station type',
  viewStationType = 'view station type',
  editStationType = 'edit station type',
  deleteStationType = 'delete station type',

  // Public link
  listPublicLink = 'list public link',
  createPublicLink = 'create public link',
  viewPublicLink = 'view public link',
  editPublicLink = 'edit public link',
  deletePublicLink = 'delete public link',

  // Invoice
  listInvoice = 'list invoice',
  createInvoice = 'create invoice',
  viewInvoice = 'view invoice',
  editInvoice = 'edit invoice',
  deleteInvoice = 'delete invoice',
  commentInvoice = 'comment invoice',

  // Invoice Category
  listInvoiceCategory = 'list invoice category',
  createInvoiceCategory = 'create invoice category',
  viewInvoiceCategory = 'view invoice category',
  editInvoiceCategory = 'edit invoice category',
  deleteInvoiceCategory = 'delete invoice category',

  // Payment
  listPayment = 'list payment',
  createPayment = 'create payment',
  viewPayment = 'view payment',
  editPayment = 'edit payment',
  deletePayment = 'delete payment',

  // Supplier
  listSupplier = 'list supplier',
  createSupplier = 'create supplier',
  viewSupplier = 'view supplier',
  editSupplier = 'edit supplier',
  deleteSupplier = 'delete supplier',

  // Supplier Type
  listSupplierType = 'list supplier type',
  createSupplierType = 'create supplier type',
  viewSupplierType = 'view supplier type',
  editSupplierType = 'edit supplier type',
  deleteSupplierType = 'delete supplier type',

  // Invitations
  listInvitation = 'list invitation',
  createInvitation = 'create invitation',
  viewInvitation = 'view invitation',
  editInvitation = 'edit invitation',
  deleteInvitation = 'delete invitation',

  // Report
  listReport = 'list report',
  createReport = 'create report',
  viewReport = 'view report',
  editReport = 'edit report',
  deleteReport = 'delete report',

  // Report item
  listReportItem = 'list report item',
  createReportItem = 'create report item',
  viewReportItem = 'view report item',
  editReportItem = 'edit report item',
  deleteReportItem = 'delete report item',

  // Task
  listTask = 'list task',
  createTask = 'create task',
  viewTask = 'view task',
  editTask = 'edit task',
  deleteTask = 'delete task',

  // Task status
  listTaskStatus = 'list task status',
  createTaskStatus = 'create task status',
  viewTaskStatus = 'view task status',
  editTaskStatus = 'edit task status',
  deleteTaskStatus = 'delete task status',

  // External advancing link
  listExternalAdvancingLink = 'list external advancing link',
  createExternalAdvancingLink = 'create external advancing link',
  viewExternalAdvancingLink = 'view external advancing link',
  editExternalAdvancingLink = 'edit external advancing link',
  deleteExternalAdvancingLink = 'delete external advancing link',
  commentExternalAdvancingLink = 'comment external advancing link',

  // Role
  listRole = 'list role',
  createRole = 'create role',
  viewRole = 'view role',
  editRole = 'edit role',
  deleteRole = 'delete role',

  // Marketing task
  listMarketingTask = 'list marketing task',
  createMarketingTask = 'create marketing task',
  viewMarketingTask = 'view marketing task',
  editMarketingTask = 'edit marketing task',
  deleteMarketingTask = 'delete marketing task',

  // Marketing task status
  listMarketingTaskStatus = 'list marketing task status',
  createMarketingTaskStatus = 'create marketing task status',
  viewMarketingTaskStatus = 'view marketing task status',
  editMarketingTaskStatus = 'edit marketing task status',
  deleteMarketingTaskStatus = 'delete marketing task status',

  // Others
  importArtistContactsToBooking = 'import artist contacts to booking',
  importVenueContactsToEvent = 'import venue contacts to event',
  downloadRoadsheet = 'download roadsheet',
}

export function enumFromStringValue<T>(
  enm: { [s: string]: T },
  value: string
): T | undefined {
  return (Object.values(enm) as unknown as string[]).includes(value)
    ? (value as unknown as T)
    : undefined
}

export function permissionFromEntityAndAction(
  entity: SBRMType,
  action: SBRMAction
): AlelaPermission {
  // Default way to compute AlelaPermission
  const verb = (() => {
    switch (action) {
      case 'update':
        return 'edit'
      case 'duplicate':
        return 'create'
      default:
        return action
    }
  })()

  return enumFromStringValue(AlelaPermission, verb + ' ' + entity)!
}

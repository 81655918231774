import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIUpdate,
} from '../utils/helpers/SBAPIHelper'
import { InvoiceCategory } from '../models/InvoiceCategory'
import { INVOICE_CATEGORIES_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { invoiceCategorySchema } from '../models/schema'
import { initialQuery } from '../utils/helpers/crud/models'

const initialState: SBState<InvoiceCategory> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: initialQuery,
}

const slice = createSlice({
  name: 'invoiceCategory',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getInvoiceCategoriesSuccess,
  setQuery: setInvoiceCategoryQuery,
  reset: resetInvoiceCategoryState,
  resetQueryAndIds: resetInvoiceCategoryQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: InvoiceCategory }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectInvoiceCategories = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedInvoiceCategory = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectInvoiceCategoryById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectInvoiceCategoriesByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedInvoiceCategory =
  (invoice: InvoiceCategory | number | undefined) =>
  async (dispatch: AppDispatch) =>
    dispatch(
      slice.actions.setSelectedId(
        invoice ? getIdOrModelId<InvoiceCategory>(invoice) : undefined
      )
    )

export const getInvoiceCategories = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<InvoiceCategory>(
    INVOICE_CATEGORIES_URL,
    params,
    [invoiceCategorySchema],
    slice.actions
  )

export const getInvoiceCategoryWithId = (id: number) =>
  SBAPIFetchDispatch<InvoiceCategory>(
    `${INVOICE_CATEGORIES_URL}/${id}`,
    invoiceCategorySchema,
    slice.actions
  )

export const createInvoiceCategory = (invoice: InvoiceCategory | FormData) =>
  SBAPICreate<InvoiceCategory | FormData>(
    invoice,
    INVOICE_CATEGORIES_URL,
    invoiceCategorySchema,
    slice.actions
  )

export const updateInvoiceCategory = (invoice: InvoiceCategory) =>
  SBAPIUpdate<InvoiceCategory>(
    invoice,
    `${INVOICE_CATEGORIES_URL}/${invoice.id}`,
    slice.actions
  )

export const updateInvoiceCategoryFormData = (invoice: FormData) =>
  SBAPICreate<FormData>(
    invoice,
    `${INVOICE_CATEGORIES_URL}/${invoice.get('id')}`,
    invoiceCategorySchema,
    slice.actions
  )

export const deleteInvoiceCategory = (invoice: InvoiceCategory) =>
  SBAPIDelete<InvoiceCategory>(
    invoice,
    `${INVOICE_CATEGORIES_URL}/${invoice.id}`,
    slice.actions
  )

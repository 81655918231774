import {
  Button,
  Col,
  Empty,
  Form,
  FormInstance,
  InputNumber,
  Row,
  Space,
  Typography,
} from 'antd'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import {
  getReportItems,
  selectReportItems,
  setReportItemQuery,
} from '../../../reducers/ReportItemReducer'
import { initialQuery } from '../../../utils/helpers/crud/models'
import { fieldFormatter } from '../../../utils/formatters'
import { ReportingWizardForms } from '../Wizard'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { useTenantSettings } from '../../../utils/hooks/useTenantSettings'

interface Props {
  form: FormInstance
}

const { Text } = Typography

const ReportWizardStep1 = ({ form }: Props) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const { currency } = useTenantSettings()

  const items = useAppSelector(selectReportItems())
  const costItems = items
    .filter((i) => i.type.color === 'red')
    .sort((a, b) => a.order - b.order)
  const revenueItems = items
    .filter((i) => i.type.color === 'green')
    .sort((a, b) => a.order - b.order)

  useEffect(() => {
    const baseQuery = {
      ...initialQuery,
      pagination: { ...initialQuery.pagination, pageSize: 1000 },
    }
    dispatch(getReportItems(baseQuery))
  }, [])

  if (items.length === 0) {
    return (
      <Empty
        style={{ margin: 80 }}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
          <Space direction="vertical" size={0}>
            <Text>
              {intl.formatMessage({
                id: LocalizationKeys.Components.Reporting.Wizard.Step1
                  .NoItemsTitle,
              })}
            </Text>
            <Text>
              {intl.formatMessage({
                id: LocalizationKeys.Components.Reporting.Wizard.Step1
                  .NoItemsDescription,
              })}
            </Text>
          </Space>
        }
      >
        {/* Testing hideCreateButton like that ensure to hide the button only when the parameter is specified */}
        <Link to="/settings/reporting/items">
          <Button type="primary">
            {intl.formatMessage({
              id: LocalizationKeys.Components.Reporting.Wizard.Step1
                .NoItemsGoToSettings,
            })}
          </Button>
        </Link>
      </Empty>
    )
  }

  return (
    <Form name={ReportingWizardForms.RevenuesAndCosts} form={form}>
      <Row gutter={[50, 16]}>
        <Col span={12}>
          <PageTitle
            title={intl.formatMessage({
              id: LocalizationKeys.Components.Reporting.Wizard.Step1.Costs,
            })}
            level={5}
          />
          {costItems.map((item) => (
            <Form.Item key={item.id} label={item.name} name={item.id}>
              <InputNumber
                min={0}
                className="w-full"
                addonBefore={currency?.symbol}
                formatter={fieldFormatter}
              />
            </Form.Item>
          ))}
        </Col>
        <Col span={12}>
          <PageTitle
            title={intl.formatMessage({
              id: LocalizationKeys.Components.Reporting.Wizard.Step1.Revenues,
            })}
            level={5}
          />
          {revenueItems.map((item) => (
            <Form.Item key={item.id} label={item.name} name={item.id}>
              <InputNumber
                min={0}
                className="w-full"
                addonBefore={currency?.symbol}
                formatter={fieldFormatter}
              />
            </Form.Item>
          ))}
        </Col>
      </Row>
    </Form>
  )
}

export default ReportWizardStep1

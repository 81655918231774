import {
  ReportItem,
  REPORT_ITEM_TYPE_COST_ID as COST_ID,
  REPORT_ITEM_TYPE_REVENUE_ID as REVENUE_ID,
} from '../../models/Report'
import { DonutItem } from '../../components/reporting/Donut'

const mapItemToDonutData = (item: ReportItem): DonutItem => ({
  type: item.name,
  value: item.amount ?? 0,
})

const groupReportItemsById = (items: ReportItem[]) => {
  const ammountsSumById = new Map<number, number>()

  // Calculer la somme des amounts pour chaque id
  for (const { id, amount } of items) {
    ammountsSumById.set(id, (ammountsSumById.get(id) || 0) + (amount ?? 0))
  }

  // Créer un tableau de ReportItem sans doublons avec la somme des montants
  const summarizedReportItems: ReportItem[] = []
  ammountsSumById.forEach((totalAmount, id) => {
    const originalItem: ReportItem | undefined = items.find(
      (item) => item.id === id
    )
    if (originalItem) {
      summarizedReportItems.push({ ...originalItem, amount: totalAmount })
    }
  })

  return summarizedReportItems
}

const computeSumForDonutData = (items: DonutItem[]): number =>
  items.reduce((sum, current) => sum + current.value, 0)

const extractCostsData = (items: ReportItem[]): DonutItem[] =>
  items
    .filter((item) => item.type.id === COST_ID)
    .sort((a, b) => (b.amount ?? 0) - (a.amount ?? 0))
    .map(mapItemToDonutData)

const extractRevenuesData = (items: ReportItem[]): DonutItem[] =>
  items
    .filter((item) => item.type.id === REVENUE_ID)
    .sort((a, b) => (b.amount ?? 0) - (a.amount ?? 0))
    .map(mapItemToDonutData)

export {
  computeSumForDonutData,
  extractCostsData,
  extractRevenuesData,
  groupReportItemsById,
}

import { Alert, AlertProps } from 'antd'
import { useAuth } from '../../utils/auth/Auth'

export const TenantBanner = () => {
  const { currentTenant } = useAuth()

  if (currentTenant && (currentTenant.banners ?? []).length === 0) {
    return <></>
  }

  const bannersHolderStyle: React.CSSProperties = {
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    position: 'sticky',
    textAlign: 'center',
  }

  return (
    <div style={bannersHolderStyle}>
      {currentTenant?.banners.map((banner) => (
        <Banner key={banner.id} type={banner.type} message={banner.message} />
      ))}
    </div>
  )
}

interface BannerProps {
  type: AlertProps['type']
  message: string
}
const Banner = ({ type, message }: BannerProps) => {
  const messageStyle: React.CSSProperties = {
    borderRadius: 0,
    paddingRight: '20px',
  }

  const linkStyle = {
    color: '#FFFFFFD9',
    textDecoration: 'underline',
  }

  return (
    <Alert
      message={
        <div
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        ></div>
      }
      type={type}
      closable={true}
      style={messageStyle}
    />
  )
}

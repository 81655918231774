import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIUpdate,
} from '../utils/helpers/SBAPIHelper'
import { CreateReport, Report, UpdateReport } from '../models/Report'
import { REPORTS_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { reportSchema } from '../models/schema'
import { initialQuery } from '../utils/helpers/crud/models'

const initialState: SBState<Report> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: initialQuery,
}

const slice = createSlice({
  name: 'report',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getReportsSuccess,
  setQuery: setReportQuery,
  reset: resetReportState,
  resetQueryAndIds: resetReportQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: Report }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectReports = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedReport = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectReportById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectReportsByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedReport =
  (report: Report | number | undefined) => async (dispatch: AppDispatch) =>
    dispatch(
      slice.actions.setSelectedId(
        report ? getIdOrModelId<Report>(report) : undefined
      )
    )

export const getReports = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<Report>(
    REPORTS_URL,
    params,
    [reportSchema],
    slice.actions
  )

export const getReportWithId = (id: number) =>
  SBAPIFetchDispatch<Report>(
    `${REPORTS_URL}/${id}`,
    reportSchema,
    slice.actions
  )

export const createReport = (report: CreateReport) =>
  SBAPICreate<CreateReport>(report, REPORTS_URL, reportSchema, slice.actions)

export const updateReport = (report: UpdateReport) =>
  SBAPIUpdate<UpdateReport>(
    report,
    `${REPORTS_URL}/${report.id}`,
    slice.actions
  )

export const deleteReport = (report: Report) =>
  SBAPIDelete<Report>(report, `${REPORTS_URL}/${report.id}`, slice.actions)

import dayjs from 'dayjs'
import Table from './Table'
import ContactTag from './ContactTag'
import { commonStyle } from './style'
import { Text, View } from '@react-pdf/renderer'
import { TravelWithRelation } from '../../../models/Travel'

interface Props {
  data: TravelWithRelation[]
}

const TravelTable = ({ data }: Props) => {
  const TravelsCols = [
    {
      title: 'Ref',
      key: 'number',
      render: (record: TravelWithRelation) => (
        <View style={commonStyle.spaceVertical}>
          <Text>{record.number}</Text>
          <Text style={commonStyle.muted}>
            {record.inbound ? 'Inbound' : 'Outbound'}
          </Text>
        </View>
      ),
    },
    {
      title: 'Departure',
      key: 'from',
      render: (record: TravelWithRelation) => (
        <View>
          <Text>{record.departure.name}</Text>
          <Text>{dayjs(record.departureTime).format('DD/MM HH:mm')}</Text>
        </View>
      ),
    },
    {
      title: 'Arrival',
      key: 'to',
      render: (record: TravelWithRelation) => (
        <View>
          <Text>{record.arrival.name}</Text>
          <Text>{dayjs(record.arrivalTime).format('DD/MM HH:mm')}</Text>
        </View>
      ),
    },
    {
      title: 'Contacts',
      key: 'contacts',
      render: (record: TravelWithRelation) => (
        <View style={commonStyle.spaceHorizontal}>
          {record.contacts.map((contact) => (
            <ContactTag key={contact.id} contact={contact} />
          ))}
        </View>
      ),
    },
  ]

  return <Table columns={TravelsCols} data={data} />
}

export default TravelTable

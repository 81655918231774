import { Col, Layout, Row } from 'antd'
import { Outlet } from 'react-router-dom'
import { LayoutFooter } from './LayoutFooter'
import MainHeader from './components/MainHeader'
import MainHeaderMobile from './components/MainHeaderMobile'
import { useHasAccess } from '../components/HasAccess'
import { AlelaPermission } from '../utils/permissions'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../i18n/LocalizationKeys'

const { Content } = Layout

export const LayoutCRM: React.FC = () => {
  const intl = useIntl()
  const { hasAccess } = useHasAccess()

  const subItems = [
    ...(hasAccess([AlelaPermission.listContact])
      ? [
          {
            key: 'contacts',
            label: (
              <Link to={'contacts'}>
                {intl.formatMessage({
                  id: LocalizationKeys.Layout.CRM.Contacts,
                })}
              </Link>
            ),
          },
        ]
      : []),
    ...(hasAccess([AlelaPermission.listSupplier])
      ? [
          {
            key: 'suppliers',
            label: (
              <Link to={'suppliers'}>
                {intl.formatMessage({
                  id: LocalizationKeys.Layout.CRM.Suppliers,
                })}
              </Link>
            ),
          },
        ]
      : []),
    ...(hasAccess([AlelaPermission.listUser])
      ? [
          {
            key: 'organization-team',
            label: (
              <Link to={'organization-team'}>
                {intl.formatMessage({
                  id: LocalizationKeys.Layout.CRM.Organization,
                })}
              </Link>
            ),
          },
        ]
      : []),
  ]

  return (
    <Layout>
      <MainHeader subItems={subItems} />
      <Layout style={{ minHeight: '100vh', marginTop: 128 }}>
        <Content>
          <Row className="container-row">
            <Col xs={24} className="px-4">
              <Outlet />
            </Col>
          </Row>
        </Content>
        <LayoutFooter />
      </Layout>
      <MainHeaderMobile subItems={subItems} />
    </Layout>
  )
}

import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
  PropsWithChildren,
} from 'react'
import {
  getLoggedUserAccountInfos,
  getLoggedUserInfos,
  logout as logoutAPI,
  updateLoggedUserInfos,
} from './requests'
import { User } from '../../models/User'
import { useNavigate } from 'react-router-dom'
import { AuthModel } from '../../models/AuthModel'
import * as authHelper from '../helpers/AuthLocalStorageHelper'
import { Tenant } from '../../models/Tenant'
import { resetStore } from '../helpers/AuthHelper'
import { useAppDispatch } from '../../reducers/hooks'
import { forgetCurrentDevice } from '../firebase'
import { useLang } from '../../i18n/useLang'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: User | undefined
  setCurrentUser: Dispatch<SetStateAction<User | undefined>>
  isLogged: boolean | undefined
  setIsLogged: Dispatch<SetStateAction<boolean | undefined>>
  currentTenant: Tenant | undefined
  saveTenant: (tenant: Tenant | undefined) => void
  tenants: Tenant[]
  setTenants: (tenants: Tenant[]) => void
  logout: () => void
  updateUser: (user: User) => Promise<boolean>
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  isLogged: undefined,
  setIsLogged: () => {},
  currentTenant: undefined,
  saveTenant: () => {},
  tenants: [],
  setTenants: () => {},
  logout: () => {},
  updateUser: ({}) => new Promise<boolean>(() => false),
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [currentUser, setCurrentUser] = useState<User | undefined>()
  const [isLogged, setIsLogged] = useState<boolean | undefined>(undefined)
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [tenants, setTenants] = useState<Tenant[]>([])
  const [currentTenant, setCurrentTenant] = useState<Tenant | undefined>(
    authHelper.getAuthTenant()
  )

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const saveTenant = (tenant: Tenant | undefined) => {
    setCurrentTenant(tenant)
    if (tenant) {
      authHelper.setAuthTenant(tenant)
    } else {
      authHelper.removeAuthTenant()
    }
  }

  const logout = () => {
    /**
     * Clear FCM Token
     * Need to be done first as it's an API request
     */
    forgetCurrentDevice()
      .then((_) => {
        // Whatever the request result
        // The user must be logged out
        logoutAPI().then(logoutHandler).catch(logoutHandler)
      })
      .catch((_) => {
        // Whatever the request result
        // The user must be logged out
        logoutAPI().then(logoutHandler).catch(logoutHandler)
      })
  }

  const logoutHandler = () => {
    setIsLogged(false)
    setCurrentUser(undefined)
    saveAuth(undefined)
    saveTenant(undefined)
    dispatch(resetStore())
    navigate('/auth/login')
    window.Beacon('logout')
  }

  const updateUser = (user: User) =>
    updateLoggedUserInfos(user).then((_) => {
      getLoggedUserInfos().then((data) => setCurrentUser(data.data.data))
      return true
    })

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        isLogged,
        setIsLogged,
        currentTenant,
        saveTenant,
        tenants,
        setTenants,
        logout,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<PropsWithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser, setIsLogged, saveTenant, setTenants } =
    useAuth()
  const didRequest = useRef(false)
  const { selectedLocale, setLocale } = useLang()

  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const { data: accountInfo } = await getLoggedUserAccountInfos()
          if (accountInfo.data && accountInfo.data.tenants) {
            /**
             * Store all tenant attached to the user
             * This allow to display the tenant selection in top right menu
             */
            setTenants(accountInfo.data.tenants)

            /**
             * Then we need to set the current tenant
             * So we first check in the local storage
             * If there is one and this one is present in the tenants we get above
             * => This one will be the current
             *
             * Otherwise we set the first of the ones we get above
             */
            const localStorageTenant = authHelper.getAuthTenant()
            const localTenantInAccountInfo = accountInfo.data.tenants.find(
              (tenant: Tenant) => tenant.id === localStorageTenant?.id
            )

            if (localTenantInAccountInfo) {
              /**
               * We save the one coming from the API instead of keeping the current one
               * This allow the update the banners
               */
              saveTenant(localTenantInAccountInfo)
            } else {
              saveTenant(accountInfo.data.tenants[0])
            }
          }
          const { data } = await getLoggedUserInfos()
          if (data) {
            setCurrentUser(data.data)
            if (data.data.locale !== selectedLocale) {
              /**
               * The just fetched locale is considered as prefered locale
               * So in case the local locale is not the same let's update it
               */
              setLocale(data.data.locale)
            }
          }
        }
      } catch (error) {
        if (!didRequest.current) {
          logout()
        }
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.access_token) {
      setIsLogged(true)
      requestUser()
    } else {
      setIsLogged(false)
    }
  }, [])

  return <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }

import { InputRef } from 'antd'
import { useIntl } from 'react-intl'
import { useEffect, useRef, useState } from 'react'
import { Artist as ArtistModel } from '../../models/Artist'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import {
  getArtists,
  selectArtists,
  setArtistQuery,
} from '../../reducers/ArtistReducer'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import getColumnSearchProps from '../../utils/table/getColumnSearchProps'
import { Artist } from '../../components/artist/Artist'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { Actions } from '../../modules/sbrm/components/Actions'
import { initialQuery } from '../../utils/helpers/crud/models'
import { TableParams } from '../../models/TableParams'
import HasAccess, { useHasAccess } from '../../components/HasAccess'
import { AlelaPermission } from '../../utils/permissions'
import SBTable from '../../components/SBTable'
import { extractSortDataFromSorterResult } from '../../utils/table/sorter'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { CurrencyFormat } from '@supplement-bacon/alela-uikit'
import { useTenantSettings } from '../../utils/hooks/useTenantSettings'

const ArtistsPage = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const { hasAccess } = useHasAccess()
  const { currency } = useTenantSettings()

  const searchInput = useRef<InputRef>(null)
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const artists = useAppSelector(selectArtists())
  const { query, isLoading } = useAppSelector((state) => state.artist)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const handleSearch = (
    selectedKeys: string[],
    dataIndex: keyof ArtistModel
  ) => {
    const newQuery = {
      ...query,
      ...{ search: { [dataIndex]: selectedKeys[0] } },
    }
    dispatch(setArtistQuery(newQuery))
    dispatch(getArtists(newQuery))
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    const newQuery = {
      ...query,
      ...{ search: {} },
    }
    dispatch(setArtistQuery(newQuery))
    dispatch(getArtists(newQuery))
  }

  const columnsToDisplay: Record<string, boolean> = {
    name: true,
    last_fee: hasAccess([AlelaPermission.viewPriceBooking]),
    average_fee: hasAccess([AlelaPermission.viewPriceBooking]),
    bookings_count: true,
    actions: hasAccess([AlelaPermission.editArtist]),
  }

  const columns: ColumnsType<ArtistModel> = [
    {
      key: 'name',
      title: intl.formatMessage({ id: LocalizationKeys.Page.Artists.Name }),
      sorter: true,
      ...getColumnSearchProps<ArtistModel>(
        'name',
        searchInput,
        handleSearch,
        handleReset,
        intl,
        isLoading
      ),
      render: (artist: ArtistModel) => (
        <Artist.Avatar id={artist.id} size="large" />
      ),
    },
    {
      key: 'last_fee',
      title: intl.formatMessage({ id: LocalizationKeys.Page.Artist.LastFee }),
      render: (record: ArtistModel) => (
        <CurrencyFormat
          amount={record.last_fee ?? 0}
          currencySymbol={currency}
        />
      ),
    },
    {
      key: 'average_fee',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.Artist.AverageFee,
      }),
      render: (record: ArtistModel) => (
        <CurrencyFormat
          amount={record.average_fee ?? 0}
          currencySymbol={currency}
        />
      ),
    },
    {
      key: 'bookings_count',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.Artists.BookingsCount,
      }),
      dataIndex: 'bookings_count',
      sorter: true,
      defaultSortOrder: 'descend',
    },
    {
      key: 'actions',
      align: 'right',
      render: (artist: ArtistModel) => (
        <Actions
          actions={['update', 'delete']}
          entity={SBRMType.artist}
          entityId={artist.id}
        />
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<ArtistModel> | SorterResult<ArtistModel>[]
  ) => {
    const newQuery = {
      ...query,
      pagination,
      filters,
      ...extractSortDataFromSorterResult<ArtistModel>(sorter),
    }
    dispatch(setArtistQuery(newQuery))
    dispatch(getArtists(newQuery))
  }

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      return
    }
    setIsFirstRender(false)

    // Trigger on init and when SBRM is closed
    const baseQuery: TableParams = {
      ...initialQuery,
      with: ['feesResume', 'bookingsCount'],
      columnKey: 'bookings_count',
      order: 'descend',
    }
    dispatch(setArtistQuery(baseQuery))
    dispatch(getArtists(baseQuery))
  }, [dispatch, SBRMIsOpen])

  return (
    <HasAccess permissions={[AlelaPermission.listArtist]}>
      <PageTitle
        title={intl.formatMessage({ id: LocalizationKeys.Page.Artists.Title })}
        toolbar={<Actions actions={['create']} entity={SBRMType.artist} />}
      />

      <SBTable
        entity={SBRMType.artist}
        scroll={{ x: 1000 }}
        columns={columns.filter(
          (column) => columnsToDisplay[column.key! as string]
        )}
        rowKey={(record) => record.id}
        dataSource={artists}
        pagination={query.pagination}
        loading={isLoading && !artists.length}
        onChange={handleTableChange}
      />
    </HasAccess>
  )
}

export default ArtistsPage

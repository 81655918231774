import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIUpdate,
  SBAPIFetch,
} from '../utils/helpers/SBAPIHelper'
import { Contact, ContactMissingInPartyCrew } from '../models/Contact'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { contactSchema } from '../models/schema'
import { initialQuery } from '../utils/helpers/crud/models'
import { CONTACTS_URL, CONTACTS_MISSING_PARTY_CREW_URL } from '../utils/urls'

export const initialContactQuery: TableParams = {
  ...initialQuery,
}

const initialState: SBState<Contact> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: initialContactQuery,
}

const slice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    ...baseReducers,
    addIds(state, action) {
      state.ids = [...state.ids, ...action.payload]
    },
  },
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getContactsSuccess,
  setQuery: setContactQuery,
  reset: resetContactState,
  resetQueryAndIds: resetContactQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: Contact }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectContacts = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )

export const selectSelectedContact = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectContactById = (id: number | string) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectContactsByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedContact =
  (contact: Contact | number) => async (dispatch: AppDispatch) =>
    dispatch(slice.actions.setSelectedId(getIdOrModelId<Contact>(contact)))

export const getContacts = (
  params: TableParams,
  infiniteMode: boolean = false
) =>
  SBAPIFetchPaginatedDispatch<Contact>(
    CONTACTS_URL,
    params,
    [contactSchema],
    slice.actions,
    null,
    infiniteMode ? slice.actions.addIds : null
  )

export const getContactWithId = (id: number) =>
  SBAPIFetchDispatch<Contact>(
    `${CONTACTS_URL}/${id}`,
    contactSchema,
    slice.actions
  )

export const getMissingContactsInPartyCrew = (bookingId: number) =>
  SBAPIFetch<ContactMissingInPartyCrew>(CONTACTS_MISSING_PARTY_CREW_URL, {
    booking: bookingId,
  })

export const createContact = (contact: Contact) =>
  SBAPICreate<Contact>(contact, CONTACTS_URL, contactSchema, slice.actions)

export const updateContact = (contact: Contact) =>
  SBAPIUpdate<Contact>(contact, `${CONTACTS_URL}/${contact.id}`, slice.actions)

export const deleteContact = (contact: Contact) =>
  SBAPIDelete<Contact>(contact, `${CONTACTS_URL}/${contact.id}`, slice.actions)

import { useAuth } from '../utils/auth/Auth'
import { useAzureSSO } from '../utils/saml-sso/AzureSSOContext'
import { LoadingFullScreen } from './LoadingFullScreen'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { TenantBanner } from './components/TenantBanner'

const LayoutLogged: React.FC = () => {
  const { pathname } = useLocation()
  const { isLogged: isLoggedEmail, currentUser } = useAuth()
  const { isLogged: isLoggedAzure } = useAzureSSO()
  const isLogged = isLoggedEmail || isLoggedAzure

  if (isLogged === false) {
    // We're not checking !isLogged as isLogged might be undefined during the account fetching on init
    // Ensure redirection to home page if the user is logged
    return <Navigate to={'/auth/login?returnTo=' + pathname} />
  }

  if (currentUser === undefined) {
    // We're not checking currentTenant as currentUser is set after
    // So when currentUser is not undefined it means the loading is finished
    return <LoadingFullScreen />
  }

  return (
    <>
      <TenantBanner />
      <Outlet />
    </>
  )
}

export default LayoutLogged

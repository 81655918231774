import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Address, CustomAddressWithRelation } from '../../models/Address'
import { TravelWithRelation } from '../../models/Travel'
import { Venue } from '../../models/Venue'
import { Hotel } from '../../models/Hotel'
import { useAppSelector } from '../../reducers/hooks'
import { TableParams } from '../../models/TableParams'
import { initialQuery } from '../helpers/crud/models'
import { SBAPIFetchPaginated } from '../helpers/SBAPIHelper'
import {
  CUSTOM_ADDRESS_URL,
  HOTELS_URL,
  TRAVELS_URL,
  VENUES_URL,
} from '../urls'
import { Ground } from '../../models/Ground'
import { Form } from 'antd'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import {
  fieldValueIsValid,
  groundTypeToCascaderValue,
} from '../helpers/GroundHelper'

type GroundTimeMode = 'departAt' | 'arriveBy'

type GroundContextDataType<T> = {
  data: T[]
  isLoading: boolean
}

type GroundContextCustomAddressField = {
  isVisible: boolean
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
}

type GroundContextProps = {
  hotels: GroundContextDataType<Hotel>
  venues: GroundContextDataType<Venue>
  travels: GroundContextDataType<TravelWithRelation>
  customAddress: GroundContextDataType<CustomAddressWithRelation>
  fromCustomAddressField: GroundContextCustomAddressField
  toCustomAddressField: GroundContextCustomAddressField
  getCustomAddressField: (
    type: 'from' | 'to'
  ) => GroundContextCustomAddressField
  fillCascader: (
    hotels?: Hotel[],
    venues?: Venue[],
    travels?: TravelWithRelation[],
    customAddess?: CustomAddressWithRelation[]
  ) => void
  getCoordinates: () => { from: string; to: string } | undefined

  timeMode: GroundTimeMode
  setTimeMode: React.Dispatch<React.SetStateAction<GroundTimeMode>>
}

const initGroundContextDataType = {
  data: [],
  isLoading: false,
}
const initGroundContextCustomAddressFieldDataType = {
  isVisible: false,
  setIsVisible: () => {},
}

const initGroundContextPropsState: GroundContextProps = {
  hotels: initGroundContextDataType,
  venues: initGroundContextDataType,
  travels: initGroundContextDataType,
  customAddress: initGroundContextDataType,
  fromCustomAddressField: initGroundContextCustomAddressFieldDataType,
  toCustomAddressField: initGroundContextCustomAddressFieldDataType,
  getCustomAddressField: () => initGroundContextCustomAddressFieldDataType,
  fillCascader: () => {},
  getCoordinates: () => ({ from: '0,0', to: '0,0' }),
  timeMode: 'departAt',
  setTimeMode: () => {},
}

const GroundContext = createContext<GroundContextProps>(
  initGroundContextPropsState
)

const useGroundContext = () => useContext(GroundContext)

interface Props {
  bookingId: number | undefined
  initialValue?: Ground
}

const GroundContextProvider = ({
  bookingId,
  children,
  initialValue,
}: PropsWithChildren<Props>) => {
  const [hotels, setHotels] = useState<Hotel[]>([])
  const [hotelsAreLoading, setHotelsAreLoading] = useState<boolean>(false)

  const [venues, setVenues] = useState<Venue[]>([])
  const [venuesAreLoading, setVenuesAreLoading] = useState<boolean>(false)

  const [travels, setTravels] = useState<TravelWithRelation[]>([])
  const [travelsAreLoading, setTravelsAreLoading] = useState<boolean>(false)

  const [customAddress, setCustomAddress] = useState<
    CustomAddressWithRelation[]
  >([])
  const [customAddressLoading, setCustomAddressLoading] =
    useState<boolean>(false)

  const [fromCustomAddressVisible, setFromCustomAddressVisible] =
    useState<boolean>(false)
  const [toCustomAddressVisible, setToCustomAddressVisible] =
    useState<boolean>(false)

  const [timeMode, setTimeMode] = useState<GroundTimeMode>('departAt')

  const form = Form.useFormInstance()
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const fillCascader = (
    Shotels?: Hotel[],
    Svenues?: Venue[],
    Stravels?: TravelWithRelation[],
    ScustomAddess?: CustomAddressWithRelation[]
  ) => {
    if (!initialValue) {
      return
    }

    form.setFieldValue('from', [
      groundTypeToCascaderValue(
        initialValue?.from!,
        Shotels ?? hotels,
        Svenues ?? venues,
        Stravels ?? travels,
        ScustomAddess ?? customAddress
      ),
      `${initialValue?.from?.id}`,
    ])
    form.setFieldValue('to', [
      groundTypeToCascaderValue(
        initialValue?.to!,
        Shotels ?? hotels,
        Svenues ?? venues,
        Stravels ?? travels,
        ScustomAddess ?? customAddress
      ),
      `${initialValue?.to?.id}`,
    ])

    if (initialValue.from?.type === SBRMType.customAddress) {
      setFromCustomAddressVisible(true)
    }
    if (initialValue.to?.type === SBRMType.customAddress) {
      setToCustomAddressVisible(true)
    }
  }

  const fromCustomAddressField = {
    isVisible: fromCustomAddressVisible,
    setIsVisible: setFromCustomAddressVisible,
  }
  const toCustomAddressField = {
    isVisible: toCustomAddressVisible,
    setIsVisible: setToCustomAddressVisible,
  }
  const getCustomAddressField = (type: 'from' | 'to') =>
    type === 'from' ? fromCustomAddressField : toCustomAddressField

  const getCoordinates = () => {
    const fromField = form.getFieldValue('from')
    const fromAddressField = form.getFieldValue('from_address')
    const toField = form.getFieldValue('to')
    const toAddressField = form.getFieldValue('to_address')

    if (!fieldValueIsValid(fromField) || !fieldValueIsValid(toField)) {
      return undefined
    }

    const fromAddress =
      fromField[1] === 'new_customaddress'
        ? ({
            latitude: fromAddressField.latitude,
            longitude: fromAddressField.longitude,
          } as Address)
        : getAddressFromTypeAndId(fromField[0], Number(fromField[1]))

    const toAddress =
      toField[1] === 'new_customaddress'
        ? ({
            latitude: toAddressField.latitude,
            longitude: toAddressField.longitude,
          } as Address)
        : getAddressFromTypeAndId(toField[0], Number(toField[1]))

    if (fromAddress === undefined || toAddress === undefined) {
      return undefined
    }

    return {
      from: `${fromAddress.latitude},${fromAddress.longitude}`,
      to: `${toAddress.latitude},${toAddress.longitude}`,
    }
  }

  const getAddressFromTypeAndId = (
    type: string,
    id: number
  ): Address | undefined => {
    switch (type) {
      case 'inbound':
      case 'outbound':
        const travel = travels.find((t) => t.id === id)
        return travel?.inbound === true
          ? travel.arrival.address
          : travel?.departure.address
      case 'venue':
        return venues.find((v) => v.id === id)?.address
      case 'hotel':
        return hotels.find((h) => h.id === id)?.address
      case 'customaddress':
        return customAddress.find((c) => c.id === id)?.address
    }
  }

  useEffect(() => {
    if (!bookingId || !SBRMIsOpen) {
      // We want to trigger only the reset
      // when the bookingId is valid and the SBRM is open
      setHotels([])
      setVenues([])
      setTravels([])
      setCustomAddress([])
      setFromCustomAddressVisible(false)
      setToCustomAddressVisible(false)
      return
    }

    const baseQuery: TableParams = {
      ...initialQuery,
      pagination: { current: 1, pageSize: 1000 },
      filters: { ...initialQuery.filters, bookings: [bookingId] },
    }

    setHotelsAreLoading(true)
    const fetchHotels = SBAPIFetchPaginated<Hotel>(HOTELS_URL, baseQuery).then(
      (data: any) => {
        setHotels(data.data)
        setHotelsAreLoading(false)
        return data.data
      }
    )
    setVenuesAreLoading(true)
    const fetchVenues = SBAPIFetchPaginated<Venue>(VENUES_URL, baseQuery).then(
      (data: any) => {
        setVenues(data.data)
        setVenuesAreLoading(false)
        return data.data
      }
    )
    setTravelsAreLoading(true)
    const fetchTravels = SBAPIFetchPaginated<TravelWithRelation>(
      TRAVELS_URL,
      baseQuery
    ).then((data: any) => {
      setTravels(data.data)
      setTravelsAreLoading(false)
      return data.data
    })
    setCustomAddressLoading(true)
    const fetchCustomAddress = SBAPIFetchPaginated<CustomAddressWithRelation>(
      CUSTOM_ADDRESS_URL,
      baseQuery
    ).then((data: any) => {
      setCustomAddress(data.data)
      setCustomAddressLoading(false)
      return data.data
    })

    /**
     * The travel where all inserted in the Outbound option, whatever they were Inboud/Outbound
     * This was because the fillCascader() method was executed before setTravels() method was completed
     * We need wait all requests to complete and explicitely pass the data to the method to ensure it will find the travel in the array
     * Despite the issue used to happend only for the travel, we pass also the hotels and venues
     * It might be useful in the future
     */
    Promise.allSettled([
      fetchHotels,
      fetchVenues,
      fetchTravels,
      fetchCustomAddress,
    ]).then((results) => {
      fillCascader(
        (results[0] as PromiseFulfilledResult<Hotel[]>).value,
        (results[1] as PromiseFulfilledResult<Venue[]>).value,
        (results[2] as PromiseFulfilledResult<TravelWithRelation[]>).value,
        (results[3] as PromiseFulfilledResult<CustomAddressWithRelation[]>)
          .value
      )
    })
  }, [bookingId, SBRMIsOpen])

  return (
    <GroundContext.Provider
      value={{
        hotels: { data: hotels, isLoading: hotelsAreLoading },
        venues: { data: venues, isLoading: venuesAreLoading },
        travels: { data: travels, isLoading: travelsAreLoading },
        customAddress: { data: customAddress, isLoading: customAddressLoading },
        fromCustomAddressField: fromCustomAddressField,
        toCustomAddressField: toCustomAddressField,
        getCustomAddressField: getCustomAddressField,
        fillCascader: fillCascader,
        getCoordinates: getCoordinates,

        timeMode: timeMode,
        setTimeMode: setTimeMode,
      }}
    >
      {children}
    </GroundContext.Provider>
  )
}

export {
  GroundContextProvider,
  useGroundContext,
  type GroundContextCustomAddressField,
  type GroundTimeMode,
}

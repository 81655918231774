import { useIntl } from 'react-intl'
import { ALMSection } from '../../../external-advancing/types'
import { numberRule, requiredRule } from '../../../../utils/rules'
import {
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from '@ant-design/icons'
import {
  Card,
  Col,
  Form,
  InputNumber,
  Radio,
  Row,
  Space,
  Switch,
  Typography,
} from 'antd'
import LocalizationKeys from '../../../../i18n/LocalizationKeys'

const { Text } = Typography

interface Props {
  section: ALMSection
}

const SectionFormItem = ({ section }: Props) => {
  const intl = useIntl()

  const form = Form.useFormInstance()
  const currentValue = Form.useWatch(section.itemName, form)

  const isEnabled = ['view', 'edit'].includes(currentValue)

  return (
    <Card
      size="small"
      title={
        <Text>
          {section.icon}{' '}
          {intl.formatMessage({
            id: LocalizationKeys.ExternalAdvancing[section.key],
          })}
        </Text>
      }
      extra={
        <Switch
          checkedChildren={<EyeOutlined />}
          unCheckedChildren={<EyeInvisibleOutlined />}
          checked={isEnabled}
          onChange={(checked) => {
            form.setFieldValue(section.itemName, checked ? 'view' : 'hidden')
          }}
        />
      }
      styles={{
        body: !isEnabled
          ? { padding: 0, paddingBottom: 2 }
          : { padding: 12, paddingTop: 3 },
      }}
      style={isEnabled ? { borderColor: '#5252d4' } : {}}
    >
      <Row gutter={16}>
        <Col span={11}>
          <Form.Item
            name={section.itemName}
            initialValue={'hidden'}
            rules={isEnabled ? [requiredRule(intl)] : []}
            style={{ display: isEnabled ? 'block' : 'none', marginBottom: 0 }}
          >
            <Radio.Group>
              {section.options.map((option) => (
                <Radio.Button key={option} value={option}>
                  {option === 'edit' && (
                    <Space>
                      <EditOutlined />
                      {intl.formatMessage({
                        id: LocalizationKeys.ExternalAdvancingManager.Section
                          .Edit,
                      })}
                    </Space>
                  )}
                  {option === 'view' && (
                    <Space>
                      <EyeOutlined />
                      {intl.formatMessage({
                        id: LocalizationKeys.ExternalAdvancingManager.Section
                          .View,
                      })}
                    </Space>
                  )}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={13}>
          {currentValue === 'edit' && section.hasMaxValue === true && (
            <Form.Item
              name={section.maxValueItemName}
              initialValue={10}
              rules={[requiredRule(intl), numberRule(intl)]}
              style={{ marginBottom: 0 }}
            >
              <InputNumber
                defaultValue={1}
                min={1}
                step={1}
                addonBefore={intl.formatMessage({
                  id: LocalizationKeys.ExternalAdvancingManager.Section.Add,
                })}
                addonAfter={intl.formatMessage({
                  id: LocalizationKeys.ExternalAdvancingManager.Section
                    .ItemsMax,
                })}
              />
            </Form.Item>
          )}
        </Col>
      </Row>
    </Card>
  )
}

export default SectionFormItem

import { Card, Space } from 'antd'
import { Header } from 'antd/es/layout/layout'
import { PropsWithChildren } from 'react'

interface Props {
  toolbar?: React.ReactNode
}

const DetailsPageHeader = ({ toolbar, children }: PropsWithChildren<Props>) => {
  return (
    <Card type="inner" bordered={false}>
      <Space
        style={{
          justifyContent: 'space-between',
          display: 'flex',
        }}
        wrap={true}
      >
        {children}
        {toolbar}
      </Space>
    </Card>
  )
}

export { DetailsPageHeader }

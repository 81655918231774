import axios from 'axios'
import { useState } from 'react'
import { App, Button, Tooltip } from 'antd'
import { ReloadOutlined } from '@ant-design/icons'
import { useAppSelector } from '../../reducers/hooks'
import { TENANT_INVITATIONS } from '../../utils/urls'
import { selectInvitationById } from '../../reducers/InvitationReducer'
import { Invitation as InvitationModel } from '../../models/Invitation'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'

interface Props {
  id?: string
  model?: InvitationModel
}

export const ResendEmail = ({ id, model }: Props) => {
  const intl = useIntl()
  const invitation = model
    ? model
    : useAppSelector(selectInvitationById(id ?? ''))

  const { message } = App.useApp()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const resendInvitationEmail = () => {
    setIsLoading(true)
    axios
      .post(`${TENANT_INVITATIONS}/${invitation?.id}/resend-email`)
      .then((response) => {
        setIsLoading(false)
        message.success('Yeah!')
      })
      .catch((e) => {
        setIsLoading(false)
        message.error('Error while sending email')
      })
  }

  if (!invitation) {
    return <></>
  }

  return (
    <Tooltip
      title={intl.formatMessage({
        id: LocalizationKeys.Components.Invitation.ResendEmail,
      })}
    >
      <Button loading={isLoading} onClick={resendInvitationEmail}>
        <ReloadOutlined />
      </Button>
    </Tooltip>
  )
}

export type ResendEmailType = { ResendEmail: typeof ResendEmail }

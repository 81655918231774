import { useIntl } from 'react-intl'
import { useAPIPing } from '../modules/api-ping/hooks'
import { Button, Col, Layout, Row, Space, Typography } from 'antd'
import LocalizationKeys from '../i18n/LocalizationKeys'

const { Text, Title } = Typography
const { Content } = Layout

interface Props {
  children: JSX.Element
}

export const MaintenanceMode = ({ children }: Props) => {
  const intl = useIntl()
  const { app } = useAPIPing()

  if (app.maintenanceMode) {
    return (
      <Layout>
        <Content>
          <Row
            align="middle"
            style={{ minHeight: '100vh', margin: 0, textAlign: 'center' }}
          >
            <Col span={24} style={{ textAlign: 'center' }}>
              <Space direction="vertical" size={25}>
                <Space direction="vertical">
                  <Title level={2}>
                    {intl.formatMessage({
                      id: LocalizationKeys.Components.MaintenanceMode.Title,
                    })}
                  </Title>
                  <Text style={{ textAlign: 'center' }}>
                    {intl.formatMessage({
                      id: LocalizationKeys.Components.MaintenanceMode
                        .Description,
                    })}
                  </Text>
                </Space>
                <img
                  style={{ maxWidth: 250 }}
                  src={'/assets/images/maintenance.svg'}
                ></img>
                <Button type="default" onClick={() => window.location.reload()}>
                  {intl.formatMessage({
                    id: LocalizationKeys.Components.MaintenanceMode.Reload,
                  })}
                </Button>
              </Space>
            </Col>
          </Row>
        </Content>
      </Layout>
    )
  }

  return children
}

import {
  Avatar,
  Button,
  Card,
  Divider,
  Menu,
  Modal,
  Space,
  Spin,
  Typography,
} from 'antd'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../utils/auth/Auth'
import { Role } from '../../components/role/Role'
import { extractSectionFromPath } from '../../utils/helpers/RouterHelpers'
import {
  HistoryOutlined,
  PictureOutlined,
  ToolOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { useAzureSSO } from '../../utils/saml-sso/AzureSSOContext'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { File } from '../../components/file/File'
import { USER_PICTURE_URL } from '../../utils/urls'

const { Title, Text } = Typography

const ProfileSider = () => {
  const {
    isLogged: emailIsLogged,
    logout: emailLogout,
    currentUser,
    setCurrentUser,
  } = useAuth()
  const { isLogged: ssoIsLogged, logout: ssoLogout } = useAzureSSO()

  const intl = useIntl()
  const { pathname } = useLocation()
  const [selectedKey, setSelectedKey] = useState<string>('profile')
  const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false)
  const [logoutModalIsVisible, setLogoutModalIsVisible] =
    useState<boolean>(false)
  const [logoFormIsVisible, setLogoFormIsVisible] = useState<boolean>(false)

  const hasRoles =
    currentUser && currentUser.roles && currentUser.roles.length > 0

  useEffect(() => {
    const menuItemSelected = extractSectionFromPath(pathname, 2)
    setSelectedKey(menuItemSelected)
  }, [pathname])

  return (
    <Card
      styles={{ body: { padding: '30px 15px 15px 15px' } }}
      bordered={false}
    >
      <Space direction="vertical" className="w-full">
        <Space direction="vertical" className="text-center w-full">
          {!logoFormIsVisible && (
            <Avatar
              icon={<UserOutlined />}
              size={50}
              src={currentUser?.profilePicture}
              onClick={() => setLogoFormIsVisible(true)}
            />
          )}
          {logoFormIsVisible && (
            <File.Upload
              icon={<PictureOutlined />}
              title={intl.formatMessage({
                id: LocalizationKeys.Layout.ProfileSider.UploadImage,
              })}
              action={USER_PICTURE_URL}
              accept={'image/*'}
              onSuccessUpload={(result) => {
                setCurrentUser(result.data.data)
                setLogoFormIsVisible(false)
              }}
            />
          )}
          <Space direction="vertical" size={0}>
            <Title level={4} style={{ margin: 0 }}>
              {currentUser?.firstName} {currentUser?.lastName}
            </Title>
            <Text>{currentUser?.email}</Text>
            <Text>{currentUser?.phone}</Text>
            {!currentUser && <Spin />}
            <b>
              {hasRoles &&
                currentUser.roles.map((role) => (
                  <Role.Tag key={role.id} model={role} />
                ))}
            </b>
          </Space>
        </Space>
        <Divider style={{ margin: '20px 0 5px 0' }} />
        <Menu
          mode="vertical"
          selectedKeys={[selectedKey]}
          style={{ border: 0 }}
          items={[
            {
              key: 'profile',
              label: (
                <Link to={'profile'}>
                  {intl.formatMessage({
                    id: LocalizationKeys.Layout.ProfileSider.Informations,
                  })}
                </Link>
              ),
              icon: <UserOutlined />,
            },
            {
              key: 'activity',
              label: (
                <Link to={'activity'}>
                  {intl.formatMessage({
                    id: LocalizationKeys.Layout.ProfileSider.Activity,
                  })}
                </Link>
              ),
              icon: <HistoryOutlined />,
            },
            {
              key: 'preferences',
              label: (
                <Link to={'preferences'}>
                  {intl.formatMessage({
                    id: LocalizationKeys.Layout.ProfileSider.Preferences,
                  })}
                </Link>
              ),
              icon: <ToolOutlined />,
            },
          ]}
        />
        <Divider style={{ margin: '5px 0 2px 0' }} />
        <Button
          className="w-full"
          onClick={() => setLogoutModalIsVisible(true)}
          danger
          size="middle"
          loading={isLoggingOut}
        >
          {intl.formatMessage({
            id: LocalizationKeys.Layout.ProfileSider.Logout,
          })}
        </Button>
      </Space>
      <Modal
        open={logoutModalIsVisible}
        onCancel={() => setLogoutModalIsVisible(false)}
        footer={
          <Button
            onClick={() => {
              setIsLoggingOut(true)
              if (emailIsLogged) {
                emailLogout()
              }
              if (ssoIsLogged) {
                ssoLogout()
              }
            }}
            loading={isLoggingOut}
            type="primary"
            color="danger"
          >
            {intl.formatMessage({
              id: LocalizationKeys.Layout.ProfileSider.Logout,
            })}
          </Button>
        }
      >
        {intl.formatMessage({
          id: LocalizationKeys.Layout.ProfileSider.LogoutConfirmation,
        })}
      </Modal>
    </Card>
  )
}

export default ProfileSider

export interface AlelaPingData {
  app: {
    version: string | undefined
    maintenanceMode: boolean
  }
  user: {}
}

export const initialAlelaPingData: AlelaPingData = {
  app: { version: undefined, maintenanceMode: false },
  user: {},
}

import { Tag as AntdTag, Typography } from 'antd'

import { useAppSelector } from '../../reducers/hooks'
import { Role } from '../../models/Role'
import { selectRoleById } from '../../reducers/RoleReducer'

const { Text } = Typography
interface Props {
  id?: number
  model?: Role
}

export const Option = ({ id, model }: Props) => {
  const role = model ? model : useAppSelector(selectRoleById(id ?? 0))
  return role ? <Text>{role.name}</Text> : <></>
}

export type OptionType = { Option: typeof Option }

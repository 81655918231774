import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIUpdate,
  SBAPIFetch,
} from '../utils/helpers/SBAPIHelper'
import { Station, StationWithRelation } from '../models/Station'
import { STATIONS_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { stationSchema } from '../models/schema'
import { initialQuery } from '../utils/helpers/crud/models'

const initialState: SBState<Station> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: initialQuery,
}

const slice = createSlice({
  name: 'station',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getStationsSuccess,
  setQuery: setStationQuery,
  reset: resetStationState,
  resetQueryAndIds: resetStationQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: Station }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectStations = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedStation = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectStationById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectStationsByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedStation =
  (station: Station | number | undefined) => async (dispatch: AppDispatch) =>
    dispatch(
      slice.actions.setSelectedId(
        station ? getIdOrModelId<Station>(station) : undefined
      )
    )

export const getStations = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<Station>(
    STATIONS_URL,
    params,
    [stationSchema],
    slice.actions
  )

export const getStationWithId = (id: number) =>
  SBAPIFetchDispatch<Station>(
    `${STATIONS_URL}/${id}`,
    stationSchema,
    slice.actions
  )

export const createStation = (station: Station) =>
  SBAPICreate<Station>(station, STATIONS_URL, stationSchema, slice.actions)

export const updateStation = (station: Station) =>
  SBAPIUpdate<Station>(station, `${STATIONS_URL}/${station.id}`, slice.actions)

export const deleteStation = (station: Station) =>
  SBAPIDelete<Station>(station, `${STATIONS_URL}/${station.id}`, slice.actions)

export const searchAirport = (name: string) =>
  SBAPIFetch<StationWithRelation[]>(`${STATIONS_URL}/search/airport`, {
    name: name,
  })

export const searchTrainStation = (name: string) =>
  SBAPIFetch<StationWithRelation[]>(`${STATIONS_URL}/search/train-station`, {
    name: name,
  })

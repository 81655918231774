import ProfilePage from '../../pages/account/ProfilePage'
import ActivityPage from '../../pages/account/ActivityPage'
import { Navigate, Route, Routes } from 'react-router-dom'
import PreferencesPage from '../../pages/account/PreferencesPage'
import { LayoutProfile } from '../../layouts/LayoutProfile'

const ProfileRoutes = () => {
  return (
    <Routes>
      <Route element={<LayoutProfile />}>
        <Route index path="profile" element={<ProfilePage />} />
        <Route path="activity" element={<ActivityPage />} />
        <Route path="preferences" element={<PreferencesPage />} />

        <Route path="*" element={<Navigate to={'profile'} />} />
      </Route>
    </Routes>
  )
}

export default ProfileRoutes

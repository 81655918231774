import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIUpdate,
} from '../utils/helpers/SBAPIHelper'
import { RIDER_TYPES_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { riderTypeSchema } from '../models/schema'
import { initialQuery } from '../utils/helpers/crud/models'
import { RiderType } from '../models/Rider'

export const initialRiderQuery: TableParams = {
  ...initialQuery,
}

const initialState: SBState<RiderType> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: {
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },
}

const slice = createSlice({
  name: 'riderType',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getRidersTypesSuccess,
  setQuery: setRiderTypeQuery,
  reset: resetRiderTypeState,
  resetQueryAndIds: resetRiderTypeQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: RiderType }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectRiderTypes = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedRiderType = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectRiderTypeById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectRiderTypesByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedRiderType =
  (riderType: RiderType | number) => async (dispatch: AppDispatch) =>
    dispatch(slice.actions.setSelectedId(getIdOrModelId<RiderType>(riderType)))

export const getRiderTypes = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<RiderType>(
    RIDER_TYPES_URL,
    params,
    [riderTypeSchema],
    slice.actions
  )

export const createRiderType = (riderType: RiderType) =>
  SBAPICreate<RiderType>(
    riderType,
    RIDER_TYPES_URL,
    riderTypeSchema,
    slice.actions
  )

export const updateRiderType = (riderType: RiderType) =>
  SBAPIUpdate<RiderType>(
    riderType,
    `${RIDER_TYPES_URL}/${riderType.id}`,
    slice.actions
  )

export const deleteRiderType = (riderType: RiderType) =>
  SBAPIDelete<RiderType>(
    riderType,
    `${RIDER_TYPES_URL}/${riderType.id}`,
    slice.actions
  )

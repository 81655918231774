import { Tag } from 'antd'
import { selectContactTypeById } from '../../reducers/ContactTypeReducer'
import { useAppSelector } from '../../reducers/hooks'

interface Props {
  id: number
  closable?: boolean
  onClose?: () => void
}
export const ContactTypeTag = ({ id, closable = false, onClose }: Props) => {
  const type = useAppSelector(selectContactTypeById(id))
  const preventDefault = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    onClose && onClose()
  }

  return type ? (
    <Tag
      key={type.id}
      color={type.color}
      closable={closable}
      onClose={preventDefault}
    >
      {type.name}
    </Tag>
  ) : (
    <></>
  )
}
export type ContactTypeTagType = { TypeTag: typeof ContactTypeTag }

import { setSelectedArtist } from '../../reducers/ArtistReducer'
import { setSelectedBooking } from '../../reducers/BookingReducer'
import { setSelectedBookingType } from '../../reducers/BookingTypeReducer'
import { setSelectedContactAssociation } from '../../reducers/ContactAssociationReducer'
import { setSelectedContact } from '../../reducers/ContactReducer'
import { setSelectedContactType } from '../../reducers/ContactTypeReducer'
import { setSelectedContract } from '../../reducers/ContractReducer'
import { setSelectedContractStatus } from '../../reducers/ContractStatusReducer'
import { setSelectedCurrency } from '../../reducers/CurrencyReducer'
import { setSelectedEvent } from '../../reducers/EventReducer'
import { setSelectedEventStatus } from '../../reducers/EventStatusReducer'
import { setSelectedExternalAdvancingLink } from '../../reducers/ExternalAdvancingLinkReducer'
import { setSelectedFile } from '../../reducers/FileReducer'
import { setSelectedGround } from '../../reducers/GroundReducer'
import { setSelectedHotel } from '../../reducers/HotelReducer'
import { setSelectedInvitation } from '../../reducers/InvitationReducer'
import { setSelectedInvoiceCategory } from '../../reducers/InvoiceCategoryReducer'
import { setSelectedInvoice } from '../../reducers/InvoiceReducer'
import { setSelectedMarketingTask } from '../../reducers/MarketingTaskReducer'
import { setSelectedMarketingTaskStatus } from '../../reducers/MarketingTaskStatusReducer'
import { setSelectedNotification } from '../../reducers/NotificationReducer'
import { setSelectedPayment } from '../../reducers/PaymentReducer'
import { setSelectedPerformance } from '../../reducers/PerformanceReducer'
import { setSelectedPerformanceType } from '../../reducers/PerformanceTypeReducer'
import { setSelectedPublicLink } from '../../reducers/PublicLinkReducer'
import { setSelectedReportItem } from '../../reducers/ReportItemReducer'
import { setSelectedReport } from '../../reducers/ReportReducer'
import { setSelectedRider } from '../../reducers/RiderReducer'
import { setSelectedRiderStatus } from '../../reducers/RiderStatusReducer'
import { setSelectedRiderType } from '../../reducers/RiderTypeReducer'
import { setSelectedRole } from '../../reducers/RoleReducer'
import { setSelectedRoom } from '../../reducers/RoomReducer'
import { setSelectedStage } from '../../reducers/StageReducer'
import { setSelectedStation } from '../../reducers/StationReducer'
import { setSelectedStationType } from '../../reducers/StationTypeReducer'
import { setSelectedSupplier } from '../../reducers/SupplierReducer'
import { setSelectedSupplierType } from '../../reducers/SupplierTypeReducer'
import { setSelectedTask } from '../../reducers/TaskReducer'
import { setSelectedTaskStatus } from '../../reducers/TaskStatusReducer'
import { setSelectedTravel } from '../../reducers/TravelReducer'
import { setSelectedTravelType } from '../../reducers/TravelTypeReducer'
import { setSelectedUser } from '../../reducers/UserReducer'
import { setSelectedVenue } from '../../reducers/VenueReducer'
import { setSelectedVenueType } from '../../reducers/VenueTypeReducer'
import { SBModel } from '../../utils/helpers/crud/models'
import { SBRMType, SBRMTypeInfosType } from './SBRMModel'

export const SBRMTypeInfos: SBRMTypeInfosType<SBModel> = {
  [SBRMType.artist]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedArtist,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'artist',
  },
  [SBRMType.venue]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedVenue,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'venue',
  },
  [SBRMType.venueType]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedVenueType,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },

    reducerName: 'venueType',
  },
  [SBRMType.stage]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedStage,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'stage',
  },
  [SBRMType.event]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedEvent,
    },
    layout: {
      create: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'event',
  },
  [SBRMType.eventStatus]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedEventStatus,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'eventStatus',
  },
  [SBRMType.booking]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedBooking,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      comment: {
        container: 'drawer',
        content: { rightPanelContent: 'comments' },
      },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'booking',
  },
  [SBRMType.bookingType]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedBookingType,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'bookingType',
  },
  [SBRMType.contract]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedContract,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: {
        container: 'drawer',
        content: { rightPanelContent: 'form', leftPanelContent: 'file-viewer' },
      },
      view: {
        container: 'drawer',
        content: { leftPanelContent: 'file-viewer' },
      },
      comment: {
        container: 'drawer',
        content: {
          rightPanelContent: 'comments',
          leftPanelContent: 'file-viewer',
        },
      },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    filePropertyName: 'file',
    reducerName: 'contract',
  },
  [SBRMType.contractStatus]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedContractStatus,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'contractStatus',
  },
  [SBRMType.invoice]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedInvoice,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: {
        container: 'drawer',
        content: { rightPanelContent: 'form', leftPanelContent: 'file-viewer' },
      },
      view: {
        container: 'drawer',
        content: { leftPanelContent: 'file-viewer' },
      },
      comment: {
        container: 'drawer',
        content: {
          leftPanelContent: 'file-viewer',
          rightPanelContent: 'comments',
        },
      },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    filePropertyName: 'files',
    reducerName: 'invoice',
  },
  [SBRMType.invoiceCategory]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedInvoiceCategory,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'invoiceCategory',
  },
  [SBRMType.payment]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedPayment,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    filePropertyName: 'pop',
    reducerName: 'payment',
  },
  [SBRMType.hotel]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedHotel,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'hotel',
  },
  [SBRMType.room]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedRoom,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'modal', content: { mainPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'room',
  },
  [SBRMType.rider]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedRider,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: {
        container: 'drawer',
        content: { rightPanelContent: 'form', leftPanelContent: 'file-viewer' },
      },
      view: {
        container: 'drawer',
        content: { leftPanelContent: 'file-viewer' },
      },
      comment: {
        container: 'drawer',
        content: {
          rightPanelContent: 'comments',
          leftPanelContent: 'file-viewer',
        },
      },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    filePropertyName: 'file',
    reducerName: 'rider',
  },
  [SBRMType.riderStatus]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedRiderStatus,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'riderStatus',
  },
  [SBRMType.riderType]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedRiderType,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'riderStatus',
  },
  [SBRMType.file]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedFile,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: {
        container: 'drawer',
        content: { rightPanelContent: 'form', leftPanelContent: 'file-viewer' },
      },
      view: {
        container: 'drawer',
        content: { leftPanelContent: 'file-viewer' },
      },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    filePropertyName: null,
    reducerName: 'file',
  },
  [SBRMType.user]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedUser,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'user',
  },
  [SBRMType.contact]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedContact,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'contact',
  },
  [SBRMType.contactType]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedContactType,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'contactType',
  },
  [SBRMType.contactAssociation]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedContactAssociation,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'contactAssociation',
  },
  [SBRMType.travel]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedTravel,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'travel',
  },
  [SBRMType.travelType]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedTravelType,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'travelType',
  },
  [SBRMType.ground]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedGround,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'ground',
  },
  [SBRMType.performance]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedPerformance,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'performance',
  },
  [SBRMType.performanceType]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedPerformanceType,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'performanceType',
  },
  [SBRMType.station]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedStation,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'station',
  },
  [SBRMType.stationType]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedStationType,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'stationType',
  },
  [SBRMType.publicLink]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedPublicLink,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    filePropertyName: 'file',
    reducerName: 'publicLink',
  },
  [SBRMType.role]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedRole,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'role',
  },
  [SBRMType.customAddress]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedRole, // TO BE CHANGED
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'address',
  },
  [SBRMType.supplier]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedSupplier,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'supplier',
  },
  [SBRMType.supplierType]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedSupplierType,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
    },
    reducerName: 'supplierType',
  },
  [SBRMType.invitation]: {
    creatable: true,
    updatable: false,
    viewable: false,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedInvitation,
    },
    layout: {
      create: { container: 'modal', content: { mainPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'invitation',
  },
  [SBRMType.notification]: {
    creatable: false,
    updatable: true,
    viewable: false,
    deletable: false,
    duplicable: false,
    methods: {
      setSelected: setSelectedNotification,
    },
    layout: {},
    reducerName: 'notification',
  },
  [SBRMType.report]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedReport,
    },
    layout: {
      comment: {
        container: 'drawer',
        content: { rightPanelContent: 'comments' },
      },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'report',
  },
  [SBRMType.reportItem]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedReportItem,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'reportItem',
  },
  [SBRMType.task]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedTask,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      comment: {
        container: 'drawer',
        content: { rightPanelContent: 'comments' },
      },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'task',
  },
  [SBRMType.taskStatus]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedTaskStatus,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'task',
  },
  [SBRMType.externalAdvancingLink]: {
    creatable: false,
    updatable: false,
    viewable: false,
    deletable: false,
    duplicable: false,
    methods: {
      setSelected: setSelectedExternalAdvancingLink,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      comment: {
        container: 'drawer',
        content: { rightPanelContent: 'comments' },
      },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'externalAdvancingLink',
  },
  [SBRMType.currency]: {
    creatable: false,
    updatable: false,
    viewable: false,
    deletable: false,
    duplicable: false,
    methods: {
      setSelected: setSelectedCurrency,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'currency',
  },
  [SBRMType.marketingTask]: {
    creatable: false,
    updatable: false,
    viewable: false,
    deletable: false,
    duplicable: false,
    methods: {
      setSelected: setSelectedMarketingTask,
    },
    layout: {
      create: {
        container: 'modal',
        content: { mainPanelContent: 'form' },
        closeToSave: true,
      },
      update: {
        container: 'modal',
        content: { mainPanelContent: 'form' },
        closeToSave: true,
      },
      view: { container: 'modal', content: { mainPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'marketingTask',
  },
  [SBRMType.marketingTaskStatus]: {
    creatable: false,
    updatable: false,
    viewable: false,
    deletable: false,
    duplicable: false,
    methods: {
      setSelected: setSelectedMarketingTaskStatus,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'marketingTaskStatus',
  },
}

import {
  Report,
  REPORT_ITEM_TYPE_COST_COLOR as COST_COLOR,
  REPORT_ITEM_TYPE_REVENUE_COLOR as REVENUE_COLOR,
  ReportItem,
} from '../../models/Report'
import { Reporting } from './Reporting'
import { currencyFormat } from '@supplement-bacon/alela-uikit'
import { Card, Col, Empty, Row, Space, Statistic, Typography } from 'antd'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { Weather } from '../weather/Weather'
import {
  computeSumForDonutData,
  extractCostsData,
  extractRevenuesData,
  groupReportItemsById,
} from '../../utils/helpers/ReportHelper'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { useTenantSettings } from '../../utils/hooks/useTenantSettings'

const { Paragraph } = Typography

interface Props {
  reports: Report[]
}

const Overview = ({ reports }: Props) => {
  const intl = useIntl()
  const { currency } = useTenantSettings()

  if (reports.length === 0)
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={intl.formatMessage(
          { id: LocalizationKeys.Components.SBTable.Empty },
          { entity: SBRMType.report }
        )}
      />
    )

  // Reduce all ReportItem in a single array
  const items = reports.reduce((acc: ReportItem[], obj) => {
    return acc.concat(obj.items)
  }, [])

  const groupedItemsByIds = groupReportItemsById(items)
  const costsData = extractCostsData(groupedItemsByIds)
  const costsSum = computeSumForDonutData(costsData)

  const revenueData = extractRevenuesData(groupedItemsByIds)
  const revenueSum = computeSumForDonutData(revenueData)

  const attendeesSum = reports.reduce((sum: number, report: Report) => {
    return sum + report.attendees
  }, 0)

  return (
    <Space size={16} direction="vertical" className="w-full">
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Card style={{ borderColor: '#5d5df6' }}>
            <Statistic
              title={intl.formatMessage({
                id: LocalizationKeys.Components.Reporting.Overview.Margin,
              })}
              value={currencyFormat(revenueSum - costsSum, currency)}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title={intl.formatMessage({
                id: LocalizationKeys.Components.Reporting.Overview.Attendees,
              })}
              value={attendeesSum > 0 ? attendeesSum : '--'}
            />
          </Card>
        </Col>
        {reports.length == 1 && (
          <>
            <Col span={12}>
              {reports[0].weather && (
                <Weather.Card condensed={true} model={reports[0].weather} />
              )}
            </Col>
            <Col span={12}>
              {reports[0].notes && (
                <Card>
                  <Paragraph
                    style={{ marginBottom: 0 }}
                    title={reports[0].notes}
                    ellipsis={{
                      rows: 3,
                      expandable: true,
                      suffix: '',
                      onEllipsis: (ellipsis) => {
                        //
                      },
                    }}
                  >
                    {reports[0].notes}
                  </Paragraph>
                </Card>
              )}
            </Col>
          </>
        )}
      </Row>

      {reports.length > 1 && (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Reporting.Charts />
          </Col>
        </Row>
      )}

      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Card
            title={intl.formatMessage({
              id: LocalizationKeys.Components.Reporting.Overview.Balance,
            })}
          >
            <Reporting.Balance
              datas={[
                {
                  type: intl.formatMessage({
                    id: LocalizationKeys.Components.Reporting.Overview.Costs,
                  }),
                  value: costsSum,
                  color: COST_COLOR,
                },
                {
                  type: intl.formatMessage({
                    id: LocalizationKeys.Components.Reporting.Overview.Revenues,
                  }),
                  value: revenueSum,
                  color: REVENUE_COLOR,
                },
              ]}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            title={intl.formatMessage({
              id: LocalizationKeys.Components.Reporting.Overview.Revenues,
            })}
          >
            <Reporting.Donut
              data={revenueData}
              color={REVENUE_COLOR}
              middle={revenueSum}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            title={intl.formatMessage({
              id: LocalizationKeys.Components.Reporting.Overview.Costs,
            })}
          >
            <Reporting.Donut
              data={costsData}
              color={COST_COLOR}
              middle={costsSum}
            />
          </Card>
        </Col>
      </Row>
    </Space>
  )
}

type OverviewType = { Overview: typeof Overview }

export { Overview, type OverviewType }

import { Card, Rate, Space, Typography } from 'antd'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Hotel } from '../../models/Hotel'
import { Actions } from '../../modules/sbrm/components/Actions'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { extractSortDataFromSorterResult } from '../../utils/table/sorter'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import {
  getHotels,
  selectHotels,
  setHotelQuery,
} from '../../reducers/HotelReducer'
import { addressFormatter } from '../../utils/formatters'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import SBTable from '../../components/SBTable'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { HelpArticle } from '../../utils/hooks/useAlelaHelp'
import { Help } from '../../components/help/Help'

const { Text } = Typography

const HotelsPage = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const hotels = useAppSelector(selectHotels())
  const { query, isLoading } = useAppSelector((state) => state.hotel)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const columns: ColumnsType<Hotel> = [
    {
      key: 'name',
      title: intl.formatMessage({ id: LocalizationKeys.Misc.Table.Name }),
      render: (record: Hotel) => record.name,
    },
    {
      key: 'address',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.Settings.Hotels.Address,
      }),
      render: (record: Hotel) => (
        <Text type="secondary">{addressFormatter(record.address)}</Text>
      ),
    },
    {
      key: 'stars',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.Settings.Hotels.Stars,
      }),
      render: (record: Hotel) => (
        <Rate style={{ fontSize: 14 }} disabled value={record.stars} />
      ),
    },
    {
      key: 'actions',
      align: 'right',
      render: (record: Hotel) => (
        <Actions
          actions={['update', 'delete']}
          entity={SBRMType.hotel}
          entityId={record.id}
        />
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Hotel> | SorterResult<Hotel>[]
  ) => {
    const newQuery = {
      ...query,
      pagination,
      filters,
      ...extractSortDataFromSorterResult(sorter),
    }
    dispatch(setHotelQuery(newQuery))
    dispatch(getHotels(newQuery))
  }

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      return
    }
    setIsFirstRender(false)

    // Trigger when SBRM is closed
    dispatch(getHotels(query))
  }, [dispatch, SBRMIsOpen])

  return (
    <Card
      bordered={false}
      styles={{ body: { padding: 0 } }}
      title={
        <PageTitle
          title={intl.formatMessage({
            id: LocalizationKeys.Page.Settings.Hotels.Title,
          })}
          level={5}
          toolbar={
            <Space>
              <Actions actions={['create']} entity={SBRMType.hotel} />
              <Help.Button article={HelpArticle.AddAndManageHotels} />
            </Space>
          }
        />
      }
    >
      <SBTable
        entity={SBRMType.hotel}
        scroll={{ x: 600 }}
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={hotels}
        pagination={query.pagination}
        loading={isLoading && !hotels.length}
        onChange={handleTableChange}
      />
    </Card>
  )
}

export default HotelsPage

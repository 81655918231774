import { SBModel } from '../utils/helpers/crud/models'
import { Address } from './Address'
import { TRAVEL_TYPE_FLIGHT, TRAVEL_TYPE_TRAIN } from './Travel'

export interface Station extends SBModel {
  name: string
  type: number
  address: Address
}

export interface StationWithRelation extends SBModel {
  name: string
  type: StationType
  address: Address
}

export interface StationType extends SBModel {
  name: string
  color: string
  icon: string
  stationsCount: number
}

export const STATION_TYPE_TRAIN_STATION = TRAVEL_TYPE_TRAIN
export const STATION_TYPE_AIRPORT = TRAVEL_TYPE_FLIGHT

import { useEffect } from 'react'
import { Alert, Card, Col, Row } from 'antd'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { SBActivityLog } from '../../components/SBActivityLog'
import { selectSelectedEvent } from '../../reducers/EventReducer'
import { Actions } from '../../modules/sbrm/components/Actions'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { MetaDataKey } from '../../models/MetaData'
import { TableParams } from '../../models/TableParams'
import { initialQuery } from '../../utils/helpers/crud/models'
import {
  getContacts,
  selectContacts,
  setContactQuery,
} from '../../reducers/ContactReducer'
import { SBRMContactAssociationType } from '../../modules/sbrm/SBRMModel'
import HasAccess from '../../components/HasAccess'
import { AlelaPermission } from '../../utils/permissions'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { Event } from '../../components/event/Event'
import { Contact } from '../../components/contact/Contact'
import { useIntl } from 'react-intl'

const SettingsPage = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const event = useAppSelector(selectSelectedEvent())
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const contacts = useAppSelector(selectContacts())

  const fetchContacts = () => {
    const baseQuery: TableParams = {
      ...initialQuery,
      pagination: { current: 1, pageSize: 100 },
      filters: { events: [event?.id!] },
      with: ['associations'],
    }
    dispatch(setContactQuery(baseQuery))
    dispatch(getContacts(baseQuery))
  }

  useEffect(() => {
    if (SBRMIsOpen || !event) {
      return
    }
    fetchContacts()
  }, [dispatch, SBRMIsOpen])

  return (
    <Row className="container-row">
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <HasAccess permissions={[AlelaPermission.listContactAssociation]}>
            <Col span={24}>
              <PageTitle
                title={intl.formatMessage({
                  id: LocalizationKeys.Page.EventDetails.Contacts,
                })}
                level={5}
                badge={contacts.length}
                toolbar={
                  <Actions
                    actions={['create']}
                    entity={SBRMType.contactAssociation}
                    metadata={[
                      {
                        key: MetaDataKey.contactAssociationType,
                        value: SBRMContactAssociationType.event,
                      },
                      { key: MetaDataKey.eventId, value: event?.id },
                    ]}
                  />
                }
              />
              <Row gutter={[16, 16]}>
                <Col md={24} sm={24} xs={24}>
                  {!contacts.length &&
                    event?.venues?.map((venue, index) => (
                      <Event.AutoImportVenueContacts
                        key={index}
                        eventId={event.id}
                        venueId={venue}
                        onImportFinished={() => {
                          fetchContacts()
                        }}
                      />
                    ))}
                </Col>
                <Col md={24} sm={24} xs={24}>
                  <Contact.TableWithAssociation
                    emptyActionsMetadata={[
                      {
                        key: MetaDataKey.contactAssociationType,
                        value: SBRMContactAssociationType.event,
                      },
                      { key: MetaDataKey.eventId, value: event?.id },
                    ]}
                  />
                </Col>
              </Row>
            </Col>
          </HasAccess>
          <HasAccess permissions={[AlelaPermission.deleteEvent]}>
            <Col span={24}>
              <PageTitle title={'Danger zone'} level={5} />
              <Alert
                message="Delete Event"
                showIcon
                type="error"
                action={
                  <Actions
                    actions={['delete']}
                    entity={SBRMType.event}
                    entityId={event?.id}
                    metadata={[
                      {
                        key: MetaDataKey.deleteWithConfirmation,
                        value: true,
                      },
                    ]}
                  />
                }
              />
            </Col>
          </HasAccess>
          <HasAccess permissions={[AlelaPermission.editEvent]}>
            {event?.activities && event?.activities?.length > 0 && (
              <Col span={24}>
                <PageTitle title={'Activity log'} level={5} />
                <SBActivityLog activities={event?.activities ?? []} />
              </Col>
            )}
          </HasAccess>
        </Row>
      </Col>
    </Row>
  )
}

export default SettingsPage

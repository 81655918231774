import { App, Button, Dropdown, MenuProps, Space, Tooltip, message } from 'antd'
import {
  createPublicLink,
  selectPublicLinksByIds,
} from '../../reducers/PublicLinkReducer'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { File } from '../../models/File'
import relativeTime from 'dayjs/plugin/relativeTime'
import { selectFileById } from '../../reducers/FileReducer'
import { CopyOutlined, ShareAltOutlined } from '@ant-design/icons'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import LocalizationKeys from '../../i18n/LocalizationKeys'

interface Props {
  id?: number
  model?: File
}

export const Share = ({ id, model }: Props) => {
  dayjs.extend(relativeTime)

  const intl = useIntl()
  const { message } = App.useApp()
  const dispatch = useAppDispatch()
  const file = model ? model : useAppSelector(selectFileById(id ?? 0))
  const publicLinks = useAppSelector(
    selectPublicLinksByIds(file?.publicLinks ?? [])
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const generatePublicLink = () => {
    setIsLoading(true)
    dispatch(
      createPublicLink({
        file: file?.id ?? 0,
      })
    )
      .then((_) => {
        // Copy generated link to clipboard
        // Do not use copyLinkToClipboard() as we do not want display success twice
        navigator.clipboard.writeText(publicLinks[0]?.url ?? '').then(() => {
          message.open({
            type: 'success',
            content: intl.formatMessage({
              id: LocalizationKeys.Components.File.Share.PublicLinkGenerated,
            }),
          })
          setIsLoading(false)
        })
      })
      .catch((_) => {
        message.open({
          type: 'error',
          content: intl.formatMessage({
            id: LocalizationKeys.Components.File.Share.PublicLinkError,
          }),
        })
        setIsLoading(false)
      })
  }

  const copyLinkToClipboard = async (link: string) => {
    await navigator.clipboard.writeText(link)
    message.open({
      type: 'success',
      content: intl.formatMessage({
        id: LocalizationKeys.Components.File.Share.PublicLinkCopiedToClipboard,
      }),
    })
  }

  const items: MenuProps['items'] = publicLinks.map((publicLink) => ({
    key: publicLink.id,
    label: intl.formatMessage({
      id: LocalizationKeys.Components.File.Share.CopyPublicLink,
    }),
    onClick: () => copyLinkToClipboard(publicLink?.url ?? ''),
  }))

  return file ? (
    <Space>
      <Tooltip
        title={`${intl.formatMessage({
          id: LocalizationKeys.Components.File.Share.Share,
        })} ${file.name}`}
      >
        <Button
          icon={<ShareAltOutlined />}
          onClick={() => generatePublicLink()}
          loading={isLoading}
        />
      </Tooltip>

      {publicLinks.length === 1 && (
        <Tooltip
          key={publicLinks[0].id}
          title={intl.formatMessage({
            id: LocalizationKeys.Components.File.Share.CopyPublicLink,
          })}
        >
          <Button
            icon={<CopyOutlined />}
            onClick={async () => copyLinkToClipboard(publicLinks[0]?.url ?? '')}
          />
        </Tooltip>
      )}
      {publicLinks.length > 1 && (
        <Dropdown menu={{ items }} placement="bottomRight">
          <Button type="primary" icon={<CopyOutlined />} />
        </Dropdown>
      )}
    </Space>
  ) : (
    <></>
  )
}

export type ShareType = { Share: typeof Share }

import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import { Event } from '../../models/Event'
import { PickerProps } from 'antd/es/date-picker/generatePicker'

interface Props {
  event: Event
  defaultPickerValue?: dayjs.Dayjs
}

const DatePickerWithEventOutlined = (props: Props & PickerProps) => {
  const { event, defaultPickerValue } = props
  return (
    <DatePicker
      defaultPickerValue={defaultPickerValue}
      format="YYYY-MM-DD HH:mm"
      showTime={{ format: 'HH:mm' }}
      className="w-full"
      cellRender={(current, info) => {
        if (info.type !== 'date') return info.originNode

        const isEventDay: boolean =
          event !== undefined &&
          dayjs(current).format('YYYY-MM-DD') ===
            dayjs(event?.start_date).format('YYYY-MM-DD')

        return (
          <div
            className="ant-picker-cell-inner"
            style={
              isEventDay
                ? { border: '1px solid #1677ff', borderRadius: '4' }
                : undefined
            }
          >
            {dayjs(current).date()}
          </div>
        )
      }}
      {...props}
    />
  )
}

export { DatePickerWithEventOutlined }

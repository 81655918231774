import { Button as ANTDButton, Alert, Dropdown, MenuProps } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import useAlelaHelp, { HelpArticle } from '../../utils/hooks/useAlelaHelp'
import { useIntl } from 'react-intl'

interface Props {
  article: HelpArticle | HelpArticle[]
}

export const Button = ({ article }: Props) => {
  const intl = useIntl()
  const help = useAlelaHelp()

  const openBeacon = (article: HelpArticle) =>
    window.Beacon('article', help.articles[article], {
      type: 'modal',
    })

  if (!Array.isArray(article)) {
    return (
      <ANTDButton
        icon={<QuestionCircleOutlined />}
        onClick={() => openBeacon(article)}
      />
    )
  }

  const items: MenuProps['items'] = article.map((article, index) => ({
    key: index,
    label: intl.formatMessage({
      id: article,
    }),
    onClick: () => openBeacon(article),
  }))

  return (
    <Dropdown menu={{ items }} placement="bottomRight">
      <ANTDButton icon={<QuestionCircleOutlined />} />
    </Dropdown>
  )
}

export type ButtonType = { Button: typeof Button }

import React from 'react'
import { EventWithRelation } from '../../models/Event'
import { BookingWithRelation } from '../../models/Booking'
import { ExternalAdvancingLink } from '../../models/ExternalAdvancing'

interface EventWithBookings {
  event: EventWithRelation
  bookings: BookingWithRelation[]
}

interface SectionProps {
  linkId: string
}

enum ALMSectionKey {
  deal = 'deal',
  timetable = 'timetable',
  contract = 'contract',
  invoices = 'invoices',
  travels = 'travels',
  hotels = 'hotels',
  ground = 'ground',
  technical = 'technical',
  partyCrew = 'partyCrew',
}
type ALMSection = {
  key: ALMSectionKey
  icon: React.ReactNode
  itemName: string
  propertyName: keyof ExternalAdvancingLink
  options: ALMSectionOption[]
  hasMaxValue?: boolean
  maxValuePropertyName?: keyof ExternalAdvancingLink
  maxValueItemName?: string
}

type ALMSectionOption = 'view' | 'edit' | 'hidden'

const externalAdvancingSections: ALMSection[] = [
  {
    key: ALMSectionKey.deal,
    icon: '🤝',
    itemName: 'acl_deal',
    propertyName: 'aclDeal',
    options: ['view'],
  },
  {
    key: ALMSectionKey.timetable,
    icon: '⏰',
    itemName: 'acl_timetable',
    propertyName: 'aclTimetable',
    options: ['view'],
  },
  {
    key: ALMSectionKey.contract,
    icon: '📑',
    itemName: 'acl_contract',
    propertyName: 'aclContract',
    options: ['view', 'edit'],
  },
  {
    key: ALMSectionKey.invoices,
    icon: '🧾',
    itemName: 'acl_invoice',
    propertyName: 'aclInvoice',
    options: ['view', 'edit'],
  },
  {
    key: ALMSectionKey.travels,
    icon: '🛩️',
    itemName: 'acl_travel',
    propertyName: 'aclTravel',
    options: ['view', 'edit'],
    hasMaxValue: true,
    maxValuePropertyName: 'limitTravel',
    maxValueItemName: 'limit_travel',
  },
  {
    key: ALMSectionKey.hotels,
    icon: '🛏️',
    itemName: 'acl_hotel',
    propertyName: 'aclHotel',
    options: ['view', 'edit'],
    hasMaxValue: true,
    maxValuePropertyName: 'limitHotels',
    maxValueItemName: 'limit_hotels',
  },
  {
    key: ALMSectionKey.ground,
    icon: '🚐',
    itemName: 'acl_ground',
    propertyName: 'aclGround',
    options: ['view', 'edit'],
    hasMaxValue: true,
    maxValuePropertyName: 'limitGround',
    maxValueItemName: 'limit_ground',
  },
  {
    key: ALMSectionKey.technical,
    icon: '🛠️',
    itemName: 'acl_technical',
    propertyName: 'aclTechnical',
    options: ['view', 'edit'],
  },
  {
    key: ALMSectionKey.partyCrew,
    icon: '👥',
    itemName: 'acl_partyCrew',
    propertyName: 'aclPartyCrew',
    options: ['view', 'edit'],
    hasMaxValue: true,
    maxValuePropertyName: 'limitPartyCrew',
    maxValueItemName: 'limit_partyCrew',
  },
  //   {
  //     key: ALMSectionKey.guestList,
  //     icon: '📋',
  //     itemName: 'acl_guestsList',
  //     propertyName: 'aclGuestsList',
  //     options: ['view', 'edit'],
  //     hasMaxValue: true,
  //     maxValuePropertyName: 'limitGuestsList',
  //     maxValueItemName: 'limit_guestsList',
  //   },
]

export {
  type SectionProps,
  type ALMSection,
  type ALMSectionOption,
  type EventWithBookings,
  ALMSectionKey,
  externalAdvancingSections,
}

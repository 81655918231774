import dayjs from 'dayjs'
import { User } from '../user/User'
import {
  Comment,
  ExternalComment,
  isExternalComment,
  isInternalComment,
} from '../../models/Comment'
import { useAuth } from '../../utils/auth/Auth'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useAppSelector } from '../../reducers/hooks'
import { selectUsers } from '../../reducers/UserReducer'
import { ExternalUser } from '../external-user/ExternalUser'
import { selectCommentById } from '../../reducers/CommentReducer'
import { Comment as UIKitComment } from '@supplement-bacon/alela-uikit'

interface Props {
  id?: number
  model?: Comment | ExternalComment
}

export const CommentCell = ({ id, model }: Props) => {
  dayjs.extend(relativeTime)
  const { currentUser } = useAuth()

  const users = useAppSelector(selectUsers())
  const item = model ? model : useAppSelector(selectCommentById(id!))

  if (!item) return <></>

  const internalUser = isInternalComment(item)
    ? item.user
    : isExternalComment(item)
    ? item.internalUser
    : undefined
  const itsMe = (currentUser?.id ?? 0) === internalUser

  return (
    <UIKitComment.Cell
      item={item}
      itsMe={itsMe}
      users={users}
      internalUserAvatar={(userId) => (
        <User.Avatar id={userId} type="condensed" />
      )}
      externaUserAvatar={(userId) => (
        <ExternalUser.Avatar id={userId} type="condensed" />
      )}
    />
  )
}

export type CommentCellType = { Cell: typeof CommentCell }

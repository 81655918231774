import { Tag as AntdTag } from 'antd'

import { useAppSelector } from '../../reducers/hooks'
import { Role } from '../../models/Role'
import { selectRoleById } from '../../reducers/RoleReducer'

interface Props {
  id?: number
  model?: Role
}

export const Tag = ({ id, model }: Props) => {
  const role = model ? model : useAppSelector(selectRoleById(id ?? 0))

  return role ? <AntdTag>{role.name}</AntdTag> : <></>
}

export type TagType = { Tag: typeof Tag }

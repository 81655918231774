import {
  AzureSSOInit,
  AzureSSOProvider,
} from '../../utils/saml-sso/AzureSSOProvider'
import axios from 'axios'
import { App } from 'antd'
import { Outlet } from 'react-router-dom'
import { setupAxios } from '../../utils/setupAxios'
import { ArgsProps } from 'antd/es/notification/interface'
import { AuthInit, AuthProvider } from '../../utils/auth/Auth'
import getConfigInstance from '../../components/custom-inputs/sb-async-select/config/SBAsyncSelectConfig'
import SBAsyncSelectDefaultConfig from '../../components/custom-inputs/sb-async-select/data/default'
import SBRM from '../../modules/sbrm/SBRM'
import APIPing from '../../modules/api-ping/APIPing'
import NewVersionChecker from '../../components/NewVersionChecker'
import { MaintenanceMode } from '../../components/MaintenanceMode'
import { MobileDisclaimer } from '../../components/MobileDisclaimer'

const LayoutAlelaMainAppInit = () => {
  const { notification: notif } = App.useApp()

  /**
   * Inject interceptors for axios.
   *
   * @see https://github.com/axios/axios#interceptors
   */
  setupAxios(axios, (args: ArgsProps) => {
    notif.error({ ...args, placement: 'bottomRight' })
  })

  const { setValue } = getConfigInstance()
  setValue(SBAsyncSelectDefaultConfig)

  return (
    <AzureSSOProvider>
      <AuthProvider>
        <AuthInit>
          <AzureSSOInit>
            {/* APIPing is injected here as it must be under AuthProvider to access currentTenant */}
            <APIPing>
              <MaintenanceMode>
                <MobileDisclaimer>
                  <>
                    <Outlet />
                    {/* SBRM is injected here as it must be under AuthProvider for hasAccess() to work */}
                    <SBRM />
                    <NewVersionChecker />
                  </>
                </MobileDisclaimer>
              </MaintenanceMode>
            </APIPing>
          </AzureSSOInit>
        </AuthInit>
      </AuthProvider>
    </AzureSSOProvider>
  )
}

export default LayoutAlelaMainAppInit

import { Space, Typography } from 'antd'
import { useAppSelector } from '../../reducers/hooks'
import { addressFormatter } from '../../utils/formatters'
import { selectHotelById } from '../../reducers/HotelReducer'
import { Hotel } from '../../models/Hotel'

const { Text } = Typography
interface Props {
  id?: number
  model?: Hotel
}

export const HotelOption = ({ id, model }: Props) => {
  const item = model ? model : useAppSelector(selectHotelById(id!))

  if (!item) return <></>
  return (
    <Space direction="horizontal" size={10}>
      <Text>{item.name}</Text>
      <Text type="secondary">
        {item.address && addressFormatter(item.address)}
      </Text>
    </Space>
  )
}

export type HotelOptionType = { Option: typeof HotelOption }

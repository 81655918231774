import { useIntl } from 'react-intl'
import { Form, FormInstance, Input } from 'antd'
import { requiredRule } from '../../../utils/rules'
import ColorSelect from '../../../components/custom-inputs/select/ColorSelect'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

interface Props {
  form: FormInstance
}

const Create = ({ form }: Props) => {
  const intl = useIntl()

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="name"
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Name })}
        rules={[requiredRule(intl)]}
      >
        <Input />
      </Form.Item>
      <ColorSelect
        name="color"
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Color })}
        rules={[requiredRule(intl)]}
      />
      <Form.Item
        name="icon"
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Icon })}
      >
        <Input />
      </Form.Item>
    </Form>
  )
}

export default Create

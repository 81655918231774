// @flow
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import type {
  DropResult,
  DraggableLocation,
  DroppableProvided,
  DragUpdate,
} from 'react-beautiful-dnd'
import type { LaneMap } from './types'
import Column from './Column'
import reorder, {
  composeColumnId,
  extractIdFromColumn,
  reorderLaneMap,
} from './utils'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { Lane } from '../../models/Kanban'
import { useKanban } from './context'

const Container = styled.div`
  width: 100%;
  overflow: scroll;
  display: inline-flex;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  padding: 15px;
`

type Props<T> = {
  withScrollableColumns?: boolean
  isCombineEnabled?: boolean
  containerHeight?: string
  useClone?: boolean
}

export const Board = <T,>({
  withScrollableColumns,
  isCombineEnabled = false,
  containerHeight,
  useClone,
}: Props<T>) => {
  const { columns, ordered, setColumns, setOrdered, itemMoved } = useKanban()

  const onDragEnd = (result: DropResult) => {
    if (result.combine) {
      if (result.type === 'COLUMN') {
        const shallow: string[] = [...ordered]
        shallow.splice(result.source.index, 1)
        setOrdered(shallow)
        return
      }

      //   const column: Quote[] = columns[result.source.droppableId]
      //   const withQuoteRemoved: Quote[] = [...column]
      //   withQuoteRemoved.splice(result.source.index, 1)
      //   const nextColumns: QuoteMap = {
      //     ...columns,
      //     [result.source.droppableId]: withQuoteRemoved,
      //   }
      //   setColumns(nextColumns)
      return
    }

    // dropped nowhere
    if (!result.destination) {
      return
    }

    const source: DraggableLocation = result.source
    const destination: DraggableLocation = result.destination

    // did not move anywhere - can bail early
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return
    }

    // reordering column
    if (result.type === 'COLUMN') {
      const nextOrdered: string[] = reorder(
        ordered,
        source.index,
        destination.index
      )

      setOrdered(nextOrdered)

      return
    }

    //Emit event about the change
    itemMoved(
      result.draggableId,
      extractIdFromColumn(destination.droppableId),
      destination.index
    )

    const data = reorderLaneMap({
      laneMap: columns,
      source,
      destination,
    })

    setColumns(data.laneMap)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="board"
        type="COLUMN"
        direction="horizontal"
        ignoreContainerClipping={Boolean(containerHeight)}
        isCombineEnabled={isCombineEnabled}
      >
        {(provided: DroppableProvided) => (
          <Container ref={provided.innerRef} {...provided.droppableProps}>
            {ordered.map((laneId: string, index: number) => (
              <Column
                key={laneId}
                index={index}
                lane={columns[laneId]}
                isScrollable={withScrollableColumns}
                isCombineEnabled={isCombineEnabled}
                useClone={useClone}
              />
            ))}
            {provided.placeholder}
          </Container>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export type BoardType = { Board: typeof Board }

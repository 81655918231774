import { Rule } from 'antd/es/form'
import { IntlShape } from 'react-intl'
import dayjs, { Dayjs } from 'dayjs'
import { isValidPhoneNumber } from 'react-phone-number-input'
import LocalizationKeys from '../i18n/LocalizationKeys'

export const integerRule: Rule = {
  type: 'integer',
  message: 'The input is not valid number',
}

export const minLengthRule = function (length: number, intl: IntlShape): Rule {
  return {
    pattern: new RegExp(`.{${length.toString()},}`, 'g'),
    message: intl.formatMessage(
      { id: LocalizationKeys.Misc.Error.MinLength },
      { length: length }
    ),
  }
}

export const capsRule = function (intl: IntlShape): Rule {
  return {
    pattern: new RegExp(/[A-Z]+/g),
    message: intl.formatMessage({ id: LocalizationKeys.Misc.Error.Caps }),
  }
}
export const lowercaseRule = function (intl: IntlShape): Rule {
  return {
    pattern: new RegExp(/[a-z]+/g),
    message: intl.formatMessage({ id: LocalizationKeys.Misc.Error.Lowercase }),
  }
}

export const containsNumberRule = function (intl: IntlShape): Rule {
  return {
    pattern: new RegExp(/\d+/g),
    message: intl.formatMessage({
      id: LocalizationKeys.Misc.Error.ContainsNumber,
    }),
  }
}
export const containsSymbolRule = function (intl: IntlShape): Rule {
  return {
    pattern: new RegExp(/\W+/g),
    message: intl.formatMessage({
      id: LocalizationKeys.Misc.Error.ContainsSymbol,
    }),
  }
}

export const numberRule = function (intl: IntlShape): Rule {
  return {
    type: 'number',
    message: intl.formatMessage({ id: LocalizationKeys.Misc.Error.TypeNumber }),
  }
}

export const stringRule = function (intl: IntlShape): Rule {
  return {
    type: 'string',
    message: intl.formatMessage({ id: LocalizationKeys.Misc.Error.TypeString }),
  }
}

export const urlRule = function (intl: IntlShape): Rule {
  return {
    type: 'url',
    message: intl.formatMessage({ id: LocalizationKeys.Misc.Error.TypeLink }),
  }
}

export const emailRule = function (intl: IntlShape): Rule {
  return {
    type: 'email',
    message: intl.formatMessage({ id: LocalizationKeys.Misc.Error.TypeEmail }),
  }
}

export const requiredRule = function (intl: IntlShape): Rule {
  return {
    required: true,
    message: intl.formatMessage({ id: LocalizationKeys.Misc.Error.Require }),
  }
}

export const acceptedRule = function (intl: IntlShape): Rule {
  return {
    validator(_, value) {
      if (value) {
        return Promise.resolve()
      }
      return Promise.reject(
        intl.formatMessage({ id: LocalizationKeys.Misc.Error.Require })
      )
    },
  }
}

export const nestedCascaderExceptAddressRule = function (
  intl: IntlShape,
  minDepth: number = 2
): Rule {
  return {
    validator(_, value) {
      if (value && value.length === 1 && value[0] === 'customaddress') {
        return Promise.resolve()
      }
      return value.length >= minDepth
        ? Promise.resolve()
        : Promise.reject(
            intl.formatMessage({ id: LocalizationKeys.Misc.Error.Require })
          )
    },
  }
}

export const validPhoneNumberRule = function (intl: IntlShape): Rule {
  return ({ getFieldValue }) => ({
    validator(_, value: any) {
      if (!value) {
        // In case there is no value we return success
        // This rule only checks if the input is valid
        return Promise.resolve()
      }
      return isValidPhoneNumber(value)
        ? Promise.resolve()
        : Promise.reject(
            new Error(
              intl.formatMessage({
                id: LocalizationKeys.Misc.Error.NotValidPhone,
              })
            )
          )
    },
  })
}

export const matchValueRule = (
  intl: IntlShape,
  expectedValue: string
): Rule => ({
  validator(_rule: any, value: string) {
    if (value === expectedValue) {
      return Promise.resolve()
    }
    return Promise.reject(
      new Error(
        intl.formatMessage({
          id: LocalizationKeys.Misc.Error.MismatchValue,
        })
      )
    )
  },
})

export const notMatchValuesRule = (
  intl: IntlShape,
  reserved: string[]
): Rule => ({
  validator(_rule: any, value: string) {
    if (reserved.includes(value)) {
      return Promise.reject(new Error('This value is reserved'))
    }
    return Promise.resolve()
  },
})

export const lessOneIsChecked = (intl: IntlShape, array: any[]): Rule => ({
  validator(_rule: any, value: string) {
    if (array.length > 0) {
      return Promise.resolve()
    }
    return Promise.reject(
      new Error(
        intl.formatMessage({
          id: LocalizationKeys.Misc.Error.MismatchValue,
        })
      )
    )
  },
})

import { CreateReport } from '../../models/Report'
import { selectReportItems } from '../../reducers/ReportItemReducer'
import { useAppSelector } from '../../reducers/hooks'
import { Reporting } from './Reporting'
import { createReportToReport } from './wizard/utils'

interface Props {
  reportData: CreateReport
}
const Preview = ({ reportData }: Props) => {
  const items = useAppSelector(selectReportItems())
  const report = createReportToReport(reportData, items)

  return (
    <div style={{ marginTop: 24, marginBottom: 24 }}>
      <Reporting.Overview reports={[report]} />
    </div>
  )
}

type PreviewType = { Preview: typeof Preview }

export { Preview, type PreviewType }

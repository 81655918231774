import { Card, Divider, Flex, Progress, Space, Tooltip, Typography } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { TENANT_USAGE_URL } from '../../utils/urls'
import { TenantUsage } from '../../models/Tenant'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'

const { Text } = Typography

const Usage: React.FC = () => {
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [usages, setUsages] = useState<TenantUsage[]>([])

  useEffect(() => {
    setIsLoading(true)
    axios
      .get(TENANT_USAGE_URL)
      .then((response) => {
        const usage = response.data.data as TenantUsage[]
        setUsages(usage)
      })
      .finally(() => setIsLoading(false))
  }, [])

  return (
    <Card loading={isLoading}>
      <Space direction="horizontal">
        {usages.map((usage) => (
          <UsageItem
            key={usage.key}
            title={intl.formatMessage({
              id: LocalizationKeys.Components.Organization.Usage[usage.key],
            })}
            percent={usage.percent}
            color={
              usage.percent > 80
                ? 'orange'
                : usage.percent > 90
                ? 'red'
                : 'green'
            }
            tooltip={intl.formatMessage(
              {
                id: LocalizationKeys.Components.Organization.Usage.Description,
              },
              { used: usage.used, quota: usage.quota }
            )}
          />
        ))}
      </Space>
    </Card>
  )
}

interface UsageItemProps {
  title: React.ReactNode
  tooltip: React.ReactNode
  percent: number
  color: string
}

const UsageItem: React.FC<UsageItemProps> = ({
  title,
  percent,
  tooltip,
  color,
}) => (
  <Tooltip title={tooltip}>
    <Space direction="vertical" style={{ textAlign: 'center' }}>
      <Progress percent={percent} type="dashboard" strokeColor={color} />
      <Text>{title}</Text>
    </Space>
  </Tooltip>
)

type UsageType = { Usage: typeof Usage }

export { Usage, type UsageType }

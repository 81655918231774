import { Empty } from 'antd'
import { MetaData } from '../models/MetaData'
import Table, { TableProps } from 'antd/es/table'
import { useAppSelector } from '../reducers/hooks'
import { SBRMType } from '../modules/sbrm/SBRMModel'
import { Actions } from '../modules/sbrm/components/Actions'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../i18n/LocalizationKeys'

const SBTable = <RecordType extends object = any>(
  props: React.PropsWithChildren<
    TableProps<RecordType> & {
      entity: SBRMType
      emptyActionsMetadata?: MetaData[]
      hideCreateButton?: boolean
    }
  >
) => {
  const intl = useIntl()
  const { isOpen: nestedIsOpen } = useAppSelector((state) => state.SBRM.nested)

  return (
    <Table
      {...props}
      locale={{
        ...props.locale,
        emptyText: !props.loading && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={intl.formatMessage(
              { id: LocalizationKeys.Components.SBTable.Empty },
              { entity: intl.formatMessage({ id: `Entity-${props.entity}` }) }
            )}
          >
            {/* Testing hideCreateButton like that ensure to hide the button only when the parameter is specified */}
            {!nestedIsOpen && !(props.hideCreateButton === true) && (
              <Actions
                actions={['create']}
                entity={props.entity}
                metadata={props.emptyActionsMetadata}
              />
            )}
          </Empty>
        ),
      }}
    />
  )
}

export default SBTable

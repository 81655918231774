import dayjs, { Dayjs } from 'dayjs'
import { useEffect } from 'react'
import { useAppSelector } from '../../reducers/hooks'
import { TableParams } from '../../models/TableParams'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { SBAsyncSelect } from '../custom-inputs/sb-async-select/SBAsyncSelect'
import { Button, Col, DatePicker, Form, Row, Switch } from 'antd'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'

const { RangePicker } = DatePicker

interface Props {
  queryChanged: (query: TableParams) => void
}

export const Filters = ({ queryChanged }: Props) => {
  const intl = useIntl()
  const [form] = Form.useForm()

  const { query } = useAppSelector((state) => state.report)

  const initialDateStart = dayjs().subtract(90, 'd')
  const initialDateEnd = dayjs()
  const rangePresets: {
    label: string
    value: [Dayjs, Dayjs]
  }[] = [
    {
      label: intl.formatMessage({
        id: LocalizationKeys.Components.Invoice.Filters.Last7Days,
      }),
      value: [dayjs().subtract(7, 'd'), dayjs()],
    },
    {
      label: intl.formatMessage({
        id: LocalizationKeys.Components.Invoice.Filters.Last14Days,
      }),
      value: [dayjs().subtract(14, 'd'), dayjs()],
    },
    {
      label: intl.formatMessage({
        id: LocalizationKeys.Components.Invoice.Filters.Last30Days,
      }),
      value: [dayjs().subtract(30, 'd'), dayjs()],
    },
    {
      label: intl.formatMessage({
        id: LocalizationKeys.Components.Invoice.Filters.Last90Days,
      }),
      value: [dayjs().subtract(90, 'd'), dayjs()],
    },
    {
      label: intl.formatMessage({
        id: LocalizationKeys.Components.Invoice.Filters.Last1Year,
      }),
      value: [dayjs().subtract(365, 'd'), dayjs()],
    },
  ]

  const composeFilteredQuery = (): void => {
    /**
     * Always defining both attributes to ensure no past value will be kept
     */
    const dates = form.getFieldValue('filterDates')
    const filteredQuery: TableParams = {
      ...query,
      pagination: { current: 1, pageSize: 1000 },
      filters: {
        ...query.filters,
        eventDate: {
          start: dates
            ? dates[0].format('YYYY-MM-DD')
            : initialDateStart.format('YYYY-MM-DD'),
          end: dates
            ? dates[1].format('YYYY-MM-DD')
            : initialDateEnd.format('YYYY-MM-DD'),
        } as any,
        venues: form.getFieldValue('venues')
          ? form.getFieldValue('venues')
          : undefined,
      },
      with: ['event'],
      columnKey: 'eventDate',
      order: 'ascend',
    }

    queryChanged(filteredQuery)
  }

  const handleFormChange = (changes: any, values: any) => {
    composeFilteredQuery()
  }

  useEffect(() => {
    // Triger queryChanged() on init
    composeFilteredQuery()
  }, [])

  return (
    <Form
      className="hide-mobile"
      layout="vertical"
      form={form}
      initialValues={{
        filterDates: [initialDateStart, initialDateEnd],
        venues: [],
      }}
      onValuesChange={handleFormChange}
    >
      <Row gutter={[16, 16]} wrap={false} style={{ justifyContent: 'end' }}>
        <Col span={6}>
          <SBAsyncSelect
            name={'venues'}
            multiple
            type={SBRMType.venue}
            style={{ width: '100%' }}
            size="middle"
            placeholder="Select"
            outsideSBRM
          />
        </Col>
        <Col span={'auto'}>
          <Form.Item>
            <Form.Item noStyle name={'filterDates'} label="">
              <RangePicker format="DD-MM-YYYY" presets={rangePresets} />
            </Form.Item>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export type FiltersType = { Filters: typeof Filters }

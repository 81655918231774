import { Tag as AntdTag } from 'antd'
import { useAppSelector } from '../../reducers/hooks'
import { TaskStatus } from '../../models/TaskStatus'
import { selectTaskStatusById } from '../../reducers/TaskStatusReducer'

interface Props {
  id?: number
  model?: TaskStatus
}

export const Tag = ({ id, model }: Props) => {
  const status = model ? model : useAppSelector(selectTaskStatusById(id ?? 0))

  return status ? <AntdTag color={status.color}>{status.name}</AntdTag> : <></>
}

export type TagType = { Tag: typeof Tag }

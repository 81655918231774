import {
  GroundLocationWithRelation,
  GroundWithRelation,
} from '../../../models/Ground'
import dayjs from 'dayjs'
import Table from './Table'
import ContactTag from './ContactTag'
import { commonStyle } from './style'
import { SBRMType } from '../../sbrm/SBRMModel'
import { Text, View } from '@react-pdf/renderer'
import { addressFormatter } from '../../../utils/formatters'

interface Props {
  data: GroundWithRelation[]
}

const GroundTable = ({ data }: Props) => {
  const renderSwitch = (groundLocation: GroundLocationWithRelation) => {
    switch (groundLocation.type) {
      case SBRMType.travel:
        return (
          groundLocation.travel &&
          (groundLocation.travel.inbound ? (
            <View style={commonStyle.spaceVertical}>
              <Text>{groundLocation.travel?.arrival.name}</Text>
            </View>
          ) : (
            <View style={commonStyle.spaceVertical}>
              <Text>{groundLocation.travel?.departure.name}</Text>
            </View>
          ))
        )
      case SBRMType.venue:
        return (
          groundLocation.venue && (
            <View style={commonStyle.spaceVertical}>
              <Text>{groundLocation.venue?.name}</Text>
              <Text>{addressFormatter(groundLocation.venue?.address)}</Text>
            </View>
          )
        )
      case SBRMType.hotel:
        return (
          groundLocation.hotel && (
            <View style={commonStyle.spaceVertical}>
              <Text>{groundLocation.hotel?.name}</Text>
              <Text>{addressFormatter(groundLocation.hotel?.address)}</Text>
            </View>
          )
        )
      case SBRMType.customAddress:
        return (
          groundLocation.customaddress && (
            <View style={commonStyle.spaceVertical}>
              <Text>{groundLocation.customaddress.address.label}</Text>
              <Text>
                {addressFormatter(groundLocation.customaddress.address)}
              </Text>
            </View>
          )
        )
      default:
        return 'Error'
    }
  }

  const GroundCols = [
    {
      title: 'From',
      key: 'from',
      render: (record: GroundWithRelation) => (
        <View style={commonStyle.spaceVertical}>
          {record.from && renderSwitch(record.from)}
          <Text>{dayjs(record.pickupTime).format('DD/MM HH:mm')}</Text>
        </View>
      ),
    },
    {
      title: 'To',
      key: 'to',
      render: (record: GroundWithRelation) => (
        <View style={commonStyle.spaceVertical}>
          {record.to && renderSwitch(record.to)}
          <Text>
            {dayjs(record.pickupTime)
              .add(record.duration, 'minute')
              .format('DD/MM HH:mm')}
          </Text>
        </View>
      ),
    },
    {
      title: 'Driver',
      key: 'driver',
      render: (record: GroundWithRelation) => (
        <View style={commonStyle.spaceHorizontal}>
          <View style={[commonStyle.spaceVertical, { gap: 3 }]}>
            {record.driver && <ContactTag contact={record.driver} />}
            <Text>{record.vehicleDetails}</Text>
          </View>
        </View>
      ),
    },
    {
      title: 'Passengers',
      key: 'passengers',
      render: (record: GroundWithRelation) => (
        <View style={commonStyle.spaceHorizontal}>
          {record.passengers &&
            record.passengers.map((passenger) => (
              <ContactTag key={passenger.id} contact={passenger} />
            ))}
        </View>
      ),
    },
  ]

  return <Table columns={GroundCols} data={data} />
}

export default GroundTable

import {
  getAccountUserWithId,
  selectSelectedUser,
  setSelectedUser,
} from '../../reducers/UserReducer'
import {
  getContactWithId,
  selectContactById,
} from '../../reducers/ContactReducer'
import {
  getContactAssociations,
  resetContactAssociationQueryAndIds,
  setContactAssociationQuery,
} from '../../reducers/ContactAssociationReducer'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { User } from '../../components/user/User'
import HasAccess from '../../components/HasAccess'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { TableParams } from '../../models/TableParams'
import { AlelaPermission } from '../../utils/permissions'
import { useNavigate, useParams } from 'react-router-dom'
import { Contact } from '../../components/contact/Contact'
import { initialQuery } from '../../utils/helpers/crud/models'
import { ArrowLeftOutlined, UserOutlined } from '@ant-design/icons'
import { SBCauserActivityLog } from '../../components/SBActivityLog'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { Row, Col, Button, Space, Spin, Typography, Card, Avatar } from 'antd'
import { ContactAssociation } from '../../components/contact-association/ContactAssociation'
import LocalizationKeys from '../../i18n/LocalizationKeys'

const { Text, Title } = Typography

const UserPage = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { userId } = useParams<{ userId: string }>()

  const dispatch = useAppDispatch()

  const user = useAppSelector(selectSelectedUser())
  const contact = useAppSelector(selectContactById(user?.contact ?? 0))
  const { isOpen: SBRMisOpen, action } = useAppSelector((state) => state.SBRM)
  const { isLoading: contactAssociationIsLoading } = useAppSelector(
    (state) => state.contactAssociation
  )
  const { isLoading: contactIsLoading } = useAppSelector(
    (state) => state.contact
  )

  useEffect(() => {
    if (isNaN(userId as unknown as number)) {
      navigate('/crm/organization-team')
    }
    dispatch(resetContactAssociationQueryAndIds(initialQuery))

    dispatch(setSelectedUser(Number(userId)))
    dispatch(getAccountUserWithId(Number(userId)))
  }, [userId])

  useEffect(() => {
    if (!contact) {
      return
    }
    const baseQuery: TableParams = {
      ...initialQuery,
      filters: { contacts: [contact.id], associationTypes: ['event'] },
    }
    dispatch(setContactAssociationQuery(baseQuery))
    dispatch(getContactAssociations(baseQuery))
  }, [contact])

  useEffect(() => {
    if (SBRMisOpen || action !== 'delete') {
      return
    }
    /*
     * When the user is updated via the Actions edit button
     * We do not have a useEffect with getAccountUserWithId
     * As the store will be updated locally
     *
     * However if the user is deleted we need to redirect on users list
     * So we need to do the request and catch error
     */
    dispatch(getAccountUserWithId(Number(userId))).catch((e) => {
      if (e.error.response.status == 404) {
        navigate('/crm/organization-team')
      }
    })
  }, [SBRMisOpen])

  if (!user) {
    return (
      <>
        <Spin />
        <Text>
          {intl.formatMessage({
            id: LocalizationKeys.Page.CRM.User.Loading,
          })}
        </Text>
      </>
    )
  }

  return (
    <>
      <HasAccess permissions={[AlelaPermission.viewUser]}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Button
              type="text"
              icon={<ArrowLeftOutlined />}
              onClick={() => navigate(-1)}
            >
              {intl.formatMessage({
                id: LocalizationKeys.Page.CRM.User.Back,
              })}
            </Button>
          </Col>
          <Col span={6}>
            <Card bordered={false}>
              <Space direction="vertical" className="text-center w-full">
                <div style={{ textAlign: 'center' }}>
                  <User.Avatar id={user.id} type="condensed" size="large" />
                </div>
                <Space direction="vertical" size={0} wrap={true}>
                  <Title level={4} style={{ margin: 0 }}>
                    {user.firstName} {user.lastName}
                  </Title>
                  <Link to={`mailto:${user.email}`} style={{ color: '#FFF' }}>
                    <Text>{user.email}</Text>
                  </Link>
                  <User.EditDeleteButtons user={user} />
                </Space>
              </Space>
            </Card>
          </Col>
          <Col span={18}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <PageTitle
                  title={intl.formatMessage({
                    id: LocalizationKeys.Page.CRM.User.AssociatedContact,
                  })}
                  level={5}
                />
                <Card bordered={false}>
                  {contactIsLoading && <Spin />}
                  {!user.contact && !contactIsLoading && (
                    <User.AssociateContact
                      user={user}
                      contactAssociated={(contactId) => {
                        if (!contact) {
                          /**
                           * On contact association the locale store user.contact is updated immediately
                           * So contact will be non-null if we already have the contact in the store
                           * In case we don't have the contact we need to fetch the API
                           */
                          dispatch(getContactWithId(contactId))
                        }
                      }}
                    />
                  )}
                  {user.contact && !contactIsLoading && (
                    <Contact.Avatar
                      id={user.contact}
                      size={'large'}
                      editable={false}
                      linkToDetails
                    />
                  )}
                </Card>
              </Col>
              {contact && (
                <Col span={24}>
                  <PageTitle
                    title={intl.formatMessage({
                      id: LocalizationKeys.Page.CRM.User.Events,
                    })}
                    level={5}
                    toolbar={contactAssociationIsLoading && <Spin />}
                  />
                  <ContactAssociation.TableWithResource />
                </Col>
              )}
              <Col span={24}>
                <PageTitle
                  title={intl.formatMessage({
                    id: LocalizationKeys.Page.CRM.User.Activities,
                  })}
                  level={5}
                />
                <Card styles={{ body: { padding: 0 } }} bordered={false}>
                  <SBCauserActivityLog
                    maxHeight={600}
                    activities={user.activities ?? []}
                  />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </HasAccess>
    </>
  )
}

export default UserPage

import { FC } from 'react'
import { AssociateContact, AssociateContactType } from './AssociateContact'
import { EditDeleteButtons, EditDeleteButtonsType } from './EditDeleteButtons'
import { UserAvatar, UserAvatarType } from './Avatar'
import { UserForm, UserFormType } from './form/Form'

export const User: FC &
  EditDeleteButtonsType &
  UserAvatarType &
  AssociateContactType &
  UserFormType = (): JSX.Element => <></>

User.EditDeleteButtons = EditDeleteButtons
User.Avatar = UserAvatar
User.AssociateContact = AssociateContact
User.Form = UserForm

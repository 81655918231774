import { Tag } from 'antd'
import { useAppSelector } from '../../reducers/hooks'
import { RiderStatus as RiderStatusModel } from '../../models/Rider'
import { selectRiderTypeById } from '../../reducers/RiderTypeReducer'

interface Props {
  id?: number
  model?: RiderStatusModel
}

export const RiderTypeTag = ({ id, model }: Props) => {
  const status = model ? model : useAppSelector(selectRiderTypeById(id ?? 0))

  return status ? <Tag color={status.color}>{status.name}</Tag> : <></>
}

export type RiderTypeTagType = { Tag: typeof RiderTypeTag }

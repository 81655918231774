import { useIntl } from 'react-intl'
import { DatePicker, Form, FormInstance, Input } from 'antd'
import { requiredRule, stringRule } from '../../../utils/rules'
import { useAppSelector } from '../../../reducers/hooks'
import { useEffect } from 'react'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { SBAsyncSelect } from '../../../components/custom-inputs/sb-async-select/SBAsyncSelect'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import { selectSelectedTask } from '../../../reducers/TaskReducer'
import TextArea from 'antd/es/input/TextArea'
import dayjs from 'dayjs'

interface Props {
  form: FormInstance
}

const Update = ({ form }: Props) => {
  const intl = useIntl()

  const item = useAppSelector(selectSelectedTask())

  useEffect(() => {
    form.setFieldsValue(item)
    item?.dueDate && form.setFieldValue('dueDate', dayjs(item?.dueDate))
  }, [item])

  if (item === undefined) {
    return <></>
  }

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="id"
        rules={[requiredRule(intl)]}
        style={{ display: 'none' }}
      >
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        name="title"
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Title })}
        rules={[requiredRule(intl), stringRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="description"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Description,
        })}
        rules={[stringRule(intl)]}
      >
        <TextArea rows={5} />
      </Form.Item>
      <Form.Item
        name="dueDate"
        rules={[]}
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.DueDate,
        })}
      >
        <DatePicker format="YYYY-MM-DD" className="w-full" />
      </Form.Item>
      <SBAsyncSelect
        type={SBRMType.taskStatus}
        name={'status'}
        rules={[requiredRule(intl)]}
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Status,
        })}
      />
      <SBAsyncSelect
        type={SBRMType.user}
        name={'assignee'}
        rules={[]}
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Assignee,
        })}
      />
    </Form>
  )
}

export default Update

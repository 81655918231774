import {
  Alert,
  Button,
  Card,
  Col,
  Row,
  Skeleton,
  Slider,
  Space,
  Statistic,
  Typography,
} from 'antd'
import { useEffect, useState } from 'react'
import { TENANT_PLAN_URL } from '../../utils/urls'
import axios from 'axios'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { CreditCardOutlined, UndoOutlined } from '@ant-design/icons'
import { Organization } from './Organization'
import dayjs from 'dayjs'
import { PageTitle } from '@supplement-bacon/alela-uikit'

const { Text } = Typography

interface TenantPlan {
  name: string
  planPaidUsers?: number
  trialEndsAt?: string
  hasDefaultPaymentMethod: boolean
  prices: {
    from: number
    to: number
    price: number
  }[]
  sliderMarks: {
    position: number
    label: string
  }[]
}

export const Plan = () => {
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [sliderValue, setSliderValue] = useState(1)
  const [tenantPlan, setTenantPlan] = useState<TenantPlan | null>(null)

  // The property marks of the Slider doesn't accept an array. We transform here the sliderMarks into an object.
  const sliderMarks = {
    ...tenantPlan?.sliderMarks.reduce((marks, mark) => {
      marks[mark.position] = mark.label
      return marks
    }, {} as Record<string, string>),
    [tenantPlan?.planPaidUsers ?? 0]: intl.formatMessage({
      id: LocalizationKeys.Components.Organization.Plan.YourOrganization,
    }),
  }

  useEffect(() => {
    setIsLoading(true)
    axios
      .get(TENANT_PLAN_URL)
      .then((response) => {
        const plan = response.data.data as TenantPlan
        setTenantPlan(plan)
        setSliderValue(plan?.planPaidUsers ?? 1)
        setIsLoading(false)
      })
      .catch((error) => setIsLoading(false))
  }, [])

  if (isLoading) {
    return (
      <>
        <PageTitle
          title={intl.formatMessage({
            id: LocalizationKeys.Page.Organization.BillingInfos,
          })}
        />
        <Space direction="vertical" style={{ width: '100%' }}>
          <Skeleton title={false} paragraph={{ rows: 1, width: '100%' }} />
        </Space>
        <PageTitle
          title={intl.formatMessage({
            id: LocalizationKeys.Page.Organization.Plan,
          })}
        />
        <Space direction="vertical" style={{ width: '100%' }}>
          <Row gutter={16}>
            {[...Array(3)].map((_, index) => (
              <Col key={index} span={8}>
                <Skeleton active />
              </Col>
            ))}
          </Row>
          <Skeleton title={false} paragraph={{ rows: 1, width: '100%' }} />
        </Space>
      </>
    )
  }

  return (
    <>
      {tenantPlan?.trialEndsAt !== undefined &&
        dayjs(tenantPlan.trialEndsAt).isAfter(dayjs()) && (
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Alert
                banner
                type="warning"
                message={intl.formatMessage({
                  id: LocalizationKeys.Components.Organization.Plan
                    .TrialsEndsOn,
                })}
                description={
                  <Text>
                    {intl.formatMessage({
                      id: LocalizationKeys.Components.Organization.Plan
                        .TrialsEndsOnDescription,
                    })}{' '}
                    <Text strong>
                      {dayjs(tenantPlan.trialEndsAt).format('DD/MM/YYYY')}
                    </Text>
                  </Text>
                }
              />
            </Col>
          </Row>
        )}

      <PageTitle
        title={intl.formatMessage({
          id: LocalizationKeys.Page.Organization.BillingInfos,
        })}
      />
      <Card bordered={false}>
        <Space direction="vertical" size={20}>
          <Text>
            {intl.formatMessage({
              id: LocalizationKeys.Components.Organization.Plan
                .ToEditGoToPortal,
            })}
          </Text>
          {tenantPlan?.hasDefaultPaymentMethod === false && (
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Alert
                  banner
                  type="error"
                  icon={<CreditCardOutlined />}
                  message={intl.formatMessage({
                    id: LocalizationKeys.Components.Organization.Plan
                      .NoDefaultPaymentMethod,
                  })}
                  description={
                    <Space direction="vertical">
                      <Text>
                        {intl.formatMessage({
                          id: LocalizationKeys.Components.Organization.Plan
                            .NoDefaultPaymentMethodDescription,
                        })}
                      </Text>
                      <Organization.StripePortalButton />
                    </Space>
                  }
                />
              </Col>
            </Row>
          )}
          {tenantPlan?.hasDefaultPaymentMethod === true && (
            <Organization.StripePortalButton />
          )}
        </Space>
      </Card>
      <PageTitle
        title={intl.formatMessage({
          id: LocalizationKeys.Page.Organization.Plan,
        })}
      />
      <Card bordered={false}>
        <Space className="w-full" direction="vertical" size={30}>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Card>
                <Statistic
                  value={sliderValue}
                  title={intl.formatMessage({
                    id: LocalizationKeys.Components.Organization.Plan.Users,
                  })}
                ></Statistic>
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <Statistic
                  value={
                    tenantPlan?.prices.filter(
                      (x) => x.from <= sliderValue && x.to >= sliderValue
                    )[0]?.price ?? 0
                  }
                  title={intl.formatMessage({
                    id: LocalizationKeys.Components.Organization.Plan
                      .PricePerUser,
                  })}
                  suffix={'€'}
                ></Statistic>
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <Statistic
                  value={
                    (tenantPlan?.prices.filter(
                      (x) => x.from <= sliderValue && x.to >= sliderValue
                    )[0]?.price ?? 0) * sliderValue
                  }
                  title={intl.formatMessage({
                    id: LocalizationKeys.Components.Organization.Plan
                      .TotalPrice,
                  })}
                  suffix={'€'}
                ></Statistic>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Slider
                min={1}
                max={20}
                defaultValue={1}
                marks={sliderMarks}
                value={sliderValue}
                onChange={(value) => {
                  if (value < 1) return
                  setSliderValue(value)
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                <Space>
                  <Text type="secondary" italic>
                    {intl.formatMessage({
                      id: LocalizationKeys.Components.Organization.Plan
                        .MoveTheCursor,
                    })}
                  </Text>
                  {sliderValue !== tenantPlan?.planPaidUsers && (
                    <Button
                      icon={<UndoOutlined />}
                      onClick={() => {
                        setSliderValue(tenantPlan?.planPaidUsers ?? 1)
                      }}
                    >
                      {intl.formatMessage({
                        id: LocalizationKeys.Components.Organization.Plan.Reset,
                      })}
                    </Button>
                  )}
                </Space>
              </Space>
            </Col>
          </Row>
        </Space>
      </Card>
    </>
  )
}
export type PlanType = { Plan: typeof Plan }

import { useAuth } from '../auth/Auth'

const useTenantSettings = () => {
  const settings = useAuth().currentTenant?.settings

  const tempSymbol = getTempSymbol(settings?.measurementUnit)
  const windSymbol = getWindSymbol(settings?.measurementUnit)

  return { ...settings, tempSymbol, windSymbol }
}

const getTempSymbol = (measurementUnit: string | undefined) => {
  if (measurementUnit === 'metric') {
    return '°C'
  }
  return '°F'
}

const getWindSymbol = (measurementUnit: string | undefined) => {
  if (measurementUnit === 'metric') {
    return 'meter/sec'
  }
  return 'miles/hour'
}

export { useTenantSettings }

import { useIntl } from 'react-intl'
import {
  Alert,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Typography,
} from 'antd'
import { numberRule, requiredRule, stringRule } from '../../../utils/rules'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import { useEffect, useRef } from 'react'
import {
  getInvoiceWithId,
  selectSelectedInvoice,
} from '../../../reducers/InvoiceReducer'
import { fieldFormatter } from '../../../utils/formatters'
import dayjs from 'dayjs'
import { useSBRMData } from '../../../modules/sbrm/hooks'
import TextArea from 'antd/es/input/TextArea'
import { MetaDataKey } from '../../../models/MetaData'
import { SBRMFormStepProps, SBRMType } from '../../../modules/sbrm/SBRMModel'
import { Payment } from '../../payment/Payment'
import {
  getPayments,
  resetPaymentQueryAndIds,
} from '../../../reducers/PaymentReducer'
import { initialQuery } from '../../../utils/helpers/crud/models'
import { FileDropzoneRefInterface } from '../../../components/custom-inputs/Dropzone'
import { SBAsyncSelect } from '../../../components/custom-inputs/sb-async-select/SBAsyncSelect'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { useTenantSettings } from '../../../utils/hooks/useTenantSettings'
import ExternalAdvancingStatus from '../../ExternalAdvancingStatus'
import { selectCurrencyById } from '../../../reducers/CurrencyReducer'

const { Text } = Typography
interface Props extends SBRMFormStepProps {}

const Update = ({ form, isNested }: Props) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const filesRef = useRef<FileDropzoneRefInterface>(null)

  const invoice = useAppSelector(selectSelectedInvoice())
  const currency = useAppSelector(selectCurrencyById(invoice?.currency ?? 0))
  const {
    action: nestedAction,
    entity: nestedEntity,
    isOpen: nestedIsOpen,
  } = useSBRMData(true)

  const fetchInvoicePayments = () => {
    dispatch(
      getPayments({
        ...initialQuery,
        filters: { invoices: [invoice?.id ?? 0] },
      })
    )
  }

  useEffect(() => {
    form.setFieldsValue(invoice)
    if (invoice?.dueDate) {
      form.setFieldValue('dueDate', dayjs(invoice?.dueDate))
    }
    dispatch(resetPaymentQueryAndIds({}))
    fetchInvoicePayments()
  }, [invoice])

  useEffect(() => {
    // We want to fetch payments when the nested SBRM is closed, with action create and entity payment
    if (
      nestedIsOpen === false &&
      nestedAction === 'create' &&
      nestedEntity === SBRMType.payment
    ) {
      fetchInvoicePayments()
      if (invoice !== undefined) {
        dispatch(getInvoiceWithId(invoice.id))
      }
    }
  }, [nestedIsOpen, nestedAction, nestedEntity])

  if (invoice === undefined) {
    return <></>
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onReset={() => filesRef.current?.handleReset()}
    >
      <Form.Item
        name="id"
        rules={[requiredRule(intl)]}
        style={{ display: 'none' }}
      >
        <Input type="hidden" />
      </Form.Item>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            name="reference"
            label={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Invoice.Reference,
            })}
            rules={[stringRule(intl)]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="dueDate"
            rules={[requiredRule(intl)]}
            label={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Invoice.DueDate,
            })}
          >
            <DatePicker className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <SBAsyncSelect
        type={SBRMType.supplier}
        name={'supplier'}
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Invoice.Supplier,
        })}
        rules={[]}
        size="middle"
      />
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            name="amountExcludingTaxes"
            label={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Invoice.AmountExclTaxes,
            })}
            rules={[requiredRule(intl), numberRule(intl)]}
          >
            <InputNumber
              min={0}
              className="w-full"
              addonBefore={currency?.symbol}
              formatter={fieldFormatter}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="amountIncludingTaxes"
            label={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Invoice.AmountInclTaxes,
            })}
            rules={[requiredRule(intl), numberRule(intl)]}
          >
            <InputNumber
              min={0}
              className="w-full"
              addonBefore={currency?.symbol}
              formatter={fieldFormatter}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation="left">
        <Text style={{ fontSize: 12 }} type="secondary">
          {intl.formatMessage({
            id: LocalizationKeys.Misc.Form.Invoice.Payments,
          })}
        </Text>
      </Divider>
      <Payment.List
        invoiceId={invoice!.id}
        emptyActionsMetadata={[
          { key: MetaDataKey.invoiceId, value: invoice!.id },
        ]}
      />
      {invoice.hasPaymentInOtherCurrency === true && (
        <Alert
          style={{ marginTop: 10 }}
          type="warning"
          message={'Payment in different currency'}
          description={
            <Space direction="vertical">
              <Text>
                Alela cannot detect if the invoice is paid when having payments
                is different currencies than the invoice.
                <br />
                Use the checkbox below to mark the invoice as paid.
              </Text>
              <Form.Item name={'isPaid'} valuePropName="checked">
                <Checkbox />
              </Form.Item>
            </Space>
          }
        ></Alert>
      )}

      <Divider orientation="left">
        <Text style={{ fontSize: 12 }} type="secondary">
          {intl.formatMessage({
            id: LocalizationKeys.Misc.Form.Invoice.Options,
          })}
        </Text>
      </Divider>
      <SBAsyncSelect
        size="middle"
        type={SBRMType.currency}
        name={'currency'}
        rules={[requiredRule(intl)]}
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Currency,
        })}
      />
      <SBAsyncSelect
        type={SBRMType.invoiceCategory}
        name={'category'}
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Invoice.Category,
        })}
        size="middle"
      />
      <SBAsyncSelect
        type={SBRMType.booking}
        name={'booking'}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Booking })}
        allowClear
        metadata={[
          {
            key: MetaDataKey.eventId,
            value: invoice?.eventId,
          },
        ]}
      />

      <Form.Item
        name="notes"
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Notes })}
        rules={[stringRule(intl)]}
      >
        <TextArea rows={3} />
      </Form.Item>
      <ExternalAdvancingStatus />
    </Form>
  )
}

export default Update

import { useIntl } from 'react-intl'
import { LinkOutlined } from '@ant-design/icons'
import { Card, Form, Space, Switch } from 'antd'
import LocalizationKeys from '../i18n/LocalizationKeys'

const ExternalAdvancingStatus = () => {
  const intl = useIntl()

  return (
    <Card
      styles={{ body: { padding: 5 } }}
      style={{
        background: '#111a2c',
        border: '1px dashed #5252d4',
        borderRadius: 8,
      }}
    >
      <Space
        style={{
          color: '#5252d4',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Space>
          <LinkOutlined style={{ fontSize: 20, color: '#5252d4' }} />
          {intl.formatMessage({
            id: LocalizationKeys.Misc.Form.ExternalAdvancingStatus,
          })}
        </Space>
        <Form.Item
          style={{ margin: 0 }}
          name="isApproved"
          rules={[]}
          valuePropName="checked"
        >
          <Switch
            checkedChildren={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Approved,
            })}
            unCheckedChildren={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Pending,
            })}
          />
        </Form.Item>
      </Space>
    </Card>
  )
}

export default ExternalAdvancingStatus

import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIUpdate,
} from '../utils/helpers/SBAPIHelper'
import { SUPPLIER_TYPES_URL } from '../utils/urls'
import { SupplierType } from '../models/Supplier'
import { supplierTypeSchema } from '../models/schema'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'

import { initialQuery } from '../utils/helpers/crud/models'

const initialState: SBState<SupplierType> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: initialQuery,
}

const slice = createSlice({
  name: 'supplierType',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getSupplierTypesSuccess,
  setQuery: setSupplierTypeQuery,
  reset: resetSupplierTypeState,
  resetQueryAndIds: resetSupplierTypeQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: SupplierType }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectSupplierTypes = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )

export const selectSelectedSupplierType = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectSupplierTypeById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectSupplierTypesByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedSupplierType =
  (supplierType: SupplierType | number) => async (dispatch: AppDispatch) =>
    dispatch(
      slice.actions.setSelectedId(getIdOrModelId<SupplierType>(supplierType))
    )

export const getSupplierTypes = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<SupplierType>(
    SUPPLIER_TYPES_URL,
    params,
    [supplierTypeSchema],
    slice.actions
  )

export const getSupplierTypeWithId = (id: number) =>
  SBAPIFetchDispatch<SupplierType>(
    `${SUPPLIER_TYPES_URL}/${id}`,
    supplierTypeSchema,
    slice.actions
  )

export const createSupplierType = (supplierType: SupplierType) =>
  SBAPICreate<SupplierType>(
    supplierType,
    SUPPLIER_TYPES_URL,
    supplierTypeSchema,
    slice.actions
  )

export const updateSupplierType = (supplierType: SupplierType) =>
  SBAPIUpdate<SupplierType>(
    supplierType,
    `${SUPPLIER_TYPES_URL}/${supplierType.id}`,
    slice.actions
  )

export const deleteSupplierType = (supplierType: SupplierType) =>
  SBAPIDelete<SupplierType>(
    supplierType,
    `${SUPPLIER_TYPES_URL}/${supplierType.id}`,
    slice.actions
  )

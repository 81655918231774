import { Button, Card, Space, Tag } from 'antd'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { EventStatus } from '../../../models/Event'
import { Actions } from '../../../modules/sbrm/components/Actions'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import {
  getEventStatuses,
  selectEventStatuses,
  setEventStatusQuery,
} from '../../../reducers/EventStatusReducer'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { Role } from '../../../components/role/Role'
import { Event } from '../../../components/event/Event'
import SBTable from '../../../components/SBTable'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { extractSortDataFromSorterResult } from '../../../utils/table/sorter'
import { ColorTag } from '../../../components/ColorTag'
import { Help } from '../../../components/help/Help'
import { HelpArticle } from '../../../utils/hooks/useAlelaHelp'
import { useNavigate } from 'react-router-dom'
import { TableParams } from '../../../models/TableParams'
import { initialQuery } from '../../../utils/helpers/crud/models'

const EventStatusesPage = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const statuses = useAppSelector(selectEventStatuses())
  const { query, isLoading } = useAppSelector((state) => state.eventStatus)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const columns: ColumnsType<EventStatus> = [
    {
      key: 'name',
      title: intl.formatMessage({ id: LocalizationKeys.Misc.Table.Name }),
      render: (record: EventStatus) => <Event.StatusTag id={record.id} />,
    },
    {
      key: 'color',
      title: intl.formatMessage({ id: LocalizationKeys.Misc.Table.Color }),
      render: (record: EventStatus) =>
        record.color && <ColorTag color={record.color} />,
    },
    {
      key: 'icon',
      title: intl.formatMessage({ id: LocalizationKeys.Misc.Table.Icon }),
      render: (record: EventStatus) => <Tag>{record.icon}</Tag>,
    },
    {
      key: 'isDraft',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.Settings.EventStatus.DefaultStatus,
      }),
      align: 'center',
      render: (record: EventStatus) =>
        record.isDraft ? <Tag color="green">Yes</Tag> : <></>,
    },
    {
      key: 'visibleBy',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.Settings.EventStatus.VisibleBy,
      }),
      render: (record: EventStatus) => (
        <Space>
          {record.visibleByRoles &&
            record.visibleByRoles.map((role) => (
              <Role.Tag key={role} id={role} />
            ))}
        </Space>
      ),
    },
    {
      key: 'actions',
      align: 'right',
      render: (record: EventStatus) => (
        <Actions
          actions={['update', 'delete']}
          entity={SBRMType.eventStatus}
          entityId={record.id}
        />
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<EventStatus> | SorterResult<EventStatus>[]
  ) => {
    const newQuery = {
      ...query,
      pagination,
      filters,
      ...extractSortDataFromSorterResult(sorter),
    }
    dispatch(setEventStatusQuery(newQuery))
    dispatch(getEventStatuses(newQuery))
  }

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      return
    }
    setIsFirstRender(false)

    // Trigger when SBRM is closed an on init
    const baseQuery: TableParams = {
      ...initialQuery,
      pagination: { current: 1, pageSize: 100 },
    }
    dispatch(setEventStatusQuery(baseQuery))

    dispatch(getEventStatuses(baseQuery))
  }, [dispatch, SBRMIsOpen])

  return (
    <Card
      bordered={false}
      styles={{ body: { padding: 0 } }}
      title={
        <PageTitle
          title={intl.formatMessage({
            id: LocalizationKeys.Page.Settings.EventStatus.Title,
          })}
          level={5}
          toolbar={
            <Space direction="horizontal">
              <Actions actions={['create']} entity={SBRMType.eventStatus} />
              <Button
                onClick={() => navigate('/settings/event-statuses/trashed')}
              >
                {intl.formatMessage({
                  id: LocalizationKeys.Page.Settings.EventStatus.SeeArchived,
                })}
              </Button>
              <Help.Button
                article={HelpArticle.ManageEventStatusAndPermissions}
              />
            </Space>
          }
        />
      }
    >
      <SBTable
        entity={SBRMType.eventStatus}
        scroll={{ x: 600 }}
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={statuses}
        pagination={query.pagination}
        loading={isLoading}
        onChange={handleTableChange}
      />
    </Card>
  )
}

export default EventStatusesPage

import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import { CONTACTS_URL, CONTACT_ASSOCIATION_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { initialQuery } from '../utils/helpers/crud/models'
import { contactAssociationSchema } from '../models/schema'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import {
  SBAPICreate,
  SBAPIDelete,
  SBAPIFetchPaginatedDispatch,
  SBAPIUpdate,
} from '../utils/helpers/SBAPIHelper'
import { ContactAssociation, CreateContactAssociation } from '../models/Contact'

export const initialContactAssociationQuery: TableParams = {
  ...initialQuery,
}

const initialState: SBState<ContactAssociation> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: initialContactAssociationQuery,
}

const slice = createSlice({
  name: 'contactAssociation',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getContactAssociationsSuccess,
  setQuery: setContactAssociationQuery,
  reset: resetContactAssociationState,
  resetQueryAndIds: resetContactAssociationQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: ContactAssociation }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectContactAssociations = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectAllNotApprovedAssociations = () =>
  createSelector([selectRawItems], (items) =>
    Object.entries(items)
      .map((i) => i[1])
      .filter((i) => i.isApproved === false)
  )
export const selectSelectedContactAssociation = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectContactAssociationById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectContactAssociationsByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedContactAssociation =
  (contactAssociation: ContactAssociation | number) =>
  async (dispatch: AppDispatch) =>
    dispatch(
      slice.actions.setSelectedId(
        getIdOrModelId<ContactAssociation>(contactAssociation)
      )
    )

export const getContactAssociations = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<ContactAssociation>(
    CONTACT_ASSOCIATION_URL,
    params,
    [contactAssociationSchema],
    slice.actions,
    null
  )
export const getNonApprovedContactAssociations = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<ContactAssociation>(
    CONTACT_ASSOCIATION_URL,
    {
      ...params,
      with: [...(params.with ?? []), 'notApproved'],
    },
    [contactAssociationSchema],
    slice.actions,
    slice.actions.startLoading,
    false, // Do no set ids for non approved
    false // Do no set pagination for non approved
  )

export const createContactAssociation = (
  association: CreateContactAssociation
) =>
  SBAPICreate<CreateContactAssociation>(
    association,
    `${CONTACTS_URL}/${association.contact}/associate/${association.parent.type}/${association.parent.id}`,
    contactAssociationSchema,
    slice.actions
  )

export const approveContactAssociation = (association: ContactAssociation) =>
  SBAPIUpdate<ContactAssociation>(
    association,
    `${CONTACTS_URL}/${association.id}/approve`,
    slice.actions
  )

export const deleteContactAssociation = (association: ContactAssociation) =>
  SBAPIDelete<ContactAssociation>(
    association,
    `${CONTACTS_URL}/${association.id}/dissociate`,
    slice.actions
  )

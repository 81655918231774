import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MetaData } from '../models/MetaData'
import { SBRMAction, SBRMType } from '../modules/sbrm/SBRMModel'

export type SBRMState = {
  isOpen: boolean
  entity: SBRMType | undefined
  action: SBRMAction
  metadata?: MetaData[]
}

type State = SBRMState & {
  nested: SBRMState
}

const initialState: State = {
  isOpen: false,
  entity: undefined,
  action: 'view',
  metadata: [],
  nested: {
    isOpen: false,
    entity: undefined,
    action: 'view',
    metadata: [],
  },
}

const slice = createSlice({
  name: 'SBRM',
  initialState,
  reducers: {
    // OPEN
    open(state) {
      state.isOpen = true
    },
    close(state) {
      /**
       * On closing we check if the nested SBRM is open
       * If so we close it
       * In not we close the main SBRM
       */
      if (state.nested.isOpen) {
        state.nested.isOpen = false
      } else {
        state.isOpen = false
      }
    },
    setEntity(
      state,
      action: PayloadAction<{
        entity: SBRMType
        action: SBRMAction
        metadata?: MetaData[]
      }>
    ) {
      /**
       * When setting entity we first check if the SBRM is already open
       * If so, we set the entity for the nested SBRM and open it
       * If not, we set the entity for the main SBRM and open it
       * However, the metadata is always at the top-level
       */
      state.metadata = action.payload.metadata

      if (state.isOpen) {
        state.nested.entity = action.payload.entity
        state.nested.action = action.payload.action

        state.nested.isOpen = true
      } else {
        state.entity = action.payload.entity
        state.action = action.payload.action
        state.isOpen = true
      }
    },
    setMainAction(
      state,
      action: PayloadAction<{
        action: SBRMAction
      }>
    ) {
      /**
       * This is made to be used to quickly change the action when the SBRM is already open
       */
      state.action = action.payload.action
    },
    reset(state) {
      state = {
        isOpen: false,
        entity: undefined,
        action: 'view',
        metadata: [],
        nested: {
          isOpen: false,
          entity: undefined,
          action: 'view',
          metadata: [],
        },
      }
    },
  },
})

// Reducer
export default slice.reducer

export const {
  open,
  close,
  setEntity,
  setMainAction,
  reset: resetSBRM,
} = slice.actions

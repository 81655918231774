import {
  getContactWithId,
  selectSelectedContact,
  setSelectedContact,
} from '../../reducers/ContactReducer'
import {
  ArrowLeftOutlined,
  MailOutlined,
  PhoneOutlined,
} from '@ant-design/icons'
import {
  getContactAssociations,
  resetContactAssociationQueryAndIds,
  setContactAssociationQuery,
} from '../../reducers/ContactAssociationReducer'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import HasAccess from '../../components/HasAccess'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { TableParams } from '../../models/TableParams'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { AlelaPermission } from '../../utils/permissions'
import { useNavigate, useParams } from 'react-router-dom'
import { Contact } from '../../components/contact/Contact'
import { initialQuery } from '../../utils/helpers/crud/models'
import { Actions } from '../../modules/sbrm/components/Actions'
import {
  Row,
  Col,
  Button,
  Space,
  Spin,
  Typography,
  Popover,
  QRCode,
  Card,
} from 'antd'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { DetailsPageHeader } from '../../components/layout/PageHeaders'
import { ContactAssociation } from '../../components/contact-association/ContactAssociation'
import LocalizationKeys from '../../i18n/LocalizationKeys'

const { Text, Title } = Typography

const ContactPage = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { contactId } = useParams<{ contactId: string }>()

  const dispatch = useAppDispatch()

  const contact = useAppSelector(selectSelectedContact())

  const { isLoading } = useAppSelector((state) => state.contactAssociation)
  const { isOpen: SBRMisOpen, action } = useAppSelector((state) => state.SBRM)

  useEffect(() => {
    if (isNaN(contactId as unknown as number)) {
      navigate('/crm/contacts')
    }
    dispatch(resetContactAssociationQueryAndIds(initialQuery))

    dispatch(setSelectedContact(Number(contactId)))
    dispatch(getContactWithId(Number(contactId)))

    const baseQuery: TableParams = {
      ...initialQuery,
      filters: {
        contacts: [Number(contactId)],
        associationTypes: ['artist', 'venue', 'supplier'],
      },
    }
    dispatch(setContactAssociationQuery(baseQuery))
    dispatch(getContactAssociations(baseQuery))
  }, [contactId])

  useEffect(() => {
    if (SBRMisOpen || action !== 'delete') {
      return
    }
    /*
     * When the contact is updated via the Actions edit button
     * We do not have a useEffect with getContactWithId
     * As the store will be updated locally
     *
     * However if the contact is deleted we need to redirect on contacts list
     * So we need to do the request and catch error
     */
    dispatch(getContactWithId(Number(contactId))).catch((e) => {
      if (e.error.response.status == 404) {
        navigate('/crm/contacts')
      }
    })
  }, [SBRMisOpen])

  if (!contact) {
    return (
      <>
        <Card bordered={false} loading={true} />
      </>
    )
  }

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Button
          type="text"
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate(-1)}
        >
          {intl.formatMessage({
            id: LocalizationKeys.Page.CRM.Contact.Back,
          })}
        </Button>
      </Col>

      <HasAccess permissions={[AlelaPermission.viewContact]}>
        <Col span={24}>
          <DetailsPageHeader
            toolbar={
              <Space direction="horizontal">
                {contact?.email && (
                  <Link to={`mailto:${contact.email}`}>
                    <Button icon={<MailOutlined />}></Button>
                  </Link>
                )}
                {contact?.phone && (
                  <Popover
                    overlayInnerStyle={{ padding: 0 }}
                    color={'#fff'}
                    content={
                      <QRCode value={`tel:${contact.phone}`} bordered={false} />
                    }
                  >
                    <Link to={`tel:${contact.phone}`}>
                      <Button icon={<PhoneOutlined />}></Button>
                    </Link>
                  </Popover>
                )}
                <Actions
                  entity={SBRMType.contact}
                  actions={['update', 'delete']}
                  entityId={contact.id}
                />
              </Space>
            }
          >
            <Space direction="horizontal">
              <Contact.Avatar model={contact} type="condensed" size={'large'} />
              <Space direction="vertical" size={0}>
                <Title level={4} style={{ margin: 0 }}>
                  {contact.firstName} {contact.lastName}{' '}
                  <Contact.LinkedToUser model={contact} />
                </Title>
                <Text>{contact.company}</Text>
              </Space>
            </Space>
          </DetailsPageHeader>
        </Col>
        <Col span={24}>
          <PageTitle
            title={intl.formatMessage({
              id: LocalizationKeys.Page.CRM.Contact.WorkFor,
            })}
            level={5}
            toolbar={isLoading && <Spin />}
          />
          <ContactAssociation.TableWithResource />
        </Col>
      </HasAccess>
    </Row>
  )
}

export default ContactPage

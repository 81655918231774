import { useIntl } from 'react-intl'
import { DatePicker, Form, FormInstance, Input } from 'antd'
import { numberRule, requiredRule, stringRule } from '../../../utils/rules'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { SBAsyncSelect } from '../../../components/custom-inputs/sb-async-select/SBAsyncSelect'
import { SBRMType, getMetadata } from '../../../modules/sbrm/SBRMModel'
import { useAppSelector } from '../../../reducers/hooks'
import { useEffect } from 'react'
import { MetaDataKey } from '../../../models/MetaData'
import TextArea from 'antd/es/input/TextArea'

interface Props {
  form: FormInstance
}

const Create = ({ form }: Props) => {
  const intl = useIntl()

  const { isOpen: SBRMIsOpen, metadata } = useAppSelector((state) => state.SBRM)

  useEffect(() => {
    //Trigger reset only when SBRM is open
    if (!SBRMIsOpen) return

    form.setFieldValue(
      'entity',
      getMetadata(metadata, MetaDataKey.taskParentType)
    )
    form.setFieldValue(
      'entityId',
      getMetadata(metadata, MetaDataKey.taskParentId)
    )
  }, [SBRMIsOpen])

  return (
    <Form form={form} layout="vertical">
      {/* Entity */}
      <Form.Item name="entity" rules={[requiredRule(intl)]} className="d-none">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        name="entityId"
        rules={[requiredRule(intl), numberRule(intl)]}
        className="d-none"
      >
        <Input type="hidden" />
      </Form.Item>

      {/* Attributes */}
      <Form.Item
        name="title"
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Title })}
        rules={[requiredRule(intl), stringRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="description"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Description,
        })}
        rules={[stringRule(intl)]}
      >
        <TextArea rows={5} />
      </Form.Item>
      <Form.Item
        name="dueDate"
        rules={[]}
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.DueDate,
        })}
      >
        <DatePicker format="YYYY-MM-DD" className="w-full" />
      </Form.Item>
      <SBAsyncSelect
        size="middle"
        type={SBRMType.taskStatus}
        name={'status'}
        rules={[requiredRule(intl)]}
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Status,
        })}
      />
      <SBAsyncSelect
        type={SBRMType.user}
        name={'assignee'}
        rules={[]}
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Assignee,
        })}
      />
    </Form>
  )
}

export default Create

import { useAuth } from '../utils/auth/Auth'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { Card, Col, Layout, Row, Space, Typography, theme } from 'antd'
import { useAzureSSO } from '../utils/saml-sso/AzureSSOContext'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../i18n/LocalizationKeys'

const { Text } = Typography
const { Footer, Content } = Layout

export const AuthLayout: React.FC = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken()
  const intl = useIntl()
  const location = useLocation()
  const { isLogged: isLoggedEmail } = useAuth()
  const { isLogged: isLoggedAzure } = useAzureSSO()
  const isLogged = isLoggedEmail || isLoggedAzure

  if (isLogged && !location.pathname.includes('invitation')) {
    // Ensure redirection to home page if the user is logged
    // Exception for invitation page
    return <Navigate to={'/'} />
  }

  const year = new Date().getFullYear()

  return (
    <Layout className="min-h-100vh">
      <Content>
        <Row className="min-h-100vh">
          <Col
            className="min-h-100vh hide-mobile"
            md={12}
            sm={24}
            style={{
              background: "url('/assets/images/login-background.jpeg')",
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          ></Col>
          <Col
            className="min-h-100vh"
            md={12}
            sm={24}
            xs={24}
            style={{
              display: 'flex',
              height: '100% ',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                marginTop: 'auto',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Card
                bordered={false}
                styles={{ body: { paddingBottom: 20 } }}
                style={{ width: 360, margin: 'auto' }}
              >
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={'/assets/images/logocurve.png'}
                    height={45}
                    style={{
                      marginTop: 0,
                      marginBottom: 15,
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  />
                </div>
                <Outlet />
              </Card>
            </div>
            <div
              className="text-center"
              style={{
                marginTop: 'auto',
              }}
            >
              <Footer>
                <Space direction="vertical" size={2}>
                  <Space size={20}>
                    <a
                      type="text"
                      className="ant-btn-text"
                      style={{
                        textAlign: 'center',
                        color: 'rgba(255, 255, 255, 0.45)',
                      }}
                      href="https://www.alela.live"
                    >
                      {intl.formatMessage({
                        id: LocalizationKeys.Layout.Auth.Discover,
                      })}
                    </a>
                    <a
                      type="text"
                      className="ant-btn-text"
                      style={{
                        textAlign: 'center',
                        color: 'rgba(255, 255, 255, 0.45)',
                      }}
                      href="mailto:contact@alela.app"
                    >
                      {intl.formatMessage({
                        id: LocalizationKeys.Layout.Auth.ContactUs,
                      })}
                    </a>
                    <a
                      type="text"
                      className="ant-btn-text"
                      style={{
                        textAlign: 'center',
                        color: 'rgba(255, 255, 255, 0.45)',
                      }}
                      href="https://www.alela.live/template/style-guide"
                    >
                      {intl.formatMessage({
                        id: LocalizationKeys.Layout.Auth.Legal,
                      })}
                    </a>
                  </Space>
                  <Text
                    style={{
                      fontWeight: 300,
                      color: 'rgba(255, 255, 255, 0.45)',
                    }}
                  >
                    © {year} Supplément Bacon
                  </Text>
                </Space>
              </Footer>
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

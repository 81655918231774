import { Space, Tooltip } from 'antd'
import { User } from '../../models/User'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { Actions } from '../../modules/sbrm/components/Actions'

interface Props {
  user: User
}

export const EditDeleteButtons = ({ user }: Props) => {
  return (
    <Space dir="horizontal" size={0}>
      <Actions actions={['delete']} entity={SBRMType.user} entityId={user.id} />
    </Space>
  )
}

export type EditDeleteButtonsType = {
  EditDeleteButtons: typeof EditDeleteButtons
}

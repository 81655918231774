import { forwardRef, Ref, useImperativeHandle } from 'react'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import { Alert, Form } from 'antd'
import Create from './Create'
import Update from './Update'
import Delete from './Delete'
import {
  createRider,
  updateRider,
  deleteRider,
} from '../../../reducers/RiderReducer'
import { MetaDataKey } from '../../../models/MetaData'
import {
  getMetadata,
  SBRMFormInterface,
  SBRMFormProps,
} from '../../../modules/sbrm/SBRMModel'
import { useSBRMData } from '../../../modules/sbrm/hooks'

const RiderForm = forwardRef(
  ({ isNested }: SBRMFormProps, ref: Ref<SBRMFormInterface>) => {
    useImperativeHandle(ref, () => ({
      handleCreate,
      handleUpdate,
      handleDelete,
      handleReset,
    }))

    const dispatch = useAppDispatch()
    const { action, metadata } = useSBRMData(isNested)
    const { error } = useAppSelector((state) => state.rider)

    const [createForm] = Form.useForm()
    const [updateForm] = Form.useForm()
    const [deleteForm] = Form.useForm()

    const handleCreate = async () =>
      await createForm.validateFields().then(async (result) => {
        const data = new FormData()
        data.append('booking', createForm.getFieldValue('booking'))
        data.append('status', createForm.getFieldValue('status'))
        data.append('type', createForm.getFieldValue('type') ?? null)
        data.append('notes', createForm.getFieldValue('notes') ?? '')

        const fileField = createForm.getFieldValue('file')
        if (fileField && fileField.fileList && fileField.fileList.length) {
          data.append('file', fileField.fileList[0].originFileObj)
        }

        return dispatch(createRider(data)).then(() => {
          createForm.resetFields()
          return true
        })
      })

    const handleUpdate = async () =>
      await updateForm.validateFields().then(async (result) => {
        const data = new FormData()
        data.append('id', updateForm.getFieldValue('id'))
        data.append('notes', updateForm.getFieldValue('notes') ?? '')
        data.append('status', updateForm.getFieldValue('status'))
        data.append('type', updateForm.getFieldValue('type') ?? null)
        data.append('isApproved', updateForm.getFieldValue('isApproved') ?? '')

        const fileField = updateForm.getFieldValue('file')
        if (fileField && fileField.fileList && fileField.fileList.length) {
          data.append('file', fileField.fileList[0].originFileObj)
        }

        return dispatch(updateRider(data)).then(() => {
          updateForm.resetFields()
          return true
        })
      })

    const handleDelete = async () =>
      await deleteForm.validateFields().then(async (result) =>
        dispatch(deleteRider(deleteForm.getFieldsValue())).then(() => {
          deleteForm.resetFields()
          return true
        })
      )

    const handleReset = () => {
      createForm.resetFields()
      updateForm.resetFields()
      deleteForm.resetFields()
    }

    return (
      <>
        {action === 'create' && (
          <Create
            form={createForm}
            eventId={getMetadata(metadata, MetaDataKey.eventId)}
            bookingId={getMetadata(metadata, MetaDataKey.bookingId)}
          />
        )}
        {action === 'update' && <Update form={updateForm} />}
        {action === 'delete' && <Delete form={deleteForm} />}
        {error && <Alert message={String(error)} type="error" />}
      </>
    )
  }
)

RiderForm.displayName = 'RiderForm'

type RiderFormType = { Form: typeof RiderForm }

export { RiderForm, type RiderFormType }

import { Tag, Tooltip, Typography } from 'antd'
import { Invoice } from '../../models/Invoice'
import { useAppSelector } from '../../reducers/hooks'
import { selectInvoiceById } from '../../reducers/InvoiceReducer'
import { selectInvoiceCategoryById } from '../../reducers/InvoiceCategoryReducer'

const { Text } = Typography

interface Props {
  id?: number
  model?: Invoice
}

export const CategoryTag = ({ id, model }: Props) => {
  const invoice = model ? model : useAppSelector(selectInvoiceById(id ?? 0))
  const category = useAppSelector(
    selectInvoiceCategoryById(invoice?.category ?? 0)
  )

  if (category === undefined) return <></>

  const start =
    category.name && category.name.slice(0, category.name.length - 5).trim()

  const suffix = category.name && category.name.slice(-5).trim()

  return (
    <Tooltip title={category.name}>
      <Tag>
        <Text style={{ maxWidth: 100, fontSize: 12 }} ellipsis={{ suffix }}>
          {start}
        </Text>
      </Tag>
    </Tooltip>
  )
}

export type CategoryTagType = { CategoryTag: typeof CategoryTag }

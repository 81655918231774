import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Form, FormInstance, Input } from 'antd'
import { requiredRule } from '../../../utils/rules'
import { useAppSelector } from '../../../reducers/hooks'
import { selectSelectedContactType } from '../../../reducers/ContactTypeReducer'
import ColorSelect from '../../custom-inputs/select/ColorSelect'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

interface Props {
  form: FormInstance
}

const Update = ({ form }: Props) => {
  const intl = useIntl()

  const type = useAppSelector(selectSelectedContactType())

  useEffect(() => {
    form.setFieldsValue(type)
  }, [type])

  if (type === undefined) {
    return <></>
  }

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        style={{ display: 'none' }}
        name="id"
        required
        rules={[requiredRule(intl)]}
      >
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        name="name"
        rules={[requiredRule(intl)]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Name })}
      >
        <Input />
      </Form.Item>
      <ColorSelect
        name="color"
        rules={[]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Color })}
      />
      <Form.Item
        name="icon"
        rules={[]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Icon })}
      >
        <Input />
      </Form.Item>
    </Form>
  )
}

export default Update

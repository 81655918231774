import { LayoutCRM } from '../../../layouts/LayoutCRM'
import SuppliersPage from '../../../pages/crm/SuppliersPage'
import SupplierPage from '../../../pages/crm/SupplierPage'
import { Navigate, Route, Routes } from 'react-router-dom'
import ContactPage from '../../../pages/crm/ContactPage'
import UserPage from '../../../pages/crm/UserPage'
import ContactMerger from '../../../pages/crm/tools/ContactMerger'
import ContactsPage from '../../../pages/crm/ContactsPage'
import TeamPage from '../../../pages/crm/organization/TeamPage'
import InvitesPage from '../../../pages/crm/organization/InvitesPage'

const CRMRoutes = () => {
  return (
    <Routes>
      <Route element={<LayoutCRM />}>
        <Route path="contacts" element={<ContactsPage />} />
        <Route path="contacts/:contactId" element={<ContactPage />} />
        <Route path="suppliers" element={<SuppliersPage />} />
        <Route path="suppliers/:supplierId" element={<SupplierPage />} />
        <Route path="organization-team" element={<TeamPage />} />
        <Route path="organization-team/invites" element={<InvitesPage />} />
        <Route path="organization-team/:userId" element={<UserPage />} />
        <Route path="tools/*">
          <Route path="contact-merger" element={<ContactMerger />} />
        </Route>
        <Route index path="*" element={<Navigate to={'contacts'} />} />
      </Route>
    </Routes>
  )
}

export default CRMRoutes

import { FC } from 'react'
import { ContractStatusTag, ContractStatusTagType } from './Tag'
import { ContractStatusForm, ContractStatusFormType } from './form/Form'

export const ContractStatus: FC &
  ContractStatusTagType &
  ContractStatusFormType = (): JSX.Element => <></>

ContractStatus.Tag = ContractStatusTag
ContractStatus.Form = ContractStatusForm

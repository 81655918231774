import { Navigate, Route, Routes } from 'react-router-dom'
import { LayoutFinancial } from '../../../layouts/LayoutFinancial'
import InvoicesPage from '../../../pages/financial/InvoicesPage'
import ReportsPage from '../../../pages/financial/ReportsPage'

const FinancialRoutes = () => {
  return (
    <Routes>
      <Route element={<LayoutFinancial />}>
        <Route path="invoices" element={<InvoicesPage />} />
        <Route path="reports" element={<ReportsPage />} />
        <Route index path="*" element={<Navigate to={'invoices'} />} />
      </Route>
    </Routes>
  )
}

export default FinancialRoutes

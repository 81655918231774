import {
  getArtistWithId,
  setSelectedArtist,
  selectSelectedArtist,
} from '../../reducers/ArtistReducer'
import {
  getBookings,
  selectBookings,
  resetBookingQueryAndIds,
  setBookingQuery,
} from '../../reducers/BookingReducer'
import {
  SBRMType,
  SBRMContactAssociationType,
} from '../../modules/sbrm/SBRMModel'
import {
  getFiles,
  selectFiles,
  resetFileQueryAndIds,
} from '../../reducers/FileReducer'
import {
  getContacts,
  selectContacts,
  resetContactQueryAndIds,
  setContactQuery,
} from '../../reducers/ContactReducer'
import { useIntl } from 'react-intl'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ARTISTS_URL } from '../../utils/urls'
import { FileRelation } from '../../models/File'
import { File } from '../../components/file/File'
import HasAccess, { useHasAccess } from '../../components/HasAccess'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { MetaDataKey } from '../../models/MetaData'
import { TableParams } from '../../models/TableParams'
import { AlelaPermission } from '../../utils/permissions'
import { Contact } from '../../components/contact/Contact'
import { Booking } from '../../components/booking/Booking'
import { initialQuery } from '../../utils/helpers/crud/models'
import { Actions } from '../../modules/sbrm/components/Actions'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import {
  Typography,
  Card,
  Space,
  Row,
  Col,
  Tag,
  Statistic,
  Segmented,
} from 'antd'
import { PictureOutlined } from '@ant-design/icons'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import {
  getReports,
  resetReportQueryAndIds,
} from '../../reducers/ReportReducer'
import { Reporting } from '../../components/reporting/Reporting'
import { report } from 'process'
import { useTenantSettings } from '../../utils/hooks/useTenantSettings'

const { Title } = Typography

const ArtistPage = () => {
  const intl = useIntl()
  const { artistId } = useParams()
  const dispatch = useAppDispatch()
  const { hasAccess } = useHasAccess()
  const { currency } = useTenantSettings()

  const [showDropZone, setShowDropZone] = useState<boolean>(false)
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)
  const [chartToDisplay, setChartToDisplay] = useState<'fees' | 'rentability'>(
    'fees'
  )

  const files = useAppSelector(selectFiles())
  const artist = useAppSelector(selectSelectedArtist())
  const bookings = useAppSelector(selectBookings())
  const contacts = useAppSelector(selectContacts())
  const { isLoading } = useAppSelector((state) => state.artist)
  const { isOpen: SBRMIsOpen, entity: SBRMEntity } = useAppSelector(
    (state) => state.SBRM
  )

  const handleDragOn = function (e: any) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setShowDropZone(true)
    }
  }

  const handleDragOff = function (e: any) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragleave') {
      setShowDropZone(false)
    }
  }

  const baseQuery: TableParams = {
    ...initialQuery,
    pagination: { current: 1, pageSize: 100 },
    filters: { artists: [Number(artistId)] },
  }

  const fetchBookings = () => {
    if (!hasAccess([AlelaPermission.listBooking])) {
      return
    }
    const query: TableParams = {
      ...baseQuery,
      with: ['event'],
      without: ['artist'],
      columnKey: 'date',
      order: 'descend',
    }
    dispatch(setBookingQuery(query))
    dispatch(getBookings(query))
  }

  const fetchContacts = () => {
    if (!hasAccess([AlelaPermission.listContact])) return
    const query: TableParams = { ...baseQuery, with: ['associations'] }
    dispatch(setContactQuery(query))
    dispatch(getContacts(query))
  }

  const fetchFiles = () => {
    if (!hasAccess([AlelaPermission.listFile])) return
    dispatch(getFiles(baseQuery))
  }

  const fetchReports = () => {
    if (!hasAccess([AlelaPermission.listReport])) return
    dispatch(
      getReports({
        ...baseQuery,
        with: ['event'],
        columnKey: 'eventDate',
        order: 'ascend',
      })
    )
  }

  useEffect(() => {
    if (Number.isNaN(artistId)) {
      //TODO: Redirect to artists details
      return
    }
    dispatch(setSelectedArtist(Number(artistId)))
    dispatch(getArtistWithId(Number(artistId)))

    // Reset current values on page arrival
    dispatch(resetBookingQueryAndIds({}))
    dispatch(resetContactQueryAndIds({}))
    dispatch(resetFileQueryAndIds({}))
    dispatch(resetReportQueryAndIds({}))

    // Fetch fresh data
    fetchBookings()
    fetchContacts()
    fetchFiles()
    fetchReports()
  }, [artistId])

  useEffect(() => {
    if (!isFirstRender && (!artist || SBRMIsOpen)) {
      return
    }
    setIsFirstRender(false)

    if (SBRMEntity === SBRMType.booking) {
      fetchBookings()
    } else if (SBRMEntity === SBRMType.contactAssociation) {
      fetchContacts()
    } else if (SBRMEntity === SBRMType.file) {
      fetchFiles()
    } else {
      dispatch(getArtistWithId(Number(artistId)))
    }
  }, [SBRMIsOpen])

  const statistics = [
    {
      title: intl.formatMessage({ id: LocalizationKeys.Page.Artist.LastFee }),
      value: artist?.last_fee,
    },
    {
      title: intl.formatMessage({
        id: LocalizationKeys.Page.Artist.AverageFee,
      }),
      value: artist?.average_fee,
    },
    {
      title: intl.formatMessage({
        id: LocalizationKeys.Page.Artist.MinimumFee,
      }),
      value: artist?.min_fee,
    },
    {
      title: intl.formatMessage({
        id: LocalizationKeys.Page.Artist.MaximumFee,
      }),
      value: artist?.max_fee,
    },
  ]

  return (
    <>
      <Row gutter={[16, 16]} style={{ display: 'flex' }}>
        <Col md={8} sm={24} xs={24} style={{ flex: '1' }}>
          <div
            style={{
              borderRadius: 8,
              flex: '0 0',
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '100%',
              background: '#1E1E1E',
            }}
          >
            <div
              className="cover-center"
              style={{
                flex: '1 1',
                width: '100%',
                height: '100%',
                background: `url(${
                  artist && !isLoading ? artist.spotify_image : undefined
                })`,
                borderRadius: '8px 8px 0 0',
              }}
            >
              {artist?.spotify_image == undefined && (
                <div
                  style={{
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    width: '100%',
                    flex: '0 0',
                    height: '100%',
                    backgroundColor: '#252525',
                    alignItems: 'center',
                    alignContent: 'center',
                    display: 'flex',
                  }}
                >
                  <PictureOutlined
                    style={{
                      color: '#333333',
                      margin: 'auto',
                      fontSize: 50,
                    }}
                  />
                </div>
              )}
            </div>
            <div style={{ flexShrink: 0, padding: 24 }}>
              <Space
                className="w-full"
                direction="horizontal"
                style={{ justifyContent: 'space-between' }}
              >
                <Title level={2} className={'m-0'}>
                  {artist?.name}
                </Title>
                <Actions
                  actions={['update', 'delete']}
                  entity={SBRMType.artist}
                  entityId={artist?.id}
                />
              </Space>
              <Space size={[2, 8]} style={{ flexWrap: 'wrap' }}>
                {artist?.spotify_genres?.map((genre, index) => (
                  <Tag key={index}>{genre}</Tag>
                ))}
              </Space>
            </div>
          </div>
        </Col>
        <Col md={16} sm={24} xs={24} style={{ flex: '1' }}>
          <HasAccess permissions={[AlelaPermission.viewPriceBooking]}>
            <Row gutter={[16, 16]}>
              {statistics.map((stat, index) => (
                <Col key={index} xl={6} lg={12} md={12} sm={12} xs={24}>
                  <Card bordered={false}>
                    <Statistic
                      suffix={currency?.symbol}
                      precision={2}
                      title={stat.title}
                      value={stat.value}
                      loading={isLoading}
                      valueStyle={{ fontSize: '16pt' }}
                    />
                  </Card>
                </Col>
              ))}
              <Col xs={24}>
                <Card
                  loading={isLoading}
                  bordered={false}
                  styles={{
                    body: { paddingTop: 24 },
                    header: { paddingTop: 16 },
                  }}
                  extra={
                    <Segmented
                      options={[
                        { label: 'Fees', value: 'fees' },
                        { label: 'Rentability', value: 'rentability' },
                      ]}
                      onChange={(value) => {
                        if (value === 'fees' || value === 'rentability') {
                          setChartToDisplay(value)
                        }
                      }}
                    />
                  }
                >
                  {chartToDisplay === 'fees' && (
                    <Booking.BarChart bookings={bookings} />
                  )}
                  {chartToDisplay === 'rentability' && (
                    <Reporting.Charts displayMode={'embedded'} />
                  )}
                </Card>
              </Col>
            </Row>
          </HasAccess>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
        <HasAccess permissions={[AlelaPermission.listBooking]}>
          <Col span={24}>
            <PageTitle
              title={intl.formatMessage({
                id: LocalizationKeys.Page.Artist.Bookings,
              })}
              level={5}
              badge={bookings.length}
              toolbar={
                <Actions
                  actions={['create']}
                  entity={SBRMType.booking}
                  metadata={[{ key: MetaDataKey.artistId, value: artist?.id }]}
                />
              }
            />
            <Booking.Table
              emptyActionsMetadata={[
                { key: MetaDataKey.artistId, value: artist?.id },
              ]}
            />
          </Col>
        </HasAccess>
        {artist?.spotify_id && (
          <Col span={24}>
            <PageTitle
              title={intl.formatMessage({
                id: LocalizationKeys.Page.Artist.Listen,
              })}
              level={5}
            />
            <iframe
              width="100%"
              height="152"
              allowFullScreen={false}
              style={{
                border: 0,
                borderRadius: 12,
                width: '100%',
                height: 400,
              }}
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              src={`https://open.spotify.com/embed/artist/${artist?.spotify_id}?utm_source=generator&theme=0`}
            ></iframe>
          </Col>
        )}
        <HasAccess permissions={[AlelaPermission.listContact]}>
          <Col span={24}>
            <PageTitle
              title={intl.formatMessage({
                id: LocalizationKeys.Page.Artist.Crew,
              })}
              level={5}
              badge={contacts.length}
              toolbar={
                <Actions
                  actions={['create']}
                  entity={SBRMType.contactAssociation}
                  metadata={[
                    {
                      key: MetaDataKey.contactAssociationType,
                      value: SBRMContactAssociationType.artist,
                    },
                    { key: MetaDataKey.artistId, value: artist?.id },
                  ]}
                />
              }
            />
            <Contact.TableWithAssociation
              emptyActionsMetadata={[
                {
                  key: MetaDataKey.contactAssociationType,
                  value: SBRMContactAssociationType.artist,
                },
                { key: MetaDataKey.artistId, value: artist?.id },
              ]}
            />
          </Col>
        </HasAccess>
        <HasAccess permissions={[AlelaPermission.listFile]}>
          <Col span={24}>
            <PageTitle
              title={intl.formatMessage({
                id: LocalizationKeys.Page.Artist.Files,
              })}
              level={5}
              badge={files.length}
              toolbar={
                <Actions
                  actions={['create']}
                  entity={SBRMType.file}
                  metadata={[
                    { key: MetaDataKey.fileParentType, value: SBRMType.artist },
                    { key: MetaDataKey.fileParentId, value: artist?.id },
                    {
                      key: MetaDataKey.fileRelation,
                      value: FileRelation.file,
                    },
                  ]}
                />
              }
            />
            <Card
              onDragOver={handleDragOn}
              onDragLeave={handleDragOff}
              bordered={false}
            >
              {!showDropZone && (
                <File.Grid
                  ids={files.map((f) => f.id) ?? []}
                  withDeleteButton={true}
                />
              )}
              {showDropZone && (
                <File.Upload
                  action={`${ARTISTS_URL}/${artist?.id}/files`}
                  onSuccessUpload={() => {
                    setShowDropZone(false)
                    dispatch(getFiles(baseQuery))
                  }}
                />
              )}
            </Card>
          </Col>
        </HasAccess>
      </Row>
    </>
  )
}

export default ArtistPage

import { Contact } from '../contact/Contact'
import { TravelWithRelation } from '../../models/Travel'

interface Props {
  travel: TravelWithRelation
}

export const CascaderOptionLabel = ({ travel }: Props) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {travel.inbound
        ? `${travel.number} | ${travel.arrival.name}`
        : `${travel.number} | ${travel.departure.name}`}
      <Contact.AvatarGroup models={travel.contacts} linkToDetails={false} />
    </div>
  )
}

export type CascaderOptionLabelType = {
  CascaderOptionLabel: typeof CascaderOptionLabel
}

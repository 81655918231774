import { useIntl } from 'react-intl'
import { Form, Input, Select } from 'antd'
import { requiredRule } from '../../../utils/rules'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { SBRMFormStepProps } from '../SBRMModel'
import { useSBRMData } from '../hooks'
import { useEffect } from 'react'
import IconSelect from '../../../components/custom-inputs/select/IconSelect'

interface Props extends SBRMFormStepProps {}

const Create = ({ isNested, form }: Props) => {
  const intl = useIntl()

  const { isOpen: SBRMIsOpen } = useSBRMData(isNested)

  useEffect(() => {
    if (!SBRMIsOpen) {
      // We want to trigger only the reset
      // when the SBRM opens
      return
    }

    form.setFieldValue('type', '1')
  }, [SBRMIsOpen])

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="type"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Type,
        })}
        rules={[requiredRule(intl)]}
      >
        <Select
          defaultValue="1"
          options={[
            {
              value: '1',
              label: intl.formatMessage({
                id: LocalizationKeys.Misc.Form.ReportItem.Cost,
              }),
            },
            {
              value: '2',
              label: intl.formatMessage({
                id: LocalizationKeys.Misc.Form.ReportItem.Revenue,
              }),
            },
          ]}
        />
      </Form.Item>
      <Form.Item
        name="name"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Name,
        })}
        rules={[requiredRule(intl)]}
      >
        <Input />
      </Form.Item>
      {/* <IconSelect
        name="icon"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Icon,
        })}
        rules={[]}
      /> */}
    </Form>
  )
}

export default Create

import { Space } from 'antd'
import { Booking } from '../booking/Booking'
import { Travel } from './Travel'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import {
  approveTravel,
  deleteTravel,
  getNonApprovedTravels,
  selectAllNotApprovedTravels,
} from '../../reducers/TravelReducer'
import { useEffect } from 'react'
import { initialQuery } from '../../utils/helpers/crud/models'
import { Contact } from '../contact/Contact'
import { TableParams } from '../../models/TableParams'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import BaseExternalRequests from '../BaseExternalRequests'

interface ExternalRequestsProps {
  eventId: number
  shouldRefreshList: () => void
}

const ExternalRequests = ({
  eventId,
  shouldRefreshList,
}: ExternalRequestsProps) => {
  const dispatch = useAppDispatch()
  const travels = useAppSelector(selectAllNotApprovedTravels())

  useEffect(() => {
    const baseQuery: TableParams = {
      ...initialQuery,
      filters: { events: [eventId] },
      columnKey: 'time',
      order: 'ascend',
    }
    dispatch(getNonApprovedTravels(baseQuery))
  }, [])

  if (travels.length === 0) return <></>

  return (
    <BaseExternalRequests
      entity={SBRMType.travel}
      items={travels}
      renderItem={(item) => (
        <Space size={10}>
          <Booking.Avatar id={item.booking} />
          <Travel.Type id={item.id} />
          <Travel.Infos model={item} />
          <Contact.AvatarGroup ids={item.contacts} />
        </Space>
      )}
      updateMethod={approveTravel}
      deleteMethod={deleteTravel}
      shouldRefreshList={shouldRefreshList}
    />
  )
}

type ExternalRequestsType = { ExternalRequests: typeof ExternalRequests }

export { ExternalRequests, type ExternalRequestsType }

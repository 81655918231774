import { useIntl } from 'react-intl'
import { Form, FormInstance, Input } from 'antd'
import {
  emailRule,
  requiredRule,
  validPhoneNumberRule,
} from '../../../utils/rules'
import TextArea from 'antd/es/input/TextArea'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

interface Props {
  form: FormInstance
}

const Create = ({ form }: Props) => {
  const intl = useIntl()

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="firstName"
        rules={[requiredRule(intl)]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.FirstName })}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="lastName"
        rules={[]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.LastName })}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[emailRule(intl)]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Email })}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="phone"
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Phone })}
        rules={[validPhoneNumberRule(intl)]}
      >
        <PhoneInput defaultCountry="FR" onChange={() => {}} />
      </Form.Item>
      <Form.Item
        name="comment"
        rules={[]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Comment })}
      >
        <TextArea rows={8} />
      </Form.Item>
    </Form>
  )
}

export default Create

import { commonStyle } from './style'
import { Text, View } from '@react-pdf/renderer'

interface Props {}

const Footer = ({}: Props) => {
  return (
    <View style={commonStyle.footer} fixed>
      <Text style={{ width: '50%', textAlign: 'left' }}>Made with ALELA</Text>
      <Text
        style={{ width: '50%', textAlign: 'right' }}
        render={({ pageNumber, totalPages }) =>
          `Page ${pageNumber} of ${totalPages}`
        }
      />
    </View>
  )
}

export default Footer

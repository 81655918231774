import { commonStyle } from './style'
import { Contact } from '../../../models/Contact'
import { View, Text, Link } from '@react-pdf/renderer'

interface Props {
  contact: Contact
}

const ContactTag = ({ contact }: Props) => {
  return (
    <View style={[commonStyle.tag]}>
      <Text>
        {contact.firstName} {contact.lastName}
      </Text>
      {contact.phone && (
        <Text>
          <Link src={`tel:${contact.phone}`}>{contact.phone}</Link>
        </Text>
      )}
    </View>
  )
}
export default ContactTag

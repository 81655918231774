import {
  FileOutlined,
  ControlOutlined,
  SettingOutlined,
  BarChartOutlined,
  SoundOutlined,
  TeamOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  UnorderedListOutlined,
  NotificationOutlined,
} from '@ant-design/icons'
import { Space, Tag, Typography } from 'antd'
import { Layout, Menu } from 'antd'
import { useIntl } from 'react-intl'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useAppSelector } from '../../reducers/hooks'
import { useHasAccess } from '../../components/HasAccess'
import { selectSelectedEvent } from '../../reducers/EventReducer'
import { extractSectionFromPath } from '../../utils/helpers/RouterHelpers'
import { AlelaPermission } from '../../utils/permissions'
import { Event } from '../../components/event/Event'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { NewTag } from '../../pages/misc/ReleaseNotesPage'

const { Sider } = Layout
const { Text } = Typography

interface Props {}

export const EventDetailsSider = ({}: Props) => {
  const intl = useIntl()
  const { pathname } = useLocation()
  const { hasAccess } = useHasAccess()

  const event = useAppSelector(selectSelectedEvent())

  const [selectedKey, setSelectedKey] = useState<string>('line-up')
  const [selectedSection, setSelectedSection] = useState<string>('line-up')

  const menuItems = [
    {
      key: 'line-up',
      label: (
        <Link to={'line-up'}>
          {intl.formatMessage({
            id: LocalizationKeys.Layout.EventDetailsSider.Lineup,
          })}
        </Link>
      ),
      icon: <SoundOutlined />,
      display: hasAccess([AlelaPermission.listBooking]),
    },
    {
      key: 'timetable',
      label: (
        <Link to={'timetable'}>
          {intl.formatMessage({
            id: LocalizationKeys.Layout.EventDetailsSider.Timetable,
          })}
        </Link>
      ),
      icon: <ClockCircleOutlined />,
      display: hasAccess([AlelaPermission.listPerformance]),
    },
    {
      key: 'administrative',
      label: intl.formatMessage({
        id: LocalizationKeys.Layout.EventDetailsSider.Admin,
      }),
      icon: <BarChartOutlined />,
      display:
        hasAccess([AlelaPermission.listContract]) ||
        hasAccess([AlelaPermission.listInvoice]),
      children: [
        ...(hasAccess([AlelaPermission.listContract])
          ? [
              {
                key: 'contracts',
                label: (
                  <Link to={'administrative/contracts'}>
                    {intl.formatMessage({
                      id: LocalizationKeys.Layout.EventDetailsSider.Contracts,
                    })}
                  </Link>
                ),
              },
            ]
          : []),
        ...(hasAccess([AlelaPermission.listInvoice])
          ? [
              {
                key: 'invoices',
                label: (
                  <Link to={'administrative/invoices'}>
                    {intl.formatMessage({
                      id: LocalizationKeys.Layout.EventDetailsSider.Invoices,
                    })}
                  </Link>
                ),
              },
            ]
          : []),
        ...(hasAccess([AlelaPermission.viewReport])
          ? [
              {
                key: 'reporting',
                label: (
                  <Link to={'administrative/reporting'}>
                    {intl.formatMessage({
                      id: LocalizationKeys.Layout.EventDetailsSider.Reporting,
                    })}
                  </Link>
                ),
              },
            ]
          : []),
      ],
    },
    {
      key: 'production',
      label: intl.formatMessage({
        id: LocalizationKeys.Layout.EventDetailsSider.Production,
      }),
      icon: <ControlOutlined />,
      display:
        hasAccess([AlelaPermission.listTravel]) ||
        hasAccess([AlelaPermission.listRoom]) ||
        hasAccess([AlelaPermission.listGround]) ||
        hasAccess([AlelaPermission.listRider]) ||
        hasAccess([AlelaPermission.listContactAssociation]),
      children: [
        ...(hasAccess([AlelaPermission.listTravel])
          ? [
              {
                key: 'travels',
                label: (
                  <Link to={'production/travels'}>
                    {intl.formatMessage({
                      id: LocalizationKeys.Layout.EventDetailsSider.Travels,
                    })}
                  </Link>
                ),
              },
            ]
          : []),
        ...(hasAccess([AlelaPermission.listRoom])
          ? [
              {
                key: 'hotels',
                label: (
                  <Link to={'production/hotels'}>
                    {intl.formatMessage({
                      id: LocalizationKeys.Layout.EventDetailsSider.Hotels,
                    })}
                  </Link>
                ),
              },
            ]
          : []),
        ...(hasAccess([AlelaPermission.listGround])
          ? [
              {
                key: 'ground',
                label: (
                  <Link to={'production/ground'}>
                    {intl.formatMessage({
                      id: LocalizationKeys.Layout.EventDetailsSider.Ground,
                    })}
                  </Link>
                ),
              },
            ]
          : []),
        ...(hasAccess([AlelaPermission.listRider])
          ? [
              {
                key: 'technical',
                label: (
                  <Link to={'production/technical'}>
                    {intl.formatMessage({
                      id: LocalizationKeys.Layout.EventDetailsSider.Technical,
                    })}
                  </Link>
                ),
              },
            ]
          : []),
        ...(hasAccess([AlelaPermission.listContactAssociation])
          ? [
              {
                key: 'party-crew',
                label: (
                  <Link to={'production/party-crew'}>
                    {intl.formatMessage({
                      id: LocalizationKeys.Layout.EventDetailsSider.PartyCrew,
                    })}
                  </Link>
                ),
              },
            ]
          : []),
      ],
    },
    {
      key: 'marketing',
      label: (
        <Space>
          <Link to={'marketing'}>Marketing</Link>
          <NewTag />
        </Space>
      ),
      icon: <NotificationOutlined />,
      display: hasAccess([AlelaPermission.listMarketingTask]),
    },
    {
      key: 'tasks',
      label: (
        <Link to={'tasks'}>
          {intl.formatMessage({
            id: LocalizationKeys.Layout.EventDetailsSider.Tasks,
          })}
        </Link>
      ),
      icon: <UnorderedListOutlined />,
      display: hasAccess([AlelaPermission.listTask]),
    },
    {
      key: 'files',
      label: (
        <Link to={'files'}>
          {intl.formatMessage({
            id: LocalizationKeys.Layout.EventDetailsSider.Files,
          })}
        </Link>
      ),
      icon: <FileOutlined />,
      display: hasAccess([AlelaPermission.listFile]),
    },
    {
      key: 'settings',
      label: (
        <Link to={'settings'}>
          {intl.formatMessage({
            id: LocalizationKeys.Layout.EventDetailsSider.Settings,
          })}
        </Link>
      ),
      icon: <SettingOutlined />,
      display:
        hasAccess([AlelaPermission.editEvent]) ||
        hasAccess([AlelaPermission.associateContactToEvent]),
    },
  ]

  useEffect(() => {
    const menuItemSelected = extractSectionFromPath(pathname, 3)
    setSelectedSection(menuItemSelected)
    setSelectedKey(
      menuItemSelected === 'administrative' || menuItemSelected === 'production'
        ? extractSectionFromPath(pathname, 4)
        : menuItemSelected
    )
  }, [pathname])

  return (
    <Sider width={250} breakpoint="lg" theme={'light'}>
      <Link to={'line-up'}>
        <Event.Cover id={event && event.id} />
      </Link>

      <div className="mx-6 py-4 d-none-lg hide-mobile">
        <Link to={'line-up'}>
          <Text className="bold">{event && event.name}</Text>
        </Link>
      </div>

      <Menu
        mode="inline"
        style={{ border: 'none' }}
        selectedKeys={[selectedKey]}
        defaultOpenKeys={[selectedSection]}
        items={menuItems.filter((i) => i.display === true)}
      />
    </Sider>
  )
}

import {
  Col,
  Form as ANTDForm,
  Input,
  Row,
  Button,
  Typography,
  Select,
  Divider,
  Spin,
} from 'antd'
import {
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useIntl } from 'react-intl'
import { Address } from '../../models/Address'
import { requiredRule } from '../../utils/rules'
import { useAppSelector } from '../../reducers/hooks'
import { selectAddressById } from '../../reducers/AddressReducer'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { CloseCircleFilled } from '@ant-design/icons'
import LocalizationKeys from '../../i18n/LocalizationKeys'

const { Text } = Typography

interface Props {
  name?: string
  initialValueId?: number
  initialValue?: Address
  handleClear?: () => void
  bypassRequiredRules?: boolean
  label?: string
  outsideSBRM?: boolean
  embededInCascaderItem?: boolean
}

export interface AddressFormInterface {
  fillFormWithAddress: (
    address: Address | undefined,
    setAutocompleteField: boolean
  ) => void
}

const Form = forwardRef(
  (
    {
      name = 'address',
      label = 'default_label',
      initialValueId,
      initialValue,
      handleClear = () => {},
      bypassRequiredRules = false,
      outsideSBRM = false,
      embededInCascaderItem = false,
    }: Props,
    ref: Ref<AddressFormInterface>
  ) => {
    useImperativeHandle(ref, () => ({
      fillFormWithAddress,
    }))

    const intl = useIntl()
    const form = ANTDForm.useFormInstance()

    const {
      placesService,
      placePredictions,
      getPlacePredictions,
      refreshSessionToken,
      autocompleteSessionToken,
      isPlacePredictionsLoading,
    } = usePlacesService({
      debounce: 1000,
      apiKey: process.env.REACT_APP_GOOGLE_PLACE_API_KEY,
      sessionToken: true,
    })

    const address = useAppSelector(selectAddressById(initialValueId ?? 0))
    const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

    const [showManualForm, setShowManualForm] = useState<boolean>(false)

    const fillFormWithAddress = (
      address: Address | undefined,
      setAutocompleteField: boolean = false
    ) => {
      form.setFieldValue([name, 'label'], address?.label)
      form.setFieldValue([name, 'street_number'], address?.street_number)
      form.setFieldValue([name, 'street'], address?.street)
      form.setFieldValue([name, 'city'], address?.city)
      form.setFieldValue([name, 'post_code'], address?.post_code)
      form.setFieldValue([name, 'state'], address?.state)
      form.setFieldValue([name, 'country'], address?.country)
      form.setFieldValue([name, 'latitude'], address?.latitude)
      form.setFieldValue([name, 'longitude'], address?.longitude)

      if (setAutocompleteField) {
        form.setFieldValue(
          [name, 'address_autocomplete_search'],
          address?.label
        )
      }
    }

    const extractAddressComponentFromPlaceDetails = (
      placeResult: google.maps.places.PlaceResult,
      needle: string
    ): string | undefined =>
      (placeResult.address_components ?? []).find(
        (element: google.maps.GeocoderAddressComponent) =>
          element.types.find((type: string) => type === needle)
      )?.long_name

    const onPlaceSelected = (placeId: string) => {
      placesService?.getDetails(
        {
          placeId: placeId,
          fields: ['address_components', 'geometry', 'name'],
          sessionToken: autocompleteSessionToken,
        },
        (placeDetails) => {
          if (!placeDetails) {
            return
          }
          refreshSessionToken() // Reset session token
          // Get label from placePredictions
          const label =
            placePredictions.find((place) => place.place_id === placeId)
              ?.description ??
            placeDetails.name ??
            ''
          fillFormWithAddress({
            id: 0,
            street_number: extractAddressComponentFromPlaceDetails(
              placeDetails,
              'street_number'
            ),
            street: extractAddressComponentFromPlaceDetails(
              placeDetails,
              'route'
            ),
            state: extractAddressComponentFromPlaceDetails(
              placeDetails,
              'administrative_area_level_2'
            ),
            city: extractAddressComponentFromPlaceDetails(
              placeDetails,
              'locality'
            ),
            country: extractAddressComponentFromPlaceDetails(
              placeDetails,
              'country'
            ),
            post_code: extractAddressComponentFromPlaceDetails(
              placeDetails,
              'postal_code'
            ),
            label: label,
            longitude: (placeDetails.geometry?.location?.lng() ?? 0).toString(),
            latitude: (placeDetails.geometry?.location?.lat() ?? 0).toString(),
          })
        }
      )
    }

    useEffect(() => {
      if (outsideSBRM) {
        // Do not proceed when the form is not in the SBRM
        return
      }
      // Reset form when SBRM is closed
      if (!SBRMIsOpen) {
        fillFormWithAddress(undefined, true)
        setShowManualForm(false)
      }
    }, [SBRMIsOpen])

    useEffect(() => {
      if ((!outsideSBRM && !SBRMIsOpen) || !initialValue) {
        return
      }
      // Fill address only when SBRM is opened
      // And there is an initialValue
      fillFormWithAddress(initialValue, true)
    }, [initialValue, SBRMIsOpen])

    useEffect(() => {
      if ((!outsideSBRM && !SBRMIsOpen) || !initialValueId || !address) {
        return
      }
      // Fill address only when SBRM is opened
      // And there is an initialValueId and a corresponding address
      fillFormWithAddress(address, true)
    }, [initialValueId, SBRMIsOpen])

    return (
      <>
        <ANTDForm.Item
          style={{ marginBottom: embededInCascaderItem ? 0 : undefined }}
          rules={
            showManualForm || bypassRequiredRules ? [] : [requiredRule(intl)]
          }
          label={
            label === 'default_label'
              ? intl.formatMessage({
                  id: LocalizationKeys.Misc.Form.Address.FormLabel,
                })
              : label
          }
          name={[name, 'address_autocomplete_search']}
        >
          <Select
            allowClear
            showSearch
            filterOption={false}
            defaultActiveFirstOption={false}
            placeholder={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Address.Search,
            })}
            loading={isPlacePredictionsLoading}
            onClear={() => {
              handleClear()
            }}
            suffixIcon={
              <CloseCircleFilled
                onClick={() => {
                  handleClear()
                }}
              />
            }
            onChange={(place_id) => {
              place_id && onPlaceSelected(place_id)
            }}
            onSearch={(value) => {
              getPlacePredictions({
                input: value,
              })
            }}
            options={placePredictions.map((d) => ({
              value: d.place_id,
              label: d.description,
            }))}
            dropdownRender={(menu) => (
              <>
                {isPlacePredictionsLoading && (
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <Spin size="small" />
                  </div>
                )}
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <Button
                  style={{ marginBottom: '5px' }}
                  type="text"
                  color="secondary"
                  onClick={() => setShowManualForm(!showManualForm)}
                >
                  <Text type="secondary" italic underline>
                    {intl.formatMessage({
                      id: showManualForm
                        ? LocalizationKeys.Misc.Form.Address.CloseManual
                        : LocalizationKeys.Misc.Form.Address.OpenManual,
                    })}
                  </Text>
                </Button>
              </>
            )}
          />
        </ANTDForm.Item>

        <div style={{ display: showManualForm ? 'block' : 'none' }}>
          <ANTDForm.Item
            name={[name, 'label']}
            label={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Address.Label,
            })}
            rules={bypassRequiredRules ? [] : [requiredRule(intl)]}
          >
            <Input />
          </ANTDForm.Item>
          <Row gutter={16}>
            <Col md={6}>
              <ANTDForm.Item
                name={[name, 'street_number']}
                label={intl.formatMessage({
                  id: LocalizationKeys.Misc.Form.Address.StreetNumber,
                })}
                rules={[]}
              >
                <Input />
              </ANTDForm.Item>
            </Col>
            <Col md={18}>
              <ANTDForm.Item
                name={[name, 'street']}
                label={intl.formatMessage({
                  id: LocalizationKeys.Misc.Form.Address.Street,
                })}
                rules={[]}
              >
                <Input />
              </ANTDForm.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={6}>
              <ANTDForm.Item
                name={[name, 'city']}
                label={intl.formatMessage({
                  id: LocalizationKeys.Misc.Form.Address.City,
                })}
                rules={[]}
              >
                <Input />
              </ANTDForm.Item>
            </Col>
            <Col md={6}>
              <ANTDForm.Item
                name={[name, 'post_code']}
                label={intl.formatMessage({
                  id: LocalizationKeys.Misc.Form.Address.PostCode,
                })}
                rules={[]}
              >
                <Input />
              </ANTDForm.Item>
            </Col>
            <Col md={6}>
              <ANTDForm.Item
                name={[name, 'state']}
                label={intl.formatMessage({
                  id: LocalizationKeys.Misc.Form.Address.State,
                })}
                rules={[]}
              >
                <Input />
              </ANTDForm.Item>
            </Col>
            <Col md={6}>
              <ANTDForm.Item
                name={[name, 'country']}
                label={intl.formatMessage({
                  id: LocalizationKeys.Misc.Form.Address.Country,
                })}
                rules={[]}
              >
                <Input />
              </ANTDForm.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginBottom: '-20px' }}>
            <Col md={12}>
              <ANTDForm.Item
                name={[name, 'latitude']}
                label={intl.formatMessage({
                  id: LocalizationKeys.Misc.Form.Address.Latitude,
                })}
                rules={bypassRequiredRules ? [] : [requiredRule(intl)]}
              >
                <Input />
              </ANTDForm.Item>
            </Col>
            <Col md={12}>
              <ANTDForm.Item
                name={[name, 'longitude']}
                label={intl.formatMessage({
                  id: LocalizationKeys.Misc.Form.Address.Longitude,
                })}
                rules={bypassRequiredRules ? [] : [requiredRule(intl)]}
              >
                <Input />
              </ANTDForm.Item>
            </Col>
          </Row>
        </div>
      </>
    )
  }
)

Form.displayName = 'Form'
export { Form }

export type FormType = { Form: typeof Form }

import { useEffect } from 'react'
import { Form, FormInstance, Input } from 'antd'
import { requiredRule } from '../../../utils/rules'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../reducers/hooks'
import { selectSelectedSupplier } from '../../../reducers/SupplierReducer'
import { SBAsyncSelect } from '../../../components/custom-inputs/sb-async-select/SBAsyncSelect'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

interface Props {
  form: FormInstance
}

const Update = ({ form }: Props) => {
  const intl = useIntl()
  const supplier = useAppSelector(selectSelectedSupplier())

  useEffect(() => {
    form.setFieldsValue(supplier)
  }, [supplier])

  if (supplier === undefined) {
    return <></>
  }

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="id"
        style={{ display: 'none' }}
        rules={[requiredRule(intl)]}
      >
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        name="name"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Name,
        })}
        rules={[requiredRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="registrationNumber"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.RegistrationNumber,
        })}
        rules={[]}
      >
        <Input />
      </Form.Item>

      <SBAsyncSelect
        type={SBRMType.supplierType}
        name={'type'}
        size={'middle'}
        rules={[]}
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Type,
        })}
      />
    </Form>
  )
}

export default Update

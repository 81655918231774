import dayjs from 'dayjs'
import { Alert, Button, Space, Typography } from 'antd'
import { Link } from 'react-router-dom'
import { Event } from '../../models/Event'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { EuroCircleOutlined, MoneyCollectOutlined } from '@ant-design/icons'

interface Props {
  event?: Event
  style?: React.CSSProperties
}

const { Title, Text } = Typography

const EventAlert = ({ event, style }: Props) => {
  const intl = useIntl()

  if (!event) return <></>

  const eventIsPast = dayjs(event.end_date) < dayjs(Date())

  return eventIsPast ? (
    <Alert
      style={{
        ...style,
        alignItems: 'center',
        backgroundColor: '#1a1223',
        border: '1px solid #5762bb',
      }}
      type="info"
      showIcon
      icon={
        <EuroCircleOutlined
          style={{
            fontSize: 25,
            color: '#5762bb',
            marginLeft: 5,
            marginRight: 15,
          }}
        />
      }
      message={
        <Space
          direction="vertical"
          size={0}
          style={{ paddingTop: 10, paddingBottom: 10, width: '100%' }}
        >
          <Text style={{ fontSize: 16, fontWeight: 'bold' }}>
            {intl.formatMessage({
              id: LocalizationKeys.Components.Reporting.EventAlert.Title,
            })}
          </Text>
          <Text>
            {intl.formatMessage({
              id: LocalizationKeys.Components.Reporting.EventAlert.Description,
            })}
          </Text>
        </Space>
      }
      action={
        <Link to={`/events/${event.id}/administrative/reporting`}>
          <Button type="primary">
            {intl.formatMessage({
              id: LocalizationKeys.Components.Reporting.EventAlert.Button,
            })}
          </Button>
        </Link>
      }
    />
  ) : (
    <></>
  )
}

type EventAlertType = { EventAlert: typeof EventAlert }

export { EventAlert, type EventAlertType }

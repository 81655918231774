import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { getMetadata } from '../SBRMModel'
import { emailRule, requiredRule } from '../../../utils/rules'
import { MetaDataKey } from '../../../models/MetaData'
import { useAppSelector } from '../../../reducers/hooks'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { Typography, Form, FormInstance, Input, Space } from 'antd'
import { DatePickerWithEventOutlined } from '../../../components/custom-inputs/DatePickerWithEventOutlined'
import { externalAdvancingSections } from '../../external-advancing/types'
import { selectEventById } from '../../../reducers/EventReducer'
import SectionFormItem from './components/SectionFormItem'
import dayjs from 'dayjs'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'

const { Text } = Typography

interface Props {
  form: FormInstance
  eventId?: number
}

const Create = ({ form, eventId }: Props) => {
  const intl = useIntl()

  const { isOpen: SBRMIsOpen, metadata } = useAppSelector((state) => state.SBRM)
  const event = useAppSelector(
    selectEventById(getMetadata(metadata, MetaDataKey.eventId) ?? eventId)
  )

  useEffect(() => {
    //Trigger reset only when SBRM is open
    if (!SBRMIsOpen) return

    form.setFieldValue('booking', getMetadata(metadata, MetaDataKey.bookingId))
  }, [SBRMIsOpen])

  useEffect(() => {
    //Trigger reset only when SBRM is open
    if (!event) return

    form.setFieldValue('deadline', dayjs(event?.start_date))
  }, [event])

  const [displayMoreOption, setDisplayMoreOption] = useState(false)

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="booking"
        rules={[requiredRule(intl)]}
        style={{ display: 'none' }}
      >
        <Input type="hidden" />
      </Form.Item>

      <Space direction="vertical" className="w-full">
        <Form.Item
          name={'email'}
          label={intl.formatMessage({
            id: LocalizationKeys.Misc.Form.Email,
          })}
          rules={[requiredRule(intl), emailRule(intl)]}
          style={{ marginBottom: 5 }}
        >
          <Input />
        </Form.Item>

        <Text style={{ margin: 0 }}>
          {intl.formatMessage({
            id: LocalizationKeys.ExternalAdvancingManager.Create
              .RestrictionsAndCapabilities,
          })}
        </Text>
        {externalAdvancingSections.map((section) => (
          <SectionFormItem key={section.key} section={section} />
        ))}

        <Space
          style={{
            justifyContent: 'center',
            width: '100%',
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <Text
            style={{ cursor: 'pointer' }}
            type="secondary"
            onClick={() => setDisplayMoreOption(!displayMoreOption)}
          >
            {displayMoreOption ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
            {intl.formatMessage({
              id: LocalizationKeys.ExternalAdvancingManager.Create.MoreOptions,
            })}
          </Text>
        </Space>

        {event !== undefined && displayMoreOption && (
          <Form.Item
            name={'deadline'}
            label={intl.formatMessage({
              id: LocalizationKeys.ExternalAdvancingManager.Create
                .DeadlineLabel,
            })}
            extra={intl.formatMessage({
              id: LocalizationKeys.ExternalAdvancingManager.Create
                .DeadlineDescription,
            })}
          >
            <DatePickerWithEventOutlined event={event} />
          </Form.Item>
        )}
      </Space>
    </Form>
  )
}

export default Create

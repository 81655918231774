import {
  getInvoices,
  selectInvoices,
  selectInvoicesByIds,
  setInvoiceQuery,
} from '../../reducers/InvoiceReducer'
import dayjs from 'dayjs'
import SBTable from '../SBTable'
import { File } from '../file/File'
import { Invoice } from './Invoice'
import { Event } from '../event/Event'
import { AddPayment } from '@supplement-bacon/alela-uikit'
import { Booking } from '../booking/Booking'
import { Supplier } from '../supplier/Supplier'
import { MetaDataKey } from '../../models/MetaData'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { Button, Space, Tooltip, Typography } from 'antd'
import { Invoice as InvoiceModel } from '../../models/Invoice'
import { Actions } from '../../modules/sbrm/components/Actions'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { extractSortDataFromSorterResult } from '../../utils/table/sorter'
import { MetaData } from '../../models/MetaData'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { addUrlParams } from '../../modules/sbrm/UrlHelper'
import { useSearchParams } from 'react-router-dom'

const { Text } = Typography

interface Props {
  ids?: number[]
  displayBooking?: boolean
  displayEvent?: boolean
  displaySupplier?: boolean
  actionsInDropdown?: boolean
  emptyActionsMetadata?: MetaData[]
}

export const Table = ({
  ids,
  displayBooking = false,
  displayEvent = false,
  displaySupplier = false,
  actionsInDropdown = false,
  emptyActionsMetadata,
}: Props) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const invoices = useAppSelector(
    ids ? selectInvoicesByIds(ids) : selectInvoices()
  )

  const { query, isLoading } = useAppSelector((state) => state.invoice)

  const columns: ColumnsType<InvoiceModel> = [
    {
      key: 'reference',
      sorter: true,
      title: intl.formatMessage({
        id: LocalizationKeys.Components.Invoice.Table.Reference,
      }),
      width: 140,
      render: (record: InvoiceModel) => (
        <Space direction="vertical">
          <Invoice.ReferenceTag model={record} />
          <Invoice.CategoryTag model={record} />
        </Space>
      ),
    },
    ...(displaySupplier
      ? [
          {
            key: 'supplier',
            sorter: true,
            title: intl.formatMessage({
              id: LocalizationKeys.Components.Invoice.Table.Supplier,
            }),
            render: (record: InvoiceModel) => (
              <a href="#" onClick={(e) => e.stopPropagation()}>
                <Supplier.Avatar id={record.supplier} linkToDetails />
              </a>
            ),
          },
        ]
      : []),
    {
      key: 'amount',
      sorter: true,
      title: intl.formatMessage({
        id: LocalizationKeys.Components.Invoice.Table.Amount,
      }),
      render: (record: InvoiceModel) => <Invoice.AmountsTag model={record} />,
    },
    {
      key: 'dueDate',
      sorter: true,
      title: intl.formatMessage({
        id: LocalizationKeys.Components.Invoice.Table.DueDate,
      }),
      width: 140,
      render: (record: InvoiceModel) => (
        <Text>{dayjs(record.dueDate).format('DD-MM-YYYY')}</Text>
      ),
    },
    ...(displayEvent
      ? [
          {
            key: 'event',
            sorter: true,
            title: intl.formatMessage({
              id: LocalizationKeys.Components.Invoice.Table.Event,
            }),
            render: (record: InvoiceModel) => (
              <a href="#" onClick={(e) => e.stopPropagation()}>
                <Event.Cell id={record.eventId ?? 0} ellipsisName={true} />
              </a>
            ),
          },
        ]
      : []),
    ...(displayBooking
      ? [
          {
            key: 'booking',
            title: intl.formatMessage({
              id: LocalizationKeys.Components.Invoice.Table.LinkedTo,
            }),
            render: (record: InvoiceModel) => (
              <a href="#" onClick={(e) => e.stopPropagation()}>
                <Booking.Avatar id={record.booking ?? 0} />
              </a>
            ),
          },
        ]
      : []),
    {
      key: 'actions',
      align: 'right',
      render: (record: InvoiceModel) => (
        <a href="#" onClick={(e) => e.stopPropagation()}>
          <Space direction="horizontal">
            {/* Make payment button */}
            <Actions
              actions={['create']}
              entity={SBRMType.payment}
              metadata={[{ key: MetaDataKey.invoiceId, value: record.id }]}
              trigger={
                <Tooltip
                  title={intl.formatMessage({
                    id: LocalizationKeys.Components.Invoice.Table.AddPayment,
                  })}
                >
                  <Button className="w-full" icon={<AddPayment />} />
                </Tooltip>
              }
            />

            {/* Download invoice file button */}
            {record.files.length > 0 && (
              <File.DownloadButton id={record.files[0]} />
            )}

            {/* Invoice actions buttons */}
            <Actions
              layout={actionsInDropdown ? 'dropdown' : undefined}
              actions={['update', 'comment', 'delete']}
              entity={SBRMType.invoice}
              entityId={record.id}
            />
          </Space>
        </a>
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<InvoiceModel> | SorterResult<InvoiceModel>[]
  ) => {
    const newQuery = {
      ...query,
      pagination,
      filters: { ...query.filters, ...filters },
      ...extractSortDataFromSorterResult(sorter),
    }
    dispatch(setInvoiceQuery(newQuery))
    dispatch(getInvoices(newQuery))
  }

  return (
    <SBTable
      entity={SBRMType.invoice}
      scroll={{ x: 1000 }}
      columns={columns}
      className="table-row-clickableinvoices-table"
      rowKey={(record) => record.id}
      dataSource={invoices}
      loading={isLoading && !invoices.length}
      pagination={query.pagination}
      onChange={handleTableChange}
      emptyActionsMetadata={emptyActionsMetadata}
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            addUrlParams(
              {
                action: 'view',
                entity: SBRMType.invoice,
                entityId: record.id.toString(),
              },
              undefined,
              setSearchParams
            )
          },
        }
      }}
    />
  )
}

export type TableType = { Table: typeof Table }

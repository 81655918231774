import {
  selectSelectedEvent,
  getEventFinancialWithId,
} from '../../../reducers/EventReducer'
import {
  getInvoices,
  selectInvoices,
  setInvoiceQuery,
} from '../../../reducers/InvoiceReducer'
import { useEffect, useState } from 'react'
import { Card, Col, Progress, Row, Space, Statistic, Typography } from 'antd'
import { PageTitle, currencyFormat } from '@supplement-bacon/alela-uikit'
import HasAccess from '../../../components/HasAccess'
import { EventFinancial } from '../../../models/Event'
import { MetaDataKey } from '../../../models/MetaData'
import { TableParams } from '../../../models/TableParams'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import { AlelaPermission } from '../../../utils/permissions'
import { Invoice } from '../../../components/invoice/Invoice'
import { initialQuery } from '../../../utils/helpers/crud/models'
import { Actions } from '../../../modules/sbrm/components/Actions'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { HelpArticle } from '../../../utils/hooks/useAlelaHelp'
import { Help } from '../../../components/help/Help'

const { Text } = Typography
const InvoicesPage = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)
  const [financialIsLoading, setFinancialIsLoading] = useState<boolean>(false)
  const [financialData, setFinancialData] = useState<
    EventFinancial | undefined
  >(undefined)

  const event = useAppSelector(selectSelectedEvent())
  const invoices = useAppSelector(selectInvoices())

  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)
  const { query } = useAppSelector((state) => state.invoice)

  const fetchFinancialData = () => {
    setFinancialIsLoading(true)
    getEventFinancialWithId(event!.id).then((data) => {
      setFinancialData(data.data)
      setFinancialIsLoading(false)
    })
  }

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      return
    }
    setIsFirstRender(false)

    const baseQuery: TableParams = {
      ...initialQuery,
      filters: { events: [event?.id!] },
      with: ['sumPaid', 'supplier', 'category', 'booking'],
    }

    dispatch(setInvoiceQuery(baseQuery))
    dispatch(getInvoices(baseQuery))
    fetchFinancialData()
  }, [SBRMIsOpen])

  return (
    <Row className="container-row">
      <Col span={24}>
        <HasAccess permissions={[AlelaPermission.listInvoice]}>
          <PageTitle
            title={intl.formatMessage({
              id: LocalizationKeys.Page.EventDetails.Invoices.Title,
            })}
            level={5}
            badge={invoices.length}
            toolbar={
              <Space>
                <Help.Button
                  article={[
                    HelpArticle.HowToAddAnInvoice,
                    HelpArticle.HowToMarkAnInvoiceAsPaid,
                  ]}
                />
                <Actions
                  actions={['create']}
                  entity={SBRMType.invoice}
                  metadata={[{ key: MetaDataKey.eventId, value: event?.id }]}
                />
              </Space>
            }
          />
          <Row gutter={[16, 16]} style={{ marginBottom: 10 }}>
            <Col xs={24} md={12} lg={6}>
              <Card bordered={false}>
                <Statistic
                  title={intl.formatMessage({
                    id: LocalizationKeys.Page.EventDetails.Invoices
                      .InvoicesPaid,
                  })}
                  loading={financialIsLoading}
                  valueStyle={{ color: '#68BE36' }}
                  value={financialData?.invoicesPaidCount}
                />
              </Card>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Card bordered={false}>
                <Statistic
                  title={intl.formatMessage({
                    id: LocalizationKeys.Page.EventDetails.Invoices
                      .InvoicesToPay,
                  })}
                  loading={financialIsLoading}
                  valueStyle={{ color: '#e89a3c' }}
                  value={financialData?.invoicesToPayCount}
                />
              </Card>
            </Col>
            {financialData?.sumByCurrency.map((byCurrency) => (
              <Col xs={24} md={12} lg={6} key={byCurrency.currency.id}>
                <Card bordered={false}>
                  <Statistic
                    title={
                      <Space
                        className="w-full"
                        style={{ justifyContent: 'space-between' }}
                      >
                        <Text type="secondary">{byCurrency.currency.name}</Text>
                        <Progress
                          showInfo={false}
                          style={{ width: 70 }}
                          percent={(byCurrency.paid / byCurrency.toPay) * 100}
                          strokeColor={
                            byCurrency.paid != byCurrency.toPay
                              ? '#e89a3c'
                              : undefined
                          }
                        />
                      </Space>
                    }
                    loading={financialIsLoading}
                    valueStyle={{
                      color:
                        byCurrency.paid != byCurrency.toPay
                          ? '#e89a3c'
                          : '#68BE36',
                    }}
                    value={
                      byCurrency.paid == 0 ||
                      byCurrency.paid == byCurrency.toPay
                        ? currencyFormat(
                            byCurrency.toPay,
                            byCurrency.currency.symbol
                          )
                        : ` ${currencyFormat(
                            byCurrency.paid,
                            byCurrency.currency.symbol
                          )} / ${currencyFormat(
                            byCurrency.toPay,
                            byCurrency.currency.symbol
                          )}`
                    }
                  />
                </Card>
              </Col>
            ))}
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12} lg={6}>
              <Invoice.ExternalRequests
                eventId={event?.id ?? 0}
                shouldRefreshList={() => {
                  dispatch(getInvoices(query))
                  fetchFinancialData()
                }}
              />
            </Col>
            <Col span={24}>
              <Invoice.Table
                displayBooking
                displaySupplier
                emptyActionsMetadata={[
                  {
                    key: MetaDataKey.eventId,
                    value: event!.id,
                  },
                ]}
              />
            </Col>
          </Row>
        </HasAccess>
      </Col>
    </Row>
  )
}

export default InvoicesPage

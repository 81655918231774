import { useEffect } from 'react'
import { Col, Row, Space, Spin } from 'antd'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import HasAccess from '../../components/HasAccess'
import { AlelaPermission } from '../../utils/permissions'
import { initialQuery } from '../../utils/helpers/crud/models'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { Reporting } from '../../components/reporting/Reporting'
import {
  getReports,
  resetReportQueryAndIds,
  selectReports,
  setReportQuery,
} from '../../reducers/ReportReducer'

const ReportsPage = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const { isLoading } = useAppSelector((state) => state.report)

  useEffect(() => {
    dispatch(resetReportQueryAndIds(initialQuery))
  }, [])

  const reports = useAppSelector(selectReports())

  return (
    <HasAccess permissions={[AlelaPermission.listReport]}>
      <PageTitle
        title={intl.formatMessage({
          id: LocalizationKeys.Page.Financials.Reports.Title,
        })}
      />
      <Reporting.Filters
        queryChanged={(query) => {
          dispatch(setReportQuery(query))
          dispatch(getReports(query))
        }}
      />
      <Spin spinning={isLoading}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Reporting.Overview reports={reports} />
          </Col>
        </Row>
      </Spin>
    </HasAccess>
  )
}

export default ReportsPage

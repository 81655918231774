import { useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { selectSelectedEvent } from '../../reducers/EventReducer'
import { Kanban } from '../../components/kanban/Kanban'
import HasAccess from '../../components/HasAccess'
import { AlelaPermission } from '../../utils/permissions'
import { convertEventMarketingToKanbanData } from '../../components/kanban/utils'
import {
  getMarketingTaskStatuses,
  resetMarketingTaskStatusState,
  selectAllMarketingTaskStatus,
  setMarketingTaskStatusQuery,
} from '../../reducers/MarketingTaskStatusReducer'
import { MarketingTask } from '../../components/marketing-task/MarketingTask'
import {
  createMarketingTask,
  getMarketingTasks,
  resetMarketingTaskState,
  selectAllMarketingTasks,
  selectMarketingTasksByStatusId,
  updateMarketingTask,
} from '../../reducers/MarketingTaskReducer'
import { MarketingTask as MarketingTaskModel } from '../../models/MarketingTask'
import { TableParams } from '../../models/TableParams'
import { initialQuery } from '../../utils/helpers/crud/models'

const MarketingPage = () => {
  const dispatch = useAppDispatch()
  const event = useAppSelector(selectSelectedEvent())
  const tasks = useAppSelector(selectAllMarketingTasks())
  const statuses = useAppSelector(selectAllMarketingTaskStatus())
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const fetchStatuses = () => {
    const baseQuery: TableParams = {
      ...initialQuery,
      pagination: { current: 1, pageSize: 1000 },
      columnKey: 'order',
      order: 'ascend',
    }

    dispatch(setMarketingTaskStatusQuery(baseQuery))
    dispatch(getMarketingTaskStatuses(baseQuery))
  }

  useEffect(() => {
    if (SBRMIsOpen) {
      return
    }

    fetchStatuses()
  }, [SBRMIsOpen])

  useEffect(() => {
    if (!isFirstRender) {
      return
    }
    setIsFirstRender(false)

    // First of all clear store
    dispatch(resetMarketingTaskState())
    dispatch(resetMarketingTaskStatusState())
  }, [])

  if (statuses.length === 0) {
    return <></>
  }

  return (
    <HasAccess permissions={[AlelaPermission.listMarketingTask]}>
      <Row>
        <Col md={24} sm={24} xs={24}>
          <Kanban
            showQuickadd
            orderAttribute={'order'}
            columnToolbar={(columnId) => <></>}
            fetchCardsForLane={getMarketingTasks}
            renderItem={(item) => <MarketingTask.KanbanCard id={item.id} />}
            initialValue={convertEventMarketingToKanbanData<MarketingTaskModel>(
              statuses
            )}
            cardsSelectorForLane={(lane) =>
              selectMarketingTasksByStatusId(lane.id)
            }
            getInitialQueryForLane={(lane) => ({
              ...initialQuery,
              pagination: { current: 1, pageSize: 20 },
              filters: { status: [lane.id], events: [event?.id ?? 0] },
              columnKey: 'order',
              order: 'ascend',
            })}
            itemMoved={(draggableId, newColumnId, newIndex) => {
              dispatch(
                updateMarketingTask({
                  ...tasks.find((e) => e.id === Number(draggableId)),
                  status: newColumnId,
                  order: newIndex + 1, // Index starts at 0, order starts at 1
                  updateOrigin: 'event',
                } as MarketingTaskModel)
              )
            }}
            quickAddSubmited={(content, columnId) =>
              dispatch(
                createMarketingTask({
                  id: 0,
                  title: content,
                  status: columnId,
                  event: event?.id,
                  order: 0,
                } as MarketingTaskModel)
              )
            }
          >
            <Kanban.Board />
          </Kanban>
        </Col>
      </Row>
    </HasAccess>
  )
}

export default MarketingPage

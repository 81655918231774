import { Draggable } from 'react-beautiful-dnd'
import type {
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd'
import List from './List'
import styled from 'styled-components'
import { Badge, Card, Space, Spin, Typography } from 'antd'
import { KanbanQuickForm } from './QuickForm'
import { Lane } from '../../models/Kanban'
import { composeColumnId } from './utils'
import { useKanban } from './context'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { TableParams } from '../../models/TableParams'

const { Text } = Typography

const Container = styled.div`
  margin: 15px;
  display: flex;
  flex-direction: column;
`

type ColumnProps<T> = {
  lane: Lane<T>
  index: number
  isScrollable?: boolean
  isCombineEnabled?: boolean
  useClone?: boolean
}

const Column = <T,>({
  index,
  lane,
  isScrollable = true,
  isCombineEnabled,
  useClone,
}: ColumnProps<T>) => {
  const {
    columnToolbar,
    showQuickadd,
    fetchCardsForLane,
    cardsSelectorForLane,
    quickAddSubmited,
    getInitialQueryForLane,
    orderAttribute,
  } = useKanban()
  const PAGE_SIZE = 20
  const dispatch = useAppDispatch()
  const columnId = composeColumnId(lane.id)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [query, setQuery] = useState<TableParams>(getInitialQueryForLane(lane))

  const cards = (useAppSelector(cardsSelectorForLane(lane)) as any)
    .sort((a: any, b: any) => a[orderAttribute] - b[orderAttribute])
    .map((t: { id: any }) => ({
      id: t.id,
      rawItem: t,
    }))

  const shouldIncreasePage = (): boolean =>
    cards.length % PAGE_SIZE === 0 && // Ensure top of the page
    cards.length < (query.pagination?.total ?? 0) // Ensure

  const fetchCards = (query: TableParams) => {
    setIsLoading(true)
    dispatch(fetchCardsForLane(query))
      .then((result) => {
        setQuery({
          ...query,
          pagination: { ...query.pagination, total: result.totalRecords },
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    fetchCards(query)
  }, [lane])

  return (
    <Draggable index={index} draggableId={columnId}>
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <Container ref={provided.innerRef} {...provided.draggableProps}>
          <Card
            styles={{
              header: { padding: '0 10px 0 15px' },
              body: { padding: 10, paddingTop: 0 },
            }}
            style={{
              borderColor: snapshot.isDragging ? '#5e5df6' : 'transparent',
            }}
            title={
              <Space
                className="w-full"
                style={{ justifyContent: 'space-between' }}
              >
                <Space>
                  <Badge count={query.pagination?.total} />
                  <Text
                    {...provided.dragHandleProps}
                    aria-label={`${lane.title} tasks list`}
                  >
                    {lane.title}
                  </Text>
                  {isLoading && <Spin />}
                </Space>
                {columnToolbar(lane.id)}
              </Space>
            }
          >
            <List
              listId={columnId}
              listType="QUOTE"
              cards={cards}
              internalScroll={isScrollable}
              isCombineEnabled={Boolean(isCombineEnabled)}
              useClone={Boolean(useClone)}
              isLoading={isLoading}
              hasMore={
                !query.pagination || !query.pagination.total
                  ? true
                  : query.pagination.total! > cards.length
              }
              fetchNext={() => {
                const nextQuery: TableParams = {
                  ...query,
                  pagination: {
                    current: 1,
                    pageSize: query.pagination!.pageSize! + PAGE_SIZE,
                  },
                }
                setQuery(nextQuery)
                fetchCards(nextQuery)
              }}
            />
            {showQuickadd && (
              <KanbanQuickForm
                columnId={lane.id}
                onSubmit={(content) => {
                  quickAddSubmited(content, lane.id).then(() => {
                    const nextQuery: TableParams = {
                      ...query,
                      pagination: {
                        current: 1,
                        pageSize:
                          query.pagination!.pageSize! +
                          (shouldIncreasePage() ? PAGE_SIZE : 0),
                      },
                    }
                    setQuery(nextQuery)
                    fetchCards(nextQuery)
                  })
                }}
              />
            )}
          </Card>
        </Container>
      )}
    </Draggable>
  )
}

export default Column

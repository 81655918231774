import { Typography } from 'antd'
import {
  STATION_TYPE_AIRPORT,
  StationWithRelation as StationWithRelationModel,
} from '../../models/Station'
import { Travel } from '@supplement-bacon/alela-uikit'

interface Props {
  item: StationWithRelationModel
}

export const Option = ({ item }: Props) => {
  return (
    <>
      {item.type.id === STATION_TYPE_AIRPORT ? (
        <Travel.SVG.FlightOutbound />
      ) : (
        <Travel.SVG.TrainInbound />
      )}
      <Typography.Text style={{ marginLeft: 10 }}>{item.name}</Typography.Text>
    </>
  )
}

export type OptionType = { Option: typeof Option }

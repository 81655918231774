import { useIntl } from 'react-intl'
import { DatePicker, Form, FormInstance, Input } from 'antd'
import { numberRule, requiredRule, stringRule } from '../../../utils/rules'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { SBAsyncSelect } from '../../../components/custom-inputs/sb-async-select/SBAsyncSelect'
import { SBRMType, getMetadata } from '../../../modules/sbrm/SBRMModel'
import { useAppSelector } from '../../../reducers/hooks'
import { useEffect } from 'react'
import { MetaDataKey } from '../../../models/MetaData'
import TextArea from 'antd/es/input/TextArea'

interface Props {
  form: FormInstance
}

const Create = ({ form }: Props) => {
  const intl = useIntl()
  const { isOpen: SBRMIsOpen, metadata } = useAppSelector((state) => state.SBRM)

  const eventId = getMetadata(metadata, MetaDataKey.eventId)

  useEffect(() => {
    //Trigger reset only when SBRM is open
    if (!SBRMIsOpen) return

    form.setFieldValue('event', eventId)
    form.setFieldValue(
      'status',
      getMetadata(metadata, MetaDataKey.marketingTaskStatusId)
    )
  }, [SBRMIsOpen])

  return (
    <Form form={form} layout="vertical">
      {!eventId && (
        <SBAsyncSelect
          type={SBRMType.booking}
          name={'event'}
          label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Event })}
          rules={[requiredRule(intl)]}
        />
      )}
      {eventId && (
        <Form.Item
          name="event"
          className="d-none"
          rules={[requiredRule(intl), numberRule(intl)]}
        >
          <Input type="hidden" />
        </Form.Item>
      )}

      {/* Attributes */}
      <Form.Item
        name="title"
        rules={[requiredRule(intl), stringRule(intl)]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Title })}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="description"
        rules={[stringRule(intl)]}
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Description,
        })}
      >
        <TextArea rows={5} />
      </Form.Item>
      <Form.Item
        rules={[]}
        name="dueDate"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.DueDate,
        })}
      >
        <DatePicker format="YYYY-MM-DD" className="w-full" />
      </Form.Item>
      <SBAsyncSelect
        size="middle"
        name={'status'}
        rules={[]}
        type={SBRMType.marketingTaskStatus}
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Status,
        })}
      />
      <SBAsyncSelect
        rules={[]}
        name={'user'}
        type={SBRMType.user}
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Assignee,
        })}
      />
    </Form>
  )
}

export default Create

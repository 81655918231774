import dayjs from 'dayjs'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import { requiredRule, stringRule } from '../../../utils/rules'
import { MetaDataKey } from '../../../models/MetaData'
import { useAppSelector } from '../../../reducers/hooks'
import {
  Card,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Radio,
  Space,
  Switch,
} from 'antd'
import { selectSelectedTravel } from '../../../reducers/TravelReducer'
import { TRAVEL_TYPE_FLIGHT, TRAVEL_TYPE_TRAIN } from '../../../models/Travel'
import { SBAsyncSelect } from '../../../components/custom-inputs/sb-async-select/SBAsyncSelect'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { LinkOutlined } from '@ant-design/icons'
import ExternalAdvancingStatus from '../../ExternalAdvancingStatus'
import TextArea from 'antd/es/input/TextArea'
import { File } from '../../file/File'

interface Props {
  form: FormInstance
}

const Update = ({ form }: Props) => {
  const intl = useIntl()

  const travel = useAppSelector(selectSelectedTravel())

  useEffect(() => {
    form.setFieldsValue(travel)
    form.setFieldValue('departureTime', dayjs(travel?.departureTime))
    form.setFieldValue('arrivalTime', dayjs(travel?.arrivalTime))
  }, [travel])

  if (travel === undefined) {
    return <></>
  }

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="id"
        rules={[requiredRule(intl)]}
        style={{ display: 'none' }}
      >
        <Input type="hidden" />
      </Form.Item>

      <SBAsyncSelect
        type={SBRMType.contact}
        name="contacts"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Passengers,
        })}
        rules={[]}
        multiple
      />

      <Form.Item
        name="type"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Travel.TransportType,
        })}
        rules={[requiredRule(intl)]}
      >
        <Radio.Group disabled>
          <Radio.Button value={TRAVEL_TYPE_FLIGHT}>
            {intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Travel.Plane,
            })}
          </Radio.Button>
          <Radio.Button value={TRAVEL_TYPE_TRAIN}>
            {intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Travel.Train,
            })}
          </Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name="inbound"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Travel.Type,
        })}
        rules={[requiredRule(intl)]}
      >
        <Radio.Group>
          <Radio.Button value={true}>
            {intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Travel.Inbound,
            })}
          </Radio.Button>
          <Radio.Button value={false}>
            {intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Travel.Outbound,
            })}
          </Radio.Button>
        </Radio.Group>
      </Form.Item>

      <SBAsyncSelect
        type={SBRMType.station}
        name="departure"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Travel.Departure,
        })}
        rules={[requiredRule(intl)]}
        metadata={[
          {
            key: MetaDataKey.stationType,
            value:
              travel?.type === TRAVEL_TYPE_TRAIN ? 'train_station' : 'airport',
          },
        ]}
      />
      <Form.Item
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Travel.DepartureTime,
        })}
        name="departureTime"
        rules={[requiredRule(intl)]}
      >
        <DatePicker
          className="w-full"
          format="YYYY-MM-DD HH:mm"
          showTime={{ format: 'HH:mm' }}
        />
      </Form.Item>

      <SBAsyncSelect
        type={SBRMType.station}
        name="arrival"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Travel.Arrival,
        })}
        rules={[requiredRule(intl)]}
        metadata={[
          {
            key: MetaDataKey.stationType,
            value:
              travel?.type === TRAVEL_TYPE_TRAIN ? 'train_station' : 'airport',
          },
        ]}
      />
      <Form.Item
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Travel.ArrivalTime,
        })}
        name="arrivalTime"
        rules={[requiredRule(intl)]}
      >
        <DatePicker
          className="w-full"
          format="YYYY-MM-DD HH:mm"
          showTime={{ format: 'HH:mm' }}
        />
      </Form.Item>
      <Form.Item
        name="notes"
        rules={[stringRule(intl)]}
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Notes,
        })}
      >
        <TextArea rows={5} />
      </Form.Item>
      <File.Dropzone
        name={'files'}
        multiple
        defaultFileIds={travel.files}
        showDropdownOnFileCell
      />
      <ExternalAdvancingStatus />
    </Form>
  )
}

export default Update

import { Tag } from 'antd'
import { useAppSelector } from '../../reducers/hooks'
import { selectContractStatusById } from '../../reducers/ContractStatusReducer'
import { ContractStatus as ContractStatusModel } from '../../models/ContractStatus'

interface Props {
  id?: number
  model?: ContractStatusModel
}

export const ContractStatusTag = ({ id, model }: Props) => {
  const contractStatus = model
    ? model
    : useAppSelector(selectContractStatusById(id ?? 0))

  return contractStatus ? (
    <Tag color={contractStatus.color}>{contractStatus.name}</Tag>
  ) : (
    <></>
  )
}

export type ContractStatusTagType = { Tag: typeof ContractStatusTag }

import { Space, Tabs, Typography } from 'antd'
import { useIntl } from 'react-intl'
import { Booking } from '../booking/Booking'
import { FilterValue } from 'antd/es/table/interface'
import { useAppSelector } from '../../reducers/hooks'
import { selectEventById } from '../../reducers/EventReducer'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { TeamOutlined } from '@ant-design/icons'

const { Text } = Typography

interface Props {
  id: number | undefined
  onChange: (nextBookingFilter: FilterValue | null) => void
  defaultAllIds?: boolean
}

export const BookingFilter = ({
  id,
  onChange,
  defaultAllIds = false,
}: Props) => {
  const intl = useIntl()

  const event = useAppSelector(selectEventById(id ?? 0))

  if (event === undefined) return <></>

  return (
    <Tabs
      items={[
        {
          label: (
            <Space direction="horizontal">
              <TeamOutlined />
              <Text>
                {intl.formatMessage({
                  id: LocalizationKeys.Misc.Tab.DisplayAll,
                })}
              </Text>
            </Space>
          ),
          key: 'all',
          children: <></>,
        },
        ...(event.bookings ?? []).map((b) => ({
          label: <Booking.Avatar id={b} linkToDetails={false} />,
          key: String(b),
          children: <></>,
        })),
      ]}
      onChange={(activeKey: string) =>
        onChange(
          activeKey == 'all'
            ? defaultAllIds
              ? event.bookings ?? []
              : null
            : [activeKey]
        )
      }
    />
  )
}

export type BookingFilterType = { BookingFilter: typeof BookingFilter }

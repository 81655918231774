import Delete from './Delete'
import { Alert, Form } from 'antd'
import { useSBRMData } from '../hooks'
import { forwardRef, Ref, useImperativeHandle } from 'react'
import { deleteReport } from '../../../reducers/ReportReducer'
import { SBRMFormInterface, SBRMFormProps } from '../SBRMModel'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'

const ReportForm = forwardRef(
  ({ isNested }: SBRMFormProps, ref: Ref<SBRMFormInterface>) => {
    useImperativeHandle(ref, () => ({
      handleCreate,
      handleUpdate,
      handleDelete,
      handleReset,
    }))

    const dispatch = useAppDispatch()
    const { action } = useSBRMData(isNested)
    const { error } = useAppSelector((state) => state.report)

    const [createForm] = Form.useForm()
    const [updateForm] = Form.useForm()
    const [deleteForm] = Form.useForm()

    const handleCreate = async () =>
      await createForm
        .validateFields()
        .then(async (result) => Promise.resolve(false))

    const handleUpdate = async () =>
      await updateForm
        .validateFields()
        .then(async (result) => Promise.resolve(false))

    const handleDelete = async () =>
      await deleteForm.validateFields().then(async (result) =>
        dispatch(deleteReport(deleteForm.getFieldsValue())).then(() => {
          deleteForm.resetFields()
          return true
        })
      )

    const handleReset = () => {
      //   createForm.resetFields()
      //   updateForm.resetFields()
      deleteForm.resetFields()
    }

    return (
      <>
        {action === 'create' && <></>}
        {action === 'update' && <></>}
        {action === 'delete' && <Delete form={deleteForm} />}
        {error && <Alert message={String(error)} type="error" />}
      </>
    )
  }
)

ReportForm.displayName = 'ReportForm'

export default ReportForm

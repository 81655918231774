import { Col, Form, Row, Typography, Button } from 'antd'
import { Ref, forwardRef, useState, useImperativeHandle, useRef } from 'react'
import { Address as AddressModel } from '../../models/Address'
import { AddressFormInterface } from '../address/Form'
import { Ground } from '../../models/Ground'
import { SwapOutlined } from '@ant-design/icons'
import { CascaderItem } from './CascaderItem'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import dayjs from 'dayjs'
import { useGroundContext } from '../../utils/context/GroundContext'

export interface GroundCascaderInterface {
  handleSetInitialValue: () => void
}

interface Props {
  eventId: number
  bookingId: number | undefined
  initialValue?: Ground
}

const Cascader = forwardRef(
  (
    { eventId, bookingId, initialValue }: Props,
    ref: Ref<GroundCascaderInterface>
  ) => {
    useImperativeHandle(ref, () => ({
      handleSetInitialValue,
    }))
    const handleSetInitialValue = () => {
      if (!hotels.data.length && !venues.data.length && !travels.data.length) {
        return
      }
      //   fillCascader(hotels.data, venues.data, travels.data, customAddress.data)
    }

    const intl = useIntl()
    const form = Form.useFormInstance()
    const {
      hotels,
      venues,
      travels,
      customAddress,
      fromCustomAddressField,
      toCustomAddressField,
      getCustomAddressField,
    } = useGroundContext()

    const [fromAddressToFill, setFromAddressToFill] = useState<
      AddressModel | undefined
    >(undefined)
    const [toAddressToFill, setToAddressToFill] = useState<
      AddressModel | undefined
    >(undefined)
    const fromAddressRef = useRef<AddressFormInterface>(null)
    const toAddressRef = useRef<AddressFormInterface>(null)

    const cascaderIsLoading =
      venues.isLoading ||
      hotels.isLoading ||
      travels.isLoading ||
      customAddress.isLoading

    const swapFromTo = () => {
      // Store current state
      const currentFrom = form.getFieldValue('from')
      const currentFromCustomAddress = form.getFieldValue('from_address')
      const currentFromCustomAddressVisible = fromCustomAddressField.isVisible
      const currentTo = form.getFieldValue('to')
      const currentToCustomAddress = form.getFieldValue('to_address')
      const currentToCustomAddressVisible = toCustomAddressField.isVisible

      // Swap From <=> To
      form.setFieldValue('from', currentTo) // Swap from - cascader - form value
      form.setFieldValue('from_address', currentToCustomAddress) // Swap address form value
      fromCustomAddressField.setIsVisible(currentToCustomAddressVisible) // Swap custom address form visibility
      if (currentToCustomAddressVisible) {
        // Swap Address filled value
        fromAddressRef.current?.fillFormWithAddress(
          currentToCustomAddress,
          true
        )
      }
      form.setFieldValue('to', currentFrom) // Swap from - cascader - form value
      form.setFieldValue('to_address', currentFromCustomAddress) // Swap address form value
      toCustomAddressField.setIsVisible(currentFromCustomAddressVisible) // Swap custom address form visibility
      if (currentFromCustomAddressVisible) {
        // Swap Address filled value
        toAddressRef.current?.fillFormWithAddress(
          currentFromCustomAddress,
          true
        )
      }
    }

    return (
      <>
        <Row gutter={[16, 0]} style={{ alignItems: 'end', marginBottom: 24 }}>
          {!bookingId && (
            <Col span={24}>
              <Typography.Text italic>
                {intl.formatMessage({
                  id: LocalizationKeys.Components.Ground.Cascader.SelectBooking,
                })}
              </Typography.Text>
            </Col>
          )}
          <Col span={11}>
            <CascaderItem
              direction="from"
              addressRef={fromAddressRef}
              customAddressField={getCustomAddressField('from')}
              cascaderIsLoading={cascaderIsLoading}
              bookingId={bookingId}
              addressToFill={fromAddressToFill}
              setAddressToFill={setFromAddressToFill}
              initialValue={initialValue}
              onChange={(value: (string | number)[], selectedOption: any) => {
                /**
                 * In case an inbound was selected we can fill pickup time
                 */
                if (
                  value !== undefined &&
                  value.length === 2 &&
                  value[0] === 'inbound' &&
                  !Number.isNaN(value[1])
                ) {
                  // Retrieve the travel
                  const inbound = travels.data.find((t) => t.id == value[1])
                  form.setFieldValue('pickupTime', dayjs(inbound?.arrivalTime))
                }
              }}
            />
          </Col>
          <Col span={2}>
            <Button icon={<SwapOutlined />} onClick={swapFromTo} />
          </Col>
          <Col span={11}>
            <CascaderItem
              direction="to"
              addressRef={toAddressRef}
              customAddressField={getCustomAddressField('to')}
              cascaderIsLoading={cascaderIsLoading}
              bookingId={bookingId}
              addressToFill={toAddressToFill}
              setAddressToFill={setToAddressToFill}
              initialValue={initialValue}
            />
          </Col>
        </Row>
      </>
    )
  }
)

Cascader.displayName = 'Cascader'
export { Cascader }

export type CascaderType = { Cascader: typeof Cascader }

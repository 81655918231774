import { Tooltip, Typography } from 'antd'
import { useAppSelector } from '../../reducers/hooks'
import { Contact } from '../../models/Contact'
import { selectContactById } from '../../reducers/ContactReducer'
import { UserOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'

const { Text } = Typography

interface Props {
  id?: number
  model?: Contact
}

export const LinkedToUser = ({ id, model }: Props) => {
  const intl = useIntl()
  const contact = model ? model : useAppSelector(selectContactById(id ?? 0))

  if (!contact) {
    return <></>
  }

  return contact.isLinkedToUser ? (
    <Tooltip
      title={intl.formatMessage({
        id: LocalizationKeys.Components.Contact.LinkedToUser,
      })}
    >
      <Text>
        <UserOutlined />
      </Text>
    </Tooltip>
  ) : (
    <></>
  )
}
export type LinkedToUserType = { LinkedToUser: typeof LinkedToUser }

import {
  getContactAssociations,
  selectContactAssociations,
  selectContactAssociationsByIds,
  setContactAssociationQuery,
} from '../../reducers/ContactAssociationReducer'
import SBTable from '../SBTable'
import { Venue } from '../venue/Venue'
import { Event } from '../event/Event'
import { Artist } from '../artist/Artist'
import { Contact } from '../contact/Contact'
import { Supplier } from '../supplier/Supplier'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { extractSortDataFromSorterResult } from '../../utils/table/sorter'
import { ContactAssociation as ContactAssociationModel } from '../../models/Contact'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'

interface Props {
  ids?: number[]
}
export const TableWithResource = ({ ids }: Props) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const associations = useAppSelector(
    ids
      ? selectContactAssociationsByIds(ids ?? [])
      : selectContactAssociations()
  )
  const { query, isLoading } = useAppSelector(
    (state) => state.contactAssociation
  )

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter:
      | SorterResult<ContactAssociationModel>
      | SorterResult<ContactAssociationModel>[]
  ) => {
    const newQuery = {
      ...query,
      pagination,
      filters,
      ...extractSortDataFromSorterResult<ContactAssociationModel>(sorter),
    }

    dispatch(setContactAssociationQuery(newQuery))
    dispatch(getContactAssociations(newQuery))
  }

  const columns: ColumnsType<ContactAssociationModel> = [
    {
      key: 'type',
      title: intl.formatMessage({
        id: LocalizationKeys.Components.Contact.TableWithAssociation.Type,
      }),
      render: (record: ContactAssociationModel) => (
        <Contact.TypeTag id={record.type} closable={false} />
      ),
    },
    {
      key: 'resource',
      title: '',
      render: (record: ContactAssociationModel) => (
        <>
          {record.contactable?.schema === 'venue' && (
            <Venue.Avatar id={record.contactable.id} linkToDetails />
          )}
          {record.contactable?.schema === 'artist' && (
            <Artist.Avatar id={record.contactable.id} linkToDetails />
          )}
          {record.contactable?.schema === 'supplier' && (
            <Supplier.Avatar id={record.contactable.id} linkToDetails />
          )}
          {record.contactable?.schema === 'event' && (
            <Event.Cell id={record.contactable.id} linkToDetails />
          )}
        </>
      ),
    },
  ]

  return (
    <SBTable
      entity={SBRMType.contactAssociation}
      scroll={{ x: 800 }}
      loading={isLoading && !associations.length}
      columns={columns}
      rowKey={(record) => record.id}
      dataSource={associations}
      pagination={query.pagination}
      onChange={handleTableChange}
      hideCreateButton
    />
  )
}

export type TableWithResourceType = {
  TableWithResource: typeof TableWithResource
}

import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { requiredRule } from '../../../utils/rules'
import { MetaDataKey } from '../../../models/MetaData'
import { useAppSelector } from '../../../reducers/hooks'
import { Checkbox, Form, FormInstance, Input, Space, Typography } from 'antd'
import { SBAsyncSelect } from '../../../components/custom-inputs/sb-async-select/SBAsyncSelect'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import {
  SBRMContactAssociationType,
  SBRMType,
  getMetadata,
} from '../../../modules/sbrm/SBRMModel'

const { Text } = Typography

interface Props {
  form: FormInstance
}

const Create = ({ form }: Props) => {
  const intl = useIntl()

  const { metadata } = useAppSelector((state) => state.SBRM)

  const associationType = getMetadata(
    metadata,
    MetaDataKey.contactAssociationType
  )
  const eventId = getMetadata(metadata, MetaDataKey.eventId)
  const artistId = getMetadata(metadata, MetaDataKey.artistId)
  const bookingId = getMetadata(metadata, MetaDataKey.bookingId)
  const venueId = getMetadata(metadata, MetaDataKey.venueId)
  const contactId = getMetadata(metadata, MetaDataKey.contactId)
  const supplierId = getMetadata(metadata, MetaDataKey.supplierId)

  const typesWithPossibleDeepAssociation = [
    SBRMContactAssociationType.booking,
    SBRMContactAssociationType.event,
  ]

  useEffect(() => {
    form.setFieldValue(
      ['parent', 'id'],
      associationType === SBRMContactAssociationType.event
        ? eventId
        : associationType === SBRMContactAssociationType.booking
        ? bookingId
        : associationType === SBRMContactAssociationType.artist
        ? artistId
        : associationType === SBRMContactAssociationType.venue
        ? venueId
        : associationType === SBRMContactAssociationType.supplier
        ? supplierId
        : undefined
    )
    form.setFieldValue(['parent', 'type'], associationType)

    if (contactId) {
      form.setFieldValue('contact', contactId)
    }
  }, [metadata])

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name={['parent', 'type']}
        rules={[requiredRule(intl)]}
        className="d-none"
      >
        <Input type="hidden" />
      </Form.Item>
      {associationType === SBRMContactAssociationType.booking ? (
        <SBAsyncSelect
          type={SBRMType.booking}
          name={['parent', 'id']}
          rules={[requiredRule(intl)]}
          metadata={[{ key: MetaDataKey.eventId, value: eventId }]}
          label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Booking })}
        />
      ) : (
        <Form.Item
          name={['parent', 'id']}
          rules={[requiredRule(intl)]}
          className="d-none"
        >
          <Input type="hidden" />
        </Form.Item>
      )}

      <SBAsyncSelect
        type={SBRMType.contact}
        name={'contact'}
        rules={[requiredRule(intl)]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Contact })}
      />
      <SBAsyncSelect
        type={SBRMType.contactType}
        name={'type'}
        rules={[requiredRule(intl)]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Type })}
      />
      {typesWithPossibleDeepAssociation.includes(associationType) && (
        <Form.Item
          name="attachToAssociatedResource"
          valuePropName="checked"
          style={{
            border: '1px dashed #424242',
            borderRadius: 10,
            padding: 10,
          }}
        >
          <Space size={20}>
            <Checkbox />
            <Space size={0} direction={'vertical'}>
              {associationType === SBRMContactAssociationType.event ? (
                <>
                  <Text>
                    {intl.formatMessage({
                      id: LocalizationKeys.Misc.Form.ContactAssociation
                        .EventTitle,
                    })}
                  </Text>
                  <Text italic>
                    {intl.formatMessage({
                      id: LocalizationKeys.Misc.Form.ContactAssociation
                        .EventDescription,
                    })}
                  </Text>
                </>
              ) : associationType === SBRMContactAssociationType.booking ? (
                <Text>
                  {intl.formatMessage({
                    id: LocalizationKeys.Misc.Form.ContactAssociation
                      .BookingTitle,
                  })}
                </Text>
              ) : (
                <></>
              )}
            </Space>
          </Space>
        </Form.Item>
      )}
    </Form>
  )
}

export default Create

import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../reducers/hooks'
import { WarningOutlined } from '@ant-design/icons'
import { requiredRule, matchValueRule } from '../../../utils/rules'
import { selectSelectedEvent } from '../../../reducers/EventReducer'
import { Form, FormInstance, Input, Result, Typography } from 'antd'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

const { Text } = Typography

interface Props {
  form: FormInstance
  deleteWithConfirmation: boolean
}

const Delete = ({ form, deleteWithConfirmation }: Props) => {
  const intl = useIntl()
  const event = useAppSelector(selectSelectedEvent())

  useEffect(() => {
    form.setFieldsValue(event)
  }, [event])

  return event ? (
    <Form form={form} layout="vertical">
      <Result
        title={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.AreYouSureToDelete,
        })}
        icon={<WarningOutlined style={{ color: '#dc4446' }} />}
      />
      {deleteWithConfirmation && (
        <Form.Item
          name={event.name}
          rules={[matchValueRule(intl, event.name)]}
          label={
            <Text>
              {intl.formatMessage(
                { id: LocalizationKeys.Misc.Form.InputValueToConfirm },
                { value: <Text strong>{event.name}</Text> }
              )}
            </Text>
          }
        >
          <Input placeholder={`${event.name}`} />
        </Form.Item>
      )}
      <Form.Item
        name="id"
        required
        rules={[requiredRule(intl)]}
        className="d-none"
      >
        <Input type="hidden" />
      </Form.Item>
    </Form>
  ) : (
    <></>
  )
}

export default Delete

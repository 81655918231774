import { FC } from 'react'
import { Infos, InfosType } from './Infos'
import {
  CascaderOptionLabel,
  CascaderOptionLabelType,
} from './CascaderOptionLabel'
import { Type, TypeType } from './Type'
import { TravelForm, TravelFormType } from './form/Form'
import { ExternalRequests, ExternalRequestsType } from './ExternalRequests'

export const Travel: FC &
  InfosType &
  CascaderOptionLabelType &
  TypeType &
  TravelFormType &
  ExternalRequestsType = (): JSX.Element => <></>

// Components
Travel.Infos = Infos
Travel.CascaderOptionLabel = CascaderOptionLabel
Travel.Type = Type
Travel.Form = TravelForm
Travel.ExternalRequests = ExternalRequests

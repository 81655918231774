import { FC } from 'react'
import { VenueTag, VenueTagType } from './Tag'
import { VenueAvatar, VenueAvatarType } from './Avatar'
import { VenueTypeTag, VenueTypeTagType } from './TypeTag'
import { VenueForm, VenueFormType } from './form/Form'

export const Venue: FC &
  VenueTagType &
  VenueTypeTagType &
  VenueAvatarType &
  VenueFormType = (): JSX.Element => <></>

Venue.Tag = VenueTag
Venue.TypeTag = VenueTypeTag
Venue.Avatar = VenueAvatar
Venue.Form = VenueForm

import { useIntl } from 'react-intl'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { App, Button, Tooltip } from 'antd'
import { ExportOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { exportEventResource } from '../../reducers/EventReducer'

interface Props {
  eventId: number
  entity: SBRMType
}

const Export = ({ eventId, entity }: Props) => {
  const intl = useIntl()
  const { message } = App.useApp()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const resourceForEntity: Partial<Record<SBRMType, string>> = {
    [SBRMType.travel]: 'travels',
    [SBRMType.ground]: 'grounds',
    [SBRMType.room]: 'rooms',
  }

  return (
    <Tooltip title={'Export'}>
      <Button
        icon={<ExportOutlined />}
        loading={isLoading}
        onClick={() => {
          setIsLoading(true)
          exportEventResource(eventId, resourceForEntity[entity] ?? '')
            .then(() => {
              message.success('Export sent by email')
            })
            .finally(() => {
              setIsLoading(false)
            })
        }}
      />
    </Tooltip>
  )
}

type ExportType = { Export: typeof Export }

export { Export, type ExportType }

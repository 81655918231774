import { FC } from 'react'
import { ArtistAvatar, ArtistAvatarType } from './Avatar'
import { ArtistAvatarGroup, ArtistAvatarGroupType } from './AvatarGroup'
import { FeesInfos, FeesInfosType } from './FeesInfos'
import { ArtistForm, ArtistFormType } from './form/Form'

export const Artist: FC &
  FeesInfosType &
  ArtistAvatarType &
  ArtistAvatarGroupType &
  ArtistFormType = (): JSX.Element => <></>

Artist.FeesInfos = FeesInfos
Artist.Avatar = ArtistAvatar
Artist.AvatarGroup = ArtistAvatarGroup
Artist.Form = ArtistForm

import {
  getRiders,
  selectRiders,
  setRiderQuery,
} from '../../../reducers/RiderReducer'
import { Col, Row, Space, Spin } from 'antd'
import { useEffect, useState } from 'react'
import SBTable from '../../../components/SBTable'
import { File } from '../../../components/file/File'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import HasAccess from '../../../components/HasAccess'
import { MetaDataKey } from '../../../models/MetaData'
import { TableParams } from '../../../models/TableParams'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import { Rider as RiderModel } from '../../../models/Rider'
import { AlelaPermission } from '../../../utils/permissions'
import { Booking } from '../../../components/booking/Booking'
import { initialQuery } from '../../../utils/helpers/crud/models'
import { Actions } from '../../../modules/sbrm/components/Actions'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import DescriptionModal from '../../../components/DescriptionModal'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { selectSelectedEvent } from '../../../reducers/EventReducer'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import { RiderStatus } from '../../../components/rider-status/RiderStatus'
import { extractSortDataFromSorterResult } from '../../../utils/table/sorter'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { useSearchParams } from 'react-router-dom'
import { addUrlParams } from '../../../modules/sbrm/UrlHelper'
import { HelpArticle } from '../../../utils/hooks/useAlelaHelp'
import { Help } from '../../../components/help/Help'
import { Rider } from '../../../components/rider/Rider'
import { RiderType } from '../../../components/rider-type/RiderType'
import { Event } from '../../../components/event/Event'

const TechnicalPage = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const event = useAppSelector(selectSelectedEvent())
  const riders = useAppSelector(selectRiders())
  const { query, isLoading } = useAppSelector((state) => state.rider)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const columns: ColumnsType<RiderModel> = [
    {
      key: 'booking',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.EventDetails.Technical.Booking,
      }),
      width: 200,
      render: (record: RiderModel) => (
        <a href="#" onClick={(e) => e.stopPropagation()}>
          <Booking.Avatar id={record.booking ?? 0} />
        </a>
      ),
    },
    {
      key: 'status',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.EventDetails.Technical.Status,
      }),
      render: (record: RiderModel) => <RiderStatus.Tag id={record.status} />,
    },
    {
      key: 'type',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.EventDetails.Technical.Type,
      }),
      render: (record: RiderModel) => <RiderType.Tag id={record.type} />,
    },
    {
      key: 'notes',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.EventDetails.Technical.Notes,
      }),
      align: 'center',
      render: (record: RiderModel) =>
        record.notes && (
          <a href="#" onClick={(e) => e.stopPropagation()}>
            <DescriptionModal
              title={intl.formatMessage({
                id: LocalizationKeys.Page.EventDetails.Technical.Notes,
              })}
              content={record.notes}
            />
          </a>
        ),
    },
    {
      key: 'actions',
      align: 'right',
      render: (record: RiderModel) => (
        <a href="#" onClick={(e) => e.stopPropagation()}>
          <Space direction="horizontal">
            <File.DownloadButton id={record.file} />
            <Actions
              actions={['update', 'comment', 'delete']}
              entity={SBRMType.rider}
              entityId={record.id}
            />
          </Space>
        </a>
      ),
    },
  ]

  const handleTabChange = (nextBookingsFilter: FilterValue | null) => {
    const newQuery: TableParams = {
      ...query,
      filters: { ...query.filters, bookings: nextBookingsFilter },
    }
    dispatch(setRiderQuery(newQuery))
    dispatch(getRiders(newQuery))
  }

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<RiderModel> | SorterResult<RiderModel>[]
  ) => {
    const newQuery = {
      ...query,
      pagination,
      filters: { ...query.filters, ...filters },
      ...extractSortDataFromSorterResult(sorter),
    }
    dispatch(setRiderQuery(newQuery))
    dispatch(getRiders(newQuery))
  }

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      return
    }
    setIsFirstRender(false)

    const baseQuery: TableParams = {
      ...initialQuery,
      filters: { events: [event?.id!] },
    }

    dispatch(setRiderQuery(baseQuery))
    dispatch(getRiders(baseQuery))
  }, [SBRMIsOpen])

  if (event === undefined) return <Spin />

  return (
    <Row className="container-row">
      <Col span={24}>
        <HasAccess permissions={[AlelaPermission.listRider]}>
          <PageTitle
            title={intl.formatMessage({
              id: LocalizationKeys.Page.EventDetails.Technical.Title,
            })}
            level={5}
            badge={riders.length}
            toolbar={
              <Space>
                <Actions
                  actions={['create']}
                  entity={SBRMType.rider}
                  metadata={[{ key: MetaDataKey.eventId, value: event.id }]}
                />
                <Help.Button
                  article={HelpArticle.AddAndManageTechnicalSheets}
                />
              </Space>
            }
          />
          <Rider.ExternalRequests
            eventId={event.id}
            shouldRefreshList={() => {
              dispatch(getRiders(query))
            }}
          />
          <Event.BookingFilter id={event.id} onChange={handleTabChange} />
          <SBTable
            entity={SBRMType.rider}
            className="table-row-clickable"
            emptyActionsMetadata={[
              { key: MetaDataKey.eventId, value: event.id },
            ]}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={riders}
            loading={isLoading && !riders.length}
            pagination={query.pagination}
            onChange={handleTableChange}
            onRow={(record, rowIndex) => ({
              onClick: (event) =>
                addUrlParams(
                  {
                    action: 'view',
                    entity: SBRMType.rider,
                    entityId: record.id.toString(),
                  },
                  undefined,
                  setSearchParams
                ),
            })}
          />
        </HasAccess>
      </Col>
    </Row>
  )
}

export default TechnicalPage

import { Button, Skeleton, Space, Table, Tag } from 'antd'
import { useEffect, useState } from 'react'
import { TENANT_INVOICES_URL } from '../../utils/urls'
import axios from 'axios'
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'
import { ColumnsType } from 'antd/es/table'
import { Link } from 'react-router-dom'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { DownloadOutlined } from '@ant-design/icons'

interface TenantInvoice {
  id: string
  number: string
  amount: string
  status: string
  download: string
  timestamp: number
}

export const Invoices = () => {
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [invoices, setInvoices] = useState<TenantInvoice[]>([])

  const columns: ColumnsType<TenantInvoice> = [
    {
      key: 'reference',
      title: intl.formatMessage({
        id: LocalizationKeys.Components.Invoice.Table.Reference,
      }),
      render: (record: TenantInvoice) => record.number,
    },
    {
      key: 'timestamp',
      title: intl.formatMessage({
        id: LocalizationKeys.Components.Invoice.Table.DueDate,
      }),
      render: (record: TenantInvoice) =>
        dayjs.unix(record.timestamp).format('DD/MM/YYYY'),
    },
    {
      key: 'amount',
      title: intl.formatMessage({
        id: LocalizationKeys.Components.Invoice.Table.Amount,
      }),
      render: (record: TenantInvoice) => record.amount,
    },
    {
      key: 'status',
      title: intl.formatMessage({ id: LocalizationKeys.Misc.Form.Status }),
      render: (record: TenantInvoice) => <Tag>{record.status}</Tag>,
    },
    {
      key: 'download',
      render: (record: TenantInvoice) => (
        <Link to={record.download} target="_blank">
          <Button type="primary">
            <DownloadOutlined />
            Download
          </Button>
        </Link>
      ),
    },
  ]

  useEffect(() => {
    setIsLoading(true)
    axios
      .get(TENANT_INVOICES_URL)
      .then((response) => {
        setInvoices(response.data.data)
        setIsLoading(false)
      })
      .catch((error) => setIsLoading(false))
  }, [])

  if (isLoading) {
    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        <Skeleton title={false} paragraph={{ rows: 1, width: '100%' }} />
      </Space>
    )
  }

  return (
    <Space className="w-full" direction="vertical" size={30}>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={invoices}
        rowKey={(record) => record.id}
      />
    </Space>
  )
}
export type InvoicesType = { Invoices: typeof Invoices }

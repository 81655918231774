import { useIntl } from 'react-intl'
import {
  Button,
  Card,
  Col,
  Form,
  FormInstance,
  Input,
  Radio,
  Row,
  Typography,
} from 'antd'
import { requiredRule } from '../../../utils/rules'
import { useState } from 'react'
import {
  searchAirport,
  searchTrainStation,
} from '../../../reducers/StationReducer'
import { StationWithRelation } from '../../../models/Station'
import { Station } from '../../../components/station/Station'
import { CheckOutlined } from '@ant-design/icons'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

const { Title, Text } = Typography

interface Props {
  form: FormInstance
}

const Create = ({ form }: Props) => {
  const intl = useIntl()
  const [searchFeedback, setSearchFeedback] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [travelType, setTravelType] = useState<'flight' | 'train'>('flight')
  const [searchResults, setSearchResults] = useState<StationWithRelation[]>([])

  const handleSuccess = (response: any) => {
    setSearchResults(response.data)
    if (!response.data.length) {
      setSearchFeedback(
        intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Station.NoStationFound,
        })
      )
    }
    setIsLoading(false)
  }

  const handleError = (response: any) => {
    setSearchFeedback(
      intl.formatMessage({ id: LocalizationKeys.Misc.Form.Station.Error })
    )
    setIsLoading(false)
  }

  const search = () => {
    const name = form.getFieldValue('name')
    if (!name || name.length < 3) {
      setSearchFeedback(
        intl.formatMessage({ id: LocalizationKeys.Misc.Form.Station.MinLength })
      )
      return
    }
    setIsLoading(true)
    setSearchResults([])
    setSearchFeedback('')
    travelType === 'flight'
      ? searchAirport(name).then(handleSuccess).catch(handleError)
      : searchTrainStation(name).then(handleSuccess).catch(handleError)
  }

  return (
    <Form form={form} layout="vertical">
      <Row gutter={[16, 16]}>
        <Col md={9}>
          <Form.Item
            name="type"
            label={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Station.TransportType,
            })}
            rules={[requiredRule(intl)]}
          >
            <Radio.Group
              defaultValue={travelType}
              onChange={(e: any) => setTravelType(e.target.value)}
            >
              <Radio.Button value={'flight'}>
                {intl.formatMessage({
                  id: LocalizationKeys.Misc.Form.Station.Airport,
                })}
              </Radio.Button>
              <Radio.Button value={'train'}>
                {intl.formatMessage({
                  id: LocalizationKeys.Misc.Form.Station.TrainStation,
                })}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col md={15}>
          <Form.Item
            name="name"
            label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Name })}
            rules={[requiredRule(intl)]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <Button
            type="primary"
            key="list-loadmore-more"
            onClick={search}
            loading={isLoading}
          >
            {intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Station.Search,
            })}
          </Button>
        </Col>
        <Col md={19}>
          <Text italic type="danger">
            {searchFeedback}
          </Text>
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        {searchResults.map((station) => (
          <Card key={station.id}>
            <Station.Type model={station.type} />
            <Title level={5}>{station.name}</Title>
            <Text italic type="secondary">
              {station.address.latitude} / {station.address.longitude}
            </Text>
            <Button
              type="primary"
              disabled
              icon={<CheckOutlined type="success" />}
            >
              {intl.formatMessage({
                id: LocalizationKeys.Misc.Form.Station.Added,
              })}
            </Button>
          </Card>
        ))}
      </Row>
    </Form>
  )
}

export default Create

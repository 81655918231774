import {
  getContacts,
  resetContactQueryAndIds,
  selectContacts,
  setContactQuery,
} from '../../reducers/ContactReducer'
import { useIntl } from 'react-intl'
import { useEffect, useMemo, useState } from 'react'
import SBTable from '../../components/SBTable'
import { Card, Row, Col, Space, Dropdown, Button } from 'antd'
import HasAccess, { useHasAccess } from '../../components/HasAccess'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { TableParams } from '../../models/TableParams'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { AlelaPermission } from '../../utils/permissions'
import { Contact } from '../../components/contact/Contact'
import { CheckboxValueType } from 'antd/es/checkbox/Group'
import { initialQuery } from '../../utils/helpers/crud/models'
import { Contact as ContactModel } from '../../models/Contact'
import { Actions } from '../../modules/sbrm/components/Actions'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { extractSortDataFromSorterResult } from '../../utils/table/sorter'
import Search from 'antd/es/input/Search'
import { debounce } from 'lodash'
import { SettingOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { HelpArticle } from '../../utils/hooks/useAlelaHelp'
import { ContactType } from '../../components/contact-type/ContactType'
import { Help } from '../../components/help/Help'

const ContactsPage = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const { hasAccess } = useHasAccess()

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const items = useAppSelector(selectContacts())
  const { query, isLoading } = useAppSelector((state) => state.contact)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const setQueryAndGetContacts = (query: TableParams) => {
    dispatch(setContactQuery(query))
    dispatch(getContacts(query))
  }

  const handleContactTypeChangedQuery = (list: CheckboxValueType[]) => {
    const newQuery = {
      ...query,
      pagination: { ...query.pagination, current: 1 }, // Must reset the pagination here
      filters: { ...query.filters, types: list },
    }
    setQueryAndGetContacts(newQuery)
  }

  const columns: ColumnsType<ContactModel> = [
    {
      key: 'name',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.CRM.Contacts.Name,
      }),
      sorter: true,
      width: 400,
      defaultSortOrder: 'ascend',
      render: (contact: ContactModel) => (
        <Contact.Avatar
          id={contact.id}
          size="small"
          editable={false}
          linkToDetails={hasAccess([AlelaPermission.viewContact])}
        />
      ),
    },
    {
      key: 'actions',
      align: 'right',
      render: (contact: ContactModel) => (
        <Actions
          actions={['update', 'delete']}
          entity={SBRMType.contact}
          entityId={contact.id}
        />
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<ContactModel> | SorterResult<ContactModel>[]
  ) => {
    const newQuery = {
      ...query,
      pagination,
      filters: {
        ...query.filters,
        ...filters,
      },
      ...extractSortDataFromSorterResult<ContactModel>(sorter),
    }

    setQueryAndGetContacts(newQuery)
  }

  const handleSearch = (value: string) => {
    const newQuery = {
      ...query,
      ...{ search: { contact: value } },
    }
    setQueryAndGetContacts(newQuery)
  }

  const debouncedHandleSearch = useMemo(
    () => debounce(handleSearch, 500),
    [query]
  )

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      return
    }
    setIsFirstRender(false)

    // Trigger on init and when SBRM is closed
    const baseQuery: TableParams = {
      ...initialQuery,
      order: 'ascend',
      columnKey: 'name',
    }
    dispatch(setContactQuery(baseQuery))
    dispatch(getContacts(baseQuery))
  }, [dispatch, SBRMIsOpen])

  useEffect(() => {
    // Only on init we want to reset ids
    // To avoid seeing old items from previous page table
    dispatch(resetContactQueryAndIds({}))
  }, [])

  return (
    <HasAccess permissions={[AlelaPermission.listContact]}>
      <PageTitle
        title={intl.formatMessage({
          id: LocalizationKeys.Page.CRM.Contacts.Title,
        })}
        toolbar={
          <Space>
            <Search
              allowClear
              placeholder={intl.formatMessage({
                id: LocalizationKeys.Page.CRM.Contacts.Search,
              })}
              onChange={(event) => debouncedHandleSearch(event.target.value)}
            />
            <Actions actions={['create']} entity={SBRMType.contact} />
            <Dropdown
              menu={{
                items: [
                  {
                    key: 'contact-merger',
                    label: (
                      <Link to={'/crm/tools/contact-merger'}>
                        {intl.formatMessage({
                          id: LocalizationKeys.Page.CRM.Contacts.Merge,
                        })}
                      </Link>
                    ),
                  },
                ],
              }}
              placement="bottomRight"
            >
              <Button icon={<SettingOutlined />} type={'text'} />
            </Dropdown>
            <Help.Button article={HelpArticle.AddAndManageContact} />
          </Space>
        }
      />
      <Row gutter={[16, 16]}>
        <Col md={6} sm={24} xs={24}>
          <Card bordered={false}>
            <HasAccess permissions={[AlelaPermission.listContactType]}>
              <ContactType.ListCheckboxes
                onChange={handleContactTypeChangedQuery}
              />
            </HasAccess>
          </Card>
        </Col>
        <Col md={18} sm={24} xs={24}>
          <SBTable
            entity={SBRMType.contact}
            scroll={{ x: 800 }}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={items}
            pagination={query.pagination}
            loading={isLoading && !items.length}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
    </HasAccess>
  )
}

export default ContactsPage

import { FC } from 'react'
import { HotelAvatar, HotelAvatarType } from './Avatar'
import { HotelForm, HotelFormType } from './form/Form'
import { HotelOption, HotelOptionType } from './Option'

export const Hotel: FC &
  HotelAvatarType &
  HotelFormType &
  HotelOptionType = (): JSX.Element => <></>

Hotel.Avatar = HotelAvatar
Hotel.Form = HotelForm
Hotel.Option = HotelOption

import { FC } from 'react'
import { Toogle, ToogleType } from './Toogle'
import { Alert, AlertType } from './Alert'
import { Button, ButtonType } from './Button'
import { SupportAlert, SupportAlertType } from './SupportAlert'

export const Help: FC &
  ToogleType &
  AlertType &
  ButtonType &
  SupportAlertType = (): JSX.Element => <></>

Help.Toogle = Toogle
Help.Alert = Alert
Help.Button = Button
Help.SupportAlert = SupportAlert

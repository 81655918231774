import { useIntl } from 'react-intl'
import { SBRMType } from './SBRMModel'
import { SBRMAction } from './SBRMModel'
import LocalizationKeys from '../../i18n/LocalizationKeys'

type SBRMTitleAction =
  | 'add-new'
  | 'create-new'
  | 'edit'
  | 'delete'
  | 'comment'
  | 'view'
  | 'upload'
  | 'duplicate'
  | 'assign'
  | 'invite'
  | 'cancel'

const SBRMTitle = (
  entity: SBRMType | undefined,
  action: SBRMAction | undefined
): string => {
  const intl = useIntl()

  if (entity == undefined || action == undefined) return 'Error'

  const localizeType = (type: SBRMType): string =>
    intl.formatMessage({
      id: LocalizationKeys.Entity[type],
    })

  const composeTitle = (action: SBRMTitleAction, type: SBRMType): string =>
    intl.formatMessage(
      { id: LocalizationKeys.SBRM.Title[action] },
      { type: localizeType(type) }
    )

  const titles: Record<SBRMType, Partial<Record<SBRMAction, string>>> = {
    [SBRMType.artist]: {
      ['create']: composeTitle('create-new', SBRMType.artist),
      ['update']: composeTitle('edit', SBRMType.artist),
      ['delete']: composeTitle('delete', SBRMType.artist),
    },
    [SBRMType.event]: {
      ['create']: composeTitle('create-new', SBRMType.event),
      ['update']: composeTitle('edit', SBRMType.event),
      ['delete']: composeTitle('delete', SBRMType.event),
    },
    [SBRMType.eventStatus]: {
      ['create']: composeTitle('create-new', SBRMType.eventStatus),
      ['update']: composeTitle('edit', SBRMType.eventStatus),
      ['delete']: composeTitle('delete', SBRMType.eventStatus),
    },
    [SBRMType.booking]: {
      ['create']: composeTitle('add-new', SBRMType.booking),
      ['update']: composeTitle('edit', SBRMType.booking),
      ['delete']: composeTitle('delete', SBRMType.booking),
      ['comment']: composeTitle('comment', SBRMType.booking),
    },
    [SBRMType.bookingType]: {
      ['create']: composeTitle('create-new', SBRMType.bookingType),
      ['update']: composeTitle('edit', SBRMType.bookingType),
      ['delete']: composeTitle('delete', SBRMType.bookingType),
    },
    [SBRMType.contract]: {
      ['view']: composeTitle('view', SBRMType.contract),
      ['create']: composeTitle('upload', SBRMType.contract),
      ['update']: composeTitle('edit', SBRMType.contract),
      ['delete']: composeTitle('delete', SBRMType.contract),
      ['comment']: composeTitle('comment', SBRMType.contract),
    },
    [SBRMType.contractStatus]: {
      ['create']: composeTitle('create-new', SBRMType.contractStatus),
      ['update']: composeTitle('edit', SBRMType.contractStatus),
      ['delete']: composeTitle('delete', SBRMType.contractStatus),
    },
    [SBRMType.invoice]: {
      ['view']: composeTitle('view', SBRMType.invoice),
      ['create']: composeTitle('upload', SBRMType.invoice),
      ['update']: composeTitle('edit', SBRMType.invoice),
      ['delete']: composeTitle('delete', SBRMType.invoice),
      ['comment']: composeTitle('comment', SBRMType.invoice),
    },
    [SBRMType.payment]: {
      ['view']: composeTitle('view', SBRMType.payment),
      ['create']: composeTitle('add-new', SBRMType.payment),
      ['update']: composeTitle('edit', SBRMType.payment),
      ['delete']: composeTitle('delete', SBRMType.payment),
    },
    [SBRMType.hotel]: {
      ['create']: composeTitle('create-new', SBRMType.hotel),
      ['update']: composeTitle('edit', SBRMType.hotel),
      ['delete']: composeTitle('delete', SBRMType.hotel),
    },
    [SBRMType.room]: {
      ['create']: composeTitle('add-new', SBRMType.room),
      ['duplicate']: composeTitle('duplicate', SBRMType.room),
      ['update']: composeTitle('edit', SBRMType.room),
      ['delete']: composeTitle('delete', SBRMType.room),
    },
    [SBRMType.rider]: {
      ['view']: composeTitle('view', SBRMType.rider),
      ['create']: composeTitle('upload', SBRMType.rider),
      ['update']: composeTitle('edit', SBRMType.rider),
      ['delete']: composeTitle('delete', SBRMType.rider),
      ['comment']: composeTitle('comment', SBRMType.rider),
    },
    [SBRMType.riderStatus]: {
      ['view']: composeTitle('view', SBRMType.riderStatus),
      ['create']: composeTitle('add-new', SBRMType.riderStatus),
      ['update']: composeTitle('edit', SBRMType.riderStatus),
      ['delete']: composeTitle('delete', SBRMType.riderStatus),
    },
    [SBRMType.riderType]: {
      ['view']: composeTitle('view', SBRMType.riderType),
      ['create']: composeTitle('add-new', SBRMType.riderType),
      ['update']: composeTitle('edit', SBRMType.riderType),
      ['delete']: composeTitle('delete', SBRMType.riderType),
    },
    [SBRMType.file]: {
      ['view']: composeTitle('view', SBRMType.file),
      ['create']: composeTitle('upload', SBRMType.file),
      ['update']: composeTitle('edit', SBRMType.file),
      ['delete']: composeTitle('delete', SBRMType.file),
    },
    [SBRMType.user]: {
      ['create']: composeTitle('create-new', SBRMType.user),
      ['update']: composeTitle('edit', SBRMType.user),
      ['delete']: composeTitle('delete', SBRMType.user),
    },
    [SBRMType.venue]: {
      ['create']: composeTitle('create-new', SBRMType.venue),
      ['update']: composeTitle('edit', SBRMType.venue),
      ['delete']: composeTitle('delete', SBRMType.venue),
    },
    [SBRMType.venueType]: {
      ['create']: composeTitle('create-new', SBRMType.venueType),
      ['update']: composeTitle('edit', SBRMType.venueType),
      ['delete']: composeTitle('delete', SBRMType.venueType),
    },
    [SBRMType.stage]: {
      ['create']: composeTitle('create-new', SBRMType.stage),
      ['update']: composeTitle('edit', SBRMType.stage),
      ['delete']: composeTitle('delete', SBRMType.stage),
    },
    [SBRMType.contact]: {
      ['create']: composeTitle('create-new', SBRMType.contact),
      ['update']: composeTitle('edit', SBRMType.contact),
      ['delete']: composeTitle('delete', SBRMType.contact),
    },
    [SBRMType.contactType]: {
      ['create']: composeTitle('create-new', SBRMType.contactType),
      ['update']: composeTitle('edit', SBRMType.contactType),
      ['delete']: composeTitle('delete', SBRMType.contactType),
    },
    [SBRMType.contactAssociation]: {
      ['create']: composeTitle('assign', SBRMType.contactAssociation),
      ['update']: composeTitle('edit', SBRMType.contactAssociation),
      ['delete']: composeTitle('delete', SBRMType.contactAssociation),
    },
    [SBRMType.travel]: {
      ['create']: composeTitle('add-new', SBRMType.travel),
      ['duplicate']: composeTitle('duplicate', SBRMType.travel),
      ['update']: composeTitle('edit', SBRMType.travel),
      ['delete']: composeTitle('delete', SBRMType.travel),
    },
    [SBRMType.travelType]: {
      ['create']: composeTitle('create-new', SBRMType.travelType),
      ['update']: composeTitle('edit', SBRMType.travelType),
      ['delete']: composeTitle('delete', SBRMType.travelType),
    },
    [SBRMType.ground]: {
      ['create']: composeTitle('add-new', SBRMType.ground),
      ['duplicate']: composeTitle('duplicate', SBRMType.ground),
      ['update']: composeTitle('edit', SBRMType.ground),
      ['delete']: composeTitle('delete', SBRMType.ground),
    },
    [SBRMType.performance]: {
      ['create']: composeTitle('add-new', SBRMType.performance),
      ['update']: composeTitle('edit', SBRMType.performance),
      ['delete']: composeTitle('delete', SBRMType.performance),
    },
    [SBRMType.performanceType]: {
      ['create']: composeTitle('create-new', SBRMType.performanceType),
      ['update']: composeTitle('edit', SBRMType.performanceType),
      ['delete']: composeTitle('delete', SBRMType.performanceType),
    },
    [SBRMType.station]: {
      ['create']: composeTitle('add-new', SBRMType.station),
      ['update']: composeTitle('edit', SBRMType.station),
      ['delete']: composeTitle('delete', SBRMType.station),
    },
    [SBRMType.stationType]: {
      ['create']: composeTitle('add-new', SBRMType.stationType),
      ['update']: composeTitle('edit', SBRMType.stationType),
      ['delete']: composeTitle('delete', SBRMType.stationType),
    },
    [SBRMType.publicLink]: {
      ['create']: composeTitle('create-new', SBRMType.publicLink),
      ['update']: composeTitle('edit', SBRMType.publicLink),
      ['delete']: composeTitle('delete', SBRMType.publicLink),
    },
    [SBRMType.role]: {
      ['create']: composeTitle('create-new', SBRMType.role),
      ['update']: composeTitle('edit', SBRMType.role),
      ['delete']: composeTitle('delete', SBRMType.role),
    },
    [SBRMType.customAddress]: {
      ['create']: '',
      ['update']: '',
      ['delete']: '',
    },
    [SBRMType.supplier]: {
      ['create']: composeTitle('add-new', SBRMType.supplier),
      ['update']: composeTitle('edit', SBRMType.supplier),
      ['delete']: composeTitle('delete', SBRMType.supplier),
    },
    [SBRMType.supplierType]: {
      ['create']: composeTitle('add-new', SBRMType.supplierType),
      ['update']: composeTitle('edit', SBRMType.supplierType),
      ['delete']: composeTitle('delete', SBRMType.supplierType),
    },
    [SBRMType.invoiceCategory]: {
      ['create']: composeTitle('add-new', SBRMType.invoiceCategory),
      ['update']: composeTitle('edit', SBRMType.invoiceCategory),
      ['delete']: composeTitle('delete', SBRMType.invoiceCategory),
    },
    [SBRMType.invitation]: {
      ['create']: composeTitle('invite', SBRMType.user),
      ['update']: '',
      ['delete']: composeTitle('cancel', SBRMType.invitation),
    },
    [SBRMType.notification]: {
      ['create']: '',
      ['update']: '',
      ['delete']: '',
    },
    [SBRMType.report]: {
      ['comment']: composeTitle('comment', SBRMType.report),
    },
    [SBRMType.reportItem]: {
      ['create']: composeTitle('add-new', SBRMType.reportItem),
      ['update']: composeTitle('edit', SBRMType.reportItem),
      ['delete']: composeTitle('delete', SBRMType.reportItem),
    },
    [SBRMType.task]: {
      ['create']: composeTitle('add-new', SBRMType.task),
      ['update']: composeTitle('edit', SBRMType.task),
      ['delete']: composeTitle('delete', SBRMType.task),
      ['comment']: composeTitle('comment', SBRMType.task),
    },
    [SBRMType.taskStatus]: {
      ['create']: composeTitle('add-new', SBRMType.taskStatus),
      ['update']: composeTitle('edit', SBRMType.taskStatus),
      ['delete']: composeTitle('delete', SBRMType.taskStatus),
    },
    [SBRMType.externalAdvancingLink]: {
      ['create']: composeTitle('create-new', SBRMType.externalAdvancingLink),
      ['update']: composeTitle('edit', SBRMType.externalAdvancingLink),
      ['delete']: composeTitle('delete', SBRMType.externalAdvancingLink),
      ['comment']: composeTitle('comment', SBRMType.externalAdvancingLink),
    },
    [SBRMType.currency]: {
      ['create']: '',
      ['update']: '',
      ['delete']: '',
    },
    [SBRMType.marketingTask]: {
      ['create']: composeTitle('create-new', SBRMType.marketingTask),
      ['update']: composeTitle('edit', SBRMType.marketingTask),
      ['delete']: composeTitle('delete', SBRMType.marketingTask),
      ['comment']: composeTitle('comment', SBRMType.marketingTask),
    },
    [SBRMType.marketingTaskStatus]: {
      ['create']: composeTitle('create-new', SBRMType.marketingTaskStatus),
      ['update']: composeTitle('edit', SBRMType.marketingTaskStatus),
      ['delete']: composeTitle('delete', SBRMType.marketingTaskStatus),
    },
  }

  const title = titles[entity][action]
  return title !== undefined ? title : ''
}

export default SBRMTitle

import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppDispatch } from '../../../../../reducers/hooks'
import { createArtist } from '../../../../../reducers/ArtistReducer'
import { requiredRule, stringRule } from '../../../../../utils/rules'
import { Form, Divider, Input, Button, Row, Col, Radio } from 'antd'
import LocalizationKeys from '../../../../../i18n/LocalizationKeys'

interface Props {
  onSuccessCreate: () => void
}

const ArtistFooter = ({ onSuccessCreate }: Props) => {
  const intl = useIntl()
  const [form] = Form.useForm()

  const dispatch = useAppDispatch()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [viaSpotify, setViaSpotify] = useState<boolean>(true)

  const handleCreate = (values: any) => {
    setIsLoading(true)
    dispatch(createArtist(values))
      .then(() => {
        form.resetFields()
        onSuccessCreate()
        setIsLoading(false)
        return true
      })
      .catch((e: any) => {
        setIsLoading(false)
        throw e
      })
  }
  return (
    <>
      <Divider style={{ margin: '8px 0' }} />
      <Form
        form={form}
        layout="horizontal"
        onFinish={handleCreate}
        style={{ padding: '0 8px 4px' }}
      >
        <Row gutter={[8, 8]}>
          <Col span={8}>
            <Form.Item
              name="method"
              initialValue={0}
              rules={[requiredRule(intl)]}
              style={{ marginBottom: '0' }}
            >
              <Radio.Group
                onChange={(e: any) => setViaSpotify(e.target.value === 0)}
              >
                <Radio.Button value={0}>
                  {intl.formatMessage({
                    id: LocalizationKeys.Components.CustomInput.Select.Footer
                      .Artist.Spotify,
                  })}
                </Radio.Button>
                <Radio.Button value={1}>
                  {intl.formatMessage({
                    id: LocalizationKeys.Components.CustomInput.Select.Footer
                      .Artist.Manual,
                  })}
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={12}>
            {viaSpotify && (
              <Form.Item
                name="spotify_id"
                rules={[requiredRule(intl), stringRule(intl)]}
                style={{ marginBottom: '0' }}
              >
                <Input
                  placeholder={intl.formatMessage({
                    id: LocalizationKeys.Components.CustomInput.Select.Footer
                      .Artist.SpotifyUrl,
                  })}
                />
              </Form.Item>
            )}
            {!viaSpotify && (
              <Form.Item
                name="name"
                rules={[requiredRule(intl), stringRule(intl)]}
                style={{ marginBottom: '0' }}
              >
                <Input
                  placeholder={intl.formatMessage({
                    id: LocalizationKeys.Components.CustomInput.Select.Footer
                      .Artist.ArtistName,
                  })}
                />
              </Form.Item>
            )}
          </Col>
          <Col span={4}>
            <Button
              className="w-full"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              {intl.formatMessage({
                id: LocalizationKeys.Misc.Form.Add,
              })}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default ArtistFooter

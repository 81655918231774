import { Venue, Venue as VenueModel } from '../../models/Venue'
import { Avatar, Space, Tag, Typography } from 'antd'
import { ConditionalLinkWrapper } from '@supplement-bacon/alela-uikit'
import { useAppSelector } from '../../reducers/hooks'
import { selectVenueById } from '../../reducers/VenueReducer'
import { AvatarProps } from 'antd/es/avatar'
import { addressFormatter } from '../../utils/formatters'

const { Text } = Typography

interface Props {
  id?: number
  model?: Venue
  linkToDetails?: boolean
  displayAddress?: boolean
  size?: AvatarProps['size']
}

export const VenueAvatar = ({
  id,
  model,
  linkToDetails = true,
  displayAddress = true,
  size = 'default',
}: Props) => {
  const venue = model ? model : useAppSelector(selectVenueById(id ?? 0))
  if (venue === undefined) return <></>
  return (
    <ConditionalLinkWrapper
      condition={linkToDetails}
      to={`/settings/venues/${venue.id}`}
    >
      <Space direction="horizontal">
        <Avatar shape="square" src={venue.logo} size={size}>
          {venue.name.slice(0, 1).toLocaleUpperCase()}
        </Avatar>
        <Space direction="vertical" size={0}>
          <Text>{venue.name}</Text>
          {displayAddress && venue.address && (
            <Text type="secondary">
              {addressFormatter(venue.address, 'full')}
            </Text>
          )}
        </Space>
      </Space>
    </ConditionalLinkWrapper>
  )
}

export type VenueAvatarType = { Avatar: typeof VenueAvatar }

import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons'
import { Badge, Button, Collapse, List, Space, Tooltip, Typography } from 'antd'
import { useAppDispatch } from '../reducers/hooks'
import { ReactNode } from 'react'
import { Approvable, SBModel } from '../utils/helpers/crud/models'
import { Actions } from '../modules/sbrm/components/Actions'
import { SBRMType } from '../modules/sbrm/SBRMModel'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../i18n/LocalizationKeys'

const { Text } = Typography

interface BaseExternalRequestsProps<T extends SBModel & Approvable> {
  entity: SBRMType
  items: T[]
  showEdit?: boolean
  renderItem: (item: T) => ReactNode
  updateMethod?: ((item: T) => any) | undefined
  deleteMethod?: ((item: T) => any) | undefined
  shouldRefreshList: (item: T) => void
}

const BaseExternalRequests = <T extends SBModel & Approvable>({
  entity,
  items,
  showEdit = true,
  renderItem,
  updateMethod = undefined,
  deleteMethod = undefined,
  shouldRefreshList,
}: BaseExternalRequestsProps<T>) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  return (
    <Collapse
      expandIconPosition={'end'}
      style={{ marginBottom: 16 }}
      items={[
        {
          key: '1',
          label: (
            <Space>
              <Text>
                {intl.formatMessage(
                  {
                    id: LocalizationKeys.Components.ExternalAdvancing.Request
                      .Title,
                  },
                  {
                    entity: intl.formatMessage({
                      id: `Entity-${entity}`,
                    }),
                  }
                )}
              </Text>
              <Badge count={items.length} />
            </Space>
          ),
          children: (
            <List
              itemLayout="horizontal"
              dataSource={items}
              renderItem={(item, index) => (
                <List.Item
                  style={{ padding: 0 }}
                  actions={[
                    <Space.Compact key={0}>
                      {updateMethod !== undefined && (
                        <Tooltip
                          title={intl.formatMessage({
                            id: LocalizationKeys.Components.ExternalAdvancing
                              .Request.Accept,
                          })}
                        >
                          <Button
                            key={'validate'}
                            icon={<CheckOutlined />}
                            style={{ color: 'green' }}
                            onClick={() =>
                              dispatch(
                                updateMethod({ ...item, isApproved: true })
                              ).then(() => {
                                shouldRefreshList(item)
                              })
                            }
                          />
                        </Tooltip>
                      )}
                      {showEdit === true && updateMethod !== undefined && (
                        <Actions
                          actions={['update']}
                          entity={entity}
                          entityId={item.id}
                          trigger={
                            <Tooltip
                              title={intl.formatMessage({
                                id: LocalizationKeys.Components
                                  .ExternalAdvancing.Request.Update,
                              })}
                            >
                              <Button key={'edit'} icon={<EditOutlined />} />
                            </Tooltip>
                          }
                        />
                      )}

                      {deleteMethod !== undefined && (
                        <Tooltip
                          title={intl.formatMessage({
                            id: LocalizationKeys.Components.ExternalAdvancing
                              .Request.Decline,
                          })}
                        >
                          <Button
                            key={'decline'}
                            icon={<CloseOutlined />}
                            style={{ color: 'red' }}
                            onClick={() => dispatch(deleteMethod(item))}
                          />
                        </Tooltip>
                      )}
                    </Space.Compact>,
                  ]}
                >
                  <List.Item.Meta avatar={renderItem(item)} />
                </List.Item>
              )}
            />
          ),
        },
      ]}
    />
  )
}

export default BaseExternalRequests

import { Link } from 'react-router-dom'
import { Dropdown, MenuProps, Space, Tag, Typography } from 'antd'
import { FilePdfOutlined } from '@ant-design/icons'
import { File as FileMode } from '../../models/File'
import { useAppSelector } from '../../reducers/hooks'
import { selectFileById } from '../../reducers/FileReducer'
import EllipsisMiddle from '../utils/EllipsisMiddle'
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons'
import { Actions } from '../../modules/sbrm/components/Actions'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import IconForFileExtension from '@supplement-bacon/alela-uikit/dist/esm/components/utils/IconForFileExtension'
import { ConditionalWrapper } from '@supplement-bacon/alela-uikit'

const { Text } = Typography

interface Props {
  id?: number
  model?: FileMode
  showDropdown?: boolean
  maxWidth?: number
}

export const FileCell = ({
  id,
  model,
  showDropdown = false,
  maxWidth = 100,
}: Props) => {
  const intl = useIntl()
  const file = model ? model : useAppSelector(selectFileById(id ?? 0))

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: file && (
        <Actions
          actions={['view']}
          entity={SBRMType.file}
          entityId={file.id}
          trigger={'View'}
        />
      ),
      icon: <EyeOutlined />,
    },
    {
      key: '2',
      label: file && (
        <Link to={file.downloadUrl}>
          {intl.formatMessage({ id: LocalizationKeys.Components.File.Cell })}
        </Link>
      ),
      icon: <DownloadOutlined />,
    },
  ]

  if (file == undefined) return <></>

  return (
    <ConditionalWrapper
      condition={showDropdown}
      wrapper={(children) => (
        <Dropdown menu={{ items }}>
          <Tag style={{ cursor: 'pointer' }}>{children}</Tag>
        </Dropdown>
      )}
    >
      <Space direction="horizontal" style={{ padding: '5px 2px 3px 2px' }}>
        <IconForFileExtension
          extension={file.extension}
          style={{ fontSize: 18 }}
        />
        <Space direction="vertical" size={0}>
          <EllipsisMiddle maxWidth={maxWidth} suffixCount={5}>
            {file.name}
          </EllipsisMiddle>
        </Space>
      </Space>
    </ConditionalWrapper>
  )
}

export type FileCellType = { Cell: typeof FileCell }

import { useIntl } from 'react-intl'
import React, { useEffect } from 'react'
import { Button, Form, Space } from 'antd'
import { useAppDispatch } from '../../../reducers/hooks'
import Create from '../../sbrm/external-advancing-link/Create'
import { createExternalAdvancingLink } from '../../../reducers/ExternalAdvancingLinkReducer'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

interface Props {
  bookingId: number
  eventId: number
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  onSuccess: () => void
}

const ALMCreate = ({
  bookingId,
  eventId,
  isLoading,
  setIsLoading,
  onSuccess,
}: Props) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()

  const onSubmit = () => {
    setIsLoading(true)

    form
      .validateFields()
      .then(async (result) =>
        dispatch(
          createExternalAdvancingLink({
            ...form.getFieldsValue(),
            booking: bookingId,
          })
        )
          .then(() => {
            onSuccess()
            form.resetFields()
          })
          .finally(() => setIsLoading(false))
      )
      .catch((_) => setIsLoading(false))
  }

  useEffect(() => {
    form.setFieldValue('booking', bookingId)
  }, [form])

  return (
    <>
      <Space direction="vertical" className="w-full">
        <Create form={form} eventId={eventId} />
        <Button
          type="primary"
          loading={isLoading}
          onClick={onSubmit}
          className="w-full"
        >
          {intl.formatMessage({ id: LocalizationKeys.SBRM.Save })}
        </Button>
      </Space>
    </>
  )
}

export default ALMCreate

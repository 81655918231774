import {
  getSupplierWithId,
  selectSelectedSupplier,
  setSelectedSupplier,
} from '../../reducers/SupplierReducer'
import { ArrowLeftOutlined, PhoneOutlined } from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import HasAccess from '../../components/HasAccess'
import { TableParams } from '../../models/TableParams'
import {
  SBRMContactAssociationType,
  SBRMType,
} from '../../modules/sbrm/SBRMModel'
import { AlelaPermission } from '../../utils/permissions'
import { initialQuery } from '../../utils/helpers/crud/models'
import { Actions } from '../../modules/sbrm/components/Actions'
import { Row, Col, Button, Space, Spin, Typography, Avatar, Modal } from 'antd'
import { DetailsPageHeader } from '../../components/layout/PageHeaders'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import {
  getContacts,
  resetContactQueryAndIds,
  selectContacts,
  setContactQuery,
} from '../../reducers/ContactReducer'
import { Contact } from '../../components/contact/Contact'
import { MetaDataKey } from '../../models/MetaData'
import { Supplier } from '../../components/supplier/Supplier'
import {
  getInvoices,
  resetInvoiceQueryAndIds,
  selectInvoices,
  setInvoiceQuery,
} from '../../reducers/InvoiceReducer'
import { Invoice } from '../../components/invoice/Invoice'
import { File } from '../../components/file/File'
import { SUPPLIERS_URL } from '../../utils/urls'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'

const { Text, Title } = Typography

const SupplierPage = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { supplierId } = useParams<{ supplierId: string }>()

  const contacts = useAppSelector(selectContacts())
  const invoices = useAppSelector(selectInvoices())
  const supplier = useAppSelector(selectSelectedSupplier())

  const {
    isOpen: SBRMisOpen,
    entity: SBRMEntity,
    action,
  } = useAppSelector((state) => state.SBRM)

  const [showPictureModal, setShowPictureModal] = useState(false)
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const openPictureModal = () => {
    setShowPictureModal(true)
  }

  const baseQuery: TableParams = {
    ...initialQuery,
    pagination: { current: 1, pageSize: 100 },
    filters: { suppliers: [Number(supplierId)] },
  }

  const fetchContacts = () => {
    const query: TableParams = { ...baseQuery, with: ['associations'] }
    dispatch(setContactQuery(query))
    dispatch(getContacts(query))
  }

  const fetchInvoices = () => {
    const query: TableParams = {
      ...baseQuery,
      with: ['sumPaid', 'supplier', 'category', 'event'],
    }
    dispatch(setInvoiceQuery(query))
    dispatch(getInvoices(query))
  }

  const handleInvoiceFiltersChanged = (query: TableParams) => {
    const nextQuery: TableParams = {
      ...baseQuery,
      ...query,
      with: ['sumPaid', 'supplier', 'category', 'event'],
      filters: {
        ...baseQuery.filters,
        ...query.filters,
      },
    }
    dispatch(setInvoiceQuery(nextQuery))
    dispatch(getInvoices(nextQuery))
  }

  useEffect(() => {
    if (isNaN(supplierId as unknown as number)) {
      navigate('/crm/suppliers')
    }
    dispatch(resetInvoiceQueryAndIds(initialQuery))
    dispatch(resetContactQueryAndIds(initialQuery))

    dispatch(setSelectedSupplier(Number(supplierId)))
    dispatch(getSupplierWithId(Number(supplierId)))

    fetchContacts()
    fetchInvoices()
  }, [supplierId])

  useEffect(() => {
    if (!isFirstRender && (!supplier || SBRMisOpen)) {
      return
    }
    setIsFirstRender(false)

    // Trigger when SBRM is closed and on init
    if (SBRMEntity === SBRMType.contactAssociation) {
      fetchContacts()
    } else if (SBRMEntity === SBRMType.invoice) {
      fetchInvoices()
    }
  }, [SBRMisOpen])

  useEffect(() => {
    if (SBRMisOpen || action !== 'delete') {
      // Trigger when SBRM is closed and on init
      return
    }
    /*
     * When the supplier is updated via the Actions edit button
     * We do not have a useEffect with getSupplierWithId
     * As the store will be updated locally
     *
     * However if the supplier is deleted we need to redirect on suppliers list
     * So we need to do the request and catch error
     */
    dispatch(getSupplierWithId(Number(supplierId))).catch((e) => {
      if (e.error.response.status == 404) {
        navigate('/crm/suppliers')
      }
    })
  }, [SBRMisOpen])

  if (!supplier) {
    return (
      <>
        <Spin />
        <Text>
          {intl.formatMessage({
            id: LocalizationKeys.Page.CRM.Supplier.Loading,
          })}
        </Text>
      </>
    )
  }

  return (
    <HasAccess permissions={[AlelaPermission.viewSupplier]}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Button
            type="text"
            icon={<ArrowLeftOutlined />}
            onClick={() => navigate(-1)}
          >
            {intl.formatMessage({
              id: LocalizationKeys.Page.CRM.Supplier.Back,
            })}
          </Button>
        </Col>
        <Col span={24}>
          <DetailsPageHeader
            toolbar={
              <Space direction="horizontal">
                {supplier?.phone && (
                  <Link to={`tel:${supplier.phone}`}>
                    <Button icon={<PhoneOutlined />}></Button>
                  </Link>
                )}
                <Actions
                  entity={SBRMType.supplier}
                  actions={['update', 'delete']}
                  entityId={supplier?.id}
                />
              </Space>
            }
          >
            <>
              <Space direction="horizontal">
                <Avatar
                  style={{ cursor: 'pointer' }}
                  shape="square"
                  src={supplier && supplier.logo}
                  size={'large'}
                  onClick={() => openPictureModal()}
                >
                  {supplier.name.slice(0, 1)}
                </Avatar>
                <Space direction="vertical">
                  <Title level={4} style={{ margin: 0 }}>
                    {supplier?.name}
                  </Title>
                  <Supplier.TypeTag id={supplier.type} />
                </Space>
              </Space>
              <Modal
                title={intl.formatMessage({
                  id: LocalizationKeys.Page.CRM.Supplier.UpdatePicture,
                })}
                open={showPictureModal}
                onOk={() => setShowPictureModal(false)}
                onCancel={() => setShowPictureModal(false)}
              >
                <File.Upload
                  action={`${SUPPLIERS_URL}/${supplier.id}/logo`}
                  accept="image/*"
                  onSuccessUpload={() => {
                    dispatch(getSupplierWithId(supplier.id))
                    setShowPictureModal(false)
                  }}
                />
              </Modal>
            </>
          </DetailsPageHeader>
        </Col>
        <Col span={24}>
          <PageTitle
            title={intl.formatMessage({
              id: LocalizationKeys.Page.CRM.Supplier.Team,
            })}
            level={5}
            badge={contacts.length}
            toolbar={
              <Actions
                actions={['create']}
                entity={SBRMType.contactAssociation}
                metadata={[
                  {
                    key: MetaDataKey.contactAssociationType,
                    value: SBRMContactAssociationType.supplier,
                  },
                  { key: MetaDataKey.supplierId, value: supplierId },
                ]}
              />
            }
          />
          <Contact.TableWithAssociation
            emptyActionsMetadata={[
              {
                key: MetaDataKey.contactAssociationType,
                value: SBRMContactAssociationType.supplier,
              },
              { key: MetaDataKey.supplierId, value: supplierId },
            ]}
          />
        </Col>
        <Col span={24}>
          <PageTitle
            title={intl.formatMessage({
              id: LocalizationKeys.Page.CRM.Supplier.Invoices,
            })}
            level={5}
            badge={invoices.length}
          />
          <Invoice.Filters queryChanged={handleInvoiceFiltersChanged} />
          <Invoice.Table displayEvent />
        </Col>
      </Row>
    </HasAccess>
  )
}
export default SupplierPage

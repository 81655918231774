import { SBRMType } from '../../../../modules/sbrm/SBRMModel'
import { SBModel } from '../../../../utils/helpers/crud/models'
import { ConfigItem, SBAsyncSelectConfig } from '../types'

/**
 * The only instance of our Singleton
 */
let instance: ReturnType<typeof makeSingleton<SBAsyncSelectConfig<SBModel>>>

/**
 * Singleton supplies accessors using Revealing Module
 * pattern and we use generics, since we could reuse
 * this across multiple singletons
 *
 * Note: Object.freeze() not required due to type narrowing!
 */
const makeSingleton = <T>(initial?: T) => {
  /** Closure of the singleton's value to keep it private */
  let _value: T | undefined = initial
  /** Only the accessors are returned */
  return {
    getValue: (): T | undefined => _value,
    getConfigForType: (type: SBRMType): ConfigItem<SBModel> | undefined =>
      _value !== undefined && _value !== null && _value!.hasOwnProperty(type)
        ? (_value as any)[type]
        : undefined,
    setValue: (value: T) => (_value = value),
  }
}

/**
 * Retrieves the only instance of the Singleton
 * and allows a once-only initialisation
 * (additional changes require the setValue accessor)
 */
const getConfigInstance = (initial?: SBAsyncSelectConfig<SBModel>) => {
  if (!instance) {
    instance = makeSingleton<SBAsyncSelectConfig<SBModel>>(initial)
    return instance
  }
  if (initial) {
    throw Error('Singleton already initialised')
  }
  return instance
}

export default getConfigInstance

import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { matchValueRule, requiredRule } from '../../../utils/rules'
import { useAppSelector } from '../../../reducers/hooks'
import { WarningOutlined } from '@ant-design/icons'
import { selectSelectedUser } from '../../../reducers/UserReducer'
import { Form, FormInstance, Input, Result, Typography } from 'antd'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

const { Text } = Typography

interface Props {
  form: FormInstance
  deleteWithConfirmation: boolean
}

const Delete = ({ form, deleteWithConfirmation }: Props) => {
  const intl = useIntl()
  const user = useAppSelector(selectSelectedUser())

  useEffect(() => {
    form.setFieldsValue(user)
  }, [user])

  return user ? (
    <Form form={form} layout="vertical">
      <Result
        title={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.AreYouSureToDelete,
        })}
        icon={<WarningOutlined style={{ color: '#dc4446' }} />}
      >
        <Text style={{ textAlign: 'justify' }}>
          {intl.formatMessage({
            id: LocalizationKeys.Misc.Form.ActionCannotBeUndone,
          })}
          <br />
          {intl.formatMessage({
            id: LocalizationKeys.Misc.Form.UserResourcesDeleteDescription,
          })}
        </Text>
      </Result>
      <Form.Item
        name="id"
        required
        rules={[requiredRule(intl)]}
        className="d-none"
      >
        <Input type="hidden" />
      </Form.Item>
      {deleteWithConfirmation && (
        <Form.Item
          label={
            <Text>
              {intl.formatMessage(
                { id: LocalizationKeys.Misc.Form.InputValueToConfirm },
                {
                  value: (
                    <Text strong>
                      {user.firstName} {user.lastName}
                    </Text>
                  ),
                }
              )}
            </Text>
          }
          rules={[matchValueRule(intl, `${user.firstName} ${user.lastName}`)]}
          name={`${user.firstName} ${user.lastName}`}
        >
          <Input placeholder={`${user.firstName} ${user.lastName}`} />
        </Form.Item>
      )}
    </Form>
  ) : (
    <></>
  )
}

export default Delete

import React from 'react'

const extractTextFromJSX = (jsxElement: React.ReactNode): string => {
  if (!jsxElement || !React.isValidElement(jsxElement)) {
    return ''
  }
  const children = React.Children.toArray(jsxElement.props.children)
  return children.filter((child) => typeof child === 'string').join('')
}

const extractTravelResumeFromCascaderOptionLabel = (
  jsxElement: React.ReactNode
): string => {
  if (
    !jsxElement ||
    !React.isValidElement(jsxElement) ||
    !jsxElement.props.travel
  ) {
    return ''
  }

  const travel = jsxElement.props.travel

  return travel.inbound
    ? `${travel.number} | ${travel.arrival.name}`
    : `${travel.number} | ${travel.departure.name}`
}

export { extractTextFromJSX, extractTravelResumeFromCascaderOptionLabel }

import { Tag } from 'antd'
import { useAppSelector } from '../../reducers/hooks'
import { selectRiderStatusById } from '../../reducers/RiderStatusReducer'
import { RiderStatus as RiderStatusModel } from '../../models/Rider'

interface Props {
  id?: number
  model?: RiderStatusModel
}

export const RiderStatusTag = ({ id, model }: Props) => {
  const status = model ? model : useAppSelector(selectRiderStatusById(id ?? 0))

  return status ? <Tag color={status.color}>{status.name}</Tag> : <></>
}

export type RiderStatusTagType = { Tag: typeof RiderStatusTag }

import { SBModel } from '../utils/helpers/crud/models'
import { Event } from './Event'
import { Weather } from './Weather'

const REPORT_ITEM_TYPE_COST_ID = 1
const REPORT_ITEM_TYPE_COST_COLOR = '#dc4446'
const REPORT_ITEM_TYPE_REVENUE_ID = 2
const REPORT_ITEM_TYPE_REVENUE_COLOR = '#0aa20a'

interface Report extends SBModel {
  notes?: string
  attendees: number
  items: ReportItem[]
  weather?: Weather
  event?: Event
}

interface ReportItem extends SBModel {
  name: string
  icon: string
  type: ReportItemType
  order: number
  amount?: number
}

interface ReportItemType extends SBModel {
  name: string
  color: string
}

interface CreateReportItem {
  id: number
  amount: number
}

interface CreateReport {
  event: number
  items: CreateReportItem[]
  attendees: number
  notes?: string
  weather?: Weather
}

interface ReorderReportItem {
  id: number
  order: number
}

interface UpdateReport extends CreateReport {
  id: number
}

export {
  REPORT_ITEM_TYPE_COST_ID,
  REPORT_ITEM_TYPE_COST_COLOR,
  REPORT_ITEM_TYPE_REVENUE_ID,
  REPORT_ITEM_TYPE_REVENUE_COLOR,
  type Report,
  type ReportItem,
  type ReportItemType,
  type CreateReport,
  type CreateReportItem,
  type ReorderReportItem,
  type UpdateReport,
}

import { Button, Card, Space, Tag } from 'antd'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Actions } from '../../../modules/sbrm/components/Actions'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import {
  getEventStatuses,
  setEventStatusQuery,
} from '../../../reducers/EventStatusReducer'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import SBTable from '../../../components/SBTable'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { extractSortDataFromSorterResult } from '../../../utils/table/sorter'
import { ColorTag } from '../../../components/ColorTag'
import { useNavigate } from 'react-router-dom'
import { TableParams } from '../../../models/TableParams'
import { initialQuery } from '../../../utils/helpers/crud/models'
import { MarketingTaskStatus as MarketingTaskStatusModel } from '../../../models/MarketingTask'
import {
  getMarketingTaskStatuses,
  selectMarketingTaskStatuses,
  setMarketingTaskStatusQuery,
} from '../../../reducers/MarketingTaskStatusReducer'

const MarketingTaskStatusesPage = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const statuses = useAppSelector(selectMarketingTaskStatuses())
  const { query, isLoading } = useAppSelector(
    (state) => state.marketingTaskStatus
  )
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const columns: ColumnsType<MarketingTaskStatusModel> = [
    {
      key: 'name',
      title: intl.formatMessage({ id: LocalizationKeys.Misc.Table.Name }),
      render: (record: MarketingTaskStatusModel) => record.name,
    },
    {
      key: 'color',
      title: intl.formatMessage({ id: LocalizationKeys.Misc.Table.Color }),
      render: (record: MarketingTaskStatusModel) =>
        record.color && <ColorTag color={record.color} />,
    },
    {
      key: 'isDraft',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.Settings.EventStatus.DefaultStatus,
      }),
      align: 'center',
      render: (record: MarketingTaskStatusModel) =>
        record.isDefault ? <Tag color="green">Yes</Tag> : <></>,
    },
    {
      key: 'actions',
      align: 'right',
      render: (record: MarketingTaskStatusModel) => (
        <Actions
          actions={['update', 'delete']}
          entity={SBRMType.marketingTaskStatus}
          entityId={record.id}
        />
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter:
      | SorterResult<MarketingTaskStatusModel>
      | SorterResult<MarketingTaskStatusModel>[]
  ) => {
    const newQuery = {
      ...query,
      pagination,
      filters,
      ...extractSortDataFromSorterResult(sorter),
    }
    dispatch(setEventStatusQuery(newQuery))
    dispatch(getEventStatuses(newQuery))
  }

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      return
    }
    setIsFirstRender(false)

    // Trigger when SBRM is closed an on init
    const baseQuery: TableParams = {
      ...initialQuery,
      pagination: { current: 1, pageSize: 100 },
    }
    dispatch(setMarketingTaskStatusQuery(baseQuery))
    dispatch(getMarketingTaskStatuses(baseQuery))
  }, [dispatch, SBRMIsOpen])

  return (
    <Card
      bordered={false}
      styles={{ body: { padding: 0 } }}
      title={
        <PageTitle
          title={intl.formatMessage({
            id: LocalizationKeys.Page.Settings.MarketingTaskStatus.Title,
          })}
          level={5}
          toolbar={
            <Space direction="horizontal">
              <Actions
                actions={['create']}
                entity={SBRMType.marketingTaskStatus}
              />
              <Button
                onClick={() =>
                  navigate('/settings/marketing-task-statuses/trashed')
                }
              >
                {intl.formatMessage({
                  id: LocalizationKeys.Page.Settings.EventStatus.SeeArchived,
                })}
              </Button>
            </Space>
          }
        />
      }
    >
      <SBTable
        entity={SBRMType.marketingTaskStatus}
        scroll={{ x: 600 }}
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={statuses}
        pagination={query.pagination}
        loading={isLoading}
        onChange={handleTableChange}
      />
    </Card>
  )
}

export default MarketingTaskStatusesPage

import { Navigate, Route, Routes } from 'react-router-dom'
import { LayoutSettings } from '../../../layouts/LayoutSettings'
import VenueTypesPage from '../../../pages/settings/VenueTypesPage'
import EventStatusesPage from '../../../pages/settings/event-status/EventStatusesPage'
import HotelsPage from '../../../pages/settings/HotelsPage'
import ContactTypePage from '../../../pages/settings/ContactTypePage'
import PerformanceTypePage from '../../../pages/settings/PerformanceTypePage'
import VenuesPage from '../../../pages/settings/venue/VenuesPage'
import VenuePage from '../../../pages/settings/venue/VenuePage'
import StationsPage from '../../../pages/settings/StationsPage'
import OrganizationPage from '../../../pages/settings/OrganizationPage'
import ContractsStatusPage from '../../../pages/settings/ContractsStatusPage'
import RiderStatusPage from '../../../pages/settings/RiderStatusPage'
import ItemsPage from '../../../pages/settings/reporting/ItemsPage'
import ReorderItemsPage from '../../../pages/settings/reporting/ReorderItemsPage'
import VenuesArchived from '../../../pages/settings/venue/VenuesArchived'
import OrganizationSettingsPage from '../../../pages/settings/OrganizationSettingsPage'
import InvoiceCategoryPage from '../../../pages/settings/InvoiceCategoryPage'
import EventStatusesArchivedPage from '../../../pages/settings/event-status/EventStatusesArchivedPage'
import OrganizationRolesPage from '../../../pages/settings/OrganizationRolesPage'
import BookingTypesPage from '../../../pages/settings/BookingTypesPage'
import MarketingTaskStatusesPage from '../../../pages/settings/marketing-task-status/MarketingTaskStatusesPage'
import RiderTypePage from '../../../pages/settings/RiderTypePage'

const SettingsRoutes = () => {
  return (
    <Routes>
      <Route element={<LayoutSettings />}>
        <Route index path="venues" element={<VenuesPage />} />
        <Route path="venues/trashed" element={<VenuesArchived />} />
        <Route path="venues/:venueId" element={<VenuePage />} />
        <Route index path="event-statuses" element={<EventStatusesPage />} />
        <Route
          path="event-statuses/trashed"
          element={<EventStatusesArchivedPage />}
        />
        <Route
          index
          path="marketing-task-statuses"
          element={<MarketingTaskStatusesPage />}
        />
        <Route
          path="marketing-task-statuses/trashed"
          element={<EventStatusesArchivedPage />}
        />
        <Route index path="booking-types" element={<BookingTypesPage />} />
        <Route index path="venue-types" element={<VenueTypesPage />} />
        <Route index path="hotels" element={<HotelsPage />} />
        <Route index path="contact-types" element={<ContactTypePage />} />
        <Route
          index
          path="performance-types"
          element={<PerformanceTypePage />}
        />
        <Route index path="rider-types" element={<RiderTypePage />} />

        <Route
          index
          path="invoice-categories"
          element={<InvoiceCategoryPage />}
        />
        <Route index path="stations" element={<StationsPage />} />
        <Route path="reporting/items" element={<ItemsPage />} />
        <Route path="reporting/items/reorder" element={<ReorderItemsPage />} />
        <Route
          index
          path="contract-statuses"
          element={<ContractsStatusPage />}
        />
        <Route index path="rider-statuses" element={<RiderStatusPage />} />
        <Route path="organization" element={<OrganizationPage />} />
        <Route path="organization-roles" element={<OrganizationRolesPage />} />
        <Route
          path="organization-settings"
          element={<OrganizationSettingsPage />}
        />
        <Route path="*" element={<Navigate to={'venues'} />} />
      </Route>
    </Routes>
  )
}

export default SettingsRoutes

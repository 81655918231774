import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIDelete,
  SBAPIFetchPaginatedDispatch,
  SBAPIUpdate,
} from '../utils/helpers/SBAPIHelper'
import { Role } from '../models/Role'
import { ROLES_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { roleSchema } from '../models/schema'
import axios from 'axios'

const initialState: SBState<Role> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: {
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },
}

const slice = createSlice({
  name: 'role',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getRolesSuccess,
  setQuery: setRoleQuery,
  reset: resetRoleState,
  resetQueryAndIds: resetRoleQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: Role }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectRoles = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedRole = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectRoleById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectRolesByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedRole =
  (role: Role | number) => async (dispatch: AppDispatch) =>
    dispatch(slice.actions.setSelectedId(getIdOrModelId<Role>(role)))

export const getRoles = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<Role>(
    ROLES_URL,
    params,
    [roleSchema],
    slice.actions
  )

export const createRole = (role: Role) =>
  SBAPICreate<Role>(role, ROLES_URL, roleSchema, slice.actions)

export const updateRole = (role: Role) =>
  SBAPIUpdate<Role>(role, `${ROLES_URL}/${role.id}`, slice.actions)

export const deleteRole = (role: Role) =>
  SBAPIDelete<Role>(role, `${ROLES_URL}/${role.id}`, slice.actions)

import {
  notMatchValuesRule,
  requiredRule,
  stringRule,
} from '../../../utils/rules'
import {
  getPermissions,
  selectPermissions,
} from '../../../reducers/PermissionReducer'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { TableParams } from '../../../models/TableParams'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { initialQuery } from '../../../utils/helpers/crud/models'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import { Form, FormInstance, Input, Spin } from 'antd'
import { roleNameNotEditable } from '../../../models/Role'
import { Tree } from 'antd'
import type { TreeProps } from 'antd'
import { Permission } from '../../../models/Permission'
import { capitalizeFirstLetter } from '../../../utils/helpers/StringHelper'
import { isSBRMType } from '../../../modules/sbrm/SBRMModel'

export const groupBy = <T,>(
  array: T[],
  predicate: (value: T, index: number, array: T[]) => string
) =>
  array.reduce((acc, value, index, array) => {
    ;(acc[predicate(value, index, array)] ||= []).push(value)
    return acc
  }, {} as { [key: string]: T[] })

interface Props {
  form: FormInstance
}

const Create = ({ form }: Props) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const permissions = useAppSelector(selectPermissions())
  const { isLoading } = useAppSelector((state) => state.permission)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([])
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([])
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true)

  const treeData = Object.entries(
    groupBy<Permission>(permissions, (permission) => permission.entity)
  ).map((value, index) => ({
    title: capitalizeFirstLetter(
      value[0] != 'null' && isSBRMType(value[0])
        ? intl.formatMessage({
            id: LocalizationKeys.Entity[value[0]] ?? 0,
          })
        : 'Misc'
    ),
    key: '0-' + value[0],
    children: value[1].map((item) => ({
      title: capitalizeFirstLetter(item.name),
      key: item.id,
    })),
  }))

  const onExpand: TreeProps['onExpand'] = (expandedKeysValue) => {
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    setExpandedKeys(expandedKeysValue)
    setAutoExpandParent(false)
  }

  const onCheck: TreeProps['onCheck'] = (checkedKeysValue) => {
    setCheckedKeys(checkedKeysValue as React.Key[])
  }

  useEffect(() => {
    if (!SBRMIsOpen) {
      // We want to trigger only the reset
      // when the SBRM opens
      return
    }

    form.resetFields()
    setCheckedKeys([])
    setExpandedKeys([])

    const baseQuery: TableParams = {
      ...initialQuery,
      pagination: { current: 1, pageSize: 1000 },
    }
    // Fetch all permissions
    dispatch(getPermissions(baseQuery))
  }, [SBRMIsOpen])

  useEffect(() => {
    form.setFieldValue(
      'permissions',
      checkedKeys.filter((permissionId) => typeof permissionId === 'number')
    )
  }, [checkedKeys])

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="name"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Name,
        })}
        rules={[
          stringRule(intl),
          requiredRule(intl),
          notMatchValuesRule(intl, roleNameNotEditable),
        ]}
      >
        <Input />
      </Form.Item>
      {isLoading && !treeData && <Spin />}
      <Form.Item
        label={intl.formatMessage({
          id: LocalizationKeys.Components.Organization.Roles.Permissions,
        })}
        name="permissions"
        required
        rules={[requiredRule(intl)]}
      >
        <Tree
          style={{ background: '#1f1f1f' }}
          checkable
          selectable={false}
          onExpand={onExpand}
          expandedKeys={expandedKeys}
          autoExpandParent={autoExpandParent}
          onCheck={onCheck}
          checkedKeys={checkedKeys}
          treeData={treeData}
        />
        <Input type="hidden" />
      </Form.Item>
    </Form>
  )
}

export default Create

import { useAppSelector } from '../../reducers/hooks'
import { Booking as UIKitBooking } from '@supplement-bacon/alela-uikit'
import { Booking } from '../../models/Booking'
import { selectBookingById } from '../../reducers/BookingReducer'
import { selectCurrencyById } from '../../reducers/CurrencyReducer'

interface Props {
  id?: number
  model?: Booking
}

export const BookingAmountsTag = ({ id, model }: Props) => {
  const booking = model ? model : useAppSelector(selectBookingById(id!))
  const currency = useAppSelector(selectCurrencyById(booking?.currency ?? 0))

  if (booking === undefined || currency === undefined) return <></>

  return <UIKitBooking.AmountsTag booking={booking} currency={currency} />
}

export type BookingAmountsTagType = { AmountsTag: typeof BookingAmountsTag }

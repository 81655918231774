import { StyleSheet } from '@react-pdf/renderer'

export const commonStyle = StyleSheet.create({
  section: { padding: 30 },
  titleSection: { marginBottom: 10, marginTop: 30 },
  col1: { width: '8.333%', padding: 5 },
  col2: { width: '16.666%', padding: 5 },
  col3: { width: '25%', padding: 5 },
  col4: { width: '33.333%', padding: 5 },
  col5: { width: '41.666%', padding: 5 },
  col6: { width: '50%', padding: 5 },
  col7: { width: '58.333%', padding: 5 },
  col8: { width: '66.666%', padding: 5 },
  col9: { width: '75.0%', padding: 5 },
  col10: { width: '83.333%', padding: 5 },
  col11: { width: '91.666%', padding: 5 },
  col12: { width: '100%', padding: 5 },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    rowGap: 10,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
  },
  h1: {
    fontSize: 20,
  },
  h2: {
    fontSize: 18,
  },
  h3: {
    fontSize: 15,
  },
  h4: {
    fontSize: 13,
  },
  h5: {
    fontSize: 11,
  },
  p: {
    fontSize: 10,
  },
  muted: {
    fontSize: 10,
    color: 'grey',
  },

  spaceVertical: {
    display: 'flex',
    flexDirection: 'column',
  },
  spaceHorizontal: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 4,
  },
  tag: {
    width: 'auto',
    backgroundColor: '#eee',
    padding: '3pt 5pt',
    borderRadius: 4,
    border: '1px solid #dedede',
    color: 'grey',
    fontSize: 9,
  },
  footer: {
    position: 'absolute',
    flexDirection: 'row',
    fontSize: 12,
    bottom: 15,
    left: 15,
    right: 15,
  },
  footerElement: {
    width: '100px',
    height: '100px',
  },
})

import {
  getRiderStatuses,
  selectRiderStatuses,
  setRiderStatusQuery,
} from '../../reducers/RiderStatusReducer'
import { useIntl } from 'react-intl'
import { useEffect, useRef, useState } from 'react'
import { Card, InputRef, Tag } from 'antd'
import SBTable from '../../components/SBTable'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { Actions } from '../../modules/sbrm/components/Actions'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import getColumnSearchProps from '../../utils/table/getColumnSearchProps'
import { extractSortDataFromSorterResult } from '../../utils/table/sorter'
import { RiderStatus as RiderStatusModel } from '../../models/Rider'
import LocalizationKeys from '../../i18n/LocalizationKeys'

const RiderStatusPage = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const searchInput = useRef<InputRef>(null)
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const statuses = useAppSelector(selectRiderStatuses())
  const { query, isLoading } = useAppSelector((state) => state.riderStatus)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const handleSearch = (
    selectedKeys: string[],
    dataIndex: keyof RiderStatusModel
  ) => {
    const newQuery = {
      ...query,
      ...{ search: { [dataIndex]: selectedKeys[0] } },
    }
    dispatch(setRiderStatusQuery(newQuery))
    dispatch(getRiderStatuses(newQuery))
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    const newQuery = {
      ...query,
      ...{ search: { dataIndex: null } },
    }
    dispatch(setRiderStatusQuery(newQuery))
    dispatch(getRiderStatuses(newQuery))
  }

  const columns: ColumnsType<RiderStatusModel> = [
    {
      key: 'name',
      title: intl.formatMessage({ id: LocalizationKeys.Misc.Table.Name }),
      ...getColumnSearchProps<RiderStatusModel>(
        'name',
        searchInput,
        handleSearch,
        handleReset,
        intl,
        isLoading
      ),
      render: (status: RiderStatusModel) => <>{status.name}</>,
    },
    {
      key: 'color',
      title: intl.formatMessage({ id: LocalizationKeys.Misc.Table.Color }),
      render: (status: RiderStatusModel) => (
        <Tag color={status.color}>{status.color}</Tag>
      ),
    },
    {
      key: 'icon',
      title: intl.formatMessage({ id: LocalizationKeys.Misc.Table.Icon }),
      render: (status: RiderStatusModel) => <Tag>{status.icon}</Tag>,
    },
    {
      key: 'isDefault',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.Settings.EventStatus.DefaultStatus,
      }),
      align: 'center',
      render: (record: RiderStatusModel) =>
        record.isDefault ? <Tag color="green">Yes</Tag> : <></>,
    },
    {
      key: 'actions',
      align: 'right',
      render: (status: RiderStatusModel) => (
        <Actions
          actions={['update', 'delete']}
          entity={SBRMType.riderStatus}
          entityId={status.id}
        />
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<RiderStatusModel> | SorterResult<RiderStatusModel>[]
  ) => {
    const newQuery = {
      ...query,
      pagination,
      filters,
      ...extractSortDataFromSorterResult(sorter),
    }
    dispatch(setRiderStatusQuery(newQuery))
    dispatch(getRiderStatuses(newQuery))
  }

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      return
    }
    setIsFirstRender(false)

    // Trigger when SBRM is closed and on init
    dispatch(getRiderStatuses(query))
  }, [dispatch, SBRMIsOpen])

  return (
    <Card
      bordered={false}
      styles={{ body: { padding: 0 } }}
      title={
        <PageTitle
          title={intl.formatMessage({
            id: LocalizationKeys.Page.Settings.RiderStatus,
          })}
          level={5}
          toolbar={
            <Actions actions={['create']} entity={SBRMType.riderStatus} />
          }
        />
      }
    >
      <SBTable
        entity={SBRMType.riderStatus}
        scroll={{ x: 600 }}
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={statuses}
        pagination={query.pagination}
        loading={isLoading}
        onChange={handleTableChange}
      />
    </Card>
  )
}

export default RiderStatusPage

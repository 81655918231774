import {
  getExternalAdvancingLinks,
  resetExternalAdvancingLinkQueryAndIds,
  setSelectedExternalAdvancingLink,
} from '../../reducers/ExternalAdvancingLinkReducer'
import { useIntl } from 'react-intl'
import ALMList from './components/ALMList'
import { useEffect, useState } from 'react'
import ALMDelete from './components/ALMDelete'
import ALMCreate from './components/ALMCreate'
import ALMUpdate from './components/ALMUpdate'
import { TableParams } from '../../models/TableParams'
import { selectEventById } from '../../reducers/EventReducer'
import { initialQuery } from '../../utils/helpers/crud/models'
import { selectArtistById } from '../../reducers/ArtistReducer'
import { Button, Modal, Space, Spin, Tooltip, Typography } from 'antd'
import { selectBookingById } from '../../reducers/BookingReducer'
import { ArrowLeftOutlined, LinkOutlined } from '@ant-design/icons'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import LocalizationKeys from '../../i18n/LocalizationKeys'

const { Text } = Typography

interface Props {
  eventId: number
  bookingId: number
}

const AdvancingLinkManager = ({ eventId, bookingId }: Props) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const event = useAppSelector(selectEventById(eventId))
  const booking = useAppSelector(selectBookingById(bookingId))
  const artist = useAppSelector(selectArtistById(booking?.artist ?? 0))

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
  const [action, setAction] = useState<'list' | 'create' | 'update' | 'delete'>(
    'list'
  )

  const fetchLinks = () => {
    const baseQuery: TableParams = {
      ...initialQuery,
      pagination: { current: 1, pageSize: 1000 },
      filters: { bookings: [bookingId] },
    }
    dispatch(getExternalAdvancingLinks(baseQuery))
  }

  useEffect(() => {
    if (modalIsOpen === false) return
    // Fetch links when model opens
    fetchLinks()
  }, [modalIsOpen])

  if (event === undefined || booking === undefined || artist === undefined)
    return <Spin />

  return (
    <>
      <Tooltip
        title={intl.formatMessage({
          id: LocalizationKeys.ExternalAdvancingManager.Tooltip,
        })}
      >
        <Button
          type="primary"
          onClick={() => {
            setModalIsOpen(true)
            setAction('list')
          }}
          icon={<LinkOutlined />}
        />
      </Tooltip>

      <Modal
        open={modalIsOpen}
        confirmLoading={isLoading}
        title={
          <Space>
            {action === 'list' ? undefined : (
              <Text
                onClick={() => setAction('list')}
                style={{ cursor: 'pointer' }}
              >
                <ArrowLeftOutlined />
              </Text>
            )}
            {intl.formatMessage(
              {
                id: LocalizationKeys.ExternalAdvancingManager.Title,
              },
              { artist: artist.name }
            )}
          </Space>
        }
        onCancel={() => {
          if (action === 'list') {
            setModalIsOpen(false)
            // TODO:
            // When reseting the store on closing, we cannot comment as the store is empty
            // dispatch(resetExternalAdvancingLinkQueryAndIds({}))
          } else {
            setAction('list')
          }
        }}
        cancelButtonProps={{
          style: { display: 'none' },
        }}
        okText={intl.formatMessage({
          id: LocalizationKeys.ExternalAdvancingManager.NewLink,
        })}
        okButtonProps={
          action !== 'list'
            ? {
                style: { display: 'none' },
              }
            : { style: { width: '100%', marginInlineStart: 0, marginTop: 10 } }
        }
        onOk={() => {
          if (action === 'list') {
            setAction('create')
          }
        }}
        styles={{
          body: { paddingTop: 10 },
        }}
      >
        {action === 'list' && (
          <ALMList
            shouldUpdateId={(linkId) => {
              dispatch(setSelectedExternalAdvancingLink(linkId))
              setAction('update')
            }}
            shouldDeleteId={(linkId) => {
              dispatch(setSelectedExternalAdvancingLink(linkId))
              setAction('delete')
            }}
          />
        )}
        {action === 'create' && (
          <ALMCreate
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            bookingId={bookingId}
            eventId={eventId}
            onSuccess={() => {
              fetchLinks()
              setAction('list')
            }}
          />
        )}
        {action === 'update' && (
          <ALMUpdate
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            eventId={eventId}
            onSuccess={() => {
              fetchLinks()
              setAction('list')
            }}
          />
        )}
        {action === 'delete' && (
          <ALMDelete
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            onSuccess={() => {
              fetchLinks()
              setAction('list')
            }}
          />
        )}
      </Modal>
    </>
  )
}

export default AdvancingLinkManager

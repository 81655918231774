import { Button, Alert as AntdAlert } from 'antd'
import { useEffect, useState } from 'react'
import useAlelaHelp, { HelpArticle } from '../../utils/hooks/useAlelaHelp'
import { LocalizationKey } from '../../i18n/LocalizationKeys'
import { useIntl } from 'react-intl'

interface Props {
  article: HelpArticle
  displayMode?: 'modal' | 'sider' | 'sidebar'
  title?: LocalizationKey
  description?: LocalizationKey
  buttonLabel?: LocalizationKey
  size?: 'condensed' | 'default'
  closable?: boolean
  style?: React.CSSProperties
}

export const Alert = ({
  article,
  displayMode = 'modal',
  title = LocalizationKey.HelpAlertTitle,
  description = LocalizationKey.HelpAlertDescription,
  buttonLabel = LocalizationKey.HelpAlertButton,
  size = 'default',
  closable = true,
  style,
}: Props) => {
  const intl = useIntl()
  const help = useAlelaHelp()

  const [isRead, setIsRead] = useState(true)
  const [articleId, setArticleId] = useState<string>('')

  const handleClose = () => {
    help.markArticleAsRead(articleId)
  }

  useEffect(() => {
    setArticleId(help.articles[article])
  }, [])

  useEffect(() => {
    if (!articleId) return
    setIsRead(help.settings.readArticles.includes(articleId))
  }, [articleId])

  if (isRead) return <></>

  return (
    <AntdAlert
      style={{
        ...style,
        alignItems: 'center',
        backgroundColor: '#1a1223',
        border: '1px solid #5762bb',
      }}
      description={
        size == 'default' ? intl.formatMessage({ id: description }) : undefined
      }
      type="info"
      showIcon
      message={intl.formatMessage({ id: title })}
      closable={closable}
      onClose={() => handleClose()}
      action={
        <Button
          type="primary"
          onClick={() =>
            window.Beacon('article', articleId, {
              type: displayMode,
            })
          }
        >
          {intl.formatMessage({ id: buttonLabel })}
        </Button>
      }
    />
  )
}

export type AlertType = { Alert: typeof Alert }

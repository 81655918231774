import { Alert } from 'antd'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { WarningOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'

interface Props {
  entity: SBRMType
}

export const CommentHeader = ({ entity }: Props) => {
  const intl = useIntl()

  if (entity === SBRMType.externalAdvancingLink) {
    // Display banner informing you're talking with people outside your organization
    return (
      <Alert
        style={{ padding: 15 }}
        banner
        showIcon
        type="warning"
        icon={<WarningOutlined style={{ fontSize: 20 }} />}
        description={
          <span style={{ color: '#d89513' }}>
            {intl.formatMessage({
              id: LocalizationKeys.Components.Comment.PeopleOutsideOrganization,
            })}
          </span>
        }
      />
    )
  }

  return <></>
}

export type CommentHeaderType = { Header: typeof CommentHeader }

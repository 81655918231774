import { Avatar, Space, Typography } from 'antd'
import { selectArtistsByIds } from '../../reducers/ArtistReducer'
import { useAppSelector } from '../../reducers/hooks'
import { AvatarProps } from 'antd/es/avatar'
import { Artist } from '../../models/Artist'
import { ConditionalLinkWrapper } from '@supplement-bacon/alela-uikit'

const { Text } = Typography
interface Props {
  ids?: number[]
  models?: Artist[]
  linkToDetails?: boolean
  size?: AvatarProps['size']
}

export const ArtistAvatarGroup = ({
  ids,
  models,
  linkToDetails = true,
  size = 'default',
}: Props) => {
  const artists = models
    ? models
    : useAppSelector(selectArtistsByIds(ids ?? []))

  return artists ? (
    <Avatar.Group>
      {artists.map((artist) => (
        <ConditionalLinkWrapper
          key={artist.id}
          condition={linkToDetails}
          to={`/artists/${artist.id}`}
        >
          <Space direction="horizontal">
            <Avatar shape="circle" src={artist.spotify_image} size={size} />
            <Space direction="vertical" size={0}>
              <Text>{artist.name}</Text>
            </Space>
          </Space>
        </ConditionalLinkWrapper>
      ))}
    </Avatar.Group>
  ) : (
    <></>
  )
}

export type ArtistAvatarGroupType = { AvatarGroup: typeof ArtistAvatarGroup }

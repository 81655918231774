import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIUpdate,
} from '../utils/helpers/SBAPIHelper'
import { CONTRACT_STATUSES_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { contractStatusSchema } from '../models/schema'
import { initialQuery } from '../utils/helpers/crud/models'
import { ContractStatus } from '../models/ContractStatus'

export const initialContractQuery: TableParams = {
  ...initialQuery,
}

const initialState: SBState<ContractStatus> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: {
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },
}

const slice = createSlice({
  name: 'contractStatus',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getContractsStatusSuccess,
  setQuery: setContractStatusQuery,
  reset: resetContractStatusState,
  resetQueryAndIds: resetContractStatusQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: ContractStatus }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectContractStatuses = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedContractStatus = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectContractStatusById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectContractStatusesByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedContractStatus =
  (contractStatus: ContractStatus | number) => async (dispatch: AppDispatch) =>
    dispatch(
      slice.actions.setSelectedId(
        getIdOrModelId<ContractStatus>(contractStatus)
      )
    )

export const getContractStatuses = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<ContractStatus>(
    CONTRACT_STATUSES_URL,
    params,
    [contractStatusSchema],
    slice.actions
  )

export const createContractStatus = (contractStatus: ContractStatus) =>
  SBAPICreate<ContractStatus>(
    contractStatus,
    CONTRACT_STATUSES_URL,
    contractStatusSchema,
    slice.actions
  )

export const updateContractStatus = (contractStatus: ContractStatus) =>
  SBAPIUpdate<ContractStatus>(
    contractStatus,
    `${CONTRACT_STATUSES_URL}/${contractStatus.id}`,
    slice.actions
  )

export const deleteContractStatus = (contractStatus: ContractStatus) =>
  SBAPIDelete<ContractStatus>(
    contractStatus,
    `${CONTRACT_STATUSES_URL}/${contractStatus.id}`,
    slice.actions
  )

import { CustomAddressWithRelation } from '../../models/Address'
import { GroundLocation } from '../../models/Ground'
import { Hotel } from '../../models/Hotel'
import { TravelWithRelation } from '../../models/Travel'
import { Venue } from '../../models/Venue'
import { SBRMType } from '../../modules/sbrm/SBRMModel'

const groundTypeToCascaderValue = (
  groundLocation: GroundLocation,
  hotels: Hotel[],
  venues: Venue[],
  travels: TravelWithRelation[],
  customAddess: CustomAddressWithRelation[]
) => {
  if (groundLocation.type !== SBRMType.travel) {
    return groundLocation.type
  }
  const travel = travels.find((t) => t.id === groundLocation.id)

  return travel?.inbound ? 'inbound' : 'outbound'
}

const groundTypeToAPI = (type: string) =>
  type === 'inbound' || type === 'outbound' ? 'travel' : type

const fieldValueIsValid = (field: any): field is [string, number | string] =>
  Array.isArray(field) &&
  field.length === 2 &&
  typeof field[0] === 'string' &&
  (typeof field[0] === 'string' || typeof field[0] === 'number')

export { groundTypeToCascaderValue, groundTypeToAPI, fieldValueIsValid }

import React from 'react'
import { useIntl } from 'react-intl'
import { Button, Form, Space } from 'antd'
import { useAppDispatch } from '../../../reducers/hooks'
import Update from '../../sbrm/external-advancing-link/Update'
import { updateExternalAdvancingLink } from '../../../reducers/ExternalAdvancingLinkReducer'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

interface Props {
  eventId: number
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  onSuccess: () => void
}

const ALMUpdate = ({ eventId, isLoading, setIsLoading, onSuccess }: Props) => {
  const intl = useIntl()
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()

  const onSubmit = () => {
    setIsLoading(true)

    form
      .validateFields()
      .then(async (result) =>
        dispatch(updateExternalAdvancingLink(form.getFieldsValue()))
          .then(() => {
            onSuccess()
            form.resetFields()
          })
          .finally(() => setIsLoading(false))
      )
      .catch((_) => setIsLoading(false))
  }

  return (
    <>
      <Space direction="vertical" className="w-full">
        <Update form={form} eventId={eventId} />
        <Button
          type="primary"
          loading={isLoading}
          onClick={onSubmit}
          className="w-full"
        >
          {intl.formatMessage({ id: LocalizationKeys.SBRM.Update })}
        </Button>
      </Space>
    </>
  )
}

export default ALMUpdate

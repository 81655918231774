import { FC } from 'react'
import { Tag, TagType } from './Tag'
import { Option, OptionType } from './Option'
import { RoleForm, RoleFormType } from './form/Form'

export const Role: FC &
  TagType &
  OptionType &
  RoleFormType = (): JSX.Element => <></>

Role.Tag = Tag
Role.Option = Option
Role.Form = RoleForm

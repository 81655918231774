import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Form, FormInstance, Input, Radio } from 'antd'
import { requiredRule, stringRule } from '../../../utils/rules'
import { useAppSelector } from '../../../reducers/hooks'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

interface Props {
  form: FormInstance
}

const Create = ({ form }: Props) => {
  const intl = useIntl()

  const [viaSpotify, setViaSpotify] = useState<boolean>(true)

  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  useEffect(() => {
    if (!SBRMIsOpen) {
      // We want to trigger only the reset
      // when the SBRM opens
      return
    }
    form.setFieldValue('method', 0)
    setViaSpotify(true)
  }, [SBRMIsOpen])

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="method"
        initialValue={0}
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.HowToCreate,
        })}
        rules={[requiredRule(intl)]}
      >
        <Radio.Group onChange={(e: any) => setViaSpotify(e.target.value === 0)}>
          <Radio.Button value={0}>
            {intl.formatMessage({
              id: LocalizationKeys.Components.CustomInput.Select.Footer.Artist
                .Spotify,
            })}
          </Radio.Button>
          <Radio.Button value={1}>
            {intl.formatMessage({
              id: LocalizationKeys.Components.CustomInput.Select.Footer.Artist
                .Manual,
            })}
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
      {viaSpotify && (
        <Form.Item
          name="spotify_id"
          label={intl.formatMessage({
            id: LocalizationKeys.Components.CustomInput.Select.Footer.Artist
              .SpotifyUrl,
          })}
          rules={[requiredRule(intl), stringRule(intl)]}
        >
          <Input />
        </Form.Item>
      )}
      {!viaSpotify && (
        <Form.Item
          name="name"
          label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Name })}
          rules={[requiredRule(intl), stringRule(intl)]}
        >
          <Input />
        </Form.Item>
      )}
    </Form>
  )
}

export default Create

import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIUpdate,
} from '../utils/helpers/SBAPIHelper'
import { PerformanceType } from '../models/Performance'
import { PERFORMANCE_TYPES_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { performanceTypeSchema } from '../models/schema'

const initialState: SBState<PerformanceType> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: {
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },
}

const slice = createSlice({
  name: 'performanceType',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getPerformanceTypesSuccess,
  setQuery: setPerformanceTypeQuery,
  reset: resetPerformanceTypeState,
  resetQueryAndIds: resetPerformanceTypeQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: PerformanceType }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectPerformanceTypes = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedPerformanceType = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectPerformanceTypeById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectPerformanceTypesByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedPerformanceType =
  (performanceType: PerformanceType | number) =>
  async (dispatch: AppDispatch) =>
    dispatch(
      slice.actions.setSelectedId(
        getIdOrModelId<PerformanceType>(performanceType)
      )
    )

export const getPerformanceTypes = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<PerformanceType>(
    PERFORMANCE_TYPES_URL,
    params,
    [performanceTypeSchema],
    slice.actions
  )

export const createPerformanceType = (performanceType: PerformanceType) =>
  SBAPICreate<PerformanceType>(
    performanceType,
    PERFORMANCE_TYPES_URL,
    performanceTypeSchema,
    slice.actions
  )

export const updatePerformanceType = (performanceType: PerformanceType) =>
  SBAPIUpdate<PerformanceType>(
    performanceType,
    `${PERFORMANCE_TYPES_URL}/${performanceType.id}`,
    slice.actions
  )

export const deletePerformanceType = (performanceType: PerformanceType) =>
  SBAPIDelete<PerformanceType>(
    performanceType,
    `${PERFORMANCE_TYPES_URL}/${performanceType.id}`,
    slice.actions
  )

import { Tag } from 'antd'
import { ReportItemType } from '../../models/Report'

interface Props {
  type: ReportItemType
}

const ItemType = ({ type }: Props) => {
  return <Tag color={type.color}>{type.name}</Tag>
}

type ItemTypeType = { ItemType: typeof ItemType }

export { ItemType, type ItemTypeType }

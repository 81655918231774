import { useRef } from 'react'
import { SBRMFormInterface, SBRMType } from './SBRMModel'
import { useAppSelector } from '../../reducers/hooks'
import { SBRMState } from '../../reducers/SBRMReducer'

// Form imports
import VenueTypeForm from './venue-types/VenueTypeForm'
import PublicLinkForm from './publicLink/PublicLinkForm'
import EventStatusForm from './event-status/EventStatusForm'
import InvitationForm from './invitation/InvitationForm'
import RiderStatusForm from './rider-statuses/RiderStatusForm'
import ReportItemForm from './report-item/ReportItemForm'
import ReportForm from './report/ReportForm'
import ExternalAdvancingLinkForm from './external-advancing-link/ExternalAdvancingLinkForm'
import { Artist } from '../../components/artist/Artist'
import { Booking } from '../../components/booking/Booking'
import { Contact } from '../../components/contact/Contact'
import { ContactType } from '../../components/contact-type/ContactType'
import { ContactAssociation } from '../../components/contact-association/ContactAssociation'
import { Contract } from '../../components/contract/Contract'
import { ContractStatus } from '../../components/contract-status/ContractStatus'
import { Event } from '../../components/event/Event'
import { File } from '../../components/file/File'
import { Invoice } from '../../components/invoice/Invoice'
import { Task } from '../../components/task/Task'
import { Payment } from '../../components/payment/Payment'
import { Venue } from '../../components/venue/Venue'
import { Ground } from '../../components/ground/Ground'
import { Hotel } from '../../components/hotel/Hotel'
import { Travel } from '../../components/travel/Travel'
import { Stage } from '../../components/stage/Stage'
import { Supplier } from '../../components/supplier/Supplier'
import { Station } from '../../components/station/Station'
import { Performance } from '../../components/performance/Performance'
import { PerformanceType } from '../../components/performance-type/PerformanceType'
import { User } from '../../components/user/User'
import { SupplierType } from '../../components/supplier-type/SupplierType'
import { Room } from '../../components/room/Room'
import { Rider } from '../../components/rider/Rider'
import { InvoiceCategory } from '../../components/invoice-category/InvoiceCategory'
import { Role } from '../../components/role/Role'
import BookingTypeForm from './booking-types/BookingTypeForm'
import MarketingTaskStatusForm from './marketing-task-statuses/MarketingTaskStatusForm'
import { MarketingTask } from '../../components/marketing-task/MarketingTask'
import RiderTypeForm from './rider-types/RiderTypeForm'

type useSBRMformsProps = {
  isNested: boolean
}

const useSBRMforms = ({
  isNested,
}: useSBRMformsProps): {
  refs: Record<SBRMType, React.RefObject<SBRMFormInterface>>
  forms: Record<SBRMType, JSX.Element>
} => {
  const SBRMrefs = {
    [SBRMType.artist]: useRef<SBRMFormInterface>(null),
    [SBRMType.venue]: useRef<SBRMFormInterface>(null),
    [SBRMType.venueType]: useRef<SBRMFormInterface>(null),
    [SBRMType.stage]: useRef<SBRMFormInterface>(null),
    [SBRMType.event]: useRef<SBRMFormInterface>(null),
    [SBRMType.eventStatus]: useRef<SBRMFormInterface>(null),
    [SBRMType.booking]: useRef<SBRMFormInterface>(null),
    [SBRMType.bookingType]: useRef<SBRMFormInterface>(null),
    [SBRMType.contract]: useRef<SBRMFormInterface>(null),
    [SBRMType.contractStatus]: useRef<SBRMFormInterface>(null),
    [SBRMType.invoice]: useRef<SBRMFormInterface>(null),
    [SBRMType.payment]: useRef<SBRMFormInterface>(null),
    [SBRMType.hotel]: useRef<SBRMFormInterface>(null),
    [SBRMType.room]: useRef<SBRMFormInterface>(null),
    [SBRMType.rider]: useRef<SBRMFormInterface>(null),
    [SBRMType.riderStatus]: useRef<SBRMFormInterface>(null),
    [SBRMType.riderType]: useRef<SBRMFormInterface>(null),
    [SBRMType.file]: useRef<SBRMFormInterface>(null),
    [SBRMType.user]: useRef<SBRMFormInterface>(null),
    [SBRMType.contact]: useRef<SBRMFormInterface>(null),
    [SBRMType.contactType]: useRef<SBRMFormInterface>(null),
    [SBRMType.contactAssociation]: useRef<SBRMFormInterface>(null),
    [SBRMType.travel]: useRef<SBRMFormInterface>(null),
    [SBRMType.travelType]: useRef<SBRMFormInterface>(null),
    [SBRMType.ground]: useRef<SBRMFormInterface>(null),
    [SBRMType.performance]: useRef<SBRMFormInterface>(null),
    [SBRMType.performanceType]: useRef<SBRMFormInterface>(null),
    [SBRMType.station]: useRef<SBRMFormInterface>(null),
    [SBRMType.stationType]: useRef<SBRMFormInterface>(null),
    [SBRMType.publicLink]: useRef<SBRMFormInterface>(null),
    [SBRMType.role]: useRef<SBRMFormInterface>(null),
    [SBRMType.customAddress]: useRef<SBRMFormInterface>(null),
    [SBRMType.supplier]: useRef<SBRMFormInterface>(null),
    [SBRMType.supplierType]: useRef<SBRMFormInterface>(null),
    [SBRMType.invoiceCategory]: useRef<SBRMFormInterface>(null),
    [SBRMType.invitation]: useRef<SBRMFormInterface>(null),
    [SBRMType.notification]: useRef<SBRMFormInterface>(null),
    [SBRMType.report]: useRef<SBRMFormInterface>(null),
    [SBRMType.reportItem]: useRef<SBRMFormInterface>(null),
    [SBRMType.task]: useRef<SBRMFormInterface>(null),
    [SBRMType.taskStatus]: useRef<SBRMFormInterface>(null),
    [SBRMType.externalAdvancingLink]: useRef<SBRMFormInterface>(null),
    [SBRMType.currency]: useRef<SBRMFormInterface>(null),
    [SBRMType.marketingTask]: useRef<SBRMFormInterface>(null),
    [SBRMType.marketingTaskStatus]: useRef<SBRMFormInterface>(null),
  }

  const SBRMforms = {
    [SBRMType.artist]: (
      <Artist.Form ref={SBRMrefs[SBRMType.artist]} isNested={isNested} />
    ),
    [SBRMType.venue]: (
      <Venue.Form ref={SBRMrefs[SBRMType.venue]} isNested={isNested} />
    ),
    [SBRMType.venueType]: (
      <VenueTypeForm ref={SBRMrefs[SBRMType.venueType]} isNested={isNested} />
    ),
    [SBRMType.stage]: (
      <Stage.Form ref={SBRMrefs[SBRMType.stage]} isNested={isNested} />
    ),
    [SBRMType.event]: (
      <Event.Form ref={SBRMrefs[SBRMType.event]} isNested={isNested} />
    ),
    [SBRMType.eventStatus]: (
      <EventStatusForm
        ref={SBRMrefs[SBRMType.eventStatus]}
        isNested={isNested}
      />
    ),
    [SBRMType.booking]: (
      <Booking.Form ref={SBRMrefs[SBRMType.booking]} isNested={isNested} />
    ),
    [SBRMType.bookingType]: (
      <BookingTypeForm
        ref={SBRMrefs[SBRMType.bookingType]}
        isNested={isNested}
      />
    ),
    [SBRMType.hotel]: (
      <Hotel.Form ref={SBRMrefs[SBRMType.hotel]} isNested={isNested} />
    ),
    [SBRMType.room]: (
      <Room.Form ref={SBRMrefs[SBRMType.room]} isNested={isNested} />
    ),
    [SBRMType.contact]: (
      <Contact.Form ref={SBRMrefs[SBRMType.contact]} isNested={isNested} />
    ),
    [SBRMType.contactType]: (
      <ContactType.Form
        ref={SBRMrefs[SBRMType.contactType]}
        isNested={isNested}
      />
    ),
    [SBRMType.contactAssociation]: (
      <ContactAssociation.Form
        ref={SBRMrefs[SBRMType.contactAssociation]}
        isNested={isNested}
      />
    ),
    [SBRMType.contract]: (
      <Contract.Form ref={SBRMrefs[SBRMType.contract]} isNested={isNested} />
    ),
    [SBRMType.contractStatus]: (
      <ContractStatus.Form
        ref={SBRMrefs[SBRMType.contractStatus]}
        isNested={isNested}
      />
    ),
    [SBRMType.invoice]: (
      <Invoice.Form ref={SBRMrefs[SBRMType.invoice]} isNested={isNested} />
    ),
    [SBRMType.invoiceCategory]: (
      <InvoiceCategory.Form
        ref={SBRMrefs[SBRMType.invoiceCategory]}
        isNested={isNested}
      />
    ),
    [SBRMType.payment]: (
      <Payment.Form ref={SBRMrefs[SBRMType.payment]} isNested={isNested} />
    ),
    [SBRMType.rider]: (
      <Rider.Form ref={SBRMrefs[SBRMType.rider]} isNested={isNested} />
    ),
    [SBRMType.riderStatus]: (
      <RiderStatusForm
        ref={SBRMrefs[SBRMType.riderStatus]}
        isNested={isNested}
      />
    ),
    [SBRMType.riderType]: (
      <RiderTypeForm ref={SBRMrefs[SBRMType.riderType]} isNested={isNested} />
    ),
    [SBRMType.file]: (
      <File.Form ref={SBRMrefs[SBRMType.file]} isNested={isNested} />
    ),
    [SBRMType.user]: (
      <User.Form ref={SBRMrefs[SBRMType.user]} isNested={isNested} />
    ),
    [SBRMType.travel]: (
      <Travel.Form ref={SBRMrefs[SBRMType.travel]} isNested={isNested} />
    ),
    [SBRMType.travelType]: <></>,
    [SBRMType.ground]: (
      <Ground.Form ref={SBRMrefs[SBRMType.ground]} isNested={isNested} />
    ),
    [SBRMType.performance]: (
      <Performance.Form
        ref={SBRMrefs[SBRMType.performance]}
        isNested={isNested}
      />
    ),
    [SBRMType.performanceType]: (
      <PerformanceType.Form
        ref={SBRMrefs[SBRMType.performanceType]}
        isNested={isNested}
      />
    ),
    [SBRMType.station]: (
      <Station.Form ref={SBRMrefs[SBRMType.station]} isNested={isNested} />
    ),
    [SBRMType.stationType]: <></>,
    [SBRMType.publicLink]: (
      <PublicLinkForm ref={SBRMrefs[SBRMType.publicLink]} isNested={isNested} />
    ),
    [SBRMType.role]: (
      <Role.Form ref={SBRMrefs[SBRMType.role]} isNested={isNested} />
    ),
    [SBRMType.customAddress]: <></>,
    [SBRMType.supplier]: (
      <Supplier.Form ref={SBRMrefs[SBRMType.supplier]} isNested={isNested} />
    ),
    [SBRMType.supplierType]: (
      <SupplierType.Form
        ref={SBRMrefs[SBRMType.supplierType]}
        isNested={isNested}
      />
    ),
    [SBRMType.invitation]: (
      <InvitationForm ref={SBRMrefs[SBRMType.invitation]} isNested={isNested} />
    ),
    [SBRMType.notification]: <></>,
    [SBRMType.report]: (
      <ReportForm ref={SBRMrefs[SBRMType.report]} isNested={isNested} />
    ),
    [SBRMType.reportItem]: (
      <ReportItemForm ref={SBRMrefs[SBRMType.reportItem]} isNested={isNested} />
    ),
    [SBRMType.task]: (
      <Task.Form ref={SBRMrefs[SBRMType.task]} isNested={isNested} />
    ),
    [SBRMType.taskStatus]: <></>,
    [SBRMType.externalAdvancingLink]: (
      <ExternalAdvancingLinkForm
        ref={SBRMrefs[SBRMType.externalAdvancingLink]}
        isNested={isNested}
      />
    ),
    [SBRMType.currency]: <></>,
    [SBRMType.marketingTask]: (
      <MarketingTask.Form
        ref={SBRMrefs[SBRMType.marketingTask]}
        isNested={isNested}
      />
    ),
    [SBRMType.marketingTaskStatus]: (
      <MarketingTaskStatusForm
        ref={SBRMrefs[SBRMType.marketingTaskStatus]}
        isNested={isNested}
      />
    ),
  }
  return { refs: SBRMrefs, forms: SBRMforms }
}

const useSBRMData = (isNested: boolean): SBRMState => {
  const {
    isOpen: SBRMisOpen,
    entity: SBRMentity,
    action: SBRMaction,
    metadata: SBRMmetadata,
  } = useAppSelector((state) => state.SBRM)
  const {
    isOpen: SBRMnestedIsOpen,
    entity: SBRMnestedEntity,
    action: SBRMnestedAction,
    metadata: SBRMnestedMetadata,
  } = useAppSelector((state) => state.SBRM.nested)

  return {
    isOpen: isNested ? SBRMnestedIsOpen : SBRMisOpen,
    entity: isNested ? SBRMnestedEntity : SBRMentity,
    action: isNested ? SBRMnestedAction : SBRMaction,
    metadata: SBRMmetadata,
  }
}

export { useSBRMforms, useSBRMData }

import { commonStyle } from './style'
import { Hotel } from '../../../models/Hotel'
import { View, Text } from '@react-pdf/renderer'
import { addressFormatter } from '../../../utils/formatters'

interface Props {
  hotel: Hotel
}

const HotelAvatar = ({ hotel }: Props) => {
  return (
    <View>
      <Text style={commonStyle.p}>{hotel.name}</Text>
      <Text style={commonStyle.muted}>{addressFormatter(hotel.address)}</Text>
    </View>
  )
}
export default HotelAvatar

import { useIntl } from 'react-intl'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { Organization } from '../../components/organization/Organization'
import { Actions } from '../../modules/sbrm/components/Actions'
import { SBRMType } from '../../modules/sbrm/SBRMModel'

const OrganizationRolesPage = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle
        title={intl.formatMessage({
          id: LocalizationKeys.Page.Organization.Roles,
        })}
        toolbar={<Actions actions={['create']} entity={SBRMType.role} />}
      />
      <Organization.Roles />
    </>
  )
}

export default OrganizationRolesPage

import { Tag, Tooltip, Typography } from 'antd'
import { Invoice } from '../../models/Invoice'
import { useAppSelector } from '../../reducers/hooks'
import { selectInvoiceById } from '../../reducers/InvoiceReducer'

const { Text } = Typography

interface Props {
  id?: number
  model?: Invoice
}

export const ReferenceTag = ({ id, model }: Props) => {
  const invoice = model ? model : useAppSelector(selectInvoiceById(id ?? 0))
  const start =
    invoice?.reference &&
    invoice?.reference.slice(0, invoice?.reference.length - 5).trim()

  const suffix = invoice?.reference && invoice?.reference.slice(-5).trim()

  return invoice && invoice.reference ? (
    <Tooltip title={invoice.reference}>
      <Tag>
        <Text style={{ maxWidth: 100, fontSize: 12 }} ellipsis={{ suffix }}>
          {start}
        </Text>
      </Tag>
    </Tooltip>
  ) : (
    <></>
  )
}

export type ReferenceTagType = { ReferenceTag: typeof ReferenceTag }

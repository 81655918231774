import { Typography } from 'antd'
import { useAppSelector } from '../../reducers/hooks'
import { Travel } from '@supplement-bacon/alela-uikit'
import { STATION_TYPE_AIRPORT, StationType } from '../../models/Station'
import { selectStationTypeById } from '../../reducers/StationTypeReducer'

interface Props {
  id?: number
  model?: StationType
}

export const Type = ({ id, model }: Props) => {
  const type = model ? model : useAppSelector(selectStationTypeById(id ?? 0))
  return type ? (
    <>
      {type.id === STATION_TYPE_AIRPORT ? (
        <Travel.SVG.FlightOutbound />
      ) : (
        <Travel.SVG.TrainInbound />
      )}
      <Typography.Text style={{ marginLeft: 10 }}>{type.name}</Typography.Text>
    </>
  ) : (
    <></>
  )
}

export type TypeType = { Type: typeof Type }

import { Card, Space } from 'antd'
import { useIntl } from 'react-intl'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { Preference } from '../../components/preference/Preference'

const PreferencesPage = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle
        title={intl.formatMessage({
          id: LocalizationKeys.Page.Preference.Title,
        })}
      />
      <Space direction="vertical" style={{ width: '100%' }}>
        <Card
          title={intl.formatMessage({
            id: LocalizationKeys.Page.Preference.Locale,
          })}
        >
          <Preference.Locale />
        </Card>
      </Space>
    </>
  )
}

export default PreferencesPage

import { Address } from '../models/Address'
import { Currency } from '../models/Tenant'

export function roundToTwo(num: number) {
  return +(Math.round((num + 'e+2') as unknown as number) + 'e-2')
}

export function wordFormat(word: string) {
  return !word ? word : word[0].toUpperCase() + word.substring(1).toLowerCase()
}

export const fieldFormatter = (value: number | undefined) => {
  return `${value}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const addressLine1 = (address: Address) =>
  address.street_number && address.street
    ? `${address.street_number} ${address.street}`
    : ''

const addressLine2 = (address: Address) =>
  address.post_code && address.city
    ? `${address.post_code} ${address.city}`
    : ''

export const addressFormatter = (
  address: Address,
  format: 'full' | 'line1' | 'line2' = 'full'
) => {
  switch (format) {
    case 'full':
      let result = addressLine1(address) ? `${addressLine1(address)}, ` : ''
      result += addressLine2(address) ? `${addressLine2(address)}` : ''
      result += address.country ? `, ${address.country}` : ''
      return result
    case 'line1':
      return addressLine1(address) ? addressLine1(address) : ''
    case 'line2':
      return addressLine2(address) ? addressLine2(address) : ''

    default:
      break
  }
}

import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIUpdate,
} from '../utils/helpers/SBAPIHelper'
import { TravelType } from '../models/Travel'
import { TRAVEL_TYPE_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { travelTypeSchema } from '../models/schema'
import { initialQuery } from '../utils/helpers/crud/models'

const initialState: SBState<TravelType> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: initialQuery,
}

const slice = createSlice({
  name: 'travelType',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getTravelTypesSuccess,
  setQuery: setTravelTypeQuery,
  reset: resetTravelTypeState,
  resetQueryAndIds: resetTravelTypeQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: TravelType }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectTravelTypes = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedTravelType = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectTravelTypeById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectTravelTypesByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedTravelType =
  (travelType: TravelType | number | undefined) =>
  async (dispatch: AppDispatch) =>
    dispatch(
      slice.actions.setSelectedId(
        travelType ? getIdOrModelId<TravelType>(travelType) : undefined
      )
    )

export const getTravelTypes = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<TravelType>(
    TRAVEL_TYPE_URL,
    params,
    [travelTypeSchema],
    slice.actions
  )

export const getTravelTypeWithId = (id: number) =>
  SBAPIFetchDispatch<TravelType>(
    `${TRAVEL_TYPE_URL}/${id}`,
    travelTypeSchema,
    slice.actions
  )

export const createTravelType = (travelType: TravelType) =>
  SBAPICreate<TravelType>(
    travelType,
    TRAVEL_TYPE_URL,
    travelTypeSchema,
    slice.actions
  )

export const updateTravelType = (travelType: TravelType) =>
  SBAPIUpdate<TravelType>(
    travelType,
    `${TRAVEL_TYPE_URL}/${travelType.id}`,
    slice.actions
  )

export const deleteTravelType = (travelType: TravelType) =>
  SBAPIDelete<TravelType>(
    travelType,
    `${TRAVEL_TYPE_URL}/${travelType.id}`,
    slice.actions
  )

import { Col, Layout, Row, Space, Spin } from 'antd'

const { Content } = Layout

export const LoadingFullScreen: React.FC = () => {
  return (
    <Layout>
      <Content>
        <Row align="middle" style={{ minHeight: '100vh', margin: 0 }}>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Space direction="vertical">
              <Spin />
            </Space>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

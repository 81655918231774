import {
  getReports,
  selectReportById,
  setReportQuery,
} from '../../../reducers/ReportReducer'
import { Button, Col, Row, Space, Spin } from 'antd'
import { useIntl } from 'react-intl'
import HasAccess from '../../../components/HasAccess'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { AlelaPermission } from '../../../utils/permissions'
import { selectSelectedEvent } from '../../../reducers/EventReducer'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import { Reporting } from '../../../components/reporting/Reporting'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { useEffect, useState } from 'react'
import { TableParams } from '../../../models/TableParams'
import { initialQuery } from '../../../utils/helpers/crud/models'
import { Actions } from '../../../modules/sbrm/components/Actions'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import { EditOutlined } from '@ant-design/icons'
import { reportToUpdateReport } from '../../../components/reporting/wizard/utils'

const ReportingPage = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const event = useAppSelector(selectSelectedEvent())
  const { isLoading } = useAppSelector((state) => state.report)

  const [reportId, setReportId] = useState<number>(0)
  const [action, setAction] = useState<'view' | 'update'>('view')
  const report = useAppSelector(selectReportById(reportId))

  const fetchReport = () => {
    if (!event) return
    setAction('view')

    const baseQuery: TableParams = {
      ...initialQuery,
      filters: { events: [event.id] },
    }

    dispatch(setReportQuery(baseQuery))
    dispatch(getReports(baseQuery)).then((response) => {
      const reports = response.data
      if (reports.length && reports.length > 0) {
        setReportId(reports[0].id)
      }
    })
  }

  useEffect(() => {
    fetchReport()
  }, [])

  if (!event) return <Spin />

  return (
    <Row className="container-row">
      <Col span={24}>
        <HasAccess permissions={[AlelaPermission.listReport]}>
          <PageTitle
            level={5}
            title={intl.formatMessage({
              id: LocalizationKeys.Page.EventDetails.Reporting.Title,
            })}
            toolbar={
              report && (
                <Space>
                  {isLoading && <Spin />}
                  <HasAccess permissions={[AlelaPermission.editReport]}>
                    <Button
                      icon={action === 'view' && <EditOutlined />}
                      onClick={() =>
                        setAction(action === 'view' ? 'update' : 'view')
                      }
                    >
                      {action === 'update' &&
                        intl.formatMessage({
                          id: LocalizationKeys.Misc.Form.CancelModifications,
                        })}
                    </Button>
                  </HasAccess>
                  <Actions
                    entity={SBRMType.report}
                    entityId={report.id}
                    actions={['delete']}
                  />
                </Space>
              )
            }
          />

          {!isLoading && report === undefined && action === 'view' && (
            <Reporting.Wizard event={event} onReportCreated={fetchReport} />
          )}
          {!isLoading && report && action === 'view' && (
            <Reporting.Overview reports={[report]} />
          )}
          {!isLoading && report && action === 'update' && (
            <Reporting.Wizard
              event={event}
              onReportCreated={fetchReport}
              initialValue={reportToUpdateReport(report, event.id)}
            />
          )}
        </HasAccess>
      </Col>
    </Row>
  )
}

export default ReportingPage

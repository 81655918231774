import { FC } from 'react'
import { ContractForm, ContractFormType } from './form/Form'
import { ExternalRequests, ExternalRequestsType } from './ExternalRequests'

export const Contract: FC &
  ContractFormType &
  ExternalRequestsType = (): JSX.Element => <></>

Contract.Form = ContractForm
Contract.ExternalRequests = ExternalRequests

import {
  CommentOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
} from '@ant-design/icons'
import { File } from '../../../models/File'
import { useEffect, useState } from 'react'
import { Button, Divider, Segmented, Space } from 'antd'
import { getFile } from '../helpers/FileHelper'
import { SBRMAction, SBRMType } from '../../sbrm/SBRMModel'
import { SBRMTypeInfos } from '../../sbrm/SBRMTypeInfos'
import { Link } from 'react-router-dom'
import { setMainAction } from '../../../reducers/SBRMReducer'
import { File as FileComponent } from '../../../components/file/File'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import { useHasAccess } from '../../../components/HasAccess'
import { AlelaPermission } from '../../../utils/permissions'

interface Props {
  entity: SBRMType
  handleDownloadCliked?: () => void
  handleShareCliked?: () => void
}

const Toolbar = ({
  entity,
  handleDownloadCliked,
  handleShareCliked,
}: Props) => {
  const dispatch = useAppDispatch()
  const { hasAccess } = useHasAccess()
  const [resourceFile, setResourceFile] = useState<File | undefined>(undefined)

  // Get selected resource based on the entity
  const resourceId = useAppSelector(
    (state) => state[SBRMTypeInfos[entity].reducerName].selectedId
  )
  const resource = useAppSelector(
    (state) => state[SBRMTypeInfos[entity].reducerName].items[resourceId!]
  )
  const { items: files } = useAppSelector((state) => state.file)
  const { isOpen: SBRMisOpen, action } = useAppSelector((state) => state.SBRM)

  useEffect(() => {
    if (!SBRMisOpen || !resource) return // When closing SBRM do not set the resource
    setResourceFile(getFile(entity, resource, files))
  }, [SBRMisOpen, resource])

  useEffect(() => {
    /**
     * Reset the selected resource when the Drawer is closed
     */
    if (!SBRMisOpen) {
      setResourceFile(undefined)
    }
  }, [SBRMisOpen])

  if (resourceFile === undefined) {
    return <></>
  }

  return (
    <Space>
      {/* Share */}
      <FileComponent.Share id={resourceFile.id} />

      {/* Download */}
      <Link to={resourceFile.downloadUrl}>
        <Button icon={<DownloadOutlined />} />
      </Link>

      {/* Action-switch */}
      <Divider type="vertical" />
      <Segmented
        value={action}
        size="middle"
        options={[
          ...(hasAccess([('view ' + entity) as AlelaPermission])
            ? [{ value: 'view', label: <EyeOutlined /> }]
            : []),
          ...(hasAccess([('edit ' + entity) as AlelaPermission])
            ? [{ value: 'update', label: <EditOutlined /> }]
            : []),
          ...(hasAccess([('comment ' + entity) as AlelaPermission])
            ? [{ value: 'comment', label: <CommentOutlined /> }]
            : []),
        ]}
        onChange={(value) =>
          dispatch(
            setMainAction({
              action: value as SBRMAction,
            })
          )
        }
      />
    </Space>
  )
}

export default Toolbar

import { Card } from 'antd'
import { useState } from 'react'
import { File } from '../file/File'
import { EVENTS_URL } from '../../utils/urls'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { getEventWithId, selectEventById } from '../../reducers/EventReducer'
import { PictureOutlined } from '@ant-design/icons'
import HasAccess, { useHasAccess } from '../HasAccess'
import { AlelaPermission } from '../../utils/permissions'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'

interface Props {
  id: number | undefined
}

export const EventCover = ({ id }: Props) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const { hasAccess } = useHasAccess()

  const event = useAppSelector(selectEventById(id ?? 0))

  const [shouldDisplayDropZone, setShouldDisplayDropZone] =
    useState<boolean>(false)
  const [keepDropZoneAlive, setKeepDropZoneAlive] = useState<boolean>(false)

  const showDropZone = function (e: any) {
    e.preventDefault()
    e.stopPropagation()
    setShouldDisplayDropZone(true)
  }

  const hideDropZone = function (e: any) {
    e.preventDefault()
    e.stopPropagation()
    setShouldDisplayDropZone(false)
  }

  return event ? (
    <div
      className="hide-mobile"
      style={{ position: 'relative' }}
      onDragOver={
        hasAccess([AlelaPermission.editEvent]) ? showDropZone : () => {}
      }
      onDragLeave={
        hasAccess([AlelaPermission.editEvent]) ? hideDropZone : () => {}
      }
      onMouseOver={
        hasAccess([AlelaPermission.editEvent]) ? showDropZone : () => {}
      }
      onMouseOut={
        hasAccess([AlelaPermission.editEvent]) ? hideDropZone : () => {}
      }
      onClick={() =>
        hasAccess([AlelaPermission.editEvent]) ? setKeepDropZoneAlive(true) : {}
      }
    >
      <div
        style={{
          width: '100%',
          height: shouldDisplayDropZone || keepDropZoneAlive ? 'unset' : 156,
          backgroundImage: `url(${
            event && event.header_picture ? event!.header_picture : ''
          })`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          imageRendering: 'crisp-edges',
        }}
      >
        <HasAccess permissions={[AlelaPermission.editEvent]}>
          {(shouldDisplayDropZone ||
            keepDropZoneAlive ||
            !event.header_picture) && (
            <Card
              style={{ borderRadius: 0 }}
              styles={{ body: { padding: 0 } }}
              bordered={false}
            >
              <File.Upload
                icon={<PictureOutlined />}
                title={intl.formatMessage({
                  id: LocalizationKeys.Components.Event.Cover,
                })}
                action={`${EVENTS_URL}/${event.id}/picture`}
                accept={'image/*'}
                onSuccessUpload={() => {
                  setKeepDropZoneAlive(false)
                  dispatch(getEventWithId(event.id))
                }}
              />
            </Card>
          )}
        </HasAccess>
      </div>
    </div>
  ) : (
    <></>
  )
}

export type EventCoverType = { Cover: typeof EventCover }

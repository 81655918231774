import { FC } from 'react'
import React from 'react'
import { MarketingTaskForm, MarketingTaskFormType } from './form/Form'
import { MarketingKanbanCard, MarketingKanbanCardType } from './KanbanCard'

export const MarketingTask: FC &
  MarketingTaskFormType &
  MarketingKanbanCardType = (): JSX.Element => <></>

MarketingTask.Form = MarketingTaskForm
MarketingTask.KanbanCard = MarketingKanbanCard

import { Venue as VenueModel } from '../../models/Venue'
import { Tag } from 'antd'
import { ConditionalLinkWrapper } from '@supplement-bacon/alela-uikit'
import { useAppSelector } from '../../reducers/hooks'
import { selectVenueById } from '../../reducers/VenueReducer'

interface Props {
  id?: number
  model?: VenueModel
  linkToDetails?: boolean
}

export const VenueTag = ({ id, model, linkToDetails = true }: Props) => {
  const venue = model ? model : useAppSelector(selectVenueById(id ?? 0))
  return venue ? (
    <ConditionalLinkWrapper
      condition={linkToDetails}
      to={`/settings/venues/${venue.id}`}
    >
      <Tag>{venue.name}</Tag>
    </ConditionalLinkWrapper>
  ) : (
    <></>
  )
}

export type VenueTagType = { Tag: typeof VenueTag }

import { User } from './User'
import { SBModel } from '../utils/helpers/crud/models'

interface CommonComment {
  type: 'text' | 'GIF'
  body: string
  date: string
}

interface Comment extends SBModel, CommonComment {
  user?: number
  mentionedUsers?: User[]
}

interface ExternalComment extends SBModel, CommonComment {
  internalUser?: number
  externalUser?: number
  mentionedUsers?: User[]
}

const isInternalComment = (
  comment: Comment | ExternalComment
): comment is Comment => 'user' in comment

const isExternalComment = (
  comment: Comment | ExternalComment
): comment is ExternalComment =>
  ('internalUser' in comment && comment.internalUser !== undefined) ||
  ('externalUser' in comment && comment.externalUser !== undefined)

export {
  type CommonComment,
  type Comment,
  type ExternalComment,
  isInternalComment,
  isExternalComment,
}

import { Tag, Typography } from 'antd'
import { useAppSelector } from '../../reducers/hooks'

import { selectVenueTypeById } from '../../reducers/VenueTypeReducer'
import { VenueType } from '../../models/VenueType'

const { Text } = Typography
interface Props {
  id?: number
  model?: VenueType
}
export const VenueTypeTag = ({ id, model }: Props) => {
  const type = model ? model : useAppSelector(selectVenueTypeById(id!))
  return (
    <>
      {type && (
        <Tag color={type.color} key={type.id}>
          {type.name}
        </Tag>
      )}
    </>
  )
}

export type VenueTypeTagType = { TypeTag: typeof VenueTypeTag }

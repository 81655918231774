import { useEffect } from 'react'
import { Col, Row, Space, Spin } from 'antd'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import HasAccess from '../../components/HasAccess'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { AlelaPermission } from '../../utils/permissions'
import { Invoice } from '../../components/invoice/Invoice'
import { initialQuery } from '../../utils/helpers/crud/models'
import { Actions } from '../../modules/sbrm/components/Actions'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import {
  getInvoices,
  resetInvoiceQueryAndIds,
  setInvoiceQuery,
} from '../../reducers/InvoiceReducer'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { HelpArticle } from '../../utils/hooks/useAlelaHelp'
import { Help } from '../../components/help/Help'

const InvoicesPage = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const { isLoading } = useAppSelector((state) => state.invoice)

  useEffect(() => {
    dispatch(resetInvoiceQueryAndIds(initialQuery))
  }, [])

  return (
    <HasAccess permissions={[AlelaPermission.listInvoice]}>
      <PageTitle
        title={intl.formatMessage({
          id: LocalizationKeys.Page.Financials.Invoices.Title,
        })}
        toolbar={
          <Space direction="horizontal">
            {isLoading && <Spin />}
            <Actions actions={['create']} entity={SBRMType.invoice} />
            <Help.Button
              article={[
                HelpArticle.HowToAddAnInvoice,
                HelpArticle.HowToMarkAnInvoiceAsPaid,
              ]}
            />
          </Space>
        }
      />
      <Invoice.Filters
        queryChanged={(query) => {
          dispatch(setInvoiceQuery(query))
          dispatch(getInvoices(query))
        }}
      />
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Invoice.Table displayEvent displaySupplier actionsInDropdown />
        </Col>
      </Row>
    </HasAccess>
  )
}

export default InvoicesPage

import { TablePaginationConfig } from 'antd/es/table'
import { Event } from '../../models/Event'
import { selectEventById } from '../../reducers/EventReducer'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import {
  Avatar,
  Card,
  Col,
  List,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { useEffect, useState } from 'react'
import { TableParams } from '../../models/TableParams'
import { initialQuery } from '../../utils/helpers/crud/models'
import { extractSortDataFromSorterResult } from '../../utils/table/sorter'
import { useIntl } from 'react-intl'
import { getTasks, selectTasks, setTaskQuery } from '../../reducers/TaskReducer'
import { Task as TaskModel } from '../../models/Task'
import { TaskStatus } from '../task-status/TaskStatus'
import { User } from '../user/User'
import { UserOutlined } from '@ant-design/icons'
import { Actions } from '../../modules/sbrm/components/Actions'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import dayjs from 'dayjs'

const { Text } = Typography
interface Props {
  eventId?: number
  eventModel?: Event
}

export const ListForEvent = ({ eventId, eventModel }: Props) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const event = eventModel
    ? eventModel
    : useAppSelector(selectEventById(eventId ?? 0))

  const { query, isLoading } = useAppSelector((state) => state.task)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const tasks = useAppSelector(selectTasks())

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<TaskModel> | SorterResult<TaskModel>[]
  ) => {
    const newQuery = {
      ...query,
      pagination,
      filters: { ...query.filters, ...filters },
      ...extractSortDataFromSorterResult<TaskModel>(sorter),
    }
    dispatch(setTaskQuery(newQuery))
    dispatch(getTasks(newQuery))
  }

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      return
    }
    setIsFirstRender(false)

    const baseQuery: TableParams = {
      ...initialQuery,
      filters: { event: [event?.id!] },
      columnKey: 'status',
      order: 'ascend',
    }

    dispatch(setTaskQuery(baseQuery))
    dispatch(getTasks(baseQuery))
  }, [SBRMIsOpen])

  return (
    <List
      itemLayout="horizontal"
      dataSource={tasks}
      renderItem={(item: TaskModel, index) => (
        <Card
          bordered={false}
          style={{ marginBottom: 10 }}
          styles={{ body: { padding: 12, paddingLeft: 24 } }}
        >
          <Row>
            <Col span={5} style={{ alignItems: 'center', display: 'flex' }}>
              <Text>{item.title}</Text>
            </Col>
            <Col
              span={4}
              style={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <TaskStatus.Tag id={item.status} />
            </Col>
            <Col span={8} style={{ alignItems: 'center', display: 'flex' }}>
              <Text>{item.description} </Text>
            </Col>
            <Col
              span={3}
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {item.dueDate && (
                <Tag>{dayjs(item.dueDate).format('YYYY-MM-DD')}</Tag>
              )}
            </Col>
            <Col
              span={2}
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {item.assignee && (
                <User.Avatar id={item.assignee} type="condensed" />
              )}
            </Col>
            <Col
              span={2}
              style={{
                textAlign: 'right',
                alignItems: 'center',
                justifyContent: 'end',
                display: 'flex',
              }}
            >
              <Actions
                entity={SBRMType.task}
                entityId={item.id}
                actions={['update', 'delete']}
              />
            </Col>
          </Row>
        </Card>
      )}
    />
  )
}

export type ListForEventType = { ListForEvent: typeof ListForEvent }

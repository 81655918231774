import { FC } from 'react'
import { Dropdown, DropdownType } from './Dropdown'
import { Cell, CellType } from './Cell'
import { Listener, ListenerType } from './Listener'
import { Wrapper, WrapperType } from './Wrapper'
import { MarkAllAsRead, MarkAllAsReadType } from './MarkAllAsRead'

export const Notification: FC &
  DropdownType &
  CellType &
  ListenerType &
  WrapperType &
  MarkAllAsReadType = (): JSX.Element => <></>

Notification.Dropdown = Dropdown
Notification.Cell = Cell
Notification.Listener = Listener
Notification.Wrapper = Wrapper
Notification.MarkAllAsRead = MarkAllAsRead

import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import { requiredRule } from '../../../utils/rules'
import { useAppSelector } from '../../../reducers/hooks'
import { selectSelectedVenue } from '../../../reducers/VenueReducer'
import { Checkbox, Col, Form, FormInstance, Input, Row, TimePicker } from 'antd'
import ColorSelect from '../../../components/custom-inputs/select/ColorSelect'
import { SBAsyncSelect } from '../../../components/custom-inputs/sb-async-select/SBAsyncSelect'
import dayjs from 'dayjs'
import { Address } from '../../../components/address/Address'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

const { TextArea } = Input

interface Props {
  form: FormInstance
}

const Update = ({ form }: Props) => {
  const intl = useIntl()
  const timeFormat = 'HH:mm'

  const venue = useAppSelector(selectSelectedVenue())

  useEffect(() => {
    form.setFieldsValue(venue)
    form.setFieldValue(
      ['defaultOpeningRange', 'start'],
      dayjs(venue?.defaultOpeningRange?.start ?? '23:00', timeFormat)
    )
    form.setFieldValue(
      ['defaultOpeningRange', 'end'],
      dayjs(venue?.defaultOpeningRange?.end ?? '05:00', timeFormat)
    )
  }, [venue])

  if (venue === undefined) {
    return <></>
  }

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="id"
        required
        rules={[requiredRule(intl)]}
        style={{ display: 'none' }}
      >
        <Input type="hidden" />
      </Form.Item>

      <Form.Item
        name="name"
        required
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Name,
        })}
        rules={[requiredRule(intl)]}
      >
        <Input />
      </Form.Item>
      <SBAsyncSelect
        type={SBRMType.venueType}
        name="type"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Type,
        })}
        rules={[requiredRule(intl)]}
        size="middle"
      />
      <Address.Form initialValue={venue?.address} />
      <Row gutter={16}>
        <Col md={8}>
          <Form.Item
            name={['defaultOpeningRange', 'start']}
            label={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Opening,
            })}
            rules={[]}
          >
            <TimePicker format={timeFormat} minuteStep={15} />
          </Form.Item>
        </Col>
        <Col md={8}>
          <Form.Item
            name={['defaultOpeningRange', 'end']}
            label={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Closing,
            })}
            rules={[]}
          >
            <TimePicker format={timeFormat} minuteStep={15} />
          </Form.Item>
        </Col>
        <Col md={8}>
          <Form.Item
            name={['defaultOpeningRange', 'endIsNextDay']}
            label={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.ClosingIsOnNextDay,
            })}
            rules={[]}
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <ColorSelect
            name="color"
            label={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.EventColorOnCalendar,
            })}
            rules={[requiredRule(intl)]}
          />
        </Col>
      </Row>
      <Form.Item
        name="description"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Notes,
        })}
        rules={[]}
      >
        <TextArea rows={4} />
      </Form.Item>
    </Form>
  )
}

export default Update

import { Space } from 'antd'
import { Booking } from '../booking/Booking'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { useEffect } from 'react'
import { initialQuery } from '../../utils/helpers/crud/models'
import { TableParams } from '../../models/TableParams'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import BaseExternalRequests from '../BaseExternalRequests'
import {
  deleteGround,
  getNonApprovedGrounds,
  selectAllNotApprovedGrounds,
  updateGround,
} from '../../reducers/GroundReducer'
import { Ground } from './Ground'
import { Contact } from '../contact/Contact'
import { CarOutlined } from '@ant-design/icons'

interface ExternalRequestsProps {
  eventId: number
  shouldRefreshList: () => void
}

const ExternalRequests = ({
  eventId,
  shouldRefreshList,
}: ExternalRequestsProps) => {
  const dispatch = useAppDispatch()
  const grounds = useAppSelector(selectAllNotApprovedGrounds())

  useEffect(() => {
    const baseQuery: TableParams = {
      ...initialQuery,
      filters: { events: [eventId] },
      columnKey: 'time',
      order: 'ascend',
    }
    dispatch(getNonApprovedGrounds(baseQuery))
  }, [])

  if (grounds.length === 0) return <></>

  return (
    <BaseExternalRequests
      entity={SBRMType.ground}
      items={grounds}
      renderItem={(item) => (
        <Space size={10}>
          <a href="#" onClick={(e) => e.stopPropagation()}>
            <Booking.Avatar id={item.booking ?? 0} />
          </a>
          <Ground.Infos id={item.id} />
          <Contact.Avatar
            id={item.driver}
            displayCompany={false}
            tag={item.vehicleDetails && item.vehicleDetails}
            tagIcon={<CarOutlined />}
          />
          <Contact.AvatarGroup ids={item.passengers} />
        </Space>
      )}
      updateMethod={updateGround}
      deleteMethod={deleteGround}
      shouldRefreshList={shouldRefreshList}
    />
  )
}

type ExternalRequestsType = { ExternalRequests: typeof ExternalRequests }

export { ExternalRequests, type ExternalRequestsType }

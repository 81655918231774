import { useContext } from 'react'
import { APIPingContext } from './context'

const useAPIPing = () => {
  return useContext(APIPingContext).pingData
}

interface useAPIPingType {
  useAPIPing: typeof useAPIPing
}

export { useAPIPing, type useAPIPingType }

import { useIntl } from 'react-intl'
import { Alert, Space, Button } from 'antd'
import { ReactNode, useEffect } from 'react'

interface Props {
  content: ReactNode
  buttonIcon: ReactNode
  buttonTitle: string
  buttonLoading: boolean
  onButtonClick: () => void
}

export const WithAction1 = ({
  content,
  buttonIcon,
  buttonTitle,
  buttonLoading,
  onButtonClick,
}: Props) => {
  const intl = useIntl()

  useEffect(() => {}, [])

  return (
    <Alert
      message={<Space style={{ width: '100%', padding: 10 }}>{content}</Space>}
      style={{ backgroundColor: '#1a1223', border: '1px solid #5762bb' }}
      action={
        <Button
          type="dashed"
          icon={buttonIcon}
          loading={buttonLoading}
          onClick={onButtonClick}
        >
          {buttonTitle}
        </Button>
      }
    />
  )
}

export type WithAction1Type = { WithAction1: typeof WithAction1 }

import { Tag } from 'antd'
import { selectSupplierTypeById } from '../../reducers/SupplierTypeReducer'
import { useAppSelector } from '../../reducers/hooks'
import { SupplierType } from '../../models/Supplier'

interface Props {
  id?: number
  model?: SupplierType
}
export const SupplierTypeTag = ({ id, model }: Props) => {
  const type = model ? model : useAppSelector(selectSupplierTypeById(id!))

  return <>{type && <Tag key={type.id}>{type.name}</Tag>}</>
}
export type SupplierTypeTagType = { TypeTag: typeof SupplierTypeTag }

import { Button, Card, Col, InputRef, Row, Tag } from 'antd'
import { useIntl } from 'react-intl'
import { useEffect, useRef, useState } from 'react'
import { Venue as VenueModel } from '../../../models/Venue'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { initialQuery } from '../../../utils/helpers/crud/models'
import {
  getVenues,
  restoreVenueWithId,
  selectVenues,
  setVenueQuery,
} from '../../../reducers/VenueReducer'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import getColumnSearchProps from '../../../utils/table/getColumnSearchProps'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { Stage } from '../../../components/stage/Stage'
import { Venue } from '../../../components/venue/Venue'
import SBTable from '../../../components/SBTable'
import { extractSortDataFromSorterResult } from '../../../utils/table/sorter'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { HelpArticle } from '../../../utils/hooks/useAlelaHelp'
import { useNavigate } from 'react-router-dom'
import { TableParams } from '../../../models/TableParams'
import HasAccess from '../../../components/HasAccess'
import { AlelaPermission } from '../../../utils/permissions'
import { ArrowLeftOutlined } from '@ant-design/icons'

const VenuesArchived = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const searchInput = useRef<InputRef>(null)
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const venues = useAppSelector(selectVenues())
  const { query, isLoading } = useAppSelector((state) => state.venue)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const fetchVenues = (nextQuery?: TableParams): void => {
    if (nextQuery !== undefined) {
      dispatch(setVenueQuery(nextQuery))
    }
    dispatch(getVenues(nextQuery !== undefined ? nextQuery : query))
  }

  const handleSearch = (
    selectedKeys: string[],
    dataIndex: keyof VenueModel
  ) => {
    const newQuery = {
      ...query,
      ...{ search: { [dataIndex]: selectedKeys[0] } },
    }
    fetchVenues(newQuery)
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    const newQuery = {
      ...query,
      ...{ search: { dataIndex: null } },
    }
    fetchVenues(newQuery)
  }

  const columns: ColumnsType<VenueModel> = [
    {
      key: 'name',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.Settings.Venues.Name,
      }),
      sorter: true,
      ...getColumnSearchProps<VenueModel>(
        'name',
        searchInput,
        handleSearch,
        handleReset,
        intl,
        isLoading
      ),
      render: (venue: VenueModel) => (
        <Venue.Avatar model={venue} size="large" linkToDetails={false} />
      ),
    },
    {
      key: 'type',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.Settings.Venues.Type,
      }),
      render: (venue: VenueModel) => <Venue.TypeTag id={Number(venue.type)} />,
    },
    {
      key: 'stages',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.Settings.Venues.Stages,
      }),
      render: (venue: VenueModel) => <Stage.List ids={venue.stages ?? []} />,
    },
    {
      key: 'defaultOpeningRange',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.Settings.Venues.OpeningRange,
      }),
      render: (venue: VenueModel) => (
        <>
          {venue.defaultOpeningRange && venue.defaultOpeningRange.start && (
            <Tag>
              {venue.defaultOpeningRange.start}
              {' -> '}
              {venue.defaultOpeningRange.end}
            </Tag>
          )}
        </>
      ),
    },
    {
      key: 'actions',
      align: 'right',
      render: (venue: VenueModel) => (
        <Button
          loading={isLoading}
          onClick={() => {
            dispatch(restoreVenueWithId(venue)).then(() => {
              fetchVenues()
            })
          }}
        >
          {intl.formatMessage({
            id: LocalizationKeys.Page.Settings.ArchivedVenues.Restore,
          })}
        </Button>
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<VenueModel> | SorterResult<VenueModel>[]
  ) => {
    const newQuery = {
      pagination,
      filters,
      ...extractSortDataFromSorterResult(sorter),
    }
    fetchVenues(newQuery)
  }

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      return
    }
    setIsFirstRender(false)

    // Trigger when SBRM is closed and on init
    const baseQuery: TableParams = {
      ...initialQuery,
      pagination: { current: 1, pageSize: 100 },
      filters: {
        archived: [true],
      },
    }
    fetchVenues(baseQuery)
  }, [dispatch, SBRMIsOpen])

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Button
          type="text"
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate('/settings/venues')}
        >
          {intl.formatMessage({
            id: LocalizationKeys.Page.CRM.Contact.Back,
          })}
        </Button>
      </Col>
      <HasAccess permissions={[AlelaPermission.listVenue]}>
        <Col span={24}>
          <Card
            bordered={false}
            styles={{ body: { padding: 0 } }}
            title={
              <PageTitle
                level={5}
                title={intl.formatMessage({
                  id: LocalizationKeys.Page.Settings.ArchivedVenues.Title,
                })}
              />
            }
          >
            <SBTable
              hideCreateButton
              entity={SBRMType.venue}
              scroll={{ x: 600 }}
              columns={columns}
              rowKey={(record) => record.id}
              dataSource={venues}
              pagination={query.pagination}
              loading={isLoading && venues.length == 0}
              onChange={handleTableChange}
            />
          </Card>
        </Col>
      </HasAccess>
    </Row>
  )
}

export default VenuesArchived

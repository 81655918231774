import { Navigate, Route, Routes } from 'react-router-dom'
import ContractsPage from '../../../pages/event-detail/administrative/ContractsPage'
import InvoicesPage from '../../../pages/event-detail/administrative/InvoicesPage'
import ReportingPage from '../../../pages/event-detail/administrative/ReportingPage'

const EventAdministrativeRoutes = () => {
  return (
    <Routes>
      <Route index path="contracts" element={<ContractsPage />} />
      <Route path="invoices" element={<InvoicesPage />} />
      <Route path="reporting" element={<ReportingPage />} />
      <Route path="*" element={<Navigate to={'contracts'} />} />
    </Routes>
  )
}

export default EventAdministrativeRoutes

import { useEffect } from 'react'
import { useMsal } from '@azure/msal-react'
import { useNavigate } from 'react-router-dom'
import { Space, Typography } from 'antd'
import { loginRequest } from '../../utils/saml-sso/authConfig'
import { useAzureSSO } from '../../utils/saml-sso/AzureSSOContext'
import { useAuth } from '../../utils/auth/Auth'
import {
  getLoggedUserAccountInfos,
  getLoggedUserInfos,
} from '../../utils/auth/requests'
import { Tenant } from '../../models/Tenant'
import * as authHelper from '../../utils/helpers/AuthLocalStorageHelper'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'

const { Text } = Typography

const LoginRedirect = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { instance } = useMsal()
  const { setIsLogged, saveAuth } = useAzureSSO()
  const { setCurrentUser, saveTenant, setTenants } = useAuth()

  useEffect(() => {
    const fetchUserInfos = async () => {
      const { data: accountInfo } = await getLoggedUserAccountInfos()
      if (accountInfo.data && accountInfo.data.tenants) {
        /**
         * Store all tenant attached to the user
         * This allow to display the tenant selection in top right menu
         */
        setTenants(accountInfo.data.tenants)

        /**
         * Then we need to set the current tenant
         * So we first check in the local storage
         * If there is one and this one is present in the tenants we get above
         * => This one will be the current
         *
         * Otherwise we set the first of the ones we get above
         */
        const localStorageTenant = authHelper.getAuthTenant()
        if (
          accountInfo.data.tenants
            .map((tenant: Tenant) => tenant.id)
            .includes(localStorageTenant?.id ?? '')
        ) {
          saveTenant(localStorageTenant)
        } else {
          saveTenant(accountInfo.data.tenants[0])
        }
      }
      const { data: user } = await getLoggedUserInfos()
      setIsLogged(true)
      setCurrentUser(user.data)
      const queryParameters = new URLSearchParams(window.location.search)
      const returnTo = queryParameters.get('returnTo')
      if (returnTo) {
        navigate(returnTo)
      }
    }
    instance.handleRedirectPromise().then(() => {
      const account = instance.getActiveAccount()
      if (!account) {
        throw Error(
          'No active account! Verify a user has been signed in and setActiveAccount has been called.'
        )
      }

      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: account,
        })
        .then((result) => {
          saveAuth({
            access_token: result.idToken,
            expires_in: (result.expiresOn ?? new Date()).toISOString(),
            refresh_token: '',
            token_type: result.tokenType,
          })
          fetchUserInfos()
        })
    })
  }, [instance])

  return (
    <Space
      direction="vertical"
      style={{ padding: 50, textAlign: 'center' }}
      size={20}
    >
      <Text>
        {intl.formatMessage({ id: LocalizationKeys.Auth.Login.Redirect })}
      </Text>
    </Space>
  )
}

export default LoginRedirect

import { commonStyle } from './style'
import { View, Text } from '@react-pdf/renderer'
import { ContactWithRelation } from '../../../models/Contact'

interface Props {
  contact: ContactWithRelation
}

const ContactAvatar = ({ contact }: Props) => {
  return (
    <View style={commonStyle.spaceVertical}>
      <Text style={commonStyle.p}>
        {contact.firstName} {contact.lastName}
      </Text>
      <Text style={commonStyle.muted}>{contact.email}</Text>
    </View>
  )
}
export default ContactAvatar

import { FC } from 'react'
import { Timetable, TimetableType } from './Timetable'
import { Cell, CellType } from './Cell'
import { TimetableCell, TimetableCellType } from './TimetableCell'
import { Table, TableType } from './Table'
import { PerformanceForm, PerformanceFormType } from './form/Form'

export const Performance: FC &
  TimetableType &
  TableType &
  TimetableCellType &
  CellType &
  PerformanceFormType = (): JSX.Element => <></>

Performance.Timetable = Timetable
Performance.Table = Table
Performance.TimetableCell = TimetableCell
Performance.Cell = Cell
Performance.Form = PerformanceForm

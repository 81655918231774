import { Card, Col, Row, Space } from 'antd'
import { selectSelectedEvent } from '../../reducers/EventReducer'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { File } from '../../components/file/File'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { EVENTS_URL } from '../../utils/urls'
import { useEffect, useState } from 'react'
import { TableParams } from '../../models/TableParams'
import { initialQuery } from '../../utils/helpers/crud/models'
import {
  getFiles,
  resetFileQueryAndIds,
  selectFiles,
  setFileQuery,
} from '../../reducers/FileReducer'
import { Actions } from '../../modules/sbrm/components/Actions'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { MetaDataKey } from '../../models/MetaData'
import { FileRelation } from '../../models/File'
import HasAccess from '../../components/HasAccess'
import { AlelaPermission } from '../../utils/permissions'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { HelpArticle } from '../../utils/hooks/useAlelaHelp'
import { Help } from '../../components/help/Help'

const FilesPage = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const [showDropZone, setShowDropZone] = useState<boolean>(false)
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const event = useAppSelector(selectSelectedEvent())
  const files = useAppSelector(selectFiles())
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const handleDragOn = function (e: any) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setShowDropZone(true)
    }
  }

  const handleDragOff = function (e: any) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragleave') {
      setShowDropZone(false)
    }
  }

  const resetQueryAndGetFiles = () => {
    const baseQuery: TableParams = {
      ...initialQuery,
      pagination: { current: 1, pageSize: 100 },
      filters: { events: [event?.id!] },
    }
    dispatch(setFileQuery(baseQuery))
    dispatch(getFiles(baseQuery))
  }

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      return
    }
    setIsFirstRender(false)

    resetQueryAndGetFiles()
  }, [SBRMIsOpen])

  return (
    <Row className="container-row">
      <Col span={24}>
        <HasAccess permissions={[AlelaPermission.listFile]}>
          <PageTitle
            title={intl.formatMessage({
              id: LocalizationKeys.Page.EventDetails.Files,
            })}
            level={5}
            badge={files.length}
            toolbar={
              <Space>
                <Actions
                  actions={['create']}
                  entity={SBRMType.file}
                  metadata={[
                    {
                      key: MetaDataKey.fileParentType,
                      value: SBRMType.event,
                    },
                    { key: MetaDataKey.fileParentId, value: event?.id },
                    {
                      key: MetaDataKey.fileRelation,
                      value: FileRelation.file,
                    },
                  ]}
                />
                <Help.Button article={HelpArticle.ShareAFile} />
              </Space>
            }
          />
          <Row gutter={16} style={{ marginTop: '10px' }}>
            <Col md={24} sm={24} xs={24}>
              <Card
                onDragOver={handleDragOn}
                onDragLeave={handleDragOff}
                bordered={false}
              >
                {!showDropZone && (
                  <File.Grid
                    ids={files.map((f) => f.id) ?? []}
                    withDeleteButton={true}
                  />
                )}
                {showDropZone && (
                  <File.Upload
                    action={`${EVENTS_URL}/${event?.id}/files`}
                    onSuccessUpload={() => {
                      setShowDropZone(false)
                      resetQueryAndGetFiles()
                    }}
                  />
                )}
              </Card>
            </Col>
          </Row>
        </HasAccess>
      </Col>
    </Row>
  )
}

export default FilesPage

import { FC } from 'react'
import { TableWithResource, TableWithResourceType } from './TableWithResource'
import { ContactAssocationForm, ContactAssocationFormType } from './form/Form'

export const ContactAssociation: FC &
  TableWithResourceType &
  ContactAssocationFormType = (): JSX.Element => <></>

ContactAssociation.TableWithResource = TableWithResource
ContactAssociation.Form = ContactAssocationForm

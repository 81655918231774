import { FC } from 'react'
import { CommentCell, CommentCellType } from './Cell'
import { CommentWrapper, CommentWrapperType } from './Wrapper'
import { CommentList, CommentListType } from './List'
import { CommentEditor, CommentEditorType } from './Editor'
import {
  CommentSubscribeToConversation,
  CommentSubscribeToConversationType,
} from './SubscribeToConversation'
import { ExternalCell, ExternalCellType } from './ExternalCell'
import { CommentHeader, CommentHeaderType } from './Header'

export const Comment: FC &
  CommentCellType &
  ExternalCellType &
  CommentWrapperType &
  CommentListType &
  CommentEditorType &
  CommentSubscribeToConversationType &
  CommentHeaderType = (): JSX.Element => <></>

Comment.Cell = CommentCell
Comment.ExternalCell = ExternalCell
Comment.Wrapper = CommentWrapper
Comment.List = CommentList
Comment.Editor = CommentEditor
Comment.SubscribeToConversation = CommentSubscribeToConversation
Comment.Header = CommentHeader

import { Stage } from '../../models/Stage'
import { ColumnsType } from 'antd/es/table'
import { useAppSelector } from '../../reducers/hooks'
import { selectStagesByIds } from '../../reducers/StageReducer'
import { Tag } from 'antd'

interface Props {
  ids: number[]
}

export const List = ({ ids }: Props) => {
  const stages = useAppSelector(selectStagesByIds(ids))
  return (
    <>
      {stages.map((item) => (
        <Tag key={item.id}>{item.name}</Tag>
      ))}
    </>
  )
}

export type ListType = {
  List: typeof List
}

import { useEffect } from 'react'
import { Contact } from '../contact/Contact'
import { Space } from 'antd'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { initialQuery } from '../../utils/helpers/crud/models'
import {
  approveContactAssociation,
  deleteContactAssociation,
  getNonApprovedContactAssociations,
  selectAllNotApprovedAssociations,
} from '../../reducers/ContactAssociationReducer'
import { TableParams } from '../../models/TableParams'
import BaseExternalRequests from '../BaseExternalRequests'
import { Booking } from '../booking/Booking'
import { ContactAssociation } from '../../models/Contact'
import { Booking as BookingModel } from '../../models/Booking'

interface Props {
  bookings: BookingModel[]
  shouldRefreshList: (association: ContactAssociation) => void
}

export const PartyCrewExternalRequests = ({
  bookings,
  shouldRefreshList,
}: Props) => {
  const dispatch = useAppDispatch()
  const associations = useAppSelector(selectAllNotApprovedAssociations())

  const fetchData = () => {
    const baseQuery: TableParams = {
      ...initialQuery,
      filters: { bookins: bookings.map((b) => b.id) },
      with: ['contact'],
    }
    dispatch(getNonApprovedContactAssociations(baseQuery))
  }
  useEffect(() => {
    if (!Array.isArray(bookings) || bookings.length === 0) return
    fetchData()
  }, [bookings.length])

  if (associations.length === 0) return <></>

  return (
    <BaseExternalRequests
      entity={SBRMType.contactAssociation}
      items={associations}
      showEdit={false}
      renderItem={(item) => (
        <Space size={10}>
          <Booking.Avatar
            id={item.contactable?.id ?? 0}
            linkToDetails={false}
          />
          <Contact.Avatar id={item.contact} />
          <Contact.TypeTag id={item.type} />
        </Space>
      )}
      updateMethod={approveContactAssociation}
      deleteMethod={deleteContactAssociation}
      shouldRefreshList={(item) => {
        fetchData()
        shouldRefreshList(item)
      }}
    />
  )
}

export type PartyCrewExternalRequestsType = {
  PartyCrewExternalRequests: typeof PartyCrewExternalRequests
}

import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import Dragger from 'antd/es/upload/Dragger'
import { Form, FormInstance, Input, Space, Typography } from 'antd'
import { requiredRule, stringRule } from '../../../utils/rules'
import { MetaDataKey } from '../../../models/MetaData'
import { useAppSelector } from '../../../reducers/hooks'
import { SBAsyncSelect } from '../../../components/custom-inputs/sb-async-select/SBAsyncSelect'
import TextArea from 'antd/es/input/TextArea'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import { InboxOutlined } from '@ant-design/icons'

const { Text } = Typography

interface Props {
  form: FormInstance
  eventId: number
  bookingId?: number
}

const Create = ({ form, eventId, bookingId }: Props) => {
  const intl = useIntl()

  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  useEffect(() => {
    if (!SBRMIsOpen) {
      // We want to trigger only the reset
      // when the SBRM opens
      return
    }

    form.setFieldValue('booking', bookingId)
  }, [SBRMIsOpen])

  return (
    <Form form={form} layout="vertical">
      {!bookingId && (
        <SBAsyncSelect
          type={SBRMType.booking}
          name={'booking'}
          label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Booking })}
          rules={[requiredRule(intl)]}
          metadata={[
            {
              key: MetaDataKey.eventId,
              value: eventId,
            },
          ]}
        />
      )}
      {bookingId && (
        <Form.Item
          name="booking"
          rules={[requiredRule(intl)]}
          className="d-none"
        >
          <Input type="hidden" />
        </Form.Item>
      )}
      <SBAsyncSelect
        type={SBRMType.riderStatus}
        name={'status'}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Status })}
        rules={[requiredRule(intl)]}
      />
      <SBAsyncSelect
        type={SBRMType.riderType}
        name={'type'}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Type })}
        rules={[]}
      />
      <Form.Item
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Notes })}
        name="notes"
        rules={[stringRule(intl)]}
      >
        <TextArea rows={5} />
      </Form.Item>

      <Form.Item name="file" rules={[]}>
        <Dragger
          accept="*"
          maxCount={1}
          multiple={false}
          beforeUpload={(file) => {
            return false
          }}
        >
          <Space direction="vertical" size={0}>
            <p className="ant-upload-drag-icon" style={{ marginTop: 0 }}>
              <InboxOutlined />
            </p>
            <Text>
              {intl.formatMessage({
                id: LocalizationKeys.Components.CustomInput.Dropzone,
              })}
            </Text>
            <Text type="secondary" italic style={{ fontSize: 12 }}>
              {intl.formatMessage({
                id: LocalizationKeys.Components.File.MaxSize,
              })}
            </Text>
          </Space>
        </Dragger>
      </Form.Item>
    </Form>
  )
}

export default Create

import { FC } from 'react'
import {
  SupplierTypeListWithCheckboxes,
  SupplierTypeListWithCheckboxesType,
} from './ListWithCheckboxes'
import { SupplierTypeForm, SupplierTypeFormType } from './form/Form'

export const SupplierType: FC &
  SupplierTypeListWithCheckboxesType &
  SupplierTypeFormType = (): JSX.Element => <></>

SupplierType.ListWithCheckboxes = SupplierTypeListWithCheckboxes
SupplierType.Form = SupplierTypeForm

import { Tag as ANTDTag, Typography } from 'antd'
import { useAppSelector } from '../../reducers/hooks'
import { selectStageById } from '../../reducers/StageReducer'
import { Stage } from '../../models/Stage'

const { Text } = Typography
interface Props {
  id?: number
  model?: Stage
}

export const Tag = ({ id, model }: Props) => {
  const stage = model ? model : useAppSelector(selectStageById(id ?? 0))
  return stage ? <ANTDTag>{stage.name}</ANTDTag> : <></>
}

export type TagType = { Tag: typeof Tag }

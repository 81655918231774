import { Typography } from 'antd'

const { Text } = Typography

interface Props {
  suffixCount: number
  maxWidth?: number
  children: string
}

const EllipsisMiddle = ({ suffixCount, maxWidth = 80, children }: Props) => {
  const start = children.slice(0, children.length - suffixCount).trim()
  const suffix = children.slice(-suffixCount).trim()
  return (
    <Text style={{ maxWidth: maxWidth }} ellipsis={{ suffix }}>
      {start}
    </Text>
  )
}

export default EllipsisMiddle

import {
  ToolOutlined,
  BankOutlined,
  PictureOutlined,
  SlidersOutlined,
  AimOutlined,
  TransactionOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../utils/auth/Auth'
import { Avatar, Card, Divider, Menu, Space, Typography } from 'antd'
import { extractSectionFromPath } from '../../utils/helpers/RouterHelpers'
import { File } from '../../components/file/File'
import { UPDATE_ORGANIZATION_LOGO } from '../../utils/urls'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'

const { Title } = Typography

const SettingsSider = () => {
  const intl = useIntl()
  const { pathname } = useLocation()
  const { currentTenant, saveTenant } = useAuth()
  const [selectedKey, setSelectedKey] = useState<string>('venues')
  const [logoFormIsVisible, setLogoFormIsVisible] = useState<boolean>(false)

  useEffect(() => {
    const menuItemSelected = extractSectionFromPath(pathname, 2)
    setSelectedKey(menuItemSelected)
  }, [pathname])

  return (
    <Card
      styles={{
        body: { paddingLeft: 10, paddingRight: 10, paddingBottom: 10 },
      }}
      bordered={false}
    >
      <Space direction="vertical" className="w-full">
        <Space direction="vertical" className="text-center w-full">
          {!logoFormIsVisible && (
            <Avatar
              shape="square"
              size={50}
              src={currentTenant?.logo}
              onClick={() => setLogoFormIsVisible(true)}
            />
          )}
          {logoFormIsVisible && (
            <File.Upload
              icon={<PictureOutlined />}
              title={intl.formatMessage({
                id: LocalizationKeys.Layout.SettingsSider
                  .UploadOrganizationImage,
              })}
              action={UPDATE_ORGANIZATION_LOGO}
              accept={'image/*'}
              onSuccessUpload={(result) => {
                saveTenant(result.data.data)
                setLogoFormIsVisible(false)
              }}
            />
          )}
          <Space direction="vertical" size={0}>
            <Title level={4} style={{ margin: 0 }}>
              {currentTenant?.name}
            </Title>
          </Space>
        </Space>
        <Divider style={{ margin: '5px 0 5px 0' }} />
        <Menu
          mode="vertical"
          selectedKeys={[selectedKey]}
          style={{ border: 0 }}
          items={[
            {
              key: 'venues',
              label: (
                <Link to={'venues'}>
                  {intl.formatMessage({
                    id: LocalizationKeys.Layout.SettingsSider.Venues,
                  })}
                </Link>
              ),
              icon: <AimOutlined />,
            },
            {
              key: 'hotels',
              label: (
                <Link to={'hotels'}>
                  {intl.formatMessage({
                    id: LocalizationKeys.Layout.SettingsSider.Hotels,
                  })}
                </Link>
              ),
              icon: <AimOutlined />,
            },
            {
              key: 'stations',
              label: (
                <Link to={'stations'}>
                  {intl.formatMessage({
                    id: LocalizationKeys.Layout.SettingsSider.Stations,
                  })}
                </Link>
              ),
              icon: <AimOutlined />,
            },
            {
              key: 'reporting',
              label: (
                <Link to={'reporting/items'}>
                  {intl.formatMessage({
                    id: LocalizationKeys.Layout.SettingsSider.ReportingItems,
                  })}
                </Link>
              ),
              icon: <AimOutlined />,
            },
            {
              type: 'divider',
              key: 'divider-1',
              dashed: false,
            },
            {
              key: 'booking-types',
              label: (
                <Link to={'booking-types'}>
                  {intl.formatMessage({
                    id: LocalizationKeys.Layout.SettingsSider.BookingTypes,
                  })}
                </Link>
              ),
              icon: <ToolOutlined />,
            },
            {
              key: 'venue-types',
              label: (
                <Link to={'venue-types'}>
                  {intl.formatMessage({
                    id: LocalizationKeys.Layout.SettingsSider.VenueTypes,
                  })}
                </Link>
              ),
              icon: <ToolOutlined />,
            },
            {
              key: 'contact-types',
              label: (
                <Link to={'contact-types'}>
                  {intl.formatMessage({
                    id: LocalizationKeys.Layout.SettingsSider.ContactTypes,
                  })}
                </Link>
              ),
              icon: <ToolOutlined />,
            },
            {
              key: 'performance-types',
              label: (
                <Link to={'performance-types'}>
                  {intl.formatMessage({
                    id: LocalizationKeys.Layout.SettingsSider.PerformanceTypes,
                  })}
                </Link>
              ),
              icon: <ToolOutlined />,
            },
            {
              key: 'rider-types',
              label: (
                <Link to={'rider-types'}>
                  {intl.formatMessage({
                    id: LocalizationKeys.Page.Settings.RiderType,
                  })}
                </Link>
              ),
              icon: <ToolOutlined />,
            },
            {
              key: 'invoice-categories',
              label: (
                <Link to={'invoice-categories'}>
                  {intl.formatMessage({
                    id: LocalizationKeys.Layout.SettingsSider.InvoiceCategories,
                  })}
                </Link>
              ),
              icon: <ToolOutlined />,
            },
            {
              type: 'divider',
              key: 'divider-2',
              dashed: false,
            },
            {
              key: 'event-statuses',
              label: (
                <Link to={'event-statuses'}>
                  {intl.formatMessage({
                    id: LocalizationKeys.Layout.SettingsSider.EventStatuses,
                  })}
                </Link>
              ),
              icon: <SlidersOutlined />,
            },
            {
              key: 'contract-statuses',
              label: (
                <Link to={'contract-statuses'}>
                  {intl.formatMessage({
                    id: LocalizationKeys.Layout.SettingsSider.ContractStatuses,
                  })}
                </Link>
              ),
              icon: <SlidersOutlined />,
            },
            {
              key: 'rider-statuses',
              label: (
                <Link to={'rider-statuses'}>
                  {intl.formatMessage({
                    id: LocalizationKeys.Layout.SettingsSider.RiderStatuses,
                  })}
                </Link>
              ),
              icon: <SlidersOutlined />,
            },
            {
              key: 'marketing-task-statuses',
              label: (
                <Link to={'marketing-task-statuses'}>
                  {intl.formatMessage({
                    id: LocalizationKeys.Layout.SettingsSider
                      .MarketingTaskStatuses,
                  })}
                </Link>
              ),
              icon: <SlidersOutlined />,
            },
            {
              type: 'divider',
              key: 'divider-3',
              dashed: false,
            },
            {
              key: 'organization',
              label: (
                <Link to={'organization'}>
                  {intl.formatMessage({
                    id: LocalizationKeys.Layout.SettingsSider.Organization,
                  })}
                </Link>
              ),
              icon: <BankOutlined />,
            },
            {
              key: 'organization-roles',
              label: (
                <Link to={'organization-roles'}>
                  {intl.formatMessage({
                    id: LocalizationKeys.Page.Organization.Roles,
                  })}
                </Link>
              ),
              icon: <UserSwitchOutlined />,
            },
            {
              key: 'organization-settings',
              label: (
                <Link to={'organization-settings'}>
                  {intl.formatMessage({
                    id: LocalizationKeys.Layout.SettingsSider.Preferences,
                  })}
                </Link>
              ),
              icon: <TransactionOutlined />,
            },
          ]}
        />
      </Space>
    </Card>
  )
}

export default SettingsSider

import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import {
  DatePicker,
  Form,
  FormInstance,
  Input,
  Tooltip,
  Typography,
} from 'antd'
import { requiredRule } from '../../../utils/rules'
import { useAppSelector } from '../../../reducers/hooks'
import { selectSelectedEvent } from '../../../reducers/EventReducer'
import { SBAsyncSelect } from '../../../components/custom-inputs/sb-async-select/SBAsyncSelect'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import dayjs from 'dayjs'
import { QuestionCircleOutlined } from '@ant-design/icons'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

const { RangePicker } = DatePicker
const { Text } = Typography

interface Props {
  form: FormInstance
}

const Update = ({ form }: Props) => {
  const intl = useIntl()

  const event = useAppSelector(selectSelectedEvent())
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  useEffect(() => {
    if (!SBRMIsOpen) {
      // We want to trigger only the reset
      // when the SBRM opens
      return
    }
    form.setFieldsValue(event)
    form.setFieldValue('dates', [
      event?.start_date ? dayjs(event?.start_date) : '',
      event?.end_date ? dayjs(event?.end_date) : '',
    ])
  }, [SBRMIsOpen])

  if (event === undefined) {
    return <></>
  }

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        style={{ display: 'none' }}
        name="id"
        required
        rules={[requiredRule(intl)]}
      >
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        name="name"
        required
        rules={[requiredRule(intl)]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Name })}
      >
        <Input showCount maxLength={60} />
      </Form.Item>
      <Form.Item
        name="dates"
        required
        label={
          <>
            {intl.formatMessage({ id: LocalizationKeys.Misc.Form.Dates })}
            <Tooltip
              title={
                <>
                  <Text>
                    {intl.formatMessage({
                      id: LocalizationKeys.Misc.Form.EventUpdate.DateChanging,
                    })}
                  </Text>
                  <br />
                  <Text strong>
                    {intl.formatMessage({
                      id: LocalizationKeys.Misc.Form.EventUpdate
                        .DateChangingWarning,
                    })}
                  </Text>
                </>
              }
            >
              <QuestionCircleOutlined style={{ marginLeft: 5 }} />
            </Tooltip>
          </>
        }
        rules={[requiredRule(intl)]}
      >
        <RangePicker
          className="w-full"
          showTime={{ format: 'HH:mm', minuteStep: 15 }}
          format="DD/MM/YYYY HH:mm"
        />
      </Form.Item>

      <SBAsyncSelect
        type={SBRMType.venue}
        name="venues"
        multiple={true}
        rules={[requiredRule(intl)]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Venues })}
      />

      <SBAsyncSelect
        type={SBRMType.eventStatus}
        name="status"
        rules={[requiredRule(intl)]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Status })}
      />
    </Form>
  )
}

export default Update

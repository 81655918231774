import { Form, FormInstance, Input, Row, Col, Alert } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import TextArea from 'antd/es/input/TextArea'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import { MetaDataKey } from '../../../models/MetaData'
import { useAppSelector } from '../../../reducers/hooks'
import { Ground } from '../../../components/ground/Ground'
import { requiredRule, stringRule } from '../../../utils/rules'
import { SBAsyncSelect } from '../../../components/custom-inputs/sb-async-select/SBAsyncSelect'
import { selectEventById } from '../../../reducers/EventReducer'
import dayjs from 'dayjs'
import { GroundCascaderInterface } from '../../../components/ground/Cascader'
import { selectSelectedGround } from '../../../reducers/GroundReducer'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import {
  GroundContextProvider,
  useGroundContext,
} from '../../../utils/context/GroundContext'

interface Props {
  form: FormInstance
  eventId: number
  bookingId?: number
  duplicate?: boolean
}

const Create = ({ form, eventId, bookingId, duplicate }: Props) => {
  const intl = useIntl()

  const cascaderRef = useRef<GroundCascaderInterface>(null)
  const { fillCascader, timeMode, setTimeMode } = useGroundContext()

  const [bookingIdForCascader, setBookingIdForCascader] = useState<
    number | undefined
  >(undefined)

  const ground = useAppSelector(selectSelectedGround())
  const event = useAppSelector(selectEventById(eventId))
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  useEffect(() => {
    if (!SBRMIsOpen || duplicate) {
      // We want to trigger only the reset
      // when the SBRM opens and it's not for a duplication
      return
    }

    form.resetFields()
    form.setFieldValue('booking', bookingId)
    form.setFieldValue('timeMode', 'departAt')
    setBookingIdForCascader(bookingId)
  }, [duplicate, SBRMIsOpen])

  useEffect(() => {
    if (!duplicate || !ground) {
      return
    }
    form.setFieldsValue(ground)
    form.setFieldValue('booking', ground.booking)
    form.setFieldValue('pickupTime', dayjs(ground.pickupTime))
    form.setFieldValue('timeMode', 'departAt')
    setBookingIdForCascader(ground.booking)
    fillCascader()
  }, [duplicate, ground])

  return (
    <Form
      form={form}
      layout="vertical"
      onValuesChange={() =>
        setBookingIdForCascader(form.getFieldValue('booking'))
      }
    >
      {!bookingId && (
        <SBAsyncSelect
          type={SBRMType.booking}
          name={'booking'}
          rules={[requiredRule(intl)]}
          label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Booking })}
          metadata={[
            {
              key: MetaDataKey.eventId,
              value: eventId,
            },
          ]}
        />
      )}
      {bookingId && (
        <Form.Item
          name="booking"
          rules={[requiredRule(intl)]}
          className="d-none"
        >
          <Input type="hidden" />
        </Form.Item>
      )}
      {!bookingIdForCascader && (
        <Col span={24}>
          <Alert
            type="warning"
            description={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.SelectABooking,
            })}
          />
        </Col>
      )}

      {bookingIdForCascader && (
        <Row gutter={[16, 0]}>
          <GroundContextProvider
            bookingId={bookingIdForCascader}
            initialValue={duplicate ? ground : undefined}
          >
            <Col span={24}>
              <Ground.Cascader
                ref={cascaderRef}
                eventId={eventId}
                initialValue={duplicate ? ground : undefined}
                bookingId={bookingIdForCascader}
              />
            </Col>
            <Col span={16}>
              <Ground.PickupTimeFormItem eventId={eventId} />
            </Col>
            <Col span={8}>
              <Ground.DurationFormItem />
            </Col>
            <Col span={24}>
              <SBAsyncSelect
                type={SBRMType.contact}
                name={'passengers'}
                multiple={true}
                rules={[]}
                label={intl.formatMessage({
                  id: LocalizationKeys.Misc.Form.Passengers,
                })}
              />
            </Col>
            <Col span={24}>
              <SBAsyncSelect
                type={SBRMType.contact}
                name={'driver'}
                rules={[]}
                label={intl.formatMessage({
                  id: LocalizationKeys.Misc.Form.Driver,
                })}
              />
            </Col>
            <Col span={24}>
              <Form.Item
                name="vehicleDetails"
                rules={[stringRule(intl)]}
                label={intl.formatMessage({
                  id: LocalizationKeys.Misc.Form.VehicleDetails,
                })}
              >
                <Input className="w-full" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="notes"
                rules={[stringRule(intl)]}
                label={intl.formatMessage({
                  id: LocalizationKeys.Misc.Form.Notes,
                })}
              >
                <TextArea rows={5} />
              </Form.Item>
            </Col>
          </GroundContextProvider>
        </Row>
      )}
    </Form>
  )
}

export default Create

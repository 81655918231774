import { Space } from 'antd'
import { Booking } from '../booking/Booking'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { useEffect } from 'react'
import { initialQuery } from '../../utils/helpers/crud/models'
import { TableParams } from '../../models/TableParams'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import BaseExternalRequests from '../BaseExternalRequests'
import {
  approveRider,
  deleteRider,
  getNonApprovedRiders,
  selectAllNotApprovedRiders,
} from '../../reducers/RiderReducer'
import { RiderStatus } from '../rider-status/RiderStatus'

interface ExternalRequestsProps {
  eventId: number
  shouldRefreshList: () => void
}

const ExternalRequests = ({
  eventId,
  shouldRefreshList,
}: ExternalRequestsProps) => {
  const dispatch = useAppDispatch()
  const riders = useAppSelector(selectAllNotApprovedRiders())

  useEffect(() => {
    const baseQuery: TableParams = {
      ...initialQuery,
      filters: { events: [eventId] },
      columnKey: 'time',
      order: 'ascend',
    }
    dispatch(getNonApprovedRiders(baseQuery))
  }, [])

  if (riders.length === 0) return <></>

  return (
    <BaseExternalRequests
      entity={SBRMType.rider}
      items={riders}
      renderItem={(item) => (
        <Space size={10}>
          <a href="#" onClick={(e) => e.stopPropagation()}>
            <Booking.Avatar id={item.booking ?? 0} />
          </a>
          <RiderStatus.Tag id={item.status} />
        </Space>
      )}
      updateMethod={approveRider}
      deleteMethod={deleteRider}
      shouldRefreshList={shouldRefreshList}
    />
  )
}

type ExternalRequestsType = { ExternalRequests: typeof ExternalRequests }

export { ExternalRequests, type ExternalRequestsType }

import { ReleaseNotes } from './ReleaseNotes'
import { Release } from '../../models/Release'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { PlusCircleOutlined, BugOutlined } from '@ant-design/icons'
import {
  Avatar,
  Badge,
  Card,
  List,
  Space,
  Tag,
  Timeline,
  Typography,
} from 'antd'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'

const { Text } = Typography

const ReleaseNotesPage = () => {
  const intl = useIntl()
  const releases = ReleaseNotes

  return (
    <Card
      bordered={false}
      title={
        <PageTitle
          title={intl.formatMessage({
            id: LocalizationKeys.Page.ReleaseNotes.Title,
          })}
          level={5}
        />
      }
    >
      <Timeline
        reverse={true}
        items={releases.map((release) => ({
          children: <ReleaseCard release={release} />,
        }))}
      />
    </Card>
  )
}

interface ReleaseCardProps {
  release: Release
}

const ReleaseCard = ({ release }: ReleaseCardProps) => {
  return (
    <Badge.Ribbon text={release.version}>
      <List
        size="small"
        header={release.date}
        bordered
        dataSource={release.fixtures}
        renderItem={(fixture) => (
          <List.Item>
            <Space direction="vertical">
              <Space>
                <Avatar
                  style={{
                    backgroundColor:
                      fixture.type == 'fix' ? '#dc4446' : '#49aa19',
                  }}
                  icon={
                    fixture.type == 'fix' ? (
                      <BugOutlined />
                    ) : (
                      <PlusCircleOutlined />
                    )
                  }
                />
                <Space direction="vertical" size={0}>
                  <Text>{fixture.title}</Text>
                  <Text type="secondary">{fixture.description}</Text>
                </Space>
              </Space>
              {fixture.gif && <img src={fixture.gif} />}
            </Space>
          </List.Item>
        )}
      />
    </Badge.Ribbon>
  )
}

export const NewTag = () => {
  return (
    <Tag color="purple" style={{ pointerEvents: 'none' }}>
      New
    </Tag>
  )
}

export default ReleaseNotesPage

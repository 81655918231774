import {
  selectPayments,
  selectPaymentsByIds,
} from '../../reducers/PaymentReducer'
import { Payment } from './Payment'
import { MetaData, MetaDataKey } from '../../models/MetaData'
import { Button, Empty, Space, Spin } from 'antd'
import { useAppSelector } from '../../reducers/hooks'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { Actions } from '../../modules/sbrm/components/Actions'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'

interface Props {
  ids?: number[]
  invoiceId: number
  emptyActionsMetadata?: MetaData[]
}

export const PaymentList = ({
  ids,
  invoiceId,
  emptyActionsMetadata,
}: Props) => {
  const intl = useIntl()
  const payments = ids
    ? useAppSelector(selectPaymentsByIds(ids))
    : useAppSelector(selectPayments())

  const { isLoading } = useAppSelector((state) => state.payment)

  if (isLoading && payments.length === 0) {
    return <Spin />
  }

  return payments.length > 0 ? (
    <Space direction="vertical" className="w-full">
      {payments.map((payment) => (
        <Payment.Card key={payment.id} model={payment} />
      ))}
      <Actions
        actions={['create']}
        entity={SBRMType.payment}
        metadata={[{ key: MetaDataKey.invoiceId, value: invoiceId }]}
        trigger={
          <Button className="w-full" type="primary">
            {intl.formatMessage({
              id: LocalizationKeys.Components.Payment.List.AddPayment,
            })}
          </Button>
        }
      />
    </Space>
  ) : (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={intl.formatMessage({
        id: LocalizationKeys.Components.Payment.List.AddFirstPayment,
      })}
    >
      <Actions
        actions={['create']}
        entity={SBRMType.payment}
        metadata={emptyActionsMetadata}
      />
    </Empty>
  )
}

export type PaymentListType = { List: typeof PaymentList }

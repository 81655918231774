import React from 'react'
import { useIntl } from 'react-intl'
import { Button, Form, Space } from 'antd'
import { useAppDispatch } from '../../../reducers/hooks'
import Delete from '../../sbrm/external-advancing-link/Delete'
import { deleteExternalAdvancingLink } from '../../../reducers/ExternalAdvancingLinkReducer'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

interface Props {
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  onSuccess: () => void
}

const ALMDelete = ({ isLoading, setIsLoading, onSuccess }: Props) => {
  const intl = useIntl()
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()

  const onSubmit = () => {
    setIsLoading(true)

    form
      .validateFields()
      .then(async (result) =>
        dispatch(deleteExternalAdvancingLink(form.getFieldsValue()))
          .then(() => {
            onSuccess()
            form.resetFields()
          })
          .finally(() => setIsLoading(false))
      )
      .catch((_) => setIsLoading(false))
  }

  return (
    <>
      <Space direction="vertical" className="w-full">
        <Delete form={form} />
        <Button
          danger
          type="primary"
          loading={isLoading}
          onClick={onSubmit}
          className="w-full"
        >
          {intl.formatMessage({ id: LocalizationKeys.SBRM.Delete })}
        </Button>
      </Space>
    </>
  )
}

export default ALMDelete

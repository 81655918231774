import {
  getContractStatuses,
  selectContractStatuses,
  setContractStatusQuery,
} from '../../reducers/ContractStatusReducer'
import { useIntl } from 'react-intl'
import { useEffect, useRef, useState } from 'react'
import { Card, InputRef, Tag } from 'antd'
import SBTable from '../../components/SBTable'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { Actions } from '../../modules/sbrm/components/Actions'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import getColumnSearchProps from '../../utils/table/getColumnSearchProps'
import { extractSortDataFromSorterResult } from '../../utils/table/sorter'
import { ContractStatus } from '../../components/contract-status/ContractStatus'
import { ContractStatus as ContractStatusModel } from '../../models/ContractStatus'
import LocalizationKeys, { LocalizationKey } from '../../i18n/LocalizationKeys'

const ContractsStatusPage = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const searchInput = useRef<InputRef>(null)
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const statuses = useAppSelector(selectContractStatuses())
  const { query, isLoading } = useAppSelector((state) => state.contractStatus)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const handleSearch = (
    selectedKeys: string[],
    dataIndex: keyof ContractStatusModel
  ) => {
    const newQuery = {
      ...query,
      ...{ search: { [dataIndex]: selectedKeys[0] } },
    }
    dispatch(setContractStatusQuery(newQuery))
    dispatch(getContractStatuses(newQuery))
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    const newQuery = {
      ...query,
      ...{ search: { dataIndex: null } },
    }
    dispatch(setContractStatusQuery(newQuery))
    dispatch(getContractStatuses(newQuery))
  }

  const columns: ColumnsType<ContractStatusModel> = [
    {
      key: 'name',
      title: intl.formatMessage({ id: LocalizationKeys.Misc.Table.Name }),
      ...getColumnSearchProps<ContractStatusModel>(
        'name',
        searchInput,
        handleSearch,
        handleReset,
        intl,
        isLoading
      ),
      render: (status: ContractStatusModel) => <>{status.name}</>,
    },
    {
      key: 'color',
      title: intl.formatMessage({ id: LocalizationKeys.Misc.Table.Color }),
      render: (status: ContractStatusModel) => (
        <Tag color={status.color}>{status.color}</Tag>
      ),
    },
    {
      key: 'icon',
      title: intl.formatMessage({ id: LocalizationKeys.Misc.Table.Icon }),
      render: (status: ContractStatusModel) => <Tag>{status.icon}</Tag>,
    },
    {
      key: 'isDefault',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.Settings.EventStatus.DefaultStatus,
      }),
      align: 'center',
      render: (record: ContractStatusModel) =>
        record.isDefault ? <Tag color="green">Yes</Tag> : <></>,
    },
    {
      key: 'actions',
      align: 'right',
      render: (contractStatus: ContractStatusModel) => (
        <Actions
          actions={['update', 'delete']}
          entity={SBRMType.contractStatus}
          entityId={contractStatus.id}
        />
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter:
      | SorterResult<ContractStatusModel>
      | SorterResult<ContractStatusModel>[]
  ) => {
    const newQuery = {
      ...query,
      pagination,
      filters,
      ...extractSortDataFromSorterResult(sorter),
    }
    dispatch(setContractStatusQuery(newQuery))
    dispatch(getContractStatuses(newQuery))
  }

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      return
    }
    setIsFirstRender(false)

    // Trigger when SBRM is closed and on init
    dispatch(getContractStatuses(query))
  }, [dispatch, SBRMIsOpen])

  return (
    <Card
      bordered={false}
      styles={{ body: { padding: 0 } }}
      title={
        <PageTitle
          title={intl.formatMessage({
            id: LocalizationKeys.Page.Settings.ContractStatus,
          })}
          level={5}
          toolbar={
            <Actions actions={['create']} entity={SBRMType.contractStatus} />
          }
        />
      }
    >
      <SBTable
        entity={SBRMType.contractStatus}
        scroll={{ x: 600 }}
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={statuses}
        pagination={query.pagination}
        loading={isLoading}
        onChange={handleTableChange}
      />
    </Card>
  )
}

export default ContractsStatusPage

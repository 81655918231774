export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname)
  if (!current || !url) {
    return false
  }

  if (current === url) {
    return true
  }

  if (current.indexOf(url) > -1) {
    return true
  }

  return false
}

/**
 * Extract the section at the level provided from the pathname
 * Level starts at 1
 * Example: /events/8 with level 1 will return `events`
 * @param pathname string
 * @param level number
 * @returns string
 */
export function extractSectionFromPath(pathname: string, level: number) {
  return pathname.split(/\//)[level]
}

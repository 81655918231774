import { Avatar, Space, Tag, Typography } from 'antd'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { selectEventById, setSelectedEvent } from '../../reducers/EventReducer'
import { Event } from '../../models/Event'
import { ConditionalLinkWrapper } from '@supplement-bacon/alela-uikit'
import dayjs from 'dayjs'
import { PictureOutlined } from '@ant-design/icons'

const { Text } = Typography

interface Props {
  id?: number
  model?: Event
  linkToDetails?: boolean
  ellipsisName?: boolean
}

export const EventCell = ({
  id,
  model,
  linkToDetails = true,
  ellipsisName = false,
}: Props) => {
  const dispatch = useAppDispatch()

  const event = model ? model : useAppSelector(selectEventById(id!))

  return event ? (
    <ConditionalLinkWrapper
      condition={linkToDetails}
      to={`/events/${event.id}/line-up`}
      onClick={() => dispatch(setSelectedEvent(event.id))}
    >
      <Space direction="horizontal" style={{ height: 55 }}>
        {event.header_picture && (
          <Avatar
            shape="square"
            src={event.header_picture}
            style={{ width: 70, height: 45, borderRadius: 3 }}
          />
        )}
        {!event.header_picture && (
          <div
            style={{
              width: 70,
              height: 45,
              borderRadius: 3,
              backgroundColor: '#252525',
              alignItems: 'center',
              alignContent: 'center',
              display: 'flex',
            }}
          >
            <PictureOutlined
              style={{ color: '#333333', margin: 'auto', fontSize: 20 }}
            />
          </div>
        )}

        <Space direction="vertical" size={0}>
          <Text
            className="m-0"
            style={
              ellipsisName
                ? { wordBreak: 'keep-all', width: 80 }
                : { wordBreak: 'keep-all' }
            }
            ellipsis={ellipsisName ? { tooltip: event.name } : false}
          >
            {event.name}
          </Text>
          <Tag
            color={dayjs(event.end_date).isBefore(dayjs()) ? 'orange' : 'green'}
          >
            <Text>{dayjs(event.start_date).format('DD MMM YYYY')}</Text>
          </Tag>
        </Space>
      </Space>
    </ConditionalLinkWrapper>
  ) : (
    <></>
  )
}

export type EventCellType = { Cell: typeof EventCell }

import {
  getSuppliers,
  resetSupplierQueryAndIds,
  selectSuppliers,
  setSupplierQuery,
} from '../../reducers/SupplierReducer'
import { debounce } from 'lodash'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import Search from 'antd/es/input/Search'
import SBTable from '../../components/SBTable'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import HasAccess from '../../components/HasAccess'
import { useEffect, useMemo, useState } from 'react'
import { TableParams } from '../../models/TableParams'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { Row, Col, Space, Card } from 'antd'
import { AlelaPermission } from '../../utils/permissions'
import { Contact } from '../../components/contact/Contact'
import { CheckboxValueType } from 'antd/es/checkbox/Group'
import { Supplier } from '../../components/supplier/Supplier'
import { initialQuery } from '../../utils/helpers/crud/models'
import { Actions } from '../../modules/sbrm/components/Actions'
import { Supplier as SupplierModel } from '../../models/Supplier'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { extractSortDataFromSorterResult } from '../../utils/table/sorter'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { CurrencyFormat } from '@supplement-bacon/alela-uikit'
import { SupplierType } from '../../components/supplier-type/SupplierType'
import { useTenantSettings } from '../../utils/hooks/useTenantSettings'

const SuppliersPage = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const { currency } = useTenantSettings()

  const suppliers = useAppSelector(selectSuppliers())
  const { query, isLoading } = useAppSelector((state) => state.supplier)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const setQueryAndGetSuppliers = (query: TableParams) => {
    dispatch(setSupplierQuery(query))
    dispatch(getSuppliers(query))
  }

  const handleSupplierTypeQueryChanged = (list: CheckboxValueType[]) => {
    const newQuery = {
      ...query, // Contact type must reset page
      pagination: { ...query.pagination, current: 1 },
      filters: { ...query.filters, types: list },
    }
    setQueryAndGetSuppliers(newQuery)
  }

  const handleSearch = (value: string) => {
    const newQuery = {
      ...query,
      ...{ search: { supplier: value } },
    }
    setQueryAndGetSuppliers(newQuery)
  }

  const debouncedHandleSearch = useMemo(
    () => debounce(handleSearch, 500),
    [query]
  )

  const columns: ColumnsType<SupplierModel> = [
    {
      key: 'name',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.CRM.Suppliers.Name,
      }),
      sorter: true,
      defaultSortOrder: 'ascend',
      render: (record: SupplierModel) => (
        <Link to={`/crm/suppliers/${record.id}`}>
          <Supplier.Avatar id={record.id} />
        </Link>
      ),
    },
    {
      key: 'type',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.CRM.Suppliers.Type,
      }),
      render: (record: SupplierModel) => <Supplier.TypeTag id={record.type} />,
    },
    {
      key: 'sumUnpaidAmount',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.CRM.Suppliers.SupplierBalance,
      }),
      render: (record: SupplierModel) => (
        <CurrencyFormat
          amount={record.sumUnpaidAmount}
          currencySymbol={currency}
        />
      ),
    },
    {
      key: 'contacts',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.CRM.Suppliers.Contacts,
      }),
      align: 'center',
      render: (record: SupplierModel) => (
        <Contact.AvatarGroup ids={record.contacts} />
      ),
    },

    {
      key: 'actions',
      align: 'right',
      render: (contact: SupplierModel) => (
        <Actions
          actions={['update', 'delete']}
          entity={SBRMType.supplier}
          entityId={contact.id}
        />
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<SupplierModel> | SorterResult<SupplierModel>[]
  ) => {
    const newQuery: TableParams = {
      ...query,
      pagination,
      filters: {
        ...query.filters,
        ...filters,
      },
      ...extractSortDataFromSorterResult<SupplierModel>(sorter),
    }
    setQueryAndGetSuppliers(newQuery)
  }

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      return
    }
    setIsFirstRender(false)
    // Trigger on init and when SBRM is closed
    const baseQuery: TableParams = {
      ...initialQuery,
      columnKey: 'name',
      order: 'ascend',
      with: ['sumUnpaidAmount', 'type', 'contacts'],
    }
    dispatch(setSupplierQuery(baseQuery))
    dispatch(getSuppliers(baseQuery))
  }, [dispatch, SBRMIsOpen])

  useEffect(() => {
    // Only on init we want to reset ids
    // To avoid seeing old items from previous page table
    dispatch(resetSupplierQueryAndIds({}))
  }, [])

  return (
    <HasAccess permissions={[AlelaPermission.listSupplier]}>
      <PageTitle
        title={intl.formatMessage({
          id: LocalizationKeys.Page.CRM.Suppliers.Title,
        })}
        toolbar={
          <Space>
            <Search
              allowClear
              placeholder={intl.formatMessage({
                id: LocalizationKeys.Page.CRM.Suppliers.Search,
              })}
              onChange={(event) => debouncedHandleSearch(event.target.value)}
            />
            <Actions actions={['create']} entity={SBRMType.supplier} />
          </Space>
        }
      />
      <Row gutter={[16, 16]}>
        <Col md={6} sm={24} xs={24}>
          <Card bordered={false}>
            <HasAccess permissions={[AlelaPermission.listSupplierType]}>
              <SupplierType.ListWithCheckboxes
                onChange={handleSupplierTypeQueryChanged}
              />
            </HasAccess>
          </Card>
        </Col>
        <Col md={18} sm={24} xs={24}>
          <SBTable
            entity={SBRMType.supplier}
            scroll={{ x: 800 }}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={suppliers}
            pagination={query.pagination}
            loading={isLoading && !suppliers.length}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
    </HasAccess>
  )
}

export default SuppliersPage

import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIUpdate,
} from '../utils/helpers/SBAPIHelper'
import { Stage } from '../models/Stage'
import { EVENTS_URL, STAGES_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { stageSchema } from '../models/schema'

const initialState: SBState<Stage> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: {
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },
}

const slice = createSlice({
  name: 'stage',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getStagesSuccess,
  setQuery: setStageQuery,
  reset: resetStageState,
  resetQueryAndIds: resetStageQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: Stage }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectStages = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedStage = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectStageById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectStagesByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedStage =
  (Stage: Stage | number) => async (dispatch: AppDispatch) =>
    dispatch(slice.actions.setSelectedId(getIdOrModelId<Stage>(Stage)))

export const getStages = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<Stage>(
    STAGES_URL,
    params,
    [stageSchema],
    slice.actions
  )

export const createStage = (stage: Stage, venueId: number) =>
  SBAPICreate<any>(
    { ...stage, venue: venueId },
    STAGES_URL,
    stageSchema,
    slice.actions
  )

export const updateStage = (stage: Stage) =>
  SBAPIUpdate<Stage>(stage, `${STAGES_URL}/${stage.id}`, slice.actions)

export const deleteStage = (stage: Stage) =>
  SBAPIDelete<Stage>(stage, `${STAGES_URL}/${stage.id}`, slice.actions)

export const getStagesForEventWithId = (id: number, params: TableParams) =>
  SBAPIFetchPaginatedDispatch<Stage>(
    `${EVENTS_URL}/${id}/stages`,
    params,
    [stageSchema],
    slice.actions
  )

import { createSlice } from '@reduxjs/toolkit'

type State = {
  currentMonth: string | undefined
}

const initialState: State = {
  currentMonth: new Date().toISOString(),
}

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setCurrentMonth(state, action: { payload: string }) {
      state.currentMonth = action.payload
    },
  },
})

// Reducer
export default slice.reducer

export const { setCurrentMonth } = slice.actions

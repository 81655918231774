import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
} from '../utils/helpers/SBAPIHelper'
import { RIDERS_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { riderSchema } from '../models/schema'
import { initialQuery } from '../utils/helpers/crud/models'
import { Rider } from '../models/Rider'

export const initialRiderQuery: TableParams = {
  ...initialQuery,
}

const initialState: SBState<Rider> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: initialRiderQuery,
}

const slice = createSlice({
  name: 'rider',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getRidersSuccess,
  setQuery: setRiderQuery,
  reset: resetRiderState,
  resetQueryAndIds: resetRiderQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: Rider }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectRiders = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectAllNotApprovedRiders = () =>
  createSelector([selectRawItems], (items) =>
    Object.entries(items)
      .map((i) => i[1])
      .filter((i) => i.isApproved === false)
  )
export const selectSelectedRider = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectRiderById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectRidersByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedRider =
  (rider: Rider | number | undefined) => async (dispatch: AppDispatch) =>
    dispatch(
      slice.actions.setSelectedId(
        rider ? getIdOrModelId<Rider>(rider) : undefined
      )
    )

export const getRiders = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<Rider>(
    RIDERS_URL,
    params,
    [riderSchema],
    slice.actions
  )
export const getNonApprovedRiders = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<Rider>(
    RIDERS_URL,
    {
      ...params,
      with: [...(params.with ?? []), 'notApproved'],
    },
    [riderSchema],
    slice.actions,
    slice.actions.startLoading,
    false, // Do no set ids for non approved
    false // Do no set pagination for non approved
  )

export const getRiderWithId = (id: number) =>
  SBAPIFetchDispatch<Rider>(`${RIDERS_URL}/${id}`, riderSchema, slice.actions)

export const createRider = (data: FormData) =>
  SBAPICreate<FormData>(data, RIDERS_URL, riderSchema, slice.actions)

export const updateRider = (data: FormData) =>
  SBAPICreate<FormData>(
    data,
    `${RIDERS_URL}/${data.get('id')}`,
    riderSchema,
    slice.actions
  )

export const approveRider = (rider: Rider) =>
  SBAPICreate<Rider>(
    rider,
    `${RIDERS_URL}/${rider.id}/approve`,
    riderSchema,
    slice.actions
  )

export const deleteRider = (rider: Rider) =>
  SBAPIDelete<Rider>(rider, `${RIDERS_URL}/${rider.id}`, slice.actions)

import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { ContactType } from '../../models/Contact'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { CheckboxValueType } from 'antd/es/checkbox/Group'
import { Badge, Button, Checkbox, List, Skeleton, Space } from 'antd'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import {
  getContactTypes,
  selectContactTypes,
} from '../../reducers/ContactTypeReducer'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { initialQuery } from '../../utils/helpers/crud/models'
import { TableParams } from '../../models/TableParams'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { addUrlParams } from '../../modules/sbrm/UrlHelper'
import { useSearchParams } from 'react-router-dom'

interface Props {
  onChange: (list: CheckboxValueType[]) => void
}

export const ContactListCheckboxes = ({ onChange }: Props) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const { isLoading } = useAppSelector((state) => state.contactType)
  const types = useAppSelector(selectContactTypes())

  const [checkAll, setCheckAll] = useState(false)
  const [indeterminate, setIndeterminate] = useState(true)
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([])

  const onCheckboxesChange = (list: CheckboxValueType[]) => {
    setCheckedList(list)
    setIndeterminate(!!list.length && list.length < types.length)
    setCheckAll(list.length === types.length)
    onChange(list)
  }

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setIndeterminate(false)
    setCheckAll(e.target.checked)
    const newList = e.target.checked
      ? types.map((type: ContactType) => type.id)
      : []
    setCheckedList(newList)
    onChange(newList)
  }

  useEffect(() => {
    const baseQuery: TableParams = {
      ...initialQuery,
      pagination: { current: 1, pageSize: 100 },
    }
    dispatch(getContactTypes(baseQuery))
  }, [])

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {isLoading && !types.length ? (
        <Skeleton />
      ) : (
        <>
          <Checkbox
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}
          >
            {intl.formatMessage({
              id: LocalizationKeys.Components.ContactType.ListCheckboxes.All,
            })}
          </Checkbox>
          <Checkbox.Group
            value={checkedList}
            onChange={onCheckboxesChange}
            className="w-full"
          >
            <List
              className="w-full"
              size="large"
              dataSource={types.sort(
                (a, b) => (b.contactsCount ?? 0) - (a.contactsCount ?? 0)
              )}
              renderItem={(type) => (
                <Space
                  style={{
                    justifyContent: 'space-between',
                    display: 'flex',
                    marginBottom: 5,
                  }}
                >
                  <Checkbox value={type.id}>{type.name}</Checkbox>
                  <Badge count={type.contactsCount} />
                </Space>
              )}
            />
          </Checkbox.Group>
          <Button
            className="w-full"
            type="primary"
            onClick={() =>
              addUrlParams(
                {
                  action: 'create',
                  entity: SBRMType.contactType,
                },
                undefined,
                setSearchParams
              )
            }
          >
            {intl.formatMessage({
              id: LocalizationKeys.Components.ContactType.ListCheckboxes
                .CreateType,
            })}
          </Button>
        </>
      )}
    </Space>
  )
}

export type ContactListCheckboxesType = {
  ListCheckboxes: typeof ContactListCheckboxes
}

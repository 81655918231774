import { FC } from 'react'
import { ReferenceTag, ReferenceTagType } from './ReferenceTag'
import { AmountsTag, AmountsTagType } from './AmountsTag'
import { Table, TableType } from './Table'
import { Filters, FiltersType } from './Filters'
import { InvoiceForm, InvoiceFormType } from './form/Form'
import { ExternalRequests, ExternalRequestsType } from './ExternalRequests'
import { CategoryTag, CategoryTagType } from './CategoryTag'

export const Invoice: FC &
  AmountsTagType &
  ReferenceTagType &
  TableType &
  FiltersType &
  InvoiceFormType &
  ExternalRequestsType &
  CategoryTagType = (): JSX.Element => <></>

Invoice.ReferenceTag = ReferenceTag
Invoice.AmountsTag = AmountsTag
Invoice.Table = Table
Invoice.Filters = Filters
Invoice.Form = InvoiceForm
Invoice.ExternalRequests = ExternalRequests
Invoice.CategoryTag = CategoryTag

import ALMLCell from './ALMCell'
import { useIntl } from 'react-intl'
import { Empty, Space, Spin, Typography } from 'antd'
import { useAppSelector } from '../../../reducers/hooks'
import { selectExternalAdvancingLinks } from '../../../reducers/ExternalAdvancingLinkReducer'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

const { Text } = Typography

interface Props {
  shouldUpdateId: (linkId: string) => void
  shouldDeleteId: (linkId: string) => void
}

const ALMList = ({ shouldUpdateId, shouldDeleteId }: Props) => {
  const intl = useIntl()

  const links = useAppSelector(selectExternalAdvancingLinks())
  const { isLoading } = useAppSelector((state) => state.externalAdvancingLink)

  if (isLoading) {
    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Spin style={{ padding: 50 }} />
      </div>
    )
  }
  if (!isLoading && links.length === 0) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
          <Space.Compact direction="vertical">
            <Text type="secondary">
              {intl.formatMessage({
                id: LocalizationKeys.ExternalAdvancingManager.List.NoLink,
              })}
            </Text>
            <Text type="secondary">
              {intl.formatMessage({
                id: LocalizationKeys.ExternalAdvancingManager.List.UseTheForm,
              })}
            </Text>
          </Space.Compact>
        }
      />
    )
  }

  return (
    <>
      <Space direction="vertical" className="w-full">
        {links.map((link) => (
          <ALMLCell
            link={link}
            shouldUpdateId={shouldUpdateId}
            shouldDeleteId={shouldDeleteId}
            key={link.id}
          />
        ))}
      </Space>
    </>
  )
}

export default ALMList

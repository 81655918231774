import { Space, Tag, Typography } from 'antd'
import { selectEventById } from '../../reducers/EventReducer'
import { useAppSelector } from '../../reducers/hooks'
import dayjs from 'dayjs'
import { Event } from '../../models/Event'
const { Text } = Typography

interface Props {
  id?: number
  model?: Event
}

export const EventOption = ({ id, model }: Props) => {
  const event = model ? model : useAppSelector(selectEventById(id!))

  return event ? (
    <Space direction="horizontal">
      <Tag color={dayjs(event.end_date) < dayjs(Date()) ? 'orange' : 'green'}>
        <Text className="m-0">
          {dayjs(event.start_date).format('DD/MM/YY')}
        </Text>
      </Tag>
      <Text className="m-0" style={{ wordBreak: 'keep-all' }}>
        {event.name}
      </Text>
    </Space>
  ) : (
    <></>
  )
}

export type EventOptionType = { Option: typeof EventOption }

import { useIntl } from 'react-intl'
import { Form, FormInstance } from 'antd'
import { requiredRule } from '../../../utils/rules'
import { File } from '../File'

interface Props {
  form: FormInstance
}

const Create = ({ form }: Props) => {
  const intl = useIntl()

  return (
    <Form form={form} layout="vertical">
      <File.Dropzone name="file" rules={[requiredRule(intl)]} />
    </Form>
  )
}

export default Create

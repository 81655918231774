import { Button, Typography } from 'antd'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { markAllNotificationAsRead } from '../../reducers/NotificationReducer'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'

const { Text } = Typography

interface Props {
  onSuccess: () => void
}

const MarkAllAsRead = ({ onSuccess }: Props) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const { unread } = useAppSelector((state) => state.notification)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  if (unread === undefined || unread === 0) {
    return <></>
  }

  return (
    <Button
      type="default"
      shape="round"
      loading={isLoading}
      onClick={() => {
        setIsLoading(true)
        dispatch(markAllNotificationAsRead()).then(() => {
          setIsLoading(false)
          onSuccess()
        })
      }}
    >
      <Text>
        {intl.formatMessage({
          id: LocalizationKeys.Components.Notification.MarkAllAsRead,
        })}
      </Text>
    </Button>
  )
}

type MarkAllAsReadType = { MarkAllAsRead: typeof MarkAllAsRead }

export { MarkAllAsRead, type MarkAllAsReadType }

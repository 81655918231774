import { useIntl } from 'react-intl'
import { Form, FormInstance, Input } from 'antd'
import { requiredRule } from '../../../utils/rules'
import ColorSelect from '../../../components/custom-inputs/select/ColorSelect'
import { SBAsyncSelect } from '../../../components/custom-inputs/sb-async-select/SBAsyncSelect'
import { Address } from '../../../components/address/Address'
import TextArea from 'antd/es/input/TextArea'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'

interface Props {
  form: FormInstance
}

const Create = ({ form }: Props) => {
  const intl = useIntl()

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="name"
        required
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Name,
        })}
        rules={[requiredRule(intl)]}
      >
        <Input />
      </Form.Item>
      <SBAsyncSelect
        type={SBRMType.venueType}
        name="type"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Type,
        })}
        rules={[requiredRule(intl)]}
        size="middle"
      />
      <Address.Form />
      <ColorSelect
        name="color"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.EventColorOnCalendar,
        })}
        rules={[requiredRule(intl)]}
      />
      <Form.Item
        name="description"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Notes,
        })}
        rules={[]}
      >
        <TextArea rows={5} />
      </Form.Item>
    </Form>
  )
}

export default Create

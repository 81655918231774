import { ReactNode } from 'react'

const I18N_CONFIG_KEY = 'i18nConfig'

type AvailableLocale = 'en' | 'fr'
const isAvailableLocale = (locale: string): locale is AvailableLocale =>
  ['en', 'fr'].includes(locale)

const locales: {
  id: AvailableLocale
  value: AvailableLocale
  label: ReactNode
}[] = [
  {
    id: 'en',
    value: 'en',
    label: '🇺🇸 English',
  },
  {
    id: 'fr',
    value: 'fr',
    label: '🇫🇷 Français',
  },
]

type I18nConfig = {
  selectedLang: AvailableLocale
}
const initialConfig: I18nConfig = {
  selectedLang: 'en',
}

function getConfig(): I18nConfig {
  const ls = localStorage.getItem(I18N_CONFIG_KEY)
  if (!ls) {
    return initialConfig
  }
  try {
    return JSON.parse(ls) as I18nConfig
  } catch (er) {
    return initialConfig
  }
}

function storeConfig(config: I18nConfig): void {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify(config))
}

// Side effect
function setLocale(locale: AvailableLocale) {
  const nextConfig: I18nConfig = { ...getConfig(), selectedLang: locale }
  storeConfig(nextConfig)
  window.location.reload()
}

const useLang = (): {
  config: I18nConfig
  selectedLocale: AvailableLocale
  locales: typeof locales
  setLocale: (locale: AvailableLocale) => void
} => ({
  config: getConfig(),
  selectedLocale: getConfig().selectedLang ?? 'en',
  locales: locales,
  setLocale: setLocale,
})

export { type AvailableLocale, useLang, isAvailableLocale }

import { SBModel } from '../utils/helpers/crud/models'

export interface File extends SBModel {
  name: string
  extension: string
  size: string
  url: string
  downloadUrl: string
  type: number

  // Relation
  event?: number
  booking?: number
  publicLinks?: number[]
  contract?: number
}

export interface FileType extends SBModel {
  name: string
  color: string
  icon: string
}

export enum FileRelation {
  cover = 'cover',
  file = 'file',
  headerPicture = 'headerPicture',
  rider = 'rider',
  contract = 'contract',
}

export function isFile(arg: any): arg is File {
  return (
    arg &&
    arg.name &&
    typeof arg.name === 'string' &&
    arg.url &&
    typeof arg.url === 'string'
  )
}

import { debounce } from 'lodash'
import dayjs, { Dayjs } from 'dayjs'
import Search from 'antd/es/input/Search'
import { useEffect, useMemo } from 'react'
import { useAppSelector } from '../../reducers/hooks'
import { TableParams } from '../../models/TableParams'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { SBAsyncSelect } from '../custom-inputs/sb-async-select/SBAsyncSelect'
import { Col, DatePicker, Form, Radio, Row, Select, Space } from 'antd'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'

const { RangePicker } = DatePicker

interface Props {
  queryChanged: (query: TableParams) => void
}

export const Filters = ({ queryChanged }: Props) => {
  const intl = useIntl()
  const [form] = Form.useForm()

  const { query } = useAppSelector((state) => state.invoice)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const rangePresets: {
    label: string
    value: [Dayjs, Dayjs]
  }[] = [
    {
      label: intl.formatMessage({
        id: LocalizationKeys.Components.Invoice.Filters.Last7Days,
      }),
      value: [dayjs().subtract(7, 'd'), dayjs()],
    },
    {
      label: intl.formatMessage({
        id: LocalizationKeys.Components.Invoice.Filters.Last14Days,
      }),
      value: [dayjs().subtract(14, 'd'), dayjs()],
    },
    {
      label: intl.formatMessage({
        id: LocalizationKeys.Components.Invoice.Filters.Last30Days,
      }),
      value: [dayjs().subtract(30, 'd'), dayjs()],
    },
    {
      label: intl.formatMessage({
        id: LocalizationKeys.Components.Invoice.Filters.Last90Days,
      }),
      value: [dayjs().subtract(90, 'd'), dayjs()],
    },
    {
      label: intl.formatMessage({
        id: LocalizationKeys.Components.Invoice.Filters.Last1Year,
      }),
      value: [dayjs().subtract(365, 'd'), dayjs()],
    },
  ]

  const composeFilteredQueryAndGetInvoices = (): void => {
    const sort = form.getFieldValue('sort') ?? 'due_date'

    /**
     * Always defning both attributes to ensure no past value will be kept
     */
    const dateFilters: (dates: any) => { dueDate: any; eventDate: any } = (
      dates
    ) => {
      return !dates
        ? { dueDate: null, eventDate: null }
        : ({
            [sort === 'due_date' ? 'dueDate' : 'eventDate']: {
              start: dates[0].format('YYYY-MM-DD'),
              end: dates[1].format('YYYY-MM-DD'),
            },
            [sort === 'due_date' ? 'eventDate' : 'dueDate']: null,
          } as { dueDate: any; eventDate: any })
    }

    const filteredQuery: TableParams = {
      ...query,
      filters: {
        ...query.filters,
        ...dateFilters(form.getFieldValue('filterDates')),
        paymentStatus:
          form.getFieldValue('paymentStatus') !== 'all'
            ? form.getFieldValue('paymentStatus')
            : undefined,
        venues: form.getFieldValue('venues')
          ? form.getFieldValue('venues')
          : undefined,
      },
      search: form.getFieldValue('search')
        ? { invoice: form.getFieldValue('search') }
        : undefined,
      with: ['sumPaid', 'supplier', 'category', 'event'],
      columnKey: sort,
      order: 'descend',
    }

    queryChanged(filteredQuery)
  }

  const handleFormChange = (changes: any, values: any) => {
    if ('search' in changes) {
      debouncedHandleForm()
    } else {
      composeFilteredQueryAndGetInvoices()
    }
  }

  const handleSearchChange = () => {
    const queryWithSearch: TableParams = {
      ...query,
      search: { invoice: form.getFieldValue('search') },
    }
    queryChanged(queryWithSearch)
  }

  const debouncedHandleForm = useMemo(
    () => debounce(handleSearchChange, 500),
    [query]
  )

  useEffect(() => {
    if (SBRMIsOpen) {
      return
    }
    // Trigger on init and when SBRM is closed
    composeFilteredQueryAndGetInvoices()
  }, [SBRMIsOpen])

  return (
    <Form
      className="hide-mobile"
      layout="vertical"
      form={form}
      initialValues={{
        sort: 'due_date',
        paymentStatus: 'all',
        venues: [],
      }}
      onValuesChange={handleFormChange}
    >
      <Row gutter={[16, 16]} wrap={false}>
        <Col>
          <Form.Item name="paymentStatus" label="Status">
            <Radio.Group className="w-full">
              <Radio.Button value="paid">
                {intl.formatMessage({
                  id: LocalizationKeys.Components.Invoice.Filters.Paid,
                })}
              </Radio.Button>
              <Radio.Button value="partially_paid">
                {intl.formatMessage({
                  id: LocalizationKeys.Components.Invoice.Filters.PartiallyPaid,
                })}
              </Radio.Button>
              <Radio.Button value="unpaid">
                {intl.formatMessage({
                  id: LocalizationKeys.Components.Invoice.Filters.Unpaid,
                })}
              </Radio.Button>
              <Radio.Button value="all">
                {intl.formatMessage({
                  id: LocalizationKeys.Components.Invoice.Filters.All,
                })}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={4}>
          <SBAsyncSelect
            multiple
            name="venues"
            label={intl.formatMessage({
              id: LocalizationKeys.Components.Invoice.Filters.Venue,
            })}
            type={SBRMType.venue}
            style={{ width: '100%' }}
            size="middle"
            placeholder={intl.formatMessage({
              id: LocalizationKeys.Components.Invoice.Filters.Venue,
            })}
            outsideSBRM
          />
        </Col>
        <Col span={8}>
          <Form.Item label="Date">
            <Space.Compact direction="horizontal" className="w-full">
              <Form.Item noStyle name={'sort'} label="Date">
                <Select style={{ maxWidth: 130 }}>
                  <Select.Option value="due_date">
                    {intl.formatMessage({
                      id: LocalizationKeys.Components.Invoice.Filters.DueDate,
                    })}
                  </Select.Option>
                  <Select.Option value="event_date">
                    {intl.formatMessage({
                      id: LocalizationKeys.Components.Invoice.Filters.EventDate,
                    })}
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item noStyle name={'filterDates'} label=" ">
                <RangePicker format="DD-MM-YYYY" presets={rangePresets} />
              </Form.Item>
            </Space.Compact>
          </Form.Item>
        </Col>
        <Col flex={'auto'}>
          <Form.Item
            name="search"
            label={intl.formatMessage({
              id: LocalizationKeys.Components.Invoice.Filters.Search,
            })}
          >
            <Search
              placeholder={intl.formatMessage({
                id: LocalizationKeys.Components.Invoice.Filters
                  .SearchPlaceholder,
              })}
              allowClear
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export type FiltersType = { Filters: typeof Filters }

import { Card, InputRef, Tag } from 'antd'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { VenueType } from '../../models/VenueType'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import {
  getVenueTypes,
  selectVenueTypes,
  setVenueTypeQuery,
} from '../../reducers/VenueTypeReducer'
import getColumnSearchProps from '../../utils/table/getColumnSearchProps'
import { Actions } from '../../modules/sbrm/components/Actions'
import { extractSortDataFromSorterResult } from '../../utils/table/sorter'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { Venue } from '../../components/venue/Venue'
import SBTable from '../../components/SBTable'
import LocalizationKeys from '../../i18n/LocalizationKeys'

const VenueTypesPage = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const searchInput = useRef<InputRef>(null)
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const types = useAppSelector(selectVenueTypes())
  const { query, isLoading } = useAppSelector((state) => state.venueType)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const handleSearch = (selectedKeys: string[], dataIndex: keyof VenueType) => {
    const newQuery = {
      ...query,
      ...{ search: { [dataIndex]: selectedKeys[0] } },
    }
    dispatch(setVenueTypeQuery(newQuery))
    dispatch(getVenueTypes(newQuery))
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    const newQuery = {
      ...query,
      ...{ search: { dataIndex: null } },
    }
    dispatch(setVenueTypeQuery(newQuery))
    dispatch(getVenueTypes(newQuery))
  }

  const columns: ColumnsType<VenueType> = [
    {
      key: 'name',
      title: intl.formatMessage({ id: LocalizationKeys.Misc.Table.Name }),
      ...getColumnSearchProps<VenueType>(
        'name',
        searchInput,
        handleSearch,
        handleReset,
        intl,
        isLoading
      ),
      render: (type: VenueType) => <Venue.TypeTag id={Number(type.id)} />,
    },
    {
      key: 'color',
      title: intl.formatMessage({ id: LocalizationKeys.Misc.Table.Color }),
      render: (type: VenueType) => <Tag>{type.color}</Tag>,
    },
    {
      key: 'icon',
      title: intl.formatMessage({ id: LocalizationKeys.Misc.Table.Icon }),
      render: (type: VenueType) => <Tag>{type.icon}</Tag>,
    },
    {
      key: 'actions',
      align: 'right',
      render: (venue: VenueType) => (
        <Actions
          actions={['update', 'delete']}
          entity={SBRMType.venueType}
          entityId={venue.id}
        />
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<VenueType> | SorterResult<VenueType>[]
  ) => {
    const newQuery = {
      ...query,
      pagination,
      filters,
      ...extractSortDataFromSorterResult(sorter),
    }
    dispatch(setVenueTypeQuery(newQuery))
    dispatch(getVenueTypes(newQuery))
  }

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      return
    }
    setIsFirstRender(false)

    // Trigger when SBRM is closed and on init
    dispatch(getVenueTypes(query))
  }, [dispatch, SBRMIsOpen])

  return (
    <Card
      bordered={false}
      styles={{ body: { padding: 0 } }}
      title={
        <PageTitle
          title={intl.formatMessage({
            id: LocalizationKeys.Page.Settings.VenueTypes,
          })}
          level={5}
          toolbar={<Actions actions={['create']} entity={SBRMType.venueType} />}
        />
      }
    >
      <SBTable
        entity={SBRMType.venueType}
        scroll={{ x: 600 }}
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={types}
        pagination={query.pagination}
        loading={isLoading}
        onChange={handleTableChange}
      />
    </Card>
  )
}

export default VenueTypesPage

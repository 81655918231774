import { useIntl } from 'react-intl'
import { DatePicker, Form, FormInstance, Input } from 'antd'
import { requiredRule } from '../../../utils/rules'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { SBAsyncSelect } from '../../../components/custom-inputs/sb-async-select/SBAsyncSelect'
import { SBRMType, getMetadata } from '../../../modules/sbrm/SBRMModel'
import { useAppSelector } from '../../../reducers/hooks'
import { selectVenueById } from '../../../reducers/VenueReducer'
import { MetaDataKey } from '../../../models/MetaData'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

const { RangePicker } = DatePicker

interface Props {
  form: FormInstance
}

const Create = ({ form }: Props) => {
  const intl = useIntl()

  /**
   * When selecting a venue, we set the event date to the venue defaultOpeningRange
   * 1. Listening to selected venues changes
   * 2. Fetching the latest selected venue
   * 3. Setting time if the venue has defaultOpeningRange filled
   */
  const venues = Form.useWatch('venues', form) // 1.
  const [lastVenueId, setLastVenueId] = useState<number>(0) // 2.
  const lastVenue = useAppSelector(selectVenueById(lastVenueId)) // 2.

  const { isOpen: SBRMIsOpen, metadata } = useAppSelector((state) => state.SBRM)

  useEffect(() => {
    if (!SBRMIsOpen) {
      // We want to trigger only the reset
      // when the SBRM opens
      return
    }

    setLastVenueId(0)
  }, [SBRMIsOpen])

  useEffect(() => {
    const selectedDate = getMetadata(metadata, MetaDataKey.selectedDate)
    if (!selectedDate) {
      // If no date is selected do not fill the form
      return
    }
    form.setFieldValue('dates', [
      dayjs(selectedDate).hour(22).minute(0),
      dayjs(selectedDate).hour(22).minute(0).add(8, 'hour'),
    ])
  }, [metadata])

  // 1.
  useEffect(() => {
    if (!venues || !venues.length) {
      return
    }
    setLastVenueId(venues[venues.length - 1])
  }, [venues])

  // 3.
  useEffect(() => {
    // Ensure we have all the data we need
    if (
      !lastVenue ||
      !lastVenue.defaultOpeningRange ||
      !lastVenue.defaultOpeningRange.start
    ) {
      return
    }

    const currentDate = form.getFieldValue('dates') ?? dayjs()

    // Convert defaultOpeningRange to dayjs for easy manipulation
    const startTime = dayjs(lastVenue?.defaultOpeningRange?.start, 'HH:mm')
    const endTime = dayjs(lastVenue?.defaultOpeningRange?.end, 'HH:mm')

    // Keep next end date in a variable so can add one day easily if endIsNextDay is true
    const nextEndDate = dayjs(currentDate[0])
      .hour(endTime.hour())
      .minute(endTime.minute())

    // Set next event dates
    form.setFieldValue('dates', [
      dayjs(currentDate[0]).hour(startTime.hour()).minute(startTime.minute()),
      lastVenue?.defaultOpeningRange.endIsNextDay
        ? nextEndDate.add(1, 'day')
        : nextEndDate,
    ])
  }, [lastVenue])

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="name"
        rules={[requiredRule(intl)]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Name })}
      >
        <Input showCount maxLength={60} />
      </Form.Item>

      <SBAsyncSelect
        type={SBRMType.venue}
        name="venues"
        multiple={true}
        rules={[requiredRule(intl)]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Venues })}
      />

      <Form.Item
        name="dates"
        required
        rules={[requiredRule(intl)]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Dates })}
      >
        <RangePicker
          className="w-full"
          showTime={{ format: 'HH:mm', minuteStep: 15 }}
          format="DD/MM/YYYY HH:mm"
        />
      </Form.Item>
    </Form>
  )
}

export default Create

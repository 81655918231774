import Create from './Create'
import Update from './Update'
import Delete from './Delete'
import { Alert, Form } from 'antd'
import { MetaDataKey } from '../../../models/MetaData'
import { forwardRef, Ref, useImperativeHandle } from 'react'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import {
  createTravel,
  deleteTravel,
  updateTravel,
} from '../../../reducers/TravelReducer'
import dayjs from 'dayjs'
import { useSBRMData } from '../../../modules/sbrm/hooks'
import {
  getMetadata,
  SBRMFormInterface,
  SBRMFormProps,
} from '../../../modules/sbrm/SBRMModel'
import { getFormDataFrom } from '../../../utils/helpers/FormDataHelper'

export const TravelForm = forwardRef(
  ({ isNested }: SBRMFormProps, ref: Ref<SBRMFormInterface>) => {
    useImperativeHandle(ref, () => ({
      handleCreate,
      handleUpdate,
      handleDelete,
      handleReset,
    }))

    const dispatch = useAppDispatch()
    const { action, metadata } = useSBRMData(isNested)
    const { error } = useAppSelector((state) => state.travel)

    const [createForm] = Form.useForm()
    const [updateForm] = Form.useForm()
    const [deleteForm] = Form.useForm()

    const handleCreate = async () =>
      await createForm.validateFields().then(async (result) => {
        const data = getFormDataFrom(createForm.getFieldsValue())

        // In case there is no contact drop the field
        if (!createForm.getFieldValue('contacts')) {
          data.delete('contacts')
        }
        // Refactor dates
        data.set(
          'departureTime',
          dayjs(createForm.getFieldValue('departureTime')).toISOString()
        )
        data.set(
          'arrivalTime',
          dayjs(createForm.getFieldValue('arrivalTime')).toISOString()
        )
        // Files to upload
        if (
          createForm.getFieldValue('files') &&
          createForm.getFieldValue('files').fileList
        ) {
          data.delete('files') // Drop raw upload
          createForm
            .getFieldValue('files')
            .fileList.map((file: any) =>
              data.append('uploads[]', file.originFileObj)
            )
        }

        return dispatch(createTravel(data)).then(() => {
          createForm.resetFields()
          return true
        })
      })

    const handleUpdate = async () =>
      await updateForm.validateFields().then(async (result) => {
        const data = getFormDataFrom(updateForm.getFieldsValue())

        // In case there is no contact drop the field
        if (!updateForm.getFieldValue('contacts')) {
          data.delete('contacts')
        }

        // Refactor dates
        data.set(
          'departureTime',
          dayjs(createForm.getFieldValue('departureTime')).toISOString()
        )
        data.set(
          'arrivalTime',
          dayjs(createForm.getFieldValue('arrivalTime')).toISOString()
        )

        // Files to upload
        if (
          createForm.getFieldValue('files') &&
          createForm.getFieldValue('files').fileList
        ) {
          data.delete('files') // Drop raw upload
          createForm
            .getFieldValue('files')
            .fileList.map((file: any) =>
              data.append('uploads[]', file.originFileObj)
            )
        }

        return dispatch(updateTravel(data)).then(() => {
          updateForm.resetFields()
          return true
        })
      })

    const handleDelete = async () =>
      await deleteForm.validateFields().then(async (result) =>
        dispatch(deleteTravel(deleteForm.getFieldsValue())).then(() => {
          deleteForm.resetFields()
          return true
        })
      )

    const handleReset = () => {
      createForm.resetFields()
      updateForm.resetFields()
      deleteForm.resetFields()
    }

    return (
      <>
        {(action === 'create' || action === 'duplicate') && (
          <Create
            form={createForm}
            duplicate={action === 'duplicate'}
            eventId={getMetadata(metadata, MetaDataKey.eventId)}
            bookingId={getMetadata(metadata, MetaDataKey.bookingId)}
          />
        )}
        {action === 'update' && <Update form={updateForm} />}
        {action === 'delete' && <Delete form={deleteForm} />}
        {error && <Alert message={String(error)} type="error" />}
      </>
    )
  }
)

TravelForm.displayName = 'TravelForm'

export type TravelFormType = { Form: typeof TravelForm }

const EVENT_SETTINGS_CONFIG_KEY = 'alela-event-display'

interface EventSettings {
  color: 'venue' | 'status'
}

const initialSettings: EventSettings = {
  color: 'venue',
}

const loadSettings = (): EventSettings => {
  const ls = localStorage.getItem(EVENT_SETTINGS_CONFIG_KEY)
  if (!ls) return initialSettings

  try {
    return JSON.parse(ls) as EventSettings
  } catch (e) {
    return initialSettings
  }
}

const updateSettings = (settings: EventSettings): void => {
  localStorage.setItem(EVENT_SETTINGS_CONFIG_KEY, JSON.stringify(settings))
}

export { type EventSettings, loadSettings, updateSettings }

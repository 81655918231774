import { Button, Col, Form, Row, Select } from 'antd'
import { useEffect, useState } from 'react'
import { AvailableLocale, isAvailableLocale, useLang } from '../../i18n/useLang'
import { useIntl } from 'react-intl'
import axios from 'axios'
import { USER_PREFERENCES_LOCALE } from '../../utils/urls'
import LocalizationKeys from '../../i18n/LocalizationKeys'

interface Props {}

export const Locale = ({}: Props) => {
  const intl = useIntl()
  const { locales, selectedLocale, setLocale } = useLang()

  const [formLocale] = Form.useForm()
  const [localeIsLoading, setLocaleIsLoading] = useState<boolean>(false)

  const handleLocaleFormSubmit = () => {
    let nextLocale = formLocale.getFieldValue('locale')
    if (!isAvailableLocale(nextLocale)) {
      nextLocale = 'en'
    }
    handleLocaleSubmit(nextLocale, setLocaleIsLoading, setLocale)
  }

  useEffect(() => {
    formLocale.setFieldValue(
      'locale',
      locales.find((l) => selectedLocale === l.id)?.id
    )
  }, [selectedLocale])

  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Form form={formLocale}>
          <Form.Item name="locale">
            <Select
              disabled={localeIsLoading}
              options={locales.map((l) => ({
                value: l.id,
                label: l.label,
              }))}
            />
          </Form.Item>
        </Form>
      </Col>
      <Col span={12}>
        <Button
          type="primary"
          htmlType="submit"
          loading={localeIsLoading}
          onClick={handleLocaleFormSubmit}
        >
          {intl.formatMessage({
            id: LocalizationKeys.Misc.Button.Update,
          })}
        </Button>
      </Col>
    </Row>
  )
}

export type LocaleType = { Locale: typeof Locale }

export const handleLocaleSubmit = (
  locale: AvailableLocale,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setLocale: (locale: AvailableLocale) => void
) => {
  setIsLoading(true)

  axios
    .put(USER_PREFERENCES_LOCALE, { locale: locale })
    .then(() => {
      setLocale(locale)
      setIsLoading(false)
    })
    .catch(() => {
      setIsLoading(false)
    })
}

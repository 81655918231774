import { useIntl } from 'react-intl'
import { Form, FormInstance, Input, Space, Typography } from 'antd'
import { requiredRule } from '../../../utils/rules'
import { useAppSelector } from '../../../reducers/hooks'
import { useEffect } from 'react'
import { getMetadata } from '../SBRMModel'
import dayjs from 'dayjs'
import { selectSelectedExternalAdvancingLink } from '../../../reducers/ExternalAdvancingLinkReducer'
import { DatePickerWithEventOutlined } from '../../../components/custom-inputs/DatePickerWithEventOutlined'
import { selectEventById } from '../../../reducers/EventReducer'
import { MetaDataKey } from '../../../models/MetaData'
import SectionFormItem from './components/SectionFormItem'
import { externalAdvancingSections } from '../../external-advancing/types'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

const { Text } = Typography
interface Props {
  form: FormInstance
  eventId?: number
}

const Update = ({ form, eventId }: Props) => {
  const intl = useIntl()

  const { metadata } = useAppSelector((state) => state.SBRM)
  const item = useAppSelector(selectSelectedExternalAdvancingLink())
  const event = useAppSelector(
    selectEventById(getMetadata(metadata, MetaDataKey.eventId) ?? eventId)
  )

  useEffect(() => {
    if (item === undefined) return

    form.setFieldsValue(item)
    item?.deadline && form.setFieldValue('deadline', dayjs(item?.deadline))

    externalAdvancingSections.map((section) => {
      form.setFieldValue(section.itemName, item[section.propertyName])
      if (section.hasMaxValue === true && item[section.maxValuePropertyName!]) {
        form.setFieldValue(
          section.maxValueItemName,
          item[section.maxValuePropertyName!]
        )
      }
    })
  }, [item])

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="id"
        rules={[requiredRule(intl)]}
        style={{ display: 'none' }}
      >
        <Input type="hidden" />
      </Form.Item>
      <Space direction="vertical" className="w-full">
        <Text style={{ margin: 0 }}>
          {intl.formatMessage({
            id: LocalizationKeys.ExternalAdvancingManager.Create
              .RestrictionsAndCapabilities,
          })}
        </Text>
        {externalAdvancingSections.map((section) => (
          <SectionFormItem key={section.key} section={section} />
        ))}

        {event !== undefined && (
          <Form.Item
            style={{ marginTop: 15 }}
            name={'deadline'}
            label={intl.formatMessage({
              id: LocalizationKeys.ExternalAdvancingManager.Create
                .DeadlineLabel,
            })}
            extra={intl.formatMessage({
              id: LocalizationKeys.ExternalAdvancingManager.Create
                .DeadlineDescription,
            })}
          >
            <DatePickerWithEventOutlined event={event} />
          </Form.Item>
        )}
      </Space>
    </Form>
  )
}

export default Update

import { useEffect, useRef, useState } from 'react'
import { Button, InputRef } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { PlusOutlined } from '@ant-design/icons'

interface Props {
  columnId: number
  onSubmit: (content: string) => void
}

export const KanbanQuickForm = ({ columnId, onSubmit }: Props) => {
  const [createMode, setCreateMode] = useState<boolean>(false)
  const inputRef = useRef<InputRef>(null)

  const [content, setContent] = useState<undefined | string>(undefined)

  useEffect(() => {
    createMode &&
      inputRef.current &&
      inputRef.current!.focus({
        cursor: 'start',
      })
  }, [createMode])

  const handleSubmit = () => {
    setCreateMode(!createMode)
    if (content) {
      onSubmit(content)
      setContent(undefined)
    }
  }
  return (
    <>
      {createMode ? (
        <TextArea
          ref={inputRef}
          placeholder="Title"
          variant="filled"
          onPressEnter={handleSubmit}
          allowClear={true}
          onChange={(v) => setContent(v.target.value)}
        />
      ) : (
        <Button
          type="text"
          icon={<PlusOutlined />}
          onClick={() => setCreateMode(!createMode)}
        >
          Add a task
        </Button>
      )}
    </>
  )
}

export type KanbanQuickFormType = { QuickForm: typeof KanbanQuickForm }

import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Tooltip,
  Typography,
} from 'antd'
import {
  getLoggedUserAccountInfos,
  getLoggedUserInfos,
  login,
} from '../../utils/auth/requests'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import * as authHelper from '../../utils/helpers/AuthLocalStorageHelper'
import { useAuth } from '../../utils/auth/Auth'
import { useNavigate } from 'react-router-dom'
import { emailRule, requiredRule, stringRule } from '../../utils/rules'
import { QuestionCircleTwoTone } from '@ant-design/icons'
import { SBAPIFetch } from '../../utils/helpers/SBAPIHelper'
import { SAML_SSO_KEYS } from '../../utils/urls'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from '../../utils/saml-sso/authConfig'
import { useAzureSSO } from '../../utils/saml-sso/AzureSSOContext'
import { Tenant } from '../../models/Tenant'
import LocalizationKeys from '../../i18n/LocalizationKeys'

const { Text } = Typography

const LoginPage = () => {
  const { instance } = useMsal()
  const { setSSODetails } = useAzureSSO()
  const intl = useIntl()
  const navigate = useNavigate()
  const { saveAuth, setCurrentUser, setIsLogged, saveTenant, setTenants } =
    useAuth()

  const [emailData, setEmailData] = useState({ email: '', password: '' })
  const [ssoData, setSsoData] = useState({ name: '' })
  const [ssoLoginButtonVisible, setSsoLoginButtonVisible] =
    useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)

  const [loginMethod, setLoginMethod] = useState<'email' | 'sso'>('email')

  const onEmailLoginSubmit = async () => {
    setIsLoading(true)
    setError(undefined)
    try {
      const { data: auth } = await login(emailData.email, emailData.password)
      saveAuth(auth)
      const { data: accountInfo } = await getLoggedUserAccountInfos()
      if (accountInfo.data && accountInfo.data.tenants) {
        /**
         * Store all tenant attached to the user
         * This allow to display the tenant selection in top right menu
         */
        setTenants(accountInfo.data.tenants)

        /**
         * Then we need to set the current tenant
         * So we first check in the local storage
         * If there is one and this one is present in the tenants we get above
         * => This one will be the current
         *
         * Otherwise we set the first of the ones we get above
         */
        const localStorageTenant = authHelper.getAuthTenant()
        if (
          accountInfo.data.tenants
            .map((tenant: Tenant) => tenant.id)
            .includes(localStorageTenant?.id ?? '')
        ) {
          saveTenant(localStorageTenant)
        } else {
          saveTenant(accountInfo.data.tenants[0])
        }
      }
      const { data: user } = await getLoggedUserInfos()
      setIsLogged(true)
      setCurrentUser(user.data)
      const queryParameters = new URLSearchParams(window.location.search)
      const returnTo = queryParameters.get('returnTo')
      if (returnTo) {
        navigate(returnTo)
      }
    } catch (error) {
      saveAuth(undefined)
      setError(intl.formatMessage({ id: LocalizationKeys.Auth.Login.Error }))
      setIsLoading(false)
    }
  }

  const onSAMLSSOSubmit = () => {
    setIsLoading(true)
    setError(undefined)
    setSsoLoginButtonVisible(false)

    SBAPIFetch(`${SAML_SSO_KEYS}/${ssoData.name}`)
      .then(async (response) => {
        setSSODetails(response.data)
        setSsoLoginButtonVisible(true)
      })
      .catch((error) => {
        setError(
          intl.formatMessage({ id: LocalizationKeys.Auth.Login.SSOError })
        )
        setIsLoading(false)
      })
  }

  const SSOTriggerLogin = async () => {
    instance.loginRedirect(loginRequest)
  }

  const emailForm = (
    <>
      <Form
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onEmailLoginSubmit}
        onValuesChange={(_, allFields) => setEmailData(allFields)}
      >
        <Form.Item name="email" rules={[requiredRule(intl), emailRule(intl)]}>
          <Input
            placeholder={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Email,
            })}
            autoComplete="email"
          />
        </Form.Item>

        <Form.Item name="password" rules={[requiredRule(intl)]}>
          <Input.Password
            placeholder={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Password,
            })}
            autoComplete="current-password"
          />
        </Form.Item>
        <Row>
          <Col span={12}>
            <Form.Item name="remember" valuePropName="checked">
              <Checkbox>
                {intl.formatMessage({
                  id: LocalizationKeys.Misc.Form.RememberMe,
                })}
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Button type="text" onClick={() => setLoginMethod('sso')}>
              {intl.formatMessage({ id: LocalizationKeys.Auth.Login.SSO })}
            </Button>
          </Col>
        </Row>

        <Form.Item style={{ marginBottom: 15 }}>
          <Button
            className="w-full"
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            {intl.formatMessage({ id: LocalizationKeys.Auth.Login.Login })}
          </Button>
        </Form.Item>
      </Form>
      <Button
        style={{ color: '#fff' }}
        className="w-full"
        type="link"
        onClick={() => navigate('/auth/forgot-password')}
      >
        {intl.formatMessage({ id: LocalizationKeys.Auth.Login.ForgotPassword })}
      </Button>
    </>
  )

  const ssoForm = (
    <>
      {!ssoLoginButtonVisible && (
        <Form
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onSAMLSSOSubmit}
          onValuesChange={(_, allFields) => setSsoData(allFields)}
        >
          <Form.Item
            name="name"
            label={
              <>
                Organization name
                <Tooltip
                  title={
                    <Text>
                      The organization name given by your administrator
                    </Text>
                  }
                >
                  <QuestionCircleTwoTone
                    style={{ fontSize: '18px', marginLeft: 5 }}
                  />
                </Tooltip>
              </>
            }
            rules={[requiredRule(intl), stringRule(intl)]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              className="w-full"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              {intl.formatMessage({
                id: LocalizationKeys.Auth.Login.ContinueWithSSO,
              })}
            </Button>
          </Form.Item>
        </Form>
      )}
      {ssoLoginButtonVisible && (
        <Button
          className="w-full"
          type="primary"
          htmlType="submit"
          onClick={SSOTriggerLogin}
        >
          {intl.formatMessage({
            id: LocalizationKeys.Auth.Login.LoginWithMicrosoft,
          })}
        </Button>
      )}
      <Button
        className="w-full"
        type="text"
        onClick={() => setLoginMethod('email')}
      >
        {intl.formatMessage({
          id: LocalizationKeys.Auth.Login.EmailConnexion,
        })}
      </Button>
    </>
  )

  return (
    <>
      {loginMethod === 'email' && emailForm}
      {loginMethod === 'sso' && ssoForm}
      <div style={{ textAlign: 'center', marginTop: 10 }}>
        {error && (
          <Alert style={{ marginBottom: 25 }} message={error} type="error" />
        )}
      </div>
    </>
  )
}

export default LoginPage

import { Button } from 'antd'
import { useEffect } from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons'
import axios from 'axios'
import { TENANT_URL } from '../../utils/urls'
import { useAuth } from '../../utils/auth/Auth'

export const Toogle = () => {
  const { currentUser } = useAuth()

  useEffect(() => {
    if (currentUser === undefined) return
    axios.get(`${TENANT_URL}/beacon-secret`).then((res) => {
      window.Beacon('identify', {
        name: currentUser.firstName + ' ' + currentUser.lastName,
        email: currentUser.email,
        signature: res.data.data,
      })
    })
  }, [currentUser])

  return (
    <Button
      type="text"
      icon={<QuestionCircleOutlined />}
      onClick={() => window.Beacon('toggle')}
    />
  )
}

export type ToogleType = { Toogle: typeof Toogle }

import {
  getLoggedUserAccountInfos,
  getLoggedUserInfos,
} from './../auth/requests'
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
  BrowserUtils,
} from '@azure/msal-browser'
import * as authHelper from '../helpers/AuthLocalStorageHelper'
import { FC, PropsWithChildren, useEffect, useState } from 'react'
import { useAuth } from '../auth/Auth'
import { useAppDispatch } from '../../reducers/hooks'

// MSAL imports
import { msalConfig } from './authConfig'
import ClientSideNavigation from './ClientSideNavigation'
import { MsalProvider, useMsal } from '@azure/msal-react'
import { AzureSSOContext, useAzureSSO } from './AzureSSOContext'
import { AuthModel, SSODetails } from '../../models/AuthModel'
import { resetStore } from '../helpers/AuthHelper'
import { forgetCurrentDevice } from '../firebase'

function AzureSSOProvider({ children }: PropsWithChildren) {
  const dispatch = useAppDispatch()
  const { setCurrentUser, saveTenant } = useAuth()

  const [auth, setAuth] = useState<AuthModel | undefined>(
    authHelper.getSSOAuth()
  )
  const [isLogged, setIsLogged] = useState<boolean | undefined>(undefined)
  const [msalInstance, setMsalInstance] = useState<PublicClientApplication>(
    new PublicClientApplication(msalConfig)
  )

  useEffect(() => {
    // Optional - This will update account state if a user signs in from another tab or window
    msalInstance.enableAccountStorageEvents()

    msalInstance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult
        const account = payload.account
        msalInstance.setActiveAccount(account)
        setIsLogged(true)
      }
    })
  }, [msalInstance])

  const setSSODetails = (details: SSODetails) => {
    setMsalInstance(
      new PublicClientApplication({
        ...msalConfig,
        auth: {
          ...msalConfig.auth,
          clientId: details.clientId,
          authority: details.authority,
        },
      })
    )
    authHelper.setSSODetails(details)
  }

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setSSOAuth(auth)
    } else {
      authHelper.removeSSOAuth()
    }
  }

  const logout = () => {
    /**
     * Clear FCM Token
     * Need to be done first as it's an API request
     */
    forgetCurrentDevice().then(logoutHandler).catch(logoutHandler)
  }

  const logoutHandler = () => {
    setIsLogged(false)
    setCurrentUser(undefined)
    saveAuth(undefined)
    saveTenant(undefined)
    dispatch(resetStore())
    authHelper.removeSSODetails()
    msalInstance.logoutRedirect({
      account: msalInstance.getActiveAccount(),
      onRedirectNavigate: () => !BrowserUtils.isInIframe(),
    })
    window.Beacon('logout')
  }

  return (
    <AzureSSOContext.Provider
      value={{ auth, isLogged, saveAuth, logout, setSSODetails, setIsLogged }}
    >
      <ClientSideNavigation pca={msalInstance}>
        <MsalProvider instance={msalInstance}>{children}</MsalProvider>
      </ClientSideNavigation>
    </AzureSSOContext.Provider>
  )
}

const AzureSSOInit: FC<PropsWithChildren> = ({ children }) => {
  const { instance } = useMsal()
  const { auth, setIsLogged } = useAzureSSO()
  const { setCurrentUser, saveTenant } = useAuth()

  useEffect(() => {
    // First of all if there isn't any token the user is obvisouly not logged
    if (!auth || !auth.access_token) {
      setIsLogged(false)
      return
    }

    // If there is no account at all we can safely assume that the user is not logged
    if (instance.getAllAccounts().length === 0) {
      setIsLogged(false)
      return
    }

    // Then in case there is no active account we must set one
    if (!instance.getActiveAccount() && instance.getAllAccounts().length > 0) {
      instance.setActiveAccount(instance.getAllAccounts()[0])
    }

    /**
     * At this point we are sure to have a proper active account
     * We can set isLogged and start account infos fetching...
     */
    setIsLogged(true)

    const fetchUserInfos = async () => {
      const { data: accountInfo } = await getLoggedUserAccountInfos()
      if (accountInfo.data && accountInfo.data.tenants) {
        saveTenant(accountInfo.data.tenants[0])
      }
      const { data } = await getLoggedUserInfos()
      if (data) {
        setCurrentUser(data.data)
      }
    }

    fetchUserInfos()
  }, [])

  return <>{children}</>
}

export { AzureSSOProvider, AzureSSOInit }

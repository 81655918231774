import { Pie } from '@ant-design/plots'
import { useTenantSettings } from '../../utils/hooks/useTenantSettings'
import { currencyFormat } from '@supplement-bacon/alela-uikit'

interface Props {
  data: DonutItem[]
  color: string
  middle: number
}
export interface DonutItem {
  type: string
  value: number
}

export interface ColoredDonutItem extends DonutItem {
  color: string
}

export const Donut = ({ data, color, middle }: Props) => {
  const { currency } = useTenantSettings()
  const coloredData: ColoredDonutItem[] = data.map(
    (donutItem: DonutItem, index: number) => ({
      ...donutItem,
      color: makeDarker(color, -10 * index),
    })
  )

  function makeDarker(color: string, percent: number) {
    var R = parseInt(color.substring(1, 3), 16)
    var G = parseInt(color.substring(3, 5), 16)
    var B = parseInt(color.substring(5, 7), 16)

    R = Number((R * (100 + percent)) / 100)
    G = Number((G * (100 + percent)) / 100)
    B = Number((B * (100 + percent)) / 100)

    R = R < 255 ? R : 255
    G = G < 255 ? G : 255
    B = B < 255 ? B : 255

    R = Math.round(R)
    G = Math.round(G)
    B = Math.round(B)

    var RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16)
    var GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16)
    var BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16)

    return '#' + RR + GG + BB
  }

  return (
    <Pie
      data={coloredData}
      angleField="value"
      colorField="type"
      innerRadius={0.6}
      legend={false}
      label={{
        text: (d: DonutItem) => d.type,
        position: 'inside',
        fill: 'white',
        fillOpacity: 1,
        overlapHide: true,
      }}
      style={{
        fill: ({ type }: { type: string }) =>
          coloredData.find((d) => d.type === type)?.color ?? '#5d5df6',
      }}
      interaction={{
        elementHighlight: true,
      }}
      tooltip={(d: ColoredDonutItem) => ({
        name: d.type,
        color: d.color,
        value: `${currencyFormat(d.value, currency)}`,
      })}
      annotations={[
        {
          type: 'text',
          style: {
            text: `${currencyFormat(middle, currency)}`,
            x: '50%',
            y: '50%',
            textAlign: 'center',
            fontSize: 21,
            fontStyle: 'bold',
            fill: color,
          },
        },
      ]}
    />
  )
}

export type DonutType = { Donut: typeof Donut }

import {
  getAccountUsers,
  selectUsers,
  setUserQuery,
} from '../../../reducers/UserReducer'
import { useIntl } from 'react-intl'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { Alert, Button, Dropdown, Space, Spin, Tooltip, Typography } from 'antd'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import { initialQuery } from '../../../utils/helpers/crud/models'
import { ColumnsType } from 'antd/es/table'
import { User as UserModel } from '../../../models/User'
import { Team } from '../../../components/team/Team'
import { getRoles } from '../../../reducers/RoleReducer'
import { Actions } from '../../../modules/sbrm/components/Actions'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import HasAccess, { useHasAccess } from '../../../components/HasAccess'
import SBTable from '../../../components/SBTable'
import { Link } from 'react-router-dom'
import { MailOutlined, SettingOutlined } from '@ant-design/icons'
import { AlelaPermission } from '../../../utils/permissions'
import { ConditionalLinkWrapper } from '@supplement-bacon/alela-uikit'
import { User } from '../../../components/user/User'
import { TableParams } from '../../../models/TableParams'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { HelpArticle } from '../../../utils/hooks/useAlelaHelp'
import { Help } from '../../../components/help/Help'

const TeamPage = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const { hasAccess } = useHasAccess()

  const users = useAppSelector(selectUsers())
  const { isLoading, query } = useAppSelector((state) => state.user)

  const columns: ColumnsType<UserModel> = [
    {
      key: 'name',
      title: intl.formatMessage({ id: LocalizationKeys.Page.CRM.Team.Name }),
      render: (record: UserModel) => (
        <ConditionalLinkWrapper
          to={`/crm/organization-team/${record.id}`}
          condition={hasAccess([AlelaPermission.viewUser])}
        >
          <User.Avatar model={record} />
        </ConditionalLinkWrapper>
      ),
    },
    {
      key: 'email',
      title: intl.formatMessage({ id: LocalizationKeys.Misc.Form.Email }),
      render: (record: UserModel) => (
        <Link to={`mailto:${record.email}`}>
          <Button type="text" icon={<MailOutlined />}>
            {record.email}
          </Button>
        </Link>
      ),
    },
    {
      key: 'Role',
      title: intl.formatMessage({ id: LocalizationKeys.Page.CRM.Team.Role }),
      render: (record: UserModel) =>
        Array.isArray(record.roles) &&
        record.roles.length > 0 &&
        record.roles[0].name === 'support' ? (
          'Support'
        ) : (
          <Team.SelectRole user={record} />
        ),
    },
    {
      key: 'actions',
      align: 'right',
      render: (record: UserModel) => <User.EditDeleteButtons user={record} />,
    },
  ]

  useEffect(() => {
    const query: TableParams = {
      ...initialQuery,
      pagination: { current: 1, pageSize: 100 },
      with: ['roles'],
      columnKey: 'name',
      order: 'ascend',
    }
    dispatch(setUserQuery(query))
    dispatch(getAccountUsers(query))
    dispatch(getRoles(query))
  }, [dispatch])

  return (
    <HasAccess permissions={[AlelaPermission.listUser]}>
      <PageTitle
        title={intl.formatMessage({ id: LocalizationKeys.Page.CRM.Team.Title })}
        toolbar={
          <Space dir="horizontal">
            {isLoading && <Spin />}
            <Actions actions={['create']} entity={SBRMType.invitation} />
            <Dropdown
              placement="bottomRight"
              menu={{
                items: [
                  {
                    key: 'invitations',
                    label: (
                      <Link to="/crm/organization-team/invites">
                        {intl.formatMessage({
                          id: LocalizationKeys.Page.CRM.Team.Invitations,
                        })}
                      </Link>
                    ),
                  },
                ],
              }}
            >
              <Button icon={<SettingOutlined />} type={'text'} />
            </Dropdown>
            <Help.Button article={HelpArticle.ManageTeamAndPermissions} />
          </Space>
        }
      />
      <Alert
        style={{ marginTop: 20, marginBottom: 20 }}
        banner
        type="warning"
        message={
          <Space direction="vertical">
            <Typography.Text>
              {intl.formatMessage(
                {
                  id: LocalizationKeys.Page.CRM.Team
                    .SubscriptionWillAdjustDisclaimer,
                },
                { br: <br /> }
              )}
            </Typography.Text>
          </Space>
        }
      />
      <SBTable
        entity={SBRMType.user}
        scroll={{ x: 600 }}
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={users}
        loading={isLoading && !users.length}
        pagination={query.pagination}
      />
    </HasAccess>
  )
}

export default TeamPage

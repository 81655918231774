import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
  getUUIDOrModelUUID,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIFetchDispatch,
} from '../utils/helpers/SBAPIHelper'
import { Invitation } from '../models/Invitation'
import { TENANT_INVITATIONS } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { invitationSchema } from '../models/schema'

const initialState: SBState<Invitation> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: {
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },
}

const slice = createSlice({
  name: 'invitation',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getInvitationsSuccess,
  setQuery: setInvitationQuery,
  reset: resetInvitationState,
  resetQueryAndIds: resetInvitationQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: Invitation }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectInvitations = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedInvitation = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectInvitationById = (id: string) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectInvitationsByIds = (ids: string[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedInvitation =
  (Invitation: Invitation | string) => async (dispatch: AppDispatch) =>
    dispatch(
      slice.actions.setSelectedId(getUUIDOrModelUUID<Invitation>(Invitation))
    )

export const getInvitations = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<Invitation>(
    TENANT_INVITATIONS,
    params,
    [invitationSchema],
    slice.actions
  )

export const getInvitationWithId = (id: string) =>
  SBAPIFetchDispatch<Invitation>(
    `${TENANT_INVITATIONS}/${id}`,
    invitationSchema,
    slice.actions
  )

export const createInvitation = (data: object) =>
  SBAPICreate<any>(data, TENANT_INVITATIONS, invitationSchema, slice.actions)

export const deleteInvitation = (invitation: Invitation) =>
  SBAPIDelete<Invitation>(
    invitation,
    `${TENANT_INVITATIONS}/${invitation.id}`,
    slice.actions
  )

export const acceptInvitation = (invitation: Invitation) =>
  SBAPICreate<Invitation>(
    invitation,
    `${TENANT_INVITATIONS}/${invitation.id}/accept`,
    invitationSchema,
    slice.actions
  )

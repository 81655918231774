import * as authHelper from '../helpers/AuthLocalStorageHelper'
import { AuthModel, SSODetails } from '../../models/AuthModel'
import { Dispatch, SetStateAction, createContext, useContext } from 'react'

type AzureSSOContextProps = {
  auth: AuthModel | undefined
  isLogged: boolean | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  logout: () => void
  setSSODetails: (details: SSODetails) => void
  setIsLogged: Dispatch<SetStateAction<boolean | undefined>>
}

const initAzureSSOContextPropsState: AzureSSOContextProps = {
  auth: authHelper.getSSOAuth(),
  isLogged: undefined,
  saveAuth: () => {},
  logout: () => {},
  setSSODetails: () => {},
  setIsLogged: () => {},
}

const AzureSSOContext = createContext<AzureSSOContextProps>(
  initAzureSSOContextPropsState
)

const useAzureSSO = () => useContext(AzureSSOContext)

export { AzureSSOContext, useAzureSSO }

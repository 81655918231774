import { Col, Layout, Row } from 'antd'
import { Outlet } from 'react-router-dom'
import { LayoutFooter } from './LayoutFooter'
import MainHeader from './components/MainHeader'
import MainHeaderMobile from './components/MainHeaderMobile'

const { Content } = Layout

export const LayoutGeneral: React.FC = () => {
  return (
    <Layout>
      <MainHeader />
      <Layout style={{ minHeight: '100vh', marginTop: 64 }}>
        <Content>
          <Row className="container-row">
            <Col xs={24} className="px-4">
              <Outlet />
            </Col>
          </Row>
        </Content>
        <LayoutFooter />
      </Layout>
      <MainHeaderMobile />
    </Layout>
  )
}

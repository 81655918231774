import {
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { Rule } from 'antd/es/form'
import { useIntl } from 'react-intl'
import { NamePath } from 'antd/es/form/interface'
import { DeleteOutlined, InboxOutlined } from '@ant-design/icons'
import {
  UploadProps,
  Upload,
  Typography,
  Form,
  Input,
  Button,
  Space,
  Card,
} from 'antd'
import { File as FileModel } from '../../models/File'
import { File } from '../file/File'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { useAppSelector } from '../../reducers/hooks'
import { selectFilesByIds } from '../../reducers/FileReducer'
import { Actions } from '../../modules/sbrm/components/Actions'
import { SBRMType } from '../../modules/sbrm/SBRMModel'

const { Dragger } = Upload
const { Text } = Typography

interface Props {
  name?: NamePath
  label?: string
  rules?: Rule[]
  accept?: string
  multiple?: boolean
  defaultFiles?: FileModel[]
  defaultFileIds?: number[]
  showDropdownOnFileCell?: boolean
}

export interface FileDropzoneRefInterface {
  handleReset: () => void
}

/**
 * TODO:
 * Refactor this component & its usage to remove defaultFiles
 * We want to rely on defaultFileIds only
 * All files deletion must be done through DELETE /files
 * We need to get rid of `filesToDelete`
 */
const FileDropzone = forwardRef(
  (
    {
      name,
      label,
      rules,
      accept = '*',
      multiple = false,
      defaultFiles,
      defaultFileIds,
      showDropdownOnFileCell = false,
    }: Props,
    ref: Ref<FileDropzoneRefInterface>
  ) => {
    useImperativeHandle(ref, () => ({ handleReset }))
    const intl = useIntl()
    const handleReset = () => setIdsToDelete([])

    const [idsToDelete, setIdsToDelete] = useState<string[]>([])
    const [uploadedFiles, setUploadedFiles] = useState<FileModel[]>([])
    const files = useAppSelector(selectFilesByIds(defaultFileIds ?? []))

    const uploadProps: UploadProps = {
      maxCount: multiple ? undefined : 1,
      multiple: multiple,
      accept: accept,
      beforeUpload(file) {
        if (!multiple) {
          setIdsToDelete(uploadedFiles.map((f) => f.id.toString()))
          setUploadedFiles([])
        }
        return false
      },
    }

    useEffect(() => {
      setIdsToDelete([])
      setUploadedFiles(defaultFiles ?? [])
    }, [defaultFiles])

    return (
      <>
        <Form.Item
          name={name}
          label={label}
          rules={rules}
          style={{ marginBottom: 0 }}
        >
          <Dragger {...uploadProps}>
            <Space direction="vertical" size={0}>
              <p className="ant-upload-drag-icon" style={{ marginTop: 0 }}>
                <InboxOutlined />
              </p>
              <Text>
                {intl.formatMessage({
                  id: uploadProps.multiple
                    ? LocalizationKeys.Components.File.Upload
                    : LocalizationKeys.Components.CustomInput.Dropzone,
                })}
              </Text>
              <Text type="secondary" italic style={{ fontSize: 12 }}>
                {intl.formatMessage({
                  id: LocalizationKeys.Components.File.MaxSize,
                })}
              </Text>
            </Space>
          </Dragger>
        </Form.Item>
        {idsToDelete.map((id) => (
          <Form.Item
            key={id}
            name={['filesToDelete', id]}
            initialValue={id}
            className="d-none"
          >
            <Input type="hidden" value={id} />
          </Form.Item>
        ))}

        <Space
          direction="vertical"
          style={{ width: '100%', marginTop: 20, marginBottom: 20 }}
        >
          {uploadedFiles?.map((file) => (
            <Card key={file.id} styles={{ body: { padding: 15 } }}>
              <Space
                direction="horizontal"
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <File.Cell showDropdown={false} model={file} maxWidth={200} />
                <Button
                  danger={true}
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    setUploadedFiles(
                      uploadedFiles.filter((f) => f.id !== file.id)
                    )
                    setIdsToDelete([...idsToDelete, file.id.toString()])
                  }}
                />
              </Space>
            </Card>
          ))}
        </Space>
        <Space
          direction="vertical"
          style={{ width: '100%', marginTop: 20, marginBottom: 20 }}
        >
          {files?.map((file) => (
            <Card key={file.id} styles={{ body: { padding: 15 } }}>
              <Space
                direction="horizontal"
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <File.Cell
                  showDropdown={showDropdownOnFileCell}
                  model={file}
                  maxWidth={200}
                />
                <Actions
                  entity={SBRMType.file}
                  entityId={file.id}
                  actions={['delete']}
                />
              </Space>
            </Card>
          ))}
        </Space>
      </>
    )
  }
)

FileDropzone.displayName = 'FileDropzone'
export { FileDropzone }

export type FileDropzoneType = { Dropzone: typeof FileDropzone }

import { FC } from 'react'
import {
  TableWithAssociation,
  TableWithAssociationType,
} from './TableWithAssociation'
import { ContactAvatar, ContactAvatarType } from './Avatar'
import { Association, AssociationType } from './Association'
import { ContactAvatarGroup, ContactAvatarGroupType } from './AvatarGroup'
import { ContactTypeTag, ContactTypeTagType } from './TypeTag'
import { LinkedToUser, LinkedToUserType } from './LinkedToUser'
import { ContactForm, ContactFormType } from './form/Form'

export const Contact: FC &
  AssociationType &
  ContactAvatarType &
  TableWithAssociationType &
  ContactTypeTagType &
  LinkedToUserType &
  ContactAvatarGroupType &
  ContactFormType = (): JSX.Element => <></>

Contact.Avatar = ContactAvatar
Contact.Association = Association
Contact.AvatarGroup = ContactAvatarGroup
Contact.TableWithAssociation = TableWithAssociation
Contact.TypeTag = ContactTypeTag
Contact.LinkedToUser = LinkedToUser
Contact.Form = ContactForm

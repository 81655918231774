import { useIntl } from 'react-intl'
import { Form, FormInstance, Input } from 'antd'
import { emailRule, requiredRule } from '../../../utils/rules'
import LocalizationKeys from '../../../i18n/LocalizationKeys'

interface Props {
  form: FormInstance
}

const Create = ({ form }: Props) => {
  const intl = useIntl()

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="firstName"
        required
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.FirstName })}
        rules={[requiredRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="lastName"
        required
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.LastName })}
        rules={[requiredRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        required
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Email })}
        rules={[requiredRule(intl), emailRule(intl)]}
      >
        <Input />
      </Form.Item>
    </Form>
  )
}

export default Create

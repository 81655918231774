import Sider from 'antd/es/layout/Sider'
import { Outlet } from 'react-router-dom'
import { Affix, Col, Layout, Row } from 'antd'
import MainHeader from './components/MainHeader'
import ProfileSider from './components/ProfileSider'

const { Content } = Layout

export const LayoutProfile: React.FC = () => {
  return (
    <Layout>
      <MainHeader />
      <Content style={{ minHeight: '100vh', marginTop: 64 }}>
        <Row className="container-row">
          <Col xs={24} className="px-4">
            <Layout>
              <Affix offsetTop={100} style={{ width: 250 }}>
                <Sider width={250} style={{ background: 'none' }}>
                  <ProfileSider />
                </Sider>
              </Affix>
              <Content style={{ padding: '0 24px', minHeight: 280 }}>
                <Outlet />
              </Content>
            </Layout>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

import { Col, DatePicker, Form, Row, Select, Space } from 'antd'
import { requiredRule } from '../../utils/rules'
import { useIntl } from 'react-intl'
import {
  GroundTimeMode,
  useGroundContext,
} from '../../utils/context/GroundContext'
import dayjs from 'dayjs'
import { useAppSelector } from '../../reducers/hooks'
import { selectEventById } from '../../reducers/EventReducer'

interface Props {
  eventId: number
}

const PickupTimeFormItem = ({ eventId }: Props) => {
  const intl = useIntl()
  const form = Form.useFormInstance()
  const { timeMode, setTimeMode } = useGroundContext()
  const event = useAppSelector(selectEventById(eventId))

  return (
    <Row gutter={[16, 16]}>
      <Col span={8} style={{ marginTop: 30 }}>
        <Form.Item name="timeMode">
          <Select
            value={timeMode}
            options={[
              { label: 'Depart at', value: 'departAt' },
              { label: 'Arrive by', value: 'arriveBy' },
            ]}
            onChange={(value: GroundTimeMode) => {
              setTimeMode(value)
              form.setFieldValue('duration', undefined)
            }}
          />
        </Form.Item>
      </Col>
      <Col span={16}>
        <Form.Item
          name="pickupTime"
          rules={[requiredRule(intl)]}
          label={'Time'}
        >
          <DatePicker
            defaultPickerValue={dayjs(event && event.start_date)
              .hour(0)
              .minute(0)
              .second(0)}
            format="YYYY-MM-DD HH:mm"
            showTime={{ format: 'HH:mm' }}
            className="w-full"
            cellRender={(current, info) => {
              if (info.type !== 'date') return info.originNode

              const isEventDay: boolean =
                event !== undefined &&
                dayjs(current).format('YYYY-MM-DD') ===
                  dayjs(event?.start_date).format('YYYY-MM-DD')

              return (
                <div
                  className="ant-picker-cell-inner"
                  style={
                    isEventDay
                      ? { border: '1px solid #1677ff', borderRadius: '4' }
                      : undefined
                  }
                >
                  {dayjs(current).date()}
                </div>
              )
            }}
          />
        </Form.Item>
      </Col>
    </Row>
  )
}

type PickupTimeFormItemType = { PickupTimeFormItem: typeof PickupTimeFormItem }

export { PickupTimeFormItem, type PickupTimeFormItemType }

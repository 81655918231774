import { useAppSelector } from '../../reducers/hooks'
import { ContactAssociation } from '../../models/Contact'
import { selectContactAssociationById } from '../../reducers/ContactAssociationReducer'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { Contact } from './Contact'
import { addUrlParams } from '../../modules/sbrm/UrlHelper'
import { useSearchParams } from 'react-router-dom'
import { Card, Space } from 'antd'
import { Booking } from '../booking/Booking'

interface Props {
  id?: number
  model?: ContactAssociation
}

export const Association = ({ id, model }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const association = model
    ? model
    : useAppSelector(selectContactAssociationById(id!))

  const handleDelete = () => {
    addUrlParams(
      {
        action: 'delete',
        entity: SBRMType.contactAssociation,
        entityId: (association?.id ?? 0).toString(),
      },
      undefined,
      setSearchParams
    )
  }

  if (!association) {
    return <></>
  }

  return (
    <Space>
      <Booking.Avatar id={association.contactable?.id ?? 0} />
      <Contact.TypeTag
        id={association.type}
        closable={true}
        onClose={handleDelete}
      />
    </Space>
  )
}

export type AssociationType = { Association: typeof Association }
